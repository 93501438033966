import React          from 'react';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../utils/colors';


var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({    root: {
        width:          '100%',
        height:         '100%',
       // minHeight:       100,
       // marginTop:      '10%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        flexFlow:       'column',

    },
    input_container: {
     //   flex:         1,
        minHeight:      '50%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        flexFlow:       'column',
        transition:     'all 1.2s',
    },
    line: {
        height:        3,
        borderRadius: '1em',
    },
    text_field: {
        paddingLeft:     10,
        paddingRight:    10,
        backgroundColor: '#FFF',
        textAlign:       'center',
        border:          `1px solid ${ colors.primary }`,
        borderRadius:    '0.25em',
        fontSize:        30,
        color:           colors.primary,
        marginTop:       10,
        marginBottom:    10,
        transition:      'background 0.2s',
        '&:focus': {
          outline: 'none',
          border:  `2px solid ${ colors.primary }`,
          height:   48,
         },
    /*     '&:hover': {
            backgroundColor: '#EEF',
        } */
      },
    input: {
        fontSize:        30,
        width:           '100%',
        letterSpacing:   1,
        transitions:     'all 1s'
    },
    shake: {
        animation:               'shake 0.4s',
        animationIterationCount: 'infinite',
    }
}))


type InputFractionProps = {
    combined:             boolean;
    maxLength:            number;
    numerator:            string;
    denominator:          string;
    success:              string;
    inputInteger:         string;
    inputNumerator:       string;
    inputDenominator:     string;
    numeratorMaxLength:   number;
    denominatorMaxLength: number;
    textFieldHeight:      number;
    setInputNumerator:    (val: string) => void;
    setInputDenominator:  (val: string) => void;
}

const InputFraction: React.FC<InputFractionProps> = ({ 
    combined,
    inputInteger,
    maxLength,
    numerator,
    denominator,
    success,
    inputNumerator,
    inputDenominator,
    numeratorMaxLength,
    denominatorMaxLength,
    textFieldHeight,
    setInputNumerator,
    setInputDenominator,
}) => {

  const { classes } = useStyles();

  const [shakeNumerator, setShakeNumerator]     = React.useState(false);
  const [shakeDenominator, setShakeDenominator] = React.useState(false);

  const numeratorRef   = React.useRef<HTMLInputElement>(null);
  const denominatorRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {

    if (numeratorRef && numeratorRef.current && combined && !inputNumerator && inputInteger.length === maxLength) {
        numeratorRef.current.focus();
    }

    if (denominatorRef && denominatorRef.current && !inputDenominator && inputNumerator.length === numeratorMaxLength) {
        denominatorRef.current.focus();
    }

  },[numeratorRef, denominatorRef, inputInteger, inputNumerator, inputDenominator, combined, maxLength, numeratorMaxLength])

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'numerator') {
        if (isNaN(+target.value) || target.value.length > numeratorMaxLength) {
            target.value = inputNumerator;
            setShakeNumerator(true);
            setTimeout(() => { setShakeNumerator(false) }, 1000)
            return;
        }
        setInputNumerator(target.value)
    }


    if (target.id === 'denominator') {


        if (isNaN(+target.value) || target.value.length > denominatorMaxLength) {
            target.value = inputDenominator;
            setShakeDenominator(true);
            setTimeout(() => { setShakeDenominator(false) }, 1000)
            return;
        }
        setInputDenominator(target.value)
    }


  }

  const [disable, failure] = React.useMemo(() => { 
    return [
        (success !== ''),
        (success === 'false')
      ]
  },[ success ])


  const [numeratorStyle, denominatorStyle, lineStyle] = React.useMemo(() => {

    const style = {
        height:    textFieldHeight,
        border:    `1px solid ${ failure ? '#BBB' : colors.primary }`,
        color:     failure ? '#BBB' : colors.primary
      }
    return[
        {
            ...style,
            width: numeratorMaxLength * 30,
        },
        {
            ...style,
            width: denominatorMaxLength * 30,
        },
        {
            width:           combined ? '100%' : '40%',
            backgroundColor: failure ? '#BBB' : colors.primary,
            border:          `1px solid ${ failure ? '#BBB' : colors.primary }`,
        }
    ]
  },[failure,combined,textFieldHeight,numeratorMaxLength,denominatorMaxLength])


  return (

    <div className = { classes.root }>
        <div className = { shakeNumerator ? clsx(classes.input_container, classes.shake) : classes.input_container }>
                <input 
                    ref          = { numeratorRef }
                    className    = { classes.text_field }
                    type         = "text"
                    autoComplete = "off"
                    onChange     = { changeInputValue }
                    value        = { disable ? numerator : inputNumerator }
                    disabled     = { disable }
                    id           = "numerator"
                    style        = { numeratorStyle }
                />
        </div>
        <div
           className = { classes.line }
           style     = { lineStyle }
        />
        <div className = { shakeDenominator ? clsx(classes.input_container, classes.shake) : classes.input_container }>
                <input 
                    ref          = { denominatorRef }
                    className    = { classes.text_field }
                    type         = "text"
                    autoComplete = "off"
                    onChange     = { changeInputValue }
                    value        = { disable ? denominator : inputDenominator }
                    disabled     = { disable }
                    id           = "denominator"
                    style        = { denominatorStyle }
                />
        </div>
    </div>
  );
}

export default InputFraction;

