
import { ActionTypes }    from '../actions/types';
import { audioStateType } from './types';


const initialState: audioStateType = {
    audioSrc:               'https://iseemath.co/audio/01_starting.wav',
    volume:                 30,
    playAudio:              false,
    pauseAudio:             true,
    muteAudio:              false,
    replay:                 false,
    showButtons:            true,
    audioGuideOn:           true,
    appMenuAudioPlayedOnce: false,
    initialOpen:            false,
};

const audioReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {

    case 'SET_AUDIO_STATE':{

      const newState = action.payload;
    
      return {
        ...state,
        ...newState
      }
    }

    default: return state;
  }
};

export type reducerType = ReturnType<typeof audioReducer>;

export { audioReducer };
