import React          from 'react';
import { makeStyles } from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
       flex:           1,
       maxHeight:      200,
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'center',
       textAlign:      'center',
       flexFlow:       'column',
    },
    text: {
       marginTop:     14,
       lineHeight:    1.2,
       letterSpacing: 2,
       display:       'flex',
       alignItems:    'center',
    },
    image: {
      height:             80,
      width:              80,
      backgroundPosition: 'center',
      backgroundSize:     'cover',
      backgroundRepeat:   'no-repeat',
      borderRadius:       '50%',
      overflow:           'hidden',
      border:              '1px solid #DDD'
    },
}))

type HeadProps = { val: string[]; }

const Head: React.FC<HeadProps> = ({ val }) => {

  const { classes } = useStyles();


  return (
    <div className = { classes.root }>
            <div
                className = { classes.image }
                style     = {{
                  backgroundImage: `url(${ val[0] })`
                }}
            />
            <div className = { classes.text }>
               { val[1] }
            </div>
            <div className = { classes.text }>
               { val[2] }
            </div>
    </div>
  );
}




export default Head;

