import React     from 'react';
import { store } from 'index';
import { 
    userAccessType, 
    userAttributesType,  
} from 'types';

const useGetUserAccess = ( 
    userAttributes: userAttributesType
) => {

    const state    = store.getState();
    const userInfo = state.user.userInfo;

    const getAccess = React.useMemo(() => {

        const { subscriber, allowTrial, completeTrial, freeUser, blocked, subscriptionSuspended } = userInfo;
        const unSubscribedButAuthed = (!freeUser && !allowTrial && ((!subscriber || subscriptionSuspended) && userAttributes.userSignedIn === 'true'));
        const subStatus             = freeUser            ? 'free user' : 
                                    allowTrial            ? 'free trial' : 
                                    blocked               ? 'blocked' :
                                    subscriptionSuspended ? 'suspended' : 
                                    completeTrial         ? 'trial completed' : 
                                    unSubscribedButAuthed ? 'authenticated user - not subscribed' : 
                                    subscriber            ? 'active' : 
                                    'not subscribed';

        const obj: userAccessType = {
            subscriber:             subscriber,
            freeTrial:              allowTrial,
            freeUser:               freeUser,
            unSubscribedAuthedUser: unSubscribedButAuthed,
            denyAccess:             (userAttributes.userSignedIn === 'true' && !freeUser && !allowTrial && (!subscriber || blocked || subscriptionSuspended)),
            subscriptionStatus:     subStatus    
        }

    return obj

    },[userAttributes, userInfo])

    return getAccess;
    
}

export default useGetUserAccess;