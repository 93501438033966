import React                 from 'react';
import { 
    topics,
    texts,
    lists
}                            from './privacy_policy';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles }        from 'makeStyles';

const obj: { [key: string]: any; } = {
    topics:  topics,
    title0:  ['Topics:'],
    title1:  [topics[0]],
    title2:  [topics[1]],
    title3:  [topics[2]],
    title4:  [topics[3]],
    title5:  [topics[4]],
    title6:  [topics[5]],
    title7:  [topics[6]],
    title8:  [topics[7]],
    title9:  [topics[8]],
    title10: [topics[9]],
    title11: [topics[10]],
    title12: [topics[11]],
    title13: [topics[12]],
    text0:   texts[0],
    text1:   texts[1],
    text2:   texts[2],
    text3:   texts[3],
    text4:   texts[4],
    text5:   texts[5],
    text6:   texts[6],
    text7:   texts[7],
    text8:   texts[8],
    text9:   texts[9],
    text10:  texts[10],
    text11:  texts[11],
    text12:  texts[12],
    text13:  texts[13],
    text14:  texts[14],
    text15:  texts[15],
    list0:   lists[0],
    list1:   lists[1],
    list2:   lists[2],
    list3:   lists[3],
    list4:   lists[4],
    list5:   lists[5],
}
 
const useStyles = makeStyles()((Theme) => ({  
  listItem: {
    display:    'flex',
    flexFlow:   'row',
    textAlign:  'left',
   // alignItems: 'center',
    marginLeft: 40,
    lineHeight: 2
  },
  title: {
    fontWeight:    'bold',
    fontSize:      18,
    paddingLeft:   10,
    paddingTop:    20,
    paddingBottom: 20,
  },
  item: {
    padding:    10,
  //  marginLeft: 10,
  }
}));

type TextProps = {  
    variant: string;
    value:   string;
}

const Text: React.FC<TextProps> = ({ variant, value }) => {
 
const { classes } = useStyles();  

const iconStyle = {
    marginTop:    11,
    fontSize:     10,
    paddingRight: 8
}

const arr = React.useMemo(() => {
    return !!obj[value] ? obj[value] : ['']
},[value])

return (
    <>
        { 
            arr.map((val: string, ind: number) => {
                return(
                    <div 
                        key       = { `priavcy_policy_${ variant }_${ ind }` }
                        className = { variant === 'title' ? classes.title : variant === 'list' ? classes.listItem : classes.item }
                    >
                        { variant === 'list' && <FiberManualRecordIcon style = { iconStyle }/> }
                       <div>{ val }</div>
                    </div>
                )
            })
        }
    </>
  );
}


export default Text