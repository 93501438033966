import React                    from 'react';
import { connect }              from 'react-redux';
import { Dispatch }             from "redux";
import { ActionTypes }          from 'actions/types';
import { reducerType }          from 'reducer';
import { colors }               from 'utils/colors';
import { makeStyles }           from 'makeStyles';
import { userInfoType }         from 'types';
import {
  appsArr,
  myStudentContArr1,
  myStudentContArr2,
  favoritesArr,
  myLessonsArr,
  groupsArr,
  lessonsArr,
  bonusTeachersArr,
  bonusStudentsArr
}                               from './helpTexts'


var clsx = require('classnames');

const titleObj: { [key: string]: any; } = {
    apps:              'I See Math Apps',
    games:             'Games',
    bonus:             'Puzzles',
    lessons:           'Lessons',
    favorites:         'Favorites',
    groupLessons:      'Group Lessons',
    userLessons:       'My Lessons',
    myStudentsContent: 'My Students Content',
    bigContentArr:     'I See Math Apps',
    introVideo:        'Introduction Video'
 }


const textsObj: { [key:string]: string[] } = {
  apps:              appsArr,
  bigContentArr:     appsArr,
  favorites:         favoritesArr,
  userLessons:       myLessonsArr,
  groupLessons:      groupsArr,
  lessons:           lessonsArr,
  bonusTeachers:     bonusTeachersArr,
  bonusStudents:     bonusStudentsArr,
}



const useStyles = makeStyles()(
  (Theme) => ({    
    container: {
        width:           '100%',
        backgroundColor: '#FFF',
    },
    inner: {
        color:           colors.darkText,
        fontSize:        18,
        overflowY:       'scroll',
        overflowX:       'hidden',
        borderBottom:    '1px solid #DDD',
        zIndex:           10,
        '&::-webkit-scrollbar': {
            width:      '0.5em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `#BBB`,
            borderRadius:    10
          }
    },
    title: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        height:          80,
        fontSize:        17,
        fontWeight:      'bold',
        borderBottom:    '1px solid #DDD'
    },
    text: {
        width:      '86%',
        marginLeft: '7%',
        fontSize:   18,
        lineHeight: 1.8
    },
    img: {
        backgroundPosition: 'center',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat',
        maxHeight:          '50vh',
        height:             290,
       // width:              270,
        width:              '100%',
      },
      favoritesImage: {
         backgroundImage: `url(https://iseemath.co/images/favoriteImage.png)`,
      },
      MSCImage: {
        backgroundImage: `url(https://iseemath.co/images/myStudentContntImage.png)`,
     }
}))


type HelpProps = {
  height:             number;
  variant:            string;
  userInfo:           userInfoType;
  studentLoggedIn:    boolean;
}

const Help: React.FC<HelpProps> = ({
  height,
  variant,            
  userInfo,           
  studentLoggedIn,    
}) => {
  
  const { classes } = useStyles();


  const arr = React.useMemo(() => {

    if (variant === 'myStudentsContent') {
       return myStudentContArr1.concat([`Email: ${ userInfo.userEmail }`, `Teacher code: ${ userInfo.teacherCode }`]).concat(myStudentContArr2)
    }
    
    const str = variant === 'bonus' ? ( studentLoggedIn ? 'bonusStudents' : 'bonusTeachers' ) : variant;

    if ( !!textsObj[`${ str }`] ) {
      return textsObj[`${ str }`]
    }
    return []

  },[variant, userInfo, studentLoggedIn]) 


  const [containerStyle, innerStyle] = React.useMemo(() => {
    return[
       {
        height: height - 82
       },
       {
        height: height - 150
       }
    ]
  },[height])
  
  return (
    <div 
        className = { classes.container }
        style     = { containerStyle }
    >
        <div className = { classes.title }>
           { !!titleObj[variant] ? titleObj[variant] : titleObj['apps'] }
        </div>
        <div 
          className = { classes.inner }
          style     = { innerStyle }
        >
            {
                arr.map((val, ind) => {
                    return(
                    <div 
                        key       = { `instructions${ val }` } 
                        className = { classes.text }
                        style     = {{
                            marginTop:    !ind ? 25 : undefined,
                            marginBottom: ind === arr.length - 1 ? 25 : undefined,
                        }}
                    >
                        { val }
                    </div>
                    )
                })
            }
            {
                variant === 'myStudentsContent' &&
                <div className = { clsx(classes.img, classes.MSCImage) } />
            }
            {
                variant === 'favorites' &&
                <div className = { clsx(classes.img, classes.favoritesImage) } />
            }
        </div>
    </div>
  );
}


const mapStateToProps = (reducer: reducerType) => {

    const arr = ['introVideo', 'games', 'content'];
  
    return {
      variant:         arr.includes(reducer.components.selectedContent) ? 'apps' : reducer.components.selectedContent,
      userInfo:        reducer.user.userInfo,
      studentLoggedIn: reducer.user.studentLoginState.loginState === 'success',
  
  }};
  
  
  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  });
  
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Help);




