
import { store } from 'index';

const get_favorites = () => {

   const state = store.getState();

   const pageState = state.page;
   const { hash }  = state.content;

   const keys = Object.keys(hash);
   const res = [];

   for ( let i = 0; i < keys.length; i++ ) {

       if ( hash[keys[i]].selected ) {
          if (
               (pageState.page === 'content'  &&  (hash[keys[i]].origin === 'apps' || hash[keys[i]].origin === 'lessons' ))
               ||
               (pageState.page === 'puzzles'  &&  hash[keys[i]].origin === 'puzzles' )
             ) {
                res.push(hash[keys[i]])
          } 
       } 
       
   }

   return res;
}

export default get_favorites;