import React                  from 'react';
import { store }              from 'index';
import { getUserInfoType }    from 'actions/types'
import { userAttributesType } from 'types'
import { GET_USER_INFO_SAGA } from 'actions';

const useGetUserInfo = (
       userAttributes: userAttributesType,
       tester:         string
    ) => {

    const state           = store.getState();
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';

    const getUserInfoCB = React.useCallback(() => {

               const getUserInfo = (payload: getUserInfoType) => store.dispatch(GET_USER_INFO_SAGA(payload));

               const { userEmail, userName, externalUser } = userAttributes;
            
               getUserInfo({
                userEmail:    userEmail,
                userName:     userName,
                externalUser: externalUser,
                tester:       tester === 'true'
               })
            
     },[userAttributes, tester]);
   
   
     React.useEffect(() => {
   
       if ( !studentLoggedIn ) {
         getUserInfoCB();
       }
       
     },[ studentLoggedIn, getUserInfoCB ])
}


export default useGetUserInfo;