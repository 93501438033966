import React     from 'react';
import { sizes } from 'utils/defaultStates';

const useRow1Style = ( wideScreen: boolean ) => {

 const [rootStyle, containerStyle, textContainerStyle, text2Style] = React.useMemo(() => {

     return[
       {
         backgroundImage: wideScreen ? 'url(https://iseemath.co/images/homePage.jpg)' : undefined,
         height:          wideScreen ? '100vh' : 350,
         marginTop:       wideScreen ? 0 : sizes.navBarSize + 50,
         marginBottom:    wideScreen ? undefined : 30,
         alignItems:      wideScreen ? 'center' : undefined,
       },
       {
        width:      wideScreen ? '50vw' : '96vw',
        marginLeft: wideScreen ? '15vw' : '2vw',
        boxShadow:  wideScreen ? '0px 0px 40px #FFF' : undefined,
       },
       {
        fontSize: wideScreen ? 50 : 30
       },
       {
        fontSize: wideScreen ? 35 : 23
       }
     ]
 },[wideScreen])

 return [rootStyle, containerStyle, textContainerStyle, text2Style]

}

export default useRow1Style;
