import { contentInfoType }     from "../../types";
import { componentsStateType } from '../../reducer/types';

const goToGroupContent = (
    groupState:         contentInfoType,
    initialFilterState: componentsStateType,
    setGroupName:       (val: string) => void,
    setFilterState:     (val: componentsStateType) => void,
    setContent:         (payload: contentInfoType) => void,
    setChangeContent:   (val: boolean) => void,
) => {
    setGroupName(groupState.groupName);
  
        if ( !!groupState.groupName.length ) {
  
           setFilterState({
             ...initialFilterState,
             appLessons:       [],
             showAppIntro:     false,
             selectedContent:  'groupLessons',
             showStudentVideo: false,
             showTutorial:     false,
           })
  
           setContent({ allContent: groupState.groupLessons})
           setChangeContent(true)
  
        }
}

export default goToGroupContent;