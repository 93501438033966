import React          from 'react';
import Integer        from './Integer';
import Fraction       from './Fraction';
import Combined       from './Combined';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({    container: {
        height:         '50vh',
        width:          300,
        display:        'flex',
        alignItems:     'center',
        flexFlow:       'column',
        justifyContent: 'flex-start',
        transition:     'all 0.8s'
    }
}))


type InputProps = {
    size:                 number[];
    fraction:             boolean;
    integer:              boolean;
    combined:             boolean;
    inputInteger:         string;
    maxLength:            number;
    inputNumerator:       string;
    inputDenominator:     string;
    numeratorMaxLength:   number;
    denominatorMaxLength: number;
    animateInput:         boolean;
    setAnimateInput:      (val: boolean) => void;
    setInputInteger:      (val: string) => void;
    setInputNumerator:    (val: string) => void;
    setInputDenominator:  (val: string) => void;
}

const Input: React.FC<InputProps> = ({
    size,
    fraction, 
    integer, 
    combined, 
    inputInteger, 
    maxLength,
    inputNumerator,
    inputDenominator,
    numeratorMaxLength,
    denominatorMaxLength,
    setInputInteger,
    setInputNumerator,
    setInputDenominator,
    animateInput,
    setAnimateInput
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false);
  const [animate, setAnimate]       = React.useState(false);
  const [variant, setVariant]       = React.useState(integer ? 'integer' : (fraction ? 'fraction' : 'combined'));

  const textFieldHeight = React.useMemo(() => {
    return size[1] > 600 ? 50 : 35
  },[size]) 

  React.useEffect(() => {

         setTimeout(() => { setInitialPos(true)}, 10);
         
  },[setInitialPos])



  React.useEffect(() => {

    const newVariant = integer ? 'integer' : (fraction ? 'fraction' : 'combined');

    setTimeout(() => { setVariant(newVariant)}, 500);
    
},[fraction, integer, combined, setVariant])



  React.useEffect(() => {

    if (animateInput) {
        setAnimate(true);
        setTimeout(() => {
            setAnimateInput(false);
            setAnimate(false);
        }, 500) 

    }
  },[setAnimate, animateInput, setAnimateInput])

  return (

    <div 
       className = { classes.container }
       style     = {{
          transform: `translate(0px, ${initialPos ? 0 : 2000 }px)`,
          marginTop: animate ? 2000 : 0
       }}
    >
        {
            variant === 'integer'
            &&
            (
                <Integer
                    combined        = { false }
                    textFieldHeight = { textFieldHeight }
                    inputInteger    = { inputInteger }
                    setInputInteger = { setInputInteger }
                    maxLength       = { maxLength }
                />
            )
        }
        {
            variant === 'fraction'
            &&
            (
                <Fraction
                    combined             = { false }
                    textFieldHeight      = { textFieldHeight }
                    maxLength            = { maxLength }
                    inputInteger         = { inputInteger }
                    inputNumerator       = { inputNumerator }
                    setInputNumerator    = { setInputNumerator }
                    inputDenominator     = { inputDenominator }
                    setInputDenominator  = { setInputDenominator }
                    numeratorMaxLength   = { numeratorMaxLength }
                    denominatorMaxLength = { denominatorMaxLength }
                />
            )
        }
        {
            variant === 'combined'
            &&
            (
                <Combined
                    inputInteger         = { inputInteger }
                    setInputInteger      = { setInputInteger }
                    maxLength            = { maxLength }
                    inputNumerator       = { inputNumerator }
                    setInputNumerator    = { setInputNumerator }
                    inputDenominator     = { inputDenominator }
                    setInputDenominator  = { setInputDenominator }
                    numeratorMaxLength   = { numeratorMaxLength }
                    denominatorMaxLength = { denominatorMaxLength }
                    textFieldHeight      = { textFieldHeight }
                />
            )
        }
    </div>
  );
}

export default Input;

