//80.235.154.64


export const devPLoad = {
    userId:                  123123,
    sessionId:               '',
    userIp:                  '',
    userName:                'some user name',
    userEmail:               'raph@raph.raph',
    userOrigin:              'some text',
    browser:                 '',
    hasPassword:             true,
    userRole:                'teacher',
    teacherCode:             '',
    firstPayment:            '2024-01-20T12:15:52Z',
    subscriptionType:        'Annually',
    trialStartedAt:          '',
    balance:                 '',
    allowTrial:              false,
    completeTrial:           false,
    subscriber:              true,
    freeUser:                false,
    blocked:                 false,
    subscriptionSuspended:   false,
    subscriptionSuspendedAt: '',
    subscriptionId:          'asdasd',
    studentOpenApps:         [],
    oneTimeEvents:           [],
    //countryName:             'United Kingdom'
    countryName:             'Israel'
}


export const guestPLoad =  {
    userId:                  -1,
    sessionId:               '',
    userIp:                  '',
    userName:                'Guest',
    userEmail:               'Guest',
    userOrigin:              '',
    browser:                 '',
    balance:                 '',
    hasPassword:             false,
    userRole:                '',
    teacherCode:             '',
    trialStartedAt:          '',
    completeTrial:           false,
    allowTrial:              false,
    firstPayment:            '',
  //  nextPayment:             '',
    subscriptionType:        '',
    subscriber:              false,
    freeUser:                false,
    blocked:                 false,
    subscriptionSuspended:   false,
   // suspendSubscriptionAt:   '',
    subscriptionSuspendedAt: '',
    subscriptionId:          '',
    studentOpenApps:         [],
    oneTimeEvents:           [],
    countryName:             '',
  }


export const errPLoad = {
    userId:                  -1,
    sessionId:               '',
    userIp:                  '',
    userName:                '',
    userEmail:               '',
    userOrigin:              '',
    browser:                 '',
    teacherCode:             '',
    balance:                 '',
    trialStartedAt:          '',
    hasPassword:             false,
    completeTrial:           false,
    allowTrial:              false,
    userRole:                '',
    subscriber:              false,
    freeUser:                false,
    blocked:                 false,
    subscriptionSuspended:   false,
  //  suspendSubscriptionAt:   '',
    subscriptionSuspendedAt: '',
    firstPayment:            '',
  //  nextPayment:             '',
    subscriptionType:        '',
    subscriptionId:          '',
    oneTimeEvents:           [],
    countryName:             ''
 }