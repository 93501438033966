import { useState, useMemo }    from 'react';
import { Dispatch }             from "redux";
import { connect }              from 'react-redux';
import { reducerType }          from 'reducer';

import Loader                   from 'components/Loader';
import TextInput                from './TextInput';
import JoinLessonInput          from './JoinLessonInput';
import StudentLogin             from './StudentLogin';
import UserNameForm             from './forms/UserNameForm';
import MessageForm              from './forms/MessageForm';
import SubDetails               from './SubDetails';
import Pitch                    from './Pitch';
import PlanSelector             from './PlanSelector';
import TutorialMenu             from './tutorialMenu';
import Help                     from './help';
import Buttons                  from './Buttons';
import closeModal               from 'functions/modal/close_modal'
import goToGroup                from './functions/go_to_group'

import { 
    ActionTypes, 
}                              from 'actions/types';
import { 
    texts, 
    subDeactivation,
    deactivationSuccess,
    expirationWarning
} 
                                from './texts';
import { 
  userInfoType,
  planTypeType,
  userAttributesType,
  userAccessType
}                               from 'types'
import { colors }               from 'utils/colors';
import { makeStyles }           from 'makeStyles';
import { componentsStateType } from 'reducer/types';

var clsx = require('classnames')

type textAlignType = 'left' | 'center';

const initialTeacherCredentials = {
    error:        '',
    teacherEmail: '',
    teacherCode:  ''
}

const useStyles = makeStyles()(
  (Theme) => ({    root : {
        position:        'fixed',
        left:            0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        transition:      'opacity 0.5s',
        transform:       'all 0.4s',
        overflowY:       'scroll',
        overflowX:       'hidden',
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex:           10,
        '&::-webkit-scrollbar': {
          width: '0.5em'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#FFF`,
          borderRadius:    10
        }
    },
    filter: {
        position: 'absolute',
        left:     0,
        height:   '100vh',
        width:    '100vw',
        zIndex:   0
    },
    modal: {
        marginLeft:      10,
        display:         'flex',
        //justifyContent:  'space-evenly',
        justifyContent:  'space-between',
        flexFlow:        'column',
        backgroundColor: '#FFF',
        border:          '1px solid #DDD',
        borderRadius:    '0.25em',
        boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)',
        zIndex:          1
    },
    title: {
        display:         'flex',
        width:           '80%',
        marginLeft:      '10%',
        justifyContent:  'center',
        flexFlow:        'column',
        fontSize:        17,
        lineHeight:      1.6,
    },
    row: {
        width:   '100%',
        display: 'flex',
      //  flex: 1,
      //  border: '1px dashed #F00',
      //  minHeight: 50
    },
    closeAccount: {
        fontWeight: 'bold',
        height:     70
    },
    inputContainer: {
        marginBottom:    15,
        height:          100,
        //width:          '75%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-between',
        textAlign:       'center',
        flexFlow:        'column'
    },
    learnMore: {
        marginLeft:      '50%',
        width:           200,
        height:          45,
        transform:       'translate(-50%, -50px)',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        backgroundColor: colors.blue,
        color:           '#FFF',
        fontWeight:      'bold',
        fontSize:        17,
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }

    },
    loaderContainer: {
        position:        'absolute',
        border:          '1px solid transparent',
        borderRadius:    '0.25em',
        backgroundColor: '#FFF',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center'
    },
    empty: { 
       flex:      1, 
       textAlign: 'center'
    },
    showFollowLink: {
        flex:            0.7,
        width:           '90%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        textDecoration:  'none',
        paddingLeft:     25,
        paddingRight:    25,
        fontSize:        22,
    
      },
      link: {
        textDecoration: 'none',
        maxWidth:       '100%',
        textAlign:      'center',
        wordBreak:      'break-all',
        fontSize:       17,
      }
}))


type ModalType = {
    size:                   number[];
    showModal:              boolean;
    showButtons:            boolean;
    guest:                  boolean;
    studentLoggedIn:        boolean;
    wideScreen:             boolean;
    contentLoaded:          boolean;
    tester:                 string;
    modalVariant:           string;
    groupName:              string;
    userAccess:             userAccessType;
    userAttributes:         userAttributesType;
    planType:               planTypeType;
    userInfo:               userInfoType;
    componentsState:        componentsStateType;
    setPlanType:            (val: planTypeType) => void;
    setShowPayForm:         (val: boolean) => void;
    completeSignUp:         () => void;
    goToGroupContentCB:     () => void;
}

const Modal: React.FC<ModalType> = ({ 
    size,
    showModal,
    showButtons,
    modalVariant,
    groupName,
    tester,
    guest,
    studentLoggedIn,
    wideScreen,
    contentLoaded,
    userAccess,
    userAttributes,
    userInfo,
    planType,
    componentsState,
    setPlanType,
    setShowPayForm,
    completeSignUp,
    goToGroupContentCB
}) => {


    //showFollowLink
  const { classes } = useStyles();

  const [groupStr, setGroupStr]                     = useState('');
  const [groupSearch, setGroupSearch]               = useState('');
  const[error, setError]                            = useState('');
  const[lessonNumber, setLessonNumber]              = useState('');
  const [teacherCredentials, setTeacherCredentials] = useState(initialTeacherCredentials);


  const displayLoader = useMemo(() => {
        return (/*!contentLoaded || */(modalVariant === 'loader'))
  },[/*contentLoaded, */modalVariant])


  const title = useMemo(() => {

    if (modalVariant === 'deactivationSuccess') {
        const text = deactivationSuccess();
        return text;
    }
    if (modalVariant === 'closeAccount') {
        const text = subDeactivation();
        return text;
    }

    if (modalVariant === 'expirationWarning') {
        const text = expirationWarning(userInfo.balance);
        return text;
    }

    if (texts[modalVariant]) {
      return texts[modalVariant];
    }

    return [];
    
  },[modalVariant, userInfo])


  const [rootStyle, modalStyle, rowStyle, /*titleStyle*/] = useMemo(() => {

    const textAlign: textAlignType = title.length > 2 ? 'left' : 'center';

    const helpHeights: { [key: string]: number; } = {
        apps:              460,
        games:             460,
        bonus:             studentLoggedIn ? 350 : 690,
        lessons:           540,
        favorites:         600,
        groupLessons:      420,
        userLessons:       420,
        myStudentsContent: 700,
        bigContentArr:     460
     //   introVideo:        ['Introduction Video']
     }

    const height: { [key: string]: number } = {
        "subscribe":        580,
        "showSuspendedSub": 500,
        "updateUserName":   500,
     //   "selectPlan":       610,
        "selectPlan":       500,
        "Contact us":       580,
        "groups":           465,
        "emptyGroup":       465,
        "tutorialMenu":     700,
        "help":             !!helpHeights[componentsState.selectedContent] ? helpHeights[componentsState.selectedContent] : 460
    };

    const width: { [key: string]: number } = {
        "tutorialMenu": 550
    };

    return [
       {
          opacity:   showModal ? 1 : 0,
          top:       showModal ? 0 : '150vh',
       },
       {
          width:      Math.min(( width[modalVariant] ? width[modalVariant] : 630 ), size[0] - 30),
          height:     Math.min(( height[modalVariant] ? height[modalVariant] : 400 ), size[1] - 50),
       },
       {
          alignItems:      title.length > 2 ? 'flex-start' : 'center',
          justifyContent:  title.length > 2 ? 'flex-start' : 'center',
          textAlign:       textAlign,
          flex:            modalVariant === 'showFollowLink' ? undefined : 1
       },
    //    {
    //     flex: modalVariant === 'showFollowLink' ? undefined : 1
    //   }
    ]
   },[size, modalVariant, title, showModal, componentsState, studentLoggedIn])

   

   const goToGroupFunc = () => {
    goToGroup(groupStr, groupName, userAccess.subscriptionStatus, setGroupSearch, setGroupStr, goToGroupContentCB)
   }

   const handleClickAway = () => {
        if (!['loader', 'generalError'].includes(modalVariant)) {
            closeModal()
        }
   }

  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
    >
        <div 
           className = { classes.filter }
           style     = { rootStyle }
           onClick   = { handleClickAway }
        />
        <div 
           className = { classes.modal }
           style     = { modalStyle }
        >
            <div 
               className = { classes.title }
               style     = {{ 
                   flex: title.length ? 1 : 0
               }} 
            >
                {
                    modalVariant === 'groups' && <div style = { rowStyle }/>
                }
                { 
                  title.length > 0 && title.map((val, ind) => {
                      return(
                         <div
                            key       = { val[0].length ? `${ val[0] }${ ind }` : `___${ ind }` }
                            className = { (!ind && modalVariant === "closeAccount") ? clsx(classes.row, classes.closeAccount) : classes.row }
                            style     = { rowStyle }
                         >
                            { val }
                         </div>
                      );
                  }) 
                }
                {
                modalVariant === 'showFollowLink' &&
                    <div className = { classes.showFollowLink }>
                        <a
                          className = { classes.link }
                          href      = { componentsState.followLink }
                        // target    = 'blank'
                        >
                        { `${ componentsState.followLink }` }
                        </a>
                    </div>
                }
            </div>
            {
                (modalVariant === 'my account' || modalVariant === 'showSuspendedSub')
                &&
                <SubDetails userInfo = { userInfo }/>
            }
            {
                modalVariant === 'subscribe'
                &&
                <Pitch userInfo = { userInfo }/>
            }
            {
                modalVariant === 'selectPlan'
                &&
                <PlanSelector
                   tester         = { tester }
                   guest          = { guest }
                   wideScreen     = { wideScreen }
                   height         = { modalStyle.height }
                   userInfo       = { userInfo }
                   planType       = { planType }
                   userAccess     = { userAccess }
                   setPlanType    = { setPlanType }
                   setShowPayForm = { setShowPayForm }
                   completeSignUp = { completeSignUp }
                />
            }
            {
                (modalVariant === 'groups' || modalVariant === 'emptyGroup')
                &&
                <TextInput
                   variant       = { modalVariant }
                   groupStr      = { groupStr }
                   groupSearch   = { groupSearch }
                   setGroupStr   = { setGroupStr }
                />
            }
            {
                ['join lesson', 'followLinkNotFound'].includes(modalVariant) && !displayLoader &&
                <JoinLessonInput
                    error           = { error }
                    lessonNumber    = { lessonNumber }
                    setLessonNumber = { setLessonNumber }
                    setError        = { setError }
                />
            }
            {
                (modalVariant === 'studentLogin')
                &&
                <StudentLogin
                   teacherCredentials    = { teacherCredentials }
                   setTeacherCredentials = { setTeacherCredentials }
                   handleSubmit          = { () => {} }
                />
            }
            {
                    modalVariant === 'updateUserName'
                    &&
                    <UserNameForm size = { size }/>
            }
            {
                    modalVariant === 'Contact us'
                    &&
                    <MessageForm size = { size }/>
            }
            {
                    modalVariant === 'tutorialMenu'
                    &&
                    <TutorialMenu height = { modalStyle.height }/>
            }
            {
                    modalVariant === 'help'
                    &&
                    <Help height = { modalStyle.height }/>
            }
            {
                showButtons && !displayLoader
                &&
                <Buttons
                   variant         = { modalVariant }
                   userAccess      = { userAccess }
                   userAttributes  = { userAttributes }
                   guest           = { guest }
                   tester          = { tester }
                   planType        = { planType }
                   groupStr        = { groupStr }
                   lessonNumber    = { lessonNumber }
                   setError        = { setError }
                   setShowPayForm  = { setShowPayForm }
                   completeSignUp  = { completeSignUp }
                   goToGroup       = { goToGroupFunc }
               />
            }
            {
                displayLoader
                &&
                <div 
                   className = { classes.loaderContainer }
                   style     = { modalStyle }
                >
                   <Loader/>
                </div>
            }
        </div>
    </div>
  );
}



const mapStateToProps = (reducer: reducerType) => {

    return {
     modalVariant:    reducer.modal.modalVariant,
     showModal:       reducer.modal.showModal,
     showButtons:     reducer.modal.showButtons,
     
     userInfo:        reducer.user.userInfo,
     studentLoggedIn: reducer.user.studentLoginState.loginState === 'success',

     contentLoaded:   reducer.data.dataUpdateState.final,

     componentsState: reducer.components,

  }};
  
  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  });
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Modal);