import { call, take, put }          from 'redux-saga/effects';
import axios                        from 'axios';
import { SET_LESSONS }              from 'actions/content_actions';
import { RECORD_ERROR }             from 'actions';
import mockLessons_                 from 'content/mockContent/lessons';
const lessonsEndpoint     = 'https://www.yisumatica.org.il/api/getLessonsEng.php';


function* getLessons(): Generator<any,any,any> {

  while(true) {
    try {
        const input = yield take('GET_LESSONS_SAGA');

        console.log('GET_LESSONS_SAGA');

        const { userEmail } = input.payload;

        if ( process.env.NODE_ENV === 'development' ) {

          const payload = { lessons: mockLessons_ }

          yield put(SET_LESSONS( payload ))
        
        }


        if (process.env.NODE_ENV === 'production') {

                const lessons = yield call( axios.post, lessonsEndpoint );

                if ( lessons && lessons.data && lessons.data.result ) {

                    const lessonsFinal: { [key: string]: any }[] = lessons.data.result;
                    const payload = { lessons: lessonsFinal }
  
                    yield put(SET_LESSONS( payload ))

                   

                }
                if ( lessons && lessons.data && !Boolean(lessons.data.success) && lessons.data.msg ) {
                  yield put(RECORD_ERROR({ userEmail: userEmail, err: lessons.data.msg, endpoint: 'get_lessons' }));
                }

        }
      
      

    }
    catch(err) {
      console.log('err at get content saga  :  '+err);
    }
    finally {}
  }
}


export default getLessons;
