
const primary = '#3677CB';
//const primary = '#3A7BCF';



export const colors = {
    primary:       primary,
    primaryDark:   '#1657AB',
    red:           '#E34',
    primaryBright: '#0647FF33',
    secondary:     '#D55950',
    darkText:      '#445',
    brightText:    '#99A',
    hoverText:     '#BBC',
    blueText:      primary,
    blue:          '#3351AB',
    button:        '#DDDDEA',
    menu:          primary,
    gridUnit:      primary,
    gridUnitRed:   '#F44',
    navBar:        '#FF7666',
    menuText:      '#334',
    brightGrey:    '#F8F8F9',
    brightGrey2:   '#EEEEF1',
    brightGrey3:   '#E9E9EF',
    temp:          primary,
    toolTip:       '#111111B0'
  }

export const categories: { [key: string]: any } = {
 // "apps":           ['App',                   "#78E"],
 //"apps":           ['App',                   "#00BFB6"],
 "apps":           ['App',                   "#7D94EF"],
 "lessons":        ['Lesson',                "#44BF90"],
  "puzzles":        ['Puzzle',                "#9091C2"],
  "interaction":    ['Special Lesson',        "#449CFF"],
  "teacherLessons": ['Lesson',                "rgb(68, 191, 144)"],
  "teacherContent": ['teacherContent',        "#EE5C60"],
  "mivdakim":       ['Test',                  "#FF7063"],
  //"denyAccess":     ['Subscrbers Only',       "#F05850"]
  "denyAccess":     ['Subscrbers Only',       "#EF585D"]
}


/**
 * 
 * 

export const categories: { [key: string]: any } = {
   "apps":           ['app',           "#78E"],
  "lessons":        ['lesson',        "#44BF90"],
  "puzzles":        ['puzzle',        "#9091C2"],
  "interaction":    ['interact',      "#3BD"],
  "teacherLessons": ['teacherLesson', "#EE5C60"],
  "teacherContent": ['teacherContent',"#EE5C60"],
  "mivdakim":       ['mivdak',        "#FF8073"],
}




 */