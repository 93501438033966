import React               from 'react';
import { connect }         from 'react-redux';
import { reducerType }     from '../../reducer';
import { Link }            from "react-router-dom";
import { contentInfoType } from '../../types';
import { colors }          from '../../utils/colors';
import { sizes }           from '../../utils/defaultStates';
import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
       position:        'absolute',
       height:          '100vh',
       borderRight:     '1px solid #CCC',
       backgroundColor: colors.brightGrey,
    },
    description: {
      position:        'absolute',
      overflowY:       'scroll',
      overflowX:       'hidden',
      paddingLeft:     30,
      paddingRight:    30,
      left:            0,
      borderTop:       `1px solid #DDD`,
      lineHeight:      2,
      letterSpacing:   2,
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
      }
    },
    buttonContainer: {
      borderTop:       `1px solid #DDD`,
      width:           '100%',
      left:            0,
      position:        'absolute', 
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-around',
      flexFlow:        'column',
    },
    /* button: {
      height:          35,
      width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      color:           '#FFF',
      fontSize:        18,
      backgroundColor: colors.primary,
      borderRadius:    '0.2em',
      '&:hover': {
         opacity: 0.5
      }
    }, */
    buttonBright: {
      height:          35,
    //  marginLeft:       50,
      width:           '100%',
      display:         'flex',
      alignItems:      'center',
     // justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
  //    fontWeight:      'bold',
     // backgroundColor: '#FFF',
   //   fontSize:        20,
   fontSize:       19,
    //  color:           colors.primary,
      borderRadius:    '0.2em',
      '&:hover': {
         opacity: 0.5
      }
    },
    backIcon: {
       fontSize: 32,
       transform: 'rotate(90deg)'
    },
    link: {
      textDecoration: 'none',
      color:          colors.darkText
    }
}))

type SideBarProps = {
    app:             contentInfoType;
    size:            number[];
    menuSize:        number;
    showFAppButton:  boolean;
    studentLoggedIn: boolean;
    directPageLink:  boolean;
    handleClose:     () => void;
    goTofather_app:  () => void;
}
const SideBar: React.FC<SideBarProps> = ({ 
    app,
    size,
    menuSize,
    showFAppButton,
    studentLoggedIn,
    directPageLink,
    handleClose,
    goTofather_app
}) => {

  const { classes } = useStyles();

  const [buttonContainerStyle, buttonStyle, descriptionStyle] =  React.useMemo(() => {

   const buttonHeight = 50;
   const buttonContainerHeight = 20 + (showFAppButton ? buttonHeight * 2 : buttonHeight);
   const { navBarSize, footerSize } = sizes;

   return [
      {
         top:    0,
         height: buttonContainerHeight,
         width:  menuSize,
      },
      {
         width:  menuSize - 60,
      },
      {
         top:    showFAppButton ? buttonContainerHeight : buttonContainerHeight,
         height: size[1] - navBarSize - footerSize - buttonContainerHeight - 4,
         width:  menuSize - 60,
      }
      
   ]
  },[showFAppButton, size, menuSize])

  
  return (
   
    <div 
       className = { classes.root }
       style     = {{
           width: menuSize
       }}
    >
       <div 
             className = { classes.buttonContainer }
             style     = { buttonContainerStyle }
      >
             <Link 
               replace
               className = { classes.link }
               to        = '/'
               onClick   = { handleClose }
             >
                  <div 
                     className = { classes.buttonBright }
                  //   onClick   = { handleClose }
                     style     = { buttonStyle }
                  >
                     { directPageLink ? 'All Content' : 'Go Back' }
                  </div>
             </Link>
             {
                showFAppButton
                &&
                <div 
                className = { classes.buttonBright }
                onClick   = { goTofather_app }
                style     = { buttonStyle }
                >
                  Source App
                </div>
             }
          </div>
          {
            ((studentLoggedIn && !!app.student_text) || (!studentLoggedIn && !!app.teacher_text))
            &&
            <div 
               className = { classes.description }
               style     = { descriptionStyle }
            >
                <br/>

                { studentLoggedIn ? app.student_text : app.teacher_text }
             </div>
          }
          
    </div>
  );
}



const mapStateToProps = (reducer: reducerType) => {
   return {
     directPageLink: reducer.components.directPageLink
 }};
 
 export default connect(mapStateToProps)(SideBar);
 