import React          from 'react';
//import { colors }     from '../utils/colors';
import { makeStyles } from 'makeStyles';

var clsx = require('classnames')

const useStyles = makeStyles()((Theme) => ({    
    root: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        width:          60,
        height:         60,
        animation:      'load 1.4s linear infinite',
    },
    root2: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        width:          40,
        height:         40,
        animation:      'load 1.4s linear infinite',
    },
    root3: {
        width:          20,
        height:         20,
        animation:      'load 1.4s linear infinite',
    },
    white: {
        border: '1px solid #FFF'
    },
    blue: {
        border: `1px solid #77A`
    }
}))

type loaderProps = {
    variant?: 'white' | 'blue';
}

const Loader: React.FC<loaderProps> = ({ variant }) => {

  const { classes } = useStyles();

  const cls = React.useMemo(() => {
      return variant === 'white' ? classes.white : classes.blue;
  },[variant, classes.white, classes.blue])
  
  return (
        <div className = { clsx(cls, classes.root) }>
           <div className = { clsx(cls, classes.root2) }>
              <div className = { clsx(cls, classes.root3) }/>
           </div>
        </div>
    );
}

export default Loader;





