import React            from 'react';
import { connect }      from 'react-redux';
import { Dispatch }     from "redux";
import { 
  useNavigate,
  useLocation
}                       from "react-router-dom";
import { ActionTypes }  from 'actions/types';
import { reducerType }  from 'reducer';
import tutorialsArr     from 'content/tutorials';
import tutorialClick    from 'functions/navBar/tutorial_click';
import closeModal       from 'functions/modal/close_modal';
import { colors }       from 'utils/colors';
import { makeStyles }   from 'makeStyles';


const sections: { [key: string]: string } = {
    "0": "General",
    "2": "Toolbar",
    "4": "Right toolbar - segments",
}



const useStyles = makeStyles()(
  (Theme) => ({    container: {
        width:           '100%',
        backgroundColor: '#FFF',
    },
    inner: {
        color:           colors.darkText,
        backgroundColor: colors.brightGrey,
        fontSize:        18,
        overflowY:       'scroll',
        overflowX:       'hidden',
        borderBottom:    '1px solid #DDD',
        zIndex:           10,
        '&::-webkit-scrollbar': {
            width:      '0.5em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `#BBB`,
            borderRadius:    10
          }
    },
    title: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        height:          80,
        fontSize:        17,
        fontWeight:      'bold',
        borderBottom:    '1px solid #DDD'
    },
    section: {
        marginTop:    25,
        marginBottom: 10,
        marginLeft:   '7%',
        fontSize:     16,
        fontWeight:   'bold',
        cursor:       'default',
        color:        colors.primary,
    },
    item: {
        display:         'flex',
        alignItems:      'center',
        backgroundColor: '#FFF',
        borderRadius:    '0.4em',
        height:          40,
        width:           '86%',
        marginTop:       8,
        marginLeft:      '7%',
        border:          `1px solid #DDD`,
        fontSize:        16,
     //   boxShadow:       '0px 3px 8px rgba(0,0,0,0.05)',
        cursor:          'pointer',
        '&:hover': {
            backgroundColor: colors.brightGrey3
        }
    },
    tutorialName: {
        marginLeft: 15
    }
}))

type TutorialMenuProps = {
  height: number;
}

const TutorialMenu: React.FC<TutorialMenuProps> = ({
  height
}) => {
  
  const { classes } = useStyles();
  const navigate    = useNavigate();
  const location    = useLocation();


  const handleTutorialClick = (ind: number) => {
    if ( location.pathname !== '/') {
      navigate('../', { replace: true });
    }
    tutorialClick('tutorials', ind)
    closeModal();
  }


  const [containerStyle, innerStyle] = React.useMemo(() => {
    return[
       {
        height: height - 82
       },
       {
        height: height - 150
       }
    ]
  },[height])
  
  
  return (
    <div 
        className = { classes.container }
        style     = { containerStyle }
    >
        <div className = { classes.title }>
           Video Tutorials
        </div>
        <div 
          className = { classes.inner }
          style     = { innerStyle }
        >
            {
                tutorialsArr.map((val, ind) => {
                    return(
                    <div key = { `tutorialsVideo${ val }${ ind }` }>
                        {
                            (!ind || ind === 2 || ind === 4) &&
                            <div className = { classes.section }>
                                { sections[`${ ind }`] }
                            </div>
                        }
                        <div 
                            className = { classes.item }
                            onClick   = { () => { handleTutorialClick(ind) }}
                            style     = {{ marginBottom: ind === tutorialsArr.length - 1 ? 20 : undefined }}
                        >
                            <div className = { classes.tutorialName } >
                               { val.title }
                            </div>
                        </div>
                    </div>
                    )
                })
            }
        </div>
    </div>
  );
}



const mapStateToProps = (reducer: reducerType) => {


  return {
    //componentsState: reducer.components,
    //userInfo:        reducer.user.userInfo,
    //studentLoggedIn: reducer.user.studentLoginState.loginState === 'success',

}};


const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
 // setComponentsState: (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload))
});



export default connect(mapStateToProps, mapDispatchToProps)(TutorialMenu);







