import { studentLoginStateType } from 'types';
import { words }                 from 'utils/dictionary'

const topics = ['שלמים' ,'גיאומטרייה' ,'שבר'];
const other  = "אחר";

const createAppArr = (
   bigContentArr:     { [key: string]: any }[],
   apps:              { [key: string]: any }[],
   lessons:           { [key: string]: any }[],
   puzzles:           { [key: string]: any }[],
   myStudentContent:  { [key: string]: any }[],
   userLessons:       { [key: string]: any }[],
   groupLessons:      { [key: string]: any }[],
   studentLoginState: studentLoginStateType,
   selectedContent:   string, 
   selectedTopic:     string,
   selectedSubTopic:  string,
   selectedClasses:   string, 
   searchStr:         string, 
) => {
  
        const content = searchStr.length ? bigContentArr :
                        studentLoginState.loginState !== 'success' && (selectedContent === 'apps' || selectedContent === 'content') ? apps :
                        selectedContent === 'bonus' ? puzzles :
                        selectedContent === 'puzzles' ? puzzles :
                        selectedContent === 'lessons' ? lessons :
                        selectedContent === 'MyStudentContent' ? myStudentContent :
                        selectedContent === 'userLessons' ? userLessons :
                        selectedContent === 'groupLessons' ? groupLessons :
                        studentLoginState.loginState === 'success' && (selectedContent === 'apps')             ? studentLoginState.studentOpenApps.concat(studentLoginState.myTeacherContent) :
                        studentLoginState.loginState === 'success' && (selectedContent === 'studentApps')      ? studentLoginState.studentOpenApps :
                        studentLoginState.loginState === 'success' && (selectedContent === 'myTeacherContent') ? studentLoginState.myTeacherContent :
                        bigContentArr;

        const topTopic = (!selectedTopic || (!topics.includes(selectedTopic))) ? '' : (words[selectedTopic] ? words[selectedTopic]['heb'] : selectedTopic);
        const subTopic = !selectedSubTopic ? '' : (words[selectedSubTopic] ? words[selectedSubTopic]['heb'] : selectedSubTopic);
        const arr = [];


        for (let i = 0; i < content.length; i++) {

          // hide some content

          let hide = false;

         /*  if ((content[i].display_app_eng && content[i].display_app_eng === 'false')) {
            hide = true;
          } */
    
          // filter by grade
    
          let classIsSelected    = (selectedClasses.length === 7 || selectedContent !== 'apps');
          let nameIsSelectd      = !searchStr.length;
          let topicIsSelected    = !selectedTopic.length;
          let subtopicIsSelected = !selectedSubTopic.length;
          let appTypeIsSelected  = selectedContent !== 'games';
    
          if (!classIsSelected && content[i].classes) {
    
               for (let j = 0; j < content[i].classes.length; j++) {
                 if (content[i].classes[j] === selectedClasses) {
                   classIsSelected = true;
                   break;
               }
             }
          }

    
          // filter by name or searchwords

          const appName     = content[i].app_eng ? content[i].app_eng : content[i].name ? content[i].name : "";
          const searchWords = content[i].search_words_eng ? content[i].search_words_eng : '';
          const puzzleTopic = content[i].origin === 'puzzles' ? content[i].topic : '';
    
          if (
               (!nameIsSelectd && appName.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()))
               ||
               (!nameIsSelectd && searchWords.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()))
               ||
               (!nameIsSelectd && puzzleTopic.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()))
               ) {
               nameIsSelectd = true;
             }
    
          // filter by topic

          const appTopic__ = content[i].top_topic ? content[i].top_topic : other;
    
          if (!topicIsSelected) {
            if (selectedTopic === other && !topics.includes(content[i].top_topic)){
              topicIsSelected = true;
            }
            else if (!!topTopic && appTopic__.toLocaleLowerCase().includes(topTopic.toLocaleLowerCase())){
              topicIsSelected = true;
            }
          }
          
          


          // filter by sub-topic


          const appTopic = content[i].topic ? content[i].topic : appTopic__;
    
          if (!subtopicIsSelected && appTopic.toLocaleLowerCase().includes(subTopic.toLocaleLowerCase())) {
            subtopicIsSelected = true;
          }


          // filter by appType

          if (!appTypeIsSelected) {
            if ( !!content[i].app_type && content[i].app_type === 1 ) {
              appTypeIsSelected = true;
            }
          }
    
          // create result
    
          if (
               !hide &&
               classIsSelected &&
               nameIsSelectd &&
               topicIsSelected &&
               subtopicIsSelected &&
               appTypeIsSelected
            ) {
            arr.push({
               app:                content[i].app ?                content[i].app                : "",
               app_eng:            content[i].app_eng ?            content[i].app_eng            : "",
               display_app_eng:    content[i].display_app_eng ?    content[i].display_app_eng    : "",
               lesson_id:          content[i].lesson_id ?          content[i].lesson_id          : "",
               uid:                content[i].uid ?                content[i].uid                : "",
               classes:            content[i].classes ?            content[i].classes            : "",
               description:        content[i].description ?        content[i].description        : "",
               description_eng:    content[i].description_eng ?    content[i].description_eng    : "",
               search_words:       content[i].search_words ?       content[i].search_words       : "",
               search_words_eng:   content[i].search_words_eng ?   content[i].search_words_eng   : "",
               youtube:            content[i].youtube ?            content[i].youtube            : "",
               youtube_eng:        content[i].youtube_eng ?        content[i].youtube_eng        : "",
               father_app:         content[i].father_app ?         content[i].father_app         : "",
               father_app_heb:     content[i].father_app_heb ?     content[i].father_app_heb     : "",
               father_app_ar:      content[i].father_app_ar ?      content[i].father_app_ar      : "",
               origin:             content[i].origin ?             content[i].origin             : "",
               img_id:             content[i].img_id ?             content[i].img_id             : "",
               lessontype:         content[i].lessontype ?         content[i].lessontype         : "",
               name:               content[i].name ?               content[i].name               : "",
               topic:              content[i].topic ?              content[i].topic              : "",
               top_topic:          content[i].top_topic ?          content[i].top_topic          : "",
               app_arabic:         content[i].app_arabic ?         content[i].app_arabic         : "",
               description_arabic: content[i].description_arabic ? content[i].description_arabic : "",
               puzzle_id:          content[i].puzzle_id ?          content[i].puzzle_id          : "",
               student_page:       content[i].student_page ?       content[i].student_page       : "",
               liked:              content[i].liked ?              content[i].liked              : 0,
               selected:           content[i].selected ?           content[i].selected           : 0,
               viewed:             content[i].viewed ?             content[i].viewed             : 0,
               totalLikes:         content[i].totalLikes ?         content[i].totalLikes         : 0,
               app_type:           content[i].app_type ?           content[i].app_type           : 0,
            });
          }
        }

        
    
       return arr;
    
}




export default createAppArr;




































