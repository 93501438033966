import React                     from 'react';
import { makeStyles }            from 'makeStyles';
import { colors }                from '../../../utils/colors';

var clsx = require('classnames')

const useStyles = makeStyles()((Theme) => ({
    
    gridContainer: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'flex-end',
        flexFlow:       'column',
        width:          '100%',
      //  marginBottom:   25,
        maxWidth:       350
    },
    selectLevel: {
        height:         50,
        fontSize:       20,
        color:          colors.primary,
        fontWeight:     'bold',
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
    grid: {
        //height:              130,
        gap:                 '10px 10px',
        display:             'grid',
        gridTemplateColumns: `repeat(3, 1fr)`,
        width:               '100%',      
    },
    button: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        borderRadius:    '0.5em',
        fontWeight:      'bold',
        border:          `1px solid ${ colors.primary }`,
    },
    selected: {
        color:           '#FFF',
        backgroundColor: colors.primary,
    },
    unSelected: {
        color:           colors.primary,
        backgroundColor: '#FFF',
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }
    }
}))

type LevelGridProps = {
    variant:          string;
    level:            number;
    setLevel:         (val: number) => void
}

const LevelGrid: React.FC<LevelGridProps> = ({
    variant,
    level,  
    setLevel
  }) => {

  const { classes } = useStyles();

  return (
   
       <div className = { classes.gridContainer }>
            <div className = { classes.selectLevel }>Select Level</div>
            <div 
               className = { classes.grid }
               style     = {{
                 height: variant === '!widescreen' ? 90 : 130
               }}
            >
                {
                    new Array(6).fill(0).map((val, ind) => {
                        return(
                            <div
                               key       = { `${ variant }challenge${ ind }?` }
                               className = { clsx(classes.button, level === ind + 1 ? classes.selected : classes.unSelected) }
                               onClick   = { () => { setLevel(ind + 1) } }
                            >
                                { ind + 1 }
                            </div>
                        )
                    })
                }
            </div>
        </div>
  );
}

export default LevelGrid;
