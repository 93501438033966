import React, { Suspense }       from 'react';
import { Dispatch }              from "redux";
import { Routes, Route }         from "react-router-dom";
import { ActionTypes }           from 'actions/types';
import { connect }               from 'react-redux';
import AppIframe                 from 'components/appIframe';
import Grid                      from './Grid';
import { reducerType }           from 'reducer';
import navBarClick               from 'functions/navBar/nav_bar_click';
import PracticeComp              from 'components/practice_component'
import TopicIntro                from 'components/topic_intro';
import Loader                    from 'components/Loader';
import { 
    contentInfoType,
    userInfoType,
}                                from 'types'
import { colors }                from 'utils/colors';
import { sizes }                 from 'utils/defaultStates';
import { makeStyles }            from 'makeStyles';
import { 
  SET_SHOW_PRACTICE_COMPONENT, 
  SET_SHOW_TOPIC_INTRO 
}                                from 'actions/component_actions';
import { SELECT_CONTENT }        from 'actions/content_actions';
import recordEvent               from 'functions/contentInteraction/record_event';
import { initialChallengeState } from 'reducer/challengesReducer';
import { SET_CHALLENGE_STATE, SET_PRACTICE_STATE } from 'actions/challenges_actions';


let eArr = new Array(16).fill('e').map((val, ind) => { return(`${ val }${ ind + 1 }`) })
 
const useStyles = makeStyles()(
  (Theme) => ({    root: {
      position:        'absolute',
      left:            0,
      top:             0,
      height:          '100vh',
      width:           '100vw',
      backgroundColor: colors.brightGrey,
    },
    fallBack: {
      position:        'absolute',
      right:           0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'transparent',
    },
    hidden: {  
      top:      '-300vh',
      left:     '-300vw',
      position: 'fixed',
      height:   100,
      width:    100, 
      opacity:  0,
    }
}))


type BonusGridProps = {
  puzzles:             contentInfoType[];
  challenges:          contentInfoType[];
  topicIntros:         contentInfoType[];
  questions:           contentInfoType[];
  topicIntrosHash:     contentInfoType;
  selectedApp:         contentInfoType;
  selectedPuzzle:      contentInfoType;
  userInfo:            userInfoType;
  selectedAppIntro:    string;
  practiceVariant:     string;
  size:                number[];
  menuSize:            number;
  wideScreen:          boolean;
  showLoader:          boolean;
  showPracticeComp:    boolean;
  studentLoggedIn:     boolean;
  showTopicIntro:      boolean;
  guest:               boolean;
  setShowTopicIntro:   (payload: boolean) => void;
  setShowPracticeComp: (payload: boolean) => void;
  setChallengeState:   (val: contentInfoType) => void;
  setChangeContent:    (val: boolean) => void;
  setSelectedContent:  (val: contentInfoType) => void;
  setPracticeState:    (val: contentInfoType) => void;
  selectContent:       (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
}


const BonusGrid: React.FC<BonusGridProps> = ({
    userInfo,
    puzzles,             
    selectedApp,         
    size,                
    menuSize,                    
    wideScreen,          
    showLoader,          
    showPracticeComp,    
    studentLoggedIn,   
    practiceVariant,  
    challenges,          
    topicIntros,         
    questions,           
    topicIntrosHash,     
    selectedAppIntro,   
    showTopicIntro,
    selectedPuzzle,
    guest,
    setShowTopicIntro,
    setChallengeState,
    setChangeContent,      
    setShowPracticeComp, 
    setSelectedContent, 
    setPracticeState,     
    selectContent      
}) => {

  const { classes } = useStyles();

  //const [practiceVariant, setPracticeVariant] = React.useState('puzzle')

  const bonusContent = React.useMemo(() => {
    return challenges.concat(puzzles)//.concat(topicIntros)
  },[puzzles, /* topicIntros,  */challenges])

/* 
  React.useEffect(() => {
    if ( !!Object.keys(selectedPuzzle).length ) {
      setSelectedApp(selectedPuzzle)
    }
    
  },[setSelectedApp, selectedPuzzle]) */


  const intro = React.useMemo(() => {
    return !!topicIntrosHash[selectedAppIntro] ? topicIntrosHash[selectedAppIntro] : topicIntros[0]
  },[topicIntrosHash, selectedAppIntro, topicIntros])


  const practiceQuestionSet = React.useMemo(() => {

    let arr = [];

    if (showLoader) {
      return [];
    }


    for (let i = 0; i < questions.length; i++) {

       if (!!intro.sub_topic && !!questions[i].practice_set && intro.sub_topic === questions[i].practice_set) {
         arr.push(questions[i])
       }
    }

    return arr;
    

  },[intro, questions, showLoader])




  const puzzleQuestionSet = React.useMemo(() => {

    if (!Object.keys(selectedPuzzle)) {
      return [];
    }

    const description_teacher = !!selectedPuzzle.description_teacher ? selectedPuzzle.description_teacher : '';
    const description_student = !!selectedPuzzle.description_student ? selectedPuzzle.description_student : '';
    const img                 = !!selectedPuzzle.img                 ? selectedPuzzle.img                 : '';


    const initialObj = {
       question:       studentLoggedIn ? description_student : description_teacher,
       image:          `${ img }.JPG`,
       question_type:  "puzzle",
       answer_choices: "",
       correct_answer: "",
       practice_set:   selectedPuzzle.puzzle_type_text
    }


    let arr = [initialObj] as contentInfoType[];
   
    for ( let i = 0; i < eArr.length; i++ ) {

      if ( !!selectedPuzzle[`${ eArr[i] }`] ) {

        const text     = selectedPuzzle[`${ eArr[i] }`];
        const question = text.slice(text.indexOf("]")+1);
        const image    = text.slice(1,text.indexOf("]"));

     

        const newObj = {
          question:       question,
          image:          `${ image }.JPG`,
          question_type:  "puzzle",
          answer_choices: "",
          correct_answer: "",
          practice_set:   selectedPuzzle.puzzle_type_text
        }

        arr.push(newObj)

      }
    }
    
    return arr;

  },[selectedPuzzle, studentLoggedIn])



  const questionSet = React.useMemo(() => { 
    return practiceVariant === 'puzzles' ? puzzleQuestionSet : practiceQuestionSet 
  },[practiceVariant, puzzleQuestionSet, practiceQuestionSet])


  const handleClick = (val: contentInfoType) => {

    const variant = !!val.origin && val.origin === 'puzzles'     ? 'puzzles' : 
                    !!val.origin && val.origin === 'topicIntros' ? 'practice' : 
                    !!val.origin && val.origin === 'challenges'  ? 'challenges' : 
                    ''

    setPracticeState({ practiceVariant: variant })

    if ( variant === 'puzzles' ) {
      setSelectedContent({ selectedPuzzle: val })
      selectContent(val, 'practiceComp', false, false)
      setShowPracticeComp(true)
    }
    if ( variant === 'practice' ) {
      setSelectedContent({ selectedAppIntro: val.sub_topic })
      setShowTopicIntro(true)
    }
    if ( variant === 'challenges' ) {
      setChallengeState({
        ...initialChallengeState,
        selectedChallenge: val
      }) 
      navBarClick('challenges', setChangeContent); 
      recordEvent('app', 'bonus content selected', 'variables challenge')
    }
  }

  const path = React.useMemo(() => {

    if ( practiceVariant === 'puzzles' && showPracticeComp && !!selectedApp && !!selectedApp.origin && selectedApp.origin === 'puzzles' && !!selectedApp.page) {
      return `/${ selectedApp.page }${ selectedApp.extra_url ? selectedApp.extra_url : '' }`;
    }
    if ( practiceVariant === 'practice' && !!intro && !!intro.sub_topic ) {
      let pth = intro.sub_topic.replaceAll(' ','_')
      if (pth[pth.length - 1] === '?') {
        pth = pth.slice(0, pth.length - 1);
      }
      return `/${ pth }`
    } 
    return '/'
  },[selectedApp, showPracticeComp, practiceVariant, intro])


  return (
         <div className = { classes.root }> 
            {
              !showLoader/*  && (content === 'Apps' || content !== 'Tutorials') */
              &&
              <Suspense 
                fallback = {
                  <div 
                    className = { classes.fallBack }
                    style     = {{
                      top:    sizes.navBarSize,
                      width:  size[0] - menuSize,
                      height: size[1] - sizes.navBarSize - sizes.footerSize
                    }}
                  >
                    <Loader/>
                  </div>
                }
              >
                    <Grid
                        bonusContent      = { bonusContent }  
                        size              = { size }       
                        showPracticeComp  = { showPracticeComp }         
                        showTopicIntro    = { showTopicIntro }
                        menuSize          = { menuSize }                     
                        wideScreen        = { wideScreen }  
                        userInfo          = { userInfo }
                        handleClick       = { handleClick }  
                    />
              </Suspense> 
            }
            {
              showLoader
              &&
              <div 
                    className = { classes.fallBack }
                    style     = {{
                      width:  wideScreen ? size[0] - menuSize : size[0],
                      height: size[1] - sizes.navBarSize - sizes.footerSize,
                    }}
              >
                <Loader/>
              </div>
            }
         {
            showTopicIntro && !showLoader && path !== '/' &&
            <Routes>
                <Route 
                  path    = { path }
                  element = {
                    <TopicIntro
                        app                 = { intro }
                        size                = { size }
                        menuSize            = { menuSize }
                        wideScreen          = { wideScreen }
                        studentLoggedIn     = { studentLoggedIn }
                        disable             = { !practiceQuestionSet.length }
                        setShowPracticeComp = { setShowPracticeComp }
                        setShowTopicIntro   = { setShowTopicIntro }
                    /> 
                  }
                />
                <Route 
                  path    = { '/' }
                  element = { <div/> }
                />
          </Routes>
            }
            <AppIframe
                size           = { size }
                selectedApp    = { selectedApp }
                guest          = { guest }
                setSelectedApp = { setSelectedContent }
            />
            {
              showPracticeComp && !showLoader && path !== '/' &&
              <Routes>
                <Route 
                  path    = { path }
                  element = {
                    <PracticeComp 
                          questionSet         = { questionSet }
                          size                = { size }
                          wideScreen          = { size[0] > 1000 }
                          menuSize            = { menuSize }
                          selectedApp         = { selectedApp }
                          setShowPracticeComp = { setShowPracticeComp }
                          selectContent       = { selectContent }
                      />
                  }
                />
                <Route 
                    path    = { '/' }
                    element = { <div/> }
                />
              </Routes>
            }
         </div>
  );
}




const mapStateToProps = (reducer: reducerType) => {

  const { userInfo, studentLoginState } = reducer.user;
  const { showTopicIntro, showPracticeComp } = reducer.components;
  const { puzzles, studentVideos, challenges, topicIntros, topicIntrosHash, questions, selectedApp, selectedAppIntro, selectedPuzzle } = reducer.content;

  return {
    userInfo:         userInfo,
    studentLoggedIn:  studentLoginState.loginState === 'success',
    puzzles:          puzzles,
    studentVideos:    studentVideos,
    challenges:       challenges,
    topicIntros:      topicIntros,
    topicIntrosHash:  topicIntrosHash,
    questions:        questions,
    showLoader:       (!puzzles.length || !topicIntros.length || !questions.length),
    showTopicIntro:   showTopicIntro,
    showPracticeComp: showPracticeComp,
    selectedApp:      selectedApp,
    selectedAppIntro: selectedAppIntro,
    selectedPuzzle:   selectedPuzzle,
    practiceVariant:  reducer.practice.practiceVariant
}};


const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setShowTopicIntro:   (payload: boolean) => dispatch(SET_SHOW_TOPIC_INTRO(payload)),
  setShowPracticeComp: (payload: boolean) => dispatch(SET_SHOW_PRACTICE_COMPONENT(payload)),
  setSelectedContent:  (payload: contentInfoType) => dispatch(SELECT_CONTENT(payload)),
  setChallengeState:   (payload: contentInfoType) => dispatch(SET_CHALLENGE_STATE(payload)),
  setPracticeState:    (payload: contentInfoType) => dispatch(SET_PRACTICE_STATE(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusGrid);





