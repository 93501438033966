import React          from 'react';
import GridUnit       from './GridUnit';
import Sector         from './Sector';
import { makeStyles } from 'makeStyles';
import { colors }     from 'utils/colors';

var clsx  = require('classnames');
const arr = [0.5, 0.3333333333333333333, 0.66666666666666666666666];

const useStyles = makeStyles()((Theme) => ({    
    root: {
       position:   'absolute',
       transition: 'all 0.6s',
    },
    grid: {
        
    },
    circle: {
        position:       'absolute',
        top:            0,
        width:          '100%',
        height:         '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        transition:     'transform 0.4s',
    },
    equal: {
        fontSize: 40,
        color:    colors.primary
    }
}))

type GridProps = {
    grid:       number[];
    positions:  number[][];
    unit:       number;
    change:     number;
    dimensions: number;
    gridIndex:  number;
    showCircle: number;
    next:       number;
    initialPos: boolean;
}

const Grid: React.FC<GridProps> = ({
    grid, 
    positions, 
    unit, 
    change, 
    dimensions, 
    gridIndex, 
    showCircle,
    next,
    initialPos
}) => {

  const { classes } = useStyles();

  return (
       <div   
          className = { classes.root }
          style     = {{
            width:   unit * 3,
            height:  unit * 2,
            opacity: next ? 0 : 1
        }}
       >
            <div 
               className = { classes.grid }
               style     = {{
                  transform:  `translate(${ showCircle ? -50 : 0 }%, 0%)`,
                  transition: `transform ${ showCircle ? 0.4 : 0 }s`,
               }}
            > 
              {
                grid.map((val, ind) => {
                    return(
                        <GridUnit
                            key        = { `GridUnit${ind}` }
                            positions  = { positions }
                            unit       = { unit }
                            val        = { val }
                            ind        = { ind }
                            change     = { change }
                            initialPos = { initialPos }
                        />
                    )
                })
              } 
            </div>
            <div 
               className = { classes.circle }
               style     = {{
                opacity:   showCircle,
                transform: `translate(${ showCircle ? 50 : 0 }%, 0%)`
            }}
            >
                  <Sector 
                    val         = { arr[gridIndex] }
                    dimensions  = { !showCircle ? 0 : dimensions }
                  />
            </div>
            <div 
               className = { clsx(classes.circle, classes.equal) }
               style     = {{
                opacity: showCircle,
               }}
            >
               =
            </div>
       </div>
  );
}

export default Grid;

