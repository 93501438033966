
import React                from 'react';
import { store }            from 'index';
import { GET_LESSONS_SAGA } from 'actions/content_actions';
import { userAccessType }   from 'types';

const useGetLessons = (
    userEmail:         string,
    guest:             boolean,
    userAccess:        userAccessType,
    getLessonsOnce:    boolean,
    setGetLessonsOnce: (val: boolean) => void
) => {


    const state           = store.getState();
    const userInfoUpdated = state.data.dataUpdateState.user;
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const userInfo        = state.user.userInfo;


    React.useEffect(() => {

      if (userInfoUpdated && !studentLoggedIn && !getLessonsOnce ) {

        const payload = {
          userEmail:       userEmail,
          guest:           guest ? true : false,
          subscriber:      !userAccess.denyAccess || userAccess.freeUser,
          studentOpenApps: userInfo.studentOpenApps
        }
    
        setGetLessonsOnce(true)
        store.dispatch(GET_LESSONS_SAGA(payload))
      }
    
    },[userInfoUpdated, studentLoggedIn, getLessonsOnce, userEmail, guest, userInfo, userAccess, setGetLessonsOnce])

}

export default useGetLessons;