import React          from 'react';
import Head           from './Head';
import { sizes }      from '../../utils/defaultStates';
import { makeStyles } from 'makeStyles';

//var clsx = require('classnames');

const arr = [
  ['https://iseemath.co/images/gali.png', 'Gali Shimoni', 'CEO'], 
  ['https://iseemath.co/images/zvi.jpg',  'Zvi Shalem', 'COO']
]

const useStyles = makeStyles()(
  (Theme) => ({    root: {
        position:       'absolute',
        overflow:       'hidden',
        top:            0, 
        left:           0,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'space-evenly',
        borderRight:    '1px solid #DDD'
    //    border: '1px solid #F00'
    }
}))

type HeadsProps = {
    size:         number[];
    menuSize:     number;
    headsHeight:  number;
    wideScreen:   boolean;
}
const Heads: React.FC<HeadsProps> = ({ size, menuSize, wideScreen, headsHeight }) => {

  const { classes } = useStyles();

  const style = React.useMemo(() => {
    
    return {
      minWidth:    wideScreen ? menuSize : '100vw',
      maxWidth:    wideScreen ? menuSize : '100vw',
      height:      wideScreen ? size[1] - sizes.footerSize - sizes.navBarSize : headsHeight,
      flexFlow:    wideScreen ? 'column' : 'row',
      top:         wideScreen ? -sizes.tabSize : undefined,
      borderTop:   wideScreen ? '1px solid #DDD' : undefined,
    }
  },[size, menuSize, wideScreen, headsHeight])


  return (
     <div 
        className = { classes.root }
        style     = { style }
     >
         <Head val = { arr[0] }/>
         <Head val = { arr[1] }/>
     </div>
       
  );
}




export default Heads;

