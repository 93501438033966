import React                  from 'react';
import { userAttributesType } from 'types';

const useForgetContent = (userAttributes: userAttributesType) => {
    React.useEffect(() => {
        const content = localStorage.getItem( 'ISeeMathContent' );
    
        if ( content && userAttributes.userSignedIn === 'false' ) {
          localStorage.removeItem( 'ISeeMathContent' );
        }
        
      },[userAttributes])
};

export default useForgetContent;