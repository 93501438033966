import { contentInfoType } from "../../types";

const updateStdntOpenApps = (
    action:             'add' | 'remove', 
    app:                contentInfoType,
    myStudentContent:   contentInfoType[],
    studentOpenApps:    any[],
    userEmail:          string,
    setStudentOpenApps: (payload: { userEmail: string, arr: string, myStudentContent: contentInfoType[] }) => void
) => {

    let newStudentApps        = studentOpenApps.slice();
    const id                  = !!app.lesson_id ? app.lesson_id : app.uid ? +app.uid : '';
    const myStudentContentNew = myStudentContent;

    if ( id === '' || id === 'undefined' ) {
      console.log('no app id in ',app);
      return;
    }
    
    if ( action === 'add' ) {
      newStudentApps.push(id);
      myStudentContentNew.push(app);
    }

    if ( action === 'remove' ) {
      const index = studentOpenApps.indexOf(id);
      newStudentApps.splice(index, 1);
      for ( let i = 0; i < myStudentContentNew.length; i++ ) {
        if (
              ( !!myStudentContentNew[i].lesson_id && myStudentContentNew[i].lesson_id === id ) ||
              ( !!myStudentContentNew[i].uid && +myStudentContentNew[i].uid === id ) 
          ){
            myStudentContentNew.splice(i, 1)
          }
      }
    }


    const payload = {
      action:           action,
      userEmail:        userEmail,
      arr:              JSON.stringify(newStudentApps),
      app:              app,
      myStudentContent: myStudentContentNew
    }

    setStudentOpenApps(payload)

  }

export default updateStdntOpenApps
