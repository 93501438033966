import { store }                  from "../../index";
import { SET_PAGE_STATE }         from '../../actions/page_actions'
import { SET_COMPONENTS_STATE }   from '../../actions/component_actions'
import { pageStateUnAuthed }      from "../../utils/defaultStates";
import { initialComponentsState } from 'reducer/initialStates';



const logoClick = () => {
 
         const state = store.getState();
         store.dispatch(SET_COMPONENTS_STATE(initialComponentsState))

         const pageState = state.page
    
         store.dispatch(SET_PAGE_STATE({ 
          ...pageState,
          opacity: 0
         }))
    
         setTimeout(() => {
          store.dispatch(SET_PAGE_STATE(pageStateUnAuthed))
         },700)
}

export default logoClick;