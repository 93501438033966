
export const appsArr = [
  `The dynamic visualizations in these apps are designed to make your lesson more effective and enjoyable.`,
  `Some of the apps are of very general use and can assist you in many lessons like "Fraction Circle Model," some are highly specific like "Circle Circumference," and some are unique games like "The Hexagon Challenge" for you to conduct in class.`,
  `You may click a topic and a subtopic to search for an app that can help you.`,
 // `You can also filter by grade or simply use the "search."`
]


export const myStudentContArr1 = [
  `Your students can log onto I SEE MATH by clicking the "I'm A Student" button in the log in form.`,
  `They will need to enter your email and teacher code:`,
]

export const myStudentContArr2 = [
  `The students will have access to all puzzles and explainer videos in the Bonus Content.`,
  `In addition, you can choose to share up to 5 apps and lessons. This is done by clicking the icon below:`
]

export const favoritesArr = [
  `Your favorite apps will appear here.`,
  `Add an app to your favorites collection by clicking on the star on the upper right corner.`
]




export const myLessonsArr = [
 //`You have not saved your own lessons yet.`,
  `Most apps allow you to create pages and easily save them as lessons.`,
  `The saved lessons appear much like a presentation, but with the added interest of the dynamic app.`,
  `Watch the tutorial "Saving a lesson" to see how it's done.`,
]
  
export const groupsArr = [
  `Groups are a convenient way for teachers to share lessons with each other.`,
  `When you save a lesson (see the tutorial "Saving a lesson") you can ascribe it to a group.`,
  `Any other teacher that searches for lessons in this group will find them here.`,
]

export const lessonsArr = [
  `I SEE MATH apps have tools that help the teacher give effective visualizations on the fly.`,
  `In addition, most apps allow teachers to easily create and save their own lessons as a sequence of pages which include the dynamic tools of the app (see the tutorial "Saving a Lesson").`,
  `Lessons can also be sent to your students with the tool "My student content" and they can be shared amongst colleagues with the "Groups" feature.`,
  `These are sample lessons that illustrate how a lesson can be created with an I SEE MATH app serving as its platform.`
]

export const bonusTeachersArr = [
  `I SEE MATH is a dynamic site that is in constant development.`,
  `In the "Bonus content" you will find puzzles and explainer videos.`,
  `All content here is available for your students (See "My student content" for more info on this.)`,  //read more
  `The puzzles are related to different topics and give the student a richer experience rather than solving routine problems.`,
  `To solve each puzzle the student will generally have to solve many problems in his or her head.`,
  `So one I See Math puzzle may be "worth" ten ordinary ones.`,
  `In addition, the student has an opportunity to use his or her intuition and develop higher-level thinking skills in approaching the puzzle.`,
  `The explainer videos introduce the basics of the important topic of fractions and, aside from the introductory ones, provide practice questions.`
]

export const bonusStudentsArr = [
  `These activities will help to deepen your understanding of math concepts as well as sharpen your problem solving skills.`,
  `The puzzles are a fun challenge that will require you to be creative as you solve problems.`,
  //`With the videos you can learn new concepts, review what you have already learned, and test your knowledge with practice questions.`
]
