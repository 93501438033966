import React           from 'react';
import instructionsArr from './instructionsArr';
import LevelGrid       from './LevelGrid';
import IntroNavBar     from './IntroNavBar';
import { makeStyles }  from 'makeStyles';
import { colors }      from '../../../utils/colors';
//

const useStyles = makeStyles()((Theme) => ({  
  root: {
    position:        'fixed',
    width:           '100vw',
    overflowY:       'auto',
    overflowX:       'hidden',
    backgroundColor: '#FFF',
    transition:      'all 0.4s',
    '&::-webkit-scrollbar': {
      borderLeft: '1px solid #CCC',
      width:      '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `#BBB`,
      borderRadius:    10
    },
  },
  container: {
    width:           '90vw',
    marginLeft:      '5vw',
    textAlign:       'center',
    backgroundColor: '#FFF',
    transition:      'all 0.5s',
  },
  info: {
    width:          '100%',
    height:         '50%',
    display:        'flex',
    justifyContent: 'center',
    flexFlow:       'column',
    textAlign:      'center',
    paddingTop:     30,
    paddingBottom:  30,
    color:          colors.primary
  },
  title: {
    fontSize:       27,
    marginBottom:   10,
    fontWeight:    'bold',
  },
  info_text: {
      marginTop:    30,
      fontSize:     16,
      width:       '90%',
      fontWeight:  'bold',
      marginLeft:  '5%',
  },
  levelSelector: {
    marginLeft:     '50%', 
    transform:      'translate(-50%, 0%)',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    width:          '80vw',
    maxWidth:       300,
  },
  button: {
    marginTop:       30,
    color:           '#FFF',
    maxWidth:        325,
    width:           '80vw',
    height:          40,
    marginLeft:      '50%',
    transform:       'translate(-50%, 0%)',
    borderRadius:    '0.4em',
    textAlign:       'center',
    cursor:          'pointer',
    backgroundColor: colors.primary,
    border:          `1px solid ${ colors.primary }`,
    '&:hover': {
      color:           colors.primary,
      backgroundColor: '#FFF'
    }
},
button_text: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontWeight:      'bold',
    fontSize:        17,
  },
  button2: {
    maxHeight: 50,
    minHeight: 50,
  }
}));

type InstructionsProps = {
  variant:             string;
  level:               number;
  showInstructions:    boolean;
  startChallenge:      () => void;
  abortChallenge:      () => void;
  setLevel:            (val: number) => void;
  setShowInstructions: (val: boolean) => void;
}

const Instructions: React.FC<InstructionsProps> = ({
   variant,
   level,
   showInstructions,
   startChallenge,
   abortChallenge,
   setLevel,
   setShowInstructions
  }) => {

  const { classes } = useStyles();

  const textArr = React.useMemo(() => {
    return !!variant && !!instructionsArr[variant] ? instructionsArr[variant] : []
  },[variant])

  return (
    <div
      className = { classes.root }
      style     = {{
        opacity:   showInstructions ? 1 : 0,
        height:    showInstructions ? '100vh' : 0,
      }}
    >
       <div 
           className = { classes.container }
           style     = {{
               height: showInstructions ? '100vh' : 0 
          }}
       >
            <IntroNavBar
               initialAnimation    = { true }
               wideScreen          = { false }
               showInstructions    = { showInstructions }
               abortChallenge      = { abortChallenge }
               setShowInstructions = { setShowInstructions }
            /> 
            <div className = { classes.info }>
                     <div className = { classes.title }>{ variant }</div>
                     {
                       textArr.map((val) => {
                           return(
                              <div 
                                key       = { val }
                                className = { classes.info_text }
                              >
                                { val }
                              </div>
                           )
                       })
                     }
            </div>
            <div className = { classes.levelSelector } >
                <LevelGrid
                   variant  = { '!widescreen' }
                   level    = { level }
                   setLevel = { setLevel }
                />
            </div>
            <div 
              className = { classes.button } 
              onClick   = { startChallenge }
            >
              <div className = { classes.button_text }>
                 Continue
              </div>    
            </div>
             <div className = { classes.button2 }/>   
        </div>
    </div>
   )
}

export default Instructions
