import React                            from 'react';
import { connect }                      from 'react-redux';
import { Dispatch }                     from "redux";
import Navigation                       from 'components/navigation';
import Footer                           from 'components/Footer';
import Modal                            from 'components/modal';
import PayForms                         from 'components/ppl';
import Authenticator                    from 'components/authenticator';
import TOS                              from 'components/forms/TermsOfService';
import PrivacyPolicy                    from 'components/forms/PrivacyPolicy';
import Hidden                           from 'components/Hidden';
import Loader                           from 'components/Loader';
import Audio                            from 'components/audio';

import signOut                          from 'functions/auth/sign_out';
import goToGroupContent                 from 'functions/contentInteraction/go_to_group_content';

import { initialComponentsState }       from 'reducer/initialStates';
 
import { 
         userAttributesType,
         planTypeType,
         pageStateType,
         pdfStateType,
         userAccessType,
       }                                from 'types'

import { SET_CONTENT }                  from 'actions';
import { SET_COMPONENTS_STATE }         from 'actions/component_actions';
import { ActionTypes }                  from 'actions/types';
import { reducerType }                  from 'reducer';
import { makeStyles }                   from 'makeStyles';
import { componentsStateType }          from 'reducer/types';
 

const useStyles = makeStyles()((Theme) => ({    
   root: {
        height:          '100vh',
        minHeight:       '100vh',
        minWidth:        '100vw',
        maxHeight:       '100vh',
        maxWidth:        '100vw',
        backgroundColor: '#FFF',
        overflow:        'hidden',
        transition:      'opacity 0.6s',
    },
    loader: {
        minHeight:       '100vh',
        minWidth:        '100vw',
        maxHeight:       '100vh',
        maxWidth:        '100vw',
        backgroundColor: '#FFF',
        position:        'fixed',
        top:             0,
        left:            0,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        zIndex:          2,
    }
}))

type rootProps = {
  userAttributes:     userAttributesType;
  userAccess:         userAccessType;
  componentsState:    componentsStateType;
  pageState:          pageStateType;
  planType:           planTypeType;
  PDFState:           pdfStateType;
  tester:             string;
  groupName:          string;
  AudioRef:           any;
  size:               number[];
  menuSize:           number;
  guest:              boolean;
  wideScreen:         boolean;
  goToAppOnce:        boolean;
  showTOS:            boolean;
  showPrivacy:        boolean;
  showPayForm:        boolean;
  groupState:         { [key: string]: any };
  setGroupName:       (val: string)  => void;
  setShowTOS:         (val: boolean)  => void;
  setShowPrivacy:     (val: boolean)  => void;
  setShowPayForm:     (val: boolean)  => void;
  setPlanType:        (val: planTypeType)  => void;
  setChangeContent:   (val: boolean)  => void;
  completeSignUp:     () => void;
  setComponentsState: (payload: componentsStateType)  => void;
  setContent:         (payload: { [key: string]: any }) => void;
}

const Root: React.FC<rootProps> = ({
  userAttributes,
  userAccess,
  componentsState,
  pageState,
  planType,
  PDFState,
  AudioRef,
  size,
  menuSize,
  guest,
  wideScreen,
  goToAppOnce,
  tester,
  groupState,
  groupName,
  showTOS,
  showPrivacy,
  showPayForm,
  setGroupName,
  setShowTOS,
  setShowPrivacy,
  setShowPayForm,
  setPlanType,
  setChangeContent,
  completeSignUp,
  setComponentsState,
  setContent,
}) => {
  
  const { classes } = useStyles();


  const [showNavigation, showFooter] = React.useMemo(() => {

    const page = pageState.page;

    const footer = (
      !componentsState.showPracticeComp && 
      ( 
        page !== 'home' && 
        page !== 'app page' && 
        !PDFState.showPDF
      )
    )
    return[
      footer,
      footer
    ]
  },[componentsState.showPracticeComp, pageState.page, PDFState.showPDF])
  
 
  const goToGroupContentCB = React.useCallback(() => {
    goToGroupContent(groupState, initialComponentsState, setGroupName, setComponentsState, setContent, setChangeContent)
  },[groupState, setGroupName, setComponentsState, setContent, setChangeContent])




  //  -      -      -      -      -      -      -      -      -      -      -      -      -      -

  return (
    <>
        {
          showNavigation
          &&
          <Navigation
            wideScreen             = { wideScreen }
            size                   = { size }
            menuSize               = { menuSize }
            guest                  = { guest }
            tester                 = { tester === 'true' }
            userAccess             = { userAccess }
            AudioRef               = { AudioRef }
            setChangeContent       = { setChangeContent }
          /> 
        }
        {
          showFooter &&
          <Footer
             size           = { size }
             page           = { pageState.page }
             setShowTOS     = { setShowTOS }
             setShowPrivacy = { setShowPrivacy }
          />
        }
        <Modal
           size                   = { size }
           userAttributes         = { userAttributes }
           guest                  = { guest }
           tester                 = { tester }
           wideScreen             = { wideScreen }
           groupName              = { groupName }
           planType               = { planType }
           userAccess             = { userAccess }
           setPlanType            = { setPlanType }
           completeSignUp         = { completeSignUp }
           setShowPayForm         = { setShowPayForm }
           goToGroupContentCB     = { goToGroupContentCB }
        />
        <Authenticator
           size                = { size }
           planType            = { planType }
           setChangeContent    = { setChangeContent }
           logOut              = { signOut }
        />
        <PayForms
            size               = { size } 
            userAttributes     = { userAttributes }
            planType           = { planType }
            showPayForm        = { showPayForm }
            tester             = { tester }
            setShowPayForm     = { setShowPayForm }
        />
        <TOS
            size       = { size }
            showTOS    = { showTOS }
            setShowTOS = { setShowTOS }
        />
        <PrivacyPolicy
            size           = { size }
            showPrivacy    = { showPrivacy }
            setShowPrivacy = { setShowPrivacy }
        />
        <Audio AudioRef = { AudioRef }/>
        <Hidden/>
        {
          !goToAppOnce
          &&
          <div className = { classes.loader }>
              <Loader/>
          </div>
        }
    </>
  );
}


const mapStateToProps = (reducer: reducerType) => {

  return {
    componentsState: reducer.components,
    studentVideos:   reducer.content.studentVideos,
    groupState:      reducer.content.groupState,
    pageState:       reducer.page,
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setContent:         (payload: { [key: string]: any }) => dispatch(SET_CONTENT(payload)),
  setComponentsState: (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);









//  "homepage": "https://iseemath.co/stage/",



