import React                            from 'react';
import { 
  useNavigate, 
  useLocation,
}                                       from 'react-router-dom';

import { connect }                      from 'react-redux';
import { Dispatch }                     from "redux";

import RootPages                        from './RootPages';
import RootComponents                   from './RootComponents';

//import useIsFirstRender                 from 'functions/useIsFirstRender';

//    ui functions

import useSize                          from 'functions/ui/use_size';
import useSizeVars                      from 'functions/ui/use_size_vars';


//    history functions

import onLoad                           from 'functions/history/onload';
import useBackListener                  from 'functions/history/use_back_listener';

//    user data functions

import useGetUserInfo                   from 'functions/userData/use_get_user_info';
import loadStoredData                   from 'functions/userData/loadStoredData';
import { loadRealData }                 from 'functions/userData/load_data';
import useUpdateAccessStatus            from 'functions/userData/use_update_access_status';
import useGetUserDevice                 from 'functions/userData/use_get_user_device';
import useGetUserAccess                 from 'functions/userData/use_get_user_access';
import useGetTeacherCode                from 'functions/userData/use_get_teacher_code';
import useGetUserActivity               from 'functions/userData/use_get_user_activity';
import useGetStudentScoresFunc          from 'functions/userData/use_get_student_scores';

//    app data functions

import setAppFilters                    from 'functions/appData/set_app_flters';
import useForgetContent                 from 'functions/appData/forget_content';
import useGetTotalLikes                 from 'functions/appData/use_get_total_likes';
//import useGetContent                    from 'functions/appData/use_get_content';
import useGetApps                       from 'functions/appData/use_get_apps';
import useGetLessons                    from 'functions/appData/use_get_lessons';
import useGetPuzzles                    from 'functions/appData/use_get_puzzles';
import useGetUserLessons                from 'functions/appData/use_get_user_lessons';
import useGetMyStudentApps              from 'functions/appData/use_get_my_student_apps';
import useGetBonusContent               from 'functions/appData/use_get_bonus_content';

//    auth functions

import handleUserCreation               from 'functions/auth/handle_user_creation';
import handleStoredPlan                 from 'functions/auth/handle_stored_plan';

//    contentInteraction functions

import { contentSelection }             from 'functions/contentInteraction/content_selection';
import goToGroupContent                 from 'functions/contentInteraction/go_to_group_content';

//    modal functions

import closeModal                        from 'functions/modal/close_modal';

import { initialPDFState }               from 'utils/defaultStates'

import { initialComponentsState }        from 'reducer/initialStates';
 
import { 
         userInfoType,
         updateInfoType,
         appTopicsArrType,
         contentInfoType,
         userAttributesType,
         planTypeType,
         pageStateType,
       }                                from 'types'

       import {
        SET_CONTENT,
        CREATE_USER,
      }                                 from 'actions';
import { SET_MODAL_STATE }              from 'actions/modal_actions'


import { 
        SET_COMPONENTS_STATE,
        SET_SHOW_TOPIC_INTRO
       }                                from 'actions/component_actions';

import { SET_PAGE_STATE }               from 'actions/page_actions';
import { SET_AUTH_STATE }               from 'actions/auth_actions';
import { ActionTypes }                  from 'actions/types';
import { reducerType }                  from 'reducer';
import { 
         authStateType,
         componentsStateType, 
         modalStateType 
        }                               from 'reducer/types';





const url_string     = window.location.href;
const url            = new URL(url_string);
const referrer       = url.searchParams.get("ref") || '';
const videoPage      = url.searchParams.get("app") || '';
const open           = url.searchParams.get("open") || '';

const url_string2    = window.location.href;
const tos            = new URL(url_string2).search === '?tos=1';
const privacy        = new URL(url_string2).search === '?privacy=1';
 
if ( !privacy ) {
  if (!!localStorage.getItem('schoolSignup')) {
    localStorage.removeItem('schoolSignup');
  }
  if (!!localStorage.getItem('webinarSignup')) {
    localStorage.removeItem('webinarSignup');
  }
}
 
type rootProps = {
  userAttributes:         userAttributesType;
  userInfo:               userInfoType;
  dataUpdateState:        updateInfoType;
  modalState:             modalStateType;
  authState:              authStateType;
  newUserCreated:         string;
  tester:                 string;
  studentLoggedIn:        boolean;
  puzzles:                { [key: string]: any }[];
  topicIntros:            { [key: string]: any }[];
  hash:                   { [key: string]: any };
  groupState:             { [key: string]: any };
  setComponentsState:     (payload: componentsStateType)  => void;
  setPageState:           (payload: pageStateType) => void;
  handledUserCreation:    (payload: { success: string }) => void;
  setContent:             (payload: { [key: string]: any }) => void;
  setModalState:          (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
  setAuthState:           (payload: authStateType) => void;
  setShowTopicIntro:      (payload: boolean) => void;
}

const Root: React.FC<rootProps> = ({
  userAttributes,
  userInfo,
  newUserCreated,
  dataUpdateState,
  modalState,
  authState,
  studentLoggedIn,
  tester,
  puzzles,
  topicIntros,
  hash,
  groupState,
  setComponentsState,
  setPageState,
  handledUserCreation,
  setContent,
  setModalState,
  setAuthState,
  setShowTopicIntro,
}) => {
  
  
  // const firstRender = useIsFirstRender()

  // console.log('firstRender : ', firstRender)
  //pages & components

  const [goToAppOnce, setGoToAppOnce]                  = React.useState(false) 

  //content

  const [getAppsOnce, setGetAppsOnce]                  = React.useState(false)
  const [getPuzzlesOnce, setGetPuzzlesOnce]            = React.useState(false)
  const [getLessonsOnce, setGetLessonsOnce]            = React.useState(false)
  const [getUserLessonsOnce, setGetUserLessonsOnce]    = React.useState(false)
  const [getMyStudentAppsOnce, setGetMyStudentAppsOnce]= React.useState(false)
  

  const [freeApp, setFreeApp]                          = React.useState('')
  const [appTopicsArr, setAppTopicsArr]                = React.useState([] as appTopicsArrType[])
  const [createDataOnce, setCreateDataOnce]            = React.useState(false)
  const [groupName, setGroupName]                      = React.useState('')

  //variables
  const [changeContent, setChangeContent]              = React.useState(true);
  const [PDFState, setPDFState]                        = React.useState(initialPDFState);
  const [showTOS, setShowTOS]                          = React.useState(tos);
  const [showPrivacy, setShowPrivacy]                  = React.useState(privacy);

  //modals
  const [showPayForm, setShowPayForm]                  = React.useState(false);
  
  //payments
  const [planType, setPlanType]                        = React.useState('' as planTypeType);


  // hooks      -      -      -      -      -      -      -      -      -      -
  // hooks      -      -      -      -      -      -      -      -      -      -
  // hooks      -      -      -      -      -      -      -      -      -      -
  

  // history

  const navigate = useNavigate();
  const location = useLocation();
  useBackListener();

  //ui

  const size = useSize();
  const { wideScreen, menuSize } = useSizeVars(size);

  // get user access level

  const guest = React.useMemo(() => {
    return userAttributes.userSignedIn === 'false';
  },[userAttributes])

  const userAccess = useGetUserAccess(userAttributes)
  

 // get User Device - - - - 

  useGetUserDevice(userAttributes.userEmail, referrer, videoPage);

 // get User Info - - -

  useGetUserInfo(userAttributes, tester)

 // update free access status - - -

   useUpdateAccessStatus(guest, userAccess.freeUser)

  // get Total Likes - - -

  useGetTotalLikes(userAccess.denyAccess, guest)
  
  // get content - - -

  useGetApps(userAttributes.userEmail, guest, userAccess, getAppsOnce, setGetAppsOnce)
  useGetLessons(userAttributes.userEmail, guest, userAccess, getLessonsOnce, setGetLessonsOnce)
  useGetPuzzles(userAttributes.userEmail, guest, userAccess, getPuzzlesOnce, setGetPuzzlesOnce)
  useGetUserLessons(userAttributes.userEmail, guest, userAccess, getUserLessonsOnce, setGetUserLessonsOnce)
  useGetMyStudentApps(userAttributes.userEmail, guest, userAccess, getMyStudentAppsOnce, setGetMyStudentAppsOnce)
  
  useForgetContent(userAttributes);

  // get bonus content - - -

  useGetBonusContent(userAttributes.userEmail)

  //   getTeacherCode

  useGetTeacherCode();

  // get User Activity - - -
 
  useGetUserActivity(guest, userAccess.denyAccess, userAttributes)

  // get student scores - - -
 
  useGetStudentScoresFunc(guest)

  // handle data loading - - -

  React.useEffect(() => {
    if ( dataUpdateState.final ) {
      setChangeContent(true);
    }
  },[dataUpdateState.final])


  React.useEffect(() => {
    if (studentLoggedIn) {
      setChangeContent(true)
    }
  },[studentLoggedIn])


  React.useEffect(() => {

    // load stored data (while waiting for all updated user data from DB)

    loadStoredData(guest, setChangeContent)
  
  },[guest, dataUpdateState])



  React.useEffect(() => {

    // load real data (received all updated user data from DB)

    loadRealData( guest, createDataOnce, setCreateDataOnce )
  },[ guest, createDataOnce, dataUpdateState ])



  // 
  // set app info and filters      -      -      -      -      -      -      -      -      -      -
  // set app info and filters      -      -      -      -      -      -      -      -      -      -
  // set app info and filters      -      -      -      -      -      -      -      -      -      -
  // set app info and filters      -      -      -      -      -      -      -      -      -      -
  // set app info and filters      -      -      -      -      -      -      -      -      -      -
  // set app info and filters      -      -      -      -      -      -      -      -      -      -
  // set app info and filters      -      -      -      -      -      -      -      -      -      -

  React.useEffect(() => {
    
    if (changeContent) {
      setAppFilters( setAppTopicsArr );
      setChangeContent(false)
    }

  },[changeContent])


  // 
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -
  //  content click functions -      -      -      -      -      -      -      -      -      -      -      -      -      -

  

  const selectContent = React.useCallback((val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => {
    contentSelection(navigate, location, size, val, destination, disabled, disabledFadeIn)
  },[ navigate, location, size ])
  
 
  const goToGroupContentCB = React.useCallback(() => {
    goToGroupContent(groupState, initialComponentsState, setGroupName, setComponentsState, setContent, setChangeContent)
  },[groupState, setGroupName, setComponentsState, setContent, setChangeContent])


  React.useEffect(() => { 
      if ( groupState.groupName !== groupName ) {
        goToGroupContentCB()
    }
  },[groupState, groupName, goToGroupContentCB]) 


//  modals  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  modals  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  modals  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  modals  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  modals  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  modals  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//

   const handleCloseModal = React.useCallback(() => {
    closeModal(userAccess.subscriptionStatus);
  },[userAccess.subscriptionStatus])



   const completeSignUp = () => {
    handleCloseModal();
    setAuthState({
      ...authState,
      name:         userInfo.userName,
      email:        userInfo.userEmail,
      userOrigin:   userInfo.userOrigin,
      showAuthForm: true,
      displayForm:  'completeSignUp'
    })
  }
  

  
//  payments  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  payments  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  payments  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  payments  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  payments  -    -      -      -      -      -      -      -      -      -      -      -      -      -
//  payments  -    -      -      -      -      -      -      -      -      -      -      -      -      -

      
   React.useEffect(() => {
     if (tester === 'true') {
       return;
     }
     handleStoredPlan(userAttributes.userSignedIn === 'true', guest, userAccess.freeUser, userAccess.freeTrial, userAccess.denyAccess, userAccess.unSubscribedAuthedUser, modalState.modalVariant, setPlanType, setModalState, setShowPayForm);
   },[modalState.modalVariant, tester, userAttributes, guest, userAccess, setModalState])



   //  authentication  -    -      -      -      -      -      -      -      -      -      -      -      -      -
   //  authentication  -    -      -      -      -      -      -      -      -      -      -      -      -      -
   //  authentication  -    -      -      -      -      -      -      -      -      -      -      -      -      -
   //  authentication  -    -      -      -      -      -      -      -      -      -      -      -      -      -
   //  authentication  -    -      -      -      -      -      -      -      -      -      -      -      -      -
   //  authentication  -    -      -      -      -      -      -      -      -      -      -      -      -      -
  


const handledUserCreationCB = React.useCallback(() => {
   
  handledUserCreation({ success: "" })

},[handledUserCreation]);


  React.useEffect(() => {
     if ( newUserCreated !== '' ) {
        handleUserCreation();
        handledUserCreationCB();
     }
  },[newUserCreated, handledUserCreationCB]) 




  // handle history      -      -      -      -      -      -      -      -      -      -
  // handle history      -      -      -      -      -      -      -      -      -      -
  // handle history      -      -      -      -      -      -      -      -      -      -
  // handle history      -      -      -      -      -      -      -      -      -      -
  // handle history      -      -      -      -      -      -      -      -      -      -
  // handle history      -      -      -      -      -      -      -      -      -      -
  // handle history      -      -      -      -      -      -      -      -      -      -



   
   React.useEffect(() => {

    onLoad(navigate, location, videoPage, goToAppOnce, wideScreen, userAttributes.userSignedIn, setGoToAppOnce, setChangeContent, setPlanType, selectContent)
      
  },[navigate, location, userAttributes, puzzles, topicIntros, goToAppOnce, wideScreen,/*  pageState,  */hash, setShowTopicIntro, setPageState, selectContent])
 
  
   React.useEffect(() => {

    if (!!open && !freeApp.length) {
      setFreeApp(open)
    }
      
  },[hash, freeApp.length])


  // audio      -      -      -      -      -      -      -      -      -      -
  // audio      -      -      -      -      -      -      -      -      -      -
  // audio      -      -      -      -      -      -      -      -      -      -
  // audio      -      -      -      -      -      -      -      -      -      -
  // audio      -      -      -      -      -      -      -      -      -      -
  // audio      -      -      -      -      -      -      -      -      -      -
  // audio      -      -      -      -      -      -      -      -      -      -

  const AudioRef         = React.useRef<HTMLAudioElement>(null);


  //  -      -      -      -      -      -      -      -      -      -      -      -      -      -
  return (
    <>
        <RootPages
            userAttributes     = { userAttributes }
            userAccess         = { userAccess }
            appTopicsArr       = { appTopicsArr }
            wideScreen         = { wideScreen }
            menuSize           = { menuSize }
            guest              = { guest }
            size               = { size }
            AudioRef           = { AudioRef }
            freeApp            = { freeApp }
            PDFState           = { PDFState }
            setPDFState        = { setPDFState }
            selectContent      = { selectContent }
            setChangeContent   = { setChangeContent }
            setShowTOS         = { setShowTOS } 
            setShowPrivacy     = { setShowPrivacy } 
        />
        <RootComponents
            userAttributes   = { userAttributes }
            userAccess       = { userAccess }
            PDFState         = { PDFState }
            AudioRef         = { AudioRef }
            size             = { size }
            menuSize         = { menuSize }
            guest            = { guest }
            wideScreen       = { wideScreen }
            goToAppOnce      = { goToAppOnce }
            tester           = { tester }
            planType         = { planType }
            showTOS          = { showTOS }
            showPrivacy      = { showPrivacy }
            showPayForm      = { showPayForm }
            groupName        = { groupName }
            setGroupName     = { setGroupName }
            setShowTOS       = { setShowTOS }
            setShowPrivacy   = { setShowPrivacy }
            setShowPayForm   = { setShowPayForm }
            setPlanType      = { setPlanType }
            setChangeContent = { setChangeContent }
            completeSignUp   = { completeSignUp }
        />
    </>
  );
}


const mapStateToProps = (reducer: reducerType) => {

  return {
    newUserCreated:         reducer.user.newUserCreated,
    userInfo:               reducer.user.userInfo,
    studentLoggedIn:        reducer.user.studentLoginState.loginState === 'success',

    puzzles:                reducer.content.puzzles,
    studentVideos:          reducer.content.studentVideos,
    topicIntros:            reducer.content.topicIntros,
    hash:                   reducer.content.hash,
    groupState:             reducer.content.groupState,

    dataUpdateState:        reducer.data.dataUpdateState,

    modalState:             reducer.modal,

    authState:              reducer.auth

}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  handledUserCreation: (payload: { success: string; }) => dispatch(CREATE_USER(payload)),
  setContent:          (payload: { [key: string]: any }) => dispatch(SET_CONTENT(payload)),
  setModalState:       (payload: { variant: string, open: boolean, showButtons: boolean }) => dispatch(SET_MODAL_STATE(payload)),
  setComponentsState:  (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload)),
  setPageState:        (payload: pageStateType) => dispatch(SET_PAGE_STATE(payload)),
  setAuthState:        (payload: authStateType) => dispatch(SET_AUTH_STATE(payload)),
  setShowTopicIntro:   (payload: boolean) => dispatch(SET_SHOW_TOPIC_INTRO(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);




//  "homepage": "https://iseemath.co/stage/",






