import React                     from 'react';

//import Info                      from './Info';
import Buttons                   from './Buttons';
import IntroNavBar               from './IntroNavBar';
import Instructions              from './Instructions';
import SideBar                   from './SideBar';
import Animation                 from '../animations';


import instructionsArr            from './instructionsArr';
 
//import { tests, tests2 }         from '../../rooms/darts/tests';
//import { all_tests }             from '../imageChallenges/tests';
//import { breakingCirclesTests }  from '../../rooms/breaking_circles/tests';
//import { cuttingGridsTests }     from '../../rooms/cutting_rect_puzzle/tests';
//import { findTheShapeTests }     from '../../rooms/find_the_shape/tests';
//import { fractionAdditionTests } from '../../rooms/fraction_addition/tests';
//import { longAdditionTests }     from '../../rooms/long_addition/tests';
//import { battleshipTests }       from '../../rooms/submarines/tests';

//import { scoreType }             from '../../types';
 
import { makeStyles }            from 'makeStyles';
import { colors }                from '../../../utils/colors';
/* 
const infoArr = [
  //  `Level: ${ 4 }`,
    `Challenges: ${ 6 }`,
  //  `Top Score: ${ 100 }`,
  //  `Personal Score: ${ 0 }`
]
 */

const useStyles = makeStyles()((Theme) => ({
    root: {
        height:     '100vh',
        width:      '100vw',
        overflow:   'hidden',
        display:    'flex',
        transition: 'all 0.5s'
    },
    horizontal_container: {
        width:          '100%',
        height:         '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
    },
    vertical_container: {
        flex:           1,
        marginTop:      '10vh',
        height:         '80vh',
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'space-between',
        flexFlow:       'column',
       // marginLeft:     15,
       // marginRight:    15,
    },
    title: {
       marginBottom: 30,
       color:        colors.primary,
       transition:   'all 0.6s'
    },
    infoContainer: {
        height:          '50%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'column',
        marginLeft:      15,
        marginRight:     15,
        backgroundColor: '#FFFFFFF9',
        zIndex:          1,
        boxShadow:       '0px 0px 20px #FFFFFFEE'

     //   border: '1px solid #000'
    },
    info: {
      color:      colors.primary,
      fontSize:   18,
      lineHeight: 2,
      fontWeight: 'bold',
      transition: 'all 0.4s'
    },
}))

type IntroProps = {
    size:                number[];
    menuSize:            number;
    wideScreen:          boolean;
    level:               number;
    variant:             string;
    startChallenge:      () => void;
    abortChallenge:      () => void;
    setLevel:            (val: number) => void;
}

const Intro: React.FC<IntroProps> = ({ 
    size, 
    menuSize,
    wideScreen,
    variant, 
    level,
    setLevel,
    startChallenge, 
    abortChallenge 
  }) => {

  const { classes } = useStyles();

  const [initialAnimation, setInitialAnimation] = React.useState(false);
  const [showInstructions, setShowInstructions] = React.useState(false);

  React.useEffect(() => {
     setTimeout(() => { setInitialAnimation(true) }, 20)
  },[setInitialAnimation])

  React.useEffect(() => {
    if (wideScreen && showInstructions) {
      setShowInstructions(false)
    }
 },[wideScreen, showInstructions])


  const goToChallenge = () => {
    setInitialAnimation(false)
    setTimeout(() => {
        startChallenge()
    },1000)
  }


  const [rootStyle] = React.useMemo(() => {
    return[
      {
          transform:  `translate(0vw, ${ initialAnimation ? 0 : -100 }vh)`,
          fontSize:   size[0] > 600 ? 45 : 33,
          
      }
    ]
  },[size, initialAnimation])


  return (
    <div className = { classes.root } >
            {
              wideScreen
              &&
              <SideBar
                 variant          = { variant }
                 level            = { level }
                 menuSize         = { menuSize }
                 initialAnimation = { initialAnimation }
                 setLevel         = { setLevel }
              />
            }
            {
              !wideScreen && <div className = { classes.vertical_container }/>
            }
            <div className = { classes.vertical_container }>
                    <div 
                       className = { classes.title }
                       style     = { rootStyle }
                    >
                       { variant }
                    </div>
                    <Animation 
                        size             = { size }
                        variant          = { variant }
                        initialAnimation = { initialAnimation }
                    />
                    <Buttons
                      initialAnimation    = { initialAnimation }
                      showInstructions    = { !wideScreen && !!instructionsArr[variant] && instructionsArr[variant].length > 1 }
                      startChallenge      = { goToChallenge }
                      setShowInstructions = { setShowInstructions }
                    />
            </div>
        { 
          (!wideScreen || (wideScreen && !showInstructions)) && 
          <div className = { classes.vertical_container }>
            <IntroNavBar
               initialAnimation    = { initialAnimation }
               showInstructions    = { showInstructions }
               wideScreen          = { wideScreen }
               abortChallenge      = { abortChallenge }
               setShowInstructions = { setShowInstructions }
            /> 
          </div> 
        }
        {
          !wideScreen
          &&
          <Instructions
            variant             = { variant }
            level               = { level }
            showInstructions    = { showInstructions }
            startChallenge      = { startChallenge }
            abortChallenge      = { abortChallenge }
            setLevel            = { setLevel}
            setShowInstructions = { setShowInstructions }
          />
        }
    </div>
  );
}

export default Intro;
