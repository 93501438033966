import { componentsStateType } from "./types";

export const initialComponentsState: componentsStateType = {
    searchStr:               '',
    selectedGrades:          '1234567',
    selectedAppTopic:        '',
    selectedAppSubTopic:     '',
    selectedContent:         'apps',
    appUrl:                  '',
    initialVideoPage:        '',
    followLink:              '',
    selectedVideo:           -1,
    showTutorial:            false,
    showStudentVideo:        false,
    openGradeGrid:           false,
    showAppIframe:           false,
    showAppIntro:            false,
    showTopicIntro:          false,
    showPracticeComp:        false,
    disabledFadeIn:          false,
    showMenu:                true,
    showSmallMenu:           false,
    showNavbar:              true,
    showFooter:              true,
    createTopicArr:          true,
    directPageLink:          false,
    appLessons:              [] as { [key: string]: any }[]
  }
  



export const initialUserInfo = { 
    userId:                  -1,
    sessionId:               '',
    userIp:                  '',
    userName:                '', 
    userEmail:               '', 
    userOrigin:              '', 
    browser:                 '', 
    hasPassword:             false, 
    completeTrial:           false, 
    allowTrial:              false, 
    trialStartedAt:          '', 
    userRole:                '', 
    teacherCode:             '', 
    subscriber:              false, 
    freeUser:                false, 
    blocked:                 false, 
    subscriptionSuspended:   false, 
    //suspendSubscriptionAt:   '',
    subscriptionSuspendedAt: '', 
    firstPayment:            '', 
    //nextPayment:             '', 
    balance:                 '',
    subscriptionType:        '', 
    subscriptionId:          '',
    studentOpenApps:         [] as string[],
    oneTimeEvents:           [] as string[],
    countryName:             '',
};