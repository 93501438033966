
export const text1 = [ `Between the years 2000 and 2010, I-SEE-MATH developers focused on developing programs for gifted students. These programs are now being used in hundreds of schools in Israel, the United States and Singapore.`,
`In light of the success of these programs, we decided in 2011 to develop a program that would suit the entire class – gifted and non-gifted students. The idea was to develop apps and lessons that enable each teacher to teach the material in a more visual and dynamic way.`,
`Our guiding principles are:`,
];
export const list1 = [
`The goal of math teaching is more about developing thinking skills, than about showing the students the quickest algorithm.`,
`It's best if each student is guided to find his/her own way in math.`,
`Mistakes are a natural part that one makes on his/her way to success.`,
`It’s important to use tasks that have more than one way to reach the solution.`,
`Stories and games are helpful while teaching math.`
];

export const text2 = [
`Our goals for the students are that they:`,
];

export const list2 = [
`Enjoy the study of math and look forward to the next lesson.`,
`Become independent learners.`,
`Enhance their creativity.`,
`Succeed in tests.`
];

export const text3 = [
`Here are 2 quotes from teachers:`
];


export const quote1 = [
`This year I taught two classes.`,
`In the first one I used ISM, and in the second there was no computer, so I couldn't use ISM.`,
`Beyond the fact that in the first class the learning was  a lot more enjoyable, the students' scores were much higher.`,
`Anat from Jerusalem`,
];

export const quote2 = [
`What a rich variety of math apps that offer a wonderful solution to the way I seek to teach my students math: by developing critical thinking and understanding of mathematical concepts in a fun way.`,
`Diana from Tel Aviv`
];
