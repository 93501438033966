import React               from 'react';
import { Dispatch }        from "redux";
import { ActionTypes }     from 'actions/types';
import { connect }         from 'react-redux';
import { reducerType }     from 'reducer';
import { Link }            from "react-router-dom";
import { colors }          from 'utils/colors';
import ToolTip             from 'components/ToolTip';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUp';
import { makeStyles }      from 'makeStyles';
//import { audioStateType }  from 'reducer/types';
import { SET_AUDIO_STATE } from 'actions/audio_actions';
import recordEvent         from 'functions/contentInteraction/record_event';
import { contentInfoType } from 'types';


const useStyles = makeStyles()(
  (Theme) => ({    
    buttonContainer: {
      width:           '100%',
      maxWidth:        600,
     // height:          200,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textDecoration:  'none'
    },
    button: {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center', 
      backgroundColor: colors.primary,
      color:           '#FFF',
      width:           '100%',
      maxWidth:        '94vw',
      height:          56,
      fontWeight:      'bold',
      cursor:          'pointer',
    },
    text: {
      flex:            1,
      height:          '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',  
    //  paddingLeft:     45,  
      '&:hover': {
        backgroundColor: colors.primaryDark
      }   
    },
    iconContainer: {
      width:           45,
      height:          '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'flex-end',
     // textAlign:       'center', 
      cursor:          'pointer',
      '&:hover': {
        backgroundColor: colors.primaryDark
      }
    },
    icon: {
       height:    '100%',
       transform: 'translate(-10px, 0px)'
    }
}))
 
type ButtonProps = {
    variant:           string;
    enableAudio:       boolean;
    wideScreen:        boolean;
    setAudioState:     (payload: contentInfoType) => void;
    handleNavBarClick: (val: string) => void;
}
 
const Button: React.FC<ButtonProps> = ({ 
    variant,
    enableAudio,
    wideScreen,
    setAudioState,
    handleNavBarClick
  }) => {

  const { classes } = useStyles();

  const [showTT1, setShowTT1] = React.useState(false)
  const [showTT2, setShowTT2] = React.useState(false)

  const handleClick = () => {
    
    handleNavBarClick('apps') 
    recordEvent('button', 'homepage continue to content button clicked', 'continue_to_content_button')
  }

  //

  const handleAudioClick = () => {
      
    setAudioState({
      playAudio:  false,
      pauseAudio: true,
      audioSrc:   'https://iseemath.co/audio/01_starting.wav',
    })    
    setTimeout(() => {
      setAudioState({
        playAudio:  true,
        pauseAudio: false,
      })
    },50)

    handleNavBarClick('apps') 
  }

  const [buttonStyle, textStyle] = React.useMemo(() => {
    return [
      {
        fontSize: wideScreen ? 23 : 20
      },
      {
        paddingLeft: enableAudio ? 45 : 0,
      }
    ]
  },[wideScreen, enableAudio])
  
  return (
       <Link 
          replace
          to        = '/Apps'
          className = { classes.buttonContainer }
       >
          <div 
             className = { classes.button }
             style     = { buttonStyle }
          >
             <div 
                className    = { classes.text }
                style        = { textStyle }
                onClick      = { handleClick }
                onMouseEnter = { () => setShowTT1(true) }
                onMouseLeave = { () => setShowTT1(false) }
             >
               <div>continue to apps</div>
               {
                 enableAudio && 
                 <ToolTip
                      showTT = { showTT1 }
                      text   = { 'continue silently' }
                      lower  = { false }
                      extra  = { 13 }
                 />
               }
             </div>
             {
                enableAudio &&
                <div 
                    className    = { classes.iconContainer }
                    onMouseEnter = { () => setShowTT2(true) }
                    onMouseLeave = { () => setShowTT2(false) }
                    onClick      = { handleAudioClick }
                >
                    <VolumeUpRoundedIcon className = { classes.icon }/>
                    <ToolTip
                      showTT = { showTT2 }
                      text   = { 'continue with a voice tour (recommended)' }
                      lower  = { false }
                      extra  = { 13 }
                    />
                </div> 
            }
          </div>
       </Link>
  );
}





const mapStateToProps = (reducer: reducerType) => {
  return {
   // audioState: reducer.audio
}};


const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setAudioState: (payload: contentInfoType) => dispatch(SET_AUDIO_STATE(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);




