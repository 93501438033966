import React               from 'react';
import MoreIcon            from '@mui/icons-material/ExpandMore';
import ClickAwayListener   from '@mui/base/ClickAwayListener';
import { sizes }           from 'utils/defaultStates';
import { colors }          from 'utils/colors';
import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    selector: {
      position:        'absolute',
      left:            0,
      height:          35,
      maxWidth:        35,
      minWidth:        35,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer'
    },
    menu: {
      width:           250,
      left:            0,
      position:        'absolute',
      border:          '1px solid #d3d4d5',
      backgroundColor: '#FFF',
      overflowX:       'hidden',
      boxShadow:       '0px 4px 10px rgba(0,0,0,0.15)',
      cursor:          'pointer',
      transition:      'height 0.3s, opacity 0.4s',
     '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#CCC',
        borderRadius:    10
      } 
    },
    item: {
      height:     50,
      width:      '100%',
      minWidth:   250,
      display:    'flex',
      alignItems: 'center',
      textAlign:  'left',
      paddingLeft: 5,
      fontSize:    14,
      fontWeight:  'normal',
      cursor:     'pointer',
      '&:hover': {
        backgroundColor: '#E6E6EA'
      }
    },
    icon: {
      fontSize: 35,
      color:    colors.primary,
    },
}))

type DropDownProps = {
  size:           number[];
  dropDownitems:  string[];
  handleClose:    () => void;
  goTofather_app: () => void;
  handleGoToPdf:  () => void;
}

const DropDown: React.FC<DropDownProps> = ({ 
  dropDownitems,
  size,
  handleClose,
  goTofather_app,
  handleGoToPdf
}) => {

  const { classes } = useStyles();

  const [open, setopen] = React.useState(false)
  
  const handleClick = (val: string) => {
     if ( val === 'Go To Source App' ) {
       goTofather_app();
     }
     if ( val === 'The Student Page' ) {
       handleGoToPdf();
     }
     if ( val === 'Go Back To Lessons' || val === 'Go Back To Apps' ) {
       handleClose();
     }
  }

  const handleClickAway = () => {
    setopen(false)
  }

  const [selectorStyle, menuStyle, itemStyle] = React.useMemo(() => {


    const { navBarSize, footerSize } = sizes;
    const scrollRequired = ((50 * dropDownitems.length) >= size[1] - (navBarSize * 2));


    return [
      {
        height:   navBarSize,
      },
      {
        top:     navBarSize,
        height:  !open ? 0 : Math.min((50 * (dropDownitems.length)), (size[1] - (navBarSize * 2) - footerSize - 7)),
        opacity: !open ? 0 : 1
      },
      {
        marginLeft: scrollRequired ? 5 : 0,
      }
    ]
  },[ open, size, dropDownitems ])

  return (
        <ClickAwayListener onClickAway = { handleClickAway }>
            <div 
               className = { classes.selector }
               style     = { selectorStyle }
               onClick   = { () => { setopen(!open) }}
            >
                <MoreIcon className = { classes.icon }/>
                <div 
                   className = { classes.menu }
                   style     = { menuStyle } 
                 >
                  {
                     dropDownitems.map((val) => {
                       return(
                          <div 
                             key       = { `${ val }{d45}{}` }
                             className = { classes.item }
                             style     = { itemStyle }
                             onClick   = { () => { handleClick(val) }}
                          >
                             { val }
                          </div> 
                       )
                     })
                  }
                </div>         
            </div>
        </ClickAwayListener>
  );
}




export default DropDown;



