
import React                from 'react';
import { store }            from 'index';
import { GET_PUZZLES_SAGA } from 'actions/content_actions';
import { userAccessType }   from 'types';

const useGetPuzzles = (
    userEmail:         string,
    guest:             boolean,
    userAccess:        userAccessType,
    getPuzzlesOnce:    boolean,
    setGetPuzzlesOnce: (val: boolean) => void
) => {


    const state           = store.getState();
    const userInfoUpdated = state.data.dataUpdateState.user;
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const userInfo        = state.user.userInfo;


    React.useEffect(() => {

      if (userInfoUpdated && !studentLoggedIn && !getPuzzlesOnce ) {

        const payload = {
          userEmail:       userEmail,
          guest:           guest ? true : false,
          subscriber:      !userAccess.denyAccess || userAccess.freeUser,
          studentOpenApps: userInfo.studentOpenApps
        }
    
        setGetPuzzlesOnce(true)
        store.dispatch(GET_PUZZLES_SAGA(payload))
      }
    
    },[userInfoUpdated, studentLoggedIn, getPuzzlesOnce, userEmail, guest, userInfo, userAccess, setGetPuzzlesOnce])

}

export default useGetPuzzles;