import React            from 'react';
import Spinner          from './Spinner';
import { makeStyles }   from 'makeStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseSharpIcon   from '@mui/icons-material/CloseSharp';
import { colors }       from '../../../utils/colors';

var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({  root: {
      position:   'absolute',
      transition: 'all 1s',
      transform:  `rotate(180deg)`
    },
  mask: {
    width:           '50%',
    height:          '100%',
    position:        'absolute',
    left:            '50%',
    zIndex:          3
  },
  inner: {
    position:        'absolute',
    height:          '95%',
    width:           '95%',
    left:            '50%',
    top:             '50%',
    transform:       'translate(-50%, -50%) rotate(-180deg)',
    borderRadius:    '50%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    zIndex:          4
  },
  icon: {
    transition: 'all 0.7s',
  },
  shake: {
    animation:               'shake 0.4s',
   // animationIterationCount: 'infinite',
  } 
}))

type ResultIconProps = {
  compSize:     number;
  speed:        number;
  hide:         boolean;
  variant:      string;
}

const ResultIcon: React.FC<ResultIconProps> = ({
  compSize,
  speed,
  hide,
  variant
}) => {

  const { classes } = useStyles();


  const [initialPos, setInitialPos] = React.useState(false);


  React.useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setInitialPos(true)
    }
    return () => { unmounted = true };
  },[])


  const [rootStyle, maskStyle, innerStyle, iconStyle] = React.useMemo(() => {

     return[
        {
         // position:  !fixePosition ? 'static' : 'absolute' as 'static' | 'absolute',
          //top:       !fixePosition ? undefined : wideScreen ? 290 : 20,
          //left:      !fixePosition ? undefined : wideScreen ? 48 : 205,
          width:     compSize,
          height:    compSize,
          opacity:   (!initialPos || hide) ? 0 : 1,
        },
        {
          animation:       variant === 'failure' ? undefined : `mask2 ${ speed }s steps(1, end)`,
          borderRadius:    `0 ${ Math.max(compSize, 140) }px ${ Math.max(compSize, 140) }px 0`,
          backgroundColor: variant === 'success' ? colors.primary : colors.red
        },
        {
          backgroundColor: hide ? 'transparent' : '#FFF',
        },
        {
          fontSize: (!hide && initialPos) ? compSize / 1.7 : 0,
          color:    variant === 'success' ? colors.primary : colors.red
        }
     ]
  },[compSize,/* fixePosition, *//* wideScreen, */hide,speed,variant,initialPos])

  return (
    <div 
       className = { variant === 'failure' ? clsx(classes.root, classes.shake) : classes.root }
       style     = { rootStyle }
    >
      {
        !hide
        &&
        <>
            <Spinner 
                compSize  = { compSize }
                rotate    = { 0 }
                speed     = { speed }
                variant   = { variant }
            />
            <div 
                className = { classes.mask }
                style     = { maskStyle }
            /> 
            <Spinner 
                compSize  = { compSize }
                rotate    = { 1 } 
                speed     = { speed }
                variant   = { variant }
            /> 
        </>  
      }
            <div 
                className = { classes.inner }
                style     = { innerStyle }
            >
              {
                variant === 'success' &&
                <CheckRoundedIcon 
                    className = { classes.icon }
                    style     = { iconStyle }
                />
              }
              {
                variant === 'failure' &&
                <CloseSharpIcon 
                    className = { classes.icon }
                    style     = { iconStyle }
                />
              } 
            </div>
    </div>
  );
}

export default ResultIcon;


