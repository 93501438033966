
import { componentsStateType } from '../../reducer/types';


const studentContentClick = (
    wideScreen:       boolean,
    filterState:      componentsStateType,
    setFilterState:   (val: componentsStateType) => void,
    setShowSmallMenu: (val: boolean) => void,
) => {


if ( filterState.selectedContent !== 'myStudentsContent' ) {
    setFilterState({
      ...filterState,
      selectedGrades:      '1234567',
      selectedAppTopic:    '',
      selectedAppSubTopic: '',
      searchStr:           '',
      selectedContent:     'myStudentsContent',
      showTutorial:        false,
      showStudentVideo:    false,
      selectedVideo:       -1,
      openGradeGrid:       false,
      showTopicIntro:      false,
      showAppIntro:        false,
      createTopicArr:      false
    })

    if (!wideScreen) {
      setShowSmallMenu(false)
    } 
  }

}

export default studentContentClick;