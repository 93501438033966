import React          from 'react';
import Video          from 'pages/home/Video';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(() => ({    
    video: {
        flex:    1,
        height: '100%',
    }
}))


type VideoCompProps = {
    wideScreen: boolean;
}
 
const VideoComp: React.FC<VideoCompProps> = ({ wideScreen }) => {


  const { classes } = useStyles();

  return (     
      <div className = { classes.video } >
         <Video
            maxHeight  = { wideScreen ? 350 : 300 }
            wideScreen = { wideScreen }
            src        = { "https://iseemath.co/videos/intro.mp4" }
         />
      </div>
  );
}


export default VideoComp;
