import { planTypeType }   from "../../types";//
import { modalStateType } from "../../actions/types";//

const handleStoredPlan = (
    userSignedIn:           boolean,
    guest:                  boolean,
    freeUser:               boolean,
    freeTrial:              boolean,
    denyAccess:             boolean,
    unSubscribedAuthedUser: boolean,
    modalVariant:           string,
    setPlanType:            (val: planTypeType) => void,
    setModalState:          (val: modalStateType) => void,
    setShowPayForm:         (val: boolean) => void
) => {
    if (
       userSignedIn &&
       localStorage.getItem('subscribeAfterSignup') &&
       localStorage.getItem('subscribeAfterSignup') === 'true' &&
       localStorage.getItem('selectedPlan')
    ) {
       let plan: planTypeType = 'Monthly';
       const storedPlan = localStorage.getItem('selectedPlan');
       if ( storedPlan && ( storedPlan === "Annually" || storedPlan === "Basic" || storedPlan === "testPlan" )) {
         plan = storedPlan;
       }

       localStorage.removeItem('subscribeAfterSignup');
       localStorage.removeItem('selectedPlan');

       if (!guest && !freeUser && !freeTrial && (denyAccess || unSubscribedAuthedUser)) {
         console.log('plan  :   ',plan)
         setPlanType(plan)
         setModalState({
           variant:     modalVariant,
           open:        false,
           showButtons: true
         })
         setShowPayForm(true)
       }
    }
  }

export default handleStoredPlan;