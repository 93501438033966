import { call, take, put }           from 'redux-saga/effects';
import axios                         from 'axios';
import { UPDATE_HASH, RECORD_ERROR } from '../actions';

const endpoint = 'https://www.yisumatica.org.il/api/updateUserActivity.php';


function* updateUserActivity(): Generator<any,any,any> {
  while(true) {
    try {

      //console.log('updateUserActivity')


        const input = yield take('UPDATE_USER_ACTIVITY_SAGA');
        
        const { hash, userEmail, userName, lesson_id, action } = input.payload;

        const newHash = {
            ...hash
        }

        if (action === 'like') {
            newHash[lesson_id].liked = 1;
            newHash[lesson_id].totalLikes += 1;
        }

        if (action === 'dislike') {
            newHash[lesson_id].liked = 0;
            newHash[lesson_id].totalLikes = Math.max(newHash[lesson_id].totalLikes - 1, 0);
        }

        if (action === 'add_to_favorites') {
          console.log('add_to_favorites')
          newHash[lesson_id].selected = 1;
        }

        if (action === 'remove_from_favorites') {
          console.log('remove_from_favorites')

          newHash[lesson_id].selected = 0;
        }

        yield put(UPDATE_HASH( newHash ))

        if (process.env.NODE_ENV === 'development') {
           console.log('input : ',input)
        }
        if (process.env.NODE_ENV === 'production') {

            const obj = { 
              user_name:  userEmail,
              name_heb:   userName,
              lesson_id:  lesson_id,
              action:     action
            }

            const userActivity = yield call( axios.post, endpoint, obj);

            console.log('res  :  ',userActivity)
            if (userActivity && userActivity.data) {
                console.log('userActivity.data  :  ',userActivity.data)
            }
            if ( userActivity && userActivity.data && !Boolean(userActivity.data.success) && userActivity.data.msg ) {
              yield put(RECORD_ERROR({ userEmail: userEmail, err: userActivity.data.msg, endpoint: 'update_user_activity' }));
            }

          /*   else {
            console.log("can't update user activity")
            }    */

        }
       

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default updateUserActivity;
