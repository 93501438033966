import { call, take }  from 'redux-saga/effects';
import axios           from 'axios';

const prod     = process.env.NODE_ENV === 'production';
const endpoint = 'https://iseemath.co/api/test.php';

 
function* doTest(): Generator<any,any,any> {
  while(true) {
    try {

        yield take('DO_TEST'); 

        console.log('doing test')
     
        if (prod) {
          const res  = yield call( axios.post, endpoint);
          console.log('res : ',res)
        }
    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default doTest;





