
import { store } from 'index';

const getAppLessons = (lesson_id: string) => {
   
    const state        = store.getState();
    const contentState = state.content;
    const { lessons }  = contentState;

    let res = [];

    for (let i = 0; i < lessons.length; i++) {
        if (lessons[i].father_app === lesson_id) {
                res.push(lessons[i])
        }
    }

    return res;
    
}


export default getAppLessons;


