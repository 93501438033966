import React          from 'react';
import IframeComp     from '../IframeComp';
import { makeStyles } from 'makeStyles';

const text1 = [
    'Established in 2014, I SEE MATH Provides math teachers with dozens of applications and lessons for students in grades 1-6.',
    'Our developers have over 25 years of experience in developing study materials for teaching mathematics.',
]

const useStyles = makeStyles()(
  (Theme) => ({    
    root: {
        backgroundColor: 'transparent',
        display:         'flex',
        alignItems:      'center',
        paddingBottom:  25,
        paddingTop:     25,
    },
    halfRow: {
        flex:            0.4,
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        flexFlow:        'column',
    },
    halfRow2: {
        flex:            0.6,
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
    },
    text: {
        lineHeight:    1.5,
        fontWeight:    'bold',
        //paddingBottom: 40,
        flex:            0.4,


    },
}))


type Row1Props = {
    size:       number[];
    wideScreen: boolean;
}
 
const Row1: React.FC<Row1Props> = ({ size, wideScreen }) => {

 const { classes } = useStyles();

 const [rootStyle, halfRowStyle1, halfRowStyle2, textStyle] = React.useMemo(() => {

     const unitHeight = wideScreen ? 400 : 900;

     return[
       { 
          flexFlow:       wideScreen ? 'row'  : 'column',
          width:          wideScreen ? '70vw' : '96vw',
          marginLeft:     wideScreen ? '15vw' : '2vw',  
        //  paddingBottom:  wideScreen ? 50 : 0,
         // marginTop:      20,//wideScreen ? 50 : 8,
          justifyContent: wideScreen ? 'space-between' : 'center',
       },
       {
           flex:           wideScreen ? undefined  : 1,
           paddingLeft:    wideScreen ? 0 : 5,
           paddingRight:   wideScreen ? 0 : 5,
           paddingBottom:  wideScreen ? undefined : 20,
           marginTop:      wideScreen ? undefined : 35,
          // width:        wideScreen ? '100%' : '90%',
       },
       {
           height:       wideScreen ? unitHeight : unitHeight * 0.4,
           minHeight:    wideScreen ? unitHeight : unitHeight * 0.4,
           maxHeight:    wideScreen ? unitHeight : unitHeight * 0.4,
           width:        wideScreen ? size[0] * 0.4 : (size[0] > 750 ? size[0]* 0.6 : size[0] * 0.9),
           maxWidth:     wideScreen ? size[0] * 0.4 : (size[0] > 750 ? size[0]* 0.6 : size[0] * 0.9),
       },
       {
        fontSize: wideScreen ? 28 : 23
       }
     ]
 },[wideScreen, size])


  return (
       
       <div
           className = { classes.root }
           style      = { rootStyle }
       >
            <div 
               className = { classes.halfRow }
               style     = { halfRowStyle1 }
            >
                <div 
                   className = { classes.text }
                   style     = { textStyle }
                >
                    {
                        text1.map((val, ind) => {
                            return(
                                <div
                                key = { `sdf${ val }${ ind }` }
                                >
                                    { val }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div 
                className = { classes.halfRow2 }
                style     = { halfRowStyle2 }
            >
                    <IframeComp
                       size       = { size }
                       wideScreen = { wideScreen }
                       rootStyle  = { halfRowStyle2 }
                    />
            </div>
        </div>
         
  );
}


export default Row1;
