import React           from 'react';
import content         from './content';
import { makeStyles }  from 'makeStyles';

const useStyles = makeStyles()(() => ({   
    kav: {
        marginLeft:  10,
        marginRight: 10,
    },
    title: {
      width:          '100%',
      display:        'flex',
      flexFlow:       'row',
      fontSize:       19,
      fontWeight:     'bold',
      paddingBottom:  12,
      letterSpacing:  1
    },
}))


type TitleProps = {
    variant:    string;
    wideScreen: boolean;
}
 
const Title: React.FC<TitleProps> = ({
    variant,
    wideScreen
  }) => {



  const { classes } = useStyles();

  const rootStyle = React.useMemo(() => {
    return {
        fontSize:       wideScreen ? 19 : 16,
        justifyContent: wideScreen ? undefined : 'center',
        alignItems:     wideScreen ? undefined : 'center',
    }
  },[wideScreen])


  return (                    
                     <div 
                       className = { classes.title }
                       style     = { rootStyle }
                     >
                       <div>
                          { content[`${ variant }`].title[0] }
                       </div>
                       <div className = { classes.kav }>
                          |
                       </div>
                       <div>
                          { content[`${ variant }`].title[1] }
                       </div>
                    </div>
                    
  );
}


export default Title;
