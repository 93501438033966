
const checkAnswerNumeric = (
    success:          string,
    number:           string,
    numerator:        string,
    denominator:      string,
    inputInteger:     string,
    inputNumerator:   string,
    inputDenominator: string,
    integer:          boolean,
    fraction:         boolean,
    combined:         boolean,
    setSuccess:       (val: string) => void,
) => {

  
    if (integer) {
       if (inputInteger.length && inputInteger === number) {
          setSuccess('true')
       } 
       else {
         if (success !== 'false') {
           setSuccess('false')
         }
       }
    }
    else if (fraction) {
      if (
          (inputNumerator.length && inputNumerator === numerator)
          && 
          (inputDenominator.length && inputDenominator === denominator)
         ) {
         setSuccess('true')
      }
      else {
        if (success !== 'false') {
          setSuccess('false')
        }
      }
    }
    else if (combined) {
      if (
          (inputInteger.length && inputInteger === number)
          && 
          (inputNumerator.length && inputNumerator === numerator)
          && 
          (inputDenominator.length && inputDenominator === denominator)
         ) {
         setSuccess('true')
      }
      else {
        if (success !== 'false') {  
          setSuccess('false')
        }
      }
    }

}

export default checkAnswerNumeric;