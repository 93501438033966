import React                  from 'react';
import { userInfoType }       from '../../types'
import { DateTime }           from 'luxon';
import { makeStyles }         from 'makeStyles';

var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({    root: {
       width:          '80%',
       marginLeft:     '10%',
       flex:           1,
       display:        'flex',
       flexFlow:       'column',
       paddingBottom:  30,
    },
    container: {
      width:          '100%',
      height:         '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'celter',
    },
    column: {
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'celter',
       flexFlow:       'column',
    },
    narrow: {
        width: 160,
    },
    wide: {
        flex: 1,
    },
    bold: {
      fontWeight: 'bold'
    },
    item: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'celter',
        height:         40,
        width:          '100%',
    },
    
}))

type SubDetailsType = { userInfo: userInfoType; }

const SubDetails: React.FC<SubDetailsType> = ({ userInfo }) => {


  const { classes } = useStyles();

   const { allowTrial, trialStartedAt, firstPayment, subscriptionType, subscriptionId } = userInfo;

   const subType = subscriptionType === 'Annually' ? 'Annual' : subscriptionType;

   const trialEndsAt = React.useMemo(() => {
       const start = DateTime.fromISO(trialStartedAt);
       const end = start.startOf('day').plus({ days: 30 }).toFormat('yyyy-LL-dd');
       return end;
  },[trialStartedAt])

   return (
    <div className = { classes.root }>
       { 
            userInfo.subscriptionSuspended 
            &&
            <div className = { clsx(classes.item, classes.bold) }>
               Your deleted subscription details:
            </div>
       }
       <div className = { classes.container }>
         <div className = { clsx(classes.column, classes.narrow, classes.bold) }>
            {
              allowTrial
              &&
              <>
                 <div className = { classes.item }>plan type:</div>
                 <div className = { classes.item }>startet at:</div>
                 <div className = { classes.item }>ends at:</div>
              </>
            }
            {
              !allowTrial
              &&
              <>
                 {
                   subType.length > 0 && 
                   <div className = { classes.item }>plan type:</div>
                 }
                 <div className = { classes.item }>subscription ID:</div>
                 <div className = { classes.item }>start date:</div>
                {/*  {
                   !blocked &&  
                   <div className = { classes.item }>
                      { `${ suspendSubscriptionAt.length > 0 ? "deactivation at:" : "next payment:" }` }
                   </div>
                 } */}
                 <div className = { classes.item }>
                   { `${ userInfo.subscriptionSuspended ? 'deleted at' : 'status'}:` }
                 </div>
              </>
            }
         </div>
         <div className = { clsx(classes.column, classes.wide) }>
            {
              allowTrial
              &&
              <>
                 <div className = { classes.item }>I SEE MATH Basic Plan</div>
                 <div className = { classes.item }>{ trialStartedAt }</div>
                 <div className = { classes.item }>{ trialEndsAt }</div>
              </>
            }
            {
              !allowTrial
              &&
              <>
                  {
                   subType.length > 0 && 
                   <div className = { classes.item }>{ subType }</div>
                  }
                  <div className = { classes.item }>{ subscriptionId }</div>
                  <div className = { classes.item }>{ firstPayment }</div>
                  {/* {
                    !blocked
                    &&
                    <div className = { classes.item }>
                      { suspendSubscriptionAt.length > 0 ? suspendSubscriptionAt : nextPayment }
                    </div>
                  } */}
                  <div className = { classes.item }>
                    { `${ userInfo.subscriptionSuspended ? userInfo.subscriptionSuspendedAt : 'active'}` }
                  </div>
              </>
            }
         </div>
      </div>
    </div>   
  );
}




export default SubDetails;



