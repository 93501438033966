

import React                 from "react";
import { colors }            from 'utils/colors';
import { userInfoType }      from 'types';
import { makeStyles }        from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root : {
      position:        'fixed',
      left:            0,
      top:             0,
      height:          '100vh',
      width:           '100vw',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
     // backgroundColor: 'rgba(0,0,0,0.3)',
      transition:      'opacity 0.5s',
      transform:       'all 0.4s',
      zIndex:          2
     // letterSpacing:   1
  },
  modal: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    backgroundColor: '#FFF',
    border:          '1px solid #DDD',
    borderRadius:    '0.25em',
    boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)'
  },
  container: {
    width:           '100%',
    flex:            1,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'space-between',
    flexFlow:        'column',
  },
   title: {
    paddingTop:      100,
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    fontSize:       20,
    fontWeight:     'bold',
    lineHeight:     1.7
  }, 
  name: {
    fontWeight: 'bold',
    fontSize:   18,
  },
  button: {
    height:          50,
    marginTop:       25,
    width:           '60%',
    minWidth:        330,
    backgroundColor: colors.primary,
    color:           '#FFF',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    textAlign:       'center',
    fontWeight:      'bold',
    borderRadius:    '0.3em',
    cursor:          'pointer',
    border:          `2px solid ${ colors.primary }`,
    '&:hover': {
        opacity: 0.7
    }
  },
  close: {
    flex:           0.2,
    maxHeight:      75,
    minHeight:      60,
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    borderTop:      '1px solid #DDD',
    color:          colors.blue,
    fontSize:       18,
    cursor:         'pointer',
    fontWeight:     'bold'
  }
}))


type TrialFormProps = {
  userInfo:         userInfoType;
  modalStyle:       { height: number, width: number };
  handleClose:      () => void;
  handleStartTrial: () => void;
}

const TrialForm: React.FC<TrialFormProps> = ({ 
  userInfo,
  modalStyle,
  handleClose,
  handleStartTrial
 }) => {


  const { classes } = useStyles();

  const startTrial = () => {
     handleClose();
     handleStartTrial();
  }

  return (
    <div className = { classes.root }>
      <div 
        className = { classes.modal }
        style     = { modalStyle }
      >
        <div className = { classes.container }>
            <div className = { classes.title }>
              <div>I See Math Basic Plan</div>
              <div>Free for 30 days</div>
            </div>
            <div className = { classes.name }>{ userInfo.userName }</div>
            <div 
               className = { classes.button }
               onClick   = { startTrial }
            >
                Start Now
            </div>
            <div 
              className = { classes.close }
              onClick   = { handleClose }
            >
              Close
            </div>
        </div>
      </div>
    </div>
  );
}

export default TrialForm;


