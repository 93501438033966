
//import { audioStateType } from "reducer/types";
import { contentInfoType } from "types";


export const SET_AUDIO_STATE = (payload: contentInfoType) => ({
  type: 'SET_AUDIO_STATE',
  payload: payload
});










 


