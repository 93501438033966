import React                      from 'react';
import TextField                  from '@mui/material/TextField';
import { makeStyles }             from 'makeStyles';
import useTextFieldStyle          from 'components/authenticator/functions/get_textfield_style';
import { colors }                 from 'utils/colors';
import { studentLoginType }       from 'actions/types';

var clsx = require('classnames');

const useStyles = makeStyles()((Theme) => ({  
  root: {
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',
  },
  logo: {
    width:              200,
    height:             65,
    backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
    marginBottom:      15,

  },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center'
  },
  text_field: {
    height:          45,
   // fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
  },
  password: {
    marginTop: 10
  },
  forgot_pwd: {
     cursor:    'pointer',
     color:     colors.darkText,
     textAlign: 'center',
     margin:    20,
     '&:hover': {
      opacity: 0.5
    } 
  },
  login: {
    marginTop:       40,
    color:           colors.darkText,
    width:           250,
    height:          40,
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    backgroundColor: '#FFF',
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
   },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        18,
  },
  signup: {
      height:          43,
      width:           350,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      marginTop:       10,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      backgroundColor: colors.primary,
      color:           '#FFF',
      borderRadius:    '0.25em',
      '&:hover': {
         opacity: 0.5
      }
  },
  loader_container: {
    width:   60,
    height:  60,
  },
  txt: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    textAlign:      'center',
    height:         20,
    cursor:         'default'
  },
  black: {

  },
  red: {
    color:          '#F00',
  },
  iseemath: {
    marginTop:  5,
    fontSize:   18,
    fontWeight: 'bold'
  }
}));

type StudentLoginFormProps =  {
  size:              number[];
  email:             string;
  code:              string;
  error:             string;
  name:              string;
  grade:             string;
  showLogo:          boolean; 
  setError:          (val: string) => void;
  setEmail:          (val: string) => void;
  setCode:           (val: string) => void;
  setGrade:          (val: string) => void;
  setName:           (val: string) => void;
  setShowLoader:     (val: boolean) => void;
  studentLogin:      (payload: studentLoginType) => void;
};

/**
 * 
 * studentName
studentClass
 *
 */

const StudentLoginForm: React.FC<StudentLoginFormProps> = ({
   size,
   email,
   code,
   name,
   grade,
   error,
   showLogo,
   setCode,
   setName,
   setGrade,
   setError,
   setShowLoader,
   setEmail,
   studentLogin
  }) => {

  const { classes } = useStyles();


  const textFieldStyle = useTextFieldStyle(size);


  const changeInputValue = (e: React.FormEvent) => {
    
    e.preventDefault();
    const target = e.target as HTMLInputElement;

    if (target.id === 'name')   { setName(target.value) }
    if (target.id === 'grade')  { setGrade(target.value) }
    if (target.id === 'email')  { setEmail(target.value) }
    if (target.id === 'code')   { setCode(target.value) }

  }


  const handleSubmit = async() => {

    if (!name.length) { 
      setError('Please enter your name'); 
      return 
    }
    if (!grade.length) { 
      setError('Please enter your class'); 
      return 
    }
    if (!email.length) { 
      setError('Teachers Email cannot be empty'); 
      return 
    }
    if (!code.length)  { 
      setError('Teacher code cannot be empty'); 
      return 
    }

    const payload = { email: email, code: code, name: name, grade: grade, reset: false }

    setShowLoader(true);
    studentLogin(payload)
  }

  
  
  return (
     <div className = { classes.root }>
              { showLogo && <div className = { classes.logo }/> }
              <div className = { classes.txt }>Enter your name, class and teachers credentials</div>
              <div className = { clsx(classes.txt ,classes.red) }> { error } </div>
                <form className = { classes.form } >
                  <TextField
                     className    = { classes.text_field }
                     style        = { textFieldStyle }
                     variant      = "standard"
                     id           = "name"
                     label        = "Your name"
                     autoComplete = "off"
                     onChange     = { changeInputValue }
                  />
                  <TextField
                     className    = { classes.text_field }
                     style        = { textFieldStyle }
                     variant      = "standard"
                     id           = "grade"
                     label        = "Your class"
                     autoComplete = "off"
                     onChange     = { changeInputValue }
                  />
                  <TextField
                     className    = { classes.text_field }
                     style        = { textFieldStyle }
                     variant      = "standard"
                     id           = "email"
                     label        = "Your teachers Email"
                     autoComplete = "off"
                     onChange     = { changeInputValue }
                  />
                  <TextField
                     className    = { classes.text_field }
                     style        = { textFieldStyle }
                     variant      = "standard"
                     id           = "code"
                     label        = "Your teachers code"
                     autoComplete = "off"
                     onChange     = { changeInputValue }
                  />
                </form>
                <div 
                   onClick   = { handleSubmit } 
                   className = { classes.login }
                >
                    <div className = { classes.buttonText }>Log In</div>
                </div>     
      </div>
    )
}



export default StudentLoginForm;