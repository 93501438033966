import { call, take, put  }    from 'redux-saga/effects';
import axios                   from 'axios';
import { RECORD_ERROR }        from 'actions'; 
import { 
  SET_MODAL_STATE, 
  CREATE_ERROR 
}                              from 'actions/modal_actions'

const dev = process.env.NODE_ENV === 'development';
const createSubscriptionEndpoint = 'https://iseemath.co/api/createSubscription.php';


function* createSubscription(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('CREATE_SUBSCRIPTION_SAGA');

        console.log('userInput    :   ',userInput)

        yield put(SET_MODAL_STATE({ variant: 'loader', open: true, showButtons: false })); 

        console.log('CHANGE_MODAL_STATE')

        localStorage.removeItem('subscriberEmail');

        const { payload } = userInput;

        console.log('payload:   ',payload)

        const res = yield call( axios.post, createSubscriptionEndpoint, payload );

        if ((dev) || (res && res.data && res.data.success && res.data.success === 1 )) {
          console.log('subsciptionCreated')
          yield put(SET_MODAL_STATE({ variant: 'subsciptionCreation', open: true, showButtons: true })); 

        }
        else {
          console.log('subsciptionNotCreated')
          yield put(CREATE_ERROR());
          if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
            yield put(RECORD_ERROR({ userEmail: payload.userEmail, err: res.data.msg, endpoint: 'create_subscription' }));
          }
        }

                
    }
    catch(err) {
      yield put(CREATE_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default createSubscription;
