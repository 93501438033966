import { contentInfoType } from "../../../types";


const handlePrevTest = (
    questionSet:         contentInfoType[],
    questionObj:         contentInfoType,
    testNum:             number,
    setQuestionObj:      (val: contentInfoType) => void,
    setTestNum:          (val: number) => void,
    setAnimateImage:     (val: boolean) => void,
    setAnimateInput:     (val: boolean) => void,
    setNumber:           (val: string) => void,
    setNumerator:        (val: string) => void,
    setDenominator:      (val: string) => void,
    setInputInteger:     (val: string) => void,
    setInputNumerator:   (val: string) => void,
    setInputDenominator: (val: string) => void, 
    setSelectedAnswer:   (val: number) => void
) => {
        if (testNum === 0 ) { return;}
    
        const newTest = testNum - 1;
    
        if ( questionObj.image !== questionSet[newTest].image ) { 
          setAnimateImage(true); 
        }
    
        setAnimateInput(true);
    
        setTimeout(() => {
          setNumber('')
          setNumerator('')
          setDenominator('')
          setInputInteger('')
          setInputNumerator('')
          setInputDenominator('') 
          setSelectedAnswer(-1);
          setTestNum(newTest)
          setQuestionObj(questionSet[newTest])
        },300)
}

export default handlePrevTest