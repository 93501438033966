
import React                        from 'react';
import { Dispatch }                 from "redux";
import { connect }                  from 'react-redux';
import { reducerType }              from 'reducer';
import { 
  ActionTypes, 
  createSubscriptionType, 
  createTrialType
}                                   from 'actions/types';
    
import DefaultForms                 from './forms/DefaultForms';
import UKForms                      from './forms/UKForms';
import TestForm                     from './forms/TestForm';
import TrialForm                    from './forms/TrialForm';

import removeSubscribePrompt        from "functions/payments/remove_subscribe_prompt";
import handleSubscribe              from 'functions/payments/handle_subscribe';

import { 
  userAttributesType, 
  userInfoType 
}                                   from 'types';
import { 
  authStateType, 
  modalStateType
}                                   from 'reducer/types';
import { 
  CREATE_FREE_TRIAL_SAGA, 
  CREATE_SUBSCRIPTION_SAGA 
}                                   from 'actions';
import { makeStyles }               from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
        position:        'fixed',
        left:            0,
        top:             0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        transition:      'opacity 0.5s',
        transform:       'all 0.4s',
        zIndex:          2
    },
    modal: {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      flexFlow:        'column',
      backgroundColor: '#FFF',
      border:          '1px solid #DDD',
      borderRadius:    '0.25em',
      boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)'
    },
}))



type PayFormsProps = {
    size:               number[];
    userAttributes:     userAttributesType;
    userInfo:           userInfoType;
    authState:          authStateType;
    modalState:         modalStateType;
    planType:           string;
    tester:             string;
    showPayForm:        boolean;
    setShowPayForm:     (val: boolean) => void;
    startTrial:         (payload: createTrialType) => void;
    createSubscription: (payload: createSubscriptionType) => void;

  }
  
  const PayForms: React.FC<PayFormsProps> = ({ 
     size, 
     userAttributes,
     userInfo,
     authState,
     modalState,
     planType,
     showPayForm,
     tester,
     setShowPayForm,
     startTrial,
     createSubscription
  }) => {

    const { classes } = useStyles();


    const handleClose = () => {
      removeSubscribePrompt();
      setShowPayForm(false);
    } 

    const subscribe = (data: any) => {
      handleSubscribe( data, authState.email, userAttributes, setShowPayForm, createSubscription );
    }


    const handleStartTrial = () => {

      const attName  = !!userAttributes.userName.length && userAttributes.userName !== 'Guest';
      const attEmail = !!userAttributes.userEmail.length && userAttributes.userEmail !== 'Guest';
      const InfName  = !!userInfo.userName.length && userInfo.userName !== 'Guest';
      const InfEmail = !!userInfo.userEmail.length && userInfo.userEmail !== 'Guest';
  
      const payload = {
        userName:   attName  ? userAttributes.userName  : InfName  ? userInfo.userName : authState.name,
        userEmail:  attEmail ? userAttributes.userEmail : InfEmail ? userInfo.userEmail : authState.email,
        userOrigin: authState.userOrigin.length > 0 ? authState.userOrigin : userInfo.userOrigin,
       }
  
       startTrial(payload)
    }
  

   

    const modalStyle = React.useMemo(() => {
        return{
                  width:  Math.min(630, size[0]),
                  height: Math.min(500, size[1]),
              }
    },[size])


    return (
      <div 
          className = { classes.root }
          style     = {{
              transform: `translate(0px, ${ showPayForm ? 0 : 150 }vh)`
          }}
      >
          {
            planType === 'Basic'
            &&
            <TrialForm
               modalStyle       = { modalStyle }
               userInfo         = { userInfo }
               handleClose      = { handleClose }
               handleStartTrial = { handleStartTrial } 
            />
          }
          {
            (planType === 'AnnuallyUK' || planType ===  'MonthlyUK') &&
            <UKForms
                size        = { size } 
                modalState  = { modalState }
                planType    = { planType === 'AnnuallyUK' ? 'Annually' : 'Monthly' }
                showPayForm = { showPayForm }
                tester      = { tester }
                subscribe   = { subscribe }
                handleClose = { handleClose }
            />
          }
          {
            (planType === 'Annually' || planType ===  'Monthly') &&
            <DefaultForms
                size         = { size } 
                modalState   = { modalState }
                planType     = { planType }
                showPayForm  = { showPayForm }
                tester       = { tester }
                subscribe    = { subscribe }
                handleClose  = { handleClose }
            />
          }
          {
            planType === 'testPlan' &&
            <TestForm
                size         = { size } 
                modalState   = { modalState }
                planType     = { planType }
                showPayForm  = { showPayForm }
                tester       = { tester }
                subscribe    = { subscribe }
                handleClose  = { handleClose }
            />
          }
      </div>
  )}


const mapStateToProps = (reducer: reducerType) => {
  return {
    userInfo:   reducer.user.userInfo,
    authState:  reducer.auth,
    modalState: reducer.modal
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  createSubscription: (payload: createSubscriptionType) => dispatch(CREATE_SUBSCRIPTION_SAGA(payload)),
  startTrial:         (payload: createTrialType) => dispatch(CREATE_FREE_TRIAL_SAGA(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(PayForms);