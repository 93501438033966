import React                    from 'react';
import { colors }               from '../../utils/colors';
//import SearchRoundedIcon        from '@mui/icons-material/SearchRounded';
import { makeStyles }           from 'makeStyles';
//import { modalStateType } from '../../types';


var clsx = require('classnames');

const useStyles = makeStyles()((Theme) => ({     
      root: {
       // minHeight:    50,
       // maxHeight:    50,
        width:        '80%',
        marginLeft:   '10%',
        textAlign:    'left',
        marginBottom: 50
      },
      container: {
        minHeight:      50,
        marginLeft:     0,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
      },
      iconContainer: {
        width:           10,
        height:          50,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        transition:      'all 0.3s'
      },
      error: {
          color:          '#F00',
          marginBottom:   15,
          display:        'flex',
          alignItems:     'center',
          justifyContent: 'center',
          textAlign:      'center'
      },
      text_field: {
        height:          '100%',
        marginLeft:      10,
        minHeight:       50,
        width:           '100%',
        fontSize:        17,
        fontFamily:      'Raleway',
        cursor:          'pointer',
        border:          0,
        backgroundColor: 'transparent',
        '&:focus': {
           outline: 0,
           cursor:  'auto',
        }
      },
      dark: {
        color: colors.menuText,
        '&::placeholder': {
          color: colors.brightText,
        }
      },
      bright:{
        color: colors.hoverText,
        '&::placeholder': {
          color: colors.hoverText,
        }
      },
      effectContainer: {
      //  marginRight:      '5%',
        width:            '100%',
        height:           1,
        zIndex:           1,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
      },
      effect: {
        borderBottom: `2px solid ${ colors.blue }`,
        transition:   '0.3s'
      },
}))

type TextInputProps = {
    variant:       string;
    groupStr:      string;
    groupSearch:   string;
    setGroupStr:   (val: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({
    variant,
    groupStr,
    groupSearch,
    setGroupStr
}) => {

  //console.log('modalState.modalVariant : ',modalState.modalVariant)

  const { classes } = useStyles();

  const [focus, setFocus]               = React.useState(0);
  const [hover, setHover]               = React.useState(false)
  const [initialFocus, setInitialFocus] = React.useState(false)


  const groupSearchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
  
      if (groupSearchRef && groupSearchRef.current && !initialFocus) {

          groupSearchRef.current.focus();
          setFocus(1);
          setInitialFocus(true)
        }
          
  },[groupSearchRef, initialFocus])


  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;               
   
    setGroupStr(target.value)
    
  }


  const on_focus = () => { 
    setFocus(1) 
  }

  const on_blur = () => { 
      setFocus(0) 
  }

  const rootStyle = React.useMemo(() => {
      return{
        borderBottom: `1px solid ${ (hover && !focus) ? '#DDD' : focus ? 'transparent' : '#666' }`,
      }
  },[focus, hover])

  return (
    <div
       className    = { classes.root }
       onMouseEnter = { () => { setHover(true) }}
       onMouseLeave = { () => { setHover(false) }}
       style        = { rootStyle }
    >
        <div className = { classes.error }>
              { 
                 variant === 'emptyGroup' && !!groupSearch.length && `No results for '${ groupSearch }'` 
              }
        </div>
        <div className = { classes.container }>
            <input
                className    = { clsx(classes.text_field, ((hover && !focus) ? classes.bright : classes.dark)) }
                type         = "text"
                id           = "code"
                ref          = { groupSearchRef }
                value        = { groupStr }
                placeholder  = { 'enter group name' }
                onChange     = { changeInputValue }
                onFocus      = { on_focus }
                onBlur       = { on_blur }
                autoComplete = "off"
            />
         </div>
         <div className = { classes.effectContainer } >
            <div
               className = { classes.effect }
               style     = {{
                 width: focus ? '100%' : 0
              }}
            />
         </div>
    </div>
  );
}




export default TextInput;



