import React                    from 'react';
import { connect }              from 'react-redux';
import { reducerType }          from 'reducer';
import Button                   from './Button';
import signIn                   from 'functions/auth/sign_in';
import signOut                  from 'functions/auth/sign_out';
import messageSubmition         from 'functions/modal/message_submition';
import closeModal               from 'functions/modal/close_modal';
import reload                   from 'functions/reload';
import goToPayForm              from 'functions/modal/go_to_payform';
import handleUpdateUserName     from 'functions/modal/handle_update_user_name';


import goToPlanSelection        from './functions/go_to_plan_selection';
import handleAbortSuspension    from './functions/handle_abort_suspension';
import unsubscribe              from './functions/unsubscribe';
//import setSuspensionTime        from './functions/set_suspension_time';
import handlReactivateSub       from './functions/handle_reactivate_sub';
import setSubscriptionState     from './functions/set_subscription_state';
import goToApp                  from './functions/go_to_app';
import goToSubscribe            from './functions/go_to_subscribe';
import disableVariant           from './functions/disable_variant';

import { 
  planTypeType, 
  userAccessType, 
  userAttributesType, 
  userInfoType 
}                               from 'types'
import { colors }               from 'utils/colors';
import { makeStyles }           from 'makeStyles';

const maxHeight = 55;
const minHeight = 55;

const useStyles = makeStyles()((Theme) => ({    
        buttonContainer: {
        flex:            0.2,
        maxHeight:       maxHeight,
        minHeight:       minHeight,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-evenly',
        borderTop:       '1px solid #DDD'
    },
    button: {
        width:           '50%',
        height:          '100%',
        maxHeight:       maxHeight,
        minHeight:       minHeight,
        fontSize:        17,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        color:           colors.blue,
        fontWeight:      'bold',
        cursor:          'pointer',
        textDecoration:  'none',
        '&:hover': {
            opacity: 0.5
        }
    },
    disabledButton: {
      opacity:         0.4,
      width:           '50%',
      height:          '100%',
      maxHeight:       maxHeight,
      minHeight:       minHeight,
      fontSize:        17,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           colors.blue,
      fontWeight:      'bold',
      cursor:          'default',
      textDecoration:  'none'
    },
    redButton: {
        width:           '50%',
        height:          '100%',
        fontSize:        17,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        color:           '#D55',
        fontWeight:      'bold',
        cursor:          'pointer',
        textDecoration:  'none',
        '&:hover': {
            opacity: 0.5
        }
    }
}))




type ButtonsType = {
    variant:                string;
    groupStr:               string;
    tester:                 string;
    lessonNumber:           string;
    userAttributes:         userAttributesType;
    userAccess:             userAccessType;
    userInfo:               userInfoType;
    planType:               planTypeType;
    guest:                  boolean;
    setError:               (val: string) => void,
    setShowPayForm:         (val: boolean) => void;
    completeSignUp:         () => void;
    goToGroup:              () => void;
}

const Buttons: React.FC<ButtonsType> = ({
    variant,
    groupStr,
    userInfo,
    planType,
    lessonNumber,
    userAttributes,
    userAccess,
    guest,
    tester,
    setError,
    setShowPayForm,
    completeSignUp,
    goToGroup,
}) => {

  const { classes } = useStyles();

  const [suspendedSub, /* hasPassword, */ freeTrial] = React.useMemo(() => {
     const { subscriptionSuspended, /* hasPassword, */ allowTrial } = userInfo;
     return[ subscriptionSuspended, /* hasPassword, */ allowTrial ]
  },[userInfo])

  


  const buttonProps = React.useMemo(() => {

    const { userEmail } = userAttributes;

    const joinLesson = () => {
      import("./functions/joinLesson").then(func => {
        func.joinLesson(lessonNumber, setError) 
      });
    }

    const handleCloseModal = () => { closeModal(userAccess.subscriptionStatus) }
    const myAccountTxt = freeTrial ? 'Subscribe' : (suspendedSub) ? 'Reactivate Subscription' : 'Unsubscribe';
    
    const obj: { [key: string]: { [key: string]: any; }} = {
      'log out':                  { 'txt': 'Sign Out',              'func': signOut,                                   'class': classes.button },
      'subscribe':                { 'txt': 'Continue',              'func': goToPlanSelection,                         'class': classes.button },
      'trialCompletion':          { 'txt': 'Continue',              'func': goToPlanSelection,                         'class': classes.button },
    //  'selectPlan':               { 'txt': 'Start Now',             'func': startNowFunc,                              'class': planType === '' ? classes.disabledButton : classes.button },
      'my account':               { 'txt': myAccountTxt,            'func': setSubscriptionState,                      'class': (suspendedSub || freeTrial) ? classes.button : classes.redButton },
      'deactivationSuccess':      { 'txt': 'Cancel Deactivtion',    'func': handleAbortSuspension,                     'class': classes.button },
      'closeAccount':             { 'txt': 'Yes, Unsubscribe',      'func': () => { unsubscribe(tester) },             'class': classes.redButton },
      'subsciptionCreation':      { 'txt': 'Continue',              'func': guest ? signIn : reload,                   'class': classes.button },
      'freeTrialCreated':         { 'txt': 'Continue',              'func': guest ? signIn : reload,                   'class': classes.button },
      'screenTooSmall':           { 'txt': 'Continue To App',       'func': () => { goToApp(disableVariant) },         'class': classes.button },
      'badBrowser':               { 'txt': 'Continue To App',       'func': () => { goToApp(disableVariant) },         'class': classes.button },
      'join lesson':              { 'txt': 'Submit',                'func': joinLesson,                                'class': classes.button },
      'followLinkNotFound':       { 'txt': 'Submit',                'func': joinLesson,                                'class': classes.button },
      'groups':                   { 'txt': 'Find Lessons In Group', 'func': goToGroup,                                 'class': groupStr === '' ? classes.disabledButton : classes.button },
      'emptyGroup':               { 'txt': 'Find Lessons In Group', 'func': goToGroup,                                 'class': groupStr === '' ? classes.disabledButton : classes.button },
      'updateUserName':           { 'txt': 'Submit',                'func': () => { handleUpdateUserName(userEmail) }, 'class': classes.button },
      'Contact us':               { 'txt': 'Submit',                'func': messageSubmition,                          'class': classes.button },
      'close':                    { 'txt': 'Close',                 'func': handleCloseModal,                          'class': classes.button },
      'disabledfavorites':        { 'txt': 'Continue',              'func': goToSubscribe,                             'class': classes.button },
      'disabledgroups':           { 'txt': 'Continue',              'func': goToSubscribe,                             'class': classes.button },
      'disableduserLessons':      { 'txt': 'Continue',              'func': goToSubscribe,                             'class': classes.button },
      'disabledmyStudentsContent':{ 'txt': 'Continue',              'func': goToSubscribe,                             'class': classes.button },
      'showSuspendedSub':    [
                                { 'txt': 'Reactivate Subscription', 'func': handlReactivateSub, 'class': classes.button },
                                { 'txt': 'Create New Subscription', 'func': goToPayForm,        'class': classes.button }
                             ]
    }

    if ( variant === 'showSuspendedSub' ) {
      return [obj[variant][0], obj[variant][1]];
    }

    if ( variant === 'my account' && userInfo.blocked ) {
      return [obj['close']];
    }

    if ( variant === 'subsciptionCreation' || variant === 'freeTrialCreated' ) {
      return [obj[variant]];
    }

    return obj[variant] ? [obj[variant], obj['close']] : [obj['close']];

  },[
    variant,
    userAttributes,
   // planType,
    classes,
    suspendedSub,
    guest,
    tester,
    freeTrial,
    lessonNumber,
    userInfo,
    groupStr,
    userAccess,
    goToGroup,
    setError
   // handleUpdateUserName,
   // startNowFunc
  ])


  return (
    <div className = { classes.buttonContainer }>
        {
          buttonProps.map((val, ind) => {
            return(
              <Button
                 key     = { `${ val['txt']}:${ ind }` }
                 props   = { val }
              />
            )
          })
        }
    </div>
  );
}






const mapStateToProps = (reducer: reducerType) => {
  return {
    userInfo: reducer.user.userInfo
}};


export default connect(mapStateToProps)(Buttons);


