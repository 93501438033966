import React              from 'react';
import { makeStyles }     from 'makeStyles';
//import { colors }         from '../../utils/colors';

const useStyles = makeStyles()((Theme) => ({
    root: {
        position:        'absolute',
        //left:            '20vw',
        
        height:          20,
        display:         'flex',
        borderRadius:    '100em',
        backgroundColor: '#EEF',
        overflow:        'hidden',
       // zIndex: 99
      //  border: '1px solid #F00'
     },
     white: {
         backgroundColor: '#EEF',
         transition:      'all 0.6s'
      },
      blue: {
        backgroundColor: '#70B0FF',
        transition:      'all 0.6s',
        borderRadius:    '100em',
     },
     brightBlue: {
        width:           '70%',
        height:          7,
        marginLeft:      10,
        borderRadius:    '100em',
        marginTop:       5,
        backgroundColor: '#80C0FF',
        transition:      'all 0.6s'

     }
}))

type ProgressBarProps = {
  size:     number[];
  menuSize: number;
  length:   number;
  prog:     number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ size, menuSize, length, prog }) => {

  const { classes } = useStyles();

  const [rootStyle, blueStyle, brightBlueStyle, whiteStyle] = React.useMemo(() => {

     const rootWidth = (size[0] - menuSize) * 0.8;
     return[
        {
           top:   size[1] < 730 ? 5 : 15,
           width: rootWidth,
        },
        {
           flex: prog
        },
        {
           width: prog ? ((rootWidth / length * prog) - 20) : 0,
        },
        {
          flex: length - prog 
        }
     ]
  },[size, menuSize, prog, length])

  return (
    <div
       className = { classes.root }
       style     = { rootStyle }
   >
       <div 
          className = { classes.blue }  
          style     = { blueStyle }
       >
          <div 
             className = { classes.brightBlue }
             style     = { brightBlueStyle }
          />
       </div>
       <div 
          className = { classes.white } 
          style     = { whiteStyle }
       />
   </div>
   
  );
}

export default ProgressBar;
