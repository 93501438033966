import React                    from 'react';
import { connect }              from 'react-redux';
import { reducerType }          from 'reducer';
import ArrowForwardIosIcon      from '@mui/icons-material/ArrowForwardIos';
import { words }                from 'utils/dictionary';
import { makeStyles }           from 'makeStyles';
import { userInfoType }         from 'types';
import { componentsStateType }  from 'reducer/types';

const selectedContent: { [key: string]: string; } = {
   apps:              'Apps',
   games:             'Games',
   bonus:             'Puzzles',
   lessons:           'Lessons',
   favorites:         'Favorites',
   groupLessons:      'Group Lessons',
   userLessons:       'My Lessons',
   studentsApps:      ' ',
   studentApps:       'Apps',
   myTeacherContent:  'My Teachers Content',
   introVideo:        ' ',
   content:           ' ',
   bigContentArr:     'content'
}


const useStyles = makeStyles()((Theme) => ({    
    tab: {
      cursor: 'default',
    },
    clickableTab: {
       cursor: 'pointer',
       '&:hover': {
         opacity: 0.4
       }
    },
    arrow: {
        transform:   'roteta(90deg)',
        marginLeft:  8,
        marginRight: 8
    },
}))

type TabProps = {
  variant:            string;
  content:            string;
  componentsState:    componentsStateType;
  userInfo:           userInfoType;
  studentLoggedIn:    boolean;
  handleClick:        () => void;
}

const Tab: React.FC<TabProps> = ({
  variant,
  content,
  componentsState,
  userInfo,
  studentLoggedIn,
  handleClick,
}) => {
  
  const { classes } = useStyles();

  const textObj: { [key: string]: string; } = React.useMemo(() => {

      const rootTabText = (
        !!selectedContent[content] ? `${ selectedContent[content] } ${ !!componentsState.searchStr ? `(${ componentsState.searchStr })` : '' } ` : 
        content === 'myStudentsContent' ? `My Students Content - Teacher Code: ${ userInfo.teacherCode }` :
        content 
      )

      return {
          root:      !!rootTabText ? rootTabText : '',
          aboutUs:   'About Us',
          topic:     !words[`${ content }`] ? '' : words[`${ content }`]['eng'],
          subTopic:  !words[`${ content }`] ? '' : words[`${ content }`]['eng'],
          allTopics: 'All Topics',
          app:       content
      }

  },[content, componentsState, userInfo])


  const clickable = React.useMemo(() => {
      if (variant === 'root') {
          return !studentLoggedIn
      }
      if ( variant === 'topic' || variant === 'subTopic' || variant === 'allTopics' ) {
          return true
      }
      return false;
  },[variant, studentLoggedIn])

 

  return (
    <>
       {
           variant !== 'root' &&
           <ArrowForwardIosIcon 
              className = { classes.arrow }
              style     = {{ fontSize: 12 }}
           />
       }
       <div 
         className = { clickable ? classes.clickableTab : classes.tab }
         onClick   = { handleClick }
       >
         { textObj[`${ variant }`] }
       </div>
    </>
  );
}




const mapStateToProps = (reducer: reducerType) => {
  return {
    componentsState: reducer.components,
    userInfo:        reducer.user.userInfo,
    studentLoggedIn: reducer.user.studentLoginState.loginState === 'success',
}};



export default connect(mapStateToProps)(Tab);







