import { store }                from 'index';
import { SET_MODAL_STATE }      from 'actions/modal_actions'
import { SET_COMPONENTS_STATE } from 'actions/component_actions';
//
const goToApp = (
   size:    number[],
   disable: boolean
) => { 

  const state           = store.getState();
  const componentsState = state.components;
  const browser         = state.user.userInfo.browser;
  const modalState      = state.modal;

  if ( disable ) {
    store.dispatch(SET_MODAL_STATE({
      variant:     'subscribe',
      open:        true,
      showButtons: true
    }))
    return;
  }


  if (
        !["Google Chrome", "Edge", "Safari"].includes(browser) && 
        !modalState.disabledVariants.includes('badBrowser') 
     )
    {
  
    store.dispatch(SET_MODAL_STATE({
      variant:     'badBrowser',
      open:        true,
      showButtons: true
    }))
    return;
  }

  if (( size[0] < 900 || size[1] < 450 ) && !modalState.disabledVariants.includes('screenTooSmall') ) {

    store.dispatch(SET_MODAL_STATE({
      variant:     'screenTooSmall',
      open:        true,
      showButtons: true
    }))
    return;
  }


  
   store.dispatch(SET_COMPONENTS_STATE({
      ...componentsState,
      showAppIntro:     false,
      showTopicIntro:   false,
      showPracticeComp: false,
      showMenu:         false,
      showSmallMenu:    false,
      showNavbar:       false,
      showFooter:       false,
      showAppIframe:    true
    }))

}

export default goToApp;