import { store }                from 'index';
import { SET_COMPONENTS_STATE } from 'actions/component_actions';
import recordEvent              from 'functions/contentInteraction/record_event';

const goToApp = (
    handleCloseModal: () => void
) => {

    const state = store.getState();
    const componentsState = state.components;

    handleCloseModal();
    recordEvent('app', 'app entered', componentsState.appUrl)

    store.dispatch(SET_COMPONENTS_STATE({
        ...componentsState,
        showAppIntro:  false,
        showMenu:      false,
        showSmallMenu: false,
        showNavbar:    false,
        showFooter:    false,
        showAppIframe: true
    }));
}

export default goToApp;