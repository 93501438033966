
import { store }                        from 'index';
import { REACTIVATE_SUBSCRIPTION_SAGA } from 'actions';
import recordEvent                      from 'functions/contentInteraction/record_event';


const handlReactivateSub = () => {

    const state    = store.getState();
    const userInfo = state.user.userInfo;
    const { 
        userEmail, 
        subscriptionId 
    } = userInfo;

    const payload = {
      userEmail:      userEmail.length > 0 && userEmail !== 'Guest' ? userEmail : '',
      subscriptionID: subscriptionId,
      tester:         false
    }

    store.dispatch(REACTIVATE_SUBSCRIPTION_SAGA(payload));
    recordEvent('modal', 'subscription reactivated', 'REACTIVATE_SUBSCRIPTION_SAGA')

}

export default handlReactivateSub;