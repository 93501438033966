import React                  from 'react';
import { contentInfoType }    from '../../../types';
import { colors }             from '../../../utils/colors';
import ExtensionIcon          from '@mui/icons-material/Extension';
import VideocamIcon           from '@mui/icons-material/Videocam';
import ToolTip                from '../../../components/ToolTip';
import { makeStyles }         from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({    top: {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'left',
      height:          45,
      width:           '100%',
      fontWeight:      'bold',
      fontSize:        17,
      cursor:          'default',
      color:           colors.primary
    },
    category: {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      height:          45,
      width:           45,
    },
    toolTip: {
      fontSize:  14,
    },
    image: {
        height:             30,
        width:              30,
        backgroundRepeat:   'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:     'contain',
    }
}))




type TopBarProps = {
  lowerTT: boolean;
  val:     contentInfoType;
}

const TopBar: React.FC<TopBarProps> = ({
  lowerTT,
  val,
}) => {

  const { classes } = useStyles();

  const [showToolTip, setShowToolTip] = React.useState('');

  const variant = React.useMemo(() => {
    return(
           !!val.origin && val.origin === 'puzzles'     ? 'Puzzle' :
           !!val.origin && val.origin === 'topicIntros' ? 'Student Practice' :
           !!val.origin && val.origin === 'challenges'  ? 'Challenge' :
           ''
    )
  },[val])

  return (
    <div className = { classes.top }>
    { 
        <div 
            className    = { classes.category }
            onMouseEnter = { () => { setShowToolTip(variant) } }
            onMouseLeave = { () => { setShowToolTip('') } }
        >
            {
              variant === 'Puzzle' && <ExtensionIcon/>
            }
            {
              variant === 'Student Practice' && <VideocamIcon/>
            }
            {
              variant === 'Challenge' && <div className = { classes.image } style = {{ backgroundImage: `url(https://iseemath.co/images/weights.png)` }}/>
            }
            <ToolTip
               showTT = { showToolTip === variant }
               text   = { variant }
               lower  = { lowerTT }
            />
        </div>
    }
   </div>
  );
}




export default TopBar;



