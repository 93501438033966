import React               from 'react';
import MenuIcon            from '@mui/icons-material/Menu';
import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
      height:          '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
    },
    icon_container: {
     // flex:            0.1,
      flex:            1,
      height:          '100%',
      maxWidth:        50,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      cursor:          'pointer',
    },
    icon: {
      color:        '#FFF',
      fontSize:     22,
      transition:   'transform 0.4s',
      borderRadius: '50%',
      border:       '1px solid #FFF',
      padding:      5
    },
    logoContainer: {
     // minWidth:       '15vw',
      
      height:         '100%',
      flex:            1,
    //  flex:           0.1,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
    },
    logo: {
      width:              '85%',
      height:             '85%',
      marginTop:          5,
      backgroundImage:    `url(https://iseemath.co/images/logoWhiteSmall.png)`,
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
    },
    
}))

type ToggleMenuButtonProps = {
  size:             number[];
  showMenuButton:   boolean;
  menuButtonWidh:   number;
  logoWidh:         number;
  handleMenuToggle: () => void;
}

const ToggleMenuButton: React.FC<ToggleMenuButtonProps> = ({ 
  size,
  showMenuButton,
  menuButtonWidh,
  logoWidh,
  handleMenuToggle
}) => {


  const { classes } = useStyles();

  const rootStyle = React.useMemo(() => {
    return {
      width: showMenuButton ? logoWidh + menuButtonWidh : logoWidh
    }
  },[logoWidh, menuButtonWidh, showMenuButton])

  return (
        <div 
           className = { classes.root }
           style     = { rootStyle }
        >
           {
                showMenuButton
                &&
                <div 
                     className = { classes.icon_container }
                     onClick   = { handleMenuToggle }
                     style     = {{ 
                      width:  menuButtonWidh,
                    }}
                >
                    {
                      <MenuIcon className = { classes.icon }/>
                    }
                </div>
            }
           <div 
                   className = { classes.logoContainer }
                   style     = {{ 
                     height: size[0] > 1000 ? '100%' : '85%',
                     width:  menuButtonWidh,
                   }}
                >
                   <div className = { classes.logo }/>
            </div>
        </div>
  );
}




export default ToggleMenuButton;



