import React                   from 'react';
import { connect }             from 'react-redux';
import { reducerType }         from 'reducer';
import {
  handlePlay,
  handlePause,
  handleReplay,
}                              from './functions';
import VolumeSlider            from './Volume';
import PauseRoundedIcon        from '@mui/icons-material/PauseRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import PlayArrowRoundedIcon    from '@mui/icons-material/PlayArrowRounded';

import { colors }              from 'utils/colors';
import { makeStyles }          from 'makeStyles';
import { audioStateType }      from 'reducer/types';


const useStyles = makeStyles()((Theme) => ({  
  controlls: {
     display:         'flex',
     justifyContent:  'space-between',
     alignItems:      'center',
     width:           180,
     height:          '100%',
     color:           colors.primary,  
  },
  icon: {
      cursor: 'pointer',
      '&:hover': {
        color: colors.primaryDark,
      }
  }
}));


type ControllsProps = { 
  AudioRef:   any;
  audioState: audioStateType;
}

const Controlls: React.FC<ControllsProps> = ({ 
  AudioRef,
  audioState,
}) => {

  const { classes } = useStyles();

  React.useEffect(() => {
    if (AudioRef && AudioRef.current) {
      AudioRef.current.volume = audioState.volume/100;
    }
  },[audioState, AudioRef])


  return (
    <div className = { classes.controlls } >
        <SkipPreviousRoundedIcon className = { classes.icon } onClick = { () => handleReplay(AudioRef) }/>
        { 
          !audioState.pauseAudio && 
            <PauseRoundedIcon     
               className = { classes.icon } 
               onClick   = { () => handlePause(AudioRef) }
            /> 
        }
        { 
           audioState.pauseAudio && 
             <PlayArrowRoundedIcon 
                className = { classes.icon } 
                onClick   = { () => handlePlay(AudioRef) }
             /> 
        }
        <VolumeSlider/>
    </div>
      
  )
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    audioState: reducer.audio
}};

export default connect(mapStateToProps)(Controlls);
