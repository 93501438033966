import React          from 'react';
import VideoComp      from './VideoComp';
import { text2 }      from './texts';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(() => ({    
    root: {
        display:    'flex',
        alignItems: 'center',
    },
    title: {
        fontSize:    28,
        marginLeft:  5,
        marginRight: 5,
        marginTop:   20,
    },
    text: {
        width:         '100%',
        flex:          1,
        fontWeight:    'bold',
        letterSpacing:  1.5,
    },
    line: {
        fontSize:      21,
        marginLeft:    10,
        marginRight:   10,
        marginTop:     20,
    },
    video: {
        flex:    1,
        height: '100%',
    },
    button: {
        left:     '50%',
        width:    450,
        position: 'absolute',
        zIndex:   1
    }
}))


type Sirton2Props = {
    wideScreen: boolean;
}
 
const Sirton2: React.FC<Sirton2Props> = ({ wideScreen }) => {


  const { classes } = useStyles();

  const [rootStyle, textStyle] = React.useMemo(() => {
    return[
        {
          width:          wideScreen ? '70vw' : '96vw',
          marginLeft:     wideScreen ? '15vw' : '2vw',
          justifyContent: wideScreen ? 'space-between' : 'center',
          flexFlow:       wideScreen ? 'row' : 'column',
        },
        {
          paddingTop:    wideScreen ? undefined : 35,
          paddingBottom: wideScreen ? undefined : 25,
          paddingLeft:   wideScreen ? 20 : undefined,
          fontSize:      wideScreen ? 28 : 23
        }
    ]
  },[wideScreen])

  return (     
       <div 
         className = { classes.root }
         style     = { rootStyle }
       >  
              {
                wideScreen && <VideoComp wideScreen/>
              }
              <div 
                  className = { classes.text } 
                  style     = { textStyle }
              >
                  <div className = { classes.title } >
                    I SEE MATH APPS:
                  </div>
                  {
                    text2.map((val, ind) => {
                        return(
                            <div
                               key       = { `${ val }${ ind }` }
                               className = { classes.line }
                            >
                                { val }
                            </div>
                        )
                    })
                  }
              </div>
              {
                !wideScreen && <VideoComp wideScreen = { false }/>
              }
       </div> 
  );
}


export default Sirton2;
