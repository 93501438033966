
const updateContent = ( 
    content:  { [key: string]: any }[],
    hash:     { [key: string]: any }
  ) => {

  for ( let i = 0; i < content.length; i++ ) {

      const id = content[i].uid ? `${ content[i].uid }` : `${ content[i].lesson_id }`;
      content[i].totalLikes = hash[`${ id }`] && hash[`${ id }`].totalLikes ? hash[`${ id }`].totalLikes : 0;

  }

  return content;

}


export default updateContent;