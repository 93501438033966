import React          from 'react';
import Animation      from './Animation';
import { makeStyles } from 'makeStyles';

var clsx = require('classnames')

const arr = ['APPS AND LESSONS', 'GAMES AND PUZZLES', 'TEACHERS USING I-SEE-MATH'];

const useStyles = makeStyles()((Theme) => ({    
    root: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',

    },
    container: {
        width:         '100%',
        display:       'flex',
        alignItems:    'center',
        height:        '100%',
    },
    numberContainer: {
      flex:           1,
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      textAlign:      'center',
    },
    text: {
      flex:           1,
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
    },
    numberVal: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        textAlign:      'center',
        color:          Theme.blue
    },
    numberText: {
      marginTop:  10,
      fontWeight: 'bold',
      fontSize:   18,
    },
    toBottom: {
      display:        'flex',
      justifyContent: 'flex-end',
      flexFlow:       'column',
      transform:      'translate(0px, 8px)'
    },
    column: {
      display:      'flex',
      flexFlow:     'column',
      paddingRight: 8,
      paddingLeft:  8,
    }

}))


type Row2Props = {
    size:           number[];
    wideScreen:     boolean;
    animateNumbers: boolean;
}
 
const Row2: React.FC<Row2Props> = ({
    size,
    wideScreen,
    animateNumbers,
  }) => {


  const { classes } = useStyles();

  const [num1, setNum1] = React.useState(wideScreen ? 0 : 70);
  const [num2, setNum2] = React.useState(wideScreen ? 0 : 85);
  const [num3, setNum3] = React.useState(wideScreen ? 0 : 7000);


  const displayAsRow = React.useMemo(() => {
    return size[0] > 800
  },[size])


  React.useEffect(() => {

    let interval = null as any;

    if (animateNumbers) {
      interval = setInterval(() => {
        if (num1 < 70) {
          setNum1(num1 => num1 + 1);
        }
        if (num2 < 85) {
          setNum2(num2 => num2 + 1);
        }
        if (num3 < 7000) {
          setNum3(num3 => num3 + 100);
        }
      }, 15);
    }
   
    return () => clearInterval(interval);
  }, [num1, num2, num3, animateNumbers]);

  const [rootStyle, containerStyle, numberStyle, numberContainerStyle, textStyle] = React.useMemo(() => {

    return[
        {
          height:     wideScreen ?  500 : undefined,
          width:      wideScreen ? '70vw' : '96vw',
          marginLeft: wideScreen ? '15vw' : '2vw',
        },
        {
          flexFlow: displayAsRow ? 'row' : 'column',
        },
        {
           opacity:  (!num1 || !num2 || !num3) ? 0 : 1,
           fontSize: displayAsRow ? 100 : 50,
        },
        {
          borderBottom: !displayAsRow ? '1px solid #BBB' : 'none',
          flexFlow:     displayAsRow  ? 'column' : 'row',
          maxWidth:     displayAsRow  ? '32vw' : '96vw',
          minWidth:     displayAsRow  ? undefined : '96vw',
        },
        {
          flexFlow: displayAsRow ? 'column' : 'row',
        }
    ]
  },[wideScreen, displayAsRow, num1, num2, num3])


  return (
       
       <div 
         className = { classes.root }
         style     = { rootStyle }
       >  
          <div 
              className = { classes.container }
              style     = { containerStyle }
          >  
                {
                  [num1, num2, num3].map((val, ind) => {
                    return(
                        <div 
                          key       = { `GSSD${ arr[ind] }` }
                          className = { classes.numberContainer }
                          style     = {{ 
                            ...numberContainerStyle, 
                            borderRight: (ind < 2 && displayAsRow) ? '1px solid #BBB' : 'none'
                          }}
                        >
                            <div 
                              className = { classes.text }
                              style     = { textStyle }
                            >
                                  <div className = { wideScreen ? '' : classes.column }>
                                      <div className = { clsx(classes.numberText , classes.toBottom ) }>
                                        OVER
                                      </div>
                                      <div
                                        className = { classes.numberVal }
                                        style     = { numberStyle }
                                      >
                                        { val }
                                      </div>
                                      <div className = { classes.numberText }>
                                        { arr[ind] }
                                      </div>
                                  </div>
                                  <Animation
                                     ind          = { ind }
                                     size         = { size }
                                     wideScreen   = { wideScreen }
                                  />
                            </div>

                        </div>
                    )
                  })
                }
            </div> 
      </div> 
  );
}


export default Row2;
