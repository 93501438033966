const arr: { [key: string]: any; }[] = [
    {
        approved: 0,
ar: 2,
author: "raphael.shalem@gmail.com",
description: "",
grades: "0",
id: 14589,
img_id: "",
kehila: 0,
lesson_email: "",
lessontype: "Nail_grid_page",
name: "raph test",
search_words: "",
teacher_page: "",
timestamp: "2022-01-09 21:36:16",
uid: 36013
    },
    {
        approved: 0,
ar: 2,
author: "raphael.shalem@gmail.com",
description: "",
grades: "0",
id: 13969,
img_id: "",
kehila: 0,
lesson_email: "",
lessontype: "Decimal_square",
name: "raphael lesson",
search_words: "",
student_page: "",
teacher_page: "",
timestamp: "2021-11-07 20:42:05",
uid: 35393
    },
    {
        "id": 26,
        "lesson_id": "Fractionization",
        "app": "פירוק לגורמים עד 1000",
        "app_type": 0,
        "app_arabic": "تحليل الى العوامل حتى 1000  ",
        "app_eng": "Factorization",
        "display_app_eng": "true",
        "top_topic": "שלמים",
        "topic": "מגוון נושאים במספרים שלמים",
        "classes": "45",
        "youtube": "WpsGpAzgGHg",
        "youtube_eng": "rb_I8Oxge2E",
        "plus": "0",
        "search_words": "ראשוני, כפולה משותפת, מחלק משותף",
        "search_words_eng": "primary, numerator, denominator, shared multiplication, shared divisor",
        "interaction": "",
        "student_page": "",
        "description": "היישומון מאפשר לפרק לגורמים מספרים הקטנים מ- 1000 למכפלת הגורמים הראשוניים שלהם. שיטת הפירוק היא בעזרת עץ. כמו כן היישומון מאפשר לגלות בדרך שיטתית את כל הפירוקים של המספר למכפלה של שני מספרים.   ",
        "description_arabic": "التطبيق يمكن تحليل الى عوامل اعداد اصغر من 1000 الى عوامله الاولية، طريقة التحليل هي بمساعدة الشجرة، كذلك التطبيق يمكن اكتشاف بطريقة منهجية كل التحليلات للعدد الى عوامل الثنائية.  ",
        "description_eng": "This app enables the Factorization of numbers to their prime components. The factorization is represented as a tree. The app also offers a convenient tool to use the prime numbers that were reached by the factorization to systematically find all two number multiplications that give that number as a product.",
        "flash": "",
        "category": "המחשה",
        "origin": "apps",
        "totalLikes": 0,
        "liked": 0,
        "selected": 0,
        "viewed": 0
      },
      {
        "id": 37,
        "lesson_id": "Decimal_square",
        "app": "שבר עשרוני ואחוזים בקלי קלות",
        "app_type": 0,
        "app_arabic": "عدد عشري ونسبة مئوية بسهولة",
        "app_eng": "Easy Decimals",
        "display_app_eng": "true",
        "top_topic": "שבר",
        "topic": "מספרים עשרוניים",
        "classes": "567",
        "youtube": "4C8CZkBH7Yc",
        "youtube_eng": "EZjD-lreO5A",
        "plus": "1",
        "search_words": "שברים עשרוניים, עשירית, מאית, אלפית",
        "search_words_eng": "decimal fractions, one tenth, one hundredth, one thousandth",
        "interaction": "",
        "student_page": "",
        "description": "היישומון מאפשר להסביר בצורה חזותית מהירה את רעיון השבר העשרוני – עשיריות, מאיות ואלפיות. אל המסך מכניסים ריבוע שהוא היחידה, ובזריזות מחלקים אותו כך שניתן יהיה להציג בעזרתו מספרים עשרונים בין 0 ל- 1. היישומון נוח גם לחיבור וחיסור של שברים כאלה ואף להשוואה ביניהם.  ",
        "description_arabic": "التطبيق هو لعبة بين مجموعتين في الصف، في كل مرحلة يظهر على اشاشة مستطيل قسم من ملون، كل مجموعة تقدر بكسر عشري اي قسم من المستطبل ملون، تقدير الصحيح يفوز بنفطة. المجموعة الفائزة هي التي تجمع اكبر عدد من النقاط.  ",
        "description_eng": "The Easy Decimals app does exactly what its title suggests; namely, it makes decimals easy to visualize and understand. Using models that make sense, students will grasp the meaning of decimal notation and will learn how to represent decimals using the basic square grid.",
        "flash": "",
        "category": "המחשה",
        "origin": "apps",
        "totalLikes": 0,
        "liked": 0,
        "selected": 0,
        "viewed": 0
      }
]

export default arr;