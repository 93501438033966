
import React                        from 'react';
import { PayPalScriptProvider }     from "@paypal/react-paypal-js";
import SubscriptionForm             from '../SubscriptionForm';

import { 
    testData, 
    planOptions,
}                                   from "utils/payment_plans";
import { modalStateType }           from 'reducer/types';


type TestFormProps = {
    size:               number[];
    modalState:         modalStateType;
    planType:           string;
    tester:             string;
    showPayForm:        boolean;
    subscribe:          (data: any) => void;
    handleClose:        () => void;

  }
  
  const TestForm: React.FC<TestFormProps> = ({ 
     size, 
     modalState,
     planType,
     showPayForm,
     tester,
     subscribe,
     handleClose,
  }) => {

    return (
            <PayPalScriptProvider options = { planOptions }>
               <SubscriptionForm
                   variant         = { 'testPlan' }
                   account         = { 'live' }
                   country         = { 'test' }
                   tester          = { tester }
                   size            = { size }
                   showPayForm     = { showPayForm }
                   showModal       = { modalState.showModal }
                   planId          = { testData.annualPlanId }
                   planType        = { planType }
                   subscribe       = { subscribe }
                   handleClose     = { handleClose }
               />
            </PayPalScriptProvider>
           
  )}




export default TestForm