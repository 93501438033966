import { call, take, put  }  from 'redux-saga/effects';
import axios                 from 'axios';
import { 
        SET_GROUP_STATE,   
        UPDATE_HASH   
      }                      from '../actions';
import { SET_MODAL_STATE }   from 'actions/modal_actions'
import mockData              from '../data/mockData/mockGroupData';

const getGroupsEndpoint = 'https://www.yisumatica.org.il/flash-upload/js/outsiders/php/get_lesson_list_mygroup.php';

const addOrigin = (arr: { [key: string]: any }, hash: { [key: string]: any }) => {

   let obj = hash;

   for ( let i = 0; i < arr.length; i++ ) {
       arr[i].origin = 'teacherContent';
 //      arr[i].author = '',
       if ( !!arr[i].uid ) {
         obj[`${ arr[i].uid }`] = arr[i];
       }
   }

   return { lessons: arr, newHash: obj };
}


function* getGroupLessons(): Generator<any,any,any> {
  while(true) {
    try {
     

        const userInput = yield take('GET_GROUP_LESSONS_SAGA');
        const { groupName, hash } = userInput.payload;

        const fail = {
            name:    '',
            lessons: [],
        }

        yield put(SET_MODAL_STATE({ variant: 'loader', open: true, showButtons: false })); 


        if (process.env.NODE_ENV === 'development') {

              const { lessons, newHash } = yield addOrigin(mockData, hash);
            
              const payload = {
                name:    groupName,
                lessons: lessons
              }

              const payload2 = {
                name:    groupName,
                lessons: [lessons[4], lessons[9]]
              }

            
              if ( groupName === 'zvi' ) {
                yield put(SET_GROUP_STATE( payload ))
                yield put(UPDATE_HASH( newHash ))
                yield put(SET_MODAL_STATE({ variant: '', open: false, showButtons: true })); 
              }
              else if ( groupName === 'zvi2' ) {
                yield put(SET_GROUP_STATE( payload2 ))
                yield put(UPDATE_HASH( newHash ))
                yield put(SET_MODAL_STATE({ variant: '', open: false, showButtons: true })); 
              }
              else {
                yield put(SET_GROUP_STATE( fail ))
                yield put(SET_MODAL_STATE({ variant: 'emptyGroup', open: true, showButtons: true })); 
              }
        }


        if (process.env.NODE_ENV === 'production') {

            const groups = yield call( axios.post, getGroupsEndpoint, { mygroup: groupName });

          //  console.log('groups.data    :   ',groups.data)

            if (groups && groups.data && groups.data.length > 0) {

                const { lessons, newHash } = yield addOrigin(groups.data, hash);

                const payload = {
                    name:    groupName,
                    lessons: lessons
                }

                yield put(SET_GROUP_STATE( payload ))
                yield put(UPDATE_HASH( newHash ))
                yield put(SET_MODAL_STATE({ variant: '', open: false, showButtons: true })); 

            }

            else {
              yield put(SET_GROUP_STATE(fail))
              yield put(SET_MODAL_STATE({ variant: 'emptyGroup', open: true, showButtons: true })); 
              console.log("can't get groups")
            }

            
        }
    }
    catch(err) {
      yield put(SET_GROUP_STATE({
        name:    '',
        lessons: []
      }))
      yield put(SET_MODAL_STATE({ variant: 'emptyGroup', open: true, showButtons: true })); 
      console.log('err in getGroups  :  '+err);
    }
    finally {}
  }
}


export default getGroupLessons;




