import React               from 'react';
import { connect }         from 'react-redux';
import { Dispatch }        from "redux";
import { ActionTypes }     from 'actions/types';
import { reducerType }     from 'reducer';
import AudioControlls      from 'components/audio/Controlls';
import { handlePlay }      from 'components/audio/functions';
import { colors }          from 'utils/colors';
import { sizes }           from 'utils/defaultStates';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUp';
import ClickAwayListener   from '@mui/base/ClickAwayListener';
import { makeStyles }      from 'makeStyles';
import { audioStateType } from 'reducer/types';
import { SET_AUDIO_STATE } from 'actions/audio_actions';

const useStyles = makeStyles()(
  (Theme) => ({    audio: {
    },
    iconContainer: {
        height:          '100%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
    },
    icon: {
      color:   colors.primary,
      cursor: 'pointer',
      '&:hover': {
          opacity: 0.4
      }
    },
    audioControlls: {
       position:        'fixed',
       top:             sizes.navBarSize + sizes.tabSize,
       right:           8,
       overflow:        'hidden',
       width:           200,
       display:         'flex',
       justifyContent:  'center',
       border:          `1px solid #DDD`,
       backgroundColor: '#FFF', 
       borderRadius:    '0.3em',
       boxShadow:       '0px 4px 10px rgba(0,0,0,0.15)',
       transition:      'height 0.1s, opacity 0.2s'
    }
}))

type AudioProps = {
  AudioRef:      any;
  audioState:    audioStateType;
  setAudioState: (payload: audioStateType) => void;
}

const Audio: React.FC<AudioProps> = ({
  AudioRef,
  audioState,
  setAudioState,
}) => {
  
  const { classes } = useStyles();

   const [open, setOpen] = React.useState(audioState.initialOpen)

   const handleClose = () => {
      setOpen(false)
      if ( audioState.initialOpen ) {
         setAudioState({
            ...audioState,
            initialOpen: false
         })
      }
   }

   const handleOpen = () => {
      handlePlay(AudioRef);
      setOpen(true)
   }

   const [iconContainerStyle, controllsStyle] = React.useMemo(() => {

    const width         = 30;
   // const controllWidth = 200;

    return[
       {
        minWidth: width,
        maxWidth: width,
       },
       {
          height:    open ? sizes.tabSize : 0,
          opacity:   open ? 1 : 0,

         // transform: `translate(-${ controllWidth - width }px, 0%)`,
       }
    ]
  },[open])

  
  return (
   <ClickAwayListener onClickAway = { handleClose }>
         <div className = { classes.audio } >
            <div 
               className = { classes.iconContainer } 
               style     = { iconContainerStyle }
            >
               <VolumeUpRoundedIcon
                  className = { classes.icon }
                  onClick   = { open ? handleClose : handleOpen }
               />
            </div>
            <div 
               className = { classes.audioControlls }
               style     = { controllsStyle }
            >
               <AudioControlls AudioRef = { AudioRef }/>
            </div>
         </div>    
    </ClickAwayListener>

   );
}



const mapStateToProps = (reducer: reducerType) => {
   return {
     audioState: reducer.audio
 }};
 
 
 const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
   setAudioState: (payload: audioStateType) => dispatch(SET_AUDIO_STATE(payload)),
 });
 
 
 
 export default connect(mapStateToProps, mapDispatchToProps)(Audio);
 
 
 
 
 
 
 
 


