
  export const defaultData = {
    annualPlanId:  "P-8CB93338688500738MJKFNLA",
    monthlyPlanId: "P-12H42293B7187210GMJKFBEI",
    clientId:      "AcIJq-HDzB5_WY_bSrXzRxdSP6e9oQWADZZFkI5Rt5W07dQIGEilK83xtveQz495go7Ec2WEkhKm_C0S"
  }

  export const UKData = {
    annualPlanId:  "P-8K338721R95627624MJKFT4Q",
    monthlyPlanId: "P-03303481LP674610NMJKFGCY",
    clientId:      "AcIJq-HDzB5_WY_bSrXzRxdSP6e9oQWADZZFkI5Rt5W07dQIGEilK83xtveQz495go7Ec2WEkhKm_C0S"
  }

  export const testData = {
    annualPlanId:  "P-5724671465902425BMJKVAXI",
    monthlyPlanId: "P-52F623866P8946542MDKLGKQ",
    clientId:      "AcIJq-HDzB5_WY_bSrXzRxdSP6e9oQWADZZFkI5Rt5W07dQIGEilK83xtveQz495go7Ec2WEkhKm_C0S"
  }
  
  export const sandBoxData = {
    annualPlanId:  "P-7PR69256W42390716MF4WKEI",
    monthlyPlanId: "P-3W498210WY883994CMF4WGAQ",
    testPlan:      "P-2FT8583602117762WMDKID7Y",
    clientId:      "AXlX4Dg2f4uRItf75HtZ7a0OTIlKdXMPsK4UYqoRVcy-r3yaPJ1SuoCbsRK6rWtEk8QTn-cM3PJ3BBCg"
  }
  
  
  export const planOptions = {
    "client-id": defaultData.clientId,
    "currency":  "USD",
    "vault":     true,
    "intent":    "subscription"
  }
  
  
  export const sandBoxOptions = {
    "client-id": sandBoxData.clientId,
    "currency":  "USD",
    "vault":     true,
    "intent":    "subscription"
  }
  /* 
  export const saleOptions = {
    "client-id": pplData.clientId,
    "currency":  "USD",
  }
  
  export const sandBoxSaleOptions = {
    "client-id": sandBoxData.clientId,
    "currency":  "USD",
    //"vault":     true,
   // "intent":    "sale"
  }
   */