import React           from 'react';
import { connect }     from 'react-redux';
import { reducerType } from 'reducer';
import { colors }      from 'utils/colors';
import { sizes }       from 'utils/defaultStates';
import { makeStyles }  from 'makeStyles';

const date = new Date().getFullYear();

const grids = ['content','students','my lessons', 'groupLessons', 'puzzles'];

const useStyles = makeStyles()(
  (Theme) => ({    
        root: {
        display:          'flex',
        alignItems:       'center',
        width:            '100vw',
        height:           350,
        color:            '#8B8B8B',
        fontWeight:       'bold',
       // borderTop:        '1px solid #CCC'
    },
    Terms: {
        marginLeft:  10,
        cursor:      'pointer'
    },
    item: {
        marginLeft:  10,
    }
}))

type FooterProps = {
    size:           number[];
    page:           string;
    showFooter:     boolean;
    setShowTOS:     (val: boolean) => void;
    setShowPrivacy: (val: boolean) => void;
}

const Footer: React.FC<FooterProps> = ({ size, page, showFooter, setShowTOS, setShowPrivacy }) => {

  const { classes } = useStyles();

  const [rootStyle, item0Style] = React.useMemo(() => {

     const pos: 'relative' | 'absolute' = page === 'home' ? 'relative' : 'absolute';
     
     return [
         {
            height:           sizes.footerSize,
            position:         pos,
            backgroundColor:  grids.includes(page) ? colors.brightGrey : '#FFF',
            bottom:           page === 'home' ? undefined : 0,
            left:             page === 'home' ? undefined : 0,
            transform:        `translate(${ showFooter ? 0 : 600 }vw, 0vh)`,
            fontSize:         size[0] > 600 ? 14 : 11,
            color:            size[0] > 600 ? undefined : '#666',
         },
         {
            marginLeft:  20,//page === 'home' ? '10vw' : 20,
            transition:  'all 0.4s'
         }
    ]
  },[size, page, showFooter])

  return (
        <div 
           className = { classes.root }
           style     = { rootStyle }
        >
           <div style     = { item0Style }>{ `© ${ date } I See Math` }</div>
           <div className = { classes.item }>&#8226;</div>
           <div className = { classes.item }>info@iseemath.co</div>
           <div className = { classes.item }>&#8226;</div>
           <div 
              className = { classes.Terms }
              onClick   = { () => { setShowTOS(true) } }
           >
               Terms
           </div>
           <div className = { classes.item }>&#8226;</div>
           <div 
              className = { classes.Terms }
              onClick   = { () => { setShowPrivacy(true) } }
           >
               Privacy
           </div>
        </div>
    );
}



const mapStateToProps = (reducer: reducerType) => {  
    return {
      showFooter: reducer.components.showFooter
  }
};
   
export default connect(mapStateToProps)(Footer);
