import React           from 'react';
import arr             from './terms_of_service';
import { colors }      from 'utils/colors';
import { makeStyles }  from 'makeStyles';
 
const useStyles = makeStyles()((Theme) => ({  
  root: {
    position:        'fixed',
    top:             0,
    left:            0,
    width:           '100vw',
    height:          '100vh',
    display:         'flex',
    flexFlow:        'column',
    alignItems:      'center',
    justifyContent:  'center',
    backgroundColor: '#FFF',
    transition:      'opacity 0.4s',
    zIndex:          3
  },
  text_container: {
   // height:          '75%',
    width:           '85%',
    display:         'flex',
    flexFlow:        'column',
    textAlign:       'left',
    overflowY:       'scroll',
    overflowX:       'hidden',
    backgroundColor: `rgba(250, 250, 250, 0.85)`,
    borderTop:       `1px solid #DDD`,
    borderBottom:    `1px solid #DDD`,
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CCC',
      outline:         '1px solid #FFF',
      borderRadius:    10
    }
  },
  text: {
    padding:    10,
    lineHeight: 2,
   // fontWeight: 'bold',
  //  color:      colors.primary
  },
  submitContainer: {
    flex:      1,
    maxHeight: 100,
    cursor:    'pointer'
   // height: 100,
  },
  submit: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    textAlign:       'center',
    backgroundColor: colors.primary,
    color:           '#FFF',
    marginTop:       20,
    width:           240,
    height:          40,
    borderRadius:    '0.2em',
    fontWeight:      'bold'
  },
  title: {
    height:     80,
    width:      '85%',
    display:    'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  }
}));

type TosProps = {  
    size:       number[];
    showTOS:    boolean;
    setShowTOS: (val: boolean) => void;
}

const Tos: React.FC<TosProps> = ({ size, showTOS, setShowTOS }) => {

const [enableButton, setEnableButton] = React.useState(false)

const contentRef = React.useRef<HTMLDivElement>(null);

const handleScroll = () => {

    if (contentRef && contentRef.current) {

        const { scrollHeight, scrollTop, clientHeight } = contentRef.current;
        
        if ( scrollHeight - scrollTop - 15 <= clientHeight ) {
            setEnableButton(true)
        } 
        else {
            if (enableButton) { 
                setEnableButton(false) 
            }
        }
    }
}


const rootStyle = React.useMemo(() => {
   return {
       transform: `translate(0vw, ${ showTOS ? 0 : 200 }vh)`,
       opacity:   showTOS ? 1 : 0
   }
},[showTOS])

/* 
const buttonStyle = React.useMemo(() => {
    return {
        cursor:  enableButton ? 'pointer' : 'default',
        opacity: enableButton ? 1 : 0.5
    }
 },[enableButton]) */
 
const { classes } = useStyles();  

return (
    <div 
        className = { classes.root }
        style     = { rootStyle }
    >
        <div className = { classes.title }>
           I SEE MATH Terms Of Service
        </div>
        <div 
           className = { classes.text_container }
           ref       = { contentRef }
           onScroll  = { handleScroll }
           style     = {{
               height: size[1] - ( size[1] > 750 ? 160 : 200 )
           }}
        >
            { 
                    arr.map((val, ind) => {
                        return(
                            <div 
                                key       = { `Tos_text_${ ind }` }
                                className = { classes.text }
                            >
                                { val }
                            </div>
                        )
                    })
            }
        </div>
        <div className = { classes.submitContainer }>
           <div 
            className = { classes.submit }
           /*  style     = { buttonStyle } */
            onClick   = { () => { /* if ( enableButton ) {  */setShowTOS(false) /* } */}}
           >
              continue
           </div>  
       </div>
    </div>
  );
}


export default Tos