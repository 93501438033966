import React            from 'react';
import { makeStyles }   from 'makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((Theme) => ({  
    root: {
      display: 'flex',
      '& > * + *': {
        //marginLeft: Theme.spacing(2),
      },
    },
    loader: {
     /*  maxHeight: 20,
      maxWidth:  20, */
     // color:     '#FFF'
    }
  }),
);

type SmallLoaderProps = {
  size:  number;
  color: string;
}

const SmallLoader: React.FC<SmallLoaderProps> = ({ size, color }) => {
  const { classes } = useStyles();

  return (
    <div className = { classes.root } >
      <CircularProgress 
          className = { classes.loader }
          style     = {{
            maxHeight: size,
            maxWidth:  size,
            color:     color
          }}
      />
    </div>
  );
}

export default SmallLoader;