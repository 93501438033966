import React                      from 'react';
import { store }                  from 'index';
import { userAttributesType }     from 'types';
import { GET_USER_ACTIVITY_SAGA } from 'actions';

const useGetUserActivity = (
    guest:          boolean,
    denyAccess:     boolean,
    userAttributes: userAttributesType,
  ) => {

    const state           = store.getState();
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const activityUpdated = state.data.dataUpdateState.activity;

  React.useEffect(() => {

      if ( studentLoggedIn || guest || denyAccess || activityUpdated === true ) {
        return
      }

      let userEmail = '';

      if ( userAttributes.userSignedIn === 'true' ) {
        userEmail = userAttributes.userEmail;
      }

      store.dispatch(GET_USER_ACTIVITY_SAGA({ userEmail: userEmail }));

  },[ userAttributes, studentLoggedIn, activityUpdated, guest, denyAccess ])


}

export default useGetUserActivity;