import React                from 'react';
import { Dispatch }         from "redux";
import { connect }          from 'react-redux';
import { reducerType }      from '../../reducer';
import { 
  ActionTypes, 
  updateUserActivityType 
}                           from '../../actions/types';
import { 
  UPDATE_USER_ACTIVITY_SAGA 
}                           from '../../actions';
import { 
        contentInfoType, 
        userInfoType 
       }                    from '../../types';

import { categories }       from '../../utils/colors'
import handleLike           from '../../functions/contentInteraction/handle_like';

import DropDown             from './DropDown';
import Loader               from '../LoaderSmall';


import StarIcon             from '@mui/icons-material/Star';
import StarBorderIcon       from '@mui/icons-material/StarBorder';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
//import MoreIcon             from ''@mui/icons-material/ExpandMore';';


import { colors }           from '../../utils/colors';
import { makeStyles }       from 'makeStyles';

import { sizes } from 'utils/defaultStates';


const useStyles = makeStyles()(
  (Theme) => ({    title: {
      position:        'absolute',
      top:             sizes.tabSize,
      right:           0,
      height:          70,
     // width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-evenly',
      textAlign:       'center',
      backgroundColor:  colors.brightGrey,
      color:           '#334',
      fontWeight:      'bold',
      zIndex:          1
    },
    favorites: {
      minWidth:       35,
      minHeight:      35,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      position:       'absolute',
    },
    star: {
      fontSize: 35,
      cursor:   'pointer',
      color:    colors.primary
    },
    backButton: {
      position:        'absolute',
      left:            3,
      height:          35,
      maxWidth:        35,
      minWidth:        35,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      color:           '#FFF',
      borderRadius:    '50%',
      backgroundColor: colors.primary,
      '&:hover': {
         opacity: 0.5
      }
    },
    moreButton: {
      position:        'absolute',
      left:            0,
      height:          35,
      maxWidth:        35,
      minWidth:        35,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      cursor:          'pointer',
      color:            colors.primary,
    }
}))

type TopBarProps = {
    userInfo:            userInfoType;
    size:                number[];
    menuSize:            number;
    app:                 contentInfoType;
    hash:                { [key: string]: any };
    wideScreen:          boolean;
    activityUpdated:     boolean;
    denyAccess:          boolean;
    showFAppButton:      boolean;
    showGoToPdf:         boolean;
    handleClose:         () => void;
    goTofather_app:      () => void;
    goToPDF:             (val: string) => void;
    updateUserActivity:  (payload: updateUserActivityType) => void;
}
const TopBar: React.FC<TopBarProps> = ({ 
    userInfo,
    size,
    menuSize,
    app,
    wideScreen,
    hash,
    activityUpdated,
    denyAccess,
    showFAppButton,
    showGoToPdf,
    handleClose,
    goTofather_app,
    goToPDF,
    updateUserActivity
}) => {

  const { classes } = useStyles();

//

  const id = React.useMemo(() => {

    return app.uid ? `${ app.uid }` : app.lesson_id;

  },[app]) 
  

  const favoriteStatus = React.useMemo(() => {

    if (denyAccess || !activityUpdated || !hash[id] || isNaN(+(hash[id].selected))) {
      return -2;
    }

    return +(hash[id].selected);

  },[id, hash, activityUpdated, denyAccess]) 


  const [showBackButton, showDropDown, dropDownItems] = React.useMemo(() => {

    const showDD = !wideScreen && (showFAppButton || showGoToPdf);
    const showBB = !wideScreen && !showDD;

    const arr =  [
      `Go Back To ${ !!app.origin && app.origin === 'lessons' ? 'Lessons' : 'Apps' }`,
    ]
    if ( showFAppButton ) { arr.push('Go To Source App') }
    if ( showGoToPdf )    { arr.push('The Student Page') }

    return [showBB, showDD, arr];
  },[app, wideScreen, showFAppButton, showGoToPdf])
  

  const handleFavoriteClick = ( status: number, id: string ) => {
     if ( status === -1 ) {
       return;
     }

     const action = status === 0 ? 'add_to_favorites' : 'remove_from_favorites';
     handleLike(id, action, userInfo.userName, userInfo.userEmail, hash, updateUserActivity);

  }

  const handleGoToPdf = () => {
    if ( !!app.student_page ) {
      goToPDF(app.student_page)
    }
  }

  const [titleContainerStyle, titleStyle] = React.useMemo(() => {

    const containerWidth = wideScreen ? size[0] - menuSize - 4 : size[0];
    const titleWidth     = size[0] - 150 - ( wideScreen ? menuSize : 0 );

    return[
      {
         maxWidth: containerWidth,
         minWidth: containerWidth,
      },
      {
        maxWidth: titleWidth,
        minWidth: titleWidth,
        fontSize: size[0] > 900 ? 25 : 20
      }
    ]
  },[size, menuSize, wideScreen])
  
  return (
    <div 
      className = { classes.title }
      style     = { titleContainerStyle }
    >
           {
                !denyAccess
                &&
                <div 
                  className = { classes.favorites }
                  onClick   = { () => { handleFavoriteClick(favoriteStatus, id) } }
                  style     = {{
                    right: wideScreen ? 20 : 5
                  }}
                >
                    {
                      favoriteStatus === -1
                      &&
                      <Loader size = { 45 } color = { '#FFF' }/>
                    }
                    {
                      favoriteStatus === 0
                      &&
                      <StarBorderIcon className = { classes.star }/>
                    }
                    {
                      favoriteStatus === 1
                      &&
                      <StarIcon className = { classes.star }/>
                    }
                </div>
           }
           {
             showBackButton &&
             <div 
               className = { classes.backButton }
               onClick   = { handleClose }
              >
                  <ArrowBackRoundedIcon/>
              </div>
           }
           {
             showDropDown &&
             <DropDown
               size           = { size }
               dropDownitems  = { dropDownItems }
               handleClose    = { handleClose }
               goTofather_app = { goTofather_app }
               handleGoToPdf  = { handleGoToPdf }
             />
           }
           <div style = { titleStyle }>
              { app.app_eng ? app.app_eng : (categories[app.origin] && categories[app.origin][0]) } 
           </div>
           
    </div>
  );
}





const mapStateToProps = (reducer: reducerType) => {

  return {
    hash:     !!reducer.content.hash ? reducer.content.hash : {},
    userInfo: reducer.user.userInfo,
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
   updateUserActivity: (payload: updateUserActivityType) => dispatch(UPDATE_USER_ACTIVITY_SAGA(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TopBar);




