import React              from 'react';
import { Dispatch }       from "redux";
import { connect }        from 'react-redux';
import { reducerType }    from 'reducer';
import { ActionTypes }    from 'actions/types';
import TextField          from '@mui/material/TextField';
import { makeStyles }     from 'makeStyles';
import { colors }         from 'utils/colors';
import { authStateType }  from 'reducer/types';
import { SET_AUTH_STATE } from 'actions/auth_actions';

const useStyles = makeStyles()((Theme) => ({
  container: {    
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    width:          '90%',
    height:         '90%',
    marginLeft:     '5%',
  },
  logo: {
     width:              200,
     height:             65,
     backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
     backgroundPosition: 'center',
     backgroundSize:     'contain',
     backgroundRepeat:   'no-repeat',
  },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
  },
 err: {
   display:        'flex',
   justifyContent: 'center',
   alignItems:     'center',
   textAlign:      'center',
   height:         30,
   color:          '#F00',
 }
}));


type TextInputProps = {
    size:         number[];
    authState:    authStateType;
    setAuthState: (val: authStateType) => void;
}

const TextInput: React.FC<TextInputProps> = ({
     size,
     authState,
     setAuthState
}) => { 

  const { classes } = useStyles();


  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'howDidYouHearAboutUs2') { setAuthState({ ...authState, userOrigin: target.value })}
    if (target.id === 'name2')                 { setAuthState({ ...authState, name: target.value })}
  }

  const textFieldStyle = React.useMemo(() => {
    return{ 
        transform:      'translate(0px, -20px)',
        width:           size[0] > 600 ? '350px' : '90%',
        height:          45,
        fontSize:        30,
        color:           colors.darkText,
        marginTop:       10,
        marginBottom:    10,
      }
  },[size])


  return (
      <div className = { classes.container }>       
          <div className = { classes.logo }/>
          <div className = { classes.err }> 
             { authState.error } 
          </div>
          <div> 
             Please enter your name or organization
          </div>
          <br/><br/>
          <form className = { classes.form }>
              <TextField
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "name2"
                label        = "Name / Organisation"
                onChange     = { changeInputValue }
              />
              <TextField
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "howDidYouHearAboutUs2"
                label        = "How did you hear about us?"
                autoComplete = "off"
                onChange     = { changeInputValue }
              />
          </form>
      </div>
    )
  
}




const mapStateToProps = (reducer: reducerType) => {
  return {
     authState: reducer.auth
  }
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setAuthState: (payload: authStateType) => dispatch(SET_AUTH_STATE(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TextInput);