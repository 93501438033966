import { store }                from '../../index';
import { SET_COMPONENTS_STATE } from '../../actions/component_actions'

const tutorialItemClick = (
    dataSet: string,
    val:     number
) => {

    const state               = store.getState();
    const componentsState     = state.components;
    const { selectedContent } = componentsState;

    if ( dataSet === 'tutorials' ) {
        store.dispatch(SET_COMPONENTS_STATE({
            ...componentsState,
            showTutorial:     true,
            showMenu:         true,
            showStudentVideo: false,
            showAppIntro:     false,
            showTopicIntro:   false,
            selectedVideo:    val,
            selectedContent:  selectedContent === 'introVideo' ? 'content' : selectedContent
        }))
    }

    if ( dataSet === 'studentVideos' ) {
        store.dispatch(SET_COMPONENTS_STATE({
            ...componentsState,
            showTutorial:     false,
            showStudentVideo: true,
            showMenu:         true,
            selectedVideo:    val,
            selectedContent:  selectedContent === 'introVideo' ? 'students' : selectedContent

        }))
    }
   
}

export default tutorialItemClick;

