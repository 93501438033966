import React           from 'react';
import { connect }     from 'react-redux';
import { Dispatch }    from "redux";
import { ActionTypes } from 'actions/types';
import { reducerType } from 'reducer';
import Tip             from './Tip';
import { 
  contentInfoType, 
  userInfoType 
}                      from 'types';
import { colors }      from 'utils/colors';
import { makeStyles }  from 'makeStyles';

const subscriberArr    = ['The_Top_Toolbar', 'Saving_A_Lesson'];
const nonSubscriberArr = ['The_Top_Toolbar'];


const useStyles = makeStyles()((Theme) => ({
  root: {
      position:         'fixed',
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      flexFlow:         'column',
      left:             5,
      transition:       'all 0.4s',
    },
    item: {
      width:            300,
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      flexFlow:         'column',
      marginTop:        10,
      marginBottom:     10,
      cursor:           'pointer',
      border:          '1px solid #DDD',
      borderRadius:    '0.4em',
      boxShadow:       '0px 4px 10px rgba(0,0,0,0.15)',
      backgroundColor: '#FFF',
      color:            colors.primary,
      fontWeight:       'bold',
      transition:       'transform 0.4s, height 0.4s',
    },
    textContainer: {
      width:            '100%',
      height:           45,
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      marginTop:        3,
      marginBottom:     3,
    },
    text: {
      '&:hover': {
        opacity: 0.5
      }
    },
    exContainer: {
      flex:             1,
      height:           '100%',
      display:          'flex',
      alignItems:       'top',
      justifyContent:   'flex-end',
    },
    ex: {
      '&:hover': {
        opacity: 0.5
      }
    },
    button: {
      minHeight:        40,
      maxHeight:        40,
      height:           40,
      width:            '100%',
      borderTop:        '1px solid #DDD',
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      cursor:           'pointer',
      '&:hover': {
        color: colors.primaryBright
      }
    },
    icon: {
      marginLeft: 5,
      '&:hover': {
        opacity: 0.5
      }
    }
}))

type ModalProps = {
  selectedApp: contentInfoType;
  userInfo:    userInfoType;
  guest:       boolean;
}

const Modal: React.FC<ModalProps> = ({ 
  selectedApp,
  userInfo,
  guest
}) => {

  //componentsState.appUrl

  const { classes } = useStyles();


  const arr = React.useMemo(() => {

    const audioArr = userInfo.subscriber ? subscriberArr : nonSubscriberArr;
    const newArr   = [];

    const appTypeIsANumber = !!selectedApp && !!selectedApp.app_type;

    for ( let i = 0; i < audioArr.length; i++ ) {
      if ( !userInfo.oneTimeEvents.includes(audioArr[i]) ) {

        const dontShowTopToolBar = audioArr[i] === "The_Top_Toolbar" && appTypeIsANumber;

        if ( !dontShowTopToolBar ) {
          newArr.push(audioArr[i])
        }
        
      }
    }

    return newArr;

  },[userInfo, selectedApp])

  
  return (
      <div className = { classes.root }>
          {
            arr.map((val) => {
              return(
                <Tip 
                    key         = { `AppIframeModal${ val }` }
                    val         = { val }
                    selectedApp = { selectedApp }
                    guest       = { guest }
                /> 
              )
            })
          }
      </div>
  );
}


const mapStateToProps = (reducer: reducerType) => {
  return {
    userInfo: reducer.user.userInfo
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

