import { store }               from 'index'; 
import { SET_ERROR }           from 'actions/auth_actions';
import { submitMessageType }   from 'actions/types';
import { SUBMIT_MESSAGE_SAGA } from 'actions';

const messageSubmition = () => {

    const state = store.getState();

    const authState  = state.auth;
    const { userId } = state.user.userInfo;
    const { name, email, message } = authState;

    const setError      = (payload: string) => store.dispatch(SET_ERROR(payload));
    const submitMessage = (payload: submitMessageType) => store.dispatch(SUBMIT_MESSAGE_SAGA(payload));



    if ( !name.length ) {
       setError('please enter your name');
       return;
    }
    if ( !email.length ) {
     setError('please enter your email address');
     return;
    }
    if ( !message.length ) {
     setError('please enter your message');
     return;
    }
    const payload = {
      userId:      userId,
      userName:    name,
      userEmail:   email,
      userMessage: message,
    }
    setError('')
    submitMessage(payload);
  }
  
export default messageSubmition;