import React          from 'react';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../../utils/colors';

var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({    input_container: {
        height:         '45vh',
        display:        'flex',
        alignItems:     'center',
        flexFlow:       'column',
        transition:     'all 1.2s',
    },
    text_field: {
        paddingLeft:     10,
        paddingRight:    10,
        backgroundColor: '#FFF',
        border:          `1px solid ${ colors.primary }`,
        borderRadius:    '0.25em',
        fontSize:        30,
        color:           colors.primary,
        marginTop:       10,
        marginBottom:    10,
        transition:      'background 0.2s',
        '&:focus': {
          outline: 'none',
          border:  `2px solid ${ colors.primary }`,
          height:   48,
         },
      },
    shake: {
        animation:               'shake 0.4s',
        animationIterationCount: 'infinite',
    }
}))

type IntegerProps = {
    combined:        boolean;
    inputInteger:    string;
    maxLength:       number;
    textFieldHeight: number;
    setInputInteger: (val: string) => void;
}

const Integer: React.FC<IntegerProps> = ({
    combined,
    inputInteger,
    textFieldHeight,
    setInputInteger,
    maxLength
}) => {

  const { classes } = useStyles();

  const [shake, setShake] = React.useState(false);

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'input1') {
        if (isNaN(+target.value) || target.value.length > maxLength) {
            target.value = inputInteger;
            setShake(true);
            setTimeout(() => { setShake(false) }, 1000)
            return;
        }
        setInputInteger(target.value)
    }
  }


  return (

    <div
       className = { shake ? clsx(classes.input_container, classes.shake) : classes.input_container }
       style     = {{
           height:         combined ? '75%'  : '45vh',
           width:          combined ? '100%' : '100%',
           justifyContent: combined ? 'center' : 'flex-start',
           paddingTop:     combined ? 0 : 50,
        }}
    >
            <input
                className    = { classes.text_field }
                type         = "text"
                autoComplete = "off"
                onChange     = { changeInputValue }
                value        = { inputInteger }
                id           = "input1"
                style        = {{
                    textAlign: "center",
                    width:     maxLength * 30,
                    height:    textFieldHeight,
                }}
            />
    </div>
  );
}

export default Integer;
