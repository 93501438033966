import React                from 'react';
import { Auth }             from 'aws-amplify';
import TextField            from '@mui/material/TextField';
import { planTypeType }     from 'types';
import subscribeAfterSignup from 'functions/auth/subscribe_after_signup';
import recordEvent          from 'functions/contentInteraction/record_event';
import useTextFieldStyle    from 'components/authenticator/functions/get_textfield_style';
import { colors }           from 'utils/colors';
import { makeStyles }       from 'makeStyles';


const useStyles = makeStyles()((Theme) => ({  
  container: {
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',
  },
  logo: {
    width:              200,
    height:             65,
    backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
 },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center'
  },
  text_field: {
    height:          45,
    fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
  },
  signup: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    marginTop:       20,
    fontSize:        20,
    color:           colors.darkText,
    width:           200,
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    height:          40,
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
   },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        22,
    fontFamily:     'Fredoka One',
  },
  forgot_pwd: {
     cursor: 'pointer',
     color:  colors.darkText,
     margin: 20
  },
  loader_container: {
    width:  60,
    height: 60
 },
  txt: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    textAlign:      'center',
    marginBottom:   3,
    marginTop:      3,   
    height:         30,
    color:          colors.darkText
  },
  err: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         30,
    color:          '#F00',
    cursor:         'default'
  },
  resend_code: {
    marginTop:      35,
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         30,
    color:          colors.darkText,
  },
  iseemath: {
    marginTop:  5,
    fontSize:   18,
    fontWeight: 'bold'
  }
}));


type CompleteSignUp2Props = {
    size:             number[];
    planType:         planTypeType;
    email:            string;
    code:             string;
    error:            string;
    showLogo:         boolean;
    setError:         (val: string) => void;
    setShowLoader:    (val: boolean) => void;
    setCode:          (code: string) => void;
    handleCreateUser: () => void;
}

const CompleteSignUp2: React.FC<CompleteSignUp2Props> = ({
     size,
     planType,
     email,
     code,
     error,
     showLogo,
     setError,
     setShowLoader,
     setCode,
     handleCreateUser
}) => { 

  const { classes } = useStyles();

  const textFieldStyle = useTextFieldStyle(size)

  const changeInputValue = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setCode(target.value)
  }


  const handleSubmit = async() => {

    if (!code.length) { 
      setError('Code cannot be empty'); return 
    }

    setShowLoader(true)

    try {
      await Auth.confirmSignUp(email, code);
      subscribeAfterSignup(planType);
      handleCreateUser(); 
      setShowLoader(false)
      recordEvent('registration', 'correct authentication code entered', 'complete sign up form 2')
    }
    catch(err: any) {

      setShowLoader(false)

      const _error = err.message || err;

      setError(_error || '')

      recordEvent('registration', `sign up failure: ${ _error }`, 'complete sign up form 2 error')
      console.error("error occured in signup_form_2 handleSubmit: ",_error);
    }
    finally {}
  }

  return(
    <div className = { classes.container }>
          { showLogo && <div className = { classes.logo }/> }
          { error.length > 0 && <div className = { classes.err }>{ error }</div> }
          <div className  = { classes.txt }>{ `An authentication code as been sent to` }</div>
          <div className  = { classes.txt }>{ email }</div>
          <div className  = { classes.txt }>{ `Note - If you can't find your authentication code in your inbox, it might have been transferred to your spam or promotions box` }</div>
          <form className = { classes.form }>
            <TextField
                className    = { classes.text_field }
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "code"
                label        = "Enter Your Code Here"
                onChange     = { changeInputValue }
            />
          </form>
          <div onClick = { handleSubmit } className = { classes.signup }>
             Continue
          </div>
      </div>
    )
  }

export default CompleteSignUp2