
import { call, take, put }                from 'redux-saga/effects';
import { UPDATE_USER_NAME, RECORD_ERROR } from '../actions';
import axios                              from 'axios';

const endpoint = 'https://iseemath.co/api/updateUserName.php';

function* updateUserName(): Generator<any,any,any> {
  while(true) {
    try {
        
        const input = yield take('UPDATE_USER_NAME_SAGA');
        
        const { userName, userEmail, userOrigin } = input.payload;

        if (process.env.NODE_ENV === 'production') {

            const obj = { 
              userName:   userName,
              userEmail:  userEmail,
              userOrigin: userOrigin
            }

            const payload = {
              userName:   userName,
              userOrigin: userOrigin 
            }

            yield put(UPDATE_USER_NAME(payload))

            const res = yield call( axios.post, endpoint, obj);

            if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
              yield put(RECORD_ERROR({ userEmail: userEmail, err: res.data.msg, endpoint: 'update_user_name' }));
            }

            console.log('res  :  ',res)
           
        }
       

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default updateUserName;
