import { store }            from 'index';
import { createDataType }   from 'actions/types'
import { CREATE_DATA_SAGA } from 'actions';

export const loadRealData = (
  guest:             boolean,
  createDataOnce:    boolean,
  setCreateDataOnce: (val: boolean) => void
) => {

    const state                          = store.getState(); 
    const { dataState, dataUpdateState } = state.data;
    const dataUpdated                    = dataUpdateState.final;
    const userEmail                      = state.user.userInfo.userEmail;
    const studentLoggedIn                = state.user.studentLoginState.loginState === 'success';
    const { hash, bigContentArr, apps, lessons, puzzles, userLessons } = state.content;

    const createData = (payload: createDataType) => store.dispatch(CREATE_DATA_SAGA(payload));


    if ( dataUpdated ) {
      return
    }

    // load real data (received all updated user data from DB)
 
    if ( 
         !studentLoggedIn &&
         dataUpdateState.user &&
         dataUpdateState.content &&
         dataUpdateState.studentVideos &&
         dataUpdateState.activity &&
         dataUpdateState.totalLikes &&
         dataUpdateState.scores &&
         !createDataOnce
       ) {

        console.log('about to create data  ')

        setCreateDataOnce(true);

        const content = {
          bigContentArr:  bigContentArr,
          apps:           apps,
          interaction:    [],
          lessons:        lessons,
          puzzles:        puzzles,
          bigTeacherArr:  [],
          mivdakim:       [],
          teacherLessons: [],
          userLessons:    userLessons
        }

        const payload = {
          content:     content,
          data:        dataState,
          hash:        hash,
          userEmail:   userEmail,
          variant:     'real',
          guest:       guest ? true : false
        }

         createData(payload)

       }

}