import React                          from 'react';
import { colors }                     from 'utils/colors';
import { planTypeType, userAccessType, userInfoType } from 'types'
import goToPayForm                    from 'functions/modal/go_to_payform';
import { openMessageForm }            from 'functions/modal/message_form';
import startNowFunction               from './functions/start_now_function';
import FiberManualRecordIcon          from '@mui/icons-material/FiberManualRecord';
import { makeStyles }                 from 'makeStyles';

var clsx = require('classnames');

const arrDefault = [
    ['Basic', 'Register for a 30 days free trial'],
    ['Monthly', 'Purchase a monthly plan for $12.95/mo'],
    ['Annually', 'Purchase an annual plan for $10.95/mo']
];

const arrUk = [
    ['Basic', 'Register for a 30 days free trial'],
    ['MonthlyUK', 'Purchase a monthly plan for £9.95/mo'],
    ['AnnuallyUK', 'Purchase an annual plan for £8.95/mo']
];

const arr2 = [
    `No credit card details needed`,
    `Access all of I See Math's apps, puzzles and games`,
    `Create and save your own lessons`
]


const useStyles = makeStyles()(
  (Theme) => ({    
    container: {
        width:           '100%',
        backgroundColor: '#FFF',
    },
    inner: {
        color:           colors.darkText,
        overflowY:       'scroll',
        overflowX:       'hidden',
        zIndex:           10,
        '&::-webkit-scrollbar': {
            width:      '0.5em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `#BBB`,
            borderRadius:    10
          }
    },
    root: {
       width:          '90%',
       marginLeft:     '5%',
       letterSpacing:    0.6
    },
    planContainer: {
        height:          50,
        marginTop:       10,
        width:           '75%',
        minWidth:        330,        
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        borderRadius:    '0.3em',
    },
    disabled: {
        backgroundColor: '#FFF',
        color:           colors.primary,
        border:          `2px solid ${ colors.primary }`,
        cursor:          'default',
        opacity:         0.3
    },
    notSelected: {
        backgroundColor: '#FFF',
        color:           colors.primary,
        border:          `2px solid ${ colors.primary }`,
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }
    },
    selected: {
        backgroundColor: colors.primary,
        color:           '#FFF',
        cursor:          'default',
        border:          `2px solid ${ colors.primary }`,
    },
    item: {
       fontWeight:     'bold',
       textDecoration: 'none',
    },
    pad: {
        paddingTop:    10,
        paddingBottom: 10,
    },
    ListItem: {
        display:        'flex',
        lineHeight:    1.5,
        marginTop:     4,
        fontWeight:    'bold',
        width:          '100%',
    },
    planName: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        minWidth:       130
    },
    column: {
       flex:           1,
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'celter',
       flexFlow:       'column',
       width:           '100%',
    },
    planTitle: {
       textAlign:     'center',
       fontWeight:    'bold',
       fontSize:      21,
       paddingTop:    19,
       paddingBottom: 20
    },
    list: {
        width:  '90%',
        marginTop: 25,
        display:   'flex',
        flexFlow:  'column',
    },
    icon: {
        fontSize:     10,
        paddingRight: 8,
        marginTop:    8
    },
    contact: {
        marginTop:      25,
        fontWeight:     'bold',
        color:          colors.primary,
        cursor:         'pointer',
        '&:hover': {
            opacity: 0.5
        }
    },
}))

type PlanSelectorPropsType = {
    tester:         string;
    guest:          boolean;
    wideScreen:     boolean;
    height:         number;
    userInfo:       userInfoType;
    planType:       planTypeType;
    userAccess:     userAccessType;
    setShowPayForm: (val: boolean) => void;
    setPlanType:    (val: planTypeType) => void;
    completeSignUp: () => void;
} 

const PlanSelector: React.FC<PlanSelectorPropsType> = ({ 
    tester,
    guest,
    wideScreen,
    height,
    userInfo,
    planType,
    userAccess, 
    setShowPayForm,
    setPlanType,
    completeSignUp
}) => {

  const { classes } = useStyles();

  

  const arr = React.useMemo(() => {
      return userInfo.countryName === 'United Kingdom' ? arrUk : arrDefault
  },[userInfo])


  const disableBasicPlan = React.useMemo(() => {
      const { allowTrial, trialStartedAt, blocked, completeTrial, subscriber } = userInfo;
      return (subscriber || blocked || allowTrial || completeTrial || ( !allowTrial && trialStartedAt.length > 0 ))
  },[userInfo])


  const [suspendedSub, hasPassword, freeTrial] = React.useMemo(() => {
    const { subscriptionSuspended, hasPassword, allowTrial } = userInfo;
    return[ subscriptionSuspended, hasPassword, allowTrial ]
 },[userInfo])


  const startNowFunc = React.useCallback((val: planTypeType) => {
    const handleGoToPayform = () => { goToPayForm( setShowPayForm ) }
    startNowFunction(val, suspendedSub, guest, freeTrial, userAccess.unSubscribedAuthedUser, hasPassword, handleGoToPayform, completeSignUp);
  },[freeTrial, guest, hasPassword, /* planType, */ suspendedSub, userAccess.unSubscribedAuthedUser, setShowPayForm, completeSignUp])



  const handleClick = (val: string) => {
    if ( disableBasicPlan && val === 'Basic' ) {
        return;
    }
    if (val === 'Basic' || val === 'Monthly' || val ===  'Annually' || val === 'MonthlyUK' || val ===  'AnnuallyUK') {
        if ( tester === 'true' ) {
            setPlanType('testPlan');
            return;
        }
        setPlanType(val);
        startNowFunc(val);
    }
  }



  const [contentStyle, innerStyle, iconStyle] = React.useMemo(() => {
    return[
       { height: height - 82 },
       { 
         height:   height - 82,
         fontSize: wideScreen ? 17 : 15
       },
       {
         fontSize:     10,
         paddingRight: 8,
         marginTop:    8
       }
    ]
  },[wideScreen, height])


  return (
    <div 
       className = { classes.container }
       style     = { contentStyle }
    >
        <div 
          className = { classes.inner }
          style     = { innerStyle }
        >
            <div 
               className = { classes.root }
               style     = { contentStyle }
            >   
                <div className = { classes.column }>
                    <div className = { classes.planTitle }>
                            Select Your I See Math Subscription Plan
                    </div>
                    <div 
                        className = { clsx(classes.planContainer, (  disableBasicPlan ? classes.disabled : planType === arr[0][0] ? classes.selected : classes.notSelected )) }
                        onClick   = { () => { handleClick(arr[0][0]) }}
                    >
                        <div className = { classes.item }>
                            { arr[0][1] }
                        </div>
                    </div>
                            
                    <div className = { classes.list }>
                    {
                        arr2.map((val) => {
                            return(
                                <div 
                                   key       = { `${ val }` }
                                   className = { classes.ListItem }
                                > 
                                   <FiberManualRecordIcon style = { iconStyle }/>
                                   <div>{ val }</div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className = { clsx(classes.item, classes.pad) }>
                            or
                    </div>
                    <div 
                        className = { clsx(classes.planContainer, ( planType === arr[1][0] ? classes.selected : classes.notSelected )) }
                        onClick   = { () => { handleClick(arr[1][0]) }}
                    >
                        <div className = { classes.item }>
                            { arr[1][1] }
                        </div>
                    </div>
                    <div 
                        className = { clsx(classes.planContainer, ( planType === arr[2][0] ? classes.selected : classes.notSelected )) }
                        onClick   = { () => { handleClick(arr[2][0]) }}
                    >
                        <div className = { classes.item }>
                            { arr[2][1] }
                        </div>
                    </div>
                    <a 
                        className = { clsx(classes.planContainer, classes.notSelected, classes.item ) }
                        style     = {{ color: colors.primary }}
                        href      = 'https://iseemath.co/schoolSignup.html'
                        target    = 'blank'
                    >
                         School-level subscription
                    </a>
                    <div 
                       className = { classes.contact }
                       onClick   = { () => { openMessageForm(guest) }}
                    >
                        contact us
                    </div>
                </div>
            </div> 
        </div>
    </div>   
  );
}




export default PlanSelector;



