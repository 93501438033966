import { componentsStateType, pageStateType } from '../../reducer/types'


const gradesClick = (
    pageState:        pageStateType,
    filterState:      componentsStateType,
    setFilterState:   (val: componentsStateType) => void,
) => {

    const createTopicArr = filterState.openGradeGrid;
   // const selectedContent = filterState.selectedContent === 'favorites' ? ( pageState.page === 'content' ? 'bigContentArr' : 'allTeacherContent' ) : filterState.selectedContent;

    setFilterState({
        ...filterState,
        openGradeGrid:    !filterState.openGradeGrid,
        selectedContent:  'apps',
        showAppIntro:     false,
        showTutorial:     false,
        showStudentVideo: false,
        showTopicIntro:   false,
        selectedVideo:    -1,
        searchStr:        '',
        createTopicArr:   createTopicArr
    })

}

export default gradesClick;
