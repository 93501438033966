import React          from 'react';
import Heads          from './Heads';
import Text           from './Text';
import { sizes }      from '../../utils/defaultStates';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      position:  'fixed',
      width:     '100%',
   //   backgroundColor: colors.brightGrey
    }
}))

type aboutProps = {
    size:     number[];
    menuSize: number;
}
const About: React.FC<aboutProps> = ({ 
    size,
    menuSize,
}) => {

  const { classes } = useStyles();


  const [wideScreen, headsHeight] = React.useMemo(() => {
    return [ (size[0] > 1000 && size[1] > 500), (size[1] > 500 ? 200 : 0) ]
  },[size])


  const textHeight = React.useMemo(() => {
     return size[1] - sizes.navBarSize - ( wideScreen ? 0 : headsHeight ) - sizes.footerSize;
  },[size, wideScreen, headsHeight])


  return (
    <div className = { classes.root } 
       style       = {{
         top:    sizes.navBarSize + sizes.tabSize,
         height: size[1] - sizes.navBarSize - sizes.footerSize - sizes.tabSize
       }}
    >
        <Heads
            wideScreen   = { wideScreen }
            size         = { size } 
            menuSize     = { menuSize }
            headsHeight  = { headsHeight }
        />
        <Text 
            wideScreen   = { wideScreen }
            size         = { size } 
            menuSize     = { menuSize }
            headsHeight  = { headsHeight }
            textHeight   = { textHeight }
        />
       {/*  <Footer
            size         = { size }
            wideScreen   = { wideScreen }
            menuSize     = { menuSize }
            headsHeight  = { headsHeight }
            textHeight   = { textHeight }
            footerHeight = { footerHeight }
        /> */}
    </div>
  );
}




export default About;

