import React              from 'react';
import { Dispatch }       from "redux";
import { connect }        from 'react-redux';
import { reducerType }    from 'reducer';
import { ActionTypes }    from 'actions/types';
import TextField          from '@mui/material/TextField';
import { makeStyles }     from 'makeStyles';
import { colors }         from 'utils/colors';
import { authStateType }  from 'reducer/types';
import { SET_AUTH_STATE } from 'actions/auth_actions';

const useStyles = makeStyles()((Theme) => ({
  container: {    
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',
  },
  form: {
    flex:           1,
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
  },
  userInfo: {
    height:         100,
    //borderBottom:   '1px solid #BBB',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'space-evenly',
    fontWeight:     'bold'
  },
  userName: {
     // marginLeft: 15
  },
  err: {
   display:        'flex',
   justifyContent: 'center',
   alignItems:     'center',
   textAlign:      'center',
   height:         30,
   color:          '#F00',
 }
}));


type MessageFormProps = {
    size:         number[];
    authState:    authStateType;
    setAuthState: (val: authStateType) => void;
}

const MessageForm: React.FC<MessageFormProps> = ({
     size,
     authState,
     setAuthState
}) => { 

  const { classes } = useStyles();


  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'name3')   { setAuthState({ ...authState, name: target.value })}
    if (target.id === 'email3')  { setAuthState({ ...authState, email: target.value })}
    if (target.id === 'message') { setAuthState({ ...authState, message: target.value })}
  }

  const [textFieldStyle, textAreaStyle, rows] = React.useMemo(() => {

    return[
        { 
            width:           size[0] > 600 ? '350px' : '90%',
            height:          45,
            fontSize:        30,
            color:           colors.darkText,
            marginTop:       10,
            marginBottom:    10,
        },
        { 
            width:           size[0] > 600 ? '350px' : '90%',
            fontSize:        30,
            color:           colors.darkText,
            marginTop:       10,
            marginBottom:    10,
        },
        (size[1] > 450 ? size[1] > 550 ? 10 : 6 : 2)
    ]
  },[size])

  const [name, email, message] = React.useMemo(() => {
    const  { name, email, message } = authState;
    return [name, email, message];
  },[authState])

  return (
      <div className = { classes.container }>       
          <div className = { classes.err }> 
             { authState.error } 
          </div>
         
          <form className = { classes.form }>
              <TextField
                 style        = { textFieldStyle }
                 id           = "name3"
                 label        = "Name / Organisation"
                 variant      = "outlined"
                 value        = { name } 
                 onChange     = { changeInputValue }
              />
              <TextField
                 style        = { textFieldStyle }
                 id           = "email3"
                 label        = "Email address"
                 variant      = "outlined"
                 value        = { email } 
                 onChange     = { changeInputValue }
              />
              <TextField
                style        = { textAreaStyle }
                id           = "message"
                label        = "Message"
                variant      = "outlined"
                multiline 
                rows         = { rows }
                value        = { message } 
                autoComplete = "off"
                onChange     = { changeInputValue } 
              />
          </form>
      </div>
    )
  
}




const mapStateToProps = (reducer: reducerType) => {
  return {
     authState: reducer.auth
  }
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setAuthState: (payload: authStateType) => dispatch(SET_AUTH_STATE(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(MessageForm);