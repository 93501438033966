
import { ActionTypes }    from '../actions/types';
import { modalStateType } from '../reducer/types';

const initialState: modalStateType = {
    modalVariant:     '',
    showModal:        false,
    showButtons:      false,
    disabledVariants: []
};

const modalReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {

    case 'SET_MODAL_STATE':
    {
    
      const { variant, open, showButtons } = action.payload;
    
      return {
        ...state,
        modalVariant: variant,
        showModal:    open,
        showButtons:  showButtons,
      }
    }

    case 'CREATE_ERROR': {

      return {
        ...state,
        modalVariant: 'generalError',
        showModal:    true,
        showButtons:  false
      }
    
    }

    case 'DISABLE_MODAL_VARIANT': {

      const disable = action.payload;
      let arr = state.disabledVariants;
      arr.push(disable);

      return {
        ...initialState,
        disabledVariants: arr
      }
    
    }

    

    default: return state;
  }
};

export type reducerType = ReturnType<typeof modalReducer>;

export { modalReducer };
