import { Auth }           from 'aws-amplify';
import { store }          from '../../index';
import { 
    SET_AUTH_STATE,
    SET_AUTH_LOADER,
    SET_ERROR
}                         from '../../actions/auth_actions';
import { authStateType }  from '../../reducer/types';
import recordEvent        from 'functions/contentInteraction/record_event';
import reload             from 'functions/reload';


const signIn = async() => {

    const state               = store.getState();
    const authState           = state.auth
    const { email, password } = authState;

    const setAuthState  = (payload: authStateType) => store.dispatch(SET_AUTH_STATE(payload));
    const setError      = (payload: string) => store.dispatch(SET_ERROR(payload));
    const setShowLoader = (payload: boolean) => store.dispatch(SET_AUTH_LOADER(payload));


    if (!email.length) { setError('Email cannot be empty'); return }
    if (!password.length) { setError('Password cannot be empty'); return }

    setShowLoader(true);
    
    try {

        recordEvent('registration', 'user signed in', 'signIn')

        await Auth.signIn(email, password);
        setShowLoader(false);
        reload();
    }
    catch(err: any) {

        const _error = err.message || err;

        console.error("error occured in sign_in_form handleSubmit: ",_error);
        setShowLoader(false);
        let errText = '';

        if (_error === 'Username cannot be empty') { errText = 'Email cannot be empty' }
        setError(errText.length ? errText : (_error || ''));

        if (err === "User is not confirmed." || err.message === "User is not confirmed.") {
          await Auth.resendSignUp(email); 
          console.log("User is not confirmed.")
          setAuthState({
              ...authState,
              userConfirmed: false,
              displayForm:   'sign_up_part2'
          })
        }
    }
    finally {
    }
}


export default signIn;