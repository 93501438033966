import React            from 'react';
import { 
  useNavigate,
  useLocation
}                       from "react-router-dom";
import { makeStyles }   from 'makeStyles';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { colors }       from 'utils/colors';

const useStyles = makeStyles()((Theme) => ({
    
  container: {
      position:       'fixed',
      top:            15,
      right:          15,
      height:         35,
      width:          35,
     // marginTop:      10,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      borderRadius:   '50%',
      cursor:         'pointer',
      transition:     'all 0.3s',
      border:         `1px solid ${ colors.primary }`,
      zIndex:         1
  },
  icon: {
   // color:    '#FFF',
    fontSize: 35,
  },
  link: {
    textDecoration: 'none',
    color:          colors.darkText
  }
}))

type ButtonProps = {
   func: () => void;
}

const Button: React.FC<ButtonProps> = ({ func }) => {

  const [initialPosition, setInitialPosition] = React.useState(false);
  const [hover, setHover]                     = React.useState(false);

  React.useEffect(() => {
     setTimeout(() => { setInitialPosition(true) }, 10)
  },[setInitialPosition])

  const { classes } = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const rootClick = () => {
    if ( location.pathname !== '/') {
      navigate('../', { replace: true });
    }
    func();
  }

  return (
    <div 
      className = { classes.link }
      onClick   = { rootClick }
    >
        <div
          className    = { classes.container }
         // onClick      = { func }
          onMouseEnter = { () => { setHover(true) }}
          onMouseLeave = { () => { setHover(false) }}
          style        = {{
            marginLeft:      initialPosition ? 0 : 600,
            backgroundColor: !hover ? '#FFF' : colors.primary
          }}
        >
            <ClearRoundedIcon className = { classes.icon } style = {{ color: !hover ? colors.primary : '#FFF' }}/>
        </div>   
    </div>
  );
}

export default Button;
