
import React                        from 'react';
import { PayPalScriptProvider }     from "@paypal/react-paypal-js";
import SubscriptionForm             from '../SubscriptionForm';

import { 
    UKData, 
    planOptions, 
    //sandBoxData, 
    //sandBoxOptions
}                                   from "utils/payment_plans";
import { modalStateType }           from 'reducer/types';


type UKFormsProps = {
    size:               number[];
    modalState:         modalStateType;
    planType:           string;
    tester:             string;
    showPayForm:        boolean;
    subscribe:          (data: any) => void;
    handleClose:        () => void;

  }
  
  const UKForms: React.FC<UKFormsProps> = ({ 
     size, 
     modalState,
     planType,
     showPayForm,
     tester,
     subscribe,
     handleClose,
  }) => {

    //const { classes } = useStyles();
    

    return (
     <>
      {/*   {
          tester === 'false'
          &&
          <> */}
            <PayPalScriptProvider options = { planOptions }>
               <SubscriptionForm
                   variant         = { 'Annually' }
                   account         = { 'live' }
                   country         = { 'UK' }
                   tester          = { tester }
                   size            = { size }
                   showPayForm     = { showPayForm }
                   showModal       = { modalState.showModal }
                   planId          = { UKData.annualPlanId }
                   planType        = { planType }
                   subscribe       = { subscribe }
                   handleClose     = { handleClose }
               />
            </PayPalScriptProvider>
            <PayPalScriptProvider options = { planOptions }>
               <SubscriptionForm
                   variant         = { 'Monthly' }
                   account         = { 'live' }
                   country         = { 'UK' }
                   tester          = { tester }
                   size            = { size }
                   showPayForm     = { showPayForm }
                   showModal       = { modalState.showModal }
                   planId          = { UKData.monthlyPlanId }
                   planType        = { planType }
                   subscribe       = { subscribe } 
                   handleClose     = { handleClose }
               />
            </PayPalScriptProvider>
         {/*  </>
        }
        {
          tester === 'true'
          &&
          <>
             <PayPalScriptProvider options = { sandBoxOptions }>
                <SubscriptionForm
                    variant         = { 'Monthly' }
                    account         = { 'live' }
                    country         = { 'UK' }
                    tester          = { tester }
                    size            = { size }
                    showPayForm     = { showPayForm }
                    showModal       = { modalState.showModal }
                    planId          = { UKData.testPlan }
                    planType        = { planType }
                    subscribe       = { subscribe }  
                    handleClose     = { handleClose }    
                />
              </PayPalScriptProvider>
              <PayPalScriptProvider options = { sandBoxOptions }>
                <SubscriptionForm
                    variant         = { 'Annually' }
                    account         = { 'live' }
                    country         = { 'UK' }
                    tester          = { tester }
                    size            = { size }
                    showPayForm     = { showPayForm }
                    showModal       = { modalState.showModal }
                    planId          = { UKData.testPlan }
                    planType        = { planType }
                    subscribe       = { subscribe }    
                    handleClose     = { handleClose }  
                />
               </PayPalScriptProvider>
          </>
        } */}
    </>
  )}




export default UKForms