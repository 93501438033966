import React           from 'react';
import CloseIcon       from '@mui/icons-material/CloseRounded';
//import ListIcon        from '@mui/icons-material/ListRounded';
import { makeStyles }  from 'makeStyles';
import { colors }      from '../../../utils/colors';

const useStyles = makeStyles()((Theme) => ({
    root: {
        top:            0,
        right:          0,
        position:       'fixed',
        width:          80,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'flex-end',
    },
    round_button: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        flexFlow:       'column',
       
        borderRadius:    '50%',
        cursor:          'pointer',
        border:          `1px solid ${ colors.primary }`,
        backgroundColor: '#FFF',
        transition:      'color 0s, backgroundColor 0s, transform 1s',
        color:           colors.primary,
        '&:hover': {
            color:           '#FFF',
            border:          `1px solid #FFF`,
            backgroundColor: colors.primary,
        }
    },
    icon: {
      fontSize: 35,
    }
}))

type IntroNavBarProps = {
    initialAnimation:    boolean;
    showInstructions:    boolean;
    wideScreen:          boolean;
    setShowInstructions: (val: boolean) => void;
    abortChallenge:      () => void;
}

const IntroNavBar: React.FC<IntroNavBarProps> = ({ 
    initialAnimation,
    showInstructions,
    wideScreen,
    setShowInstructions,
    abortChallenge
  }) => {

  const { classes } = useStyles();

  const handleClick = () => {
     if ( showInstructions ) {
        setShowInstructions(false)
     }
     else {
        abortChallenge()    
     }
  }

  return (
    <div 
       className = { classes.root }
       style     = {{
          height: wideScreen ? 80 : 50
       }}
    > 
         <div 
            className = { classes.round_button } 
            onClick   = { handleClick }
            style     = {{
                transform: `translate(${ initialAnimation ? 0 : 250 }px, 0px) rotate(${ initialAnimation ? 0 : 720 }deg)`,
                height:      wideScreen ? 45 : 36,
                width:       wideScreen ? 45 : 36,
                marginRight: wideScreen ? 20 : 6,
            }}
          >
             <CloseIcon className = { classes.icon }/>
          </div>
    </div>
  );
}

export default IntroNavBar;
