
import React                       from 'react';
import { store }                   from 'index';
import { updateFreeAccessType }    from 'actions/types';
import { UPDATE_FREE_ACCESS_SAGA } from 'actions';

const useUpdateAccessStatus = (
    guest:    boolean,
    freeUser: boolean
) => {

    const state           = store.getState();
    const userInfo        = state.user.userInfo;
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const userInfoUpdated = state.data.dataUpdateState.user;

   React.useEffect(() => {

       const updateFreeAccess = (payload: updateFreeAccessType) => store.dispatch(UPDATE_FREE_ACCESS_SAGA(payload));

       if (!studentLoggedIn && userInfoUpdated && !guest ) {

           const payload: updateFreeAccessType = {
             userEmail:  userInfo.userEmail,
             freeAccess: freeUser ? 'true' : 'false'
           }
    
           updateFreeAccess(payload);
       }
     
 
   },[ guest, freeUser, userInfoUpdated, studentLoggedIn, userInfo ])

}

export default useUpdateAccessStatus;