import { store }           from 'index';
import { SET_AUDIO_STATE } from 'actions/audio_actions';
import { contentInfoType } from 'types';

const audioClick = () => { 

    const setAudioState = (payload: contentInfoType) => store.dispatch(SET_AUDIO_STATE(payload));

    setTimeout(() => {
        setAudioState({
          playAudio:  true,
          pauseAudio: false,
        })
    },50)
}

export default audioClick;