import React                  from 'react';
import { connect }            from 'react-redux';
import { reducerType }        from 'reducer';
import { Dispatch }           from "redux";
import { ActionTypes }        from 'actions/types';
import { colors }             from 'utils/colors';
import PlayArrowRoundedIcon   from '@mui/icons-material/PlayArrowRounded';
import { makeStyles }         from 'makeStyles';
import { audioStateType }     from 'reducer/types';
import { SET_AUDIO_STATE }    from 'actions/audio_actions';
import recordEvent            from 'functions/contentInteraction/record_event';
import { contentInfoType }    from 'types';


var clsx = require('classnames')

const audioSrc: { [key: string]:string } = {
  Integers:          '04_Integers',
  Fractions:         '05_Fractions',
  Geometry:          '06_Geometry',
  Additional_Topics: '07_Additional_Topics',
} 

const useStyles = makeStyles()(
  (Theme) => ({    
    root: {
      height:          320,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-evenly',
      flexFlow:        'column',
      transition:      'opacity 0.2s',
    },
    inner: {
      height:          290,
     // width:           270,
      width:           '90%',
      borderRadius:    '0.4em',
      border:          `1px solid #E5E5E5`,
      transition:      'height 0.2s, width 0.2s, box-shadow 0.2s',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      flexFlow:        'column',
      backgroundColor: '#FFF',
      overflow:        'hidden',
      boxShadow:       '0px 3px 2px rgba(0,0,0,0.07)',
      cursor:          'pointer',
    },
    notDisabled: {
      '&:hover': {
        height:    315,
        width:     '96%',
        boxShadow: '0px 8px 7px rgba(0,0,0,0.04)',
      }
    },
    imageContainer: {
        width:           '85%',
        height:          '85%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-evenly',
        flexFlow:        'column',
        textDecoration:  'none'
        //border: '1px solid #0FF'
      },
      image: {
        height:             '60%',
        width:              '90%',
        backgroundPosition: 'center',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat',
        //border: '1px solid #000'
    },
    name: {
      height:         '20%',
      width:          '90%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'flex-end',
      flexFlow:       'column',
      textAlign:      'center',
      fontWeight:     'bold',
      fontSize:       19,
      color:          colors.darkText,
      textDecoration: 'none'
    },
    iconContainer: {
      width:          '100%',
      height:         25,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'flex-start',
    },
    icon: {
      marginRight: 10,
      cursor:      'pointer',
      color:       colors.primary,
      '&:hover': {
        color: colors.primaryDark
      }
    }
}))

type UnitProps = {
  gridOpacity:   number;
  topic:         string;
  image:         string;
  disable:       boolean;
  guest:         boolean;
  audioState:    audioStateType;
  setAudioState: (val: contentInfoType) => void;
  onClick:       (val: string) => void;
}

const Unit: React.FC<UnitProps> = ({
  gridOpacity,
  topic,
  image,
  disable,
  guest,
  audioState,
  setAudioState,
  onClick
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {

    setTimeout(() => {
      setInitialPos(true)
    }, 10)
  },[])

  const audioId = React.useMemo(() => {
    const longFile = guest && !audioState.appMenuAudioPlayedOnce && topic !== 'Additional_Topics';
    return `https://iseemath.co/audio/${ longFile ? `${ audioSrc[topic] }2.m4a` : `${ audioSrc[topic] }.wav` }`;
  },[guest, audioState, topic ])



  const setAudioSrc = () => {
    setAudioState({
      playAudio:              false,
      pauseAudio:             true,
      appMenuAudioPlayedOnce: true,
      audioSrc:               audioId
    })
  }


  const handleAudioClick = () => {
      
       setAudioSrc();
       
       setTimeout(() => {
         setAudioState({
           playAudio:  true,
           pauseAudio: false,
         })
       },50)

       recordEvent('audio', 'audio played', audioId)
  }

//
  return (
    <div 
      dir       = 'rtl'
      className = { classes.root }
      style     = {{
        opacity: initialPos ? (disable ? 0.5 : gridOpacity) : 0
      }}
    >
        <div className = { disable ? classes.inner : clsx(classes.inner, classes.notDisabled) }>
           {
              audioState.audioGuideOn &&
              <div className = { classes.iconContainer }>
                  <PlayArrowRoundedIcon 
                    className = { classes.icon }
                    onClick   = { () => { 
                      handleAudioClick();
                      if (!disable){ 
                        onClick(topic) 
                      } 
                    }}
                  />
              </div>
           }
           <div 
              className = { classes.imageContainer }
              onClick   = { () => { if (disable){ return; } setAudioSrc(); onClick(topic) }}
           >
              <div
                  className = { classes.image }
                  style     = {{ backgroundImage: image }}
              />
              <div className = { classes.name }>
                 { !!topic ? topic.replaceAll('_', ' ') : '' }
              </div>
           </div>
        </div>
    </div>
  );
}




const mapStateToProps = (reducer: reducerType) => {

  return {
    hash:       reducer.content.hash,
    audioState: reducer.audio
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setAudioState: (payload: contentInfoType) => dispatch(SET_AUDIO_STATE(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Unit);












//  "homepage": "https://iseemath.co/stage/",



