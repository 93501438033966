
import { pageStateType } from "reducer/types";


export const SET_PAGE_STATE = (payload: pageStateType) => ({
  type: 'SET_PAGE_STATE',
  payload: payload
});


export const GO_TO_HOME_PAGE = () => ({
    type: 'GO_TO_HOME_PAGE',
    payload: true
  });








 


