import { Auth } from 'aws-amplify';

const getUserGroup = async() => {

    try {      
        const user = await Auth.currentAuthenticatedUser();

        const arr = user.signInUserSession.accessToken.payload["cognito:groups"];

        return arr;
      
    }
    catch(err) {
        console.log('err : ',err)
        return[];
    }
    finally {
    }

}

export default getUserGroup;
