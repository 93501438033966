

const compareLikes = ( a: { [key: string]: any }, b: { [key: string]: any } ) => {
  if ( +a.totalLikes > +b.totalLikes ){
    return -1;
  }
  if ( +a.totalLikes < +b.totalLikes ){
    return 1;
  }
  return 0;
}


const comparePuzzles = ( a: { [key: string]: any }, b: { [key: string]: any } ) => {
  if ( a.puzzletype > b.puzzletype ){
    return -1;
  }
  if ( a.puzzletype < b.puzzletype ){
    return 1;
  }
  return 0;
}


const sortContent = ( 
  content: { [key: string]: any }[],
  sortBy:  string
) => {

  if ( sortBy === 'likes' ) {
    content.sort( compareLikes );
  }

  if ( sortBy === 'puzzleType' ) {
    content.sort( comparePuzzles );
  }

  
  return content;

}


export default sortContent;