import React          from 'react';
import Integer        from './Integer';
import Fraction       from './Fraction';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({    container: {
        height:         '80%',
      //  width:          200,
        display:        'flex',
      //  alignItems:     'center',
       // justifyContent: 'space-around',
       // justifyContent: 'flex-start',
    },
    int: {
        marginRight: 30
    }
}))


type CombinedProps = {
    inputInteger:         string;
    maxLength:            number;
    inputNumerator:       string;
    inputDenominator:     string;
    numeratorMaxLength:   number;
    denominatorMaxLength: number;
    textFieldHeight:      number;
    setInputInteger:      (val: string) => void;
    setInputNumerator:    (val: string) => void;
    setInputDenominator:  (val: string) => void;
}

const Combined: React.FC<CombinedProps> = ({
    inputInteger, 
    maxLength,
    inputNumerator,
    inputDenominator,
    numeratorMaxLength,
    denominatorMaxLength,
    textFieldHeight,
    setInputInteger,
    setInputNumerator,
    setInputDenominator,
}) => {

  const { classes } = useStyles();

  return (

    <div className = { classes.container }>
        <div className = { classes.int }>
            <Integer
                combined        = { true }
                inputInteger    = { inputInteger }
                setInputInteger = { setInputInteger }
                maxLength       = { maxLength }
                textFieldHeight = { textFieldHeight }
            />
        </div>
        <Fraction
            combined             = { true }
            maxLength            = { maxLength }
            inputInteger         = { inputInteger }
            inputNumerator       = { inputNumerator }
            setInputNumerator    = { setInputNumerator }
            inputDenominator     = { inputDenominator }
            setInputDenominator  = { setInputDenominator }
            numeratorMaxLength   = { numeratorMaxLength }
            denominatorMaxLength = { denominatorMaxLength }
            textFieldHeight      = { textFieldHeight }
        />
    </div>
  );
}

export default Combined;

