import React           from 'react';
import { makeStyles }  from 'makeStyles';

var clsx = require('classnames')

const b_   = '#2F3F9F'
const c_   = '#45B'
const d_   = '#56C'
const e_   = "#3F4FAF"
const f_   = '#203090'
const g_   = '#34A'


const useStyles = makeStyles()(
  (Theme) => ({    
    dimensions150: {
        width:  150,
        height: 150,
    },
    dimensions100: {
        width:  100,
        height: 100,
    },
    dimensions50: {
        width:  50,
        height: 50,
    },
    root: {
        position:        'fixed',
        maxHeight:       '100vh',
        maxWidth:        '100vw',
        backgroundColor: '#FFF'
    },
    boxCanvas: {
        perspective: 600
    },
    qube: {
       position:        'relative',
        transformStyle: 'preserve-3d',
        animation:      'rotateQube 30s linear infinite'
    },
    back: {
        position:        'absolute',
        backgroundColor: b_    
    },
    left: {
        position:        'absolute',
        backgroundColor: c_    
    },
    right: {
        position:        'absolute',
        backgroundColor: d_
    },
    top: {
        position:        'absolute',
        backgroundColor: e_
    },
    bottom: {
        position:        'absolute',
        backgroundColor: f_
    },
    front: {
        position:        'absolute',
        backgroundColor: g_
    },
}))


type VolumesProps = {
    dimensions: number;
}

const Volumes: React.FC<VolumesProps> = ({ dimensions }) => {

  const { classes } = useStyles();

  return (
        <div className = { dimensions === 150 ? clsx(classes.boxCanvas, classes.dimensions150) : dimensions === 50 ? clsx(classes.boxCanvas, classes.dimensions50) : clsx(classes.boxCanvas, classes.dimensions100) }>
            <div className = { dimensions === 150 ? clsx(classes.qube, classes.dimensions150) : dimensions === 50 ? clsx(classes.qube, classes.dimensions50) : clsx(classes.qube, classes.dimensions100) }>
                <div className = { clsx(classes.back,   (dimensions === 150 ? classes.dimensions150 : dimensions === 50 ? classes.dimensions50 : classes.dimensions100)) } style = {{ transform:  `translateZ(${ -dimensions/2 }px)` }}/>
                <div className = { clsx(classes.left,   (dimensions === 150 ? classes.dimensions150 : dimensions === 50 ? classes.dimensions50 : classes.dimensions100)) } style = {{ transform:  `translateX(${ -dimensions/2 }px) rotateY(90deg)` }}/>
                <div className = { clsx(classes.right,  (dimensions === 150 ? classes.dimensions150 : dimensions === 50 ? classes.dimensions50 : classes.dimensions100)) } style = {{ transform:  `translateX(${ dimensions/2 }px) rotateY(90deg)` }}/>
                <div className = { clsx(classes.top,    (dimensions === 150 ? classes.dimensions150 : dimensions === 50 ? classes.dimensions50 : classes.dimensions100)) } style = {{ transform:  `translateY(${ -dimensions/2 }px) rotateX(90deg)` }}/>
                <div className = { clsx(classes.bottom, (dimensions === 150 ? classes.dimensions150 : dimensions === 50 ? classes.dimensions50 : classes.dimensions100)) } style = {{ transform:  `translateY(${ dimensions/2 }px) rotateX(90deg)` }}/>
                <div className = { clsx(classes.front,  (dimensions === 150 ? classes.dimensions150 : dimensions === 50 ? classes.dimensions50 : classes.dimensions100)) } style = {{ transform:  `translateZ(${ dimensions/2 }px)` }}/>
            </div>
       </div>
  );
}

export default Volumes;
