import React                  from 'react';
import { makeStyles }         from 'makeStyles';
import { colors }             from '../../utils/colors';

const useStyles = makeStyles()((Theme) => ({
    root: {
        position:   'fixed',
        top:        0,
        left:       0,
        width:      '100vw',
        height:     10,
        display:    'flex',
     },
     blue: {
        backgroundColor: colors.primary,
        transition:      'all 0.6s',
        borderRadius:    '0 0.5em 0.5em 0'
     },
     white: {
         backgroundColor: '#F7F7FF',
         transition:      'all 0.6s'
      },
}))

type ProgressBarProps = {
  length:  number;
  testNum: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ length, testNum }) => {

  const { classes } = useStyles();

  return (
    <div className = { classes.root }>
       <div className = { classes.blue }  style = {{ flex: testNum }}/>
       <div className = { classes.white } style = {{ flex: length - testNum }}/>
   </div>
   
  );
}

export default ProgressBar;
