import React          from 'react';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../utils/colors';

var clsx  = require('classnames')
const abc = ['a','b','c','d','e','f','g','h','i','j'];


const useStyles = makeStyles()(
  (Theme) => ({    root: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        flexFlow:       'column',
    },
    unit: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
       // height:          35,
        width:           '80vw',
        maxWidth:        480,
        marginTop:       8,
        marginBottom:    8,
        paddingTop:      6,
        paddingBottom:   6,
        paddingLeft:     15,
        paddingRight:    15,
        lineHeight:      1.6,
        letterSpacing:   1,
        boxShadow:       '2px 4px 9px rgba(0,0,0,0.05)',
        fontWeight:      'bold',
        fontSize:        17,
        borderRadius:    '0.5em',
    },
    unselected: {
        backgroundColor: '#FFF',
        color:           colors.primary,
        border:          `1px solid ${ colors.primary }`,
        cursor:          'pointer',
        '&:hover': {
            backgroundColor: '#DDF',
        }
    },
    selected: {
        backgroundColor: colors.primary,
        border:          `1px solid ${ colors.primary }`,
        color:           '#FFF',
        cursor:         'default',
    },
    failureSelected: {
        backgroundColor: '#999',
        border:          `1px solid #999`,
        color:           '#FFF',
        cursor:          'default',
    },
    failure: {
        backgroundColor: '#FFF',
        border:          `1px solid #999`,
        color:           '#999',
        cursor:          'default',
    },
    disable: {
        backgroundColor: '#FFF',
        color:           colors.primary,
        border:          `1px solid ${ colors.primary }`,
        cursor:          'default',
    }
    
}))

type AnswerChoiceProps = {
    answerChoices:     string;
    correctAnswer:     string;
    success:           string;
    selectedAnswer:    number;
    setSelectedAnswer: (val: number) => void;
}

const AnswerChoice: React.FC<AnswerChoiceProps> = ({
    answerChoices,
    correctAnswer,
    success,
    selectedAnswer,
    setSelectedAnswer
}) => {

  const { classes } = useStyles();

  const [ disable, failure, greyAnswer ] = React.useMemo(() => {
      return[
         ( success !== '' ),
         ( success === 'false' ),
         ( abc.indexOf(correctAnswer.toLowerCase()) )
      ]
  },[success, correctAnswer])

  return (
    <div className = { classes.root } >
        {
            answerChoices.split('@').map((val: string, ind: number) => {
                return(
                    <div
                        key       = { `${ val }${ ind }_+` }
                        className = { clsx(classes.unit,( failure && ind === greyAnswer ? classes.failureSelected : failure ? classes.failure : disable && ind !== greyAnswer ? classes.disable : selectedAnswer === ind ? classes.selected : classes.unselected )) }
                        onClick   = { () => { if ( disable ) { return; } setSelectedAnswer(ind) }}
                        style     = {{
                            height: val.length >= 50 ? 60 : 35
                        }}
                    >
                        { val }
                    </div>
                )
            })
        }
    </div>
  );
}

export default AnswerChoice;
