import { call, take, put }          from 'redux-saga/effects';
import axios                        from 'axios';
import { SET_MY_STUDENT_CONTENT }   from 'actions/content_actions';
import { RECORD_ERROR }             from 'actions';

 const studentAppsEndpoint = 'https://www.yisumatica.org.il/api/getStudentAppsTa.php';



 const addOrigin = (str: string, arr: { [key: string]: any }) => {
   for ( let i = 0; i < arr.length; i++ ) {
         arr[i].origin = str;
         arr[i].uid    = !!arr[i].uid ? `${ arr[i].uid }` : ''
   }
   return arr;
 }


function* getMyStudentContent(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('GET_MY_STUDENT_CONTENT_SAGA');

        console.log('GET_MY_STUDENT_CONTENT_SAGA');


        const { userEmail, studentOpenApps } = input.payload;

        if ( process.env.NODE_ENV === 'development' ) {


          const payload = { myStudentContent: [] }


          yield put(SET_MY_STUDENT_CONTENT( payload ))
        
        }


        if (process.env.NODE_ENV === 'production') {

                  let myStudentContentArr = []; 

                  const myStudentContent  = yield call( axios.post, studentAppsEndpoint, { apps: studentOpenApps });

             

                 if ( !!myStudentContent && !!myStudentContent.data && !!myStudentContent.data.studentOpenApps && !!myStudentContent.data.studentOpenApps.length ) {                  
                   myStudentContentArr = yield addOrigin('myStudentContent', myStudentContent.data.studentOpenApps);
                 }


          
                if ( !!myStudentContentArr.length ) {

                    const payload = { myStudentContent: myStudentContentArr }
  
                    yield put(SET_MY_STUDENT_CONTENT( payload ))

                   

                }
                if ( myStudentContent && myStudentContent.data && !Boolean(myStudentContent.data.success) && myStudentContent.data.msg ) {
                  yield put(RECORD_ERROR({ userEmail: userEmail, err: myStudentContent.data.msg, endpoint: 'getMyStudentContent' }));
                }

        }
      
      

    }
    catch(err) {
      console.log('err at get content saga  :  '+err);
    }
    finally {}
  }
}


export default getMyStudentContent;
