import React           from 'react';
import content         from './content';
import Title           from './Title';
import { makeStyles }  from 'makeStyles';

const useStyles = makeStyles()(() => ({   
    column: {
        display:  'flex',
        flexFlow: 'column',
    },
    text: {
       flex:       0.75,
       fontWeight: 'bold',
       lineHeight: 1.5
    }
}))


type MilimProps = {
    variant:    string;
    wideScreen: boolean;
}
 
const Milim: React.FC<MilimProps> = ({
    variant,
    wideScreen
  }) => {


  const { classes } = useStyles();

  const columnStyle = React.useMemo(() => {
     return {
      paddingLeft:   wideScreen && variant !== 'zvi' ? 30 : undefined,
      paddingRight:  wideScreen && variant === 'zvi' ? 30 : undefined,
      paddingTop:    15,
      paddingBottom: 15,
     }
  },[variant, wideScreen])

  return (       
                <div 
                    className = { classes.column }
                    style     = { columnStyle }
                >         
                    {
                      wideScreen && <Title variant = { variant } wideScreen = { wideScreen }/>
                    }            
                    <div className = { classes.text }>
                      { content[`${ variant }`].text }
                    </div>
                </div>
  );
}


export default Milim;
