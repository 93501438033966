import React               from 'react';
import Iframe              from '../VideoIframe';
import { contentInfoType } from '../../types';

import { colors }          from '../../utils/colors';
import { makeStyles }      from 'makeStyles';
import { sizes } from 'utils/defaultStates';


const useStyles = makeStyles()(
  (Theme) => ({    container: {
        position:      'absolute',
       // top:           70, 
        top:           70 + sizes.tabSize,
        right:         0,
        backgroundColor: colors.brightGrey,
    },
    image: {
      width:              270,
      position:           'absolute',
      left:               '50%',
      top:                '50%',
      transform:          'translate(-50%, -50%)',
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
     // border: '1px solid #000'
    },
    description: {
      position:       'absolute',
      overflowY:      'scroll',
      overflowX:      'hidden',
      paddingLeft:    30,
      paddingRight:   30,
      left:           0,
      backgroundColor: '#FFF',
      lineHeight:     2,
      letterSpacing:  2,
      borderTop:     '1px solid #CCC',
      borderBottom:  '1px solid #CCC',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
      }
    }
}))

type ContainerProps = {
    app:                 contentInfoType;
    size:                number[];
    containerHeight:     number;
    descriptionHeight:   number;
    width:               number;
    showIframe:          boolean;
    showImage:           boolean;
    wideScreen:          boolean;
}
const Container: React.FC<ContainerProps> = ({ 
    app,
    size,
    containerHeight,
    descriptionHeight,
    width,
    showIframe,
    showImage,
    wideScreen,
}) => {

  const { classes } = useStyles();

 // https://www.youtube.com/embed/wMxRK-pqj6k?rel=0
  //console.log(' app.youtube_eng  :  ', app.youtube_eng)
  
  return (
   
    <div 
       className = { classes.container }
       style     = {{
         width:  width,
         height: containerHeight,
       }}
    >
          {
            showIframe
            &&
            <Iframe 
               width  = { width }
               height = { containerHeight }
               src    = { `https://www.youtube.com/embed/${ !!app.youtube_eng ? app.youtube_eng : !!app.youtube ? app.youtube : '' }?rel=0` }
            />
          }
          {
            !showIframe && showImage
            &&
            <div
              className = { classes.image }
              style     = {{
                height:          Math.min( 200, size[1] - 300 ),
                backgroundImage: `url(${ `https://www.yisumatica.org.il/flash-upload/js/img/apps3/${ app.img_id && app.img_id.length ? app.img_id : app.lesson_id ? app.lesson_id : app.lessontype ? app.lessontype : '' }.JPG` })`
              }}
            />
          }
          {
            !wideScreen && app.description_eng && app.description_eng.length
            &&
            <div
               className = { classes.description }
               style     = {{
                  top:    showIframe ? containerHeight : 0,
                  width:  width - 60,
                  height: descriptionHeight
               }}
             >
                { app.description_eng }
             </div>
          } 
    </div>
  );
}




export default Container;

