import React          from 'react';
import { colors }     from '../../utils/colors';
import { makeStyles } from 'makeStyles';

var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({      container: {
        height:         50,
        width:          '80%',
        marginLeft:     '10%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'space-evenly',
        flexFlow:       'column',
      },
      text_field: {
        height:          '100%',
        marginLeft:      10,
        minHeight:       50,
        width:           '100%',
        fontSize:        19,
        fontFamily:      'Raleway',
        cursor:          'pointer',
        border:          0,
        backgroundColor: 'transparent',
        '&:focus': {
           outline: 0,
           cursor:  'auto',
        }
      },
      dark: {
        color: colors.menuText,
        '&::placeholder': {
          color: colors.menuText,
        }
      },
      bright:{
        color: colors.hoverText,
        '&::placeholder': {
          color: colors.hoverText,
        }
      },
      effectContainer: {
          width:            '100%',
          height:           1,
          zIndex:           1,
          display:         'flex',
          alignItems:      'center',
          justifyContent:  'center',
        },
        effect: {
          borderBottom: `2px solid ${ colors.blue }`,
          transition:   '0.3s'
        },
}))

type TCtype = {
  error:        string;
  teacherEmail: string;
  teacherCode:  string;
}

type ButtonInputProps = {
  teacherCredentials:    TCtype;
  setTeacherCredentials: (val: TCtype) => void;
  handleSubmit:          (payload: TCtype) => void;
}

const ButtonInput: React.FC<ButtonInputProps> = ({
    teacherCredentials,
    setTeacherCredentials,
    handleSubmit
}) => {

  const { classes } = useStyles();

  const [focus, setFocus]               = React.useState(0);
  const [hover, setHover]               = React.useState(false)
  const [initialFocus, setInitialFocus] = React.useState(false)

  const teacherEmailRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
  
      if (teacherEmailRef && teacherEmailRef.current && !initialFocus) {

          teacherEmailRef.current.focus();
          setFocus(1);
          setInitialFocus(true)
        }
          
  },[teacherEmailRef, initialFocus])


  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();
    const target = e.target as HTMLInputElement;

    if (target.id === 'teacherEmail'){ 
      setTeacherCredentials({ 
        ...teacherCredentials,
        teacherEmail: target.value 
      }) 
    }             
       
  }

  const on_focus = () => { 
    setFocus(1) 
  }

  const on_blur = () => { 
      setFocus(0) 
  }

  const containerStyle = React.useMemo(() => {
    return{
      borderBottom: `1px solid ${ (hover && !focus) ? '#DDD' : focus ? 'transparent' : '#666' }`,
    }
  },[focus, hover])

  return (
    <div 
       className     = { classes.container }
       onMouseEnter  = { () => { setHover(true) }}
       onMouseLeave  = { () => { setHover(false) }}
       style         = { containerStyle }
    >
        <input
                className    = { clsx(classes.text_field, ((hover && !focus) ? classes.bright : classes.dark)) }
                type         = "text"
                id           = "teacherEmail"
                ref          = { teacherEmailRef }
                value        = { teacherCredentials.teacherEmail }
                placeholder  = { 'Enter Your Teachers Email' }
                onChange     = { changeInputValue }
                onFocus      = { on_focus }
                onBlur       = { on_blur }
                autoComplete = "off"
        />
        <div className = { classes.effectContainer } >
            <div
               className = { classes.effect }
               style     = {{
                 width: focus ? '100%' : 0
              }}
            />
         </div>
    </div>
  );
}




export default ButtonInput;



