
export const words: { [key: string]: { [key: string]: string; }} = {
  
  "Tutorials": {
    "heb": `Tutorials`,
    "eng": "Tutorials",
    "ara": `Tutorials` 
  },
  "emptyLessonsResult" : {
    "heb": `לא נמצאו שיעורים שלך`,
    "eng": "Integers",
    "ara": `לא נמצאו שיעורים שלך` 
  },
  "emptyFavoritesResult" : {
    "heb": `לא נמצאו מועדפים בקטגוריה שבחרת`,
    "eng": "Integers",
    "ara": `לא נמצאו מועדפים בקטגוריה שבחרת` 
  },
  "emptyGradeResult" : {
    "heb": `לא נמצאו תכנים בקטגוריה שבחרת עבור כיתה`,
    "eng": "Integers",
    "ara": `לא נמצאו תכנים בקטגוריה שבחרת עבור כיתה` 
  },
  "emptySearchResult" : {
    "heb": `לא נמצאו תכנים הכוללים את מילות החיפוש:`,
    "eng": "no results found for the following search words:",
    "ara": `לא נמצאו תכנים הכוללים את מילות החיפוש:` 
  },
  "emptyGroup" : {
    "heb": "לא נמצאו תכנים בקבוצה",
    "eng": "Integers",
    "ara": "לא נמצאו תכנים בקבוצה",
  },
  "fatherApp": {
    "heb": " יישומון האב",
    "eng": "Integers",
    "ara": " للتطبيق الأصل"
  },
  "myLessons": {
    "heb": "השיעורים שלי",
    "eng": "Integers",
    "ara": "دروسي الخاصة"
  },
  "continueToApp": {
    "heb": "ליישומון",
    "eng": "Integers",
    "ara": "للتَّطبيق"
  },
  "continueToLesson": {
    "heb": "לשיעור",
    "eng": "Integers",
    "ara": "للدّرس"
  },
  "groupLessons": {
    "heb": "שיעורי הקבוצה",
    "eng": "Integers",
    "ara": "دروس المجموعه"
  },
  "groupLessonsLink": {
    "heb": "קישור ישיר לשיעורי הקבוצה",
    "eng": "Integers",
    "ara": "رابط خاص لدرس المجموعه"
  },
  "groups": {
    "heb": "קבוצות",
    "eng": "Integers",
    "ara": "المجموعات"
  },
  "appDescription": {
    "heb": "תאור היישומון",
    "eng": "Integers",
    "ara": "תאור היישומון"
  },
  "updating": {
    "heb": "מעדכן",
    "eng": "Integers",
    "ara": "מעדכן"
  },
  "getLostGuest": {
    "heb": "לאורחים אין גישה לתכני המורים",
    "eng": "Guests do not have access to teacher content",
    "ara": "לאורחים אין גישה לתכני המורים"
  },
  "confirmSignOut": {
    "heb": "התנתק מיישומטיקה?",
    "eng": "Sign Out?",
    "ara": "התנתק מיישומטיקה?"
  },
  "teacherUrlModalTitle": {
    "heb": "קישור ייחודי עבורכם הועתק. הדביקו אותו (CTRL V) במייל, בווטסאפ, בצ'אט של הזום וכו', שילחו אותו לתלמידיכם ותוכלו לראות את תוצאותיהם. הקישור פעיל לשבועיים",
    "eng": "A unique link has been copied to your clipboard. Paste it (CTRL V) and send it via email, WhatsApp, Zoom chat, etc. to your students to receive their results. The link will be active for two weeks.",
    "ara": "تم نسخ الرابط الخاص بكم. الصقه ( ctrl v) بالبريد الالكتروني،بالواتس اب، رسائل الزوم||ارساله للطلاب ويمكنكم مشاهدة نتائجهم. الرابط يعمل لاسبوعين"
  },
  "groupUrlModalTitle1": {
    "heb": "הקישור הועתק. ניתן להדביק אותו (CTRL V) במייל, בווטסאפ וכו׳ ולשלוח לתלמידים. קישור זה יפתח מייד את המסך הזה עם השיעורים של קבוצה זו.",
    "eng": "The link has been copied to your clipboard. Paste it (CTRL V) and send it to your students via email, WhatsApp, etc. This link will open a screen containing the lessons of this group.",
    "ara": "הקישור הועתק. ניתן להדביק אותו (CTRL V) במייל, בווטסאפ וכו׳ ולשלוח לתלמידים. קישור זה יפתח מייד את המסך הזה עם השיעורים של קבוצה זו."
  },
  "showResults": {
    "heb": "התלמיד יראה ציון של X או V על התשובות לאחר ההגשה",
    "eng": "The student will see a score of X or V on his/her answers after submission",
    "ara": "الطالب يرى إشارة V او إشارة X على الإجابات بعد التسليم"
  },
  "showScore": {
    "heb": "התלמיד יראה את הציון שלו בעת הגשת המבדק (אם הציון מעל 70)",
    "eng": "The student will see his/her grade after submitting the Test (if the grade is above 70)",
    "ara": "الطالب يشاهد علامتة عندما يسلم الاختبار( بشرط اذا كان العلامة اعلى من 70) "
  },
  "createUrl": {
    "heb": "צור קישור",
    "eng": "Integers",
    "ara": "צור קישור"
  },
  "guest": {
    "heb": "אורח",
    "eng": "Integers",
    "ara": "אורח"
  },
  "viewScores": {
    "heb": "נתוני התלמידים",
    "eng": "Integers",
    "ara": "נתוני התלמידים"
  },
  "שלמים": {
    "heb": "שלמים",
    "eng": "Integers",
    "ara": "صِحاح"
  },
  "גיאומטרייה": {
    "heb": "גיאומטרייה",
    "eng": "Geometry",
    "ara": "هندسة"
  },
  "שבר": {
    "heb": "שבר",
    "eng": "Fractions",
    "ara": "كسر"
  },
  "נושאים נוספים": {
    "heb": "נושאים נוספים",
    "eng": "Additional Topics",
    "ara": "مواضيع أخرى"
  },
  "ציר": {
    "heb": "ציר",
    "eng": "Axes",
    "ara": "محور"
  },
  "גרפים": {
    "heb": "גרפים",
    "eng": "Graphs",
    "ara": "رسومات"
  },
  "אלגברה": {
    "heb": "אלגברה",
    "eng": "Algebra",
    "ara": "الجبر"
  },
  "הסתברות": {
    "heb": "הסתברות",
    "eng": "Probability",
    "ara": "الاحتمال"
  },
  "אחר": {
    "heb": "אחר",
    "eng": "Additional Topics",
    "ara": "آخر"
  },
  "מספרים עשרוניים": {
    "heb": "מספרים עשרוניים",
    "eng": "Decimals",
    "ara": "أعداد عشرية"
  },
  "חיפוש": {
    "heb": "חיפוש",
    "eng": "Search",
    "ara": "חיפוש"
  },
  "כיתות": {
    "heb": "כיתות",
    "eng": "Grades",
    "ara": "כיתות"
  },
  "המבנה העשרוני": {
    "heb": "המבנה העשרוני",
    "eng": "Decimal System",
    "ara": "المبنى العشري"
  },
  "חוק הפילוג": {
    "heb": "חוק הפילוג",
    "eng": "Distribution Law",
    "ara": "قانون التوزيع"
  },
  "פעולות חשבון במאונך": {
    "heb": "פעולות חשבון במאונך",
    "eng": "Vertical Arithmetic",
    "ara": "العمليات الحسابية عاموديا"
  },
  "תרגולים בפעולות החשבון": {
    "heb": "תרגולים בפעולות החשבון",
    "eng": "Arithmetic Challenges",
    "ara": "تدريب على العمليات الحسابية"
  },
  "אחוזים": {
    "heb": "אחוזים",
    "eng": "Percentages",
    "ara": "نسبة مئوية"
  },
  "חילוק בשלמים": {
    "heb": "חילוק בשלמים",
    "eng": "Integer Division",
    "ara": "القسمة على صحيح"
  },
  "מגוון נושאים במספרים שלמים": {
    "heb": "מגוון נושאים במספרים שלמים",
  //  "eng": "Various Integer Challenges",
    "eng": "Other Integer topics",
    "ara": "مواضيع متنوّعة بالأعداد الصحيحة"
  },
  "שברים במודלים שונים": {
    "heb": "שברים במודלים שונים",
    "eng": "Fractions In Various Models",
    "ara": "كسور بنماذج مختلفة"
  },
  "פעולות חשבון בשברים": {
    "heb": "פעולות חשבון בשברים",
    "eng": "Arithmetic With Fractions",
    "ara": "عمليات حسابية على الكسور"
  },
  "משחקים בשברים": {
    "heb": "משחקים בשברים",
    "eng": "Fraction Games",
    "ara": "ألعاب في الكسور"
  },
  "נושאים נוספים בשברים": {
    "heb": "נושאים נוספים בשברים",
   // "eng": "Various Fraction Challenges",
    "eng": "Other Fraction Topics",
    "ara": "مواضيع أخرى في الكسور"
  },
  "קנה מידה": {
    "heb": "קנה מידה",
    "eng": "Scale",
    "ara": "مقياس"
  },
  "תכונות מרובעים": {
    "heb": "תכונות מרובעים",
    "eng": "Quadrilateral Attributes",
    "ara": "صفات الأشكال الرباعية"
  },
  "תלת ממד": {
    "heb": "תלת ממד",
    "eng": "3D",
    "ara": "ثلاثي الأبعاد"
  },
  "זוויות": {
    "heb": "זוויות",
    "eng": "Angles",
    "ara": "زوايا"
  },
  "היקפים": {
    "heb": "היקפים",
    "eng": "Perimeters",
    "ara": "محيطات"
  },
  "שטח משולש": {
    "heb": "שטח משולש",
    "eng": "Triangle Area",
    "ara": "مساحة المثلث"
  },
  "מעגל": {
    "heb": "מעגל",
    "eng": "Circles",
    "ara": "دائرة"
  },
  "יישומונים כלליים לאיורים גיאומטריים": {
    "heb": "יישומונים כלליים לאיורים גיאומטריים",
   // "eng": "Geometric Illustration Apps",
    "eng": "General Geometric visualisations",
    "ara": "تطبيقات عامّة لأشكال هندسيّة"
  },
  "זיהוי צורות מוסתרות": {
    "heb": "זיהוי צורות מוסתרות",
    "eng": "Identifying Hidden Shapes",
    "ara": "تحديد أشكال مخفيّة"
  },
  "מגוון נושאים בגיאומטרייה": {
    "heb": "מגוון נושאים בגיאומטרייה",
  //  "eng": "Various Geometry Challenges",
    "eng": "Other geometrical topics",
    "ara": "مواضيع متنوّعة في الهندسة"
  },
  "mivdakDescription": {
    "heb": "תאור המבדק",
    "eng": "favorites",
    "ara": "תאור המבדק"
  },
  "favorites": {
    "heb": "מועדפים",
    "eng": "Favorites",
    "ara": "مُفضّلة"
  },
  "teacherLesson": {
    "heb": "שיעור מורים",
    "eng": "lessons",
    "ara": "שיעור מורים"
  },
  "mivdak": {
    "heb": "מבדק",
    "eng": "lessons",
    "ara": "מבדק"
  },
  "lessonDescription": {
    "heb": "תאור השיעור",
    "eng": "lessons",
    "ara": "תאור השיעור"
  },
  "viewVideo": {
    "heb": "סרטון ועוד",
    "eng": "lessons",
    "ara": "סרטון ועוד"
  },
  "allTeacherContent": {
    "heb": "כל תכני המורים",
    "eng": "All Teacher Content",
    "ara": "כל תכני המורים"
  },
  "mivdakim": {
    "heb": "מבדקים",
    "eng": "Tests",
    "ara": "اختبارات"
  },
  "teacherLessons": {
    "heb": "שיעורי מורים",
    "eng": "Teacher Lessons",
    "ara": "دروس المعلمين"
  },
  "lesson": {
    "heb": "שיעור",
    "eng": "lessons",
    "ara": "שיעור"
  },
  "bigContentArr": {
    "heb": "כל התכנים",
    "eng": "All Content",
    "ara": "כל התכנים"
  },
  "puzzles": {
    "heb": "פאזלים",
    "eng": "Puzzles",
    "ara": "بازل"
  },
  "puzzle": {
    "heb": "פאזל",
    "eng": "content",
    "ara": "פאזל"
  },
  "shapePuzzles": {
    "heb": "פאזל הנחת הצורה",
    "eng": "content",
    "ara": "وضعية شكل البازل "
  },
  "interaction": {
    "heb": "שיעורים מיוחדים",
    "eng": "Special Lessons",
    "ara": "שיעורים מיוחדים"
  },
  "interact": {
    "heb": "שיעור מיוחד",
    "eng": "content",
    "ara": "שיעור מיוחד"
  },
  "apps": {
    "heb": "יישומונים",
    "eng": "Apps By Topic",
    "ara": "יישומונים"
  },
  "app": {
    "heb": "יישומון",
    "eng": "content",
    "ara": "تطبيق"
  },
  "lessons": {
    "heb": "שיעורים",
    "eng": "Lesson Samples",
    "ara": "دروس"
  },
  "Integers": {
    "heb": "שלמים",
    "eng": "integers",
    "ara": "صِحاح"
  },
  "Geometry": {
    "heb": "גיאומטרייה",
    "eng": "geometry",
    "ara": "هندسة"
  },
  "Fractions": {
    "heb": "שבר",
    "eng": "fractions",
    "ara": "كسر"
  },
  "Additional Topics": {
    "heb": "נושאים נוספים",
    "eng": "Additional Topics",
    "ara": "مواضيع أخرى"
  },
  "content": {
    "heb": "תכנים",
    "eng": "Content",
    "ara": "תכנים"
  },
  "teacherContent": {
    "heb": "תכני מורים",
    "eng": "Teacher Content",
    "ara": "תכני מורים"
  },
  "Decimals": {
    "heb": "מספרים עשרוניים",
    "eng": "Decimals",
    "ara": "أعداد عشرية"
  },
  "Search": {
    "heb": "חיפוש",
    "eng": "Search",
    "ara": "חיפוש"
  },
  "Grades": {
    "heb": "כיתות",
    "eng": "Grades",
    "ara": "כיתות"
  },
  "Decimal System": {
    "heb": "המבנה העשרוני",
    "eng": "Decimal System",
    "ara": "المبنى العشري"
  },
  "Distribution Law": {
    "heb": "חוק הפילוג",
    "eng": "Distribution Law",
    "ara": "قانون التوزيع"
  },
  "Vertical Arithmetic": {
    "heb": "פעולות חשבון במאונך",
    "eng": "Vertical Arithmetic",
    "ara": "العمليات الحسابية عاموديا"
  },
  "Arithmetic Challenges": {
    "heb": "תרגולים בפעולות החשבון",
    "eng": "Arithmetic Challenges",
    "ara": "تدريب على العمليات الحسابية"
  },
  "Percentages": {
    "heb": "אחוזים",
    "eng": "Percentages",
    "ara": "نسبة مئوية"
  },
  "Integer Division": {
    "heb": "חילוק בשלמים",
    "eng": "Integer Division",
    "ara": "القسمة على صحيح"
  },
  "Various Integer Challenges": {
    "heb": "מגוון נושאים במספרים שלמים",
  //  "eng": "Various Integer Challenges",
    "eng": "Other Integer topics",
    "ara": "مواضيع متنوّعة بالأعداد الصحيحة"
  },
  "Fractions In Various Models": {
    "heb": "שברים במודלים שונים",
    "eng": "Fractions In Various Models",
    "ara": "كسور بنماذج مختلفة"
  },
  "Arithmetic With Fractions": {
    "heb": "פעולות חשבון בשברים",
    "eng": "Arithmetic With Fractions",
    "ara": "عمليات حسابية على الكسور"
  },
  "Fraction Games": {
    "heb": "משחקים בשברים",
    "eng": "Fraction Games",
    "ara": "ألعاب في الكسور"
  },
  "Various Fraction Challenges": {
    "heb": "נושאים נוספים בשברים",
   // "eng": "Various Fraction Challenges",
    "eng": "Other Fraction Topics",
    "ara": "مواضيع أخرى في الكسور"
  },
  "Scale": {
    "heb": "קנה מידה",
    "eng": "Scale",
    "ara": "مقياس"
  },
  "Quadrilateral Attributes": {
    "heb": "תכונות מרובעים",
    "eng": "Quadrilateral Attributes",
    "ara": "صفات الأشكال الرباعية"
  },
  "3D": {
    "heb": "תלת ממד",
    "eng": "3D",
    "ara": "ثلاثي الأبعاد"
  },
  "Angles": {
    "heb": "זוויות",
    "eng": "Angles",
    "ara": "زوايا"
  },
  "Perimeters": {
    "heb": "היקפים",
    "eng": "Perimeters",
    "ara": "محيطات"
  },
  "Triangle Area": {
    "heb": "שטח משולש",
    "eng": "Triangle Area",
    "ara": "مساحة المثلث"
  },
  "Circles": {
    "heb": "מעגל",
    "eng": "Circles",
    "ara": "دائرة"
  },
  "Geometric Illustration Apps": {
    "heb": "יישומונים כלליים לאיורים גיאומטריים",
  //  "eng": "Geometric Illustration Apps",
    "eng": "General Geometric visualisations",
    "ara": "تطبيقات عامّة لأشكال هندسيّة"
  },
  "Identifying Hidden Shapes": {
    "heb": "זיהוי צורות מוסתרות",
    "eng": "Identifying Hidden Shapes",
    "ara": "تحديد أشكال مخفيّة"
  },
  "Various Geometry Challenges": {
    "heb": "מגוון נושאים בגיאומטרייה",
  //  "eng": "Various Geometry Challenges",
    "eng": "Other geometrical topics",
    "ara": "مواضيع متنوّعة في الهندسة"
  },
  "Axes": {
    "heb": "ציר",
    "eng": "Axes",
    "ara": "محور"
  },
  "Graphs": {
    "heb": "גרפים",
    "eng": "Graphs",
    "ara": "رسومات"
  },
  "Algebra": {
    "heb": "אלגברה",
    "eng": "Algebra",
    "ara": "الجبر"
  },
  "Probability": {
    "heb": "הסתברות",
    "eng": "Probability",
    "ara": "الاحتمال"
  },
  "Other": {
    "heb": "אחר",
    "eng": "Other",
    "ara": "آخر"
  },
  "pisa_questions": {
    "heb": "שאלות ברוח פיזה",
    "eng": "",
    "ara": "تَمارِين بيزا PISA "
  },
  "classes": {
    "heb": "פרקי רענון",
    "eng": "",
    "ara": "فصول تحديثية"
  },
  "enterTeacherCode": {
    "heb": "הזן קוד מורה",
    "eng": "",
    "ara": "ادخل رمز المعلم"
  },
  "changeTeacherCode": {
    "heb": "שנה קוד מורה",
    "eng": "",
    "ara": "تغير رمز المعلم"
  },
  "teacherCode": {
    "heb": "קוד מורה",
    "eng": "",
    "ara": "رمز المعلم"
  },
  "myTeacherCode": {
    "heb": "קוד המורה שלך הוא",
    "eng": "",
    "ara": "رمز معلمك الخاص هو"
  },
  "grades": {
    "heb": "כיתות",
    "eng": "Grades",
    "ara": "כיתות"
  },
  "ok": {
    "heb": "אישור",
    "eng": "",
    "ara": "موافق"
  },
  "emptyCode": {
    "heb": "חובה להזין קוד",
    "eng": "",
    "ara": "ملزم  بادخال الرمز"
  },
  "studentResults": {
    "heb": "תשובות תלמידים",
    "eng": "",
    "ara": "إجابات التلاميذ"
  },
  "submissionNum": {
    "heb": "מספר המגישים",
    "eng": "",
    "ara": "عدد المرسلين"
  },
  "showStudentInfo": {
    "heb": "הצגת נתוני תלמידים",
    "eng": "",
    "ara": "عرض نتائج الطلاب"
  },
  "signOut": {
    "heb": "התנתק",
    "eng": "",
    "ara": "تسجيل خروج"
  },
  "inputIsNaN": {
    "heb": "חובה להזין ספרות בלבד",
    "eng": "",
    "ara": "ملزم  بكتابة ارقام لوحدها"
  },
  "loading": {
    "heb": "אנא המתן",
    "eng": "",
    "ara": "انتظار"
  },
  "signIn": {
    "heb": "לחץ להתחברות",
    "eng": "",
    "ara": "اضغط للاتصال"
  },
  "continue": {
    "heb": "המשך",
    "eng": "",
    "ara": "استمر"
  },
  "return": {
    "heb": "חזור",
    "eng": "",
    "ara": "חזור"
  },
  "toFatherapp": {
    "heb": "ליישומון האב",
    "eng": "",
    "ara": "للتطبيق الأصل"
  },
  "toLessons": {
    "heb": "לשיעורים",
    "eng": "",
    "ara": "לשיעורים"
  },
  "about": {
    "heb": "צור קשר",
    "eng": "",
    "ara": "اتصل بنا"
  },
  "greeting": {
    "heb": "ברוך שובך ליישומטיקה",
    "eng": "",
    "ara": "أهلاً برجوعك لموقع يِسوماتيكا"
  },
  "allGrades": {
    "heb": "כל הכיתות",
    "eng": "",
    "ara": "جميع الصفوف"
  },
  "grade1": {
    "heb": "א",
    "eng": "",
    "ara": "الأول"
  },
  "grade2": {
    "heb": "ב",
    "eng": "",
    "ara": "الثّاني"
  },
  "grade3": {
    "heb": "ג",
    "eng": "",
    "ara": "الثّالث"
  },
  "grade4": {
    "heb": "ד",
    "eng": "",
    "ara": "الرّابع"
  },
  "grade5": {
    "heb": "ה",
    "eng": "",
    "ara": "الخامس"
  },
  "grade6": {
    "heb": "ו",
    "eng": "",
    "ara": "السّادس"
  },
  "grade7": {
    "heb": "ז",
    "eng": "",
    "ara": "السّابع"
  },
  "gali": {
    "heb": "גלי שמעוני",
    "eng": "",
    "ara": "גלי שמעוני"
  },
  "zvi": {
    "heb": "צבי שלם",
    "eng": "",
    "ara": "צבי שלם",
  },
  "galiTitle": {
    "heb": "ראש תחום מתמטיקה",
    "eng": "",
    "ara": "ראש תחום מתמטיקה"
  },
  "zviTitle": {
    "heb": "מפתח פדגוגי וטכנולוגי",
    "eng": "",
    "ara": "מפתח פדגוגי וטכנולוגי"
  },
  "lessonsWarning": {
    "heb": "השיעורים כאן באחריות המורים שהעלו אותם",
    "eng": "The following content was created by teachers",
    "ara": "השיעורים כאן באחריות המורים שהעלו אותם"
  },
  "All Topics": {
    "heb": "All Topics",
    "eng": "All Topics",
    "ara": "All Topics"
  }
}