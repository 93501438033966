import React           from 'react';
import { useNavigate } from "react-router-dom";

type ButtonType = {
  props: { [key: string]: any; };
}

const Button: React.FC<ButtonType> = ({ props }) => {

const navigate = useNavigate();

const onClick = () => {

   if (props['txt'] === 'Find Lessons In Group') {
     navigate('../', { replace: true })
   }
   props['func']();
}

return (
  <div
    className = { props['class'] }
    onClick   = { onClick }
  >
     { props['txt'] }
  </div>
);
}


export default Button;


