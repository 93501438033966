import { call, take, put }  from 'redux-saga/effects';
import axios                from 'axios';
import { 
    SET_STUDENT_OPEN_APPS,
    RECORD_ERROR 
}                           from '../actions';

const endpoint = 'https://iseemath.co/api/setStudentOpenApps.php';


function* setStudentOpenApps(): Generator<any,any,any> {
  while(true) {
    try {

      //console.log('setStudentOpenApps')


        const input = yield take('SET_STUDENT_OPEN_APPS_SAGA');
        
        const { userEmail, arr, myStudentContent } = input.payload;

        console.log('input.payload  :  ',input.payload)

        const payload = {
          arr:              JSON.parse(arr),
          myStudentContent: myStudentContent
        }

        yield put(SET_STUDENT_OPEN_APPS( payload ))

        if (process.env.NODE_ENV === 'production') {

            const obj = {
              userEmail: userEmail,
              content:   arr,
            }

            const res = yield call( axios.post, endpoint, obj);

            console.log('res  :  ',res)

            if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
              yield put(RECORD_ERROR({ userEmail: userEmail, err: res.data.msg, endpoint: 'set_student_content' }));
            } 
        }
    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default setStudentOpenApps;
