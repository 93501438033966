import React                from 'react';
import { connect }          from 'react-redux';
import { reducerType }      from 'reducer';
import { 
  useNavigate, 
  useLocation 
}                           from 'react-router-dom';
import MenuButtonIcon       from './MenuButtonIcon';
import AudioButton          from './AudioButton';
import menuButtonClick      from './functions/menu_button_click';

import { colors }           from 'utils/colors';
import { makeStyles }       from 'makeStyles';
import { 
  audioStateType, 
  componentsStateType 
}                           from 'reducer/types';


const names: { [key:string]: any; }  = {
  bonus:             { text: 'Student Puzzles',     audio: 'https://iseemath.co/audio/Puzzles.wav' },
  favorites:         { text: 'Favorites',           audio: 'https://iseemath.co/audio/Favorites.wav' },
  myStudentsContent: { text: 'My Students Content', audio: 'https://iseemath.co/audio/My_Student_Content.wav' },
  myTeacherContent:  { text: 'My Teachers Lessons', audio: '' },
  userLessons:       { text: 'My Lessons',          audio: 'https://iseemath.co/audio/My_Lessons.wav' },
  groupLessons:      { text: 'Groups',              audio: 'https://iseemath.co/audio/Groups.wav' },
  studentApps:       { text: 'Apps',                audio: '' },
  apps:              { text: 'Class Apps',          audio: 'https://iseemath.co/audio/Apps.m4a' },
  games:             { text: 'Class Games',         audio: 'https://iseemath.co/audio/Games.wav' },
  lessons:           { text: 'Sample Lessons',      audio: 'https://iseemath.co/audio/Sample_Lessons.wav' },
  puzzles:           { text: 'Student Puzzles',     audio: '' },
}

const useStyles = makeStyles()(
  (Theme) => ({      root: {
        textAlign:      'left',
        color:          colors.darkText,
        textDecoration: 'none',
        borderRadius:   '0.5em',
        display:        'flex',
        alignItems:     'center',
        '&:hover': {
          backgroundColor: colors.brightGrey3,
        }
      },
      selector: {
        minHeight:      50,
        marginLeft:     0,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        cursor:         'pointer',
        width:          '100%'
      },
      bigButton: {
        textAlign:      'center',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        fontSize:       17,
        width:          '100%',
        height:         50,
        borderRadius:   '0.5em',
        border:         '1px solid #BBB',
      },
      text: {
        height:         '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'flex-start',
        marginLeft:     10,
        width:          '100%',
        fontSize:       17,
        fontFamily:     'Raleway',
        border:         0,
        color:          colors.menuText,
      },
      iconContainer: {
        width:           10,
        height:          50,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
      },
      icon: {
          fontSize:   15,
          color:      colors.menuText,
      },
      audioContainer: {
        position:  'absolute',
        textAlign: 'right',
      },
      audio: {
        fontSize:    17,
        marginRight: 5,
        cursor:      'pointer',
        '&:hover': {
          color: colors.primary
        }
      },
      dark: {
      },
      bright:{
      }
}))

type MenuButtonProps = {
  AudioRef:        any;
  variant:         string;
  menuSize:        number;
  wideScreen:      boolean;
  disable:         boolean;
  bigButton:       boolean;
  studentLoggedIn: boolean;
  componentsState: componentsStateType;
  audioState:      audioStateType;
  setOpenItem:     (val: string) => void;
  handleOpenModal: (val: string) => void;
  handleMenuClick: (variant: string, val: string, disabled: boolean) => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  AudioRef,
  variant,
  menuSize,
  disable,
  bigButton,
  studentLoggedIn,
  wideScreen,
  componentsState,
  audioState,
  setOpenItem,
  handleOpenModal,
  handleMenuClick
}) => {

  const { classes } = useStyles();
  const navigate    = useNavigate();
  const location    = useLocation();
  


  const [hover, setHover] = React.useState(false)

  


  const selected = React.useMemo(() => {
     return componentsState.selectedContent === variant;
  },[componentsState.selectedContent, variant])


  const showAudio = React.useMemo(() => {
    return !studentLoggedIn && !!names[`${ variant }`] && !!names[`${ variant }`].audio.length
  },[studentLoggedIn, variant])


  const playState = React.useMemo(() => {
    if ( audioState.audioSrc === names[`${ variant }`].audio && !audioState.pauseAudio ) {
        return 'pause'
    }
    return 'play'
    
   },[variant, audioState])

   const onClick = () => { 
    if ( playState === 'pause' ) {
      return
    }
    menuButtonClick(variant, disable, navigate, location, names, handleOpenModal, setOpenItem, handleMenuClick)
  }

  const [rootStyle, elStyle, audioContainerStyle] = React.useMemo(() => {

    const fontSize = wideScreen ? undefined : 14;

    return [
        { 
           width:     menuSize - 40,
           marginTop: bigButton ? 10 : 5,
           minHeight: bigButton ? 51 : 40,
           maxHeight: bigButton ? 51 : 40,
        },
        { 
          color:      disable ? colors.brightText : selected ? colors.blueText : undefined,
          border:     bigButton && selected ? `1px solid ${ colors.primary }` : undefined,
          fontSize:   fontSize,
          fontWeight: selected ? 'bold' as 'bold' : undefined
        },
        {
          width: menuSize - 40,
        }
    ]
  },[menuSize, selected, disable, wideScreen, bigButton/* , hover */])



  return (
    <div
       className    = { classes.root }
       style        = { rootStyle }
       onClick      = { onClick }
       onMouseEnter = { () => { setHover(true) }}
       onMouseLeave = { () => { setHover(false) }}
    >
        <div className = { classes.selector }>
            {
              !bigButton &&
              <MenuButtonIcon
                 variant         = { variant }
                 disable         = { disable }
                 hover           = { hover }
                 wideScreen      = { wideScreen }
                 componentsState = { componentsState }
              />
            }
            <div 
              className = { bigButton ? classes.bigButton : classes.text }
              style     = { elStyle }
            >
               { names[`${ variant }`].text }
               {
                 bigButton && showAudio &&
                 <div 
                    className = { classes.audioContainer }
                    style     = { audioContainerStyle }
                 >
                   {
                      <AudioButton 
                         AudioRef  = { AudioRef }
                         playState = {playState }
                         selected  = { selected }
                         disable   = { disable }
                      />
                   }
                 </div>
               }
            </div>
         </div>
         {
            !bigButton && showAudio &&
            <AudioButton 
               AudioRef  = { AudioRef }
               playState = {playState }
               selected  = { selected }
               disable   = { disable }
            />
         }
    </div>
  );
}





const mapStateToProps = (reducer: reducerType) => {
  return {
    audioState:      reducer.audio,
    componentsState: reducer.components,
    studentLoggedIn: reducer.user.studentLoginState.loginState === 'success'
  }};
/* 
const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
}); */

export default connect(mapStateToProps/* , mapDispatchToProps */)(MenuButton);










