

import React                 from "react";
import { PayPalButtons }     from "@paypal/react-paypal-js";
import { colors }            from 'utils/colors';
import setPlan               from "functions/payments/set_plan";
import { makeStyles }        from 'makeStyles';
/* import { 
  defaultData, 
  UKData, 
  //sandBoxData, 
  //sandBoxOptions
}                                   from "utils/payment_plans"; */


const planTypes = {
  'Annually' : ['I See Math Annual Subscription',  '$131.40 per year'],
  'Monthly'  : ['I See Math Monthly Subscription', '$12.95 per month'],
  'testPlan' : ['I See Math Annual Subscription',  '$0.5 per year'],
  'AnnuallySandBox' : ['I See Math Annual Subscription',  '$131.40 per year'],
  'MonthlySandBox'  : ['I See Math Monthly Subscription', '$12.95 per month'],
  'testPlanSandBox' : ['I See Math Annual Subscription',  '$0.5 per year']
};

const UKPlanTypes = {
  'Annually' : ['I See Math Annual Subscription',  '£107.40 per year'],
  'Monthly'  : ['I See Math Monthly Subscription', '£9.95 per month'],
  'testPlan' : ['I See Math Annual Subscription',  '£0.5 per year'],
  'AnnuallySandBox' : ['I See Math Annual Subscription',  '£107.40 per year'],
  'MonthlySandBox'  : ['I See Math Monthly Subscription', '£9.95 per month'],
  'testPlanSandBox' : ['I See Math Annual Subscription',  '$0.5 per year']
};

const testPlanTypes = {
  'Annually' : ['I See Math Annual Subscription',  '£0.5 per year'],
  'Monthly'  : ['I See Math Monthly Subscription', '£9.95 per month'],
  'testPlan' : ['I See Math Annual Subscription',  '£0.5 per year'],
  'AnnuallySandBox' : ['I See Math Annual Subscription',  '£107.40 per year'],
  'MonthlySandBox'  : ['I See Math Monthly Subscription', '£9.95 per month'],
  'testPlanSandBox' : ['I See Math Annual Subscription',  '$0.5 per year']
};

const useStyles = makeStyles()(
  (Theme) => ({    root : {
      position:        'fixed',
      left:            0,
      top:             0,
      height:          '100vh',
      width:           '100vw',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'rgba(0,0,0,0.3)',
      transition:      'opacity 0.5s',
      transform:       'all 0.4s',
      zIndex:          2
  },
  modal: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    backgroundColor: '#FFF',
    border:          '1px solid #DDD',
    borderRadius:    '0.25em',
    boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)'
  },
  container: {
    width:           '100%',
    flex:            1,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'space-between',
    flexFlow:        'column',
  },
   title: {
    paddingTop:      100,
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    fontSize:       20,
    fontWeight:     'bold',
    lineHeight:     1.7
  }, 
  buttons: {
    flex:            1,
    maxHeight:       200,
    width:           250,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
  },
  close: {
    flex:           0.2,
    maxHeight:      75,
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    borderTop:      '1px solid #DDD',
    color:          colors.blue,
    fontSize:       18,
    cursor:         'pointer',
    fontWeight:     'bold'
  }
}))


const PayForm = ({ 
  variant,
  account,
  tester,
  country,
  size, 
  planId,
  planType,
  showPayForm,
  showModal, 
  handleClose,
  subscribe
 }) => {


  const { classes } = useStyles();

  const [showRoot, modalStyle] = React.useMemo(() => {

    return [
           (
             !showModal && 
             showPayForm &&
             variant === planType
           ),
           {
              width:  Math.min(630, size[0]),
              height: Math.min(500, size[1]),
           }
      ]
   },[size, planType, showModal, showPayForm, variant])


   React.useEffect(() => {
     if ( showRoot ) {
       setPlan(planType)
     }
   },[showRoot, planType])



  const planObj = React.useMemo(() => {
    return country === 'test' ? testPlanTypes : country === 'UK' ? UKPlanTypes : planTypes
  },[country])

  


  return (
    <div 
      className = { classes.root }
      style     = {{
        transform: `translate(0px, ${ showRoot ? 0 : 200 }vh)`
      }}
    >
      <div 
        className = { classes.modal }
        style     = { modalStyle }
      >
        <div className = { classes.container }>
            <div className = { classes.title }>
              <div>{ planObj[planType] ? planObj[planType][0] : '' }</div>
              <div>{ planObj[planType] ? planObj[planType][1] : '' }</div>
            </div>
            <PayPalButtons
                className          = { classes.buttons }
                createSubscription = {(data, actions) =>
                    actions.subscription.create({
                        plan_id: planId,
                        vault:   true,
                        application_context: {
                          shipping_preference: "NO_SHIPPING"
                        }
                    })
                }
                onApprove = { (data) => {
                    //const { orderId, billingToken, subscriptionID } = data;
                    //const paymentId = data.paymentId ? data.paymentId : '';
                    subscribe(data);
                }}
                style = {{
                    label: "subscribe",
                    color: "blue"
                }}
            />
            <div 
              className = { classes.close }
              onClick   = { handleClose }
            >
              Close
            </div>
        </div>
      </div>
    </div>
  );
}

export default PayForm;

