import React          from 'react';
import FormControl    from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton     from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Input          from '@mui/material/Input';
import InputLabel     from '@mui/material/InputLabel';
import Visibility     from '@mui/icons-material/Visibility';
import VisibilityOff  from '@mui/icons-material/VisibilityOff';

type PasswordInputProps = {
    password:         string;
    showHelperText:   boolean;
    textFieldStyle:   { [key: string]: any };
    changeInputValue: (e: React.FormEvent) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
     password,
     showHelperText,
     textFieldStyle,
     changeInputValue
}) => { 

  //const { classes } = useStyles();


  const [showPassword, setShowPassword] = React.useState(false)

 

  return (
        <FormControl style = { textFieldStyle }>
              <InputLabel 
                 htmlFor = "password"
                 style   = {{ marginLeft: -15 }}
              >
                 Password
              </InputLabel>
              <Input 
                id           = "password"
                type         = { showPassword ? 'text' : 'password' }
                value        = { password }
                autoComplete = "current-password"
                onChange     = { changeInputValue }
                endAdornment = {
                  <InputAdornment position = "end">
                    <IconButton
                      aria-label = "toggle password visibility"
                      onClick    = { () => { setShowPassword(!showPassword) } }
                    >
                      { showPassword ? <Visibility /> : <VisibilityOff /> }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                  showHelperText
                  &&
                  <FormHelperText id = "password">must be at least 8 characters long</FormHelperText> 
              }
        </FormControl>
    )
  
}


export default PasswordInput;




