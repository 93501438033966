import React                from 'react';
import { Link }             from "react-router-dom";
import { colors }           from '../../utils/colors';
import { makeStyles }       from 'makeStyles';
import { contentInfoType }  from '../../types';

var clsx = require('classnames')

const useStyles = makeStyles()(
  (Theme) => ({    bar: {
      position:        'absolute', 
      height:          70,
      backgroundColor: colors.brightGrey,
      display:         'flex',
      alignItems:      'center',
      transition:      'all 0.5s',
      color:           '#FFF',
    },
    button: {
      height:          35,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
     // maxWidth:        340,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      color:           '#FFF',
      backgroundColor: colors.primary,
      borderRadius:    '0.2em',
      textDecoration:  'none',
      '&:hover': {
         opacity: 0.5
      }
    },
    big: {
       fontSize: 18,
       maxWidth: 400,
    }
}))

type BarProps = {
    app:                 contentInfoType;
    containerHeight:     number;
    descriptionHeight:   number;
    width:               number;
    wideScreen:          boolean;
    disable:             boolean;
    showDescription:     boolean;
    setShowPracticeComp: (val: boolean) => void;
    setShowTopicIntro:   (val: boolean) => void;
}
const Bar: React.FC<BarProps> = ({ 
    app,
    wideScreen,
    containerHeight,
    descriptionHeight,
    width,
    disable,
    showDescription,
    setShowPracticeComp,
    setShowTopicIntro

}) => {

  const { classes } = useStyles();

  const handleClick = () => {
    
    setShowPracticeComp(true)
    setShowTopicIntro(false)
    /* const disableAccess = app.origin !== "lessons" && denyAccess && app.totalLikes === 0;
    if ( !disableAccess ) {
      recordAppSelection();
    }
    goToApp( disableAccess ); */
  }


  return (
        <div 
         className = { classes.bar }
         style     = {{
           width:           width / 10 * 9.6,
           right:           width / 10 * 0.2,
        //   top:             containerHeight + 74,
           top:             containerHeight + 74 + (showDescription ? descriptionHeight : 0),

           justifyContent:  'space-evenly',
         }}
         >
           {
             !disable 
             &&
             <Link 
          //     replace
               className = { wideScreen ? clsx(classes.button, classes.big) : classes.button }
               to        = { app.sub_topic ? `/${ app.sub_topic.replaceAll(' ','_') }` : '/' }
               onClick   = { handleClick }
             >
               { `practice` }
             </Link>
           }
       </div>
  );
}





export default Bar;

