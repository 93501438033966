import React                        from 'react';
import { 
  UNSAFE_NavigationContext,
  useNavigate
}                                   from "react-router-dom";
import { store }                    from 'index'
import { SET_COMPONENTS_STATE }     from 'actions/component_actions';
import { SET_PAGE_STATE }           from 'actions/page_actions';
import { componentsStateType }      from 'reducer/types';
import { pageStateType }            from 'types';

const useBackListener = () => {

  const navigator: any = React.useContext(UNSAFE_NavigationContext).navigator;

  const state              = store.getState();
  const pageState          = state.page;
  const componentsState    = state.components;
  const setComponentsState = (payload: componentsStateType) => store.dispatch(SET_COMPONENTS_STATE(payload));
  const setPageState       = (payload: pageStateType) => store.dispatch(SET_PAGE_STATE(payload));
  const navigate           = useNavigate();

  React.useEffect(() => {
    const listener: any = ({ location, action }: any) => {
      //console.log("listener", { location, action });
      if (action === "POP") {
        const appPage = pageState.page === 'app page';

        setComponentsState({
          ...componentsState, 
          showAppIntro:     false,
          showTopicIntro:   false,
          showPracticeComp: false,
          showMenu:         true,
          showNavbar:       true,//appPage ? true : componentsState.showNavbar,
          showFooter:       true,//appPage ? true : componentsState.showFooter,
          showAppIframe:    false,
          appUrl:           '',
        })
        
        if ( appPage || pageState.page === 'challenges' ) {
          setPageState({ ...pageState, page: 'content' })
          navigate('../', { replace: true })
        }
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [navigator, navigate, componentsState, pageState]);
};

export default useBackListener