import React             from 'react';
import { makeStyles }    from 'makeStyles';
import FindTheShape      from 'animations/find_the_shape';
import ImaginigFractions from 'animations/imagining_fractions';
import Volumes           from 'animations/volumes';

var clsx = require('classnames')

const useStyles = makeStyles()((Theme) => ({    
    animation: {
      marginTop:      30,
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
    },
    image: {
      display:            'flex',
      justifyContent:     'center',
      alignItems:         'center',
      height:             '90%',
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
      backgroundImage:    'url(https://iseemath.co/images/teacher.png)' 
    },
    innerAnimation: {
       flex:   1,
       height: '100%',
       width:  '100%',
    }
}))


type AnimationProps = {
  ind:        number;
  size:       number[];
  wideScreen: boolean;
}
 
const Animation: React.FC<AnimationProps> = ({ ind, size, wideScreen }) => {


  const { classes } = useStyles();

  const phoneSize = React.useMemo(() => {
    return size[0] < 500
  },[size])

  const dimensions = React.useMemo(() => {
    return wideScreen ? [120, 0.5, 33] : [70, 0.3, (phoneSize ? 15 : 20)]
  },[wideScreen, phoneSize])


  const [animationStyle, innerAnimationStyle] = React.useMemo(() => {
    return[
        {
          width:     wideScreen ? 300 : '32vw',
          minWidth:  wideScreen ? 300 : '32vw',
          maxWidth:  wideScreen ? 300 : '32vw',
          height:    wideScreen ? 200 : 200,
          minHeight: wideScreen ? 200 : 200,
          maxHeight: wideScreen ? 200 : 200,
        },
        {
        
          transform: `translate(${ wideScreen ? '51%' : /* phoneSize ? '52%' :  */'53%' }, ${ wideScreen ? '35%' : phoneSize ? '44%' : '41%' })`,
        }
      ]
  },[wideScreen, phoneSize])


  return (
        <>  
            {
              !ind && 
              <div 
                 className = { classes.animation }
                 style     = { animationStyle }
              >
                 <ImaginigFractions dimensions = { dimensions[ind] }/>
              </div>
            }
            {
              ind === 1 && 
              <div 
                 className = { classes.animation }
                 style     = { animationStyle }
              >
                <FindTheShape dimensions = { dimensions[ind] }/>
              </div>
            }
            {
              ind === 2 && 
              <div 
                 className = { clsx(classes.animation, classes.image) }
                 style     = { animationStyle }
              >
                 <div 
                   className = { classes.innerAnimation }
                   style     = { innerAnimationStyle }
                 >
                   <Volumes dimensions = { dimensions[ind] }/>
                 </div>
              </div>
            }
        </>

  );
}


export default Animation;
