

const my_replace_pattern = (string: string, pattern: string, replacement: string) => {

    if( string.indexOf(pattern) === -1 ) {
        return string;
    }
    var array = string.split(pattern);
    return array.join(replacement);
}

const clean_char = ( string: string, c: string ) => {
    
    c = c || " ";
    
    if ( string.indexOf(c) === -1 ) { 
        return string
    }
        
    var result = "";

    for ( let i = 0; i < string.length; i++ ) {
        var new_ = string[i]; 
        if( new_ !== c ) {
           result += new_;
        }
    }

    return result;
    
}



const func = (obj: { [key: string]: string }, c: string) => {
    return obj[c];
}


export const my_encoder = (string: string, decode: boolean) => {
 
    string = my_replace_pattern(string,"@gmail.com","");
    string = clean_char(string,"@");
    string = clean_char(string,"."); 

    let arr = [];
  
    var encoding_obj = {"0":"m","1":"c","2":"g","3":"o","4":"3","5":"2","6":"r","7":"1","8":"e","9":"6","a":"s","b":"n","c":"x","d":"u","e":"w","f":"5","g":"9","h":"l","i":"h","j":"j","k":"b","l":"0","m":"p","n":"y","o":"t","p":"7","q":"v","r":"8","s":"a","t":"q","u":"4","v":"k","w":"f","x":"i","y":"z","z":"d","@":"_",".":"#"};
    var decoding_obj = {"0":"l","1":"7","2":"5","3":"4","4":"u","5":"f","6":"9","7":"p","8":"r","9":"g","s":"a","n":"b","x":"c","u":"d","w":"e","l":"h","h":"i","j":"j","b":"k","p":"m","y":"n","t":"o","v":"q","a":"s","q":"t","k":"v","f":"w","i":"x","z":"y","d":"z","c":"1","g":"2","o":"3","r":"6","e":"8","m":"0","_":"@","#":"."};
  
    var obj = decode ? decoding_obj : encoding_obj;    
    
    for ( let i = 0; i < string.length; i++ ) {
        const str = func(obj, string[i])
        arr.push(str)
    }

    arr.reverse();

    return arr.join("");
    
}


const last = (array: [] | string) => { return array[array.length-1]; }


export const create_time_limit_code = () => {

    //console.log("create_time_limit")

    var D = new Date();

    var y = last(String(D.getFullYear()));

    var m = String(D.getMonth())  //Get the month as a number (0-11)
    var d = String(D.getDate()) //Get the day as a number (1-31)

    if( m.length === 1 ) { m = "0" + m }
    if( d.length === 1 ) { d = "0" + d }

    var code = d[0]+m[0]+y+m[1]+d[1]; 

    //console.log("y : "+ y);
    //console.log("m : "+ m);
    //console.log("d : "+ d);

    //console.log("code : " + code)

    code = my_encoder(String(code), false);

    //console.log("code : " + code);

    return code;  
}




export const refineData = (data: { [key: string]: string }[]) => {

    for ( let i = 0; i < data.length; i++ ) {
      if ( data[i].lessonType.length > 10 ) {
  
         const str = data[i].lessonType;
         const res = str.substring(0, str.indexOf(" "));
  
         data[i].lessonType = res;
      }
    }
  
    return data;
  }
  
  
export const updateHash = ( data: { [key: string]: string }[], hash: { [key: string]: any } ) => {
  
    for ( let i = 0; i < data.length; i++ ) {
       if ( hash[data[i].lessonType] ) {
          hash[data[i].lessonType].studentScores = 1;
       }
    }
  
    return hash;
  
  }
  
  









