import React from 'react';

const useSize = () => {
    const [size, setSize] = React.useState([0,0]);  

    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
  
      updateSize();
      return () => { 
        window.removeEventListener('resize', updateSize); 
      }
    }, []);

    return size;
  
}

export default useSize;

