import { call, take, put }                                   from 'redux-saga/effects';
import axios                                                 from 'axios';
import { SET_TOTAL_LIKES, UPDATE_TOTAL_LIKES, RECORD_ERROR } from '../actions';
import mockTotalLikes                                        from '../data/mockData/mockTotalLikes';

const totalLikesEndpoint = 'https://www.yisumatica.org.il/api/getTotalLikes.php';

function* getTotalLikes(): Generator<any,any,any> {
  while(true) {
    try {

        yield take('GET_TOTAL_LIKES_SAGA');
        
        if (process.env.NODE_ENV === 'development') {

            yield put(SET_TOTAL_LIKES( mockTotalLikes ))
            yield put(UPDATE_TOTAL_LIKES())

        }
        if (process.env.NODE_ENV === 'production') {
            // get user activity from DB

            const totalLikes = yield call( axios.post, totalLikesEndpoint);

           // console.log("totalLikes   :    ",totalLikes)

            if (totalLikes && totalLikes.data && totalLikes.data.result) {

                yield put(SET_TOTAL_LIKES( totalLikes.data.result ))
                yield put(UPDATE_TOTAL_LIKES())
            }

            else {
              yield put(UPDATE_TOTAL_LIKES())
              console.log("can't get user activity")
              if ( totalLikes && totalLikes.data && !Boolean(totalLikes.data.success) && totalLikes.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: '', err: totalLikes.data.msg, endpoint: 'get_total_likes' }));
              }
            }

            
        }
    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default getTotalLikes;














