import React          from 'react';
import { colors }     from '../utils/colors';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      borderRadius:    '0.25em',
      position:        'absolute',
      whiteSpace:      'nowrap',
      paddingTop:      7,
      paddingBottom:   7,
      paddingLeft:     15,
      paddingRight:    15,
      fontSize:        14,
      fontWeight:      'bold',
      backgroundColor: colors.toolTip,
      color:           '#FFF',
      transition:      'padding 0.05s, opacity 0.2s',
     // transition:      'opacity 0.25s'
    },
}))

type ToolTipProps = {
  showTT: boolean;
  lower:  boolean;
  text:   string;
  extra?: number;
}

const ToolTip: React.FC<ToolTipProps> = ({ showTT, lower, text, extra }) => {

  const { classes } = useStyles();

  const style = React.useMemo(() => {
      return {
          maxHeight:     showTT ? undefined : 0,
          paddingTop:    showTT ? undefined : 0,
          paddingBottom: showTT ? undefined : 0,
          opacity:       showTT ? 1 : 0,
          transform:     `translate(0px, ${ lower ? 35 : -35 - (!!extra ? extra : 0) }px)`,
      }
  },[showTT, lower, extra])

  return (
   
        <div 
           className = { classes.root }
           style     = { style }
        >
            { text }
        </div>
                     
  );
}


export default ToolTip;



