import React                     from 'react';
import instructionsArr           from './instructionsArr';
import LevelGrid                 from './LevelGrid';
import { makeStyles }            from 'makeStyles';
import { colors }                from '../../../utils/colors';

//var clsx = require('classnames')

const useStyles = makeStyles()((Theme) => ({
    
    root: {
        //border: '1px dashed #DDD',

        flex:           1,
        height:         '80vh',
        marginTop:      '10vh',
        width:          '100%',
       // display:        'flex',
        //alignItems:     'center',
       // justifyContent: 'space-between',
        //flexFlow:       'column',
        //marginLeft:     15,
        //marginRight:    15,
        paddingLeft:    25,
       // paddingRight:   15,
        transition:     'all 0.5s'
    },
    container: {
        //border: '1px dashed #000',
        //flex:           1,
        height:         '80vh',
        //marginTop:      '10vh',
      //  width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'space-between',
        flexFlow:       'column',
        //marginLeft:     15,
        //marginRight:    15,
        //paddingLeft:    15,
        //paddingRight:   15,
       // transition:     'all 0.5s'
    },
    infoContainer: {

        flex:            1,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        flexFlow:        'column',
        maxWidth:        350,
        backgroundColor: '#FFFFFFF9',
        zIndex:          1,
    },
    info: {
      color:      colors.primary,
      fontSize:   18,
      lineHeight: 2,
      fontWeight: 'bold',
      transition: 'all 0.4s'
    },
    gridContainer: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'flex-end',
        flexFlow:       'column',
        width:          '100%',
       // padding: 15,
    },
    selectLevel: {
        height:         50,
        fontSize:       20,
        color:          colors.primary,
        fontWeight:     'bold',
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center'
    },
    grid: {
        height:              150,
        gap:                 '10px 10px',
        display:             'grid',
        gridTemplateColumns: `repeat(3, 1fr)`,
        width:               '100%',      
    },
    button: {
        display:         'flex',
        height:          40,
        fontSize:        17,
        color:           colors.primary,
        width:           '100%',
        alignItems:      'center',
        justifyContent:  'center',
        borderRadius:    '0.4em',
        fontWeight:      'bold',
        border:          `1px solid ${ colors.primary }`,
    },
    selected: {
        color:           '#FFF',
       // border:          '1px solid #FFF',
        backgroundColor: colors.primary,
    },
    unSelected: {
        color:           colors.primary,
      //  border:          `1px solid ${ colors.primary }`,
        backgroundColor: '#FFF',
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }
    }
}))

type SideBarProps = {
    variant:          string;
    level:            number;
    menuSize:         number;
    initialAnimation: boolean;
    setLevel:         (val: number) => void
}

const SideBar: React.FC<SideBarProps> = ({
    variant,
    level,  
    menuSize,
    initialAnimation,
    setLevel

  }) => {

  const { classes } = useStyles();

  const [rootStyle, containerStyle] = React.useMemo(() => {
      return[
          {
            transform: `translate(${ initialAnimation ? 0 : -100 }vw, 0vh)`,
            opacity:   initialAnimation ? 1 : 0
          },
          {
            width: menuSize - 60
          }
    ]
  },[initialAnimation, menuSize])

  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
    >
       <div 
          className = { classes.container } 
          style     = { containerStyle }
      >
            {/* <div className = { classes.button }>
                exit
            </div> */}
            <div className = { classes.infoContainer }>
                {
                    !!instructionsArr[variant] && instructionsArr[variant].map((val) => {
                        return(
                            <div 
                            key       = { val }
                            className = { classes.info }
                            >
                            { val }
                            </div>
                        )
                    })
                }
            </div>
            <LevelGrid
                variant  = { variant }
                level    = { level }
                setLevel = { setLevel }
            />
        </div>
     </div>
  );
}

export default SideBar;
