import React                from 'react';
import { Link }             from "react-router-dom";
import { contentInfoType }  from '../../types';
import { colors }           from '../../utils/colors';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { makeStyles }       from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    title: {
      position:        'absolute',
      top:             0,
      right:           0,
      height:          70,
     // width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-evenly',
      textAlign:       'center',
      backgroundColor:  colors.brightGrey,
      color:           '#334',
      fontWeight:      'bold',
     // borderTop: '1px solid #FFF'
    },
    favorites: {
      minWidth:       35,
      minHeight:      35,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      position:       'absolute',
    },
    star: {
      fontSize: 40,
      cursor:   'pointer',
      color:    colors.primary
    },
    backButton: {
      position:        'absolute',
      left:            3,
      height:          35,
      maxWidth:        35,
      minWidth:        35,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      color:           '#FFF',
      borderRadius:    '50%',
      backgroundColor: colors.primary,
      '&:hover': {
         opacity: 0.5
      }
    },
}))

type TopBarProps = {
    size:              number[];
    menuSize:          number;
    app:               contentInfoType;
    wideScreen:        boolean;
    handleClose:       () => void;
}
const TopBar: React.FC<TopBarProps> = ({ 
    size,
    menuSize,
    app,
    wideScreen,
    handleClose,
}) => {

  const { classes } = useStyles();


  const [titleContainerStyle, titleStyle] = React.useMemo(() => {

    const containerWidth = wideScreen ? size[0] - menuSize - 4 : size[0];
    const titleWidth     = size[0] - 150 - ( wideScreen ? menuSize : 0 );

    return[
      {
         maxWidth: containerWidth,
         minWidth: containerWidth
      },
      {
        maxWidth: titleWidth,
        minWidth: titleWidth,
        fontSize: size[0] > 900 ? 25 : 20,
      }
    ]
  },[size, menuSize, wideScreen])
  
  return (
    <div 
      className = { classes.title }
      style     = { titleContainerStyle }
    >
           <div style = { titleStyle }>
              { !!app && !!app.sub_topic ? app.sub_topic : '' } 
           </div>
           {
             !wideScreen &&
             <Link 
               replace
               to        = '/'
               className = { classes.backButton }
               onClick   = { handleClose }
              >
               <ArrowBackRoundedIcon/>
              </Link>
           }
    </div>
  );
}




export default TopBar;

