
import React                      from 'react';
import { store }                  from 'index';
import { GET_BONUS_CONTENT_SAGA } from 'actions';

const useGetBonusContent = (userEmail: string) => {
   
    const state           = store.getState();
    const userInfoUpdated = state.data.dataUpdateState.user;

    const [getBonusContentOnce, setGetBonusContentOnce]  = React.useState(false)

    React.useEffect(() => {

        if (userInfoUpdated && !getBonusContentOnce ) {

            setGetBonusContentOnce(true)
    
            const payload = {
                userEmail: userEmail,
                hash:      {}
            }

            store.dispatch(GET_BONUS_CONTENT_SAGA(payload));

        }
        
    },[userEmail, userInfoUpdated, getBonusContentOnce])
    
}


export default useGetBonusContent;