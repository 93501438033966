import { call, take, put }               from 'redux-saga/effects';
import axios                             from 'axios';
import { RECORD_ERROR }                  from '../actions'; 
import { SET_MODAL_STATE, CREATE_ERROR } from 'actions/modal_actions'


const dev = process.env.NODE_ENV === 'development';
const endpoint = 'https://iseemath.co/api/submitMessage.php';


function* submitMessage(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('SUBMIT_MESSAGE_SAGA');
        yield put(SET_MODAL_STATE({ variant: 'loader', open: true, showButtons: false })); 

        const { payload } = userInput;
        console.log('payload : ',payload )

        if (dev) {
            yield put(SET_MODAL_STATE({ variant: 'messageSent', open: true, showButtons: true }));  
        }
        else {

            const res = yield call( axios.post, endpoint, payload );

            console.log('res       :   ',res)
            console.log('res.data  :   ',res.data)
     
            if (res && res.data && res.data.success && res.data.success === 1 ) {
                yield put(SET_MODAL_STATE({ variant: 'messageSent', open: true, showButtons: true }));  
            }
            else {
              yield put(CREATE_ERROR());
              if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: payload.userEmail, err: res.data.msg, endpoint: 'submit_message' }));
              }
            }

        }
    }
    catch(err) {
      yield put(CREATE_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default submitMessage;


