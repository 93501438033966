import React          from 'react';
import Circle         from './Circle';
import { makeStyles } from 'makeStyles';
import { colors }     from 'utils/colors'

const positions = [
    [{ width: 300, height: 200, rotate: 0 },   { width: 250, height: 250, rotate: 45 }, { width: 300, height: 90,  rotate: 70 }],
    [{ width: 60,  height: 25,  rotate: 100 }, { width: 77,  height: 200, rotate: 30 }, { width: 100, height: 220, rotate: 9 }],
    [{ width: 100, height: 300, rotate: 20 },  { width: 70,  height: 130, rotate: 80 }, { width: 285, height: 285, rotate: 177 }]
];

const useStyles = makeStyles()((Theme) => ({    
    rect: {
        position:   'absolute',
        left:       '50%',
        top:        '50%',
        //background: '#000'
    }
}))

type RectProps = {
    len:        number;
    ind:        number;
    counter:    number;
    dimensions: number;
    speed:      number;
}

const Rect: React.FC<RectProps> = ({ len, ind, counter, dimensions, speed }) => {

  const { classes } = useStyles();

  const [ width, setWidth ]           = React.useState(positions[ind][counter].width);
  const [ height, setHeight ]         = React.useState(positions[ind][counter].height);
  const [ rotation, setRotation ]     = React.useState(positions[ind][counter].rotate);
  const [ border, setBorder ]         = React.useState('1px solid transparent');
  const [ transition, setTransition ] = React.useState(0);

  React.useEffect(() => {

    setTransition(0.5)
    setBorder('1px solid transparent')
    setWidth(positions[ind][counter].width);
    setHeight(positions[ind][counter].height);
    setRotation(positions[ind][counter].rotate);

    if (!ind) {
        setTimeout(() => { setBorder(`1px solid ${ colors.primary }`) }, speed * 0.6)
        setTimeout(() => { setTransition(0) }, speed * 0.9)
    }

  },[counter, ind, speed, setWidth, setHeight, setRotation, setTransition, setBorder ])


  const rectStyle = React.useMemo(() => {
    return {
        width:      width * dimensions,
        height:     height * dimensions,
        transform:  `translate(-50%,-50%) rotate(${ rotation }deg)`,
        transition: `width ${ speed/2000 }s, height ${ speed/2000 }s, transform ${ speed/2000 }s, border ${ transition }s`,
    }
  },[width, dimensions, height, rotation, speed, transition])

  return (

     <div 
       className  = { classes.rect }
       style      = {{ 
        ...rectStyle,
        border:     ind ? 'none' : border,
       }}
     >
         {
            new Array(len).fill(0).map((val, ind) => {
               return(
                   <Circle key = {`circle${ind}`} ind = { ind }/>
               )
            })
         }
     </div>
  );
}

export default Rect


