import React                    from 'react';
import { Dispatch }             from "redux";
import { connect }              from 'react-redux';
import { reducerType }          from 'reducer';
import { ActionTypes }          from 'actions/types';
import { 
  useNavigate, 
  useLocation 
}                               from "react-router-dom";
import DropDown                 from './dropdown';
import Container                from './Container';
import ToggleMenuButton         from './ToggleMenuButton';
import useGetDropDownItems      from './useGetDropDownItems';
import navBarClick              from 'functions/navBar/nav_bar_click';
import menuToggle               from 'functions/navBar/menu_toggle';
import logoClick                from 'functions/navBar/logo_click';
import { openMessageForm }      from 'functions/modal/message_form';
import recordEvent              from 'functions/contentInteraction/record_event';
import { pagesWithMenuArr }     from 'utils/defaultStates';
import { sizes }                from 'utils/defaultStates';
import { colors }               from 'utils/colors';
import { makeStyles }           from 'makeStyles';
import { pageStateType }        from 'types';
import { SET_MODAL_STATE }      from 'actions/modal_actions'


const useStyles = makeStyles()(
  (Theme) => ({    navBar: {
        position:        'fixed',
        top:             0,
        right:           0,
        backgroundColor: colors.primary,
        transition:      'top 0.6s',
        boxShadow:       '0px 4px 4px rgba(0,0,0,0.15)',
    },
    logoContainer: {
      position:        'fixed',
      left:            0,
      overflow:        'hidden',
      display:         'flex',
      alignItems:      'center',
      transition:      'background 0.4s, top 0.6s',
      backgroundColor: colors.brightGrey
    },
    logo: {
      width:              78,
      height:             '85%',
      marginTop:          5,
      marginLeft:         15,
      backgroundImage:    `url(https://iseemath.co/images/logoSmall.png)`,
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
      cursor:             'pointer'
    },
    groupUrl: {
      position:       'absolute',
      minWidth:       '100vw',
      height:         '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      fontSize:       25,
      fontWeight:     'bold'
    },
    logOut: {
      position:       'absolute',
      width:          180,
      right:          15,
      height:         36,
      top:            0,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      fontWeight:     'bold',
      color:          '#FFF',
      borderRadius:   '0.25em',
      border:         '1px solid #FFF',
      cursor:         'pointer'
    }
}))

type NavBarProps = {
  pageState:              pageStateType;
  size:                   number[];
  menuSize:               number;
  subscriptionStatus:     string;
  showNavbar:             boolean;
  wideScreen:             boolean;
  denyAccess:             boolean;
  guest:                  boolean;
  subscriber:             boolean;
  unSubscribedAuthedUser: boolean;
  studentLoggedIn:        boolean;
  setChangeContent:       (val: boolean) => void;
  setModalState:          (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
}

const NavBar: React.FC<NavBarProps> = ({ 
  size,
  menuSize,
  pageState,
  wideScreen,
  showNavbar,
  subscriptionStatus,
  studentLoggedIn,
  denyAccess,
  guest,
  subscriber,
  unSubscribedAuthedUser,
  setChangeContent,
  setModalState
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
    setInitialPos(showNavbar)
  },[showNavbar])

  const navigate =  useNavigate();
  const location =  useLocation();

  const logoContainerClick = () => {
    if ( location.pathname !== '/') {
      navigate('../', { replace: true })
    }
  }


  const handleNavClick = (val: string) => {
    navBarClick( val, setChangeContent )
  }

  const handleOpenModal = (val: string) => {
    setModalState({ variant: val, open: true, showButtons: true })
  }

  const goToMessageForm = () => {
    openMessageForm(guest);
  }

  const getStarted = () => {
    if ( !denyAccess ) {
        setModalState({
          variant:     'subDetails', 
          open:        true, 
          showButtons: true
        })
    }
    else {
      setModalState({
        variant:     'selectPlan', 
        open:        true, 
        showButtons: true
      })
    }
    recordEvent('registration', 'navbar get started button clicked', 'navbar_get_started')
  } 


  const bigNavBar = React.useMemo(() => {
    return size[0] > 1200;
  },[size])



  const showSubscribeButton = React.useMemo(() => {
    return (!studentLoggedIn && (guest || denyAccess || unSubscribedAuthedUser));
  },[studentLoggedIn, guest, denyAccess, unSubscribedAuthedUser])


  const showSelectors = React.useMemo(() => { 
    return showSubscribeButton ? size[0] > 1000 : size[0] > 800

  },[size, showSubscribeButton])



  const showMenuButton = React.useMemo(() => {
    return !wideScreen && (pagesWithMenuArr.includes(pageState.page))
  },[wideScreen, pageState.page])



  const [dropDownButtonWidth, menuButtonWidh, logoWidh] = React.useMemo(() => {
     
     return size[0] > 600 ? [80,(showMenuButton ? 80 : 0),80] : [50,(showMenuButton ? 50 : 0),50]
  },[size, showMenuButton])



 const { selectors, dropDownitems } = useGetDropDownItems(pageState, showSelectors, studentLoggedIn, guest, subscriber)

 
 const [navBarStyle, logoContainerStyle] = React.useMemo(() => {
  return [
    {
       top:    initialPos ? 0 : -(sizes.navBarSize + 50),
       height: sizes.navBarSize,
       width:  bigNavBar ? size[0] - menuSize : size[0],
    },
    {
      height: sizes.navBarSize,
      width:  menuSize,
      top:    initialPos ? 0 : -(sizes.navBarSize + 50)
    }
  ]
 },[initialPos, menuSize, size, bigNavBar])

 
  return (
    <>
        {
          wideScreen
          &&
          <div 
            className = { classes.logoContainer }
            onClick   = { logoContainerClick }
            style     = { logoContainerStyle }
          >
            <div 
                className = { classes.logo }
                onClick   = { logoClick }
            />
          </div>
        }
        
        <div 
            className = { classes.navBar }
            style     = { navBarStyle }
        >
          {
            !bigNavBar
            &&
            <ToggleMenuButton
                size             = { size }
                showMenuButton   = { showMenuButton }
                menuButtonWidh   = { menuButtonWidh }
                logoWidh         = { logoWidh }
                handleMenuToggle = { menuToggle }
            />
          }
          <Container
              size                   = { size }
              bigContainerWidth      = { size[0] - menuSize }
              dropDownButtonWidth    = { dropDownButtonWidth }
              menuButtonWidh         = { menuButtonWidh }
              logoWidh               = { logoWidh }
              bigNavBar              = { bigNavBar }
              page                   = { pageState.page }
              wideScreen             = { wideScreen }
              showSelectors          = { showSelectors }
              selectors              = { selectors }
              subscriptionStatus     = { subscriptionStatus }
              showSubscribeButton    = { showSubscribeButton }
              showSignInButton       = { !studentLoggedIn && guest }
              goToMessageForm        = { goToMessageForm }
              getStarted             = { getStarted }
              handleNavClick         = { handleNavClick }
              handleOpenModal        = { handleOpenModal }
          />
          {
            studentLoggedIn && 
            <div 
               className = { classes.logOut }
               onClick   = { () => handleOpenModal('log out') }
               style     = {{
                  top: (sizes.navBarSize - 36) / 2
               }}
            >
               log out
            </div>
          }
          {
            !studentLoggedIn && 
            <DropDown
                dropDownitems         = { dropDownitems }
                size                  = { size }
                wideScreen            = { wideScreen }
                bigNavBar             = { bigNavBar }
                dropDownButtonWidth   = { dropDownButtonWidth }
                goToMessageForm       = { goToMessageForm }
                handleOpenModal       = { handleOpenModal }
                handleNavClick        = { handleNavClick }
            />
         }
        </div>
    </>
  );
}




const mapStateToProps = (reducer: reducerType) => {

  //const studentLoggedIn   = reducer.user.studentLoginState.loginState === 'success';

  return {

    pageState:       reducer.page,
    studentLoggedIn: reducer.user.studentLoginState.loginState === 'success',
    showNavbar:      reducer.components.showNavbar
    
}};
 
const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setModalState: (payload: { variant: string, open: boolean, showButtons: boolean }) => dispatch(SET_MODAL_STATE(payload)),
});
 

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

