import { useMemo }             from 'react';
import { contentInfoType }     from 'types'
import { componentsStateType } from 'reducer/types';


const usePath = ( 
    componentsState: componentsStateType, 
    selectedApp:     contentInfoType
) => {
 
  const path = useMemo(() => {

    if ( componentsState.showAppIntro ) {
      const appId = !!selectedApp.lesson_id ? selectedApp.lesson_id : !!selectedApp.uid ? `${ selectedApp.uid }` : '';
      if ( !!appId ) {
        let pth     = appId.replaceAll(' ','_')
        if (pth[pth.length - 1] === '?') {
          pth = pth.slice(0, pth.length - 1);
        }
        return `/${ pth }`
      }
    } 
    return '/'
  },[selectedApp, componentsState ]) 

  return path
 }

  export default usePath;
