import React            from 'react';
import { makeStyles }   from 'makeStyles';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { colors }       from 'utils/colors';

const useStyles = makeStyles()((Theme) => ({
    
  container: {
      position:       'fixed',
      top:            30,
      right:          15,
      height:         30,
      width:          30,
      marginTop:      10,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      borderRadius:   '50%',
      cursor:         'pointer',
      transition:     'all 0.3s',
     // border:         `1px solid ${ colors.primary }`,
      zIndex:         1
  },
  icon: {
   // color:    '#FFF',
    fontSize: 35,
  }
}))

type ButtonProps = {
   func: () => void;
}

const Button: React.FC<ButtonProps> = ({ func }) => {

  const [initialPosition, setInitialPosition] = React.useState(false);
  const [hover, setHover]                     = React.useState(false);

  React.useEffect(() => {
     setTimeout(() => { setInitialPosition(true) }, 10)
  },[setInitialPosition])

  const { classes } = useStyles();

  const doFunc = () => {
   // recordEvent('app', 'bonus content closed', 'bonus content closed');
    func();
  }

  return (
        <div
          className    = { classes.container }
          onClick      = { doFunc }
          onMouseEnter = { () => { setHover(true) }}
          onMouseLeave = { () => { setHover(false) }}
          style        = {{
            marginLeft:      initialPosition ? 0 : 600,
            backgroundColor: !hover ? '#FFF' : colors.primary
          }}
        >
            <ClearRoundedIcon className = { classes.icon } style = {{ color: !hover ? colors.primary : '#FFF' }}/>
        </div>   
  );
}

export default Button;
