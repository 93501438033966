import { contentInfoType } from "types";

export const SET_CHALLENGE_STATE = (payload: contentInfoType) => ({
  type: 'SET_CHALLENGE_STATE',
  payload: payload
});


export const SET_PRACTICE_STATE = (payload: contentInfoType) => ({
  type: 'SET_PRACTICE_STATE',
  payload: payload
});
