import { call, take, put }          from 'redux-saga/effects';
import axios                        from 'axios';
import { SET_USER_LESSONS }         from 'actions/content_actions';
import { RECORD_ERROR }             from 'actions';
 import mockUserLessons             from 'content/mockContent/userLessons';

 const userLessonsEndpoint = 'https://www.yisumatica.org.il/api/getUserLessonsRaph.php';



 const addOrigin = (str: string, arr: { [key: string]: any }) => {
   for ( let i = 0; i < arr.length; i++ ) {
         arr[i].origin = str;
         arr[i].uid    = !!arr[i].uid ? `${ arr[i].uid }` : ''
   }
   return arr;
 }


function* getUserLessons(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('GET_LESSONS_SAGA');

        console.log('GET_LESSONS_SAGA');


        const { userEmail } = input.payload;

        if ( process.env.NODE_ENV === 'development' ) {

          const payload = { userLessons: mockUserLessons }

          yield put(SET_USER_LESSONS( payload ))
        
        }


        if (process.env.NODE_ENV === 'production') {

                  let userLessonsArr      = []; 
                  const userLessons       = yield call( axios.post, userLessonsEndpoint, { userName: userEmail });

                 if ( !!userLessons && !!userLessons.data && !!userLessons.data.result && !!userLessons.data.result.length ) {                  
                   userLessonsArr = yield addOrigin('userLessons', userLessons.data.result);
                 }
          
                if ( userLessons && userLessons.data && userLessons.data.result ) {

                    const payload = { userLessons: userLessonsArr }
  
                    yield put(SET_USER_LESSONS( payload ))

                   

                }
                if ( userLessons && userLessons.data && !Boolean(userLessons.data.success) && userLessons.data.msg ) {
                  yield put(RECORD_ERROR({ userEmail: userEmail, err: userLessons.data.msg, endpoint: 'get_user_lessons' }));
                }

        }
      
      

    }
    catch(err) {
      console.log('err at get content saga  :  '+err);
    }
    finally {}
  }
}


export default getUserLessons;
