import { call, take, put }           from 'redux-saga/effects';
import axios                         from 'axios';
import { initialUserInfo }           from 'utils/defaultStates';
import { CREATE_USER, RECORD_ERROR } from 'actions';
import { SET_USER_INFO }             from 'actions/userInfo_actions';


const endpoint = 'https://iseemath.co/api/createUser.php';
const dev = process.env.NODE_ENV === 'development';


function* createUser(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('CREATE_USER_SAGA');
        const { payload } = userInput;


        if ( dev ) {
          console.log('MOCK CREATE_USER')
          console.log('payload  :  ',payload)
          yield put(CREATE_USER({ success: 'true' }));
        }

        else {
          console.log('CREATE_USER_SAGA')
          console.log('userInput  :   ',userInput)
          console.log('payload  :  ',payload)
  
          const res = yield call( axios.post, endpoint, payload );
  
          console.log('res       :  ',res)
          console.log('res.data  :  ',res.data)
  
          if ( res.data && res.data.msg ) {
              console.log('res.data.msg  :   ',res.data.msg)
          }
  
          if ( res.data && res.data.success ) {

              const userInfo = { ...initialUserInfo, userEmail: payload.userEmail }
              
              yield put(SET_USER_INFO(userInfo));

              if ( res.data.success === 1 ) {
                if ( res.data.freeUser && res.data.freeUser === 'true' ) {
                  yield put(CREATE_USER({ success: 'freeUser' }));
                }
                else {
                  yield put(CREATE_USER({ success: 'true' }));
                }
              }
              if ( res.data.success === 2 ) {
                yield put(CREATE_USER({ success: 'userAlreadyExists' }));
              }
          }
          else {
              yield put(CREATE_USER({ success: 'false' }));
              if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: payload.userEmail, err: res.data.msg, endpoint: 'create_user' }));
              }
          }
        }

    }
    catch(err) {
      yield put(CREATE_USER({ success: 'false' }));
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default createUser;
