import React                 from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles }        from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    
    text: {
       marginLeft:   '50%',
       width:        '90%',
       transform:     'translate(-50%, 0%)',
       paddingTop:    15,
       paddingBottom: 15,
       paddingLeft:   30,
       paddingRight:  30,
       lineHeight:    1.2,
       letterSpacing: 2,
       display:       'flex',
       alignItems:    'center',
    },
    quote: {
      display:      'flex',
      marginLeft:   '50%',
      width:        '90%',
      transform:    'translate(-50%, 0%)',
      lineHeight:   1.3,
      fontStyle:    'italic'
    },
    teacherName: {
      display:    'flex',
      marginTop:  5,
      marginLeft: '50%',
      width:      '90%',
      transform:  'translate(-50%, 0%)',
      lineHeight: 1.3,
      fontWeight: 'bold',
      fontSize:   15
    },
    list: {
      display:    'flex',
      width:      '86%',
      marginLeft: '7%',
      lineHeight: 1.4,
    },
    dot: {
      marginTop:   5,
      fontSize:    9,
      marginRight: 8,
    }
}))

type TextCompProps = {
    text:    string[];
    variant: string;
    keyName: string;
}
const TextComp: React.FC<TextCompProps> = ({ 
    text,
    variant,
    keyName
}) => {

  const { classes } = useStyles();

  const myClass: { [key: string]: any; } = React.useMemo(() => {
      return{ 
          'text':  classes.text,
          'list':  classes.list,
          'quote': classes.quote,
      }
  },[classes])

  
  return (
        <>
          { 
            text.map((val, ind) => {

                const teacherName = variant === 'quote' && ind === text.length - 1;

                return (
                    <div 
                      key       = { `${ keyName }_${ ind }` }
                      className = { teacherName ? classes.teacherName : myClass[variant] }
                    >
                      {
                          (variant === 'text' || variant === 'quote') && val
                      }
                      {
                          variant === 'list'
                          &&
                          <>
                            <FiberManualRecordIcon className = { classes.dot } style = {{ fontSize: 9 }}/>
                            <div>
                                { val }
                            </div>   
                         </> 
                      }
                    </div>
                )
            }) 
         }
        </>       
  );
}




export default TextComp;

