import React                      from 'react';
import { connect }                from 'react-redux';
import { Dispatch }               from "redux";
import { reducerType }            from 'reducer';
import { ActionTypes }            from 'actions/types';
import { SET_AUDIO_STATE }        from 'actions/audio_actions';
import { audioStateType }         from 'reducer/types';
import { makeStyles }              from 'makeStyles';
import Slider                     from '@mui/material/Slider';
import VolumeDownRoundedIcon      from '@mui/icons-material/VolumeDownRounded';
import VolumeUpRoundedIcon        from '@mui/icons-material/VolumeUp';
import VolumeOffRoundedIcon       from '@mui/icons-material/VolumeOffRounded';
import { colors }                 from 'utils/colors';


const useStyles = makeStyles()((Theme) => ({  
    root: {
    width:          120,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    color:           colors.primary,       
  },
  icon: {
    width:          60,
    display:        'flex',
    justifyContent: 'flex-start',
    cursor:         'pointer',
    '&:hover': {
      color: colors.primaryDark,
    }
  }
}))


type VolumeSliderProps = {
  audioState:    audioStateType;
  setAudioState: (payload: audioStateType) => void;
}

const VolumeSlider: React.FC<VolumeSliderProps> = ({ audioState, setAudioState }) => {

  const { classes } = useStyles();

  const handleChange = (event: any, newValue: number | number[]) => {
    const newVal = newValue as number;
    setAudioState({
      ...audioState,
      volume: newVal
    })
  };

  const iconClick = () => {
    setAudioState({
      ...audioState,
      volume: audioState.volume === 0 ? 30 : 0
    })
  }

  
  const Icon = audioState.volume === 0 ? <VolumeOffRoundedIcon/> : ( audioState.volume >= 50 ? <VolumeUpRoundedIcon/> : <VolumeDownRoundedIcon/> );

  return (
    <div className = { classes.root }>
          <div 
             className = { classes.icon }
             onClick   = { iconClick }
          >
               { Icon }
          </div>
          <Slider 
             value           = { audioState.volume } 
             onChange        = { handleChange } 
             aria-labelledby = "continuous-slider" 
             sx              = {{
                width:          120,
                display:        'flex',
                alignItems:     'center',
                justifyContent: 'center',
                color:           colors.primary,
                '& .MuiSlider-thumb': {
                  height: 16,
                  width:  16,
                },
             }}
          />
    </div>
  );
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    audioState: reducer.audio
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setAudioState: (payload: audioStateType) => dispatch(SET_AUDIO_STATE(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VolumeSlider);
