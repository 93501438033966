import { componentsStateType } from '../../reducer/types'

const contentClick = (
    val:              string,
    filterState:      componentsStateType,
    setFilterState:   (val: componentsStateType) => void,
    setShowSmallMenu: (val: boolean) => void,
) => {

    if (val === 'Practice' || val === 'Challenges' || val === 'Explainer Videos') {
        setShowSmallMenu(false)
    }

    setFilterState({
        ...filterState,
        searchStr:           '',
        selectedContent:     val,
        selectedAppTopic:    '',
        selectedAppSubTopic: '',
        openGradeGrid:       false,
        showTutorial:        false,
        showTopicIntro:      false,
        showStudentVideo:    false,
        showMenu:            true,
        selectedVideo:       -1,
        showAppIntro:        false,
        createTopicArr:      true,
        selectedGrades:      '1234567',
    })
}

export default contentClick;
