import React             from 'react';
import { colors }        from '../../../utils/colors';
import { makeStyles }    from 'makeStyles';

var clsx = require('classnames')

const useStyles = makeStyles()(
  (Theme) => ({    button: {
      width:           '100%',
      height:          33,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      cursor:         'pointer',
      fontWeight:     'bold',
      color:          '#FFF',
      borderRadius:    '0.2em',
    },
    hover: {
      '&:hover': {
        opacity: 0.5
      }
    },
    small: {
      fontSize: 14,
    },
    big: {
      fontSize: 15,
    }
}))


type ButtonProps = {
  val:                 string;
  subscriptionStatus:  string;
  wideScreen:          boolean;
  func:                () => void;
}

const Button: React.FC<ButtonProps> = ({ 
  val,
  subscriptionStatus,
  wideScreen,
  func
}) => {

  
  const { classes } = useStyles();


  const text = React.useMemo(() => {

    if (val === 'subscribe') {
      if ( subscriptionStatus === 'authenticated user - not subscribed' || subscriptionStatus === 'not subscribed') {
        return 'start free trial'
      }
      else {
        return 'subscribe'
      }
      
    }

    return val;

  },[val, subscriptionStatus])


  const buttonStyle = React.useMemo(() => {


      const signin = val === 'sign in';

      return {
        color:           signin ? '#FFF' : colors.primary,
        backgroundColor: signin ? colors.primary : '#FFF',
        border:          `1px solid ${ signin ? '#FFF' : 'transparent' }`,
        maxWidth:        wideScreen ? 170 : 130,
        marginLeft:      signin ? 0 : 10,
        marginRight:     signin ? 10 : 0,
      }


  },[val, wideScreen])


  return (
      <div 
         className = { clsx(classes.button, classes.hover, ( wideScreen ? classes.big : classes.small)) }
         onClick   = { func  }
         style     = { buttonStyle }
      >
        { text }
      </div>
  );
}




export default Button;



