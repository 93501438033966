import React          from 'react';
import { makeStyles } from 'makeStyles';
import { contentInfoType } from '../../types';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
        height:         '50vh',
        width:          '100vw',
        maxWidth:       600,
        display:        'flex',
        alignItems:     'center',
        flexFlow:       'column',
        justifyContent: 'center',
    },
    image: {  
        height:             '85%',
        width:              '100%',
        backgroundPosition: 'center',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat',
        transition:         'opacity 0.4s',
    }
}))


type ImageProps = {
   questionObj:     contentInfoType;
   animateImage:    boolean;
   wideScreen:      boolean;
   setAnimateImage: (val: boolean) => void;
}

const Image: React.FC<ImageProps> = ({ wideScreen, questionObj, animateImage, setAnimateImage }) => {

  const { classes } = useStyles();

 // const [initialPos, setInitialPos] = React.useState(false);
  const [animate, setAnimate]       = React.useState(false);
  const [imageSrc, setImageSrc]     = React.useState(`https://www.yisumatica.org.il/justine/${ questionObj.question_type === 'puzzle' ? 'puzzles' : 'img' }/${ questionObj.image }`);

  /* React.useEffect(() => {

        setTimeout(() => { setInitialPos(true) }, 10)

  },[setInitialPos])
 */
  React.useEffect(() => {

    if (animateImage) {

      setAnimate(true);
      setAnimateImage(false);
      setTimeout(() => { 
        setAnimate(false) 
      }, 500)
    }

  },[animateImage, setAnimateImage, questionObj])


  React.useEffect(() => {
    if ( imageSrc !== `https://www.yisumatica.org.il/justine/${ questionObj.question_type === 'puzzle' ? 'puzzles' : 'img' }/${ questionObj.image }`) {
      setTimeout(() => { 
        setImageSrc(`https://www.yisumatica.org.il/justine/${ questionObj.question_type === 'puzzle' ? 'puzzles' : 'img' }/${ questionObj.image }`); 
      }, 150)
    }
  },[imageSrc, questionObj])


  const [rootStyle, imgStyle] = React.useMemo(() => {


    return [
      {
        maxHeight: wideScreen ? 280 : 180,
      },
      {
        backgroundImage: `url(${ imageSrc })`,
        opacity:         !animate ? 1 : 0,
        width:           wideScreen ? '100%' : '90%',
      }
    ]
  },[imageSrc, /* initialPos,  */animate, wideScreen])

  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
    >
        <div
           className = { classes.image }
           style     = { imgStyle }
        />
    </div>
  );
}

export default Image;
