

import { authStateType } from "reducer/types";


export const SET_AUTH_STATE = (payload: authStateType) => ({
  type: 'SET_AUTH_STATE',
  payload: payload
});

export const SET_AUTH_LOADER = (payload: boolean) => ({
    type: 'SET_AUTH_LOADER',
    payload: payload
});

export const SET_ERROR = (payload: string) => ({
    type: 'SET_ERROR',
    payload: payload
});
