
import { componentsStateType } from "reducer/types";


  export const SET_COMPONENTS_STATE = (payload: componentsStateType) => ({
    type: 'SET_COMPONENTS_STATE',
    payload: payload
  });

  export const SHOW_INTRO_VIDEO = () => ({
    type: 'SHOW_INTRO_VIDEO',
    payload: true
  });

  export const SET_INITIAL_VIDEO_PAGE = (payload: string) => ({
    type: 'SET_INITIAL_VIDEO_PAGE',
    payload: payload
  });

  export const SET_SHOW_SMALL_MENU = (payload: boolean) => ({
    type: 'SET_SHOW_SMALL_MENU',
    payload: payload
  });

  export const SET_SHOW_TOPIC_INTRO = (payload: boolean) => ({
    type: 'SET_SHOW_TOPIC_INTRO',
    payload: payload
  });

  export const SET_SHOW_PRACTICE_COMPONENT = (payload: boolean) => ({
    type: 'SET_SHOW_PRACTICE_COMPONENT',
    payload: payload
  });

/* 
  export const SHOW_INITIAL_VIDEO = () => ({
    type: 'SHOW_INITIAL_VIDEO',
    payload: true
  });

  export const DONT_SHOW_INITIAL_VIDEO = () => ({
    type: 'DONT_SHOW_INITIAL_VIDEO',
    payload: true
  });




 */
  




   
  
  
  