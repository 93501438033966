import React               from 'react';
import Iframe              from '../VideoIframe';
import { contentInfoType } from '../../types';

import { colors }          from '../../utils/colors';
import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    container: {
        position:      'absolute',
        top:           70, 
        right:         0,
        backgroundColor: colors.brightGrey
    },
    image: {
      width:              270,
      position:           'absolute',
      left:               '50%',
      top:                '50%',
      transform:          'translate(-50%, -50%)',
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
     // border: '1px solid #000'
    },
    description: {
      position:       'absolute',
      overflowY:      'scroll',
      overflowX:      'hidden',
      paddingLeft:    30,
      paddingRight:   30,
      left:           0,
      backgroundColor: '#FFF',
      lineHeight:     2,
      letterSpacing:  2,
      borderTop:     '1px solid #CCC',
      borderBottom:  '1px solid #CCC',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
      }
    }
}))

type ContainerProps = {
    app:                 contentInfoType;
    size:                number[];
    containerHeight:     number;
    descriptionHeight:   number;
    width:               number;
    description:         string;
    showDescription:     boolean;
    wideScreen:          boolean;
}
const Container: React.FC<ContainerProps> = ({ 
    app,
    size,
    containerHeight,
    descriptionHeight,
    width,
    description,
    showDescription,
    wideScreen,
}) => {

  const { classes } = useStyles();

  
  return (
   
    <div 
       className = { classes.container }
       style     = {{
         width:  width,
         height: containerHeight,
       }}
    >
          <Iframe 
             width  = { width }
             height = { containerHeight }
             src    = { `https://www.youtube.com/embed/${ !!app &&!!app.youtube ? app.youtube : '' }?rel=0` }
          />
          {
            !wideScreen && showDescription
            &&
            <div
               className = { classes.description }
               style     = {{
                  top:    containerHeight,
                  width:  width - 60,
                  height: descriptionHeight
               }}
             >
                { description }
             </div>
          } 
    </div>
  );
}




export default Container;

