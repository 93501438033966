import React                  from 'react';
import { Link }               from "react-router-dom";
import TopBar                 from './TopBar';
import ImageContainer         from './ImageContainer';
import { contentInfoType }    from '../../../types';
import { makeStyles }         from 'makeStyles';
import { colors }             from '../../../utils/colors';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      height:          320,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-evenly',
      flexFlow:        'column',
      transition:      'opacity 0.2s',
    },
    inner: {
      height:          290,
      width:           270,
      borderRadius:    '0.4em',
      border:          `1px solid #E5E5E5`,
      transition:      'height 0.2s, width 0.2s, box-shadow 0.2s',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      flexFlow:        'column',
      backgroundColor: '#FFF',
      overflow:        'hidden',
      boxShadow:       '0px 3px 2px rgba(0,0,0,0.07)',
      cursor:          'pointer',
      textDecoration:  'none',
      '&:hover': {
        height:    315,
        width:     295,
        boxShadow: '0px 8px 7px rgba(0,0,0,0.04)',
      }
    },
    image: {
      height:             30,
      width:              30,
      backgroundRepeat:   'no-repeat',
      backgroundPosition: 'center',
      backgroundSize:     'contain',
    },
    link: {
      textDecoration: 'none',
      color:          colors.darkText
    }
}))

type UnitProps = {
  gridOpacity: number;
  lowerTT:     boolean;
  val:         contentInfoType;
  handleClick: (val: contentInfoType) => void;

}

const Unit: React.FC<UnitProps> = ({
  gridOpacity,
  lowerTT,
  val,
  handleClick
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos]   = React.useState(false)

  React.useEffect(() => {

    setTimeout(() => {
      setInitialPos(true)
    }, 10)
  },[])


  const path = React.useMemo(() => {
    if (!!val.origin && val.origin === 'puzzles' && !!val.page) {
      return `/${ val.page }${ val.extra_url ? val.extra_url : '' }`;
    }
    if ( !!val.sub_topic ) {
      let path = val.sub_topic.replaceAll(' ','_');
      if (path[path.length - 1] === '?') {
        path = path.slice(0, path.length - 1);
      }
      return `/${ path }`
    } 
    return '/'
  },[val])


  return (
    <div 
      dir       = 'rtl'
      className = { classes.root }
      style     = {{
        opacity: initialPos ? gridOpacity : 0
      }}
    >
        <Link 
          // replace
           className = { classes.link }
           to        = { path }
           onClick   = { () => { handleClick(val) }}
        >
           <div className = { classes.inner }>
              <TopBar 
                 val     = { val }
                 lowerTT = { lowerTT }
              />
              <ImageContainer val = { val }/>
           </div>
        </Link>
    </div>
  );
}


export default Unit;



