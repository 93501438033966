import React                from 'react';
import { makeStyles }       from 'makeStyles';
import ArrowForwardIosIcon  from '@mui/icons-material/ArrowForwardIos';
import { colors }           from 'utils/colors';

var clsx = require('classnames')

const useStyles = makeStyles()(
  (Theme) => ({    button: {
            color:           colors.primary,
            height:          40,
            display:        'flex',
            alignItems:     'center',
            justifyContent: 'center',
            borderRadius:    '0.4em',
            textAlign:       'center',
            fontSize:        17,
            fontWeight:     'bold',
            border:         `1px solid ${ colors.primary }`,
            transition:     'opacity 0.4s',
            cursor:          'pointer',
            '&:hover':{
                color:          '#FFF',
                backgroundColor: colors.primary,
            } 
     },
       /*  height:          45,
        width:           180,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-evenly',
        textAlign:       'center',
        border:          `1px solid ${ colors.primary }`,
        borderRadius:    '0.4em',
        fontWeight:      'bold',
        fontSize:        18,
        cursor:          'pointer',
        backgroundColor: colors.primary,
        boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
        color:           '#FFF',
        '&:hover':{
            backgroundColor: '#FFF',
            color:           colors.primary,
        } 
    },*/
    disable: {
        opacity: 0.4,
        cursor:  'default',
        '&:hover':{
            color:           colors.primary,
            backgroundColor: '#FFF',
        }
    },
    small: {
        
    },
    arrow: {
        flex:        1,
        marginLeft:  5,
        marginRight: 5,
        fontSize:    19,
        minWidth:    19,
    },
    rotate: {
        flex:        1,
        transform:   'rotate(180deg)',
        marginLeft:  5,
        marginRight: 5,
        fontSize:    19,
        minWidth:    19,
    },
    link: {
        textDecoration: 'none',
        color:           colors.primary,
    }
}))

type BottomBarProps = {
    text:        string;
    disable:     boolean;
    wideScreen:  boolean;
    buttonStyle: { [key: string]: any };
    action:      () => void;
}

const BottomBar: React.FC<BottomBarProps> = ({ 
    text,
    disable,
    wideScreen,
    buttonStyle,
    action
}) => {

  const { classes } = useStyles();
  
  const [next, prev, style] = React.useMemo(() => { 
      return [
          (text === 'next' || text === 'continue'), 
          text === 'previous',
          { position: wideScreen ? 'absolute' : 'static' as 'absolute' | 'static' }
        ] 
 },[text, wideScreen])

  return (
    
        <div
           className = { !disable ? classes.button : clsx( classes.button, classes.disable )  }
           style     = { buttonStyle } 
           onClick   = { action }
           dir       = 'ltr'
        >
            { next && <div style = {{ flex: 1 }}/> }
            { prev && <ArrowForwardIosIcon className = { classes.rotate }/> }
            <div style = { style } >{ text }</div>
            { next && <ArrowForwardIosIcon className = { classes.arrow }/> }
            { prev && <div style = {{ flex: 1 }}/> }
        </div>   
  );
}

export default BottomBar;
