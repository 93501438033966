import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import { SET_MODAL_STATE } from 'actions/modal_actions';
import { SET_FOLLOW_LINK } from 'actions';

const endpoint = 'https://www.yisumatica.org.il/yisumaticaApi/joinLesson.php';

function* joinLesson(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('JOIN_LESSON_SAGA');
        yield put(SET_MODAL_STATE({ variant: 'followLinkNotFound', open: true, showButtons: true })); 


        if (process.env.NODE_ENV === 'development') {
          yield put(SET_FOLLOW_LINK('test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123test123'))
         // yield put(SET_MODAL_STATE({ variant: 'showFollowLink', open: true, showButtons: true })); 
          yield put(SET_MODAL_STATE({ variant: 'followLinkNotFound', open: true, showButtons: true })); 

        }
        if (process.env.NODE_ENV === 'production') {

            yield put(SET_MODAL_STATE({ variant: 'loader', open: true, showButtons: true })); 

            const res = yield call( axios.post, endpoint, { lessonNum: +input.payload });        
            if (!!res && !!res.data && !!res.data.result && !!res.data.result[0]) {

              const followLink = res.data.result[0].following_link;
              const arr        = followLink.split(',').map((val: string) => { return +val })
              const link__     = String.fromCharCode.apply(null, arr)
              const link       = link__.includes('lang=eng') ? link__ : link__.includes('lang=he') ? link__.replace('lang=he', 'lang=eng') : link__.replace('lang=en', 'lang=eng')
              
              yield put(SET_FOLLOW_LINK(link))
              yield put(SET_MODAL_STATE({ variant: 'showFollowLink', open: true, showButtons: true })); 
            }
            else {
              yield put(SET_MODAL_STATE({ variant: 'followLinkNotFound', open: true, showButtons: true })); 
            }
        }
        
    }
    catch(err) {
    //  yield put(CREATE_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default joinLesson;











