import React               from 'react';
//import Timer               from './Timer';
import Buttons             from './Buttons';
import ResultIcon          from './success_animation';
import { makeStyles }      from 'makeStyles';
import { colors }          from '../../utils/colors';
import { contentInfoType } from '../../types';

const initialPos = true;

const useStyles = makeStyles()((Theme) => ({
   root: {
      position:        'fixed',
      top:             0,
      left:            0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'flex-start',
      flexFlow:        'column',
     // borderRight:     `1px solid #DADADA`,
      borderRight:     `1px solid #CCC`,
      backgroundColor: colors.brightGrey,
      transition:      'transform 0.4s'
      //boxShadow:       '0px 8px 7px rgba(0,0,0,0.1)'  
   },
   hide: {
   },
   name: {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      height:          55,
      width:           '100%',
      color:           colors.primary,
      fontSize:        21,
      fontWeight:      'bold',
      textAlign:       'center',
      borderBottom:    '1px solid #D0D0D0'
   },
   nameInner: {
      width: '90%',
   },
   goBackButton: {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      marginTop:       10,
      height:          40,
      width:           '100%',
      color:           colors.primary,
      fontSize:        17,
      fontWeight:      'bold',
      textAlign:       'center',
      cursor:          'pointer',
      backgroundColor: '#FFF',
      borderRadius:    '0.4em',
      border:          `1px solid ${ colors.primary }`,
      '&:hover': {
         backgroundColor: colors.primary,
         color:           '#FFF',
      }
    },
    resultIconContainer: {
      
      marginBottom: 15,
   },
    scoreContainer: {
        marginTop:       10,
        height:          70,
        color:           colors.primary,
        display:         'flex',
        alignItems:      'flex-start',
        justifyContent:  'space-evenly',
        flexFlow:        'column',
        borderRadius:    '1em',
        textAlign:       'center',
        backgroundColor: 'transparent',
        fontSize:        17,
        letterSpacing:   0.8,
        transition:      'opacity 1s',
    },
    score: {
      fontWeight: 'bold'
    },
    link: {
      textDecoration: 'none',
      color:           colors.primary,
    }
}))

type ActionBarProps = {
  size:            number[];
  menuSize:        number;
  name:            string;
  challengeState:  contentInfoType;
  checkOrContinue: 'check' | 'continue';
  wideScreen:      boolean;
  disableContinue: boolean;
  disableSkip:     boolean;
  resetTimer:      boolean;
  activateTimer:   boolean;
  correctAnswer?:   boolean;
  handleContinue:  () => void;
  handleSkip:      () => void;
  handleExit:      () => void;
  setResetTimer:   (val: boolean) => void;
  setTime:         (val: number) => void;

  testNum:           number;
  variant:           string;
  text:              string;
  success:           string;
  enableCheckAnswer: boolean;
  prevTest:          () => void;
  nextTest:          () => void;
  goToPuzzle:        () => void;
  checkAnswer:       () => void;
  setOpenModal:      (val: boolean) => void;
}

const ActionBar: React.FC<ActionBarProps> = ({ 
   size, 
   menuSize,
   name,
   challengeState,
   disableContinue,
   wideScreen,
   disableSkip,
   resetTimer,
   activateTimer,
   checkOrContinue,
   correctAnswer,
   handleSkip,
   handleExit,
   handleContinue,
   setResetTimer,
   setTime,

   testNum,
   variant,
   text,
   success,
   enableCheckAnswer,
   prevTest,
   nextTest,
   goToPuzzle,
   checkAnswer,
   setOpenModal
}) => {

  const { classes } = useStyles();
/* 
  const [initialPos, setInitialPos] = React.useState(false);

  React.useEffect(() => {
     let unmounted = false;
     if (!unmounted) {
        setTimeout(() => {
         setInitialPos(true)
        },100)
     }
     return () => { unmounted = true } 
  },[])
 */

  const [rootStyle, buttonStyle, /* scoreStyle,  */compSize, iconContainerStyle, nameStyle ] = React.useMemo(() => {

     const iconSize = wideScreen ? 140 : 60;

     return [ 
         {
           transform: wideScreen ? `translate(${ !initialPos ? -200 : 0 }vw, 0px)` : undefined,
           width:     wideScreen ? menuSize : 0,
           minWidth:  wideScreen ? menuSize : 0,
           height:    wideScreen ? '100vh' : 0,
         },
         {
            width: menuSize - 60
         },
         /* {
           width: menuSize - 60
         }, */
         iconSize,
         {
            position:   wideScreen ? 'static' : 'absolute' as 'static' | 'absolute',
            height:     iconSize,
            width:      iconSize,
            left:       wideScreen ? undefined : 20,
            top:        wideScreen ? undefined : variant === 'challenge' ? 80 : -1000,
            marginTop:  wideScreen ? 15 : 0,
         },
         {
            height: name.length > 35 ? 100 : undefined
         }
      ]
  },[wideScreen, menuSize, /* initialPos, */ name, variant])


  return (
    <div 
       className = { wideScreen ? classes.root : classes.hide }
       style     = { rootStyle }
    >
         {
            wideScreen &&
            <div 
              className = { classes.name }
              style     = { nameStyle }
            >
               <div className = { classes.nameInner }>
                  { name }
               </div>
            </div>
         }
        {/*  {
            variant === 'challenge' &&
            <>
               <Timer
                  size          = { size }
                  wideScreen    = { wideScreen }
                  menuSize      = { menuSize }
                  reset         = { resetTimer }
                  active        = { activateTimer }
                  setResetTimer = { setResetTimer }
                  setTime       = { setTime }
               />
            </>
         }
         {
            variant === 'challeng' && wideScreen &&
            <div
               className = { classes.scoreContainer }
               style     = { scoreStyle }
            >
               <div className = { classes.score }>
                  score: { challengeState.score }
               </div>
               <div className = { classes.score } >
                  time bonus: { challengeState.timeBonus }
               </div>
            </div>
         } */}
         <Buttons
               menuSize          = { menuSize }
               wideScreen        = { wideScreen }
               disableContinue   = { disableContinue }
               disableSkip       = { disableSkip }
               checkOrContinue   = { checkOrContinue }
               handleSkip        = { handleSkip }
               handleContinue    = { handleContinue }
              // text              = { buttonText }
               variant           = { variant }
               text              = { text }
               testNum           = { testNum }
               enableCheckAnswer = { enableCheckAnswer }
               success           = { success }
               checkAnswer       = { checkAnswer }
               nextTest          = { nextTest }
               prevTest          = { prevTest }
               goToPuzzle        = { goToPuzzle }
               setOpenModal      = { setOpenModal }
         />
         {
         wideScreen &&
            <div 
                  className = { classes.goBackButton } 
                  style     = { buttonStyle }
                  onClick   = { handleExit }
            >
               exit
            </div>
         }
         {
            variant === 'challenge' && wideScreen &&
            <div 
               className = { classes.resultIconContainer } 
               style     = { iconContainerStyle }
            >
               <ResultIcon
                  speed        = { 1 }
                  compSize     = { compSize }
                  hide         = { success === '' }
                  variant      = { success === 'true' ? 'success' : success === 'false' ? 'failure' : '' }
               />
            </div>
         }
         {
            variant === 'practice' && wideScreen &&
            <div 
               className = { classes.resultIconContainer } 
               style     = { iconContainerStyle }
            >
               <ResultIcon
                  speed        = { 1 }
                  compSize     = { compSize }
                  hide         = { success === '' }
                  variant      = { success === 'true' ? 'success' : success === 'false' ? 'failure' : '' }
               />
            </div>
         }
         
         {
         
}
     </div>
  );
}

export default ActionBar;
