import { store }                from '../../index';
import { SET_COMPONENTS_STATE } from '../../actions/component_actions'
import { SELECT_CONTENT } from 'actions/content_actions';

export const topicClick = ( 
    variant:             string, 
    val:                 string,
    setChangeContent:    ( val: boolean ) => void
) => {

    const state = store.getState();
    const compoentsState = state.components;

    const resetSelectedApp = () => {
      store.dispatch(SELECT_CONTENT({
        selectedAppIntro: '',
        selectedApp:      {},
        selectedPuzzle:   {},
      }))
    }

    if (variant === 'topic') {

        store.dispatch(SET_COMPONENTS_STATE({
          ...compoentsState,
          selectedAppTopic:        val,
          selectedAppSubTopic:     '',
          searchStr:               '',
          openGradeGrid:           false,
          showTopicIntro:          false,
          showTutorial:            false,
          createTopicArr:          false,
          showAppIntro:            false,
          showMenu:                true
        }))

        setChangeContent(true)
        resetSelectedApp()
    }

    if (variant === 'subTopic') {

        store.dispatch(SET_COMPONENTS_STATE({
          ...compoentsState,
          selectedAppSubTopic: val === 'All Topics' ? '' : val,
          openGradeGrid:       false,
          createTopicArr:      false,
          showTutorial:        false,
          showSmallMenu:       false,
          showAppIntro:        false,
          showMenu:            true
        }))

       if (compoentsState.selectedContent === 'Practice') {
          store.dispatch(SELECT_CONTENT({
            selectedAppIntro: val
          }))
       }

       else {
        resetSelectedApp();
       }

       setChangeContent(true)
    }
 }