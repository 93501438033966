import React                from 'react';
import audioClick           from './functions/audio_click';
import { handlePause }      from 'components/audio/functions';
import { colors }           from 'utils/colors';
import { makeStyles }       from 'makeStyles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon     from '@mui/icons-material/PauseRounded';


const useStyles = makeStyles()(
  (Theme) => ({      
      audio: {
        marginRight: 5,
        cursor:      'pointer',
        '&:hover': {
          color: colors.primary
        }
      }
}))

type AudioButtonProps = {
  AudioRef:   any;
  playState:   string;
  selected:   boolean;
  disable:    boolean;
}

const AudioButton: React.FC<AudioButtonProps> = ({
  AudioRef,
  playState,
  selected,
  disable
}) => {

  const { classes } = useStyles();



  const [audioStyle] = React.useMemo(() => {

    return [
        {
          color:    disable ? colors.brightText : selected ? colors.blueText : undefined,
          fontSize: 17,
        }
    ]
  },[selected, disable])

  return (
    <>
        {
          playState === 'play' &&
           <PlayArrowRoundedIcon 
             className = { classes.audio }
             style     = { audioStyle }
             onClick   = { audioClick }
           />
        }
        {
          playState === 'pause' &&
           <PauseRoundedIcon 
             className = { classes.audio }
             style     = { audioStyle }
             onClick   = { () => handlePause(AudioRef) } 
          />
        }
    </>
  );
}



/* 

const mapStateToProps = (reducer: reducerType) => {
  return {
  }};
 
const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
}); 

export default connect(mapStateToProps , mapDispatchToProps )(AudioButton); */
export default AudioButton;










