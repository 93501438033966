import { contentInfoType } from "../../../types"

const setNumberValues = (
    numValuesSet:     boolean,
    questionObj:      contentInfoType,
    numerator:        string,
    combined:         boolean,
    fraction:         boolean,
    setNumValuesSet:  (val: boolean) => void,
    setInteger:       (val: boolean) => void,
    setCombined:      (val: boolean) => void,
    setFraction:      (val: boolean) => void,
    setNumber:        (val: string) => void,
    setNumerator:     (val: string) => void,
    setDenominator:   (val: string) => void,
    setMaxLength:     (val: number) => void,
    setCorrectAnswer: (val: string) => void,
) => {

    if ( numValuesSet ) {
      return;
    }

    setNumValuesSet(true)


    const question_type  = !!questionObj.question_type ? questionObj.question_type : '';
    const correct_answer = !!questionObj.correct_answer ? questionObj.correct_answer : '';

    setCorrectAnswer(correct_answer);
        
    if ( question_type !== "N" ) {
        setInteger(false)
        setCombined(false)
        setFraction(false)
        return;
     }
 
     const str = correct_answer;
 
     if ( str.includes(' ') ) {
          if (!combined) {
            setInteger(false)
            setCombined(true)
            setFraction(false)
          }
          if (!numerator.length) {
 
            const int = str.slice(0, str.indexOf(' '));
            const num = str.slice(str.indexOf(' ')+1, str.indexOf('/'));
            const den = str.slice(str.indexOf('/')+1, str.length);
 
            setNumber(int);
            setNumerator(num);
            setDenominator(den);
            setMaxLength(int.length);
          }
     } 
     else if ( str.includes('/') ) {
          if (!fraction) {
            setInteger(false)
            setCombined(false)
            setFraction(true)
          }
          if (!numerator.length) {
 
            const num = str.slice(0, str.indexOf('/'));
            const den = str.slice(str.indexOf('/')+1, str.length);
            setNumerator(num);
            setDenominator(den);
          }
     }
 
     else {
       setNumber(str);
       setInteger(true)
       setCombined(false)
       setFraction(false)
       setMaxLength(correct_answer.length)
     }
 
}

export default setNumberValues