import React              from 'react';
import { makeStyles }     from 'makeStyles';

const H = 2.83;
//const H = 3;
const W = 4;

const useStyles = makeStyles()((Theme) => ({
    root: {
      width:           '100%',
      height:          '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      transition:      'opacity 1s',
    }
}))

type IframeProps = {
    width:  number;
    height: number;
    src:    string;
}

const Iframe: React.FC<IframeProps> = ({ width, height, src }) => {

  const { classes } = useStyles();

  const [opacity, setOpacity] = React.useState(0)

  React.useEffect(() => {
      const timeOut = setTimeout(() => {
        setOpacity(1)
      },50)
      return () => { clearTimeout(timeOut) }
  },[])

  const YTStyle = React.useMemo(() => {
  
    const bigWidth  = height * W / H;
    const bigHeight = width * H / W;

    if ( bigWidth > width ) {
      return {
        width:   width,
        height:  bigHeight,
      }
    }

    if ( bigWidth <= width ) {
      return {
        width:   bigWidth,
        height:  height,
      }
    }

    return {
      height: '100%',
      width:  '100%',
    }
    
  },[ width, height ])

  return (
      <div 
        className = { classes.root }
        style     = {{
          opacity: opacity
        }}
      >
        <iframe 
            title           = "I See Math"
            width           = { YTStyle.width }
            height          = { YTStyle.height }
            src             = { src }
            frameBorder     = '0'
            allow-scripts   = 'true'
            allowFullScreen
        />
    </div>
       
  );
}

export default Iframe;
