import { store }           from 'index';
import { modalStateType }  from 'actions/types';
import { authStateType }   from 'reducer/types';
import { SET_AUTH_STATE }  from 'actions/auth_actions';
import { SET_MODAL_STATE } from 'actions/modal_actions'
import recordEvent         from 'functions/contentInteraction/record_event'


const closeModal = ( subscriptionStatus?: string ) => {

const state            = store.getState();
const variant          = state.modal.modalVariant;
const authState        = state.auth;
const setAuthState     = (payload: authStateType) => store.dispatch(SET_AUTH_STATE(payload));
const setModalState    = (payload: modalStateType) => store.dispatch(SET_MODAL_STATE(payload));

 recordEvent('modal', `${ variant } modal closed`, 'close modal')

 setModalState({
    variant:     variant,
    open:        false,
    showButtons: true
 })
 setAuthState({
   ...authState,
   name:       '',
   email:      '',
   code:       '',
   grade:      '',
   message:    '',
   userOrigin: '',
   error:      ''
 })
 
}

export default closeModal;