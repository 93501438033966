import { call, take, put }               from 'redux-saga/effects';
import axios                             from 'axios';
import { RECORD_ERROR }                  from '../actions'; 
import { SET_MODAL_STATE, CREATE_ERROR } from 'actions/modal_actions'

const dev = process.env.NODE_ENV === 'development';
const liveEndpoint    = 'https://iseemath.co/api/deleteSubscription.php';
const sandBoxEndpoint = 'https://iseemath.co/api/deleteSubscriptionSandBox.php';


function* deleteSubscription(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('UNSUBSCRIBE_SAGA');

        console.log('userInput  :  ',userInput)

        yield put(SET_MODAL_STATE({ variant: 'loader', open: true, showButtons: false }));  

        const { payload } = userInput;
        const { tester } = payload;

        console.log('tester  :   ',tester)

        if ( dev ) {
          window.location.reload();
        }

        const endpoint = tester ? sandBoxEndpoint : liveEndpoint;

        const res = yield call( axios.post, endpoint, payload );


        console.log('res       :   ',res)
        console.log('res.data  :   ',res.data)

 
        if (res && res.data && res.data.success && res.data.success === 1 ) {
           window.location.reload();
        }
        else {
          yield put(CREATE_ERROR());
          if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
            const error  = res.data.Error ? res.data.Error : '';
            const result = res.data.result ? res.data.result : '';
            yield put(RECORD_ERROR({ userEmail: payload.userEmail, err: `msg: ${ res.data.msg }, error: ${ error }, result: ${ result }`, endpoint: 'unsubscribe' }));
          }
        }
    }
    catch(err) {
      yield put(CREATE_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default deleteSubscription;


