import React             from 'react';
import { Auth }          from 'aws-amplify';
import PasswordInput     from '../PasswordInput';
import TextField         from '@mui/material/TextField';
import useTextFieldStyle from 'components/authenticator/functions/get_textfield_style';
import recordEvent       from 'functions/contentInteraction/record_event';
import { makeStyles }    from 'makeStyles';
import { colors }        from 'utils/colors';



const useStyles = makeStyles()((Theme) => ({  
  container: {
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',

  },
  logo: {
    width:              200,
    height:             65,
    backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
 },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    marginTop:      10
  },
  text_field: {
    height:          45,
    fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
    transform:      'translate(0px, -20px)'
  },
  signup: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    marginTop:       40,
  //  fontSize:        20,
    color:           colors.darkText,
    width:           200,
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    height:          40,
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
  },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        18,
    fontFamily:     'Fredoka One',
  },
  login: {
    color:     colors.darkText,
    marginTop: 35,
    cursor:    'pointer'
  },
  forgot_pwd: {
     cursor: 'pointer',
     color:  colors.darkText,
     margin: 20
  },
  loader_container: {
    width:  60,
    height: 60
 },
  err: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         45,
    color:          '#F00'
  },
  txt: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    textAlign:      'center',
    height:         30,
    color:          colors.darkText,
  },
  resend_code: {
    marginTop:      35,
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         45,
    color:          colors.darkText,
    cursor:         'pointer'
  },
  iseemath: {
    marginTop:  5,
    fontSize:   18,
    fontWeight: 'bold'
  }
}));


type ReacoverPwd2Props = {
  size:           number[];
  email:          string;
  code:           string;
  password:       string;
  error:          string;
  showLogo:       boolean;
  setError:       (val: string) => void;
  setCode:        (val: string) => void;
  setPassword:    (val: string) => void;
  setDisplayForm: (val: string) => void;
  setShowLoader:  (val: boolean) => void;
}

const RecoverPwd2: React.FC<ReacoverPwd2Props> = ({
   size,
   email,
   code,
   password,
   error,
   showLogo,
   setError,
   setCode,
   setPassword,
   setDisplayForm,
   setShowLoader
}) => { 

  const { classes } = useStyles();

  const textFieldStyle = useTextFieldStyle(size)

  const changeInputValue = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;

    if (target.id === 'code')     { setCode(target.value) }
    if (target.id === 'password') { setPassword(target.value) }

  }/* 

  const resendCode = async() => {
     
     try {
      await Auth.resendSignUp(email);
      setResendText('A new code has been sent') 
    }
    catch(err) {
      console.log(err)
    }
  } */

 
  const handleSubmit = async() => {

    setShowLoader(true);

    try {
      setError('')
      await Auth.forgotPasswordSubmit(email, code, password) 
      setShowLoader(false);
      setDisplayForm('recover_pwd_part3')
      recordEvent('registration', `recoverPwrd2 success`, 'recoverPwrd2')

    }
    catch(err: any) {
      setShowLoader(false)

      const _error = err.message || err;

      setError(_error || '')
      console.error("error occured in recover_pwd_2 handleSubmit: ",_error);
      recordEvent('registration', `recoverPwrd2 error : ${ _error }`, 'recoverPwrd2 error')

    }
    finally {}
  }

  return(
    <div className = { classes.container }>
          { showLogo && <div className = { classes.logo }/> }
          { error.length > 0 && <div className = { classes.err }>{ error }</div> }
          <div className = { classes.txt }>{ `An authentication code as been sent to` }</div>
          <div className = { classes.txt }>{ email }</div>
          <div className = { classes.txt }>{ `Note - If you can't find your authentication code in your inbox, it might have been transferred to your spam box` }</div>
          <form className = { classes.form }>
            <TextField
                className    = { classes.text_field }
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "code"
                label        = "Your Code"
                inputProps   = {{
                  autocomplete: 'code',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange     = { changeInputValue }
            />
            <PasswordInput
                 password         = { password }
                 showHelperText   = { true }
                 textFieldStyle   = { textFieldStyle }
                 changeInputValue = { changeInputValue }
            />
          </form>
          <div onClick = { handleSubmit } className = { classes.signup }>
             Continue
          </div>
      </div>
    )
  }

export default RecoverPwd2




