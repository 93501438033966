import React           from 'react';
import Mishehu         from './Mishehu';
import { makeStyles }  from 'makeStyles';

const useStyles = makeStyles()(() => ({    
    root: {
        display:        'flex',
        flexFlow:       'column',
        alignItems:     'center',
        justifyContent: 'center',
        width:          '70vw',
        marginLeft:     '15vw',
        paddingTop:     35,
        paddingBottom:  35,
    }
}))


type AboutProps = {
    wideScreen: boolean;
}
 
const About: React.FC<AboutProps> = ({
    wideScreen,
  }) => {


  const { classes } = useStyles();

  const rootStyle = React.useMemo(() => {
    return { 
       width:        wideScreen ? '70vw' : '96vw',
       marginLeft:   wideScreen ? '15vw' : '2vw',
     //  paddingRight: wideScreen ? undefined : 5,
     //  paddingLeft:  wideScreen ? undefined : 5,
    }
  },[wideScreen])


  return (
        <div 
           className = { classes.root }
           style     = { rootStyle }
        >  
            {
                ['gali', 'zvi', 'avi'].map((val) => {
                    return(
                        <Mishehu
                          key        = { `Mishehu${ val }` }
                          variant    = { val }
                          wideScreen = { wideScreen }
                        />
                    )
                })
            }
        </div> 
  );
}


export default About;
