import React           from 'react';
import ActionButton    from './Button';
import { makeStyles }  from 'makeStyles';
import { colors }      from '../../utils/colors';


const useStyles = makeStyles()((Theme) => ({
    root: {
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
    },
    button: {
        color:           colors.primary,
        maxWidth:        '40vw',
        height:          40,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        borderRadius:    '0.4em',
        textAlign:       'center',
        fontSize:        17,
        fontWeight:     'bold',
        border:         `1px solid ${ colors.primary }`,
        transition:     'opacity 0.4s'
    },
}))

type ButtonsProps = {
  menuSize:        number;
  checkOrContinue: 'check' | 'continue';
  disableContinue: boolean;
  disableSkip:     boolean;
  wideScreen:      boolean;
  handleContinue:  () => void;
  handleSkip:      () => void;

  testNum:           number;
  variant:           string;
  text:              string;
  success:           string;
  enableCheckAnswer: boolean;
  prevTest:          () => void;
  nextTest:          () => void;
  goToPuzzle:        () => void;
  checkAnswer:       () => void;
  setOpenModal:      (val: boolean) => void;
  
}

const Buttons: React.FC<ButtonsProps> = ({
   menuSize,
   disableContinue,
   disableSkip,
   checkOrContinue, 
   wideScreen,
   handleContinue,
   handleSkip,

    variant, 
    text,      
    enableCheckAnswer,
    testNum,   
    success,
    checkAnswer,
    prevTest,
    nextTest, 
    goToPuzzle, 
    setOpenModal 
}) => {

  const { classes } = useStyles();





  const nextClick = React.useCallback(() => {


   if (text === 'start puzzle') {
      return
   }

   if ( text !== 'continue' && variant !== 'practice' ){ 
       setOpenModal(true) 
   } 
   else { 
       nextTest()
   }        
 },[text, variant, setOpenModal, nextTest])



 const actionButtons = React.useMemo(() => {

     const checkAnswerButton = {
       text:    'check answer',
       disable: !enableCheckAnswer,
       action:  checkAnswer
     }

     const puzzleArr = [
        {
           text:    'next',
           disable: text === 'start puzzle',
           action:  nextClick
        },
        {
           text:    'start puzzle',
           action:  goToPuzzle
        },
        {
          text:    'previous',
          disable: testNum === 0,
          action:  prevTest
      },
        
     ]

     const practiceArr = [
       {
          text:    success === '' ? 'skip' : 'continue',
          action:  nextClick
       }
     ]

     if ( success === '' ){
       practiceArr.unshift(checkAnswerButton)
     }

   return variant === 'puzzle' ? puzzleArr : practiceArr


 },[variant, success, testNum, text, enableCheckAnswer, nextClick, checkAnswer, prevTest, goToPuzzle])









  const[hover, setHover] = React.useState(0)

  const [rootStyle, buttonStyle, skipStyle, checkStyle] = React.useMemo(() => {

     const btnStyle = {
      marginTop:   wideScreen ? 10 : 0,
      marginRight: wideScreen ? 0 : 8,
      marginLeft:  wideScreen ? 0 : 8,
      width:       wideScreen ? menuSize - 60 : 240,
      maxWidth:    wideScreen ? undefined : actionButtons.length === 3 ? '30vw' : '40vw',
     }

     return[
        {
         flexFlow: wideScreen ? 'column' : 'row',
         position: wideScreen ? 'static' : 'fixed' as 'static' | 'fixed',
         width:    wideScreen ? '100%' : '98vw',
         maxWidh:  '98vw',
         height:   wideScreen ? undefined : 100,
         bottom:   wideScreen ? undefined : 0,
         left:     wideScreen ? undefined : '1vw',
        },
        btnStyle,
        {
         ...btnStyle,
         opacity:         disableSkip ? 0.4 : 1,
         color:           !disableSkip && hover === 1 ? '#FFF' : colors.primary,
         backgroundColor: !disableSkip && hover === 1 ? colors.primary : '#FFF',
         cursor:          !disableSkip && hover === 1 ? 'pointer' : 'default'
        },
        {
         ...btnStyle,
         opacity:         disableContinue ? 0.4 : 1,
         color:           !disableContinue && hover === 2 ? '#FFF' : colors.primary,
         backgroundColor: !disableContinue && hover === 2 ? colors.primary : '#FFF',
         cursor:          !disableContinue && hover === 2 ? 'pointer' : 'default'
        }
     ]
  },[wideScreen, menuSize, disableSkip, disableContinue, hover, actionButtons])


  return (
      <>
         {
           variant === 'challenge'
           &&
           <div 
               className = { classes.root }
               style     = { rootStyle }
            >
               <div 
                  className    = { classes.button }
                  onMouseEnter = { () => { if(!disableSkip) { setHover(1) }}}
                  onMouseLeave = { () => { if(!disableSkip) { setHover(0) }}}
                  onClick      = { () => { if(!disableSkip) { handleSkip() }}}
                  style        = { skipStyle }
               >
                  skip
               </div>
               <div 
                  className    = { classes.button }
                  onClick      = { () => { if(!disableContinue) { handleContinue() }}}
                  onMouseEnter = { () => { if(!disableContinue) { setHover(2) }}}
                  onMouseLeave = { () => { if(!disableContinue) { setHover(0) }}}
                  style        = { checkStyle }
               >
                  { checkOrContinue === 'check' ? 'check answer' : 'continue' }
               </div>
            </div>
        }
        {
           variant !== 'challenge'
           &&
           <div 
              className = { classes.root }
              style     = { rootStyle }
              dir       = { wideScreen ? 'ltr' : 'rtl' }
           >
              {
               actionButtons.map((val: { text: string; disable?: boolean; action: () => void; }) => {
                     return(
                        <ActionButton
                           key         = { `${ val.text }actionButton` }
                           text        = { val.text }
                           buttonStyle = { buttonStyle }
                           wideScreen  = { wideScreen }
                           disable     = { !!val.disable }
                           action      = { val.action }
                        />
                     )
                  })
               }
            </div>
        }
    </>
  );
}

export default Buttons;
