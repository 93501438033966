import { useMemo }             from 'react';
import { componentsStateType } from 'reducer/types';
import { contentInfoType } from 'types';


const useShowGrid = ( 
    componentsState: componentsStateType, 
    showLoader:      boolean,
    path:            string,
    gridArr:         contentInfoType[]
) => {

 
  const [showGrid, showAppIntro, displayLoader] = useMemo(() => {

    const { showAppIntro, selectedContent } = componentsState;
    
    const allowEmptyGrid = ['userLessons', 'favorites', 'myStudentsContent'].includes(selectedContent)

    let grid     = ( !showLoader && !showAppIntro && !!gridArr.length );
    let appIntro = ( showAppIntro && !showLoader && path !== '/' );
    let loader   = ( !allowEmptyGrid && (showLoader || (!grid && !appIntro) ))

    return [grid, appIntro, loader]

  },[showLoader, componentsState, path, gridArr ]) 

  return { showGrid, showAppIntro, displayLoader }
 }

  export default useShowGrid;
