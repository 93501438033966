import { store }           from 'index';
import { SET_AUDIO_STATE } from 'actions/audio_actions';
import recordEvent         from 'functions/contentInteraction/record_event';
import { contentInfoType } from 'types';

const menuButtonClick = (
    variant:         string,
    disable:         boolean,
    navigate:        any,
    location:        any,
    names:           { [key:string]: any; },
    handleOpenModal: (val: string) => void,
    setOpenItem:     (val: string) => void,
    handleMenuClick: (variant: string, val: string, disabled: boolean) => void

) => { 

    const setAudioState = (payload: contentInfoType) => store.dispatch(SET_AUDIO_STATE(payload));

    recordEvent('menu button', `menu button clicked`, variant)

    const noPathReplace = ['groupLessons', 'apps', 'puzzles', 'games'];

    if ( variant === 'apps' ){
      navigate('../Apps');
    }
    if ( variant === 'games' ){
      navigate('../Games');
    }

    if ( location.pathname !== '/' && !noPathReplace.includes(variant)) {
        navigate('../', { replace: true } );
    }

    setAudioState({
      playAudio:  false,
      pauseAudio: true,
      audioSrc:   names[`${ variant }`].audio
    })


    if ( variant === 'groupLessons' ) {
         if (disable) {
            handleMenuClick('groups', 'groups', true);
            return;
         }
         handleOpenModal('groups');
         return;
    }

    setOpenItem('noDisplay');
    handleMenuClick(variant, variant, disable)
  }

export default menuButtonClick;