import React                  from 'react';
//import { withAuthenticator  } from 'aws-amplify-react';
import Amplify                from 'aws-amplify';
//import { Auth }               from 'aws-amplify';
import awsconfig              from './aws-exports';
import Loader                 from './components/Loader';
import Root                   from './pages/root';
import getCurrentUser         from './functions/userData/get_current_user';
import getUserGroups          from './functions/auth/get_user_groups';
import { makeStyles }         from 'makeStyles';


const noAuth = false;

const defaultAttr = {
  userName:     'name',
  userEmail:    'email',
  userSignedIn: 'true',
  externalUser: false
}

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost ? 'http://localhost:3000/' : 'https://iseemath.co/',
    redirectSignOut: isLocalhost ? 'http://localhost:3000/' : 'https://iseemath.co/',
  }
}

Amplify.configure(updatedAwsConfig);

const useStyles = makeStyles()((Theme) => ({  
  loaderContainer: {
      minHeight:       '100vh',
      minWidth:        '100vw',
      maxHeight:       '100vh',
      maxWidth:        '100vw',
      backgroundColor: '#FFF',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
  }
}))


const App: React.FC = () => {

  const { classes } = useStyles();

  const [tester, setTester]                 = React.useState('pending');
  const [userAttributes, setUserAttributes] = React.useState({
    userName:     '',
    userEmail:    '',
    userSignedIn: '',
    externalUser: false
  });

  React.useEffect(() => {

    let unmounted = false;

   // getUserGroups();

    const func = async() => {

      let externalUser = false;
      let tester = 'pending';
      const user = await getCurrentUser();
      const groups: string[] = await getUserGroups();

      if (!unmounted) {
        const { userName, userEmail } = user;
        const userSignedIn = userEmail.length > 0 && userEmail !== 'Guest' ? 'true' : 'false';
        if ( userSignedIn === 'true' ) {
          externalUser = groups && groups.includes('us-east-2_RcMqolf7o_Google');
          tester = groups && groups.includes('testers') ? 'true' : 'false';
        }
        if ( userSignedIn === 'false' ) {
          tester = 'false'
        }

        setUserAttributes({
          userName:     userName,
          userEmail:    userEmail,
          userSignedIn: userSignedIn,
          externalUser: externalUser
        })

        setTester(tester)
      }
    }
    func();
  
    return () => { unmounted = true };
  },[])


  if (!noAuth) {
    return (
      <>
        {
          (
            ((tester === 'pending') || (userAttributes.userSignedIn.length === 0))
            && 
            <div className = { classes.loaderContainer }>
               <Loader variant = { 'blue' }/>
            </div>
          )
          ||
          (<Root userAttributes = { userAttributes } tester = { tester }/>)
        }
      </>
   ) 
  }

  return(<Root userAttributes = { defaultAttr } tester = { 'true' }/>)
} 


export default App;


