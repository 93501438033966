import { SET_MODAL_STATE } from 'actions/modal_actions'
import { store }           from 'index';
import goToSignUp          from 'functions/auth/go_to_signup';
import { planTypeType }    from 'types';
import recordEvent         from 'functions/contentInteraction/record_event';

const startNowFunc = (
    planType:               planTypeType,
    suspendedSub:           boolean,
    guest:                  boolean,
    freeTrial:              boolean,
    unSubscribedAuthedUser: boolean,
    hasPassword:            boolean,
    goToPayForm:            () => void,
    completeSignUp:         () => void
) => {

    if ( planType === '' ) {
      return
    }

    recordEvent('registration', `${ planType } plan selected. continue button clicked`, 'plan selected')

    if ( suspendedSub && planType !== 'Basic' ) {
        store.dispatch(SET_MODAL_STATE({ variant: 'showSuspendedSub', open: true, showButtons: true }))
      return
    }
    if ( guest ) {
     // goToSignUp(false, planType);
      goToSignUp(true, planType);
      return
    }
    if ( freeTrial ) {
      goToPayForm();
      return
    }
    if ( unSubscribedAuthedUser ) {
        if ( hasPassword ) {
            goToPayForm();
            return
        }
        else {
          completeSignUp()
          return
        }
    }
  }
  
export default startNowFunc;