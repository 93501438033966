import React             from 'react';
import Selector          from './Selector';
import recordEvent       from 'functions/contentInteraction/record_event';
import { colors }        from 'utils/colors';
import { makeStyles }    from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
      flex:            1,
      height:          '100%',
      display:         'flex',
      flexFlow:        'row',
      alignItems:      'center',
      backgroundColor: colors.primary,
    }
}))

type SelectorContainerProps = {
  selectors:        string[];
  page:             string;
  wideScreen:       boolean;
  showSelectors:    boolean;
  goToMessageForm:  () => void;
  handleNavClick:   (val: string) => void;
  handleOpenModal:  (val: string) => void;
}

const SelectorContainer: React.FC<SelectorContainerProps> = ({ 
  selectors,
  page,
  wideScreen,
  showSelectors,
  goToMessageForm,
  handleNavClick,
  handleOpenModal,
}) => {

  const { classes } = useStyles();

  const onClick = (val: string) => {


    if (val !== 'video tutorials') {
      recordEvent('navigation button', `navbar button clicked`, `navbar ${ val }`)
    }

    if ( val === 'contact us' ){
      goToMessageForm()
      return;
    }
    else if (val === 'introduction video') {
      handleNavClick('introVideo');
      return;
    }
    else if (val === 'video tutorials') {
      handleOpenModal('tutorialMenu');
      return;
    }
    handleNavClick(val);

  }
  
  return (
    
      <div className = { classes.root } style = {{ maxWidth: showSelectors ? undefined : 1 }}> 
          {
            selectors.map((val) => {
                  return(
                    <Selector
                      key           = { val }
                      showSelectors = { showSelectors }
                      val           = { val }
                      page          = { page }
                      wideScreen    = { wideScreen }
                      onClick       = { onClick }
                    />
                  )
            })
          }                 
      </div>
          
  );
}




export default SelectorContainer;



