
import React                           from 'react';
import { store }                       from 'index';
import { GET_MY_STUDENT_CONTENT_SAGA } from 'actions/content_actions';
import { userAccessType }              from 'types';

const useGetMyStudentApps = (
    userEmail:               string,
    guest:                   boolean,
    userAccess:              userAccessType,
    getMyStudentAppsOnce:    boolean,
    setGetMyStudentAppsOnce: (val: boolean) => void
) => {


    const state           = store.getState();
    const userInfoUpdated = state.data.dataUpdateState.user;
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const userInfo        = state.user.userInfo;


    React.useEffect(() => {

      if (userInfoUpdated && !studentLoggedIn && !getMyStudentAppsOnce ) {

        const payload = {
          userEmail:       userEmail,
          guest:           guest ? true : false,
          subscriber:      !userAccess.denyAccess || userAccess.freeUser,
          studentOpenApps: userInfo.studentOpenApps
        }
    
        setGetMyStudentAppsOnce(true)
        store.dispatch(GET_MY_STUDENT_CONTENT_SAGA(payload))
      }
    
    },[userInfoUpdated, studentLoggedIn, getMyStudentAppsOnce, userEmail, guest, userInfo, userAccess, setGetMyStudentAppsOnce])

}

export default useGetMyStudentApps;