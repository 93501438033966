import { call, take, put }    from 'redux-saga/effects';
import axios                  from 'axios';
import { RECORD_ERROR }       from 'actions';
import { SET_MODAL_STATE }    from 'actions/modal_actions'
import { SET_USER_INFO }      from 'actions/userInfo_actions';
import makUuid                from 'functions/make_uuid.js';
import getUserBrowser         from 'functions/userData/get_user_browser';
import { planTypeType }       from 'types';
import { DateTime, Interval } from 'luxon';

import { 
  devPLoad, 
  guestPLoad,
  errPLoad
}                             from 'sagas/defaultPayloads/getUserInfo';

const getUserInfoEndpoint     = 'https://iseemath.co/api/getUserInfo.php';
const getUserLocationEndpoint = 'https://iseemath.co/api/getUserLocation.php';
const completeTrialEndpoint   = 'https://iseemath.co/api/completeTrial.php';
const now                     = DateTime.local();
const dev                     = process.env.NODE_ENV === 'development';
const browser                 = getUserBrowser();

const devPayload = {
    ...devPLoad,
    browser: browser
}

function* getUserInfo(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('GET_USER_INFO_SAGA');

        const { userName, userEmail, externalUser } = input.payload;
        const userIp_   = yield fetch('https://api.ipify.org/?format=json').then(response =>response.json());
        const userIp    = !!userIp_.ip ? userIp_.ip : '';
        const sessionId = yield makUuid();

        if (dev) {

          console.log('input : ',input)

          const payload = { 
            ...devPayload, 
            userIp:    userIp, 
            sessionId: sessionId,
          }

          yield put(SET_USER_INFO( payload ))
          
        }
        else {

            // get countryName
            
            let countryName = '';

            if ( !!userIp.length ) {
              const location = yield call( axios.post, getUserLocationEndpoint, { ip: userIp });
              if ( !!location && !!location.data && !!location.data.result ) {
                countryName = location.data.result;
              }
            }

            // guest payload

            if (userEmail === 'Guest') {
              const payload =  {
                ...guestPLoad,
                sessionId:   sessionId,
                userIp:      userIp,
                browser:     browser,
                countryName: countryName,
              }

              yield put(SET_USER_INFO( payload ))
            }

            else {

              const res = yield call( axios.post, getUserInfoEndpoint, { userEmail: userEmail });

            // console.log('res:       ', res)

              let userId                              = -1;
              let completeTrial                       = false;
              let allowTrial                          = false;
              let subscriber                          = false;
              let freeUser                            = false;
              let blocked                             = false;
              let subscriptionSuspended               = false;
              let subscriptionStatus                  = '';
              let failedPayments                      = 0;
              let userOrigin                          = '';
              let teacherCode                         = '';
              let hasPassword                         = false;
              let trialStartedAt                      = '';
              let trialCompleted                      = false;
           //   let suspendSubscriptionAt               = '';
              let subscriptionSuspendedAt             = '';
              let firstPayment                        = '';
           //   let nextPayment                         = '';
              let balance                             = '';
              let subscriptionId                      = '';
              let subscriptionType: planTypeType | '' = '';
              let studentOpenApps                     = [];
              let oneTimeEvents                       = [];


              if ( res && res.data && res.data.result && res.data.result.length > 0 && res.data.result[0] ) {

                console.log('res    :   ',res)

                const data = res.data.result[0];

                userId                  = data.id;
                userOrigin              = data.user_origin;
                teacherCode             = `${ data.teacher_code }`;
                hasPassword             = data.has_password === 'true';
                subscriber              = data.subscribed === 'true';
                freeUser                = data.free_access === 'true';
                subscriptionSuspended   = data.subscription_suspended === 'true';
                subscriptionStatus      = data.subscription_status;
                failedPayments          = !!data.failed_payments && !isNaN(+data.failed_payments) ? +data.failed_payments : 0;
                trialStartedAt          = data.trial_started_at;
                trialCompleted          = data.trial_completed === 'true';
             //   suspendSubscriptionAt   = data.suspend_subscription_at;
                subscriptionSuspendedAt = data.subscription_suspended_at;
                subscriptionId          = data.subscription_id;
                firstPayment            = /* data.subscription_reactivated_at.length ? data.subscription_reactivated_at :  */data.subscription_created_at;
                subscriptionType        = data.subscription_type;
                studentOpenApps         = !!data.student_open_apps ? JSON.parse(data.student_open_apps) : [];
                oneTimeEvents           = data.one_time_events === '' ? [] : JSON.parse(data.one_time_events);
              /*   nextPayment             = !!data.last_payment ? 
                                             !!DateTime.fromISO(data.last_payment) ? 
                                                subscriptionType === 'Monthly' ? 
                                                DateTime.fromISO(data.last_payment).plus({ months: 1 }).plus({ days: 1 }).toFormat('yyyy-LL-dd') : 
                                             DateTime.fromISO(data.last_payment).plus({ years: 1 }).plus({ days: 1 }).toFormat('yyyy-LL-dd') : 
                                             '' : 
                                          '';
 */



                if ( trialStartedAt.length > 0 && !trialCompleted ) {

                  // free trial is completed

                  const trialStartDate = DateTime.fromISO(trialStartedAt);
                  const int_           = Interval.fromDateTimes(trialStartDate, now).count('days');
                  if ( int_ >= 30 ) {
                      completeTrial = true;
                      const res     = yield call( axios.post, completeTrialEndpoint, { userEmail: userEmail });
                      console.log('res  :  ',res)
                      yield put(SET_MODAL_STATE({ variant: 'trialCompletion', open: true, showButtons: true })); 
                  }
                  else {
                      allowTrial = true;
                  }
                }

                else if ( trialCompleted ) {
                  completeTrial = true;
                }

                if ( subscriptionStatus === 'active' && failedPayments >= 1 ) {

                  // handle subscribed user not paying
                }

                if ( subscriptionStatus === 'expired' ) {

                  // handle subscription expired

                }

                // handle externalUser name update (user that signed up with google account)

                if ( externalUser && data.user_name === data.user_email && data.user_name_updated === 'false' ) {
                  yield put(SET_MODAL_STATE({ variant: 'updateUserName', open: true, showButtons: true })); 
                }

                // end of handle externalUser name update (user that signed up with google account)

              }
              if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: userEmail, err: res.data.msg, endpoint: 'get_user_info' }));
              }

              const payload = {
                  userId:                  userId,
                  sessionId:               sessionId,
                  userIp:                  userIp,
                  userName:                userName,
                  userEmail:               userEmail,
                  userOrigin:              userOrigin,
                  browser:                 browser,
                  balance:                 balance,
                  hasPassword:             hasPassword,
                  trialStartedAt:          trialStartedAt,
                  completeTrial:           completeTrial,
                  allowTrial:              allowTrial,
                  userRole:                'teacher',
                  teacherCode:             teacherCode,
                  subscriber:              ((subscriber) || (subscriptionStatus === 'active')),
                  freeUser:                freeUser,
                  blocked:                 blocked,
                  subscriptionSuspended:   subscriptionSuspended,
                //  suspendSubscriptionAt:   suspendSubscriptionAt,
                  subscriptionSuspendedAt: subscriptionSuspendedAt,
                  firstPayment:            firstPayment,
               //   nextPayment:             nextPayment,
                  subscriptionType:        subscriptionType,
                  subscriptionId:          subscriptionId,
                  studentOpenApps:         studentOpenApps,
                  oneTimeEvents:           oneTimeEvents,
                  countryName:             countryName
              }

              yield put(SET_USER_INFO( payload ));

          }
        }
    }
    catch(err) {
      const payload = {
         ...errPLoad,
         browser: browser
      }

      yield put(SET_USER_INFO( payload ))
      console.log('err  :  '+err);

    }
    finally {}
  }
}


export default getUserInfo;


