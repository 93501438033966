import { useState }        from 'react';
import { 
  useNavigate,
  useLocation
}                          from "react-router-dom";
import useStyle            from "./useStyle"
import handleClick         from "./handleClick";
import ArrowDropDownIcon   from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener   from '@mui/base/ClickAwayListener';
import { makeStyles }      from 'makeStyles';
import { colors }          from 'utils/colors';


var clsx = require('classnames')

const navbarItems = ['about us', 'my lessons','teachers','students', 'puzzles'];


const useStyles = makeStyles()(
  (Theme) => ({    selector: {
      position:        'absolute',
      right:           0,
      top:             0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
    },
    hover: {
      color:      '#FFF',
      fontWeight: 'bold',
      '&:hover': {
        opacity: 0.5,
      }
    },
    small: {
      fontSize: 14,
    },
    big: {
      fontSize: 15,
    },
    menu: {
      width:           250,
      right:           0,
      position:        'absolute',
      border:          '1px solid #d3d4d5',
      backgroundColor: '#FFF',
      overflowX:       'hidden',
      boxShadow:       '0px 4px 10px rgba(0,0,0,0.15)',
      cursor:          'pointer',
      transition:      'height 0.3s, opacity 0.4s',
     '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#CCC',
        borderRadius:    10
      } 
    },
    item: {
      height:         50,
      width:          '100%',
      minWidth:       250,
      display:        'flex',
      alignItems:     'center',
      textAlign:      'left',
      paddingLeft:    5,
      textDecoration: 'none',
      cursor:         'pointer',
      color:          colors.darkText,
      '&:hover': {
        backgroundColor: '#E6E6EA'
      }
    },
    icon: {
        color: '#FFF'
    },
}))

type DropDownProps = {
  dropDownitems:         string[];
  size:                  number[];
  dropDownButtonWidth:   number;
  wideScreen:            boolean;
  bigNavBar:             boolean;
  goToMessageForm:       () => void;
  handleOpenModal:       (val: string) => void;
  handleNavClick:        (val: string) => void;
}

const DropDown: React.FC<DropDownProps> = ({ 
  dropDownitems,
  size,
  dropDownButtonWidth,
  wideScreen,
  bigNavBar,
  goToMessageForm,
  handleOpenModal,
  handleNavClick,
}) => {

  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setopen] = useState(false)
  

  const click = (val: string) => {
        handleClick(
            val,
            navbarItems,
            location,
            navigate,
            goToMessageForm,
            handleOpenModal,
            handleNavClick,
            setopen
        )
   }

  const handleClickAway = () => {
    setopen(false)
  }

  const { selectorStyle, menuStyle, itemStyle, iconStyle } = useStyle( dropDownitems, size, dropDownButtonWidth, bigNavBar, open)



  return (
        <ClickAwayListener onClickAway = { handleClickAway }>
            <div 
               className = { clsx(classes.selector, ( wideScreen ? classes.big : classes.small)) }
               style     = { selectorStyle }
               onClick   = { () => { setopen(!open) }}
            >
                <div className = { classes.hover }>
                    {
                      <ArrowDropDownIcon
                          className = { classes.icon }
                          style     = { iconStyle } 
                      />
                    }
                </div>
                <div 
                   className = { classes.menu }
                   style     = { menuStyle } 
                 >
                  {
                     dropDownitems.map((val) => {
                       return(
                          <div 
                             key       = { `${ val }{}{}` }
                             className = { clsx(classes.item, wideScreen ? classes.big : classes.small) }
                             style     = { itemStyle }
                             onClick   = { () => { click(val) }}
                          >
                             { val }
                          </div> 
                       )
                     })
                  }
                </div>         
            </div>
        </ClickAwayListener>
  );
}




export default DropDown;



