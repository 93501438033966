
import { store }                 from 'index';
//import { setDataType }           from 'actions/types';
//import { SET_CONTENT, SET_DATA } from 'actions';

const lsContent = localStorage.getItem( 'ISeeMathContent' ); 

const loadStoredData = (
    guest:            boolean,
    setChangeContent: (val: boolean) => void
) => {

    const state           = store.getState();
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const dataUpdated     = state.data.dataUpdateState.final;
    //const setData         = (payload: setDataType) => store.dispatch(SET_DATA(payload));
    //const setContent      = (payload: { [key: string]: any }) => store.dispatch(SET_CONTENT(payload));

    if ( !studentLoggedIn && !guest && lsContent && typeof lsContent === 'string' && !dataUpdated ) { 

      //const storedContent = JSON.parse(lsContent);

      //setContent( storedContent )
      //setChangeContent(true)
      //setData({ variant: 'stored' })

    }
}


export default loadStoredData
