import React                   from 'react';
import { connect }             from 'react-redux';

import { reducerType }         from 'reducer';

import Home                    from 'pages/home';
import Grids                   from 'pages/grids';
import About                   from 'pages/about';
import ChallengePage           from 'pages/challenge_page';

import TutorialVideo           from 'components/TutorialVideo';
 
import { 
         appTopicsArrType,
         contentInfoType,
         userAttributesType,
         pageStateType,
         pdfStateType,
         userAccessType,
         updateInfoType,
       }                       from 'types'

import { makeStyles }          from 'makeStyles';
import { componentsStateType } from 'reducer/types';
 

const useStyles = makeStyles()((Theme) => ({    
    root: {
        height:          '100vh',
        minHeight:       '100vh',
        minWidth:        '100vw',
        maxHeight:       '100vh',
        maxWidth:        '100vw',
        backgroundColor: '#FFF',
        overflow:        'hidden',
        transition:      'opacity 0.6s',
    }
}))

type rootPagesProps = {
  userAttributes:         userAttributesType;
  userAccess:             userAccessType;
  componentsState:        componentsStateType;
  pageState:              pageStateType;
  dataUpdateState:        updateInfoType;
  groupState:             { [key: string]: any };
  appTopicsArr:           appTopicsArrType[];
  PDFState:               pdfStateType;
  size:                   number[];
  menuSize:               number;
  AudioRef:               any;
  freeApp:                string;
  wideScreen:             boolean;
  guest:                  boolean;
  studentLoggedIn:        boolean;
  storageUpdateState:     boolean;
  allContent:             { [key: string]: any }[];
  selectContent:          (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
  setPDFState:            (val: pdfStateType) => void;
  setChangeContent:       (val: boolean) => void;
  setShowTOS:             (val: boolean) => void;
  setShowPrivacy:         (val: boolean) => void;
}

const RootPages: React.FC<rootPagesProps> = ({
  userAttributes,
  userAccess,
  componentsState,
  pageState,
  dataUpdateState,
  groupState,
  appTopicsArr,
  wideScreen,
  menuSize,
  guest,
  size,
  AudioRef,
  allContent,
  studentLoggedIn,
  storageUpdateState,
  freeApp,
  PDFState,
  setPDFState,
  selectContent,
  setChangeContent,
  setShowTOS, 
  setShowPrivacy, 
}) => {
  
  const { classes } = useStyles();

  const [showLoader, showUpdatingLoader] = React.useMemo(() => {

    if ( studentLoggedIn ) {
      return[ false, false]
    }

    return[
      (
         groupState.showGroupLessonsLoader //|| 
        // (!dataUpdateState.final && !storageUpdateState)
      ),
      false
    //  (!dataUpdateState.final && storageUpdateState)
    ]
  },[/*dataUpdateState, storageUpdateState, */studentLoggedIn, groupState])



  return (
        <div 
          className = { classes.root }
          style     = {{ opacity: pageState.opacity }}
        >
          <Grids
              allContent            = { allContent }   
              appTopicsArr          = { appTopicsArr }      
              size                  = { size }      
              freeApp               = { freeApp }         
              menuSize              = { menuSize }                  
              wideScreen            = { wideScreen }         
              showLoader            = { showLoader }         
              showUpdatingLoader    = { showUpdatingLoader } 
              denyAccess            = { guest || userAccess.denyAccess }         
              PDFState              = { PDFState }
              subscriptionStatus    = { userAccess.subscriptionStatus }
              AudioRef              = { AudioRef }
              setPDFState           = { setPDFState }
              selectContent         = { selectContent }        
              setChangeContent      = { setChangeContent }
          />
          {
              (pageState.page === 'about' || pageState.page === 'about us') 
              &&
              <About 
                  size       = { size }
                  menuSize   = { menuSize }
              />
          }
          {
              componentsState.showTutorial
              &&
              <TutorialVideo
                 size                 = { size }
                 menuSize             = { menuSize }
                 wideScreen           = { wideScreen }
                 componentsState      = { componentsState }
                 phaseOutTutorial     = { false }
                 studentVideos        = { [] }
                 setPhaseOutTutorial  = { () => {} }
                 setShowTutorialVideo = { () => {} }
              />
          }
          {
              pageState.page === 'home'
              &&
              <Home
                 size              = { size }
                 page              = { pageState.page }
                 subscriber        = { userAccess.subscriber || userAccess.freeTrial || userAccess.freeUser }
                 denyAccess        = { userAccess.denyAccess }
                 userSignedIn      = { userAttributes.userSignedIn === 'true' }
                 setShowTOS        = { setShowTOS }
                 setShowPrivacy    = { setShowPrivacy }
                 setChangeContent  = { setChangeContent }
              />
          }
          {
              pageState.page === 'challenges'
              &&
              <ChallengePage
                  size             = { size }
                  menuSize         = { menuSize }
                  wideScreen       = { size[0] > 1000 }
                  setChangeContent = { setChangeContent }
              /> 
          }
        </div>
  );
}


const mapStateToProps = (reducer: reducerType) => {

  return {
    componentsState:    reducer.components,
    allContent:         reducer.content.allContent,
    studentVideos:      reducer.content.studentVideos,
    groupState:         reducer.content.groupState,
    dataUpdateState:    reducer.data.dataUpdateState,
    storageUpdateState: reducer.data.storageUpdateState,
    pageState:          reducer.page,
    studentLoggedIn:    reducer.user.studentLoginState.loginState === 'success',
}};


export default connect(mapStateToProps)(RootPages);

