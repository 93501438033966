

export const set_user_activity = (userEmail: string, arr: { [key: string]: any }[], hash: { [key: string]: any }) => {
      
      for (let i = 0; i < arr.length; i++) {
 
         const id = arr[i].uid ? `${ arr[i].uid }` : arr[i].lesson_id;

         if ( hash[id] ) {

            if ( arr[i].user_name === userEmail ) {
               hash[id].liked    = arr[i].liked    ? arr[i].liked    : ( hash[id].liked ?    hash[id].liked    : 0 );
               hash[id].selected = arr[i].selected ? arr[i].selected : ( hash[id].selected ? hash[id].selected : 0 );
               hash[id].viewed   = arr[i].viewed   ? arr[i].viewed   : ( hash[id].viewed ?   hash[id].viewed   : 0 );
            }

            if ( arr[i].liked === 1 ) {
               const newTotalLikes = hash[id].totalLikes ? hash[id].totalLikes + 1 : 1;
               hash[id].totalLikes = newTotalLikes;
            }
            
         }

      }

   return hash;
    
}











/* 
 if ( arr[i].user_name === userEmail ) {
               hash[id].liked    = arr[i].liked    ? arr[i].liked : hash[id].liked ?    hash[id].liked    : 0;
               hash[id].selected = arr[i].selected ? arr[i].liked : hash[id].selected ? hash[id].selected : 0;
               hash[id].viewed   = arr[i].viewed   ? arr[i].liked : hash[id].viewed ?   hash[id].viewed   : 0;
            }
 */