
import { store }                  from 'index';
import { SET_PAGE_STATE }         from 'actions/page_actions'
import { SET_COMPONENTS_STATE }   from 'actions/component_actions'
import { SET_CONTENT }            from 'actions/'
import { pagesWithMenuArr }       from 'utils/defaultStates';
import { initialComponentsState } from 'reducer/initialStates';


const navBarClick = (
    val:              string,
    setChangeContent: (val: boolean) => void,
  ) => {

    const state = store.getState();

    const pageState       = state.page;
    const componentsState = state.components;
    const pageWithMenu    = pagesWithMenuArr.includes(val)
    const { bigContentArr, apps, userLessons } = state.content;

    if (val === 'apps') {
      val = 'content'
    }


    if (pageState.page === val) {
      if ( val === 'content' ) {
        store.dispatch(SET_COMPONENTS_STATE({
          ...componentsState,
          selectedAppSubTopic: "",
          selectedAppTopic:    "",
          selectedContent:     "apps",
          showAppIntro:        false,
          showStudentVideo:    false,
          showTutorial:        false,
          showMenu:            true,
          showSmallMenu:       false,
          showTopicIntro:      false,
        }))

        store.dispatch(SET_CONTENT({ 
          allContent: bigContentArr,
          sender:     'navBarClick'
        }))
        setChangeContent(true)
      }
      return;
    }


    const saveState = pageState.page === 'app page' || val === 'app page';
    
    let { savedPage } = pageState;

    const save = [ 'content' ];

    if (save.includes(val)) {
      savedPage = val
    }

    if ( val !== 'app page' ) {

      store.dispatch(SET_PAGE_STATE({
        ...pageState,
        opacity: 0
      }))
    }

    setTimeout(() => {
          if (!saveState) {

            let selectedContent = 'apps';
            let contentArr      = apps;

            if ( val === 'bonus' ) {
              selectedContent = 'bonus';
            }

            if ( val === 'introVideo' ) {
              selectedContent = 'introVideo';
            }

            if ( val === 'my lessons' ) {
              selectedContent = 'userLessons';
              contentArr      = userLessons
            }
            
            store.dispatch(SET_COMPONENTS_STATE({
              ...initialComponentsState,
              appLessons:       [],
              showAppIntro:     false,
              selectedContent:  selectedContent,
              showStudentVideo: false,
              showMenu:         pageWithMenu,
              showNavbar:       val !== 'challenges',
              showFooter:       val !== 'challenges',
              showSmallMenu:    false,
              showTopicIntro:   false,
              selectedVideo:    val === 'introVideo' ? -1 : componentsState.selectedVideo,
              showTutorial:     val === 'introVideo'
            }))


            store.dispatch(SET_CONTENT({ 
              allContent: contentArr,
              sender:     'navBarClick2'
            }))
            setChangeContent(true)
          }
    },350)
    
    setTimeout(() => {

          store.dispatch(SET_PAGE_STATE({ 
            page:      (val === 'introVideo' || val === 'bonus' ) ? 'content' : val,
            opacity:   1,
            savedPage: savedPage
          }))
     },700)
  
} 




export default navBarClick;

