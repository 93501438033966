import React                     from 'react';
import { contentInfoType }       from '../../../types'
import { makeStyles }            from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    imageContainer: {
      width:           '100%',
      height:          '100%',
      cursor:          'pointer',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-evenly',
      flexFlow:        'column',
    },
    image: {
      height:             '60%',
      width:              '80%',
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
  },
  name: {
    height:         '20%',
    width:          '90%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'flex-end',
    flexFlow:       'column',
    textAlign:      'center',
    fontWeight:     'bold'
  }
}))

type ImageContainerProps = {
  val: contentInfoType;
}

const ImageContainer: React.FC<ImageContainerProps> = ({
  val,
}) => {

  const { classes } = useStyles();

  const [imageStyle, name] = React.useMemo(() => {

    let src  = '';
    let text = '';

    if ( !!val.origin ) {
      if ( val.origin === 'puzzles' ) {
         src  = !!val.img ? `url(https://www.yisumatica.org.il/justine/puzzles/${ val.img }.JPG)` : "";
         text = !!val.puzzle_type_text ? !!val.topic ? val.topic : val.puzzle_type_text : ''
      }
      if ( val.origin === 'topicIntros' ) {
         src  = !!val.img ? `url(https://www.yisumatica.org.il/justine/img/${ val.img })` : "";
         text = !!val.sub_topic ? val.sub_topic : '';
      }
      if ( val.origin === 'challenges' ) {
         src  = !!val.image ? `url(https://iseemath.co/images/challenges/${ val.image }.png)` : '';
         text = !!val.name ? val.name : '';
      }
    }
    return [{ backgroundImage: src }, text]

  },[val])


  return (
        <div className = { classes.imageContainer }>
              <div
                  className = { classes.image }
                  style     = { imageStyle }
              />
              <div 
                 className = { classes.name }
                 dir       = 'ltr'
              >
                  { name }
              </div>
       </div>
  );
}

export default ImageContainer;


