import React                      from 'react';
import { Auth }                   from 'aws-amplify';
import SignUpForm2                from '../signUp/SignUpForm2';
import PasswordInput              from '../PasswordInput';
import TextField                  from '@mui/material/TextField';
import GoogleButton               from '../GoogleButton';
import recordEvent                from 'functions/contentInteraction/record_event';
import useTextFieldStyle          from 'components/authenticator/functions/get_textfield_style';
import { makeStyles }             from 'makeStyles';
import { colors }                 from 'utils/colors';


const useStyles = makeStyles()((Theme) => ({  
  root: {
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',
  },
  logo: {
    width:              200,
    height:             65,
    backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
  },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center'
  },
 /*  text_field: {
    height:          45,
   // fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
  }, */
  password: {
    marginTop: 10
  },
  forgot_pwd: {
     cursor:    'pointer',
     color:     colors.darkText,
     textAlign: 'center',
     margin:    20,
     '&:hover': {
      opacity: 0.5
    } 
  },
  login: {
    marginTop:       40,
    color:           colors.darkText,
    width:           250,
    height:          40,
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    backgroundColor: '#FFF',
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
   },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        18,
  },
  signup: {
      height:          43,
      width:           350,
      maxWidth:        '90vw',
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
      marginTop:       10,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      backgroundColor: colors.primary,
      color:           '#FFF',
      borderRadius:    '0.25em',
      '&:hover': {
         opacity: 0.5
      }
  },
  loader_container: {
    width:   60,
    height:  60,
  },
  txt: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         30,
    color:          '#F00',
    cursor:         'default'
  },
  iseemath: {
    marginTop:  5,
    fontSize:   18,
    fontWeight: 'bold'
  }
}));

type SignInFormProps =  {
  size:             number[];
  email:            string;
  password:         string;
  code:             string;
  error:            string;
  userConfirmed:    boolean; 
  showLogo:         boolean; 
  setError:         (val: string) => void;
  setName:          (val: string) => void;
  setEmail:         (val: string) => void;
  setPassword:      (val: string) => void;
  setCode:          (val: string) => void;
  setDisplayForm:   (val: string) => void;
  setUserConfirmed: (val: boolean) => void;
  setShowLoader:    (val: boolean) => void;
  handleCreateUser: () => void;
};

const SignInForm: React.FC<SignInFormProps> = ({
   size,
   email,
   password,
   code,
   error,
   userConfirmed,
   showLogo,
   setUserConfirmed,
   setError,
   setShowLoader,
   setName,
   setEmail,
   setPassword,
   setCode,
   setDisplayForm,
   handleCreateUser,
  }) => {

  const { classes } = useStyles();

  const textFieldStyle = useTextFieldStyle(size);

  //
  const handleForgotPassword = () => {
    setName('')
    setEmail('')
    setPassword('')
    setDisplayForm('recover_pwd_part1')
    recordEvent('registration', `forgot password button clicked`, 'handleForgotPassword')
  }

  const handleGoToSignup = () => {
    setDisplayForm('sign_up_part1')
    recordEvent('registration', `switch to signup button clicked`, 'handleGoToSignup')
  }

  const changeInputValue = (e: React.FormEvent) => {
    
    e.preventDefault();
    const target = e.target as HTMLInputElement;

    if (target.id === 'email')    { setEmail(target.value) }
    if (target.id === 'password') { setPassword(target.value) }

  }


  const handleSubmit = async() => {

    if (!email.length) { setError('Email cannot be empty'); return }
    if (!password.length) { setError('Password cannot be empty'); return }

    setShowLoader(true);
    
    try {
        await Auth.signIn(email, password);
        setName('')
        setEmail('')
        setPassword('')
        recordEvent('registration', 'user signed in', 'sign in form')
      //  setShowLoader(false);
        window.location.reload(); 
    }
    catch(err: any) {

        const _error = err.message || err;

        console.error("error occured in sign_in_form handleSubmit: ",_error);
        setShowLoader(false);
        let errText = '';

        if (_error === 'Username cannot be empty') { errText = 'Email cannot be empty' }
        setError(errText.length ? errText : (_error || ''));

        if (err === "User is not confirmed." || err.message === "User is not confirmed.") {
          await Auth.resendSignUp(email); 
          console.log("User is not confirmed.")
          setUserConfirmed(false)
          setDisplayForm('sign_up_part2')
        }

        recordEvent('registration', `sign in error: ${ _error }`, 'sign in error')
    }
    finally {
    }
  }

  const goToStudentLogin = () => {
    setEmail('');
    setCode('');
    setDisplayForm('studentLogin')
    recordEvent('registration', `student login button clicked`, 'goToStudentLogin')
  }
  
  return (
     <div className = { classes.root }>
        {
          userConfirmed &&
          (  <>
              { showLogo && <div className = { classes.logo }/> }
              <div className = { classes.txt }> { error } </div>
                <form className = { classes.form } >
                  <TextField
                     variant      = "standard"
                  //   className    = { classes.text_field }
                     style        = { textFieldStyle }
                     id           = "email"
                     label        = "Email"
                     autoComplete = "current-email"
                     onChange     = { changeInputValue }
                  />
                  <PasswordInput
                     password         = { password }
                     showHelperText   = { false }
                     textFieldStyle   = { textFieldStyle }
                     changeInputValue = { changeInputValue }
                  />
                </form>
                <div 
                   onClick   = { handleSubmit } 
                   className = { classes.login }
                >
                    <div className = { classes.buttonText }>Log In</div>
                </div>     
                <GoogleButton/>       
                <div>
                   <div onClick = { handleForgotPassword } className = { classes.forgot_pwd }>
                     forgot password?
                   </div>
                   <div className = { classes.signup } onClick = { handleGoToSignup }>
                      Don't Have An Account Yet? Sign Up
                   </div>
                   <div className = { classes.signup } onClick = { goToStudentLogin }>
                      I'm A Student
                   </div>
                </div>
            </>
          )
        }
        {
          !userConfirmed
          &&
          (
            <SignUpForm2
              size             = { size }
              email            = { email }
              code             = { code }
              error            = { error }
              showLogo         = { showLogo }
              setError         = { setError }
              setShowLoader    = { setShowLoader }
              setCode          = { setCode }
              handleCreateUser = { handleCreateUser }
            />
          )
        }
      </div>
    )
}



export default SignInForm;