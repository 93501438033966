import { take, put }             from 'redux-saga/effects';
import { SET_DATA, SET_CONTENT } from '../actions';
import { set_user_activity }     from '../functions/appData/set_user_activity';
import { updateHash }            from '../functions/userData/my_encoder';
import updateContent             from '../functions/appData/update_content';
import sortContent               from '../functions/appData/sort_content';


function* createData(): Generator<any,any,any> {
  while(true) {
    try {

            const input = yield take('CREATE_DATA_SAGA');

            const { content, data, hash, userEmail, variant, guest } = input.payload;
            let { bigContentArr, apps, lessons, puzzles, userLessons } = content;

            console.log('create data');

            const allActivity = data.userActivity.concat(data.totalLikes);


            const hashWithActivity: { [key: string]: any } = yield set_user_activity(userEmail, allActivity, hash);
            const finalHash         = yield updateHash(data.scores, hashWithActivity);

            const newBigContentArr  = yield updateContent(bigContentArr, finalHash)
            const newApps           = yield updateContent(apps, finalHash)
            const newLessons        = yield updateContent(lessons, finalHash)
            const newPuzzles        = yield updateContent(puzzles, finalHash)

            const soretedBigContentArr  = yield sortContent(newBigContentArr, 'likes')
            const soretedApps           = yield sortContent(newApps, 'likes')
            const soretedLessons        = yield sortContent(newLessons, 'likes')
            const soretedPuzzles        = yield sortContent(newPuzzles, 'puzzleType')


            const payload = {
              bigTeacherArr:         [],
              teacherLessons:        [],
              mivdakim:              [],
              bigContentArr:         soretedBigContentArr,
              apps:                  soretedApps,
              interaction:           [],
              puzzles:               soretedPuzzles,
              lessons:               soretedLessons,
              userLessons:           userLessons,
              hash:                  finalHash,
              contentUpdated:        true,
              storageContentUpdated: true,
              sender:                'create_data'
            }

            yield put(SET_CONTENT( payload ))
            yield put(SET_DATA({ variant: variant }))

           // console.log('set data');


            // update local storage

            if ( !guest ) {
              
              const payload__ = {
                ...payload,
                contentUpdated: false
              }
    
              const newContentString = JSON.stringify(payload__)
              localStorage.setItem("ISeeMathContent", newContentString);
            }
          
            

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default createData;






