
import { 
  pageStateType
} from '../types'

export const pages = ['content', 'about']

export const pagesWithMenuArr = ['content', 'apps', 'introVideo', 'bonus'/*,  'challenges' */, 'my lessons', 'groupLessons'];
export const noMenuComponents = ['appIntro', 'topicIntro', 'practiceComp', 'directAppLink', 'directPracticeLink'];


export const initialPageState: pageStateType = {
  page:      'content',
  opacity:   1,
  savedPage: 'content'
}

export const pageStateUnAuthed: pageStateType = {
  page:      'home',
  opacity:   1,
  savedPage: 'content'
}

export const pageStateGroup = {
  page:      'content',
  opacity:   1,
  savedPage: 'content'
}



export const initialteacherUrlState = {
  url:         '',
  showResults: true,
  showScore:   true
}

export const initialUserInfo = { 
     userName:                '', 
     userEmail:               '', 
     userOrigin:              '', 
     hasPassword:             false, 
     completeTrial:           false, 
     allowTrial:              false, 
     trialStartedAt:          '', 
     userRole:                '', 
     teacherCode:             '', 
     subscriber:              false, 
     freeUser:                false, 
     blocked:                 false, 
     subscriptionSuspended:   false, 
     subscriptionSuspendedAt: '', 
     firstPayment:            '', 
     nextPayment:             '', 
     balance:                 '',
     subscriptionType:        '', 
     subscriptionId:          '',
     studentOpenApps:         [] as string[],
};


export const initialPDFState = {
  showPDF: false,
  src:     ''
}


export const sizes = {
  navBarSize: 60,
  tabSize:    60,
  footerSize: 25
}

export const topics: { [key: string]: string } = {
  'Integers': 'שלמים',
  'Geometry': 'גיאומטרייה',
  'Fractions': 'שבר',
  'Additional_Topics': 'אחר'
} 


export const introVideoSrc = "https://www.youtube.com/embed/e_XdY-AAGiU?rel=0";

