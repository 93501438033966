import React           from 'react';
import TextComponent   from './TextComponent';
import { sizes }       from 'utils/defaultStates';
import { makeStyles }  from 'makeStyles';
import {
  text1, 
  text2, 
  text3, 
  list1, 
  list2, 
  quote1, 
  quote2
} from './texts';

//var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({    
    container: {
      position:     'absolute',
      borderTop:    '1px solid #DDD',
      borderBottom: '1px solid #DDD'
    },
    textComponent: {
        position:    'absolute',
        top:         '50%',
        transform:   'translate(0%, -50%)',
        width:       '100%',
        maxHeight:   '100%',
        overflowY:   'scroll',
        overflowX:   'hidden',
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: `#BBB`,
            borderRadius:    10
        },
    },
    text: {
       marginLeft:   '50%',
       width:        '90%',
       transform:     'translate(-50%, 0%)',
       paddingTop:    15,
       paddingBottom: 15,
       paddingLeft:   30,
       paddingRight:  30,
       lineHeight:    1.2,
       letterSpacing: 2,
       display:       'flex',
       alignItems:    'center',
    },
    listItem: {
      display:    'flex',
      width:      '86%',
      marginLeft: '7%',
      lineHeight: 1.3,

    },
    dot: {
      marginTop:   3,
      fontSize:    11,
      marginRight: 8,
    }
}))

type TextProps = {
    size:         number[];
    menuSize:     number;
    headsHeight:  number;
    textHeight:   number;
    wideScreen:   boolean;
}
const Text: React.FC<TextProps> = ({ 
    size,
    menuSize,
    headsHeight,
    textHeight,
    wideScreen
}) => {

  const { classes } = useStyles();

  const containerStyle = React.useMemo(() => {
      return {
          height: textHeight - sizes.tabSize,
          width:  wideScreen ? size[0] - menuSize : '100vw',
          top:    /* sizes.tabSize +  */(wideScreen ? 0 : headsHeight),
          left:   wideScreen ? menuSize : 0
      }
  },[size, wideScreen, menuSize, headsHeight, textHeight])

  return (
        <div 
            className = { classes.container }
            style     = { containerStyle }
        >
                <div className = { classes.textComponent }>
                    <br/>
                    <TextComponent
                       variant = { 'text' }
                       text    = { text1 }
                       keyName = { 'text1' }
                    />
                    <TextComponent
                       variant = { 'list' }
                       text    = { list1 }
                       keyName = { 'list1' }
                    />
                    <TextComponent
                       variant = { 'text' }
                       text    = { text2 }
                       keyName = { 'text2' }
                    />
                     <TextComponent
                       variant = { 'list' }
                       text    = { list2 }
                       keyName = { 'list2' }
                    />
                    <br/>
                    <TextComponent
                       variant = { 'text' }
                       text    = { text3 }
                       keyName = { 'text3' }
                    />
                    <TextComponent
                       variant = { 'quote' }
                       text    = { quote1 }
                       keyName = { 'quote1' }
                    />
                    <br/>
                    <TextComponent
                       variant = { 'quote' }
                       text    = { quote2 }
                       keyName = { 'quote2' }
                    />
                    <br/>
                    <br/>
                </div>
        </div>
  );
}




export default Text;

