import React               from 'react';
import Integer             from './Integer';
import Fraction            from './Fraction';
import Combined            from './Combined';
import AnswerChoice        from './AnswerChoices';
import { makeStyles }      from 'makeStyles';

//const initialPos = true;

const useStyles = makeStyles()(
  (Theme) => ({    container: {
      //  height:         200,
      //  marginTop:      10,
        width:          300,
        display:        'flex',
        alignItems:     'center',
        flexFlow:       'column',
        justifyContent: 'center',
        transition:     'all 0.3s',
    }
}))


type InputProps = {
    size:                 number[];
    integer:              boolean;
    fraction:             boolean;
    combined:             boolean;
    inputInteger:         string;
    maxLength:            number;
    variant:              string;
    answerChoices:        string;
    inputNumerator:       string;
    inputDenominator:     string;
    number:               string;
    numerator:            string;
    denominator:          string;
    success:              string;
    correctAnswer:        string;
    selectedAnswer:       number;
    numeratorMaxLength:   number;
    denominatorMaxLength: number;
    animateInput:         boolean;
    setAnimateInput:      (val: boolean) => void;
    setInputInteger:      (val: string) => void;
    setInputNumerator:    (val: string) => void;
    setInputDenominator:  (val: string) => void;
    setSelectedAnswer:    (val: number) => void;
}

const Input: React.FC<InputProps> = ({
    size,
    integer,
    fraction, 
    combined, 
    inputInteger, 
    animateInput,
    maxLength,
    variant,
    number,
    numerator,
    denominator,
    success,
    answerChoices,
    inputNumerator,
    inputDenominator,
    numeratorMaxLength,
    denominatorMaxLength,
    selectedAnswer,
    correctAnswer,
    setSelectedAnswer,
    setInputInteger,
    setInputNumerator,
    setInputDenominator,
    setAnimateInput
}) => {

  const { classes } = useStyles();

  //const [initialPos, setInitialPos] = React.useState(false);
  const [animate, setAnimate]       = React.useState(false);


  const [textFieldHeight, containerStyle] = React.useMemo(() => {
    return [
        (size[1] > 600 ? 70 : 35), 
        { 
          //  transform: `translate(0px, ${initialPos ? 0 : 2000 }px)`,
            opacity:    animate ? 0 : 1,
        }
    ]
  },[size/* , initialPos */, animate]) 

/* 
  React.useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setInitialPos(true)
    }
    return () => { unmounted = true };
  },[]) */



  React.useEffect(() => {

    if (animateInput) {
        setAnimate(true);
        setTimeout(() => {
            setAnimateInput(false);
            setAnimate(false);
        }, 500) 

    }
  },[setAnimate, animateInput, setAnimateInput])


  return (

    <div 
       className = { classes.container }
       style     = { containerStyle }
    >
        {
            variant === 'N' && integer
            &&
            (
                <Integer
                    combined        = { false }
                    number          = { number }
                    success         = { success }
                    textFieldHeight = { textFieldHeight }
                    inputInteger    = { inputInteger }
                    maxLength       = { maxLength }
                    setInputInteger = { setInputInteger }
                />
            )
        }
        {
            variant === 'N' && fraction
            &&
            (
                <Fraction
                    combined             = { false }
                    textFieldHeight      = { textFieldHeight }
                    maxLength            = { maxLength }
                    numerator            = { numerator }
                    denominator          = { denominator }
                    success              = { success }
                    inputInteger         = { inputInteger }
                    inputNumerator       = { inputNumerator }
                    inputDenominator     = { inputDenominator }
                    numeratorMaxLength   = { numeratorMaxLength }
                    denominatorMaxLength = { denominatorMaxLength }
                    setInputNumerator    = { setInputNumerator }
                    setInputDenominator  = { setInputDenominator }
                />
            )
        }
        {
            variant === 'N' && combined
            &&
            (
                <Combined
                    inputInteger         = { inputInteger }
                    maxLength            = { maxLength }
                    number               = { number }
                    numerator            = { numerator }
                    denominator          = { denominator }
                    success              = { success }
                    inputNumerator       = { inputNumerator }
                    inputDenominator     = { inputDenominator }
                    numeratorMaxLength   = { numeratorMaxLength }
                    denominatorMaxLength = { denominatorMaxLength }
                    textFieldHeight      = { textFieldHeight }
                    setInputInteger      = { setInputInteger }
                    setInputNumerator    = { setInputNumerator }
                    setInputDenominator  = { setInputDenominator }

                />
            )
        }
        {
            variant === 'MC'
            &&
            <AnswerChoice
               correctAnswer     = { correctAnswer }
               answerChoices     = { answerChoices }
               selectedAnswer    = { selectedAnswer }
               success           = { success }
               setSelectedAnswer = { setSelectedAnswer }
            />
                        
        }
    </div>
  );
}

export default Input;

