import React                   from 'react';
import { colors }              from '../../../utils/colors';
import { makeStyles }          from 'makeStyles';
import ExtensionIcon           from '@mui/icons-material/Extension';
import StarIcon                from '@mui/icons-material/Star';
import LocalLibraryIcon        from '@mui/icons-material/LocalLibrary';
import PersonRoundedIcon       from '@mui/icons-material/PersonRounded';
import GroupRoundedIcon        from '@mui/icons-material/GroupRounded';
//import PlusIcon                from '@mui/icons-material/CloseSharp';
import BorderColorIcon         from '@mui/icons-material/BorderColor';
import AppsIcon                from '@mui/icons-material/Apps';
import { componentsStateType } from 'reducer/types';




var clsx = require('classnames');


const useStyles = makeStyles()(
  (Theme) => ({      
      iconContainer: {
        width:           10,
        height:          50,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
      },
      icon: {
          fontSize:   15,
          color:      colors.menuText,
      },
      dark: {
       // color: colors.menuText,
      },
      bright:{
       // color: colors.hoverText,
      }
}))

type MenuButtonIconProps = {
  variant:         string;
  wideScreen:      boolean;
  disable:         boolean;
  hover:           boolean;
  componentsState: componentsStateType;
}

const MenuButtonIcon: React.FC<MenuButtonIconProps> = ({
  variant,
  disable,
  hover,
  wideScreen,
  componentsState
}) => {

  const { classes } = useStyles();

  const selected = React.useMemo(() => {
     return componentsState.selectedContent === variant;
  },[componentsState.selectedContent, variant])


  const [iconStyle, elStyle] = React.useMemo(() => {

    const fontSize = wideScreen ? 15 : 14;

    return[{
          color:    disable ? colors.brightText : selected ? colors.blueText : undefined,
          fontSize: fontSize
       //   transform: variant === 'bonus' ? `rotate(-45deg)` : undefined
        },
        { 
            color:    disable ? colors.brightText : selected ? colors.blueText : undefined,
            fontSize: fontSize
        }
    ]
  },[selected, /* variant,  */disable, wideScreen])

  return (
        <div className = { classes.iconContainer }>
            {
                variant === 'bonus'
                &&
                <ExtensionIcon 
                   className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                   style     = { iconStyle }
                />
            }
            {
                variant === 'lessons'
                &&
                <BorderColorIcon 
                className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                style     = { iconStyle }
                />
            }
            {
                variant === 'favorites'
                &&
                <StarIcon 
                  className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                  style     = { iconStyle }
                />
            }
            {
                (variant === 'myStudentsContent' || variant === 'myTeacherContent')
                &&
                <LocalLibraryIcon 
                  className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                  style     = { elStyle }
                />
            }
            {
                variant === 'userLessons'
                &&
                <PersonRoundedIcon 
                  className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                  style     = { iconStyle }
                />
            }
            {
                variant === 'groupLessons'
                &&
                <GroupRoundedIcon 
                  className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                  style     = { iconStyle }
                />
            }
            {/* {
                variant === 'bonus'
                &&
                <PlusIcon 
                  className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                  style     = { iconStyle }
                />
            } */}
            {
            variant === 'studentApps'
            &&
                <AppsIcon 
                  className = { clsx (classes.icon, (hover ? classes.bright : classes.dark)) }
                  style     = { iconStyle }
                />
            }
        </div>
  );
}




export default MenuButtonIcon;
