import React                   from 'react';
import { makeStyles }          from 'makeStyles';


const dev = process.env.NODE_ENV === 'development';

const useStyles = makeStyles()((Theme) => ({  
     video_container: {
       height:          '100%',
       flex: 1,
       //width:           '99%',
       display:         'flex',
       flexFlow:        'row',
       justifyContent:  'center',
       alignItems:      'center',
       backgroundColor: "#FFF",
       borderRadius:    '0.3em',
     }
}));
 
type VideoComponentProps = {
    maxHeight:  number;
    wideScreen: boolean;
    src:        string;
} 

const VideoComponent: React.FC<VideoComponentProps> = ({ maxHeight, wideScreen, src }) => {

    const { classes } = useStyles();


    const Ref = React.useRef<HTMLVideoElement>(null);

     if (Ref && Ref.current && !dev) {
             Ref.current.play();
     }

    
   
    return(
        <div className = { classes.video_container }>
           <video 
               ref                = { Ref }
               src                = { src }
               muted              = { true }
               height             = { maxHeight }
               width              = '100%'
               preload            = "auto"
               webkit-playsinline = "true"
               playsInline
               loop
           />
        </div>
    )
}

export default VideoComponent;
