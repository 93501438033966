import { call, take, put }                    from 'redux-saga/effects';
import axios                                  from 'axios';
import { 
         SET_USER_ACTIVITY, 
         UPDATE_ACTIVITY,
         RECORD_ERROR
       }                                      from '../actions';

const userActivityEndpoint = 'https://www.yisumatica.org.il/api/getUserActivity.php';

 
const mockData = [{
  id:        18393,
  lesson_id: "Fraction_circle_model",
  liked:     1,
  selected:  1,
  user_email: "test@test.test",
  viewed:    1
}]
 
function* getUserActivity(): Generator<any,any,any> {
  while(true) {
    try {

    //  console.log('getUserActivity    ')


        const input = yield take('GET_USER_ACTIVITY_SAGA');
        
        const { userEmail } = input.payload;

        if (process.env.NODE_ENV === 'development') {

            yield put(SET_USER_ACTIVITY( mockData ))
            yield put(UPDATE_ACTIVITY())
            
        }

        if (process.env.NODE_ENV === 'production' ) {

            // get user activity from DB

            const userActivity = yield call( axios.post, userActivityEndpoint, { user_name: userEmail });        

            if (userActivity && userActivity.data && userActivity.data.result ) {

               // console.log('userActivity.data.result    :    ',userActivity.data.result)

                yield put(SET_USER_ACTIVITY( userActivity.data.result ))
                yield put(UPDATE_ACTIVITY())

            }

            else {
              yield put(UPDATE_ACTIVITY())
              console.log("can't get user activity")
              if ( userActivity && userActivity.data && !Boolean(userActivity.success) && userActivity.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: userEmail, err: userActivity.data.msg, endpoint: 'get_user_avtivity' }));
              }
            }
            
        }
       

    }
    catch(err) {
      yield put(UPDATE_ACTIVITY())
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default getUserActivity;


/**
 * if ( res && res.data && res.data.msg ) {
                yield put(RECORD_ERROR(res.data.msg));
              }
 */



