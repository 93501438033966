import { call, take, put }          from 'redux-saga/effects';
import axios                        from 'axios';
import { SET_APPS }                 from 'actions/content_actions';

import { 
         UPDATE_STUDENT_SCORES,
         UPDATE_TOTAL_LIKES,
         UPDATE_ACTIVITY,
         UPDATE_GUEST_DATA,
         RECORD_ERROR
       }                            from '../../actions';

import { makeGuestApps }            from '../../functions/appData/make_guest_apps';

import mockApps_                    from '../../content/mockContent/apps2';
import guestApps                    from '../../content/guestApps';

const mockApps  = mockApps_.filter((val) => { return (/* val.display_app_eng && val.display_app_eng === 'true' &&  */val.youtube_eng && val.youtube_eng.length > 0)});

const appsEndpoint        = 'https://www.yisumatica.org.il/api/getApps.php';


function* getApps(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('GET_APPS_SAGA');
        
        console.log('GET_APPS_SAGA');

        const { guest, subscriber, userEmail } = input.payload;

        if ( process.env.NODE_ENV === 'development' ) {

          const filteredApps = mockApps.filter((val) => { return(/* val.display_app_eng === 'true' &&  */val.youtube_eng && val.youtube_eng.length > 0)});
          const appsFinal = ( guest || !subscriber ) ? makeGuestApps(filteredApps, guestApps) : filteredApps;

          const payload = {
              apps: appsFinal
          }


          yield put(SET_APPS( payload ))
          if ( guest || !subscriber ) {

            
            yield put(UPDATE_STUDENT_SCORES());
            yield put(UPDATE_TOTAL_LIKES())
            yield put(UPDATE_ACTIVITY())
            yield put(UPDATE_GUEST_DATA())
          }

          
        }


        if (process.env.NODE_ENV === 'production') {

                const apps = yield call( axios.post, appsEndpoint );

                if ( apps && apps.data && apps.data.result) {

                    const apps_: { [key: string]: any }[] = apps.data.result;
                    const filteredApps                    = apps_.filter((val) => { return(val.youtube_eng && val.youtube_eng.length > 0)});
                    const appsFinal                       = ( guest || !subscriber ) ? makeGuestApps(filteredApps, guestApps) : filteredApps;

                    const payload = { apps: appsFinal }
  
                    yield put(SET_APPS( payload ))

                    if ( guest || !subscriber ) {
                      yield put(UPDATE_STUDENT_SCORES());
                      yield put(UPDATE_TOTAL_LIKES())
                      yield put(UPDATE_ACTIVITY())
                      yield put(UPDATE_GUEST_DATA())
                    }

                }
                if ( apps && apps.data && !Boolean(apps.data.success) && apps.data.msg ) {
                  yield put(RECORD_ERROR({ userEmail: userEmail, err: apps.data.msg, endpoint: 'get_apps' }));
                }

        }
      
      

    }
    catch(err) {
      console.log('err at get content saga  :  '+err);
    }
    finally {}
  }
}


export default getApps;
