
import { call, take }  from 'redux-saga/effects';
import axios           from 'axios';

const endpoint = 'https://iseemath.co/api/updateFreeAccess.php';

function* updateFreeAccess(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('UPDATE_FREE_ACCESS_SAGA'); 

        if (process.env.NODE_ENV !== 'production') {
           console.log('input : ',input)
        }
        
        if (process.env.NODE_ENV === 'production') {
            yield call( axios.post, endpoint, input.payload);
        }

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default updateFreeAccess;
