import React          from 'react';
import { colors }     from '../../../../utils/colors';
import { makeStyles } from 'makeStyles';

const maxHeight = 70;
const minHeight = 65;


const useStyles = makeStyles()(
  (Theme) => ({    buttonContainer: {
        flex:            0.2,
        maxHeight:       maxHeight,
        minHeight:       minHeight,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-evenly',
        borderTop:       '1px solid #DDD',
       // borderBottom:    '1px solid #DDD',
    },
    button: {
        width:           '50%',
        height:          '100%',
        maxHeight:       maxHeight,
        minHeight:       minHeight,
        fontSize:        17,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        color:           colors.blue,
        fontWeight:      'bold',
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }
    },
    disabledButton: {
      opacity:         0.4,
      width:           '50%',
      height:          '100%',
      maxHeight:       maxHeight,
      minHeight:       minHeight,
      fontSize:        17,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           colors.blue,
      fontWeight:      'bold',
      cursor:          'default',
    },
    redButton: {

        width:           '50%',
        height:          '100%',
        fontSize:        17,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        color:           '#D55',
        fontWeight:      'bold',
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }
    }
}))




type ButtonsType = {
    handleCloseModal: () => void;
    func:             () => void;
}

const Buttons: React.FC<ButtonsType> = ({
    handleCloseModal,
    func,
}) => {

  const { classes } = useStyles();


  return (
    <div className = { classes.buttonContainer }>
        <div
           className = { classes.button }
           onClick   = { func }
        >
            Yes
        </div>
        <div
           className = { classes.button }
           onClick   = { handleCloseModal }
        >
             No
        </div>
    </div>
  );
}





export default Buttons;
