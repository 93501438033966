import React                    from 'react';
import { useNavigate }          from 'react-router-dom';  
import { connect }              from 'react-redux';
import { Dispatch }             from "redux";
import { ActionTypes }          from 'actions/types';
import { reducerType }          from 'reducer';
import { contentInfoType }      from 'types';
import Tips                     from './Tips';
import Loader                   from 'components/Loader';
import recordEvent              from 'functions/contentInteraction/record_event'
import { componentsStateType }  from 'reducer/types';
import { SET_COMPONENTS_STATE } from 'actions/component_actions';
import setAppUrl                from 'functions/contentInteraction/set_app_url';
import { makeStyles }           from 'makeStyles';

const dev = false//process.env.NODE_ENV === 'development';

const useStyles = makeStyles()((Theme) => ({     
      root: {
      position:        'absolute',
      top:             0,
      left:            0,
      width:           '100vw',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      flexFlow:        'column',
      transition:      'opacity 0.3s',
    },
    loaderContainer: {
      position:        'fixed',
      top:             0,
      left:            0,
      width:           '100vw',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      flexFlow:        'column',
      backgroundColor: '#FFF',
      transition:      'opacity 0.3s',
    },
    iframe: {
        flex:  1,
        width: '100%',
    },
    button: {
        width:           100,
        height:          100,
        border:          '5px solid #D00',
        backgroundColor: '#DDD',
        cursor:          'pointer'
    },
    nosrc: {
    },
    dev: {
      position: 'fixed',
      width:    300,
      height:   100,
      border:   '1px dashed #DDD'
    },
    tipModal: {
      position:        'fixed',
      top:             15,
      left:            15,
      width:           300,
      height:          100,
      backgroundColor: '#FFF'
    }
}))

type AppPageProps = {
  selectedApp:       contentInfoType;
  componentsState:   componentsStateType;
  size:              number[];
  guest:             boolean;
  setSelectedApp:    (val: contentInfoType) => void;
  setComponentsState:(payload: componentsStateType) => void;
}

const AppPage: React.FC<AppPageProps> = ({ 
  selectedApp,
  componentsState,
  size, 
  guest,
  setSelectedApp,
  setComponentsState
}) => {

  //componentsState.appUrl

  const { classes } = useStyles();

  const [src, setSrc]           = React.useState('')

  React.useEffect(() => {

    if (!!selectedApp.lesson_id || !!selectedApp.uid || !!selectedApp.puzzletype) {
        const url = setAppUrl(selectedApp);
        setSrc(url)
    }
    else {
      setSrc('')
    }
  },[selectedApp])
  

  const [height, iframSrc] = React.useMemo(() => {

   // console.log(`%c src: ${ src }`,  'color: blue; font-weight: bold;')
    
    return [size[1], src];
  },[size, src]) 


  const rootStyle = React.useMemo(() => {
    return {
      height:    height,
      transform: `translate(${ componentsState.showAppIframe ? 0 : 600 }vw, 0vh)`
    }
  },[height, componentsState.showAppIframe]) 


  const navigate = useNavigate();

  const goToGrid = React.useCallback(() => {

    recordEvent('app', 'app closed', componentsState.appUrl)

    setComponentsState({
      ...componentsState, 
      showAppIntro:  false,
      showMenu:      true,
      showNavbar:    true,
      showFooter:    true,
      showAppIframe: false,
      appUrl:        '',
    })

    navigate("../", { replace: true });
    setSelectedApp({ selectedApp: {} })

  },[navigate, componentsState, setSelectedApp, setComponentsState])


  React.useEffect(() => {

    const handleMessage = (evt: any) => {
      if(evt && evt.data && evt.data === "close_window"){
        console.log(`%c evt: ${ JSON.stringify(evt.data, null, 2) }`,  'color: green; font-weight: bold;')
        goToGrid()
       }
    }
    
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage, false); 
  }, [goToGrid]);   


  return (
    <>
    {
      !!src && !dev &&
      <>
          <div 
              className = { classes.loaderContainer }
              style     = { rootStyle }
          >
              <Loader/>
          </div>
          <div 
            className = { classes.root }
            style     = { rootStyle }
          >
            <div className = { classes.iframe }>
                <iframe 
                    title           = 'I-See-Math'
                    width           = '100%'
                    height          = '100%'
                    src             = { iframSrc }
                    frameBorder     = '0'
                    allow-scripts   = 'true'
                    allowFullScreen
                />
            </div>
        </div>
    </>
    }
    {
      !src && <div className = { classes.nosrc }></div>
    }
    <Tips 
       selectedApp = { selectedApp }
       guest       = { guest }
    /> 
    {
      dev && !!src && <div className = { classes.dev } onClick = { goToGrid }/>
    }
  </>
  );
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    componentsState: reducer.components,
    userInfo:        reducer.user.userInfo,
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setComponentsState: (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppPage);

