
import React                from 'react';
import { store }            from 'index';
import { GET_APPS_SAGA }    from 'actions/content_actions';
import { userAccessType }   from 'types';

const useGetApps = (
    userEmail:      string,
    guest:          boolean,
    userAccess:     userAccessType,
    getAppsOnce:    boolean,
    setGetAppsOnce: (val: boolean) => void
) => {


    const state           = store.getState();
    const userInfoUpdated = state.data.dataUpdateState.user;
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const userInfo        = state.user.userInfo;


    React.useEffect(() => {

      if (userInfoUpdated && !studentLoggedIn && !getAppsOnce ) {

        const payload = {
          userEmail:       userEmail,
          guest:           guest ? true : false,
          subscriber:      !userAccess.denyAccess || userAccess.freeUser,
          studentOpenApps: userInfo.studentOpenApps
        }
    
        setGetAppsOnce(true)
        store.dispatch(GET_APPS_SAGA(payload))
      }
    
    },[userInfoUpdated, studentLoggedIn, getAppsOnce, userEmail, guest, userInfo, userAccess, setGetAppsOnce])

}

export default useGetApps;