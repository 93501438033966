import React          from 'react';
import { colors }     from '../../../../utils/colors';
import { makeStyles } from 'makeStyles';

const Star          = 'https://i-see-geometry-images.s3.amazonaws.com/animations/star.svg'
const Pentagon      = 'https://i-see-geometry-images.s3.amazonaws.com/animations/pentagon.svg'
const Hexagon       = 'https://i-see-geometry-images.s3.amazonaws.com/animations/hexagon.svg'
const Meshuba       = 'https://i-see-geometry-images.s3.amazonaws.com/animations/meshuba.svg'
const Fraction1     = 'https://i-see-geometry-images.s3.amazonaws.com/animations/fraction1.png'
const Fraction2     = 'https://i-see-geometry-images.s3.amazonaws.com/animations/fraction2.png'
const Fraction3     = 'https://i-see-geometry-images.s3.amazonaws.com/animations/fraction3.png'
const Int1          = 'https://i-see-geometry-images.s3.amazonaws.com/animations/int1.png'
const Int2          = 'https://i-see-geometry-images.s3.amazonaws.com/animations/int2.png'
const Int3          = 'https://i-see-geometry-images.s3.amazonaws.com/animations/int3.png'
const QuestionMark  = 'https://i-see-geometry-images.s3.amazonaws.com/animations/questionMark.png'


//const speed   = 1200;
const speed   = 1600;
const arr     = [[0, 100, -100], [-100, 0, 100], [100, -100, 0]];
const shapes  = [Star, Int1, Meshuba, QuestionMark, Pentagon, Int2, Hexagon, Int3, Pentagon]
const shapes2 = [Star, Fraction1, Meshuba, QuestionMark, Pentagon, Fraction2, Hexagon, Fraction3, Pentagon]

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      border:       `1px solid ${ colors.primary }`,
      borderRadius: '0.5em',
      padding:      40,
      overflow:     'hidden',
      transition:   `transform ${ speed/2000 }s, opacity ${ speed/1000 }s`
    },
    inner: {
      width:              '100%',
      display:            'flex',
      alignItems:         'center',
      justifyContent:     'center',
      backgroundSize:     'contain',
      backgroundPosition: 'center',
      backgroundRepeat:   'no-repeat',
    }
}))

type variableProps = {
  dimensions: number;
  variant:    string;
}

const Variables: React.FC<variableProps> = ({ dimensions, variant }) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos]     = React.useState(0)
  const [change, setChange]             = React.useState(0)
  const [counter, setCounter]           = React.useState(0)
  const [stopCounting, setStopCounting] = React.useState(1)


  const images = React.useMemo(() => {
     return variant === 'Variables' ? shapes : shapes2
  },[variant])


  React.useEffect(() => {

    const timeOut = setTimeout(() => {
      setInitialPos(1)
    },10)
    return () => { clearTimeout(timeOut) }

  },[])


  React.useEffect(() => {

    if (!change) {

      if (!stopCounting) {
          setTimeout(() => {
             if (counter < 2) {
                 let newCounter = counter;
                 setCounter(newCounter + 1)
             }
             else {
                 setCounter(0)
             }
           },speed / 4)
        setStopCounting(1)
      }
    
      setTimeout(() => {
        setChange(1)
      },speed)

      setTimeout(() => {
        setChange(2)
        setStopCounting(0)
      },speed * 2)

      setTimeout(() => {
        setChange(0)
      },speed * 3)

    }

    

  },[change, counter, stopCounting, setChange, setCounter, setStopCounting])


  return (
       <div 
          className = { classes.root }
          style     = {{
             height:    dimensions,
             width:     dimensions,
             opacity:   initialPos,
             transform: `translate(0px, ${ initialPos ? 0 : 100 }vh)`
          }}
       >
         {
           arr.map((val, ind) => {
             return(
               <div 
                  key       = { `variable__${ ind }` }
                  className = { classes.inner }
                  style     = {{
                    height:          dimensions,
                    transform:       `translate( ${ val[change] }vw, 0px) rotate(${ change === ind ? 0 : -270 }deg)`,
                    marginTop:       !ind ? 0 : -dimensions,
                    opacity:         change === ind ? 1 : 0,
                    backgroundImage: `url(${ images[ind * 3 + counter] })`,
                    transition:      `transform ${ speed/1000 }s, opacity ${ speed/2000 }s`
                  }}
               />
             )
           })
         }
       </div>
  );
}

export default Variables;


