import React          from 'react';
import Button         from 'pages/home/Button';
import useRow1Styles  from './useRow1Styles';
import { makeStyles } from 'makeStyles';



const useStyles = makeStyles()(() => ({    
    root: {
        width:              '100vw',
        backgroundPosition: 'auto',
        backgroundSize:     '100%',
        backgroundRepeat:   'no-repeat',
        display:            'flex',
        justifyContent:     'center',
    },
    filter: {
        width:      '100vw',
        height:     '100vh',
        background: '#FFFFFF77',
        position:   'absolute',
    },
    container: {
        position:        'absolute',
        left:            0,
        flexFlow:        'column',
        display:         'flex',
        alignItems:      'left',
        zIndex:          1,
        justifyContent:  'space-between',
        background:      '#FFFFFF88',
    },
    logoContainer: {
        display:      'flex',
        alignItems:   'left',
        width:        '100%',
        marginBottom: 40,
    },
    textContainer: {
        display:         'flex',
        flexFlow:        'column',
        justifyContent: 'flex-end',
        flex:            1,
        height:          105,
        minHeight:       70,
    },
    logo: {
          width:              130,
          height:             105,
          minHeight:          70,
          backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
          backgroundPosition: 'top',
          backgroundSize:     'contain',
          backgroundRepeat:   'no-repeat'
    },
    text: {
        color:        '#000',
        letterSpacing: 1,
        fontWeight:    'bold',
    },
    text2: {
        fontSize:      35,
        color:         '#000',
        letterSpacing: 1,
        lineHeight:    1.5,
        fontWeight:    'bold',
        paddingBottom: 40
    },
    
      
}))


type Row1Props = {
    enableAudio:       boolean;
    wideScreen:        boolean;
    handleNavBarClick: (val: string) => void;

}
 
const Row1: React.FC<Row1Props> = ({
    enableAudio,
    wideScreen,
    handleNavBarClick,
  }) => {


  const { classes } = useStyles();

  const [rootStyle, containerStyle, textContainerStyle, text2Style] = useRow1Styles(wideScreen);

  return (
       
       <div 
          className = { classes.root }
          style     = { rootStyle } 
       >
            {
              wideScreen && <div className = { classes.filter }/>
            }
            <div 
               className = { classes.container }
               style     = { containerStyle }
            >
                    <div className = { classes.logoContainer }>
                        <div className = { classes.logo }/>
                        <div 
                            className = { classes.textContainer }
                            style     = { textContainerStyle }
                        >
                            <div className = { classes.text }>
                               Classroom Tools
                            </div>
                            <div className = { classes.text }>
                               for Math Teachers
                            </div>
                        </div>
                    </div>
                    <div 
                       className = { classes.text2 }
                       style     = { text2Style }
                    >
                       Effective use of class time, while promoting students' understanding, engagement and enjoyment
                    </div>
                    <Button 
                       variant           = { "goToApps" }
                       wideScreen        = { wideScreen }
                       enableAudio       = { enableAudio }
                       handleNavBarClick = { handleNavBarClick }
                    /> 
            </div>
        </div>
         
  );
}


export default Row1;
