import { useMemo } from 'react';
import { sizes }   from 'utils/defaultStates';

const useStyle = (
    dropDownitems:       string[],
    size:                number[],
    dropDownButtonWidth: number,
    bigNavBar:           boolean,
    open:                boolean
) => {

  const [selectorStyle, menuStyle, itemStyle, iconStyle] = useMemo(() => {


    const { navBarSize, footerSize } = sizes;
    const scrollRequired = ((50 * dropDownitems.length) >= size[1] - navBarSize);


    return [
      {
        height:   navBarSize,
        width:    dropDownButtonWidth,
      },
      {
        top:     navBarSize,
        height:  !open ? 0 : Math.min((50 * (dropDownitems.length)), (size[1] - navBarSize - footerSize - 2)),
        opacity: !open ? 0 : 1
      },
      {
        marginLeft: scrollRequired ? 5 : 0,
      },
      {
        fontSize:   bigNavBar ? 50 : 45,
        transform:  `translate(3px, 0px)rotate(${ open ? -180 : 0 }deg)`,
        transition: 'transform 0.4s'
      }
    ]
  },[ open, bigNavBar, size, dropDownitems, dropDownButtonWidth ])

  return { selectorStyle, menuStyle, itemStyle, iconStyle }
}




export default useStyle;



