
import { store }                from '../../index';
import { SET_CONTENT }          from '../../actions';
import { SET_COMPONENTS_STATE } from '../../actions/component_actions';
import { SELECT_CONTENT } from 'actions/content_actions';

const showAppLessons = () => {

    const state = store.getState();
    const componentsState = state.components;

    store.dispatch(SET_CONTENT({ 
        allContent: componentsState.appLessons,
        sender:    'showAppLessons'
    }))

    store.dispatch(SELECT_CONTENT({ 
        selectedApp: {}
    }))

    store.dispatch(SET_COMPONENTS_STATE({
        ...componentsState,
        showAppIntro: false,
        appLessons:   [],
        showMenu:     true,
    }))
}

export default showAppLessons