
export const makeGuestApps = (apps: { [key: string]: any }[], arr: string[]) => {
   for ( let i = 0; i < apps.length; i++ ) {
      apps[i].totalLikes = 0;
      for ( let j = 0; j < arr.length; j++ ) {

          if ( apps[i].lesson_id === arr[j] ) {
              if ( arr[j] === 'Circle_circumference' ) {
                 apps[i].totalLikes = 20;
              }
              else {
                apps[i].totalLikes = 10;
              }

          }
      }
   }

   return apps;
}
