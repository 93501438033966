import React                           from 'react';
import { connect }                     from 'react-redux';
import { Dispatch }                    from "redux";
import { ActionTypes }                 from 'actions/types';
import { reducerType }                 from 'reducer';
import { 
  contentInfoType, 
  userInfoType 
}                                      from 'types';
import { componentsStateType }         from 'reducer/types';
import recordEvent                     from 'functions/contentInteraction/record_event'
import { colors }                      from 'utils/colors';
import VolumeUpRoundedIcon             from '@mui/icons-material/VolumeUp';
//import CloseSharpIcon                  from '@mui/icons-material/CloseSharp';
import { makeStyles }                  from 'makeStyles';
import { SET_AUDIO_STATE }             from 'actions/audio_actions';
import { UPDATE_ONE_TIME_EVENTS_SAGA } from 'actions/userInfo_actions';


const useStyles = makeStyles()((Theme) => ({
    item: {
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      flexFlow:         'column',
      marginTop:        10,
      marginBottom:     10,
      overflow:         'hidden',
      cursor:           'pointer',
      border:           '1px solid #DDD',
      borderRadius:     '0.4em',
      boxShadow:        '0px 4px 10px rgba(0,0,0,0.15)',
      backgroundColor:  '#FFF',
      color:            colors.primary,
      fontWeight:       'bold',
      transition:       'transform 0.4s, height 0.4s',
    },
    textContainer: {
      width:            '100%',
      height:           45,
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      marginTop:        3,
      marginBottom:     3,
    },
    text: {
      '&:hover': {
        opacity: 0.5
      }
    },
    exContainer: {
      flex:             1,
      height:           '100%',
      display:          'flex',
      alignItems:       'top',
      justifyContent:   'flex-end',
    },
    ex: {
      '&:hover': {
        opacity: 0.5
      }
    },
    button: {
      minHeight:        40,
      maxHeight:        40,
      height:           40,
      width:            '100%',
      borderTop:        '1px solid #DDD',
      display:          'flex',
      alignItems:       'center',
      justifyContent:   'center',
      cursor:           'pointer',
      '&:hover': {
        color: colors.primaryBright
      }
    },
    icon: {
      marginLeft: 5,
      '&:hover': {
        opacity: 0.5
      }
    }
}))

type TipProps = {
  val:                 string;
  selectedApp:         contentInfoType;
  componentsState:     componentsStateType;
  userInfo:            userInfoType;
  guest:               boolean;
  setAudioState:       (payload: contentInfoType) => void;
  updateOneTimeEvents: (payload: { userEmail: string, events: string }) => void;
}

const Tip: React.FC<TipProps> = ({ 
  val,
  selectedApp,
  componentsState,
  userInfo,
  guest,
  setAudioState,
  updateOneTimeEvents
}) => {

  //componentsState.appUrl

  const { classes } = useStyles();

  const [showTip, setShowTip]         = React.useState(true);
  const [audioPlayed, setAudioPlayed] = React.useState(false);

  const tipActiv = React.useMemo(() => {
    return (
      audioPlayed ||
      (
        componentsState.showAppIframe && 
        !!selectedApp.origin && 
        selectedApp.origin === 'apps' && 
        selectedApp.app_type !== 1 &&
        selectedApp.app_type !== 2 &&
        (
          (!userInfo.oneTimeEvents.includes('topToolBarTip')) ||
          (userInfo.subscriber && !userInfo.oneTimeEvents.includes('savingLessonTip'))
        )
      )
    )
  },[ componentsState,  selectedApp, userInfo, audioPlayed])



 


  const handleAudioClick = () => {
       
       setAudioPlayed(true)

       setAudioState({
        playAudio:              false,
        pauseAudio:             true,
        appMenuAudioPlayedOnce: true,
        audioSrc:               `https://iseemath.co/audio/${ val }.wav`,
      })
       
       setTimeout(() => {
         setAudioState({
           playAudio:  true,
           pauseAudio: false,
         })
       },50)

       recordEvent('audio', 'audio played', `https://iseemath.co/audio/${ val }.wav`)
  }

  const handleUpdateOneTimeEvents = () => {
    let newEvents = userInfo.oneTimeEvents;

    if ( !newEvents.includes(val) ) {
     newEvents.push(val);
     const newEventsStr = JSON.stringify(newEvents);
     const payload = { 
       userEmail: userInfo.userEmail,
       events:    newEventsStr
     }
     updateOneTimeEvents(payload)
    }
  }


  const handleClose = (permanent: boolean) => {
    setShowTip(false)
    setAudioPlayed(false)
    if ( permanent ) {
        setTimeout(() => {
            handleUpdateOneTimeEvents();
        },200)
    }
  }


  const [itemStyle] = React.useMemo(() => {
    return[
      {
        transform: `translate(${ showTip ? 0 : -100 }vw, 0vh)`,
        width:     tipActiv ? 300 : 0,
      //  height:    90 + (arr.length * 40)
      }
    ]
  },[showTip, tipActiv/* , arr */])


  
  return (
    <div 
        className = { classes.item }
        style     = { itemStyle }
    >
        <div className = { classes.textContainer }>
            {/* <div className = { classes.exContainer }/> */}
            <div 
               className = { classes.text }
               onClick   = { handleAudioClick }
            >
              { val.replaceAll('_', ' ') }
            </div>
            <VolumeUpRoundedIcon className = { classes.icon } onClick = { handleAudioClick }/>
            {/* <div className = { classes.exContainer }>
              <CloseSharpIcon 
                className = { classes.ex }
                onClick   = { () => { handleClose(false) }}
              />
            </div> */}
        </div>
        <div 
            className = { classes.button }
            onClick   = { () => { handleClose(true) }}
        >
          {
            `${ guest ? "close" : "don't show this tip again" }`
          } 
        </div>
    </div>

  );
}


const mapStateToProps = (reducer: reducerType) => {
  return {
    componentsState: reducer.components,
    userInfo:        reducer.user.userInfo
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setAudioState:       (payload: contentInfoType) => dispatch(SET_AUDIO_STATE(payload)),
    updateOneTimeEvents: (payload: { userEmail: string, events: string }) => dispatch(UPDATE_ONE_TIME_EVENTS_SAGA(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tip);

