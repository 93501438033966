import React              from 'react';
import { BrowserRouter }  from 'react-router-dom';
import ReactDOM           from "react-dom/client";
import { Provider }       from 'react-redux';
import { configureStore } from './store/configureStore';
import App                from './App';
import reportWebVitals    from './reportWebVitals';

import './index.css';

export const store = configureStore();

const element = document.getElementById("root");

if (element !== null) {
   const root = ReactDOM.createRoot(element);
   root.render(
      <React.StrictMode>
         <Provider store = { store }>
            <BrowserRouter>
              <App/>
            </BrowserRouter>
         </Provider>
      </React.StrictMode>,
   );
}





/**
 * 
 * ReactDOM.render(
   <React.StrictMode>
      <Provider store = { store }>
         <BrowserRouter>
            <App/>
         </BrowserRouter>
      </Provider>
   </React.StrictMode>,
  document.getElementById('root')
);
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

