import { contentInfoType } from "../../../types";
import handleGoToPuzzle    from './handle_go_to_puzzle'
import handleExit          from './handle_exit'


const handleNextTest = (
    navigate:            any,
    questionSet:         contentInfoType[],
    questionObj:         contentInfoType,
    selectedApp:         contentInfoType,
    testNum:             number,
    setInitialPos:       (val: boolean) => void,
    setNumValuesSet:     (val: boolean) => void,
    setInteger:          (val: boolean) => void,
    setShowPracticeComp: (val: boolean) => void,
    setAnimateImage:     (val: boolean) => void,
    setAnimateInput:     (val: boolean) => void,
    setSelectedAnswer:   (val: number) => void,
    setSuccess:          (val: string) => void,
    setNumber:           (val: string) => void,
    setNumerator:        (val: string) => void,
    setDenominator:      (val: string) => void,
    setInputInteger:     (val: string) => void,
    setInputNumerator:   (val: string) => void,
    setInputDenominator: (val: string) => void, 
    setTestNum:          (val: number) => void,
    setQuestionObj:      (val: contentInfoType) => void,
    selectContent:       (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void
) => {

    if (testNum === questionSet.length - 1) { 
      if ( questionObj.question_type === "puzzle" ) {
          handleGoToPuzzle(navigate, selectedApp, questionSet, setInitialPos, setNumValuesSet, setInteger, setShowPracticeComp, setInputInteger, setSelectedAnswer, setSuccess, setTestNum, setQuestionObj, selectContent);
      }
      else {
          handleExit(navigate, 'backToGrid', questionSet, setInitialPos, setNumValuesSet, setInteger, setShowPracticeComp, setInputInteger, setSelectedAnswer, setSuccess, setTestNum, setQuestionObj);
      }
      return;
    }

    const newTest = testNum + 1;

    if ( questionObj.image !== questionSet[newTest].image ) { 
      setAnimateImage(true); 
    }

    setAnimateInput(true);

    setTimeout(() => {
      setNumber('')
      setNumerator('')
      setDenominator('')
      setInputInteger('')
      setInputNumerator('')
      setInputDenominator('') 
      setSelectedAnswer(-1);
      setSuccess('');
      setTestNum(newTest)
      setNumValuesSet(false)
      setQuestionObj(questionSet[newTest])
    },300)
  }


export default handleNextTest;