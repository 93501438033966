import React                    from 'react';
import Iframe                   from './VideoIframe';
import { componentsStateType }  from 'reducer/types';
import tutorialsArr             from 'content/tutorials';
import { colors }               from 'utils/colors';
import { sizes, introVideoSrc } from 'utils/defaultStates';
import { makeStyles }           from 'makeStyles';

const titleHeight   = 60;

const useStyles = makeStyles()(
  (Theme) => ({    root: {
        position:        'absolute',
        right:           0,
        display:         'flex',
        flexFlow:        'column',
        backgroundColor: colors.brightGrey,
        transition:      'opacity 0.7s',
    },
    title: {
        height:          titleHeight,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        fontWeight:      'bold',
        color:           '#334',
        fontSize:        25,
        backgroundColor: colors.brightGrey,
    },
    iframeContainer: {
        flex:      1,
        display:   'flex'
    },
/*     footer: {
        height:          footerHeight,
        borderTop:       '1px solid #CCC',
        backgroundColor: colors.brightGrey,
    }, */
    backButton: {
        position:        'fixed',
        height:          40,
        maxWidth:        40,
        minWidth:        40,
        flex:            1,
        marginLeft:      5,
        marginRight:     5,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        cursor:          'pointer',
        fontWeight:      'bold',
        color:           '#FFF',
        borderRadius:    '50%',
        backgroundColor: colors.primary,
        '&:hover': {
           opacity: 0.5
        }
      },
}))

type ContainerProps = {
    size:                 number[];
    menuSize:             number;
    wideScreen:           boolean;
    phaseOutTutorial:     boolean;
    componentsState:      componentsStateType;
    studentVideos:        { [key: string]: any }[];
    setPhaseOutTutorial:  (val: boolean) => void;
    setShowTutorialVideo: (val: boolean) => void;


}
const Container: React.FC<ContainerProps> = ({ 
    size,
    menuSize,
    wideScreen,
    componentsState,
    studentVideos,
    phaseOutTutorial,
    setPhaseOutTutorial,
    setShowTutorialVideo,
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false);

  
  React.useEffect(() => {
    setInitialPos(true)
  },[])

  React.useEffect(() => {
      if (phaseOutTutorial) {
         setPhaseOutTutorial(false)
         setInitialPos(false)
         const timeOut = setTimeout(() => {
          setShowTutorialVideo(false)
        },720)
        return () => { clearTimeout(timeOut) }
      }
  },[phaseOutTutorial, setPhaseOutTutorial, setShowTutorialVideo])


  const video = React.useMemo(() => {

    if (!!componentsState.showStudentVideo && !!studentVideos[componentsState.selectedVideo] && !!studentVideos[componentsState.selectedVideo].ism_channel ) {
       return studentVideos[componentsState.selectedVideo].ism_channel
    }
    if ( tutorialsArr[componentsState.selectedVideo] ) {
        return tutorialsArr[componentsState.selectedVideo]
    }
    return tutorialsArr[0];

 },[componentsState, studentVideos])


  const src = React.useMemo(() => {

    if ( componentsState.selectedContent === 'introVideo' ) {
      return introVideoSrc;
    }
    return `https://www.youtube.com/embed/${ !!video.youTube ? video.youTube : video }?rel=0&start=${ !!video.time ? video.time : 0 }`;

  },[video, componentsState])


  const [rootStyle, titleStyle] = React.useMemo(() => {

       const titleWidth = size[0] - 150 - ( wideScreen ? menuSize : 0 );

       const { navBarSize, tabSize, footerSize } = sizes;

       return [
           {
              top:     navBarSize + tabSize,
              height:  size[1] - navBarSize - tabSize - footerSize,
              width:   wideScreen ? size[0] - menuSize : size[0],
              opacity: initialPos ? 1 : 0
           },
           {
             fontSize:  size[0] > 900 ? 23 : 20,
             maxWidth:  titleWidth,
             textAlign: 'center' as 'center'
           }
       ]
  },[size, menuSize, wideScreen, initialPos])



  

  
  return (
   
    <div 
        className = { classes.root }
        style     = { rootStyle }
    >
        { 
              componentsState.selectedContent !== 'introVideo' && componentsState.selectedVideo !== -1
              &&
              <div className = { classes.title }>
                  <div style = { titleStyle }> 
                    { /* filterState.selectedVideo === -1 ? '' :  */video.title ? video.title : '' }
                  </div>
              </div>
        }
        <div className = { classes.iframeContainer } >
            <Iframe 
               width  = { rootStyle.width }
               height = { rootStyle.height - titleHeight - sizes.footerSize }
               src    = { src }
            />
        </div>
    </div>
  );
}


export default Container;

