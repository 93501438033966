import React                    from 'react';
import { connect }              from 'react-redux';
import { useNavigate }          from 'react-router-dom'
import { Dispatch }             from "redux";
import { ActionTypes }          from 'actions/types';
import { reducerType }          from 'reducer';
import { menuClick }            from 'functions/menu/menu_click'
import { topicClick }           from 'functions/menu/topic_click';
import navBarClick              from 'functions/navBar/nav_bar_click';
import recordEvent              from 'functions/contentInteraction/record_event';
import AudioDropDown            from './AudioDropDown';
import Tab                      from './Tab';
import HelpOutlineIcon          from '@mui/icons-material/HelpOutline';
import { colors }               from 'utils/colors';
import { sizes }                from 'utils/defaultStates';
import { words }                from 'utils/dictionary';
import { makeStyles }           from 'makeStyles';
import { contentInfoType }      from 'types';
import { 
   audioStateType,
   componentsStateType, 
   pageStateType 
}                               from 'reducer/types';
import { SET_MODAL_STATE }      from 'actions/modal_actions'
import { SELECT_CONTENT }       from 'actions/content_actions';

const other = 'אחר';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      position:        'fixed',
      right:           0,
      height:          sizes.tabSize - 1,
      display:         'flex',
      color:           colors.darkText,
      fontSize:        16,
      backgroundColor: colors.brightGrey,
      transition:      'top 0.5s, background 0.5s',
    },
    tabContainer: {
        flex:       1,
        display:    'flex',
        alignItems: 'center',
    },
    iconContainer: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
    },
    help: {
      color: colors.primary,
      cursor: 'pointer',
      '&:hover': {
          opacity: 0.4
      }
    }
}))

type TabsProps = {
  size:               number[];
  menuSize:           number;
  componentsState:    componentsStateType;
  pageState:          pageStateType;
  audioState:         audioStateType;
  selectedApp:        contentInfoType;
  wideScreen:         boolean;
  showNavbar:         boolean;
  studentLoggedIn:    boolean;
  AudioRef:           any;
  setChangeContent:   (val: boolean) => void;
  selectContent:      (payload: { [key: string]: any; }) => void;
  setModalState:      (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
}

const Tabs: React.FC<TabsProps> = ({
  size,
  menuSize,
  componentsState,
  pageState,
  audioState,
  selectedApp,
  wideScreen,
  showNavbar,
  studentLoggedIn,
  AudioRef,
  setChangeContent,
  selectContent,
  setModalState
}) => {
  
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [initialPos, setInitialPos] = React.useState(false)
  
  React.useEffect(() => {
    const showTabs = showNavbar //&& pageState.page !== 'about us'
    setInitialPos(showTabs)
  },[showNavbar, pageState])


  const appName = React.useMemo(() => {
    if ( !!selectedApp.lesson_id && selectedApp.lesson_id === 'Who_is_the_whole' ) {
      return `What's the Unit`
    }
    return !!selectedApp.app_eng ? selectedApp.app_eng : !!selectedApp.name ? selectedApp.name : '';
  },[selectedApp])
  


  const rootTabClick = () => {

    const val = componentsState.selectedContent;

    if (studentLoggedIn) {
       return
    }

    if (['favorites', 'userLessons', 'myStudentsContent'].includes(val)) {
       return
    }


    if ( pageState.page === 'about us' ) {
      navBarClick( 'apps', setChangeContent )
      navigate(`../Apps`, { replace: true });
      selectContent({
         selectedAppIntro : "",
         selectedApp      : {},
         selectedPuzzle   : {}
       })
      recordEvent('navigation button', 'tab clicked', 'apps tab')
      return
    }


    navigate(`../${ val }`, { replace: true });

 
    if ( val === 'groupLessons' ) {
         setModalState({ variant: 'groups', open: true, showButtons: true })
         return;
    }

    menuClick( val, val, wideScreen, false, setChangeContent)
    selectContent({
      selectedAppIntro : "",
      selectedApp      : {},
      selectedPuzzle   : {}
    })
    recordEvent('navigation button', `${ val } tab clicked`, `${ val } tab`)
  }


  const handleTopicClick = ( val: string ) => {
   navigate(`/${ !!words[val]['eng'] ? words[val]['eng'] : val }`, { replace: true });
   topicClick( 'topic', val, setChangeContent)
   const topic = words[`${ val }`] ? words[`${ val }`]['eng'] : val;
   recordEvent('navigation button', `topic tab clicked`, topic)
 }

 const handleSubTopicClick = ( val: string ) => {
   topicClick( 'subTopic', val, setChangeContent)
   const subTopic = words[`${ val }`] ? words[`${ val }`]['eng'] : val;
   recordEvent('navigation button', `subTopic tab clicked`, subTopic)
 }

 const handleHelpClick = () => {
   setModalState({ variant: 'help', open: true, showButtons: true })
   recordEvent('button', `help button clicked`, `help button`)
 }

  const [content, topic, subTopic] = React.useMemo(() => {
     const  { selectedContent ,selectedAppTopic ,selectedAppSubTopic } = componentsState;
     return [ pageState.page === 'about us' ? 'I See Math Apps' : selectedContent ,selectedAppTopic ,selectedAppSubTopic ]
  },[componentsState, pageState])


  const showHelp = React.useMemo(() => {

     if (
         (!studentLoggedIn && pageState.page !== 'about us') || 
         (studentLoggedIn && content === 'bonus')
     ) {
        return true
     }
     return false
   },[studentLoggedIn, content, pageState])



  const [rootStyle, tabContainerStyle, iconContainerStyle, iconStyle] = React.useMemo(() => {

    const wideScreen__ = (size[0] > 1000 && size[1] > 500);

    return[
       {
        top:             initialPos ? sizes.navBarSize : -(sizes.navBarSize + 50),
        fontSize:        wideScreen ? undefined : 15,
        width:           pageState.page === 'about us' ? wideScreen__ ? size[0] - menuSize - 1 : size[0] : wideScreen ? size[0] - menuSize - 1 : size[0],
        backgroundColor: pageState.page === 'about us' ? '#FFF' : colors.brightGrey,
        borderBottom:    pageState.page === 'about us' ? wideScreen__ ? undefined : '1px solid #CCC' : '1px solid #CCC',
        borderLeft:      pageState.page === 'about us' ? '1px solid #DDD' : undefined
       },
       {
        paddingLeft: size[0] > 1200 ? 35 : 10,
       },
       {
        minWidth: size[0] > 600 ? 75 : 43,
        maxWidth: size[0] > 600 ? 75 : 43,
       },
       {
        fontSize: size[0] > 600 ? 37 : 32,
       }
    ]
  },[size, wideScreen, menuSize, initialPos, pageState])

  
  return (
        <div 
            className = { classes.root }
            style     = { rootStyle }
        >
            <div 
               className = { classes.tabContainer } 
               style     = { tabContainerStyle }
            >
               {
                  !!content &&
                  <Tab
                    variant     = { 'root' }
                    content     = { content }
                    handleClick = { rootTabClick }
                  />
               }
               {
                   content === 'I See Math Apps' && 
                   <Tab
                    variant     = { 'aboutUs' }
                    content     = { '' }
                    handleClick = { () => {} }
                  />
               }
               {
                   !studentLoggedIn && !!topic.length && 
                   <Tab
                    variant     = { 'topic' }
                    content     = { topic }
                    handleClick = { () => handleTopicClick(topic) }
                   />
               }
               {
                   !studentLoggedIn && !!subTopic && topic !== other && 
                   <Tab
                    variant     = { 'subTopic' }
                    content     = { subTopic }
                    handleClick = { () => handleSubTopicClick(subTopic) }
                   />
               }
               {
                   !studentLoggedIn && !subTopic && !!topic && topic !== other &&
                   <Tab
                    variant     = { 'allTopics' }
                    content     = { '' }
                    handleClick = { () => handleTopicClick(topic) }
                   />
               }
               {
                   (content === 'apps' || content === 'games' || content === 'lessons') && size[0] > 1000 &&
                   !!Object.keys(selectedApp).length &&
                   <Tab
                    variant     = { 'app' }
                    content     = { appName }
                    handleClick = { () => {} }
                   />
               }
            </div>
            {
               showHelp && audioState.audioGuideOn && !studentLoggedIn &&
               <AudioDropDown
                 AudioRef = { AudioRef }
               />
            }  
            {
               showHelp &&
               <div 
                  className = { classes.iconContainer } 
                  style     = { iconContainerStyle }
               >
                  <HelpOutlineIcon 
                     className = { classes.help } 
                     style     = { iconStyle }
                     onClick   = { handleHelpClick } 
                  />
               </div>
            }
        </div>          
  );
}




const mapStateToProps = (reducer: reducerType) => {
  return {
    componentsState: reducer.components,
    showNavbar:      reducer.components.showNavbar,
    selectedApp:     reducer.content.selectedApp,
    pageState:       reducer.page,
    studentLoggedIn: reducer.user.studentLoginState.loginState === 'success',
    audioState:      reducer.audio
}};


const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setModalState:      (payload: { variant: string, open: boolean, showButtons: boolean }) => dispatch(SET_MODAL_STATE(payload)),
  selectContent:      (payload: { [key: string]: any; }) => dispatch(SELECT_CONTENT(payload)),
});



export default connect(mapStateToProps, mapDispatchToProps)(Tabs);







