import React          from 'react';
import { colors }     from 'utils/colors';
import { makeStyles } from 'makeStyles';


const useStyles = makeStyles()((Theme) => ({    
  root: {
       position:       'absolute',
       transition:     'all 0.4s',
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'center',
    },
    inner: {
      borderRadius: '0.5em',
      border:       `1px solid ${ Theme.primary }`
    }
}))

type GridUnitProps = {
    positions:  number[][];
    unit:       number;
    val:        number;
    ind:        number;
    change:     number;
    initialPos: boolean;
}

const GridUnit: React.FC<GridUnitProps> = ({ initialPos, positions, unit, val, ind, change }) => {

  const { classes } = useStyles();

  const [top, left, trans0, trans1, trans2 ] = React.useMemo(() => {

      const top              = ind > 2 ? unit : 0;
      const left             = (ind > 2 ? ind - 3 : ind) * unit;
      const transitionTop    = positions[ind][0] * unit;
      const transitionLeft1  = positions[ind][1] * unit;
      const transitionLeft2  = positions[ind][2] * unit;

      return[
        top,
        left,
        `translate(0px, 0px)`,
        `translate(${ transitionLeft1 }px, ${ transitionTop }px)`,
        `translate(${ transitionLeft2 }px, ${ transitionTop }px)`
      ]
  },[unit, ind, positions])


  return (
    <div 
       className = { classes.root }
       style     = {{
        width:           unit,
        height:          unit,
        left:            left,
        top:             top,
        transform:       [trans0, trans1, trans2][change],
      }}
    >
        <div 
           className = { classes.inner }
           style     = {{
            backgroundColor: val ? colors.primary : '#FFF',
            width:           initialPos ? unit - 5 : 1,
            height:          initialPos ? unit - 5 : 1,
            transition:      `width ${ (ind + 1)/5 }s, height ${ (ind + 1)/5 }s`
           }}
        />
    </div>
  );
}

export default GridUnit;

