import React           from 'react';
import getFraction     from 'functions/getFraction';
import { colors }      from 'utils/colors';
import { makeStyles }  from 'makeStyles';

const fillBright = '#FFF';
const fillDark   = colors.primary;

const degs = [0, 0, -30, 180, 17.8, 30 ,38.5, 45, 50, 54, 57, 60];

const useStyles = makeStyles()((Theme) => ({    
  root: {
      position:     'absolute',
      cursor:       'pointer',
      transition:   'width 0.3s, height 0.3s',
      borderRadius: '50%',
      border:       `1px solid ${ fillDark }`
    },
    circle: {
      position:     'relative',
      height:       '100%',
      width:        '100%', 
      borderRadius: '50%',
      overflow:     'hidden',

    },
    half_circle: {
        width:           '100%',
        height:          '100%',
        marginLeft:      '50%',
        borderLeft:      '1px solid #FFF',
        backgroundColor: fillDark,
    },
    sector: {
        width:           '400px',
        height:          '400px',
        position:        'absolute',
        transformOrigin: '100% 100%',
        backgroundColor:  fillDark,
        borderRight:     '1px solid #FFF',
        borderBottom:    '1px solid #FFF'
    }
}))

type FractionImageProps = { 
  val:        number;
  dimensions: number;
}

const FractionImage: React.FC<FractionImageProps> = ({ dimensions, val }) => {

  const { classes } = useStyles();

  const [numerator, setNumerator]     = React.useState(1);
  const [denominator, setDenominator] = React.useState(1);

  React.useEffect(() => {
    const fractoin = getFraction(val)
    setNumerator(fractoin.numerator);
    setDenominator(fractoin.denominator);
  },[val, setNumerator, setDenominator])

  const size = React.useMemo(() => {
     return dimensions * 0.65;
  },[dimensions])

  return (
    <>
      {
      new Array(numerator).fill(0).map((value, index) => {
         return(
                <div
                   key       = { `fraction_${ index }` }
                   className = { classes.root }
                   style     = {{
                       width:     size,
                       height:    size,
                       transform: `rotate(${ val === 0.5 ? 0 : 90 + (index * (360 / denominator)) }deg)`
                    }}
                >
                    <div 
                    className = { classes.circle }
                    style     = {{
                        backgroundColor: val === 1 ? fillDark : (index === 0 ? fillBright : 'transparent')
                      }}
                    >
                        {
                            val === 0.5 && ( <div className = { classes.half_circle }/> )
                        }
                        {
                            val !== 0.5
                            &&
                            (
                                <div 
                                    className = { classes.sector }
                                    style     = {{
                                        transform:       `skew(${ degs[denominator-1] }deg) rotate(0deg)`,
                                        left:            '50%',
                                        top:             '50%',
                                        marginTop:       '-400px',
                                        marginLeft:      '-400px',
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
            )
         })
       }
    </>
  );
}

export default FractionImage;


