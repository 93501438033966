import React                          from 'react';
import { Dispatch }                   from "redux";
import { connect }                    from 'react-redux';
import { reducerType }                from 'reducer';
import SignUpComponent                from './signUp';
import SignInForm                     from './signIn';
import RecoverPwd1                    from './recover_pwd/RecoverPwd1';
import RecoverPwd2                    from './recover_pwd/RecoverPwd2';
import RecoverPwd3                    from './recover_pwd/RecoverPwd3';
import StudentLogIn                   from './student_login';
import TOS                            from 'components/forms/TermsOfService';
import PrivacyPolicy                  from 'components/forms/PrivacyPolicy';


import Loader                         from '../Loader';
import CloseSharpIcon                 from '@mui/icons-material/CloseSharp';

import handleClose                    from './functions/handle_close';
import navBarClick                    from 'functions/navBar/nav_bar_click';

import { 
  ActionTypes, 
  createUserType, 
  studentLoginType,
}                                     from 'actions/types';

import { 
         planTypeType, 
         studentLoginStateType, 
         userInfoType 
       }                              from 'types';
import { colors }                     from 'utils/colors';
//import removeSubscribePrompt          from "utils/functions/payments/remove_subscribe_prompt";
import { makeStyles }                 from 'makeStyles';
import { 
  CREATE_USER_SAGA, 
  STUDENT_LOGIN_SAGA 
}                                     from 'actions';
import { authStateType }              from 'reducer/types';
import { 
  SET_AUTH_LOADER, 
  SET_AUTH_STATE, SET_ERROR 
}                                     from 'actions/auth_actions';


const useStyles = makeStyles()((Theme) => ({  
    root : {
        position:        'fixed',
        left:            0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        transition:      'opacity 0.5s',
        transform:       'all 0.4s',
        overflowY:       'scroll',
        overflowX:       'hidden',
        zIndex:          2,
        '&::-webkit-scrollbar': {
          width: '0.5em'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#DDD`,
          borderRadius:    10
        }
    },
    authenticator: {
        marginLeft:      10,
        display:         'flex',
        justifyContent:  'space-evenly',
        flexFlow:        'column',
        backgroundColor: '#FFF',
        border:          '1px solid #DDD',
        borderRadius:    '0.25em',
        boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)'
    },
    iconContainer: {
      marginTop:       5,
      flex:             1,
      height:          40,
      minHeight:       40,
      maxHeight:       40,
      width:           '100%',
      display:         'flex',
      alignItems:      'flex-end',
      justifyContent:  'flex-end',
    },
    closeIcon: {
        color:       colors.blue,
        cursor:      'pointer',
        marginRight: 12,
        fontSize:    32,
        '&:hover': {
            opacity: 0.5
        }
    },
    loaderContainer: {
      position:        'absolute',
      backgroundColor: '#FFF',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      borderRadius:    '0.25em',
      border:          '1px solid #DDD',
     // transform:       'translate(0px, -25px)'
    }
}));



type AuthenticatorProps = {
    userInfo:           userInfoType;
    size:               number[];
    planType:           planTypeType;
    studentLoginState:  studentLoginStateType;
    authState:          authStateType;
    showGoogleButton:   boolean; 
    showLoader:         boolean; 
    showAuthForm:       boolean;
    userConfirmed:      boolean; 
    displayForm:        string;
    name:               string;
    email:              string;
    password:           string;
    userOrigin:         string;
    code:               string;
    grade:              string;
    error:              string; 
    setChangeContent:   (val: boolean) => void;
    setAuthState:       (payload: authStateType) => void;
    setAuthError:       (payload: string) => void;
    setAuthLoader:      (payload: boolean) => void;
    studentLogin:       (payload: studentLoginType) => void;
    createUser:         (payload: createUserType) => void;
    logOut:             () => void;

}

const Authenticator: React.FC<AuthenticatorProps> = ({ 
    userInfo,
    size,
    planType,
    studentLoginState,
    authState,
    showGoogleButton,
    showAuthForm,
    displayForm,
    name,
    grade,
    email,
    password,
    userOrigin,
    code,
    error,
    userConfirmed,
    showLoader,
    setChangeContent,
    createUser,
    setAuthState,
    setAuthError,
    setAuthLoader,
    studentLogin,
    logOut
}) => {
  
    const { classes } = useStyles();

    const setName             = React.useCallback((val: string) => { setAuthState({ ...authState, name: val })} ,[setAuthState,authState ])
    const setEmail            = React.useCallback((val: string) => { setAuthState({ ...authState, email: val })} ,[setAuthState,authState ])
    const setPassword         = React.useCallback((val: string) => { setAuthState({ ...authState, password: val })} ,[setAuthState,authState ])
    const setUserOrigin       = React.useCallback((val: string) => { setAuthState({ ...authState, userOrigin: val })} ,[setAuthState,authState ])
    const setCode             = React.useCallback((val: string) => { setAuthState({ ...authState, code: val })} ,[setAuthState,authState ])
    const setGrade            = React.useCallback((val: string) => { setAuthState({ ...authState, grade: val })} ,[setAuthState,authState ])
    const setDisplayForm      = React.useCallback((val: string) => { setAuthState({ ...authState, displayForm: val, error: '', showGoogleButton: true })} ,[setAuthState,authState ])
    const setUserConfirmed    = React.useCallback((val: boolean) => { setAuthState({ ...authState, userConfirmed: val })} ,[setAuthState,authState ])
    const setError            = React.useCallback((val: string) => { setAuthError(val) },[setAuthError])
    const setShowLoader       = React.useCallback((val: boolean) => { setAuthLoader(val) },[setAuthLoader])

    const [showTOS, setShowTOS]         = React.useState(false)
    const [showPrivacy, setShowPrivacy] = React.useState(false)
    const [tosChecked, setTosChecked]   = React.useState(false)
 
    
    React.useEffect(() => {
       if ( showLoader && studentLoginState.loginState === 'failure' ) {
          setError('Invalid teacher Email or code')
          setShowLoader(false)
          studentLogin({ email: '', code: '', name: '', grade: '', reset: true })
       }
       if ( showAuthForm && studentLoginState.loginState === 'success' ) {
        navBarClick( 'content', setChangeContent )
        handleClose();
       }
    },[studentLoginState, showLoader, showAuthForm, setError, setShowLoader, studentLogin, setChangeContent])


    const handleCreateUser = () => {
        console.log('creating user')
        createUser({ userEmail: email, userName: name, userOrigin: userOrigin})
    }
    

    
  
  
    const [rootStyle, authenticatorStyle, loaderStyle, showLogo] = React.useMemo(() => {

         const smallScreen = size[1] < 600 && size[0] < 1050;

         const pos: 'absolute' | 'relative' = smallScreen ? 'absolute' : 'relative';
         const containerHeight = smallScreen ? Math.max(550, size[1]) : Math.min(690, size[1]);

         const authStyle = {
          position:        pos,
          overflow:        'hidden',
          top:             smallScreen ? 0 : undefined,
          marginLeft:      smallScreen ? 5 : 10,
          border:          showLoader ? 'none' : undefined,
          borderRadius:    smallScreen ?  '0em' : '0.25em',
          width:           smallScreen ? size[0] : Math.min(700, size[0] - 20),
          height:          showLoader ? 0 : containerHeight
         }

         return [
           {
            opacity:   showAuthForm ? 1 : 0,
            top:       showAuthForm ? 0 : '150vh',
           },
           authStyle,
           {
            minHeight:    containerHeight,
            minWidth:     authStyle.width,
            marginLeft:   authStyle.marginLeft,
            border:       '1px solid #DDD',
           },
           ( size[1] > 600 )
        ]
    },[size, showAuthForm, showLoader])
  
  
    return (
      <div 
         className = { classes.root }
         style     = { rootStyle }
      >
          <div 
             className = { classes.authenticator }
             style     = { authenticatorStyle }
          >
            
            <div className = { classes.iconContainer }>
                <CloseSharpIcon 
                   className = { classes.closeIcon }
                   onClick   = { handleClose }
                />
            </div>
            {
              (displayForm === 'sign_up_part1' || displayForm === 'sign_up_part2' || displayForm === 'completeSignUp' || displayForm === 'completeSignUp2') &&
              <SignUpComponent
                  userInfo         = { userInfo }
                  size             = { size }
                  showGoogleButton = { showGoogleButton }
                  planType         = { planType }
                  displayForm      = { displayForm }
                  name             = { name }
                  email            = { email }
                  password         = { password }
                  userOrigin       = { userOrigin }
                  code             = { code }
                  error            = { error }
                  tosChecked       = { tosChecked }
                  showLogo         = { showLogo }
                  setTosChecked    = { setTosChecked }
                  setError         = { setError }
                  setShowLoader    = { setShowLoader }
                  setName          = { setName }
                  setEmail         = { setEmail }
                  setPassword      = { setPassword }
                  setUserOrigin    = { setUserOrigin }
                  setCode          = { setCode }
                  setDisplayForm   = { setDisplayForm }
                  handleCreateUser = { handleCreateUser }
                  setShowTOS       = { setShowTOS }
                  setShowPrivacy   = { setShowPrivacy }
                  logOut           = { logOut }
              />
            }
            {
            displayForm === 'sign_in'
            &&
              <SignInForm
                size             = { size }
                email            = { email }
                password         = { password }
                code             = { code }
                error            = { error }
                userConfirmed    = { userConfirmed }
                showLogo         = { showLogo }
                setUserConfirmed = { setUserConfirmed }
                setError         = { setError }
                setShowLoader    = { setShowLoader }
                setName          = { setName }
                setEmail         = { setEmail }
                setPassword      = { setPassword }
                setCode          = { setCode }
                setDisplayForm   = { setDisplayForm }
                handleCreateUser = { handleCreateUser } 
              />
            }
            {
              displayForm === 'recover_pwd_part1' &&
              <RecoverPwd1
                  size           = { size }
                  email          = { email }
                  error          = { error }
                  showLogo       = { showLogo }
                  setError       = { setError }
                  setShowLoader  = { setShowLoader }
                  setEmail       = { setEmail }
                  setDisplayForm = { setDisplayForm }
              />
            }
            {
              displayForm === 'recover_pwd_part2' &&
              <RecoverPwd2
                  size           = { size }
                  email          = { email }
                  code           = { code }
                  password       = { password }
                  error          = { error }
                  showLogo       = { showLogo }
                  setError       = { setError }
                  setShowLoader  = { setShowLoader }
                  setCode        = { setCode }
                  setPassword    = { setPassword }
                  setDisplayForm = { setDisplayForm }
               />
            }
            {
              displayForm === 'recover_pwd_part3' &&
              <RecoverPwd3
                 size           = { size }
                 email          = { email }
                 password       = { password }
                 error          = { error }
                 showLogo       = { showLogo }
                 setError       = { setError }
                 setShowLoader  = { setShowLoader }
                 setEmail       = { setEmail }
                 setPassword    = { setPassword }
              />
            }
            {
              displayForm === 'studentLogin' &&
              <StudentLogIn
                  size              = { size }
                  email             = { email }
                  code              = { code }
                  grade             = { grade }
                  name              = { name }
                  error             = { error }
                  showLogo          = { showLogo }
                  setError          = { setError }
                  setShowLoader     = { setShowLoader }
                  setEmail          = { setEmail }
                  setName           = { setName }
                  setGrade          = { setGrade }
                  setCode           = { setCode }
                  studentLogin      = { studentLogin }
              />
            }
            {
              showAuthForm
              &&
              <>
                <TOS 
                  size       = { size }
                  showTOS    = { showTOS }
                  setShowTOS = { setShowTOS }
                />
                <PrivacyPolicy
                  size           = { size }
                  showPrivacy    = { showPrivacy }
                  setShowPrivacy = { setShowPrivacy }
                />
              </>
            }
          </div>
          {
              showLoader && 
              <div 
                 className = { classes.loaderContainer }
                 style     = { loaderStyle }
              >
                 <Loader variant = { 'blue' }/>
              </div>
          }
      </div>
    )
}



const mapStateToProps = (reducer: reducerType) => {
  const {userConfirmed, showGoogleButton, showAuthForm, displayForm, name, grade, email, password, userOrigin, code, error, showAuthLoader } = reducer.auth;
  return {
    userInfo:          reducer.user.userInfo,
    studentLoginState: reducer.user.studentLoginState,
    authState:         reducer.auth,
    showGoogleButton:  showGoogleButton,
    showAuthForm:      showAuthForm,
    displayForm:       displayForm,
    name:              name,
    grade:             grade,
    email:             email,
    password:          password,
    userOrigin:        userOrigin,
    code:              code,
    error:             error,
    showLoader:        showAuthLoader,
    userConfirmed:     userConfirmed
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  createUser:    (payload: createUserType) => dispatch(CREATE_USER_SAGA(payload)),
  studentLogin:  (payload: studentLoginType) => dispatch(STUDENT_LOGIN_SAGA(payload)),
  setAuthState:  (payload: authStateType) => dispatch(SET_AUTH_STATE(payload)),
  setAuthError:  (payload: string) => dispatch(SET_ERROR(payload)),
  setAuthLoader: (payload: boolean) => dispatch(SET_AUTH_LOADER(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);