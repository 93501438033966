import React             from 'react';
import { Auth }          from 'aws-amplify';
import recordEvent       from 'functions/contentInteraction/record_event';
import TextField         from '@mui/material/TextField';
import useTextFieldStyle from 'components/authenticator/functions/get_textfield_style';
import { makeStyles }    from 'makeStyles';
import { colors }        from 'utils/colors';


const useStyles = makeStyles()((Theme) => ({  
    root: { 
      display:        'flex',
      flexFlow:       'column',
      justifyContent: 'center',
      alignItems:     'center',
      width:          '90%',
      height:         '100%',
      marginLeft:     '5%',
    },
    logo: {
      width:              200,
      height:             65,
      backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
    },
    form: {
      width:          '100%',
      display:        'flex',
      flexFlow:       'column',
      justifyContent: 'center',
      alignItems:     'center'
    },
    text_field: {
      height:          45,
      fontSize:        30,
      color:           colors.darkText,
      marginTop:       10,
      marginBottom:    10,
      transform:      'translate(0px, -20px)'
    },
    button:  {
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      marginTop:       20,
      fontSize:        18,
      color:           colors.darkText,
      width:           200,
      borderRadius:    '0.5em',
      textAlign:       'center',
      cursor:          'pointer',
      height:          40,
      border:          `1px solid ${ colors.brightText }`,
      '&:hover': {
        opacity: 0.5
      } 
    },
    /*  buttonText: {
      height:         '100%',
      width:          '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      fontSize:        22,
      fontFamily:     'Fredoka One',
    }, */
    forgot_pwd: {
       cursor: 'pointer',
       color:  colors.darkText,
       margin: 20
    },
    err: {
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      height:         45,
      color:          '#F00'
    },
    txt: {
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      height:         30,
      color:          colors.darkText,
      '&:hover': {
        opacity: 0.5
      }
    },
    resend_code: {
      marginTop:      35,
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      height:         45,
      color:          colors.darkText,
      cursor:         'pointer'
    },
    go_back: {
       cursor:'pointer',
       color: '#888',
       margin: 20
    },
    loader_container: {
       width:  60,
       height: 60
    },
    error: {
      color: colors.darkText
    },
    iseemath: {
      marginTop:  5,
      fontSize:   18,
      fontWeight: 'bold'
    }
  }));


type RecoverPwd1Props =  {
    size:           number[];
    email:          string;
    error:          string;
    showLogo:       boolean;
    setError:       (val: string) => void;
    setEmail:       (val: string) => void;
    setDisplayForm: (val: string) => void;
    setShowLoader:  (val: boolean) => void;
};

const RecoverPwd1: React.FC<RecoverPwd1Props> = ({ 
  size, 
  email, 
  error, 
  showLogo,
  setError, 
  setEmail, 
  setDisplayForm,
  setShowLoader
}) => {

  const { classes } = useStyles();

  const textFieldStyle = useTextFieldStyle(size)


  const changeInputValue = (e: React.FormEvent) => {
    
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setEmail(target.value)
  }

  const handleSubmit = async() => {

    if (!email.length) { setError('Please enter your Email address'); return }

    setShowLoader(true);

    try{
        await Auth.forgotPassword(email); 
        setShowLoader(false);
        setDisplayForm('recover_pwd_part2'); 
        recordEvent('registration', `recoverPwrd1 success`, 'recoverPwrd1')
    }
    catch(err: any){
      setShowLoader(false)

      const _error = err.message || err
  
       setError(_error || '');
       recordEvent('registration', `recoverPwrd1 error : ${ _error }`, 'recoverPwrd1 error')

    }
    finally {  }
  }

  return(
     <div className = { classes.root }>
           { showLogo && <div className = { classes.logo }/> }
           { error.length > 0 && <div className = { classes.err }> { error} </div> }
           <form className = { classes.form }>
           { error.length === 0 && <div className = { classes.txt }>Please enter your Email address</div> }
             <TextField
                className    = { classes.text_field }
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "email"
                label        = "Email"
                onChange     = { changeInputValue }
             />
             <div onClick = { handleSubmit } className = { classes.button }>
                Continue
             </div>      
           </form>
      </div>
    )
}

 
export default RecoverPwd1;
