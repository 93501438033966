import React          from 'react';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../../utils/colors';


const useStyles = makeStyles()((Theme) => ({
    buttons_container: {
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        flexFlow:       'column',
        marginTop:      20
    },
    button: {
        marginTop:       10,
        color:           '#FFF',
        width:           325,
        height:          40,
        borderRadius:    '0.4em',
        textAlign:       'center',
        cursor:          'pointer',
        backgroundColor: colors.primary,
        border:          `1px solid ${ colors.primary }`,
        '&:hover': {
          color:           colors.primary,
          backgroundColor: '#FFF'
        }
    },
    button_text: {
        height:         '100%',
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        textAlign:      'center',
        fontWeight:      'bold',
        fontSize:        17,
      //  fontFamily:     'Fredoka One',
      }
}))

type ButtonsProps = {
    initialAnimation:    boolean;
    showInstructions:    boolean;
    startChallenge:      () => void;
    setShowInstructions: (val: boolean) => void;
}

const Buttons: React.FC<ButtonsProps> = ({ 
    initialAnimation,
    showInstructions,
    startChallenge,
    setShowInstructions
  }) => {

  const { classes } = useStyles();

  const buttonStyle = React.useMemo(() => {
      return {
        transform:  `translate(0vw, ${ initialAnimation ? 0 : 100 }vh)`,
        transition: 'transform 0.3s'
      }
  },[initialAnimation])

  return (
        <div className = { classes.buttons_container }>
                {
                    showInstructions
                    &&
                    <div 
                        className = { classes.button } 
                        onClick   = { () => { console.log('tets'); setShowInstructions(true) }}
                        style     = { buttonStyle }
                    >
                        <div className = { classes.button_text }>
                            Select Level
                        </div>    
                    </div>
                }
                <div 
                    className = { classes.button } 
                    onClick   = { startChallenge }
                    style     = { buttonStyle }
                >
                    <div className = { classes.button_text }>
                       Continue
                    </div>    
                </div>
        </div>
  );
}

export default Buttons;
