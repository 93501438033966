const mockQuestions: { [key: string]: any }[] = [
  {
    "id": 4,
    "question": "Which rectangle is showing half?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "3",
    "correct_answer": "c",
    "image": "half1.png"
  },
  {
    "id": 5,
    "question": "Is this image split in half?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "yes@no",
    "correct_answer": "a",
    "image": "half2.png"
  },
  {
    "id": 6,
    "question": "Ella says that this image is showing half. Why is she incorrect?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "there are more than 2 equal parts@the parts are not equal",
    "correct_answer": "a",
    "image": "half3.png"
  },
  {
    "id": 7,
    "question": "Is this half?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "yes@no",
    "correct_answer": "a",
    "image": "half4.png"
  },
  {
    "id": 8,
    "question": "Which image is showing half?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "3",
    "correct_answer": "c",
    "image": "half5.png"
  },
  {
    "id": 9,
    "question": "Tom says this is half. Is he correct?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "yes@no",
    "correct_answer": "a",
    "image": "half6.png"
  },
  {
    "id": 10,
    "question": "Is this half?",
    "question_type": "MC",
    "practice_set": "What is a Half?",
    "answer_choices": "yes@no",
    "correct_answer": "b",
    "image": "half7.png"
  },
  {
    "id": 16,
    "question": "What fraction is shown?",
    "question_type": "N",
    "practice_set": "Fascinating Fourths",
    "answer_choices": "",
    "correct_answer": "1/4",
    "image": "fascinatingfourths1.png"
  },
  {
    "id": 17,
    "question": "Is this picture showing fourths?",
    "question_type": "MC",
    "practice_set": "Fascinating Fourths",
    "answer_choices": "yes@no",
    "correct_answer": "a",
    "image": "fascinatingfourths2.png"
  },
  {
    "id": 18,
    "question": "Is this picture showing fourths?",
    "question_type": "MC",
    "practice_set": "Fascinating Fourths",
    "answer_choices": "yes@no",
    "correct_answer": "b",
    "image": "fascinatingfourths3.png"
  },
  {
    "id": 19,
    "question": "Which is NOT a way to make fourths?",
    "question_type": "MC",
    "practice_set": "Fascinating Fourths",
    "answer_choices": "copying an object four times@splitting an object into four equal parts@splitting an object in half, then splitting each half in half",
    "correct_answer": "a",
    "image": "fascinatingfourths4.png"
  },
  {
    "id": 20,
    "question": "Britney says that she can split her sandwich in fourths by cutting it in thirds, then splitting each third in half. Is she correct?",
    "question_type": "MC",
    "practice_set": "Fascinating Fourths",
    "answer_choices": "yes@no",
    "correct_answer": "b",
    "image": "fascinatingfourths5.png"
  },
  {
    "id": 21,
    "question": "Cara says that this image is showing fourths. Why is she incorrect?",
    "question_type": "MC",
    "practice_set": "Fascinating Fourths",
    "answer_choices": "the parts are not equal@there are less than 4 parts",
    "correct_answer": "b",
    "image": "fascinatingfourths6.png"
  },
  {
    "id": 22,
    "question": "What is one way to create eighths?",
    "question_type": "MC",
    "practice_set": "Exciting Eighths",
    "answer_choices": "split an object into four equal parts@split an object into eight equal parts",
    "correct_answer": "b",
    "image": "excitingeighths1.png"
  },
  {
    "id": 23,
    "question": "Daniel says that eighths are larger than fourths. Is he correct?",
    "question_type": "MC",
    "practice_set": "Exciting Eighths",
    "answer_choices": "yes@no",
    "correct_answer": "b",
    "image": "excitingeighths2.png"
  },
  {
    "id": 24,
    "question": "What is one way to create eighths?",
    "question_type": "MC",
    "practice_set": "Exciting Eighths",
    "answer_choices": "split an object into fourths and then split those fourths in half@split an object into fourths, and then split those fourths into fourths",
    "correct_answer": "a",
    "image": "excitingeighths3.png"
  },
  {
    "id": 25,
    "question": "Is this image showing eighths?",
    "question_type": "MC",
    "practice_set": "Exciting Eighths",
    "answer_choices": "yes@no",
    "correct_answer": "a",
    "image": "excitingeighths4.png"
  },
  {
    "id": 26,
    "question": "Is this image showing eighths?",
    "question_type": "MC",
    "practice_set": "Exciting Eighths",
    "answer_choices": "yes @ no, because the pieces are not equal @no,because there are not eight total pieces",
    "correct_answer": "b",
    "image": "excitingeighths5.png"
  },
  {
    "id": 27,
    "question": "Which fraction is smaller, halves or eighths?",
    "question_type": "MC",
    "practice_set": "",
    "answer_choices": "halves@eighths",
    "correct_answer": "b",
    "image": "excitingeighths6.png"
  },
  {
    "id": 28,
    "question": "What fraction do we get when we split a fourth in half?",
    "question_type": "N",
    "practice_set": "Halves, Fourths, and Eighths",
    "answer_choices": "",
    "correct_answer": "1/8",
    "image": "halvesfourthseighths.png"
  },
  {
    "id": 29,
    "question": "What fraction do we get when we double a fourth?",
    "question_type": "N",
    "practice_set": "Halves, Fourths, and Eighths",
    "answer_choices": "",
    "correct_answer": "1/2",
    "image": "halvesfourthseighths.png"
  },
  {
    "id": 30,
    "question": "Which statement is true?",
    "question_type": "MC",
    "practice_set": "Halves, Fourths, and Eighths",
    "answer_choices": "Two fourths fit inside of a half @Two halves fit inside of a fourth",
    "correct_answer": "a",
    "image": "halvesfourthseighths.png"
  },
  {
    "id": 31,
    "question": "Which statement is true?",
    "question_type": "MC",
    "practice_set": "Halves, Fourths, and Eighths",
    "answer_choices": "1/8 + 1/8 = 1/4 @1/4 + 1/4 = 1/8",
    "correct_answer": "a",
    "image": "halvesfourthseighths.png"
  },
  {
    "id": 32,
    "question": "What fraction can we double to get 1/2?",
    "question_type": "N",
    "practice_set": "Halves, Fourths, and Eighths",
    "answer_choices": "",
    "correct_answer": "1/4",
    "image": "halvesfourthseighths.png"
  },
  {
    "id": 33,
    "question": "Which statement is true?",
    "question_type": "MC",
    "practice_set": "Halves, Fourths, and Eighths",
    "answer_choices": "Two eighths make a half @Four eighths make a half",
    "correct_answer": "b",
    "image": "halvesfourthseighths.png"
  },
  {
    "id": 34,
    "question": "What is this fraction?",
    "question_type": "N",
    "practice_set": "Fractions on the Number Line",
    "answer_choices": "",
    "correct_answer": "3/4",
    "image": "fotnl1.png"
  },
  {
    "id": 35,
    "question": "Where is 3/5 on the number line?",
    "question_type": "MC",
    "practice_set": "Fractions on the Number Line",
    "answer_choices": "4",
    "correct_answer": "c",
    "image": "fotnl2.png"
  },
  {
    "id": 36,
    "question": "What is this fraction?",
    "question_type": "N",
    "practice_set": "Fractions on the Number Line",
    "answer_choices": "",
    "correct_answer": "5/6",
    "image": "fotnl3.png"
  },
  {
    "id": 37,
    "question": "Where is 1/3 on the number line?",
    "question_type": "MC",
    "practice_set": "Fractions on the Number Line",
    "answer_choices": "2",
    "correct_answer": "b",
    "image": "fotnl4.png"
  },
  {
    "id": 38,
    "question": "Which statement is true?",
    "question_type": "MC",
    "practice_set": "Fractions on the Number Line",
    "answer_choices": "These fractions are greater than 1 @These fractions are less than 1",
    "correct_answer": "b",
    "image": "fotnl5.png"
  },
  {
    "id": 39,
    "question": "What is this fraction?",
    "question_type": "N",
    "practice_set": "Fractions on the Number Line",
    "answer_choices": "",
    "correct_answer": "4/8",
    "image": "fotnl6.png"
  },
  {
    "id": 40,
    "question": "1/3 is equal to how many 12ths?",
    "question_type": "N",
    "practice_set": "Thirds, Sixths, and Twelfths",
    "answer_choices": "",
    "correct_answer": "4",
    "image": "thirdssixthstwelfths.png"
  },
  {
    "id": 41,
    "question": "Which fraction is equal to 5/6?",
    "question_type": "MC",
    "practice_set": "Thirds, Sixths, and Twelfths",
    "answer_choices": "10/12 @9/12 @2/3",
    "correct_answer": "a",
    "image": "thirdssixthstwelfths.png"
  },
  {
    "id": 42,
    "question": "Why are 5ths not related to 12ths?",
    "question_type": "MC",
    "practice_set": "Thirds, Sixths, and Twelfths",
    "answer_choices": "5ths are smaller than 12ths @5 is not a factor of 12",
    "correct_answer": "b",
    "image": "thirdssixthstwelfths.png"
  },
  {
    "id": 43,
    "question": "How many 3rds are equal to 4/6?",
    "question_type": "N",
    "practice_set": "Thirds, Sixths, and Twelfths",
    "answer_choices": "",
    "correct_answer": "2",
    "image": "thirdssixthstwelfths.png"
  },
  {
    "id": 44,
    "question": "Which statement is true?",
    "question_type": "MC",
    "practice_set": "Thirds, Sixths, and Twelfths",
    "answer_choices": "Splitting 6ths in half creates 3rds @Splitting 3rds in half creates 6ths",
    "correct_answer": "b",
    "image": "thirdssixthstwelfths.png"
  },
  {
    "id": 45,
    "question": "Which statement is true?",
    "question_type": "MC",
    "practice_set": "Thirds, Sixths, and Twelfths",
    "answer_choices": "Doubling 12ths creates 6ths @Doubling 6ths creates 12ths",
    "correct_answer": "a",
    "image": "thirdssixthstwelfths.png"
  }
]
export default mockQuestions;