import { store }           from 'index'
import goToBonusContent    from './go_to_bonus_content'
import goToContent         from './go_to_content'
import goToSubscribe       from './go_to_subscribe'
import { GO_TO_HOME_PAGE } from 'actions/page_actions';
import { 
  contentInfoType, 
  planTypeType 
}                          from 'types';

const onLoad = (
    navigate:            any,
    location:            any,
    videoPage:           string,
    goToAppOnce:         boolean,
    wideScreen:          boolean,
    userSignedIn:        string,
    setGoToAppOnce:      (val: boolean) => void,
    setChangeContent:    (val: boolean) => void,
    setPlanType:         (val: planTypeType) => void,
    selectContent:       (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void
) => {

    const state = store.getState();

    const { hash, puzzles, topicIntros } = state.content;

    if (!goToAppOnce) {
      if (!videoPage.length && location.pathname === '/') {
          if (userSignedIn !== 'true') {  
             store.dispatch(GO_TO_HOME_PAGE());
          }
          if (userSignedIn === 'true') {
            navigate('/Apps', { replace: true })
          }
          setGoToAppOnce(true);
          return;
      }
      else if (location.pathname === '/getStarted') {
          setGoToAppOnce(true);
          goToSubscribe(navigate, userSignedIn, setPlanType);
          return;
      }
      else {
        const path = location.pathname.slice(1);

        if (!!Object.keys(hash).length) {
          goToContent(navigate, (!!videoPage ? videoPage : path), wideScreen, setGoToAppOnce, setChangeContent, selectContent)
        }

        if ( !!puzzles.length && !!topicIntros.length ) {
          goToBonusContent(navigate, (!!videoPage ? videoPage : path), setGoToAppOnce, setChangeContent, selectContent)
        }
          
      }
    }
}

export default onLoad;

