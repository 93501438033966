import React           from 'react';
import { makeStyles }  from 'makeStyles';

/* var clsx = require('classnames')

const b_   = '#2F3F9F'
const c_   = '#45B'
const d_   = '#56C'
const e_   = "#3F4FAF"
const f_   = '#203090'
const g_   = '#34A' */


const useStyles = makeStyles()(
  (Theme) => ({    
    dimensions150: {
        width:  150,
        height: 150,
    },
    dimensions100: {
        width:  100,
        height: 100,
    },
    root: {
        position:        'fixed',
        maxHeight:       '100vh',
        maxWidth:        '100vw',
        backgroundColor: '#FFF'
    },
    boxCanvas: {
        perspective: 600
    },
    qube: {
       position:        'relative',
        transformStyle: 'preserve-3d',
        animation:      'rotateQube 30s linear infinite'
    },
    pea: {
        border:          '1px solid #FFF',
        position:        'absolute',
        backgroundColor: Theme.primary
    },
    
}))


type VolumesProps = {
    dimensions: number;
}

const Volumes: React.FC<VolumesProps> = ({ dimensions }) => {

  const { classes } = useStyles();

  const unitStyle = React.useMemo(() => {
    return{
        width:  dimensions,
        height: dimensions
    }
  },[dimensions]) 

  return (
        <div 
           className = { classes.boxCanvas }
           style     = { unitStyle }
        >
            <div 
               className = { classes.qube  }
               style     = { unitStyle }
            >
                <div className = { classes.pea  } style = {{ ...unitStyle, transform: `translateZ(${ -dimensions/2 }px)` }}/>
                <div className = { classes.pea  } style = {{ ...unitStyle, transform: `translateX(${ -dimensions/2 }px) rotateY(90deg)` }}/>
                <div className = { classes.pea  } style = {{ ...unitStyle, transform: `translateX(${ dimensions/2 }px) rotateY(90deg)` }}/>
                <div className = { classes.pea  } style = {{ ...unitStyle, transform: `translateY(${ -dimensions/2 }px) rotateX(90deg)` }}/>
                <div className = { classes.pea  } style = {{ ...unitStyle, transform: `translateY(${ dimensions/2 }px) rotateX(90deg)` }}/>
                <div className = { classes.pea  } style = {{ ...unitStyle, transform: `translateZ(${ dimensions/2 }px)` }}/>
            </div>
       </div>
  );
}

export default Volumes;
