/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7523477b-7462-40bb-bb3d-2aa04ece640c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_RcMqolf7o",
    "aws_user_pools_web_client_id": "5m5d1ob64dv1r9hl4hjleg1slm",
    "oauth": {
        "domain": "iseemath-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://iseemath.co/,http://localhost:3000/",
        "redirectSignOut": "https://iseemath.co/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
