

const tutorialsArr = [
  //  { title: 'Saving A Lesson',             youTube: '8utP85Xoavs', time: '0'   },
   // { title: 'Top Tool Bar - Left',         youTube: 'RjHSdpioAdo', time: '0'   },
   // { title: 'Top Tool Bar - Right',        youTube: 'dQj2BnNaMKk', time: '0'   },
    { title: 'How do I create and save my own lessons?', youTube: 'HM-bSrowKmQ', time: '0'   },
    { title: 'Sharing with groups',                      youTube: '1T3G0V2JIgM', time: '0'   },
    { title: 'How do I use the toolbar - left side?',    youTube: 'Rl5C0lsHPZs', time: '0'   },
    { title: 'How do I use the toolbar - right side?',   youTube: 'O-nxl0vtapI', time: '0'   },
    { title: 'Adding a Mathematical expression',         youTube: 'dQj2BnNaMKk', time: '13'  },
    { title: 'Adding a text',                            youTube: 'dQj2BnNaMKk', time: '118' },
    { title: 'Adding a Table',                           youTube: 'dQj2BnNaMKk', time: '154' },
    { title: 'Using the scribble',                       youTube: 'dQj2BnNaMKk', time: '205' },
    { title: 'Adding a line',                            youTube: 'dQj2BnNaMKk', time: '238' },
    { title: 'Selecting multiple objects',               youTube: 'dQj2BnNaMKk', time: '270' },
    { title: 'Adding a Link',                            youTube: 'dQj2BnNaMKk', time: '281' },
    { title: 'Adding an Image',                          youTube: 'dQj2BnNaMKk', time: '341' },
];

export default tutorialsArr;
