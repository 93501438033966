import React               from 'react';
import { Dispatch }        from "redux";
import { connect }         from 'react-redux';
import { reducerType }     from 'reducer';
import { ActionTypes }     from 'actions/types';
import { contentInfoType } from 'types';
import navBarClick         from 'functions/navBar/nav_bar_click';
import ImageApp            from './imageChallenges';
import Intro               from './intro';
import ScorePage           from './ScorePage';
import recordEvent         from 'functions/contentInteraction/record_event';
import { makeStyles }      from 'makeStyles';
import { useNavigate }      from 'react-router-dom';
import { SET_CHALLENGE_STATE } from 'actions/challenges_actions';

const useStyles = makeStyles()((Theme) => ({
    root: {
        minHeight:       '100vh',
        minWidth:        '100vw',
        backgroundColor: '#FFF',
        transition:      'opacity 0.4s'
    }
}))

type ChallengePageProps = {
    size:              number[];
    menuSize:          number;
    wideScreen:        boolean;
    challengeState:    contentInfoType;
    setChangeContent:  (val: boolean) => void;
    setChallengeState: (val: contentInfoType) => void;
}

const ChallengePage: React.FC<ChallengePageProps> = ({ 
  size,
  menuSize,
  wideScreen,
  challengeState,
  setChangeContent,  
  setChallengeState,
}) => {

  const { classes } = useStyles();

  const navigate = useNavigate();

  const [opacity, setOpecity]             = React.useState(0);
  const [showIntro, setShowIntro]         = React.useState(true);
  const [showScorePage, setShowScorePage] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setOpecity(1)
    }
    return () => { unmounted = true }
  },[])
  

  const exitChallengePage = React.useCallback(() => {
    navBarClick('bonus', setChangeContent)
  },[setChangeContent])


  const [challenge, level] = React.useMemo(() => {
    return [challengeState.selectedChallenge.name, !challengeState.level ? 1 : challengeState.level];
  },[challengeState])

  const setLevel = (val: number) => {
    setChallengeState({
        ...challengeState,
        level: val
    })
  }

  const handleExit = () => {
    recordEvent('app', 'bonus content closed', 'variables challenge')
    setOpecity(0)
    setTimeout(() => {
      setShowScorePage(false)
      exitChallengePage()
      setChallengeState({
        ...challengeState,
           selectedChallenge: {},
           level:             0,
           score:             0,
           timeBonus:         0,
      })
      navigate('../',{ replace: true })
    },300)
    
  }

  const rootStyle = React.useMemo(() => { return{ opacity: opacity } },[opacity])

  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
    >
        { 
           showIntro && 
           <Intro
               size           = { size }
               menuSize       = { menuSize }
               wideScreen     = { wideScreen }
               variant        = { challenge }
               level          = { level }
               setLevel       = { setLevel }
               startChallenge = { () => { setShowIntro(false) }}
               abortChallenge = { handleExit }
           />
        }
        { !showIntro && challenge === 'Volumes'    && <ImageApp size = { size } menuSize = { menuSize } wideScreen = { wideScreen } challengeState = { challengeState } setChallengeState = { setChallengeState } exitChallengePage = { handleExit } setShowScorePage = { setShowScorePage } variant = { 'Volumes' }    /> }
        { !showIntro && challenge === 'Perimeters' && <ImageApp size = { size } menuSize = { menuSize } wideScreen = { wideScreen } challengeState = { challengeState } setChallengeState = { setChallengeState } exitChallengePage = { handleExit } setShowScorePage = { setShowScorePage } variant = { 'Perimeters' } /> }
        { !showIntro && challenge === 'Variables'  && <ImageApp size = { size } menuSize = { menuSize } wideScreen = { wideScreen } challengeState = { challengeState } setChallengeState = { setChallengeState } exitChallengePage = { handleExit } setShowScorePage = { setShowScorePage } variant = { 'Variables' }  /> }
        <ScorePage 
            showScorePage    = { showScorePage }
            challengeState   = { challengeState }
            handleExit       = { handleExit }
        />
     </div>
    
  );
}

 
const mapStateToProps = (reducer: reducerType) => {
  return {
    challengeState: reducer.challenges
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setChallengeState: (payload: contentInfoType) => dispatch(SET_CHALLENGE_STATE(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ChallengePage);

