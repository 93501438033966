import React              from 'react';
import ActionBar          from '../../../components/actionbar';
import ExitButton         from '../components/buttons/ExitButton';
import Modal              from '../components/modal';
import ProgressBar        from '../../../components/progressBars/ProgressBar';
import BigProgressBar     from '../../../components/progressBars/BigProgressBar';
import Image              from './Image';
import Input              from './Input';
import { colors }         from '../../../utils/colors'
import { makeStyles }     from 'makeStyles';
import { contentInfoType } from '../../../types';

var clsx = require('classnames');


const useStyles = makeStyles()(
  (Theme) => ({    root: {
        position:       'fixed',
        minHeight:      '100vh',
        minWidth:       '100vw',
        transition:     'all 1s'
    },
    container: {
      position:       'fixed',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      flexFlow:       'column'
    },
    shake: {
      animation:               'shake 0.5s',
      animationIterationCount: '0.5s',
    },
    text: {
      maxWidth:       '95%',
      fontWeight:     'bold',
      textAlign:      'center',
      lineHeight:     1.5,
      display:        'flex',
      alignItems:     'center',
      flexFlow:       'column',
      paddingTop:     8,
      color:          colors.blue//'#444'
    },
}))

type ImageRoomProps = {
    size:              number[];
    menuSize:          number;
    wideScreen:        boolean;
    variant:           string;
    challengeState:    contentInfoType;
    setChallengeState: (val: contentInfoType) => void;
    setShowScorePage:  (val: boolean) => void;
    exitChallengePage: () => void;
  }

const ImageRoom: React.FC<ImageRoomProps> = ({ 
  size, 
  menuSize,
  wideScreen,
  variant, 
  challengeState,
  setChallengeState,
  setShowScorePage,
  exitChallengePage 
}) => {

  const { classes } = useStyles();

  const [tests, level] = React.useMemo(() => {
    const { set1, set2, set3, set4, set5, set6 } = challengeState.selectedChallenge;
    const lvl = Math.max(0, challengeState.level - 1);
    return [
      [ set1, set2, set3, set4, set5, set6 ],
      lvl
    ]
  },[challengeState])


  const [testNum, setTestNum]                   = React.useState(0);
  const [imageSrc, setImageSrc]                 = React.useState(!!tests[level][testNum] ? tests[level][testNum]['img'] : '');
  const [inputInteger, setInputInteger]         = React.useState('');
  const [inputNumerator, setInputNumerator]     = React.useState('');
  const [inputDenominator, setInputDenominator] = React.useState('');
  const [number, setNumber]                     = React.useState('');
  const [numerator, setNumerator]               = React.useState('');
  const [denominator, setDenominator]           = React.useState('');
  const [maxLength, setMaxLength]               = React.useState(!!tests[level][testNum] ? tests[level][testNum]['ans'].length : 0);
  const [integer, setInteger]                   = React.useState(false);
  const [fraction, setFraction]                 = React.useState(false);
  const [combined, setCombined]                 = React.useState(false);
  const [animateImage, setAnimateImage]         = React.useState(false);
  const [animateInput, setAnimateInput]         = React.useState(false);
  const [openExitModal, setOpenExitModal]       = React.useState(false);
  const [phaseOut, setPhaseOut]                 = React.useState(false);
  const [resetTimer, setResetTimer]             = React.useState(false);
  const [correctAnswer, setCorrectAnswer]       = React.useState(false)
  const [success, setSuccess]                   = React.useState('')
  const [shake, setShake]                       = React.useState(false)
  const [activateTimer, setActivateTimer]       = React.useState(true)
  const [checkOrContinue, setCheckOrContinue]   = React.useState('check' as 'check' | 'continue')
  const [time, setTime]                         = React.useState(-1);
  
  

  
  React.useEffect(() => {
     
     const str = tests[level][testNum]['ans'];

     if ( str.includes(' ') ) {
          if (!combined) {
            setInteger(false)
            setCombined(true)
            setFraction(false)
          }
          if (!numerator.length) {

            const int = str.slice(0, str.indexOf(' '));
            const num = str.slice(str.indexOf(' ')+1, str.indexOf('/'));
            const den = str.slice(str.indexOf('/')+1, str.length);

            setNumber(int);
            setNumerator(num);
            setDenominator(den);
            setMaxLength(int.length);
          }
     }

     else if ( str.includes('/') ) {
          if (!fraction) {
            setInteger(false)
            setCombined(false)
            setFraction(true)
          }
          if (!numerator.length) {

            const num = str.slice(0, str.indexOf('/'));
            const den = str.slice(str.indexOf('/')+1, str.length);
            setNumerator(num);
            setDenominator(den);
          }
     }

     else {
       setNumber(str);
       setInteger(true)
       setCombined(false)
       setFraction(false)
       setMaxLength(tests[level][testNum]['ans'].length)
     }


  },[
    tests, combined, fraction, level, setFraction, variant, testNum, setNumerator, setNumber, setDenominator,   setInteger,   setCombined,   setMaxLength, numerator.length
  ])



  React.useEffect(() => {
    if (integer) {
       if (inputInteger.length && inputInteger === number) {
          setCorrectAnswer(true)
       } 
       else {
         if (correctAnswer) {
          setCorrectAnswer(false)
         }
       }
    }
    else if (fraction) {
      if (
          (inputNumerator.length && inputNumerator === numerator)
          && 
          (inputDenominator.length && inputDenominator === denominator)
         ) {
          setCorrectAnswer(true)
      }
      else {
        if (correctAnswer) {
          setCorrectAnswer(false)
        }
      }
    }
    else if (combined) {
      if (
          (inputInteger.length && inputInteger === number)
          && 
          (inputNumerator.length && inputNumerator === numerator)
          && 
          (inputDenominator.length && inputDenominator === denominator)
         ) {
          setCorrectAnswer(true)
      }
      else {
        if (correctAnswer) {
          setCorrectAnswer(false)
        }
      }
    }
  },[
    correctAnswer,
    time,
    integer, 
    fraction,
    combined,
    denominator,
    number,
    numerator,
    inputInteger, 
    inputNumerator,
    inputDenominator,
    success,
  ])




  const nextTest = () => {

    if (testNum === tests[level].length - 1) { 
      setPhaseOut(true)
      setTimeout(() => { setShowScorePage(true) },1100)   
     return;
    }

   // setEnableNextButton(false)
    setSuccess('')
    setNumber('')
    setNumerator('')
    setDenominator('')
    setInputInteger('')
    setInputNumerator('')
    setInputDenominator('') 
    setImageSrc(tests[level][testNum + 1].img)
    setTestNum(testNum + 1)
    setAnimateImage(true);
    setAnimateInput(true);
    setResetTimer(true)
    setActivateTimer(true)
    setCheckOrContinue('check')
   // setTest(`${variant}_test${round}`)
  }

  
  

  const handleCheck = () => {
    if (!correctAnswer) {
      setSuccess('false')
      setShake(true)
      setTimeout(() => { setShake(false) },500)
    }
    else {

      setSuccess('true')

      const timeBonus         = time <= 5 ? 10 : time <= 10 ? 5 : 0;
      const newTimeBonus      = challengeState.timeBonus + timeBonus;
      const newChallengeScore = challengeState.score + 10;

      setChallengeState({
        ...challengeState,
        score:     newChallengeScore,
        timeBonus: newTimeBonus
      })
      setCheckOrContinue('continue')
      setActivateTimer(false)
    }
  }

  const [rootStyle, containerStyle, line2Style] = React.useMemo(() => {
  
     return[
        { opacity: phaseOut ? 0 : 1 },
        {
          height:     wideScreen ? '100vh' : size[1] - 60,
          width:      wideScreen ? size[0] - menuSize : '100%',
          marginLeft: wideScreen ? menuSize : 0,
          marginTop:  wideScreen ? 0 : 60
        },
        {
          opacity:    !wideScreen && success === 'true' ? 1 : 0,
          transition: 'all 0.4s'
        }
      ]
  },[size, wideScreen, phaseOut, menuSize, success])

  return (
  <>
    <div 
      className = { classes.root }
      style     = { rootStyle }
    >
      {
            !wideScreen && 
            <ProgressBar length = { tests[level].length - 1 } testNum = { testNum }/>
      }
      <div 
         className = { !shake ? classes.container : clsx(classes.container, classes.shake) }
         style     = { containerStyle }
      >
          {
              wideScreen &&
              <BigProgressBar 
                size     = { size } 
                length   = { tests[level].length - 1 } 
                prog     = { testNum }
                menuSize = { menuSize }
              />
          }
          <div 
              className = { classes.text }
              style     = { line2Style }
          >
                Correct!
          </div>
          <Image
            size            = { size }
            variant         = { variant }
            src             = { imageSrc }
            animateImage    = { animateImage }
            setAnimateImage = { setAnimateImage }
          />
          <Input
            size                 = { size }
            integer              = { integer }
            fraction             = { fraction }
            combined             = { combined }
            inputInteger         = { inputInteger }
            setInputInteger      = { setInputInteger }
            maxLength            = { maxLength }
            inputNumerator       = { inputNumerator }
            setInputNumerator    = { setInputNumerator }
            inputDenominator     = { inputDenominator }
            setInputDenominator  = { setInputDenominator }
            numeratorMaxLength   = { numerator.length }
            denominatorMaxLength = { denominator.length }
            animateInput         = { animateInput }
            setAnimateInput      = { setAnimateInput }
          />
      </div>
      {
        !wideScreen && <ExitButton func = { () => { setOpenExitModal(true)}}/>
      }
        <ActionBar
           size             = { size }
           menuSize         = { menuSize }
           wideScreen       = { wideScreen }
           name             = { challengeState.selectedChallenge.name }
           disableSkip      = { checkOrContinue === 'continue' }
           disableContinue  = { !inputNumerator.length && !inputInteger.length && !inputDenominator.length }
           resetTimer       = { resetTimer }
           activateTimer    = { activateTimer }
           challengeState   = { challengeState }
           checkOrContinue  = { checkOrContinue }
           success          = { success } 
           handleContinue   = { checkOrContinue === 'check' ? handleCheck : nextTest }
           handleSkip       = { nextTest }
           handleExit       = { () => { setOpenExitModal(true)}}
           setResetTimer    = { setResetTimer } 
           setTime          = { setTime }

           testNum           = { -1 }
           variant           = { 'challenge' }
           text              = { '' }
           enableCheckAnswer = { false }
           prevTest          = { () => {} }
           nextTest          = { () => {} }
           goToPuzzle        = { () => {} }
           checkAnswer       = { () => {} }
           setOpenModal      = { () => {} }
        />        
        <Modal 
           size             = { size } 
           variant          = { 'exit' }
           showModal        = { openExitModal }
           handleCloseModal = { () => setOpenExitModal(false) }
           abortChallenge   = { exitChallengePage }
        />  
    </div>
  </>
  );
}

export default ImageRoom;
