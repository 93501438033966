const apps: { [key: string]: any }[] = [
  {
    "id": 5,
    "lesson_id": "Ordering_digits_game",
    "app": "משחק סידור הספרות",
    "app_type": 1,
    "app_arabic": "لعبة ترتيب الأرقام",
    "app_eng": "Ordering Digits Game",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "המבנה העשרוני",
    "classes": "34",
    "youtube": "Zwa78nd_vaA",
    "youtube_eng": "d9b2Kg-Nm3g",
    "plus": "0",
    "search_words": "ערך המקום, עשרות,יחידות, מאות, אלפים",
    "search_words_eng": "value of place, tens, units, hundreds, thousands game",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידים בכיתות ג-ו (בתנאי שהם מכירים מספרים בני 5 ספרות), והוא מהווה משחק שני שחקנים בנושא ערך המקום במבנה המספר. כל אחד משני השחקנים מתחיל עם המספר 12,345. במהלך המשחק יתאפשר לכל שחקן להזיז 5 פעמים את אחת מספרות המספר שלו ימינה או שמאלה. המחשב הוא זה הקובע באופן רנדומלי לאיזה כיוון יש להזיז ספרה, ובכמה מקומות. אם, למשל, במהלך הראשון על אחד מהשחקנים להזיז במספר שלו את אחת הספרות שמאלה 3 מקומות, והוא מזיז את הספרה 5, יתקבל המספר 15,234. המנצח במשחק הוא זה שהמספר שלו, לאחר 5 הזזות, גדול מזה של יריבו.",
    "description_arabic": "التطبيق مخصص لتلاميذ في الصفين الثالث والرابع (بشرط انهم يعرفون اعداد ذات 5 ارقام)، وهم غبارة عن لعبة للاعبين في موضوع قيمة المكان في المبنى العشري. كل لاعب يبدا مع الأرقام 12,345. خلال اللعبة يتاح لكل لاعب أن يحرك 5 مرات احد الأرقام الي اليمين او اليسار. الحاسوب هو الذي يحدد بطريقة عشوائية لأية جهة يجب تحريك الرقم، وعدد الاماكن. اذا مثلا، في المرحلة الاولى لاحد اللاعببين يجب علية ازاحة رقمة 3 اماكن لليسار، وهو يحرك الرقم 5 ، ينتج لديه العدد  15,234. الفائز في اللعبة هو الذي رقمه، بعد 5 ازاحات، اكبر من الذي لمنافسه.  ",
    "description_eng": "This two-player game is intended to strengthen the understanding of how the value of a digit depends on its position. It can be played in front of the whole class where it is divided into two teams. Alternatively, students can play in pairs, one against the other, on the same device. Each player starts with the number 12,345. In the course of the game each player will have 5 turns in which they can move one of the digits to the right or to the left. The app chooses a movement randomly and the player must choose which digit to apply it to. If, for example, in the first move, a player must move a digit three places to the left, and they move the 5, then the number will be transformed to 15,234. The winner of the game is the player that ends up with the larger number. The game can also be played in decimal modes. The format of the game stays exactly the same but the game starts off with the number 123.45 for example, instead of 12,345.",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 6,
    "lesson_id": "Vertical_addition2",
    "app": "חיבור במאונך",
    "app_type": 0,
    "app_arabic": "الجمع العمودي",
    "app_eng": "Vertical Addition",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "פעולות חשבון במאונך",
    "classes": "23",
    "youtube": "qFmRJ2Dc-t0",
    "youtube_eng": "N1hixX1-x-M",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון עוסק בטכניקה של חיבור במאונך. היישומון מאפשר להציג בצורה חזותית כל תרגיל חיבור שנכתב במאונך. בצד אחד של המסך יוצג תרגיל חיבור במאונך, שבו יבצע המורה את טכניקת החיבור המקובלת, ואילו בצד השני של המסך ידגים המורה כל פעולה טכנית שביצע על המספרים בעזרת ייצוג חזותי שלהם – מטבעות. העבודה הזאת תסייע לתלמידים להבין לעומק מדוע הטכניקה של החיבור במאונך אכן מוליכה אל התוצאה הנכונה.",
    "description_arabic": "التطبيق يتناول الجمع العمودي، التطبيق يمكن من غرض بشكل بصري كل تمرين كتب بشكل جمع عمودي، في جهة واحدة على الشاشة يعرض تمرين حمع عمودي، التي ينفذ قيها المعلم تقنية الجمع العمودي، بينما في الجهة الأخرى للشاشة يغرض المعلم كل غمليات تقنية على الأرقام بمساعدة عرض بصري لها، ـ قطع نقود. عذا العمل تساعد كل تلميذ ان يفهم لماذا تقنية الجمع العمودي فعلا تؤدي الى النتيجة",
    "description_eng": "This app creates a visual representation of the algorithm of vertical subtraction alongside the technical algorithm. In this way, on one side of the screen the teacher implements the technical algorithm and on the other side they can show the visual representation the same operation. This work will help the students understand more deeply why the algorithm actually works.",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 7,
    "lesson_id": "Vertical_subtraction",
    "app": "חיסור במאונך",
    "app_type": 0,
    "app_arabic": "الطرح العمودي",
    "app_eng": "Vertical Subtraction",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "פעולות חשבון במאונך",
    "classes": "23",
    "youtube": "IfyhmAtXTis",
    "youtube_eng": "7U16v2YAGU",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון עוסק בטכניקה של חיסור במאונך. היישומון מאפשר ללוות בצורת ויזואלית  כל תרגיל חיסור שנכתב במאונך. בצד אחד של המסך יוצג תרגיל חיסור במאונך, שבו יבצע המורה את טכניקת החיסור המקובלת, ואילו בצד השני של המסך ידגים המורה כל פעולה טכנית שביצע על המספרים בעזרת ייצוג חזותי שלהם – מטבעות. העבודה הזאת תסייע לתלמידים להבין לעומק מדוע הטכניקה של החיסור במאונך אכן מוליכה אל התוצאה הנכונה.",
    "description_arabic": "التطبيق يتناول تقنية الطرح العمودي. التطبيق يتيح مرافقة بشكل بصري عملية الطرح التي كتبت عموديا، في جعة واحدة للشاشة يعرض تمرين الطرح، الذي فيه ينقذ المعلم تقنية الطرخ العادية المعتادة، بينما في الجهة الأحرى للشاشة يعرض المعلم تفنية الطرح الت نفذها على الأرقام بمساعدة غرص بصري ـ قطع نقود، هذه الحقيقة تساعد التلاميذ في فهم لماذا تفنية الطرح العمودي تؤدي الى النتيجة الصحيحة،  ",
    "description_eng": "",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 9,
    "lesson_id": "Hexagon_challenge",
    "app": "סכומים ומכפלות במשושים",
    "app_type": 1,
    "app_arabic": "جمع وضرب بمسدسات",
    "app_eng": "The Hexagon Challenge",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "תרגולים בפעולות החשבון",
    "classes": "123",
    "youtube": "7qhWytFc88U",
    "youtube_eng": "lx_-6kPEKcg",
    "plus": "0",
    "search_words": "חיבור, כפל, לוח הכפל משחק",
    "search_words_eng": "addition , multiplication, multiplication board game",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידים בכיתות א-ג, והוא מהווה משחק לוח לשני שחקנים. לוח המשחק מורכב ממשושים, כאשר בכל משושה רשום מספר. בכל מהלך צובע השחקן שתורו לשחק שני משושים בצבע שלו. המטרה של כל שחקן היא להיות הראשון שיוצר על הלוח מסלול משושים, עם הצבע שלו, מימין לשמאל או מלמעלה למטה. מבחינה מתמטית נדרשים התלמידים לחפש כאן זוגות של מספרים בעלי סכום נתון או מכפלה נתונה. למשל: שני מספרים מבין 5-9 שסכומם 14 או 2 מספרים מבין 6-9 שמכפלתם 48. ",
    "description_arabic": "التطبيق معد لتلاميذ الصفوف الأول والثالث، وهو بمثابة لوح لعبة لللاعبين. يتكون لوح اللعبة من مسدسات، كل مسدس مسجل فيه رفم. في كل مرحلة يلون اللاعب مسار المسدسات، بلونه الخاص، هدف اللعبة كل لاعب ان يكون اول من بلون المسار من اليمين الى اليسار أو من الأعلى الى الأسفل. من ناحية رياضية التلاميذ مطالبون أن يحدوا زوح أرقام معطى ذات مجموع اعداد معطى أو ضرب معطي, مثلا رقمان من بين   5-9 مجموعهما   14 أو 2 ارقام من بين 6-9 حاصل ضربهما 48. ",
    "description_eng": "The Hexagon Challenge is a two-player game. It is played on a board that is composed of hexagons, each hexagon containing a number. The game can be set to three different modes for practicing three different topics: Simple addition with numbers up to ten, the multiplication table or adding mixed fractions with the same denominator. In their turn, the player must find pairs of adjacent numbers that give a certain sum or product (according the mode chosen). The player then colors the two hexagons with their color. The player that colors a sequence of hexagons that reaches from the right to left of the board or from the top to the bottom of the board - wins the game! ",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 10,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 11,
    "lesson_id": "Multiplication_table",
    "app": "לימוד בעל פה של לוח הכפל",
    "app_type": 2,
    "app_arabic": "حفظ جدول الضرب غيبا",
    "app_eng": "The Multiplication Table",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "תרגולים בפעולות החשבון",
    "classes": "34",
    "youtube": "jE8rUtbdViY",
    "youtube_eng": "jRDarb_0SB0",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לעבודה עצמית של התלמידים. מטרת היישומון היא ללוות אותם מן הרגע שבו הם יודעים בעל פה מעט מאוד תרגילים מלוח הכפל, ועד אשר הם מצליחים לזכור את כולו. כל תלמיד מסמן את התרגילים שהוא כבר יודע בעל פה, ונשאל רק עליהם. במהלך הזמן התלמיד מוסיף לעצמו עוד תרגילים שלמד בעל פה, ונשאל כמובן גם עליהם. עבודת התלמידים ביישומון נעשית במשחק שבו עליהם לפתור כמה שיותר תרגילים בפרק זמן נתון.",
    "description_arabic": "التطبيق مخصص للعمل الذاتي للتلاميذ، هدف التطبيق هو مرافقة التلاميذ من اللحظة التي يعرفون فيها غيبا بعض  تمارين الضرب غيبا من لوح الضرب، حتى ينجحون بتذكره كله. كل تلميذ يشير الى التمارين التي يعرفها، ونسال فقط عنها. مع مرور الزمن يضيف التلميذ لنفسه التمارين التي يعرفها غيبا، ونسال طبعا عنها. عمل التلاميذ في التطبيق يتم في اللعبة التي عليهم حل تمارين أكثر في مدة زمنية محددة.  ",
    "description_eng": "This is an app for practicing the multiplication table independently. It will accompany a student from the moment they can solve a small number of basic multiplication questions by heart - until he or she masters the entire multiplication table. Each student marks the multiplications they already know and then will be given 40 seconds to answer as many of these questions as they can. As the student becomes more and more proficient, they will add new multiplications to practice on until they master the entire multiplication table.",
    "flash": "",
    "category": "תרגול",
    "origin": "apps",
    "totalLikes": 10,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 14,
    "lesson_id": "Distribution_law",
    "app": "חוק הפילוג",
    "app_type": 0,
    "app_arabic": "قانون التوزيع",
    "app_eng": "Distribution Law",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "חוק הפילוג",
    "classes": "34",
    "youtube": "e0OZSyV_XtU",
    "youtube_eng": "18CSaOPpWfo",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מסייע למורה להציג בצורה חזותית ודינאמית את חוק הפילוג. הנה למשל התרגיל 13×5. בעזרת היישומון אפשר להוציא 13 עמודות שבכל אחת 5 עיגולים. ההפרדה של 13 העמודות האלה ל- 10 עמודות ול- 3 עמודות תמחיש לתלמידים את חוק הפילוג: 13×5=3×5+10×5.",
    "description_arabic": "التطبيق يساعد المعلم ان يعرض بشكل بصري وحركي قانون التوسع. مثلا التمرين 13×5. بمساعدة التطبيق يمكن أن نخرج 13 عمودا في كل عمود 5 دوائر. توزيع 13 عمود هذه  ل 10 اعمدة ول 3 اعمدة توصح للتلاميذ فانون التوسيع :   13×5=3×5+10×5.",
    "description_eng": "This app helps the teacher present the Distribution Law in a visual and dynamic way. Let’s take the question 5x13 for example. The app will display this multiplication as 13 columns each containing 5 circles. The app then allows these to be easily separated to 10 columns of 5 circles each and another 3 columns of 5 circles each. This clearly illustrates the distribution law: 13x5=10x5+3x5.   ",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 10,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 15,
    "lesson_id": "Extended_distribution",
    "app": "חוק הפילוג המורחב",
    "app_type": 0,
    "app_arabic": "قانون التوزيع الموسع",
    "app_eng": "Extended Distribution",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "חוק הפילוג",
    "classes": "45",
    "youtube": "aUkk_yZbBSM",
    "youtube_eng": "5IPhpdTwH_k",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר להמחיש בצורה חזותית את חוק הפילוג המורחב. למשל:   (10+3)×(10+5) = 10×10+10×5+3×10+3×5. ההמחשה הזו משמשת גם להבנת האלגוריתם של כפל במאונך, המתבסס על חוק הפילוג הזה. היישומון מאפשר לתלמידים לתרגל את הכפל במאונך באופן עצמאי, במחשבים שלהם,  ולפתח אצלם את ההבנה",
    "description_arabic": "التطبيق يمكن من توضيح بشكل بصري قانون التوسع الموسع. مثلا: (10+3)×(10+5) = 10×10+10×5+3×10+3×5. هذا الوصيح يستخدم ايضا لفهم الغوريثم الضرب العمودي، الذي يعتمد على هذا القانون. التطبيق يمكن التلاميذ التدرب على الضرب العمةدي بشكل نفسي، في حواسيبهم، وتطوير الفهم لديهم. ה ",
    "description_eng": "This app creates a visual representation of the “Extended Distribution”. For example (10+3)×(10+5) = 10×10+10×5+3×10+3×5. The representation also helps explain the algorithm of vertical multiplication that is based on this distribution. That app enables the students to practice and develop their understanding white working with the app independently.",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 16,
    "lesson_id": "Long_division",
    "app": "חילוק ארוך",
    "app_type": 0,
    "app_arabic": "القسمة الطويلة  ",
    "app_eng": "Short Division",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "חילוק בשלמים",
    "classes": "45",
    "youtube": "jTFGy6llrPE",
    "youtube_eng": "uCGhP_PloKo",
    "plus": "0",
    "search_words": "עשרוני חלוקה ",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון עוסק בטכניקה של חילוק ארוך. הטכניקה המוצגת כאן קצרה יותר מן הטכניקה המקובלת.תלמידים רבים מגלים קושי רב בטכניקה המקובלת, ולכן הקיצור המוצג יכול לעזור לרבים מהם. כמו ביישומונים \"חיבור במאונך\" או \"חיסור במאונך\", גם כאן אנו מלווים את החישוב הטכני בייצוג חזותי המסייע מאוד בהבנת התהליך.",
    "description_arabic": "التطبيق يتناول تفنية القسمة الطويلة، التقنية هنا أقصر من التفنية المعتادة. تلاميذ كثيرون يظهرون صعوبة في التقنية المعتادة، ولذلك الاختصار هنا يمكن أن يساعدهم. كما في تطبيقات \" الجمع العمودي\" وتطبيق \" الطرح العمودي\"، هنا ايضا نرافق الحساب التقني بعرض بصري يساهم في فهم العملية. י ",
    "description_eng": "This app deals with the technique of Short Division. Like the vertical addition and vertical subtraction apps, the technical application of the algorithm is accompanied by a visual representation that will help the students gain a deeper understanding of its operation. ",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 17,
    "lesson_id": "Division_multiplication_with_distribution",
    "app": "חילוק וכפל באמצעות חוק הפילוג",
    "app_type": 0,
    "app_arabic": "  قسمة وضرب بواسطة قانون التوزيع",
    "app_eng": "Division and Multiplication with Distribution",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "חילוק בשלמים",
    "classes": "345",
    "youtube": "4VUi0muPurg",
    "youtube_eng": "bvJWoZFW2pg",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "יישומון זה מתאים לתלמידים בכיתות ג-ד, הוא מיועד בעיקר לעבודה עצמית של התלמידים ו/או להדגמה של מורה הכיתה על הלוח. בתרגילי החילוק על המסך מופיע תרגיל כמו 150:6, והתלמידים יכולים לבצע את החישוב בשלבים. אם למשל הם יודעים לחשב כמה הם 150:6, הם יכולים לפצל את 150 ל- 120 ול- 30 וכך להמשיך. עיקרון דומה קיים גם בתרגילי הכפל. את התרגיל 123x4, למשל, אפשר לפרק לתרגיל המחולק לשלושה מחוברים שכל אחד מהם הוא מכפלה: 100x4 ועוד 20x4 ועוד 3x4.",
    "description_arabic": "هذا التطبيق مناسب لحميع تلاميذ الصفوف الثالث والرابع، وهو مخصص في الاساس للعمل الذاتي للتلاميذ و/أو للعرض من قبل المعلم في الصف على اللوح، في تمارين الفسمة على الشاشة يظهر التمرين   150:6, والتلاميذ يستطيعون أجراء الحساب على مراحل، يمكنهم ايضا أن يوزعوا  الـ 150 لـ 120 ولـ 30 وهكذا يستمرون.  مبدأ مشابه موجود ايضا في تمرين الضرب.  التمرين   123x4، مثلا، يمكن تحليل التمرين الى تمرين مقسم الى ثلاثة اقسام متصلة بحيث أن كل واحد منها هو ضرب:   100x4 وايضا 20x4 وكذلك 3x4.",
    "description_eng": "This app can be used in the course of a class demonstration or to allow students to practice independently. In the app, we can present a question such as 645:3. The student can easily split the 645 into two or more numbers to create questions that they can solve, for example: 600:3, 30:3 and 15:3. The same can be done with multiplication. 123x4 can be split to 100x4+20x4+4x4.",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 20,
    "lesson_id": "Matches",
    "app": "פעולות חשבון בגפרורים וציורים",
    "app_type": 0,
    "app_arabic": "عمليات حسابية بعيدان ثقاب  ",
    "app_eng": "Math Operations with Matches",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "123",
    "youtube": "IUrXIvpqWEQ",
    "youtube_eng": "IUfiIq5NDRA",
    "plus": "0",
    "search_words": "חיבור, חיסור, כפל, חילוק, מספר שלם, מספרים שלמים, המבנה העשרוני",
    "search_words_eng": "addition, subtraction, multiplication, division, integer, integers, decimal structure",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מתאים לתלמידים מכיתה א'. אפשר להסביר באמצעותו את המבנה העשרוני של המספר ולהמחיש את פעולות החיבור, החיסור, הכפל והחילוק של מספרים שלמים. חשוב להבין שנוח להשתמש ביישומון עבור מספרים חד ספרתיים ודו ספרתיים. מכניסים אל המסך קבוצה או קבוצות של גפרורים המסודרים לפי המבנה העשרוני ויוצרים בעזרתם תרגיל. אפשר לצרף יחד שתי קבוצות גפרורים, לפרק קבוצה של גפרורים למספר קבוצות, לשכפל קבוצה ועוד. אין גבול לרעיונות שאפשר לממש ביישומון הזה.  ",
    "description_arabic": "التطبيق مناسب لتلامبذ الصف الأول.  يمكن بواسطتة شرح المبنى العشري للعدد وتوضيح عمليات الجمع، الطرح، الضرب والفسمة لاعداد طبيعية. من المهم أن نفهم أنه من السهل استخدام التطبيق لاعداد احادية الأرقام وثنائية الأرقام. ندخل الى الشاشة مجموعة أو مجموعات عيدان ثقاب مرتبة بحسب المبنى العشري ونكون بواسطتها تمرينا.  يمكن اضافة مجموعتين من عيدان الثقاب، تحليل مجموعة عيدان ثقاب الى عدة مجموعات، نسخ لمجموعة واحدة وغيرها. لا توجد حدود لافكار بمكن تحقيقها بواسطة هذا التطبيق.    ",
    "description_eng": "This app can be used from first grade onward and is most useful for use for numbers under 100. The numbers are also represented as rows of math grouped into tens. The app can be used to demonstrate addition, subtraction, multiplication and division of whole numbers. It’s easy to combine groups of matches, take them apart, duplicate numbers etc. There is no limit to the ideas that can be demonstrated in this versatile app.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 21,
    "lesson_id": "Order_of_operations",
    "app": "סדר פעולות החשבון",
    "app_type": 0,
    "app_arabic": "تسلسل العمليات الحسابية  ",
    "app_eng": "Order of Operations",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "34",
    "youtube": "TnGfD_DtLXc",
    "youtube_eng": "O30YB8LpNgY",
    "plus": "0",
    "search_words": "סוגריים",
    "search_words_eng": "Parenthesis",
    "interaction": "",
    "student_page": "",
    "description": "יישומון זה מאפשר תרגול של סדר פעולות החשבון. כל שנדרש כאן הוא לרשום תרגיל כלשהו, לגרור את התרגיל אל המסך ואז לפתור אותו בסדר כלשהו. אם גם מבקשים מהמחשב לא לאשר פעולות הנוגדות את סדר פעולות החשבון, המחשב לא יאפשר לבצע אותן.",
    "description_arabic": "يمكن هذا التطبيق من التدرب على تسلسل العمليات الحسابية، كل ما يلزم هو تسحيل تمرين معين، وجره الى الشاشة وثم حله بتسلسل معين. اذا طلبنا من الحاسوب عدم تصديق العمليات التي تنافض تسلسل العمليات الحسابية، فالحاسوب لن يصادق على تنفيذها.  ",
    "description_eng": "This app is designed to teach and practice the topic of \"Order of Operations\". In the app we write a mathematical expression such as \"2x4+6+10\". When we click an operation, we get the same expression after the operation has acted on the numbers to its right and left. After clicking on the multiplication, we would get \"8+6+10\" below the original expression. We can block illegal operations such as starting with a click on the plus sign between the 4 and 6. ",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 23,
    "lesson_id": "Average3",
    "app": "ממוצע ",
    "app_type": 0,
    "app_arabic": "المعدل  ",
    "app_eng": "Average",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "5",
    "youtube": "P3G_H5-slFc",
    "youtube_eng": "7N5H80Ab1gk",
    "plus": "0",
    "search_words": "חקר נתונים",
    "search_words_eng": "data exploration ",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מתאים לתלמידים בכיתה ה'. אל המסך ניתן להכניס ילדים ולתת לכל אחד מהם מספר עיגולים. המשימה היא לחשב את מספר העיגולים הממוצע לילד. התלמיד צריך להבין שעליו לחלק את העיגולים שווה בשווה בין הילדים, והכמות השווה היא הממוצע. היישומון מציע שתי דרכים לקבלת החלוקה השווה. לאסוף מכל הילדים את כל העיגולים ואז לחלק אותם שווה בשווה בין הילדים, או פשוט לבצע העברות של עיגולים מילד לילד עד לקבלת הכמות השווה.",
    "description_arabic": "التطبيق مناسب لتلاميذ الصف الخامس. الى الشاشة يمكن اولاد واعطاء كل واحد منهم عدة دوائر. المهمة هي ايجاد معدل الدوائر لكل ولد، والتلميذ يجب أن يفهم أن عليه تقسيم الدوائر بالتساوي بين الأولاد. والكمية المتساوية هي المعدل، التطبيق يقترح طريقتين للقسمة المتساوية، جمع كل الدوائر من الاولاد وثم تقسيمها بالتساوي بين الآولآد، او تمرير الدوائر بين الآولآد حتى تتساوى.  ",
    "description_eng": "This app allows the teacher to bring boys onto the screen and to give each of them a certain number of circles. The task is then to ascertain the average number of circles per boy. We would like the student to understand that the solution can be reached by distributing the circles equally amongst the boys. This can be done in two ways. The circles can all be collected and then distributed equally. Alternatively, some circles can be transferred from one boy to the other.  ",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 24,
    "lesson_id": "Odd_and_even",
    "app": "זוגי וא-זוגי",
    "app_type": 0,
    "app_arabic": "زوجي وفردي",
    "app_eng": "Odd and Even",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "123",
    "youtube": "0ooESTImq5M",
    "youtube_eng": "M1vglA6Wwek",
    "plus": "0",
    "search_words": "אי זוגי",
    "search_words_eng": "odd",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר להבין לעומק טענות כמו: מספר אי־זוגי ועוד מספר אי־זוגי שווה מספר זוגי, או למשל מספר זוגי כפול מספר אי־זוגי שווה מספר זוגי. לכל מספר ביישומון יש גם ייצוג חזותי: המספרים הזוגיים נראים כמו אוטובוסים – מלבנים ברוחב 2 שהם בעצם זוגות־זוגות של ריבועי יחידה. המספרים האי־זוגיים נראים כמו משאיות – מלבנים ברוחב 2 שיש להם תוספת של ריבוע יחידה קטן בקצה. הייצוגים האלה מאפשרים להבין בצורה כללית את הטענות שהצגנו לעיל וטענות דומות אחרות.  ",
    "description_arabic": "",
    "description_eng": "",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 25,
    "lesson_id": "Dividing_in_three",
    "app": "סימן התחלקות ב-3",
    "app_type": 0,
    "app_arabic": "دلالة قابلية القسمة على 3  ",
    "app_eng": "Dividing in Three",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "4",
    "youtube": "rbi4pW0Knzk",
    "youtube_eng": "FATCictwCIg",
    "plus": "0",
    "search_words": "סימני התחלקות חילוק חלקה שלושה",
    "search_words_eng": "Signs of division",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לכיתה שתלמידיה כבר גילו את סימן ההתחלקות ב- 3. הגילוי הזה מעניין, אך בדרך כלל אינו טומן בחובו הסבר לשאלה מדוע מסקנתו היא אכן נכונה. בעזרת היישומון תוכלו להציג הוכחה חזותית, מעניינת, דינאמית וקצרה לסימן ההתחלקות הזה",
    "description_arabic": "التطبيق مخصص للصف الذي اكتشف تلاميذه اكتشف علامة القسمة على 3. هذا الأكتشاف مثير، لكن عادة لا يكمن في داخله تفسيرا للسؤال لماذا استنتاجه هو فعلا صحيحا. بمساعدة التطبيق يمكنكم عرض اثبات بصري، مثير، متحرك وقصير لعلامة الفسمة.  ",
    "description_eng": "This app is designed for students that learned the technique for finding out if a number is divisible by three. Students usually don’t learn why this actually works. With this app you will be able to present an interesting and visual explanation as to why the technique works. ",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 26,
    "lesson_id": "Fractionization",
    "app": "פירוק לגורמים עד 1000",
    "app_type": 0,
    "app_arabic": "تحليل الى العوامل حتى 1000  ",
    "app_eng": "Factorization",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "45",
    "youtube": "WpsGpAzgGHg",
    "youtube_eng": "zgOkh9Pvgi8",
    "plus": "0",
    "search_words": "ראשוני, כפולה משותפת, מחלק משותף",
    "search_words_eng": "primary, numerator, denominator, shared multiplication, shared divisor",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר לפרק לגורמים מספרים הקטנים מ- 1000 למכפלת הגורמים הראשוניים שלהם. שיטת הפירוק היא בעזרת עץ. כמו כן היישומון מאפשר לגלות בדרך שיטתית את כל הפירוקים של המספר למכפלה של שני מספרים.   ",
    "description_arabic": "التطبيق يمكن تحليل الى عوامل اعداد اصغر من 1000 الى عوامله الاولية، طريقة التحليل هي بمساعدة الشجرة، كذلك التطبيق يمكن اكتشاف بطريقة منهجية كل التحليلات للعدد الى عوامل الثنائية.  ",
    "description_eng": "This app enables the Factorization of numbers to their prime components. The factorization is represented as a tree. The app also offers a convenient tool to use the prime numbers that were reached by the factorization to systematically find all two number multiplications that give that number as a product.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 31,
    "lesson_id": "Powers",
    "app": "חזקות",
    "app_type": 0,
    "app_arabic": "القوى",
    "app_eng": "Exponentiation",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "457",
    "youtube": "uZ9TvzS-4ps",
    "youtube_eng": "z2hdVDmcs8w",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר למורה להציג לתלמידיו בנוחות ובזריזות את הגדרת החזקה. בנינו את היישומון כדי להוביל את התלמידים לתובנה שהחזקה היא קיצור של כפל חוזר, בדיוק כפי שהכפל הוא קיצור של חיבור חוזר. היישומון מאפשר גם ללמד בנוחות כללי חזקות בסיסיים.",
    "description_arabic": "التطبيق يتيح للمغلم ان يعرض لتلاميذه تعريف الأسس. بنينا التطبيق لكي نوصل التلاميذ الى فهم ان الأسس هو اختصار لضرب متكرر، بالضبط كما الضرب هو اختصار لجمع متكرر، التطبيق بتيح للتلاميذ ان يتعلموا بسهولة قوانين الأسس هذا:   (a^n×a^m=a^(m+n.  ",
    "description_eng": "This app helps present the definition of \"Exponentiation\" in a clear and effective way in class. It helps demonstrate that Exponentiation is shorthand for repeated multiplication in the same manner that multiplication is shorthand for repeated addition. The app also helps explain basic Exponentiation rules.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 32,
    "lesson_id": "Ratios2",
    "app": "חלוקה ביחס",
    "app_type": 0,
    "app_arabic": "تقسيم بنسبة",
    "app_eng": "Ratios",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "67",
    "youtube": "m-kIbkvjyCE",
    "youtube_eng": "a9-S4zWqHQE",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "Ratio",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ו-ז, והוא מציג המחשה נוחה לנושא החלוקה ביחס. אל המסך מכניסים מספר ילדים, ואז מחליטים על כמות עיגולים אותם רוצים לחלק ביניהם ועל יחס החלוקה. בשלב הבא מתבצעת החלוקה בצורה דינמית על המסך. אם למשל רוצים לחלק 20 עיגולים לשני ילדים, ביחס של 1:4, אזי בכל שלב מכניסים אל המסך 5 עיגולים שאחד מהם עובר אל הילד הראשון ואילו ארבעת הנותרים אל הילד השני. כך יכולים התלמידים להבין שהילד הראשון מקבל חמישית ממספר העיגולים ואילו השני ארבע חמישיות ממספר העיגולים. הסתכלות ויזואלית זו חשובה מאוד על מנת להבין לעומק את הרעיון הלא פשוט של חלוקה ביחס.",
    "description_arabic": "التطبيق معد لتلاميذ الصفين الخامس والسادس، وهو ملائم لاكساب موضوع النسبة، بمساعدة موديل بصري لمستطيل، يغهم التلاميذ كيفية حساب نسبة معينة لعدد طبيعي معطى.  ",
    "description_eng": "This app helps present the topic of Ratios in a visual and clear way. There are two children on the screen and we choose a ratio in which we wish to divide balls between them. For example: We can choose 20 balls to be distributed with the ratio 1:4. This is done in stages where, in each stage, 5 of the 20 balls are distributed: 1 to one child and 4 to the other. In this way the students can understand that the first child receives one fifth of the quantity and second receives four fifths. The visual approach is important in order to tackle this challenging topic.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 33,
    "lesson_id": "Multiplying_on_the_axis",
    "app": "כפל במודל ישר המספרים",
    "app_type": 0,
    "app_arabic": "",
    "app_eng": "",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "234567",
    "youtube": "tg6p__45oBY",
    "youtube_eng": "6Z_AJYrYdNU",
    "plus": "0",
    "search_words": "כפל שברים ציר",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "זהו יישומון מאוד נוח להדגמת הכפל במודל הציר. מטפח את ההבנה של הכפל וגם את ההבנה של הציר. נוח לעבודה מאוד גם בעבודה עם שברים.",
    "description_arabic": "",
    "description_eng": "",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 37,
    "lesson_id": "Decimal_square",
    "app": "שבר עשרוני ואחוזים בקלי קלות",
    "app_type": 0,
    "app_arabic": "عدد عشري ونسبة مئوية بسهولة",
    "app_eng": "Easy Decimals",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "מספרים עשרוניים",
    "classes": "567",
    "youtube": "4C8CZkBH7Yc",
    "youtube_eng": "Q2um-87SqsQ",
    "plus": "0",
    "search_words": "שברים עשרוניים, עשירית, מאית, אלפית",
    "search_words_eng": "decimal fractions, one tenth, one hundredth, one thousandth",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר להסביר בצורה חזותית מהירה את רעיון השבר העשרוני – עשיריות, מאיות ואלפיות. אל המסך מכניסים ריבוע שהוא היחידה, ובזריזות מחלקים אותו כך שניתן יהיה להציג בעזרתו מספרים עשרונים בין 0 ל- 1. היישומון נוח גם לחיבור וחיסור של שברים כאלה ואף להשוואה ביניהם.  ",
    "description_arabic": "التطبيق هو لعبة بين مجموعتين في الصف، في كل مرحلة يظهر على اشاشة مستطيل قسم من ملون، كل مجموعة تقدر بكسر عشري اي قسم من المستطبل ملون، تقدير الصحيح يفوز بنفطة. المجموعة الفائزة هي التي تجمع اكبر عدد من النقاط.  ",
    "description_eng": "The Easy Decimals app does exactly what its title suggests; namely, it makes decimals easy to visualize and understand. Using models that make sense, students will grasp the meaning of decimal notation and will learn how to represent decimals using the basic square grid.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 38,
    "lesson_id": "Decimal_approximation_game",
    "app": "משחק הערכה עשרוני",
    "app_type": 1,
    "app_arabic": "لعبة تقدير عشري   ",
    "app_eng": "Decimal Estimation Game",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "מספרים עשרוניים",
    "classes": "567",
    "youtube": "uMSVCNJ8UrI",
    "youtube_eng": "LlTmlltHwHE",
    "plus": "0",
    "search_words": "אומדן ציר ישר",
    "search_words_eng": "estimate",
    "interaction": "",
    "student_page": "",
    "description": "היישומון הוא משחק המשוחק בכיתה בין שתי קבוצות. בכל שלב מופיע על הצג מלבן שחלקו צבוע. כל קבוצה בתורה מעריכה במספר עשרוני איזה חלק מן המלבן צבוע. הערכה באזור הנכון תזכה את הקבוצה בנקודה. הקבוצה המנצחת במשחק היא כמובן הקבוצה שזוכה במספר הנקודות הגבוה",
    "description_arabic": "التطبيق يمكن من تفسير للتلاميذ بشكل مريح وواضح موضوع \" ازاحة النقطة العشرية\" في تمارين الضرب. الألغوريثم هو كالتالي: نفترض اننا نريد ان نضرب العدد 3.4 في العدد 5.29. بدل ان ننفذ عملية الضرب نحن نضرب العدد 34 في العدد 529، وعندها نزيح النقطة العشرية للنتيجة ثلاثة اماكن لليسار. لماذا 3 اماكن؟ لأن في عملية الضرب للتمرين كان 3 ارقام بعد النقطة العشرية. الجواب الأخير لم تكن تعليلا بل تفسيرا \"موجب\". بمساعدة التطبيق نزود تعليلا واصحا ومفهوما للسؤال الي سألناه.  ",
    "description_eng": "This game is to be played between two teams. At every stage, a square is shown where part of it is colored. Every team, in turn, estimates what part of the square is colored. If the estimation is close enough, the team earns a point. The team who acquires the most points wins the game.",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 40,
    "lesson_id": "Multiplying_dividing_by_10",
    "app": "הכפלה או חילוק בעשר",
    "app_type": 0,
    "app_arabic": "ضرب أو قسمة على عشرة  ",
    "app_eng": "Multiplying or Dividing by 10",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "מספרים עשרוניים",
    "classes": "3",
    "youtube": "ypuwt7Zav-k",
    "youtube_eng": "eFIYQgiMgsU",
    "plus": "0",
    "search_words": "כפל מספר עשרוני ב- 10 100 ",
    "search_words_eng": "multiply, multiplication",
    "interaction": "",
    "student_page": "",
    "description": "היישומון עוסק בעובדה שכאשר כופלים מספר עשרוני בעשר, הנקודה העשרונית זזה מקום אחד ימינה, ולעומת זאת כאשר מחלקים מספר עשרוני בעשר, הנקודה העשרונית זזה מקום אחד שמאלה. היישומון מאפשר למורה להציג את העובדה הזאת בצורה חזותית נוחה על המסך ואף להציג את ההוכחה לכך.  ",
    "description_arabic": "التطبيق يعرض موديل الدوائر لعرض كسور، وهو يمكن من توضيح بشكل بصري تقريبا كل مادة التعليم في موضوع الكسور البسيطة، بمساعدة التطبيق يمكن رسم دوائر، وتقسمها الى حزوز متساوية، ان نفصل بين الحزوز، وتفسيم الحزوز الى حزوز متساوية وغيرها كثير. غنى العمليات هذا يمكن من عرض افكار جديدة وعلى التمارين الطاهرة في كتاب التعليم.  ",
    "description_eng": "When we multiply a whole number by 10, a zero is added to the right of the number. When multiplying a decimal number by ten, the decimal point moves one digit to the right. This visual app helps explain in a clear and deep way this works. ",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 41,
    "lesson_id": "Fraction_circle_model",
    "app": "שברים במודל העיגולים",
    "app_type": 0,
    "app_arabic": "كسور بموديل الدوائر  ",
    "app_eng": "Fraction Circle Model",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "שברים במודלים שונים",
    "classes": "3456",
    "youtube": "KReLZMFnPvA",
    "youtube_eng": "L72ZwSqLF1Q",
    "plus": "0",
    "search_words": "כללי",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מציג את מודל העיגולים לייצוג שברים, והוא מאפשר להמחיש בצורה חזותית כמעט את כל חומר הלימוד בנושא השברים הפשוטים. בעזרת היישומון אפשר לשרטט עיגולים, לחלק אותם לפלחים שווים, להפריד את הפלחים, לחלק את הפלחים לפלחים שווים ועוד רבות. עושר הפעולות הזה מאפשר להציג התבוננויות חדשות על רעיונות ועל תרגילים המופיעים בספרי הלימוד",
    "description_arabic": "بني التطبيق ليمكن المعلم من تفسير بشكل بسيط وواضح الافكار الأساسية لموضوع الكسور ـ كسور الوحدة والمقارنة بينها. عنصر هام في التطبيق هو كتابة الكسور بالكلمات ـ كتابة كهذه يمكنها ان تؤدي الى فهم افضل للموضوع. ה ",
    "description_eng": "This application employs the Circle Model to visually convey almost all of the material on simple fractions. Using this application, the teacher can easily prepare circle diagrams, divide circles into equal sections, separate sections, subdivide sections into equal parts, and much more. The application’s versatility lets teachers display new perspectives on the concepts taught and on textbook examples and exercises.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 42,
    "lesson_id": "Half_third_fourth",
    "app": "ממלאים עיגולים בשברים",
    "app_type": 0,
    "app_arabic": "نملأ دوائر بكسور  ",
    "app_eng": "Placing Fractions in Circles",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "שברים במודלים שונים",
    "classes": "34",
    "youtube": "5N-hQEAfwi8",
    "youtube_eng": "tmzj2-J7jB4",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון נבנה כדי לאפשר למורה להסביר בצורה פשוטה וברורה את הרעיונות הבסיסיים של נושא השברים – שברי יחידה והשוואת שברים. אלמנט חשוב ביישומון הוא הכתיבה של השברים במילים – כתיבה שכזו יכולה להוביל להבנה טובה יותר של הנושא.",
    "description_arabic": "بني التطبيق ليمكن المعلم من تفسير بشكل بسيط وواضح الافكار الأساسية لموضوع الكسور ـ كسور الوحدة والمقارنة بينها. عنصر هام في التطبيق هو كتابة الكسور بالكلمات ـ كتابة كهذه يمكنها ان تؤدي الى فهم افضل للموضوع.",
    "description_eng": "This app was created to help the teacher explain in a clear and simple way basic concepts in the topic of fractions such as unit fractions and fraction comparison. The app also develops a verbal understanding of fraction questions as it enables the expression of the fractions in words. ",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 43,
    "lesson_id": "Rectangle_fraction_model",
    "app": "שברים במודל המלבנים",
    "app_type": 0,
    "app_arabic": "كسور بموديل المستطيلات  ",
    "app_eng": "Fractions in the Rectangle Model",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "שברים במודלים שונים",
    "classes": "3456",
    "youtube": "8Cr7VoYw5Ws",
    "youtube_eng": "4DDC3gGisck",
    "plus": "0",
    "search_words": "אחוזים",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מציג את מודל העוגה המלבנית ללימוד השבר הפשוט. היישומון מאפשר להמחיש בצורה חזותית כמעט את כל חומר הלימוד בנושא השברים הפשוטים והשברים העשרוניים. בעזרת היישומון המורה יכול להכין בנקל שרטוטים של מלבנים המחולקים למלבנים פנימיים שחלקם מחולקים גם כן למלבנים פנימיים וכך הלאה. כמו כן המורה יכול למחוק בנוחות קטעים פנימיים בתוך האיורים שיצר ובכך לקבל בסופו של דבר שרטוטים מעניינים ומלמדים, שכמותם כמעט ואין רואים בספרי הלימוד הרגילים. ",
    "description_arabic": "التطبيق يعرض موديل الكعكة المستطيلة لتعليم الكسر البسيط. التطبيق يمكن ان نوضح بشكل بصري تقريبا كل المادة التعليمية بموضوع الكسور البسيطة والكسر العشرية. بمساعدة التطبيق يمكن للمعلم أن يرسم بسهولة تخطيطات لمستطيلات مقسمة لمستطيلات داخلية التي بعضها مقسم لمستطيلات داخلية وهكذا دواليك. كذلك يستطيع المعلم أن يمحو قطع داخلية داخل الرسم الذي انتجه ويحصل في النهاية رسمات مثيرة، لا يمكن رؤية مثيلاتها في كتب التعليم.  ",
    "description_eng": "This application uses the Rectangle Model to illustrate a wide range of learning material on simple fractions. Using this application, teachers can easily prepare diagrams of rectangles divided into smaller rectangles, which can in turn can be divided into still smaller rectangles, and so forth. Likewise, teachers can easily remove internal partitions from diagrams to illustrate concepts such as fraction reduction.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 45,
    "lesson_id": "Rectangle_model_division",
    "app": "כפל שברים במודל המלבנים",
    "app_type": 0,
    "app_arabic": "ضرب كسور بموديل المستطيلات  ",
    "app_eng": "Multiplying Fractions in the Rectangle Model",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "6",
    "youtube": "gLdNjED0_1Q",
    "youtube_eng": "qwwl3cHxUtI",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר למורה להוליך את התלמידים להבנה עמוקה של כפל שברים. במסך היישומון מופיעה בעיה מילולית המתאימה לכפל שברים. המשתמש יכול להחליף את השברים המופיעים בבעיה. תחילה יבינו התלמידים כיצד כופלים שברי יחידה, ואט אט יגלו איך כופלים גם שברים שאינם כאלה. החזותיות המלווה את השאלות מאפשרת להבין בנקל מדוע בהכפלת שברים כופלים מונה כפול מונה ומכנה כפול מכנה. ",
    "description_arabic": "التطبيق يمكن المعلم ان يتوصل التلاميذ الى فهم عميق لضرب الكسور. على الشاشة تظهر مسألة كلامية ملائمة لضرب الكسور. المستخدم يمكنه تبديل الكسور التي في المسألة. في البداية يفهم التلاميذ كيف نضرب كسور وحدة، وتدريجيا يكتشفون كيف نضرب كسور ليست كهذه، البصرية التي ترافق الأسئلة تمكن من فهم بسطولة لماذا عند ضرب كسور نضرب المقامات ببعصها ونضرب  البسط بالبسط  .  ",
    "description_eng": "Visualizing fraction multiplication in a realistic context empowers students to appreciate the meaning of fraction multiplication and understand the logic behind the algorithmic procedure. This app offers multiplication situations that are realistic, easy to understand, and fun to solve.",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 46,
    "lesson_id": "Whole_times_fraction_page",
    "app": "שלם כפול שבר",
    "app_type": 0,
    "app_arabic": "صحيح ضرب كسر",
    "app_eng": "Whole Times a Fraction",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "456",
    "youtube": "BD_p--lk5Bs",
    "youtube_eng": "3Zi-1aTu3MY",
    "plus": "0",
    "search_words": "שברים כפל בהופכי",
    "search_words_eng": "fractions",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מבוסס על מודל העיגולים לייצוג שברים, ובאמצעותו ילמדו התלמידים לפתור בצורה חזותית תרגילי כפל שאחד מן המוכפלים בהם הוא מספר שלם, ואילו השני - שבר (או מספר מעורב). העבודה החזותית תפתח אצל התלמידים את היכולת לפתור בראש תרגילים דומים, בזמן שהם מדמיינים את הייצוגים החזותיים שהופיעו ביישומון.  ",
    "description_arabic": "يعتمد التطبيق على موديل الدوائر لعرض الكسور. بواسطته يتعلم التلاميذ حل بشكل بصري تمارين ضرب احد المضروبين هو عدد كامل، بينما الثاني هو كسر (أو عدد مختلط). العمل البصري تطور لدى التلاميذ القدرة على حل تمارين مشاية . حين يتخيلون العروض البصرية التي ظهرت في التطبيق. ",
    "description_eng": "This app is based on fractions represented in the circle model. With this visual tool, the students will learn to solve multiplication problems where one of the factors is a whole number and the other one a mixed number or proper fraction. The goal is that the students internalize the visual representation and will develop their capability to solve such problems without pencil and paper.",
    "flash": "",
    "category": "שיטת פתרון",
    "origin": "apps",
    "totalLikes": 10,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 48,
    "lesson_id": "Division_with_circle_model",
    "app": "חילוק להכלה במודל העיגולים",
    "app_type": 0,
    "app_arabic": "قسمة طويلة في موديل الدوائر",
    "app_eng": "Division in the Circle Model",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "3456",
    "youtube": "l9pwxD0tp_o",
    "youtube_eng": "J6AP-czo6jI",
    "plus": "0",
    "search_words": "שברים חלוקה שבר לחלק לשבר",
    "search_words_eng": "fractions",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר להציג את הנושא של חילוק להכלה. כדי לענות על השאלה כמה הם 6 לחלק לאחת וחצי? יש לבדוק כמה פעמים נכנס אחת וחצי ב- 6. היישומון מציג 6 עיגולים ולידם עיגול וחצי, והוא מאפשר לבחון בעזרת דינמיות וצבעים כמה פעמים אפשר להכניס את העיגול וחצי בתוך ששת העיגולים. הדוגמה שהצגנו זה עתה מתאימה לתלמידים בכיתה ו, אך אפשר להציג ביישומון גם תרגילי חילוק של מספר שלם חלקי מספר שלם שיכולים להתאים לתלמידים בכיתות נמוכות יותר.",
    "description_arabic": "التطبيق يمكن من عرض موضوع الفسمة الطويلة. لكي نجيب عن السؤال نقسم الى الي واح ونصف؟ يجب فحص كم مرة يدخل واحد ونصف غي 6. التطبيق يعرض 6 دوائر وبجانبها دائر ونصف، وهو يمكن فحص يمكن ان ندخل الدائرة والنصف داخل 6 دوائر. المثال الذي عرضناه ملائم لتلاميذ الصف السادس، لكن يمكن عرض في التطبيق ايضا تمارين قسمة عدد صحيح على عدد صحيح ملائمة لتلاميذ الصفوف الا وطأ.  ",
    "description_eng": "This app helps represent the topic of division in a very visual way. For example, we can create the question: \"What is six divided by one and half?\" this question can't be solved as \"Fair share division\" because we can't divide a number into \"one and a half boys\". We can do \"Measurement division\". Here we ask ourselves \"How many times does one a half fit into six?\" The app shows the answer to this question very clearly.  The app can be used with a wide variety of division questions that involve fractions and can be used advantageously with whole numbers as well. ",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 50,
    "lesson_id": "Fraction_bingo",
    "app": "בינגו שברים",
    "app_type": 0,
    "app_arabic": "بينجو الكسور  ",
    "app_eng": "Fraction Bingo",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "45",
    "youtube": "G8Dg4QA4JGs",
    "youtube_eng": "C5PI2I17VKE",
    "plus": "0",
    "search_words": "משחק",
    "search_words_eng": "game",
    "interaction": "",
    "student_page": "",
    "description": "היישומון הוא  משחק בינגו המיועד לשני שחקנים. במהלך המשחק על השחקנים לפתור תרגילי חיבור וחיסור של שברים ושל מספרים מעורבים. בכל תרגיל יופיעו אותם המכנים. המטרה כאן היא לתרגל בעל פה את התרגילים האלה.",
    "description_arabic": "التطبيق هو لعبة بينجو مخصصة للاعبين. اثناء اللعبة على اللاعبين حل تمارين جمع وطرح علة كسور وعلة اغداد مختلطة، في كل تمرين تظهر نفس المقام. الهدف هنا التدرب غيبا على هذا التمارين.  ",
    "description_eng": "This is a bingo game for two players. It can be played in class as a competition between two teams or as a game between two students. In the course of the game, the players are tasked with solving addition and subtraction questions with mixed fractions with the same denominator. The goal is to develop the students’ capability of solving these types of questions without pencil and paper.  ",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 51,
    "lesson_id": "Division_to_fraction",
    "app": "השבר כמנת חילוק- יישומון",
    "app_type": 0,
    "app_arabic": "الكسر كقسمة- تطبيق   ",
    "app_eng": "The Fraction as a Solution to a Division Problem",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "56",
    "youtube": "dXhV_0pDEeM",
    "youtube_eng": "XMhdr7ny_IM",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידים בכיתות ה-ו, והוא מאפשר להסביר בצורה פשוטה וויזואלית את הרעיון של השבר כמנת חילוק – מדוע, כאשר מחלקים למשל 4 פיצות ל- 5 ילדים, כל ילד מקבל ארבע חמישיות של פיצה.  ",
    "description_arabic": "التطبيق معد لتلاميذ الصفوف الخامس والسادس، يمكن من تفصير بشكل بسيط ومرئي فكرة الكسر كفسمة ـ لماذا، عندما نفسم مثلا 4 بيتسا علة 5 اولاد، كل ولد يأخذ خمس البيتسا.   ",
    "description_eng": "This app helps make the connection between the fraction as a part of a whole to a fraction as a solution of a division problem. For example, why when dividing four pizzas to five boys, each boy will receive four fifths.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 52,
    "lesson_id": "Fraction_memory_game",
    "app": "משחק הזיכרון בשברים",
    "app_type": 1,
    "app_arabic": "لعبة الذاكرة في الكسور  ",
    "app_eng": "Fraction Memory Game",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "משחקים בשברים",
    "classes": "34",
    "youtube": "Rkves27MIRY",
    "youtube_eng": "vGf8i1__n1M",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון שלפנינו מתאים לתלמידים מסוף כיתה ג' ועד לכיתה ו. ביישומון מופיעים קלפים שמצוירים עליהם ציורים של צורות לא שגרתיות. התלמידים מתבקשים לגלות איזה חלק מכל צורה צבוע. החלק הצבוע יהיה תמיד חצי שליש או רבע מן הצורה כולה. לאחר אימון בזיהוי השברים המתאימים לקלפים השונים, ישחקו התלמידים בזוגות את משחק הזיכרון עם הקלפים האלה. כל זוג תלמידים במחשב שלו.",
    "description_arabic": "التطبيق الذي امامنا مناسب لتلاميذ من نهاية الصف الثالث وحتى الصف السادس. في التطبيق تظهر بطاقات مرسوم عليها رسومات ليست عادية. المطلوب من التلاميذ اكتشاف الجزء الملون. الفسم الملون يكون دائما نصف ثلث أو ربع الصورة كلها، بعد التدرب علة تشخيص الكسور الملائمة للبطاقات المختلفة، يلعبون التلاميذ بازواج لعية الذاكرة مع البطاقات هذه، كل زوح تلاميذ يلعبون في حاسوب واحد.  ",
    "description_eng": "A fun and addictive fraction memory game. By playing the game Fraction Math Match, students come to appreciate various aspects of fraction representation including visual, geometric, numeric, and artistic.",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 54,
    "lesson_id": "Filling_fraction_circle_game",
    "app": "משחק צביעת עיגולי השברים",
    "app_type": 1,
    "app_arabic": "لعبة تلوين دوائر الكسور  ",
    "app_eng": "Coloring Circle Fraction Game ",
    "display_app_eng": "false",
    "top_topic": "שבר",
    "topic": "משחקים בשברים",
    "classes": "45",
    "youtube": "a-brErHhcb4",
    "youtube_eng": "4AZAAdof7gE",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידים בכיתות ד-ו, והוא מהווה משחק שני שחקנים בנושא שמות שונים לשבר. לכל שחקן 5 עיגולים ריקים – עיגול של חצאים, עיגול של שלישים, עיגול של רבעים, עיגול של שישיות ועיגול המחולק ל- 12 חלקים שווים. בכל תור המחשב מגריל עבור השחקן שתורו לשחק שבר מרשימת השברים המופיעה בראש המסך. השחקן צריך לצבוע בעיגולים שלו את השבר שהתקבל. בכל תור מותר לצבוע עיגול אחד ויחיד. כל הנצבע בשלמותו מזכה את השחקן שמילא אותו בנקודה, והוא מיד הופך שוב לריק. המנצח הוא השחקן המגיע ראשון ל- 5 נקודות. ",
    "description_arabic": "التطبيق معد لتلامنيذ الصفوف الرابع ـ السادس. وهو عبارة عن لعبة للاعبين في موضوع اسماء مختلفة لكسور، لكل لاعب 5 دوائر فارغة ـ دائرة لانصاف، دائرة لاثلاث، دائرة لارباع، دائرة لاسداس، ودائرة مفسمة الى 12 قسم متساو. كل مرة الحاسوب يظهر للاعب كسرا من قائمة الكسور التي تظهر في رأس الشاشة. اللاعب يجب عليه تلوين دوائر خاصته الكسر الذي نتج.    كل دور مسموح تلوين دائرة واحدة فقط.  .كل الذي تلون بكامله يحظى اللاعب بنقطة، وهو يتحول ثانية الى فارغ، الفائز هو اللاعب الذي يصل اولا الى 5 نفاط.   . ",
    "description_eng": "This app is a two-player game on the topic of reduction and expansion of fractions. Every player has five circles divided into different unit fractions.  In each turn a player gets a random quantity to color. If they get a \"Half\" they may color, for example, two empty quarters in the circle divided into quarters, or three empty sixths in the circle divided into sixths. When a circle is filled, the player gets a point and the circle is cleared and ready to be filled again. The first player to reach 5 points wins the game! ",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 57,
    "lesson_id": "Expantion_and_reduction_page",
    "app": "צמצום והרחבה של שברים",
    "app_type": 0,
    "app_arabic": "اختزال وتوسيع الكسور",
    "app_eng": "Reduction and Expantion of Fractions",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "נושאים נוספים בשברים",
    "classes": "45",
    "youtube": "7u6SX5x5hiQ",
    "youtube_eng": "OkItoKjn8B8",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון הזה מאפשר לתלמידים להבין לעומק ובאופן חזותי ודינאמי את נושא ההרחבה והצמצום של שברים. מודל השברים המלווה את היישומון הוא מלבן המחולק למלבנים שווי גודל, שחלק מהם צבועים. כמו כן השימוש ביישומון יחזק אצל התלמידים את הבנת מושג השבר – מכנה השבר הוא מספר החלקים שחולק המלבן השלם, ואילו המונה הוא מספר החלקים הצבועים. ",
    "description_arabic": "هذا التطبيق يمكن التلاميذ ان يفهموا للعمق وبشكل بصري وحركي موضوع التوسيع والتقليص للكسور. موديل الكسور المرافق التطبيق هو مستطيل مقسم الى مستطيلات متساوية.   بعصها ملون.  كذلك استخدام التطبيق يقوي لدى التلاميذ فهم مصطلح الكسر ـ  مقام الكسر هو عدد الاقسام الذي فسم المستطيل له، بينما البسط هو عدد الأفسام الملونة.   ",
    "description_eng": "Visualizing fraction equivalence can be a challenge for students. To many, ‘multiplying up and down by 2’ means doubling the fraction value.  Using circular and bar models, this app helps students see with their eyes and mind that equivalent fractions are truly equal. This is a powerful springboard to the formal equivalence algorithm.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 58,
    "lesson_id": "Whole_to_fraction",
    "app": "ממספר שלם או מעורב לשבר",
    "app_type": 0,
    "app_arabic": "تحويل العدد الصحيح أو المخلوط الى كسر",
    "app_eng": "From a Whole or Mixed Number to a Fraction",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "נושאים נוספים בשברים",
    "classes": "34",
    "youtube": "lqPhUO3MOrs",
    "youtube_eng": "bVU4CJdNdy8",
    "plus": "0",
    "search_words": "שברים",
    "search_words_eng": "fractions",
    "interaction": "",
    "student_page": "",
    "description": "יישומון זה מיועד לתלמידים בכיתות ג-ד, ובמרכזו שאלות כמו כמה רבעים יש בשני שלמים? או כמה שלישים יש בחמישה שלמים? במקרה של השאלה הראשונה, למשל, יופיעו על המסך 2 עיגולים שלמים, ואז לחיצה על אחד מכפתורי היישומון תגרום ל-\"היפרדות\" רגעית של כל עיגול שלם לארבעה רבעים ואז לחזרה למצב המקורי. כך יתקבל שמספר הרבעים הוא 5×4. התמונה הדינמית אותה מספר היישומון עשוייה להיחקק במוחם של התלמידים, מה שיסייע להם להתמודד בהמשך עם שאלות מורכבות יותר, המכילות בתוכן גם את הידע \"החקוק\" הזה. ",
    "description_arabic": "هذا التطبيق معد لتلاميذ الصف الثالث والرابع، وفي مركزه اسئلة مثل كم ربعا يوجد في عددين صحيحين؟  أو كم تلثا يوجد في 5 اعداد صحيحة؟  في حالة السؤال الأول، مثلا، يظهر على الشاشة 2 دوائر كاملة، وعند الصغط على اجد ازراار التطبيق يؤدي الى \" انفصال\" مؤقت لكل دائرة كاملة لأربعة وعندها يعود الى حالتة الأصلية.  هكذا ينتج عدد الأرباع وهو 4×5.  الصورة المتحركة هو عدد التطبيق الذي قد يصمد في ذهن التلاميذ، الذي سيساعدهم في مواجهة اسئلة لاحقا مركبة اكثر، التي تحتوي بداخلها ايضا المعرفة \" القانون\" هذا.   ",
    "description_eng": "This is a specialized app for questions such as ‘How many quarters are there in two wholes?’ or ‘How many thirds are there in three and a third?’.  The app offers a simple animation of circles being divided into the appropriate fraction. The animation will imprint an image in the students’ imagination that will help them understand these basic questions as a stepping stone for more complex topics.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 60,
    "lesson_id": "Cube_builder",
    "app": "מבנים מקוביות ",
    "app_type": 0,
    "app_arabic": "ابنية من مكعبات ",
    "app_eng": "Building with Cubes",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "תלת ממד",
    "classes": "2467",
    "youtube": "6x-k9PYX3Z8",
    "youtube_eng": "Omk2TxCWAB8",
    "plus": "0",
    "search_words": "נפח, שטח פנים, תיבות, תלת מימד, תלת מימדי",
    "search_words_eng": "volume, surface area, boxes, 3D, 3D",
    "interaction": "",
    "student_page": "",
    "description": "יישומון זה מאפשר לבנות בצורה נוחה מבנים המורכבים מקוביות. לאחר שנבנו המבנים ניתן לפרק אותם לשכבות בדרכים שונות וכך לגלות את נפחיהם, ולהוציא את ההיטלים שלהם, וכך לגלות את שטח הפנים שלהם. ",
    "description_arabic": "هذا التطبيق يمكن من بناء سهل مباني مركبة من مكعبات.י بعد تركيب المباني يمكن فصلها الى طيفات بطرق مختلفة وهكذا يمكن اكتشاف حجمها،    واخراج الأثاث منها، وهكذا يمكن اكتشاف مساحة السطح الداخلي لها. ו ",
    "description_eng": "This app enables the quick construction of structures made out of cubes. The structures can be split into layers so as to make the discovery of their volume intuitive. The app also has a unique tool to illustrate the calculation of the surface area. ",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 61,
    "lesson_id": "Prisa2",
    "app": "גופים ופריסות",
    "app_type": 0,
    "app_arabic": "مجسمات وانتشارات",
    "app_eng": "Solid Figures",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "תלת ממד",
    "classes": "6",
    "youtube": "gQLc2ShyScY",
    "youtube_eng": "bPsGQs_NZno",
    "plus": "0",
    "search_words": "פריסה, תיבה, קובייה, כדורגל, מיקצוע, פאה, פאות, פריסות גליל חרוט גופים תלת ממד גופים משוכללים פיאונים אפלטון פאונים",
    "search_words_eng": "layout, box, cube, soccer, profession, side, sides, layouts nets",
    "interaction": "",
    "student_page": "",
    "description": "יישומון זה יאפשר לכם להדגים פריסות של קוביות, תיבות, פירמידות, מנסרות, גלילים, חרוטים וכן צורות מורכבות יותר.",
    "description_arabic": "هذا التطبيق يمكن من غرض تخطيطات للمكعبات، صناديق، أهرام، اسطوانات، مخاريط وكذلك أشكال مركبة أكثر.  ",
    "description_eng": "This app displays a wide range of solid figures that can be examined and manipulated: Cubes, Pyramids, Prisms, Cylinders, Cones etc. The app is especially suited for working with nets of shapes and enables the “folding” the nets. The app allows the students to attempt to create nets made out of squares. This can help the students try to discover how many different nets of cubes exist.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 10,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 62,
    "lesson_id": "Ninty_degress",
    "app": "הזווית הישרה",
    "app_type": 0,
    "app_arabic": " الزاوية المستقيمة  ",
    "app_eng": "The Right Angle",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "זוויות",
    "classes": "3",
    "youtube": "jBZBk08v1y8",
    "youtube_eng": "pLabhvGBu3M",
    "plus": "0",
    "search_words": "זוויות, תשעים מעלות זויות זויות",
    "search_words_eng": "angles, ninety degrees, right angle",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידים בכיתה ג, והוא מאפשר להכניס אל המסך ציורים ולבדוק האם והיכן יש בהם זווית ישרה. בדרך זו יבינו התלמידים גם בהי זווית חדה ומהי זווית קהה.",
    "description_arabic": "هذا التطبيق معد لتلاميذ الصف الثالث، وهو يمكن من ادخال للشاشة رسمومات وفحص اذا  واين يوجد بها زاوية مستفيمة، بهذه الطريقة يفهم التلاميذ ايضا ما هي الزاوية الحادة وما هي الزاوية المنفرجة. ",
    "description_eng": "This app allows us to bring pictures onto the screen and search for right angles. This will help explain right angles, sharp angles and obtuse angles. ",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 67,
    "lesson_id": "Circumference2",
    "app": "היקפים ",
    "app_type": 0,
    "app_arabic": "محيطات  ",
    "app_eng": "Perimeter",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "היקפים",
    "classes": "2345",
    "youtube": "i2wDwPBxeZU",
    "youtube_eng": "E74aISlk2Sc",
    "plus": "0",
    "search_words": "שטחים צב",
    "search_words_eng": "territories, areas, scopes",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידי כיתות ב-ו, והוא מאפשר להציג ולתרגל בצורה חזותית דינאמית את הנושא היקף של מצולע. ההיקף מחושב ביישומון באמצעות נקודה שנעה עליו. הדינמיות הזאת מקטינה את מספר הטעויות שחלק מן התלמידים עושים בתרגילי חישוב היקפים. באופן טבעי משתלב כאן גם הנושא שטח של מצולע. רבים מן התלמידים סבורים בטעות ששטח גדול יותר של מצולע פירושו גם היקף גדול יותר. היישומון מסייע לטפל בכשל הזה.",
    "description_arabic": "التطبيق معد لتلاميذ الصفوف الثاني حتى السادس، وهو يمكن  ان يعرض بشكل بصري حركي موصوع محيط المضلع.   المحيط يحسب بواسطة نقطة تتحرك عليه.  الحركة هذه تقلل عدد الأخطاء التي يخطئون فيها التلاميذ في تمارين حساب المحيطات.   بشكل طبيعي يندمج هنا ايضا موضوع سطج المضلع.   كثير من التلاميذ يعتقدون خطأ ان مساحة المضلع اكبر معناه أن محيط المضلع اكبر, التطبيق يساعد في علاج هذا الخطأ.   ",
    "description_eng": "This application provides an animated graphic and an ability to easily create practice exercises on calculating the perimeter of a polygon. The application presents the concept by means of a turtle that moves along the length of the perimeter. The animation helps correct the common misperceptions such as that a polygon with a larger area must have a longer perimeter.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 69,
    "lesson_id": "Triangle_area",
    "app": "שטח משולש",
    "app_type": 0,
    "app_arabic": "مساحة المثلث  ",
    "app_eng": "Triangle Area",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "שטח משולש",
    "classes": "56",
    "youtube": "1QHHSduB-b0",
    "youtube_eng": "yC5LO_Vbe6g",
    "plus": "0",
    "search_words": "שטחים, גובה",
    "search_words_eng": "areas, height, scope",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידי כיתה ו', ובאמצעותו אפשר להגיע בצורה דינאמית לנוסחה של שטח משולש, כולל שטח של משולש קהה זווית. ",
    "description_arabic": "التطبيق معد لتلاميذ الصف السادس، بواسطتة يمكن التوصل بشكل حركي الة قانون مساحة المثلث، يشمل مساحة مثلث منفرج الزاوية.  ",
    "description_eng": "This app is intended to lead students to the understanding of the formula for the area of a triangle in an intuitive way. ",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 70,
    "lesson_id": "Circle_area_by_squares",
    "app": "חישוב שטח עיגול בעזרת צלוחיות",
    "app_type": 0,
    "app_arabic": "حساب مساحة دائرة بمساعد صحن  ",
    "app_eng": "Calculate the Area of a Circle with Squares",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "מעגל",
    "classes": "67",
    "youtube": "ijyInolQpiY",
    "youtube_eng": "3EZPBaozWUw",
    "plus": "0",
    "search_words": "שטח עיגול",
    "search_words_eng": "circle",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר לתלמידים לגלות באופן ויזואלי ששטח עיגול שווה לקצת יותר משלוש פעמים רדיוסו בריבוע. המורה יגלה לתלמידים שהמספר המדוייק הזה הוא פאי. על הלוח מופיע עיגול ומופיעים גם ריבועים שאורך צלעם הוא רדיוס העיגול (שטחם רדיוס בריבוע). התלמידים יחלקו את העיגול לחתיכות, וימלאו באמצעותם את הריבועים המצויירים. הם יגלו ששטח המעגל \"ממלא\" קצת יותר משלושה ריבועים.",
    "description_arabic": "التطبيق يمكن التلاميذ اكتشاف بشكل مرئي أن مساحة الدائرة تساوي اكثر بقليل ثلاث مرات قطره في المربع.  المعلم يكشف للتلاميذ ان العدد الدقيق هو ط. على اللوخ يظهر دائرة ومربعات طول ضلعها قطر الدائرة (مساحتهم نصف قطر ترببيع).  التلاميذ يقسمون الدائرة الة قطع، وبملأون المربعات الملونة، سيكنشغون أنن مساحة الدائرة \"تملأ\" اكثر بفليل من ثلاثة مربعات.    ",
    "description_eng": "This app allows the students discover visually that the area of a circle is equal to a little more than three times its radius squared. The teacher will then explain that actual number is Pi. On the screen there will be a circle and squares with a side the length the radius of the circle (so their area is this radius squared). The circle will be cut into pieces so that the students can use them to fill the squares. This is how they will find out that the circle parts fill a little more than three squares.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 71,
    "lesson_id": "Calculating_circle_area_with_rectangle",
    "app": "חישוב שטח עיגול בעזרת מלבן",
    "app_type": 0,
    "app_arabic": "حساب مساحة الدائرة بواسطة مستطيل  ",
    "app_eng": "Calculating the Area of a Circle with a Rectangle",
    "display_app_eng": "false",
    "top_topic": "גיאומטרייה",
    "topic": "מעגל",
    "classes": "67",
    "youtube": "KXPou7LPRIw",
    "youtube_eng": "jqSeGxXAkfc",
    "plus": "0",
    "search_words": "שטח עיגול",
    "search_words_eng": "circle",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר למורה להוליך את תלמידיו להוכחה מעניינת לחישוב שטח של עיגול. ההוכחה מתבצעת בשלבים – בכל שלב נחתך העיגול למספר חלקים גדול יותר מזה שהיה בשלב הקודם. כל הגזירים מודבקים זה לזה ליצירת צורה ההולכת ומתקרבת למלבן. החל משלב מסויים העין אינה מבדילה בין הצורה המודבקת לבין מלבן. ההבדלים הם כה קטנים עד שניתן לומר ששטח המלבן כמעט שווה לשטח העיגול. החישוב מוליך לנוסחת שטח העיגול המוכרת לנו – פאי כפול הרדיוס בריבוע.",
    "description_arabic": "التطبيق يمكن المعلم أن يتوصل مع التلاميذ الى اثبات لحساب الدائرة. الاثبات يتم على مراحل ـ  في كل مرحلة يتم قطع الدائرة الى عدد أقسام اكثر من المرحلة السابقة،   كل القطع الملصفة على بعضها لانتاج شكل يقترب الى مستتطيل،  ابتداء من مرحلة العين لا تميز بين الشكل الملصق وبين المستطيل. الاختلافات هي ضئيلة ختى يمكن القول أن مساحة المستطيل تقريبا تساوي مساحة الدائرة.  الحساب يؤدي الى قانون مساحة الدائرة المعروف لنا ـ ط ضرب نصف القطر تربيع.   ",
    "description_eng": "",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 72,
    "lesson_id": "Nail_grid_page",
    "app": "לוח מסמרים",
    "app_type": 0,
    "app_arabic": "لوحة المسامير  ",
    "app_eng": "Building with Grid Boards",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "יישומונים כלליים לאיורים גיאומטריים",
    "classes": "1234567",
    "youtube": "8uW-k_dvAwY",
    "youtube_eng": "fjiuItNDCB4",
    "plus": "0",
    "search_words": "שטחים, היקף, היקפים, משולש, מצולעים, זווית, זוויות, מרובעים, חפיפה, חפיפת משולשים, מקבילית, ריבוע, טרפז, מעוין, קעור, קמור",
    "search_words_eng": "areas, circumference, circumference, perimeter, perimeters triangle, polygons, angle, angles, squares, overlap, triangle overlap, parallel, square, trapeze, rhombus, concave, convex",
    "interaction": "",
    "student_page": "",
    "description": "לוח מסמרים הוא כלי חשוב בלימודי הגאומטרייה בבית הספר היסודי. היישומון שלפנינו מאפשר לבצע פעולות רבות ומגוונות על לוח המסמרים (לוח שריג), שחלק נכבד מהן אי אפשר לבצע על לוח מסמרים פיסי. אפשר לשרטט קטעים, ליצור שטחים צבועים, לגרור את הקטעים והשטחים אל מחוץ ללוח, לסובב ולהפוך אותם ועוד. בעזרת היישומון אפשר ללמד כמעט כל נושא בגאומטרייה של בית הספר היסודי: שטחים, היקפים, סימטרייה, חפיפת משולשים, תכונות של מרובעים, זוויות ועוד.      ",
    "description_arabic": "لوح المسامير هو اداة حساب في تعليم المثلثات في المدرسة الأبتدائية، التطبيق الذي امامنا يمكن من تنفيذ فعاليات كثيرة ومتنوعة على لوح المسامير (لوح نسيج)، بعضها لا يمكن تنفيذها على لوح المسامير طبيعي. يمكن رسم قطع، انتاج مساحات رسم، جر قطع الى خارج اللوح، تدويرها وقلبها، بمساعدة التطبيق يمكن تعليم كل المثلثات للمرسة الأبتدائية: مساحات، محيطات، تشابه، تشابه مثلثات، صفات مربعات، زوايا وغيرها.       ",
    "description_eng": "The Grid board is an important tool in the teaching of Geometry in elementary school. This versatile app enables a variety of operations on the grid board, many of which cannot be applied to a physical grid board. The app can assist in teaching the topics of area, perimeters, symmetry, properties of quadrilaterals and many more.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 78,
    "lesson_id": "Submarine_challenge",
    "app": "צוללות",
    "app_type": 0,
    "app_arabic": "غواصات  ",
    "app_eng": "Submarine Game",
    "display_app_eng": "false",
    "top_topic": "גיאומטרייה",
    "topic": "זיהוי צורות מוסתרות",
    "classes": "2345",
    "youtube": "QR6iBmrRg70",
    "youtube_eng": "cg9lW50Zv3w",
    "plus": "0",
    "search_words": "משחק",
    "search_words_eng": "game",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר למורה לבנות שיעור שהוא משחק בגאומטרייה המשוחק בכיתה. הנושאים העיקריים שאפשר לשלב במשחק הם היקף, שטח וסימטרייה של מצולעים המורכבים מריבועים. על לוח הריבועים המורה בונה שני מצולעים בעלי תכונה משותפת, למשל, מצולעים בעלי שטח של 4 יחידות רבועות. היישומון מאפשר למורה ליצור כיסוי ללוח כך שהתלמידים אינם רואים את שני המצולעים. הכיתה מחולקת לשתי קבוצות. מצולע בעל צבע אחד שייך לקבוצה א', ואילו מצולע בעל צבע אחר שייך לקבוצה ב'. כל קבוצה בתורה מבקשת מן המורה לחשוף את אחת ממשבצות הלוח. הקבוצה המנצחת היא הקבוצה החושפת ראשונה את המצולע שלה. למשחק חוקים נוספים, והם מוסברים בסרטון.   ",
    "description_arabic": "التطبيق مناسب لاستخدام الصفوف الثالث حتى السادس،  وهو يشكل فعلا لعبة صفية،   معلم الصف يخفي مضلعا على لوح النسيج،    التلاميذ يحاولون أن يكتشفوا رؤوس المضلع بعدد قليل من المحاولات،  في كل محاولة يختارون نقطة على لوح النسيج والتطبيق يكشف لهم اذا كانت هذه النقطة هي رأس المصلع، على ضلع المضلع، داخل المضلع او خارجه. ",
    "description_eng": "",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 82,
    "lesson_id": "Symmetry",
    "app": "סימטרייה",
    "app_type": 0,
    "app_arabic": "تماثل  ",
    "app_eng": "Symmetry",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "מגוון נושאים בגיאומטרייה",
    "classes": "1234",
    "youtube": "ouVduFm0i1M",
    "youtube_eng": "2MFhlEaQgEo",
    "plus": "0",
    "search_words": "ציר, קו, סיבובית, קווית",
    "search_words_eng": "axis, line, rotary, linear",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מתאים לתלמידי כיתות א-ה הלומדים את  נושא הסימטרייה. היישומון מאפשר להדגים בצורה חזותית נושאים כמו סימטרייה שיקופית (ציר סימטרייה) וסימטריה סיבובית. היישומון מאפשר להציג את הנושאים האלה במגוון רחב של צורות.",
    "description_arabic": "هذا التطبيق يشكل لعبة للاعبين في موضوع نسب الاحتواء في عائلة المربعات. استخدام التطبيق يجب أن يتم بعد أن يتعلم التلاميذ في الصف نسب احتواء في محموعة متوازي المتطيلات ـ    مثلا: مربع هو ايضا مستطيل، وايضا معين وكذلك متوازي مستطييل أو \" معين هو ايضا مستطيل\".  كما يوجد حاجة للتحدث عن نسب الأحتواء في مجموعات أخرى بسيطة أكثر  ـ شبه المنحرف (مشروح في الشريط).  كل زوج تلاميذ يجلسون بجانب الحاسوب، عندما كل تلميذ يرمي مكعبا.    حسب النتيجة التي تنتج يعرف أي نوع مربع عليه تلوينه من مجموعة مربعات كثيرة.  اذا مثلا حصل التلميذ على متوازي مستطيل، هو يستطيع تلوين متوازي اضلاع، لكن ايضا مستطيل، معين أو مربع، وهي ايضا مستطيلات. للعبة قوانين أخرى تحوله الى مثير.  ",
    "description_eng": "The symmetry app lets the teacher display, using visual animations, such concepts as reflectional symmetry and rotational symmetry. The app allows the folding of a shape on any line so as to check if it is indeed a symmetry line or not.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 83,
    "lesson_id": "Height_of_triangle",
    "app": "גובה במשולש",
    "app_type": 0,
    "app_arabic": "ارتفاع المثلث   ",
    "app_eng": "Height of a Triangle",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "מגוון נושאים בגיאומטרייה",
    "classes": "57",
    "youtube": "QWZOYCDYFyM",
    "youtube_eng": "_rxuWiUpDXM",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר להסביר בצורה פשוטה ונוחה כיצד מורידים גובה במשולש. מכניסים אל המסך משולש כשכל צלע שלו מופיעה בצבע של הקדקוד שמולה. בוחרים את הצלע שמעוניינים להוריד אליה גובה, מסובבים את המשולש לפי הצורך ומבקשים מן המחשב להוריד את הגובה המופיע אף הוא בצבע של הצלע והקדקוד. אפשר להמשיך ולהוריד גם את שני הגבהים האחרים. כעת אפשר לשנות את המשולש כך שיהיה ישר זווית או קהה זווית, לשרטט בעצמנו את הגבהים ולבדוק אם הם זהים לגבהים שהמחשב מצייר.",
    "description_arabic": "التطبيق ملائم لتلاميذ الصفوف الأول حتى الصف السادس،  الذين يتعلمون موضوع التماثل،   التطبيق يمكن من عرص بصريا مواضيع مثل تماثل شفاف (محور التماثل)  وتماثل دوران.  التطبيق يمكن من عرض هذه المواضيع بأشكال عديدة.  ",
    "description_eng": "This app enables a simple and convenient explanation of how to draw the height of a triangle. If the height falls outside the triangle, the app displays an extension of the side that intersects with the height. The app allows the students to try and draw the height and then check if they did so correctly.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 85,
    "lesson_id": "Paralel",
    "app": "ישרים מקבילים ומאונכים",
    "app_type": 0,
    "app_arabic": "مستقيمات متوازية ومتعامدة  ",
    "app_eng": "Parallel and Perpendicular Lines",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "מגוון נושאים בגיאומטרייה",
    "classes": "34",
    "youtube": "zC5v2JbHr4g",
    "youtube_eng": "EPo-BA3yHYI",
    "plus": "0",
    "search_words": "תשעים מעלות ישר קו קווים מקביל ",
    "search_words_eng": "ninety degrees, right angle",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידים החל מכיתה ג', והוא עוסק בזיהוי של ישרים מקבילים ומאונכים. על המסך מופיע קו (ראשון) ונקודות. יש לגלות שתי נקודות שאם מחברים אותן מתקבל קו המקביל או המאונך לקו הראשון.",
    "description_arabic": "التطبيق ملائم لتلاميذ الصفين الخامس والسادس،  وهو يمكن التعلم بصورة سهلة ، بصرية وحركية موضوع الارتفاع في متوازي الأضلاع،   التعريف الذي سنستخدمه هو \"ارتفاع في متوازي الأضلاع هو يحرج من ضلع واحدة في متوازي الأضلاع، وهو ينتهي على الضلع المقابل او على استمرارها عندما يتعامد معها.  ",
    "description_eng": "This app deals with identifying parallel and perpendicular lines. The app shows a line on one side and a group of points on the other. The task of the students is to connect two points that create a line that is parallel or perpendicular to the one shown.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 86,
    "lesson_id": "Measurement",
    "app": "מדידות עם סרגל",
    "app_type": 0,
    "app_arabic": "قياس بواسطة مسطرة  ",
    "app_eng": "Measurements and More",
    "display_app_eng": "false",
    "top_topic": "גיאומטרייה",
    "topic": "מגוון נושאים בגיאומטרייה",
    "classes": "123",
    "youtube": "y-me2Ws-JXU",
    "youtube_eng": "FYKqKSfNkFc",
    "plus": "0",
    "search_words": "אורך, סנטימטר, דצימטר יחידות מידות המרה עשרוניות",
    "search_words_eng": "length, cm, centimeter, decimeter",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר למדוד אורכים של עפרונות (או של כל תמונה שיעלה המשתמש). הסרגל הוא למעשה מקטע של ישר המספרים ויש אפשרות להציג אותו כשהשנתות הן רק סנטימטרים שלמים, או חצי סנטימטרים או עשיריות הסנטימטר (מילימטרים). מתוך כך אפשר ללמד כאן גם מעט על שברים.",
    "description_arabic": "التطبيق معد لتلاميذ من الصف الثالث، وهو يتناول بتشخيص مستقيمات متوازية ومتعامدة.  على الشاشة يظهر خط (اولي) نقاط.   يجب اكتشاف نقطتين اذا اوضلناهما نحصل على خط يتوازى أو يتعامد للخط الاول.   ",
    "description_eng": "",
    "flash": "",
    "category": "הכנה מראש",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 88,
    "lesson_id": "Axis2",
    "app": "ישר המספרים",
    "app_type": 0,
    "app_arabic": "محور الأعداد  ",
    "app_eng": "Axis",
    "display_app_eng": "true",
    "top_topic": "ציר",
    "topic": "ציר",
    "classes": "1234567",
    "youtube": "Tj7LfgQKRJY",
    "youtube_eng": "YrKeR-ViL64",
    "plus": "0",
    "search_words": "שנתות עשרוני שבר שליליים ציר המספרים",
    "search_words_eng": "line, linear, tick marks",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מאפשר לבנות כמעט כל שאלה מספר הלימוד שמופיע בה ציר המספרים, ולהציג אותה לתלמידים. העבודה כאן נוחה ואינטראקטיבית.",
    "description_arabic": "التطبيق يمكن أن يؤدي التلاميذ لأثبات قانون فيتاغورس بدون استخدام التلاعب الجبري.    ",
    "description_eng": "This versatile app enables the creation of a wide variety of questions that involve the use of the Number Axis. It can present whole numbers, fractions, decimal numbers as well as very large numbers.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 98,
    "lesson_id": "Boxes_and_numbers",
    "app": "על מספרים וקופסאות",
    "app_type": 0,
    "app_arabic": "عن الأعداد والصناديق ",
    "app_eng": "Numbers and Boxes: Pre-Algebra",
    "display_app_eng": "true",
    "top_topic": "אלגברה",
    "topic": "אלגברה",
    "classes": "1234567",
    "youtube": "Y5HSiUw44UU",
    "youtube_eng": "DuNfIW0SzDU",
    "plus": "0",
    "search_words": "שבר, אלגברה, חוקיות, חיבור, כפל",
    "search_words_eng": "fraction, algebra, legality, connection, multiplication",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידי כיתה ה ו- ו', והוא מהווה הכנה לאלגברה. הנה כך המורה מכין שאלה לדוגמה: מכניסים את מסך היישומון שתי קופסאות, ולכל קופסה מכניסים מספרים לפי בחירה. מעל לכל קופסה מופיע סכום המספרים שבתוכה. כעת המורה יכול לכסות את המספרים במלבנים צבעוניים כך שמספרים שווים מכוסים במלבנים שווי צבע. כעת המורה מציג את המסך לתלמידיו, ועליהם לגלות את ערכי המספרים המתאימים לכל צבע של מלבן. התלמידים \"ייאלצו\" להפעיל כאן שיקולים המבוססים על אלגברה.",
    "description_arabic": "التطبيق معد لتلاميذ الصفين الخامس والسادس، وهو عبارة عن اعداد للجبر.  هكذا يعد المعلم سؤال: ندخل الى شاشة التطبيق صندوقين، ولكل صندوق ندخل ارقاما حسب الاختيار.  فوق كل صندوق يظهر مجموع الأعداد التي بداخله.   الآن يغطي المعلم الأعداد  بمستطيلات ملونة حيث كل عددين متساويين مكسوان بمستطيلات بنفس اللون.  الآن يعرض المعلم لتلاميذه، وعليهم اكتشاف الأعداد الملائمة لكل لون مستطيل,   سيضطر التلاميذ تشغيل اعتبارات تعتمد على الجبر. ",
    "description_eng": "This app is designed to develop algebraic thinking. Numbers can be brought onto the screen and then it is easy to cover identical numbers with the same shape. The hidden or exposed numbers can be placed in boxes that show their total sum. In this way each box forms an \"equation\". It is easy to create a wide range of questions with this app – including questions that involve fractions.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 111,
    "lesson_id": "Mountain_area_game",
    "app": "משחק שטח ההר",
    "app_type": 1,
    "app_arabic": "لعبة مساحة الجبل  ",
    "app_eng": "Mountain Area Game",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "שטח משולש",
    "classes": "5",
    "youtube": "hide",
    "youtube_eng": "_cf9TKwdpOY",
    "plus": "0",
    "search_words": "משולש ישר זווית",
    "search_words_eng": "right angled triangle",
    "interaction": "",
    "student_page": "",
    "description": "היישומון הוא משחק המיועד לשני שחקנים, והוא עוסק בחישוב השטח של משולש ישר זווית. המשחק משוחק בזוגות במחשב – לכל שחקן יש לוח משובץ, והוא מוסיף בכל תור קטע נוסף של ההר שלו. המנצח הוא השחקן שיצר הר בעל שטח גדול יותר מחברו. מעבר למשחק של שני השחקנים יש כאן אתגר אישי – מי יצליח להגיע להר שהשטח שלו גדול מ- 100 יחידות שטח.",
    "description_arabic": "التطبيق هو لعبة معد لللاعبين، وخو يتناول بايجاد مساحة مثلث مستتقيم الزاوية. اللعبة تتم بازواج في الحاسوب بازواج ـ لكل لاعب يوجد لوح مدمج، وهو يضيف كل مرة قطعة للجبل خاصته. القائز هو اللاعب الذي ينتج مساحة أكير من زميله. بين اللاعبين يوجد تحد شخصي ـ من ينجح اولا أن يصل يكون المساحة اكبر من 100 وحدة مساحة.  .",
    "description_eng": "This app is a two-player game that deals with a calculation of the area of a right triangle. Students will not have to be given a formula for this calculation, but will reach a solution intuitively. Each player has a checkered board. During each turn, players add a segment to their 'mountains'. The player whose mountain has the larger area wins. It is the calculation of these mountains that will necessitate the calculation of right triangles. ",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 112,
    "lesson_id": "Circle_circumference",
    "app": "היקף מעגל",
    "app_type": 0,
    "app_arabic": "محيط الدائرة",
    "app_eng": "Circle Circumference",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "מעגל",
    "classes": "67",
    "youtube": "hide",
    "youtube_eng": "O4v3VXRWcOs",
    "plus": "0",
    "search_words": "עיגול",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לתלמידי כיתה ו, והוא מאפשר להם לגלות בצורה חזותית את הנוסחה להיקף המעגל. קוטר המעגל נכנס בהיקפו בערך 3.14 פעמים. המספר המדויק של פעמים מסומן ב- P, ולכן נוסחת היקף המעגל היא P×d, d הוא קוטר המעגל.",
    "description_arabic": "التطبيق معد لتلاميذ الصف السادس، وهو يمكنهم من اكتشاف بصريا قاونون محيط الدائرة، قطر الدائرة يدخل كله بقيمة 3.14 مرة. العدد الدقيق للمراة يشار اليه بـ ط، ولذلك قانون محيط الدائرة هو م، ط×ق هو قطر الدائرة.  ",
    "description_eng": "This app uses a visual animation to illustrate the formula for circumference. The app is especially built to facilitate a class discussion before reaching the final answer. The circle’s diameter fits along its circumference approximately 3.14 times. Pi denotes the exact number of times.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 20,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 113,
    "lesson_id": "Adding_remainders",
    "app": "חלוקה עם שארית במודל ההכלה",
    "app_type": 0,
    "app_arabic": "قسمة مع باقي  ",
    "app_eng": "Remainders in the Snake Model",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "חילוק בשלמים",
    "classes": "34",
    "youtube": "7-D1NP9CXNI",
    "youtube_eng": "FzG0AjlGMwk",
    "plus": "0",
    "search_words": "ציר  ישר מספרים חילוק להכלה שארית שאריות",
    "search_words_eng": "numbers axis, line of numbers divide for containment, line, linear",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ג-ד, והוא מאפשר להוביל את התלמידים בצורה נוחה להבנת רעיון השארית. בין היתר ניתן לראות כאן את השאריות השונות האפשריות בחלוקה למספר כלשהו. לדוגמה, בחלוקה ל-5 יש 5 אפשרויות שונות: 0,1,2,3,4.",
    "description_arabic": "التطبيق معد للتعليم في الصفين الثالث والرابع، ويحعل التلاميذ ان يفهموا بشكل مريح فكرة الباقي، يمكن رؤية هنا اليواقي المختلفة الممكنة في قسمة عدد معين، في قسمة 5 على 5 هناك امكانيات مختلفة:   0,1,2,3,4.",
    "description_eng": "This app is designed to lead the students to understand the concept of the remainder in an intuitive way. Among other things it will help make clear why dividing by 5 can leave 5 different remainders: 0,1,2,3,4. The app can help explain prime numbers and measurement division as well.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 114,
    "lesson_id": "Add_fractions",
    "app": "חיבור וחיסור שברים פשוטים",
    "app_type": 0,
    "app_arabic": "جمع كسور بسيطة  ",
    "app_eng": "Fraction Addition",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "45",
    "youtube": "0do2I0wl2xA",
    "youtube_eng": "om4THMbLuHQ",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ג-ו, והוא מאפשר להוביל את התלמידים בדרך נוחה מאוד להבנת הנושא של חיבור שברים פשוטים בעזרת מודל העיגולים. נראה כאן שברים עם מכנים שווים, מוכלים וזרים.",
    "description_arabic": "التطبيق معد للاستخدام في الصفين الثالث والرابع، وهو يمرن على جدول الضرب وتسلسل العمليات الحسابية. الحاسوب يفرز بازل، في كل واحد منها على التلاميذ بناء تمرين ملائم للنتيجة المعطاة. التلاميذ يستطيعون حل البازل في حاسوبهم الشخصي أو مطبوعا.  ",
    "description_eng": "This application provides simple circle diagrams to convey concepts of addition of fractions with equal denominators, denominators where one is divisible by the other, and denominators without common multiples.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 115,
    "lesson_id": "Pyramid_route",
    "app": "משחק לוח הכפל וסדר פעולות החשבון",
    "app_type": 2,
    "app_arabic": "لعبة جدول الضرب والعمليات الحسابية  ",
    "app_eng": "Multiplication Game and Order of Operations",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "34",
    "youtube": "VRhNfr_r-ik",
    "youtube_eng": "dKCrTZIWiO8",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לשימוש בכיתות ג-ד, והוא מתרגל את לוח הכפל ואת סדר פעולות החשבון. המחשב מגריל פאזלים, שבכל אחד מהם יהיה על התלמידים לבנות תרגיל המתאים לתוצאה נתונה. התלמידים יוכלו לפתור את הפאזלים במחשביהם האישיים או על גבי דף מודפס.",
    "description_arabic": "هذا التطبيق يمكن من عرض اعدادا بشكل مرئي يبرز المبنى العشري لها. عشر وحدات، كل واحدة منها ممثلة بواسطة مربع، تتحول الى عشرة الذي هو مستطيل، وعشرة عشرات تتخول مستطيل أكبر الذي هو الف واحد. بالاضافة لذلك يمكن توضيح ببشكل واضح وحركي جمع، طرح وضرب حتى 9999 وغيرها. ",
    "description_eng": "This practice app is designed to develop proficiency in the order of operations as well as to practice the multiplication tables. The app produces random puzzles along with a target number. The student must create a path that runs through numbers and operations in the puzzle to create an expression with a result that matches the target number.",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 10,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 116,
    "lesson_id": "Decimal_structure_2d2",
    "app": "המבנה העשרוני",
    "app_type": 0,
    "app_arabic": "المبنى العشري  ",
    "app_eng": "Decimal Structure",
    "display_app_eng": "true",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "123",
    "youtube": "22eII2_OklQ",
    "youtube_eng": "J2c1MkMSevo",
    "plus": "0",
    "search_words": "מבנה, עשרוני, עשר, חיבור, דו ספרתי, חד ספרתי",
    "search_words_eng": "structure, decimal, ten, addition double-digit, single-digit",
    "interaction": "",
    "student_page": "",
    "description": "יישומון זה מאפשר להציג מספרים בצורה ויזואלית המבליטה את המבנה העשרוני שלהם. עשר יחידות, שכל אחת מיוצגת על ידי ריבוע, הופכות לעשרת אחת שהיא מלבן, ועשר עשרות הופכות למאה אחת שהיא ריבוע גדול, ועשר מאות הופכות למלבן גדול שהוא אלף אחד. בנוסף אפשר להמחיש כאן בצורה ברורה ודינאמית חיבור, חיסור וכפל עד 9999 ועוד ועוד.",
    "description_arabic": "التطبيق معد للتعليم في الصفين الثالث والرابع، وهو سهل لتعليم المساحات في المثلثات وعرض زويا مضلعات. يمكن ايضا رسم مصلعات، قصها وتوصيل القطع لتكوين شكل جديد مساحتها نحن نعرفه. عنا مثلا يمكن أن \"نحول\" بسهولة متوازي اضلاع الى مستطيل والحصول على قانون حساب مساحته. كما أنه يمكن رسم مثلثات مختلفة، وعرض زاواياها وأن نبين ان مجموعها 180 درجة. ",
    "description_eng": "This app represents numbers in a way that lays emphasis on the decimal structure. Each unit is represented as a small square. Tens are represented by a 1X10 rectangle. Hundreds are represented as a square with the dimensions of 10x10 units and so on. The app enables the numbers to be deconstructed into their components and reconstructed easily. This makes it easy to demonstrate addition, subtraction and multiplication.",
    "flash": "",
    "category": "המחשה",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 117,
    "lesson_id": "Polycut",
    "app": "חיתוך מצולעים ומדדית זוויות",
    "app_type": 0,
    "app_arabic": "قص مضلعات وقياس زوايا",
    "app_eng": "Cutting Polygons",
    "display_app_eng": "false",
    "top_topic": "גיאומטרייה",
    "topic": "יישומונים כלליים לאיורים גיאומטריים",
    "classes": "34567",
    "youtube": "0Okij8N0z6Q",
    "youtube_eng": "Gn4Aaw27-FY",
    "plus": "0",
    "search_words": "חיתוך, מצולע, מקבילית, מרובע, מלבן, טרפז, שטח, זווית, זוויות זויות",
    "search_words_eng": "cutting, polygon, parallel, square, rectangle, trapezoidal, area, angle, angles",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ג-ז, והוא נוח ללימוד שטחים בגיאומטריה ולהצגת זוויות של מצולעים. אפשר לשרטט כאן מצולעים, לחתוך אותם ולחבר את החתיכות ליצירת צורה חדשה שאת שטחה אנו יודעים לחשב. כך ניתן למשל \"להפוך\" בקלות מקבילית למלבן ולקבל את הנוסחה לחישוב שטחה. כמו כן אפשר לשרטט משולשים שונים, להציג את זוויותיהם ולהראות שסכומן הוא 180 מעלות.",
    "description_arabic": "التطبيق معد للتعليم في الصفين الثالث والرابع، وهو سهل لتعليم المساحات في المثلثات وعرض زويا مضلعات. يمكن ايضا رسم مصلعات، قصها وتوصيل القطع لتكوين شكل جديد مساحتها نحن نعرفه. عنا مثلا يمكن أن \"نحول\" بسهولة متوازي اضلاع الى مستطيل والحصول على قانون حساب مساحته. كما أنه يمكن رسم مثلثات مختلفة، وعرض زاواياها وأن نبين ان مجموعها 180 درجة. ",
    "description_eng": "This app is convenient for teaching various topics in Geometry and specifically: working with areas of shapes. Shapes can be cut and then the parts recombined in a new way. So, for example, we can cut a parallelogram and put the pieces back together to create a rectangle. This manipulation can be used to give us the formula for calculating the area of a parallelogram. ",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 119,
    "lesson_id": "Simple_counting",
    "app": "ספירה ומנייה",
    "app_type": 0,
    "app_arabic": "عد واحصاء ",
    "app_eng": "Let's Count",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "1",
    "youtube": "YQ4P4ScabS8",
    "youtube_eng": "1V1T0LTMIpg",
    "plus": "0",
    "search_words": "",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מתאים להוראה בסיסית מאוד של חיבור, חיסור, מספר עוקב, מספר קודם ועוד.  ",
    "description_arabic": "التطبيق ملائم للتعليم الاساسي للجمع، الطرح، عدد متوالي، عدد سابق.  ",
    "description_eng": "",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 121,
    "lesson_id": "Angle_game",
    "app": "משחק התאמת הזוויות",
    "app_type": 1,
    "app_arabic": "لعبة ملاءمة الزوايا  ",
    "app_eng": "Angle Match",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "זוויות",
    "classes": "234",
    "youtube": "dkCGR4jrW4M",
    "youtube_eng": "yZFYdM1INFE",
    "plus": "0",
    "search_words": "זווית, זוויות, ישר  חד  קהה   שוות זויות ",
    "search_words_eng": "angle, angles, acute, right, obtuse",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ד-ו, והוא עוסק בהבנת מושג הזווית. בכל משחק יופיעו ציורים של 10 זוויות כשהמשימה היא לחלק אותן לזוגות של זוויות שוות. קושי ראשון נובע מכך שהקרניים המצוירות של כל זווית הן באורכים שונים. הקושי השני נובע מכך שהזוויות גם משורטטות בהטיות שונות. בנוסף, במשחק יש אופציה לבקש מהתלמידים לגלות את הגודל המדויק במעלות של כל זווית.",
    "description_arabic": "التطبيق معد للتعليم في الصفوف الرابع حتى الصف السادس، وهو يتناول فهم مصطلح الزاوية،  في كل لعبة يظهر رسوم لـ 10 زوايا ومهمة التلاميذ تقسيم الزوايا الة أزواج زوايا ممتساوية. صعوبة اولى تنيع من اضلاع الزوايا المرسومة هي باطوال مختلفة. الصعوبة الثانية هي أن الزوايا مرسومة بميلان مختلف. بالاضافة، في اللعبة امكانية ان نطلب مت التلاميذ اكتشاف الحجم الدقيق بالدرجات لكل زاوية.  ",
    "description_eng": "The goal of this app is to help introduce the topic of angles. The app will help the students overcome the common misconception of linking length of the rays to the size of the angle between them.  The task for the students will be to identify identical angles that are presented with rays of different lengths and with different orientations. For more advanced students, the app can go beyond the goal of identifying identical angles to the question of ascertaining the actual value of the angle.",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 122,
    "lesson_id": "Kollim_lamispar",
    "app": "קולעים למספר",
    "app_type": 0,
    "app_arabic": "نصيب العدد  ",
    "app_eng": "Target Number",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "4",
    "youtube": "t98Es5viDuU",
    "youtube_eng": "xIucveHV6mw",
    "plus": "0",
    "search_words": "תרגילים, קליעה למטרה,  משחק",
    "search_words_eng": "aiming, aim, target, exercise, target nimber",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ד-ו, אך ניתן להשתמש בו גם בסוף כיתה ג. המחשב מגריל מספר מטרה ו-5 ספרות, ומשימת התלמידים היא למצוא בפרק זמן נתון כמה שיותר תרגילים המשתמשים בכל 5 הספרות, בארבע פעולות החשבון ובסוגריים, שהתוצאה שלהם היא מספר המטרה. יש כאן חיזוק של לוח הכפל ופיתוח חשיבה יצירתית.",
    "description_arabic": "التطبيق معد للتعليم في الصفوف الرابع حتى السادس، لكن يمكن استخدامه في الصف الثالث.  الحاسوب يظهر عدد اهداف و5 اعداد، مهمة التلاميذ هي ايجاد في فترة زمنية معطاه كم تمرينا يستخدم نفس 5 ارقام ، في اربع عمليات حسابية وباقواس ، ونتيجتها هي عدد الهدف. يوجد هنا تقوية للوح الضرب وتطوير تفكير ابداعي.   ",
    "description_eng": "This app produces four or five numbers together with a Target number. The goal for the students is to use the given numbers together with math operations to reach the Target number. There will always be at least one solution. The app can produce all possible solutions that don't run into a negative or fraction in the process of solving the expression. The goal for the students can be to find at least one solution or to find as many solutions as possible.",
    "flash": "",
    "category": "משחק",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 125,
    "lesson_id": "Who_is_the_whole",
    "app": "מיהו השלם",
    "app_type": 0,
    "app_arabic": "ما هو العدد الصحيح  ",
    "app_eng": "What’s the Unit?",
    "display_app_eng": "true",
    "top_topic": "שבר",
    "topic": "פעולות חשבון בשברים",
    "classes": "45",
    "youtube": "Vfz7J2II3-E",
    "youtube_eng": "36e5ruucDP8",
    "plus": "0",
    "search_words": "שבר, שלם, שברים פשוטים, עשרוני",
    "search_words_eng": "fraction, fractions, whole",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להוראה בכיתות ד-ה, והוא עוסק ביחסים שבין גדלים. נתונות מספר חתיכות. בכל פעם ניתן להגדיר חתיכה אחרת כשלם, ובהתאם לכך לבדוק איזה שבר מתאים לחלק שמהווה ממנה חתיכה אחרת.",
    "description_arabic": "هنا تجدون لوحا مبنيا من مثلثات متساوية الآضلاع يمكن تلوينها.  ",
    "description_eng": "Is a half larger than a third? The answer is yes, provided the implied whole or unit is the same for both fractions. But what if the units are different? The What’s the Unit? App helps students understand the necessity of knowing the unit when defining a fraction.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 128,
    "lesson_id": "Weights_on_scale",
    "app": "משקולות על המאזניים",
    "app_type": 0,
    "app_arabic": "اوزان على الميزان  ",
    "app_eng": "Weights on a scale",
    "display_app_eng": "false",
    "top_topic": "שלמים",
    "topic": "מגוון נושאים במספרים שלמים",
    "classes": "1234567",
    "youtube": "p-g-1W8UHWU",
    "youtube_eng": "T6Ll1OvpOoI",
    "plus": "0",
    "search_words": "מדידה מדידות אלגברה",
    "search_words_eng": "",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד לשימוש במגוון כיתות בבית הספר היסודי, והוא מאפשר פיתוח של חשיבה מספרית וחשיבה אלגברית. אל המסך ניתן להכניס משקולות במשקלים שונים ולהשתמש במודל המאזניים על מנת ליצור תרגילים שונים, כולל כאלה בהם אחת המשקולות אינה ידועה – מייצגת \"נעלם\".",
    "description_arabic": "التطبيق معد للاستخدام في مختلف الصفوف الابتدائية، وهو يمكن من تطوير التفكير العددي والتفكير الجبري,. الى الشاشة يمكن ادخال اوزان مختلفة واستخدامها في الميزان لإنتاج تمارين مختلفة. يشمل كالتي فيها الآوزان غير معروفة ـ تمثل \"مجهولا\"  ",
    "description_eng": "",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  },
  {
    "id": 142,
    "lesson_id": "Protractor_2",
    "app": "מד זווית",
    "app_type": 0,
    "app_arabic": "",
    "app_eng": "Protractor",
    "display_app_eng": "true",
    "top_topic": "גיאומטרייה",
    "topic": "זוויות",
    "classes": "567",
    "youtube": "NzFsNqoBwfQ",
    "youtube_eng": "8U0a3vxnD6o",
    "plus": "0",
    "search_words": "מד זווית, זווית, מד זוית, זוית, מדידה, מעלות, סכום זוויות, זוויות",
    "search_words_eng": "angle, protractor, measurement",
    "interaction": "",
    "student_page": "",
    "description": "היישומון מיועד להקל על המורים להציג לתלמידיהם את במד הזווית. בחרנו כאן מד זווית עם סקלה אחת בלבד של 180 מעלות. תוכלו לשרטט זוויות, למדוד אותן תוך הזזה וסיבוב של מד הזווית ואף לבקש מהיישומון לבצע בעצמו פעולה זו.",
    "description_arabic": "",
    "description_eng": "This app is intended to help teachers present the Protractor to the students. You can easily create angles and measure them.",
    "flash": "",
    "category": "כלי",
    "origin": "apps",
    "totalLikes": 0,
    "liked": 0,
    "selected": 0,
    "viewed": 0
  }
];
export default apps;