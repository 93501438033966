import React                    from 'react';
import { store }                from 'index';
import { GET_TOTAL_LIKES_SAGA } from 'actions';

const useGetTotalLikes = (
       denyAccess: boolean,
       guest:      boolean
    ) => {

    const state           = store.getState();
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';

    React.useEffect(() => {

        if (studentLoggedIn || guest || denyAccess) { return; }

        store.dispatch(GET_TOTAL_LIKES_SAGA())
    
    },[studentLoggedIn, guest, denyAccess])

}

export default useGetTotalLikes;