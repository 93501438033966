import React               from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon    from '@mui/icons-material/ArrowBackIos';
import useGetStyles        from './useGetStyles'
import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(() => ({    
    root: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        flexFlow:       'column',
        width:          '96vw',
        marginLeft:     '2vw',
    },
    title: {
      //height:         80,
      paddingTop:     6,
      paddingBottom:  20,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      fontSize:        28,
      letterSpacing:  2,
      fontWeight:     'bold'
    },
    row: {
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      flexFlow:       'row',
      width:          '96vw',
      marginLeft:     '2vw',
    },
    container: {
      width:    '84vw',
      overflow: 'hidden',
      zIndex:    1     
    },
   innerContainer: {
     overflow: 'hidden'     
   },
   unit: {
       backgroundPosition: 'center',
       backgroundSize:     'contain',
       backgroundRepeat:   'no-repeat',
       transition:         'margin 0.3s'
   },
   arrow: {
    '&:hover': {
      opacity: 0.5,
      zIndex:    3     
    },
   },
   button: {
    zIndex:   3,
    left:     '50%',
    width:    450,
    position: 'absolute',
    marginTop: 30
  },

}))


const arr = ['0','1','2','3','4']


type Row5Props = {
    size:       number[];
    wideScreen: boolean;
}
 
const Row5: React.FC<Row5Props> = ({
    size,
    wideScreen,
  }) => {


  const { classes } = useStyles();

  const [animate, setAnimate] = React.useState(true)
  const [state, setState]     = React.useState(0)
  const [dir, setDir]         = React.useState(0)

  const stateMax = React.useMemo(() => {
    return wideScreen ? 3 : 5
  },[wideScreen])
 

  React.useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setState(state >= stateMax - 1 ? 0 : state + 1)
      }, 6000)
    }
    
  },[animate, state, stateMax])


  const goLeft = () => {

    if (animate) { setAnimate(false) }

    setDir(-1)
    if ( state <= 0 ) {
      return
    }
    setState(state - 1)
  }


  const goRight = () => {

    if (animate) { setAnimate(false) }

    setDir(0)
    if ( state >= stateMax - 1 ) {
      return
    }
    setState(state + 1)
  }


  const {
    rootStyle, 
    containerStyle, 
    innerContainerStyle, 
    unitStyle, 
    arrowStyle, 
    arrowStyle2, 
    unitWidth, 
    unitHeight,
  } = useGetStyles(size, state, stateMax, wideScreen)

  return (
       <div 
         className = { classes.root }
         style     = { rootStyle }
       >  
           <div className = { classes.title }>
                  Teachers Say About Us
           </div>
           <div className = { classes.row }>
              <div onClick = { goLeft } style = {{ zIndex: 2 }}>
                  <ArrowBackIosIcon 
                    className = { classes.arrow }
                    style     = { arrowStyle } 
                  />
              </div>
              <div 
                  className = { classes.container }
                  style     = { containerStyle }
              > 
                  <div 
                    className = { classes.innerContainer }
                    style     = { innerContainerStyle }
                  > 
                    {
                      arr.map((val, ind) => {

                        const len  = arr.length;                      
                        const move = state + ind + 1 >= len ? -len : 0
                        const left = ind + state + move;
                        const top  = (-unitHeight * ind) + (40 * ind) + 20;

                        return(
                          <div
                              key       = { `https://iseemath.co/images/wb${ ind }.png` }
                              className = { classes.unit }
                              style     = {{ 
                                ...unitStyle,
                                marginLeft:      (unitWidth * left) + 20,
                                transform:       `translate(0px, ${ top }px)`,
                                backgroundImage: `url(https://iseemath.co/images/wb${ ind + 1 }.png)`,
                                opacity:         (left >= dir && left <= dir + stateMax) ? 1 : 0
                              }}
                          />
                        )
                      })
                    }
                  </div>
              </div>
              <div onClick = { goRight } style = {{ zIndex: 2 }}>
                  <ArrowForwardIosIcon 
                    className = { classes.arrow }
                    style     = { arrowStyle2 } 
                  />
              </div>
            </div>
       </div> 
  );
}


export default Row5;
