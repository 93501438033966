export const topics = [
    `What data do we collect?`,
    `How do we collect your data?`,
    `How will we use your data?`,
    `How do we store your data?`,
    `Marketing`,
    `What are your data protection rights?`,
    `What are cookies?`,
    `How do we use cookies?`,
    `What types of cookies do we use?`,
    `How to manage your cookies`,
    `Privacy policies of other websites`,
    `Changes to our privacy policy`,
    `How to contact us`,    
]

export const texts = [
  [ `This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.`],
  [ `I SEE MATH collects the following data:` ],
  [ `You directly provide I SEE MATH with most of the data we collect. We collect data and process data when you:` ],
  [ `I SEE MATH may also receive your data indirectly from the following sources:` ],
  [ `I SEE MATH collects your data so that we can:` ],
  [ `When I SEE MATH processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.` ],
  [ `We may retain your data on I SEE Math's servers for as long as necessary for the purposes
     described in this privacy policy. This may include keeping data for the period that is
     necessary to pursue our legitimate business interests, conduct audits, comply with (and
     demonstrate compliance with) legal obligations, resolve disputes and enforce our
     agreements.` 
  ],
  [ `I SEE MATH would like to send you information about products and services of ours that we think you might like.
     If you have agreed to receive marketing, you may always opt out at a later date.
     You have the right at any time to stop I SEE MATH from contacting you for marketing purposes.`
  ],
  [
    [`I SEE MATH would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:`],
    [`The right to access - You have the right to request I SEE MATH for copies of your personal data. We may charge you a fee for this service.`],
    [`The right to rectification - You have the right to request that I SEE MATH correct any information you believe is inaccurate. You also have the right to request I SEE MATH to complete the information you believe is incomplete.`],
    [`The right to erasure - You have the right to request that I SEE MATH erase your personal data, under certain conditions.`],
    [`The right to restrict processing - You have the right to request that I SEE MATH restrict the processing of your personal data, under certain conditions.`],
    [`The right to object to processing - You have the right to object to I SEE MATH's processing of your personal data, under certain conditions.`],
    [`The right to data portability - You have the right to request that I SEE MATH transfer the data that we have collected to another organization, or directly to you, under certain conditions.`],
    [`If you would like to exercise any of these rights, please contact us at our email: info@iseemath.co`]
  ],
  [
    [ `Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.` ],
    [ `For further information, visit allaboutcookies.org.` ]
  ],
  [`I SEE MATH uses cookies in a range of ways to improve your experience on our website, including:`],
  [ `I SEE MATH uses cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.`],
  [ `You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.` ],
  [ `The I SEE MATH website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.` ],
  [ `I SEE MATH keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 14 April 2022.` ],
  [ `If you have any questions about I SEE MATH's privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. Email us at: info@iseemath.co` ],




]

export const lists = [
    [
        `Personal identification information such as name and email address.`,
        'User identification information such as username and password',
        'Information you provide while sending us messages or filling out forms included in this website.',
        'IP address.',
        'Geographic location.',
        'Information from your web browser or mobile device, such as browser type, language settings and device screen size.',
        'Information regarding your subscription plan and payments, such as subscription activation, subscription suspension, successful payments, failed payments etc.',
        'Your access and use patterns and information about redirection to our website.',
        `Cookie-related data (see the 'How do we use cookies' section for more details).`
    ],
    [
        `Register online or place an order for any of our products or services.`,
        `Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.`,
        `Use or view our website via your browser's cookies.`,
        `Interact with our website interface`,
    ],
    [ 
      'AWS user registration and authentication services',
      'Google user registration and authentication services',
      'Google Analytics',
      'Google Adds',
      `Paypal webhooks` 
    ],
    [ 
      `Process your order and manage your account.`,
      `Email you with special offers on other products and services we think you might like.`,
      `Analyse trends among our users to help improve our Website.`, 
      `identify and fix errors.`,
      `develop additional features and services.`
    ],
    [
      `Keeping you signed in`,
      `Understanding how you use our website`
    ]

]





