import React, { Suspense } from 'react';
import LoaderSmall         from '../../../components/LoaderSmall';
//import Instructions        from '../Instructions';
import Unit                from './Unit';
import { 
  contentInfoType, 
  userInfoType,
  //filterStateType,
}                          from '../../../types'
import { colors }          from '../../../utils/colors';
import { sizes }           from '../../../utils/defaultStates';
import { makeStyles }      from 'makeStyles';

//const Unit = React.lazy(() => import('./Unit'));

const backgroundColor = '#F8F8F9';

const useStyles = makeStyles()(
  (Theme) => ({    container: {
      position:        'absolute',
      right:           0,
      overflowY:       'scroll',
      overflowX:       'hidden',
      transition:      'opacity 0.3s',
      backgroundColor: backgroundColor,
      '&::-webkit-scrollbar': {
        borderLeft: '1px solid #CCC',
        width:      '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `#BBB`,
        borderRadius:    10,
      }
    },
    grid: {
      display:    'grid',
      marginLeft: '50%',
      transform:  'translate(-50%, 0)',
      width:      '100%',
    },
    fallBack: {
      height:          320,
      width:           300,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'transparent',
    },
    loaderContainer: {
      height:          290,
      width:           270,
      border:          `1px solid #CCC`,
      borderRadius:    '0.5em',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: '#FFF',
    },
    warning: {
      //marginTop:       15,
      height:          90,
      width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           '#666',
      fontSize:        20,
    //  fontWeight:      'bold',
      backgroundColor: 'transparent',
      transition:      'all 0.4s'
    },
    updating: {
      position:        'fixed',
      right:           0,
      height:          90,
      width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           '#666',
      fontSize:        20,
      //fontWeight:      'bold',
      //backgroundColor: 'transparent'
      backgroundColor: '#FFF'
    },
    smallLoaderContainer: {
      marginLeft: 15
    }
}))

type GridProps = {
    bonusContent:      contentInfoType[];
    size:              number[];
    menuSize:          number;
    wideScreen:        boolean;
    showPracticeComp:  boolean;
    showTopicIntro:    boolean;
    userInfo:          userInfoType;
    handleClick:       (val: contentInfoType) => void;
}

const Grid: React.FC<GridProps> = ({
    bonusContent,
    size,
    menuSize,
    wideScreen,
    showPracticeComp,
    showTopicIntro,
    userInfo,
    handleClick
}) => {
  
  const { classes } = useStyles();

 /*  const [arr, setArr]                 = React.useState(puzzles)
  const [gridOpacity, setgridOpacity] = React.useState(1) */

  const contentRef = React.useRef<HTMLDivElement>(null);


  const containerStyle = React.useMemo(() => {

    const { navBarSize, tabSize, footerSize } = sizes;

    return {
      top:      navBarSize + tabSize,
      height:   size[1] - navBarSize - tabSize - footerSize,
      width:    wideScreen ? size[0] - menuSize : size[0],
      opacity:  (showPracticeComp || showTopicIntro) ? 0 : 1
    }
  },[size, wideScreen, menuSize, showPracticeComp, showTopicIntro])


  const [gridStyle, lowerToolTip] = React.useMemo(() => {

    const threeCol = size[0] > 1250;
    const twoCol   = size[0] > 950;

    return [
      {
         gridTemplateColumns: `repeat(${ threeCol ? 3 : ( twoCol ? 2 : 1) }, 1fr)`,
         maxWidth:            size[0] > 1250 ? 930 : 620,
      },
      ( threeCol ? 3 : twoCol ? 2 : 1 ) 
    ]
  },[size])



  return (
         <div 
            ref       = { contentRef }
            className = { classes.container }
            style     = { containerStyle }
         >
          {/* <Instructions
             userInfo           = { userInfo }
             wideScreen         = { wideScreen }
             width              = { containerStyle.width }
             showUpdatingLoader = { false } 
             showLoader         = { false }
          /> */}
          <div 
              className = { classes.grid }
              style     = { gridStyle }
           >
            {
                bonusContent.map((val, ind) => {
                    return(
                      <div key = { `bonus_row_${ ind }` }>
                         <Suspense 
                             fallback = {
                               <div className = { classes.fallBack }>
                                  <div className = { classes.loaderContainer }>
                                     <LoaderSmall size = { 30 } color = { colors.primary }/>
                                  </div>  
                               </div>
                             }
                         >
                           <Unit
                               val         = { val }
                               lowerTT     = { ind < lowerToolTip }
                               gridOpacity = { 1 }
                               handleClick = { handleClick }
                           />
                         </Suspense>
                      </div>
                    )
                })
            }
          </div>
        </div>
  );
}

export default Grid;





