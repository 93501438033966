import React  from 'react'
import { userAccessType } from 'types'
import Menu   from './menu'
import NavBar from './navbar'
import Tabs   from './tabs'


type NavigationProps = {
  size:                   number[];
  menuSize:               number;
  tester:                 boolean;
  wideScreen:             boolean;
  guest:                  boolean;
  AudioRef:               any;
  userAccess:             userAccessType;
  setChangeContent:       (val: boolean) => void;
}


const Navigation: React.FC<NavigationProps> = ({ 
  size,
  menuSize, 
  guest,
  tester,
  wideScreen,
  AudioRef,
  userAccess,
  setChangeContent,
}) => {

    return (
      <>
      <Tabs
         size             = { size }
         menuSize         = { menuSize }
         wideScreen       = { wideScreen }
         AudioRef         = { AudioRef }
         setChangeContent = { setChangeContent }
      />
      <Menu
        size             = { size }
        menuSize         = { menuSize }
        AudioRef         = { AudioRef }
        guest            = { guest }
        tester           = { tester }
        denyAccess       = { userAccess.denyAccess }
        wideScreen       = { wideScreen }
        setChangeContent = { setChangeContent }
      />
      <NavBar
          size                   = { size }
          menuSize               = { menuSize }
          wideScreen             = { wideScreen }
          guest                  = { guest }
          subscriber             = { userAccess.subscriber || userAccess.freeTrial || userAccess.freeUser }
          denyAccess             = { guest || userAccess.denyAccess }
          unSubscribedAuthedUser = { userAccess.unSubscribedAuthedUser }
          subscriptionStatus     = { userAccess.subscriptionStatus }
          setChangeContent       = { setChangeContent }
      /> 
</>
    )
}

export default Navigation;
