import React             from 'react';
//import { useNavigate }    from "react-router-dom";
import { makeStyles }    from 'makeStyles';

var clsx = require('classnames')

//const pagesObj: { [key: string]: string } = { content: 'content', /* teacherContent: 'teacher content', */ about: 'about' };

const useStyles = makeStyles()(
  (Theme) => ({    selector: {
      flex:           1,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
     // border: '1px dashed #F00'
    },
    button: {
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      cursor:         'pointer',
      fontWeight:     'bold',
      color:          '#FFF',
      textDecoration: 'none'
    },
    hover: {
      '&:hover': {
        opacity: 0.5
      }
    },
    small: {
      fontSize: 14,
    },
    big: {
      fontSize: 15,
    }
}))


type SelectorProps = {
  val:                 string;
  page:                string;
  showSelectors:       boolean;
  wideScreen:          boolean;
  onClick:             (val: string) => void;
}

const Selector: React.FC<SelectorProps> = ({ 
  val,
  page,
  showSelectors,
  wideScreen,
  onClick,
}) => {

  
  const { classes } = useStyles();


  const text = React.useMemo(() => {

    if (val === 'contact us' && !wideScreen) {
       return 'contact'
    }

   return val;

  },[val, wideScreen])


  
  const buttonStyle = React.useMemo(() => {

    return {
      borderBottom: `1px solid ${ val === page ? '#FFF' : 'transparent' }`
    }

  },[val, page])


  const rootStyle = React.useMemo(() => {
    return{
      maxWidth: 150,
      minWidth: showSelectors ? 110 : undefined
    }
  },[ showSelectors ]) 


  return (
      <div
        className = { classes.selector }
        style     = { rootStyle }
      >
        {
          showSelectors &&
          <div 
             className = { clsx(classes.button, classes.hover, ( wideScreen ? classes.big : classes.small)) }
             onClick   = { () => { onClick(val) }}
             style     = { buttonStyle }
          >
             { text }
          </div>
        }
      </div>
       
  );
}




export default Selector;



