import React                          from 'react';
import SignUpForm1                    from './SignUpForm1';
import SignUpForm2                    from './SignUpForm2';
import CompleteSignUp1                from './CompleteSignUp1';
import CompleteSignUp2                from './CompleteSignUp2';
import { userInfoType, planTypeType } from '../../../types';
import { makeStyles }                 from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({  sign_up_component: {
   width:  '100%',
   height: '100%'
 },
 check_box_container: {
  display:        'flex',
  alignItems:     'center',
  justifyContent: 'center',
},
}));

type SignUpComponentProps = {
  userInfo:         userInfoType;
  size:             number[];
  planType:         planTypeType;
  displayForm:      string;
  name:             string;
  email:            string;
  password:         string;
  code:             string;
  userOrigin:       string;
  error:            string;
  showGoogleButton: boolean;
  tosChecked:       boolean;
  showLogo:         boolean;
  setTosChecked:    (val: boolean) => void;
  setShowTOS:       (val: boolean) => void;
  setShowPrivacy:   (val: boolean) => void;
  setShowLoader:    (val: boolean) => void;
  setDisplayForm:   (val: string) => void;
  setName:          (val: string) => void;
  setEmail:         (val: string) => void;
  setPassword:      (val: string) => void;
  setUserOrigin:    (val: string) => void;
  setCode:          (val: string) => void;
  setError:         (val: string) => void;
  handleCreateUser: () => void;
  logOut:           () => void;
}

const SignUpComponent: React.FC<SignUpComponentProps> = ({
   userInfo,
   size,
   planType,
   displayForm,
   name,
   email,
   password,
   userOrigin,
   code,
   error,
   tosChecked,
   showGoogleButton,
   showLogo,
   setTosChecked,
   setShowTOS,
   setShowPrivacy,
   setShowLoader,
   setDisplayForm,
   setName,
   setEmail,
   setPassword,
   setUserOrigin,
   setCode,
   setError,
   handleCreateUser,
   logOut
  }) => {

  const { classes } = useStyles();




  return (
     <div className = { classes.sign_up_component}>
       { 
         displayForm === 'sign_up_part1'
         &&
            <SignUpForm1 
               size             = { size } 
               name             = { name } 
               email            = { email }
               password         = { password }
               userOrigin       = { userOrigin }
               error            = { error }
               tosChecked       = { tosChecked }
               showLogo         = { showLogo }
               showGoogleButton = { showGoogleButton }
               setTosChecked    = { setTosChecked }
               setDisplayForm   = { setDisplayForm } 
               setName          = { setName } 
               setEmail         = { setEmail } 
               setPassword      = { setPassword }
               setUserOrigin    = { setUserOrigin }
               setShowLoader    = { setShowLoader }
               setError         = { setError }
               setShowTOS       = { setShowTOS }
               setShowPrivacy   = { setShowPrivacy }
            />
        }
        {
           displayForm === 'sign_up_part2'
           &&
            <SignUpForm2 
               size             = { size }
               email            = { email } 
               code             = { code } 
               error            = { error }
               showLogo         = { showLogo }
               setError         = { setError }
               setShowLoader    = { setShowLoader }
               setCode          = { setCode }
               handleCreateUser = { handleCreateUser }
            /> 
          }
          { 
            displayForm === 'completeSignUp'
            &&
                 <CompleteSignUp1 
                    userInfo       = { userInfo }
                    size           = { size } 
                    name           = { name } 
                    email          = { email }
                    password       = { password }
                    userOrigin     = { userOrigin }
                    error          = { error }
                    tosChecked     = { tosChecked }
                    showLogo       = { showLogo }
                    setTosChecked  = { setTosChecked }
                    setDisplayForm = { setDisplayForm } 
                    setName        = { setName } 
                    setEmail       = { setEmail } 
                    setPassword    = { setPassword }
                    setUserOrigin  = { setUserOrigin }
                    setShowLoader  = { setShowLoader }
                    setError       = { setError }
                    setShowTOS     = { setShowTOS }
                    setShowPrivacy = { setShowPrivacy }
                    logOut         = { logOut }
                 />
           }
           {
           displayForm === 'completeSignUp2'
           &&
            <CompleteSignUp2 
               size             = { size }
               planType         = { planType }
               email            = { email } 
               code             = { code } 
               error            = { error }
               showLogo         = { showLogo }
               setError         = { setError }
               setShowLoader    = { setShowLoader }
               setCode          = { setCode }
               handleCreateUser = { handleCreateUser }
            /> 
          }
      </div>
    )

  
}




export default SignUpComponent;






