import { topicsObjType, appTopicsArrType } from '../../types'

const ceateTopicIntrosArr = (arr: { [key: string]: any }) => {

    let obj      = {} as topicsObjType;

    for (let i = 0; i < arr.length; i++) {
       if ( !obj[`${ arr[i].topic }`] ) {
         obj[`${ arr[i].topic }`] = [];
       }
       else {
         obj[`${ arr[i].topic }`].push(arr[i].sub_topic)
       }
    }

    let keys     = Object.keys(obj);
    let topicArr = [] as appTopicsArrType[];

    for (let j = 0; j < keys.length; j++) {
        topicArr.push({
            mainTopic: keys[j],
            subTopics: obj[`${keys[j]}`]
        })
    }

    return topicArr;
    
}


export default ceateTopicIntrosArr;




























