import { call, all }          from 'redux-saga/effects';
import createUser             from './create_user';
import createSubscription     from './create_subscription';
import createFreeTrial        from './create_trial';
import getUserInfo            from './get_user_info';
import getUserActivity        from './get_user_activity';
import getTotalLikes          from './get_total_likes';
import getGroupLessons        from './get_group_lessons';
import updateUserActivity     from './update_user_activity';
import setStudentOpenApps     from './set_student_open_apps';
import getApps                from './get_content/get_apps';
import getPuzzles             from './get_content/get_puzzles';
import getLessons             from './get_content/get_lessons';
import getUserLessons         from './get_content/get_user_lessons';
import getStudentApps         from './get_content/get_student_apps';
import getBonusContent        from './get_bonus_content';
import getStudentScores       from './get_student_scores';
import getTeacherCode         from './get_teacher_code';
import upadteUserName         from './update_user_name';
import createData             from './create_data';
import reactivateSubscription from './reactivate_subscription';
import unsubscribe            from './unsubscribe';
import abortSuspension        from './abort_suspension';
import submitMessage          from './submit_message';
import updateFreeAccess       from './update_free_access';
import studentLogin           from './student_login';
import recordUserDevice       from './record_user_device';
import recordEvent            from './record_event';
import recordError            from './record_error';
import updateOneTimeEvents    from './update_one_time_events';
import joinLesson             from './join_lesson';
import doTest                 from './do_test';


export default function* indexSaga(){
  yield all([
    call(joinLesson),
    call(createUser),
    call(createSubscription),
    call(createFreeTrial),
    call(getUserInfo),
    call(getApps),
    call(getPuzzles),
    call(getLessons),
    call(getUserLessons),
    call(getStudentApps),
    call(getBonusContent),
    call(getGroupLessons),
    call(upadteUserName),
    call(getUserActivity),
    call(getTotalLikes),
    call(getStudentScores),
    call(getTeacherCode),
    call(abortSuspension),
    call(createData),
    call(updateUserActivity),
    call(setStudentOpenApps),
    call(unsubscribe),
    call(reactivateSubscription),
    call(submitMessage),
    call(updateFreeAccess),
    call(studentLogin),
    call(recordUserDevice),
    call(recordEvent),
    call(recordError),
    call(updateOneTimeEvents),
    call(doTest)
  ])
}
 