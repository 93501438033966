
const arr = [
  "Circle_circumference", 
  "Prisa2", 
  "Hexagon_challenge",
  "Distribution_law", 
  "Multiplication_table", 
  "Whole_times_fraction_page", 
  "Pyramid_route",
  "Vertical_addition2",
  "Vertical_subtraction"
];

export default arr;