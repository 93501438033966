import { store }            from 'index';
import { SET_MODAL_STATE } from 'actions/modal_actions'

const goToCloseAccount = () => { 

    const setModalState = (payload: { variant: string, open: boolean, showButtons: boolean }) => store.dispatch(SET_MODAL_STATE(payload))
    setModalState({ variant: 'closeAccount', open: true, showButtons: true }) 
}

export default goToCloseAccount
