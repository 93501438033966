
import { store }             from '../../index';  
import removeSubscribePrompt from '../payments/remove_subscribe_prompt';
import signIn                from './sign_in';
import { 
  SET_ERROR, 
  SET_AUTH_LOADER 
}                            from '../../actions/auth_actions';


const handleUserCreation = (
   // newUserCreated:  string,
   // setError:        (val: string) => void,
  //  handleSignIn:    () => void,
  //  setShowLoader:   (val: boolean) => void,
) => {


     const state = store.getState();

     const newUserCreated = state.user.newUserCreated;

     const setError      = (payload: string) => store.dispatch(SET_ERROR(payload))
     const setShowLoader = (payload: boolean) => store.dispatch(SET_AUTH_LOADER(payload))

     console.log('newUserCreated  :  ',newUserCreated)


      if ( newUserCreated === 'freeUser') { 
        setShowLoader(false); 
        removeSubscribePrompt();
        signIn();
      }

      else if ( newUserCreated === 'userAlreadyExists' ) {

        console.log("newUserCreated === 'newUserCreated'")
    
        const userWantsToSubscribe = localStorage.getItem('subscribeAfterSignup') && localStorage.getItem('subscribeAfterSignup') === 'true';
    
        if ( userWantsToSubscribe ) {
           // removeSubscribePrompt();
            /* setShowAuthForm(false)
            setShowLoader(false); 
            setShowPayForm(true) */
            signIn();
        }
        else {
          signIn();
        }
        
      }

      if ( newUserCreated === 'true' ) {

        console.log("newUserCreated === 'true'")
       /*  setShowAuthForm(false)
        setShowLoader(false); 
        setShowPayForm(true); */
        signIn();
      }

      if ( newUserCreated === 'false' ) {
        removeSubscribePrompt();
        setShowLoader(false); 
        setError('Something went wrong. Please try again later');
      }
}


export default handleUserCreation;
