import { modalStateType } from "./types";

export const SET_MODAL_STATE = (payload: modalStateType) => ({
    type: 'SET_MODAL_STATE',
    payload: payload
  });

export const CREATE_ERROR = () => ({
  type: 'CREATE_ERROR',
  payload: true
});


export const DISABLE_MODAL_VARIANT = (payload: string) => ({
  type: 'DISABLE_MODAL_VARIANT',
  payload: payload
});
