import { store }            from 'index';
import { UNSUBSCRIBE_SAGA } from 'actions';
import recordEvent          from 'functions/contentInteraction/record_event';


const unsubscribe = (tester: string) => {

    const state    = store.getState();
    const userInfo = state.user.userInfo;

    const payload = {
        userEmail:      userInfo.userEmail,
        subscriptionID: userInfo.subscriptionId,
        tester:         tester === 'true'
    }

    store.dispatch(UNSUBSCRIBE_SAGA(payload));
    recordEvent('modal', 'user unsubscribed', 'UNSUBSCRIBE_SAGA')

}

export default unsubscribe;



