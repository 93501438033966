import React           from 'react';
import { colors }      from '../../utils/colors';
import { makeStyles }  from 'makeStyles';
import { contentInfoType } from '../../types';



var clsx = require('classnames')

const useStyles = makeStyles()((Theme) => ({  
  root: {
    position:        'fixed',
    width:           '100vw',
    height:          '100vh',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    backgroundColor: '#FFF',
    transition:      'opacity 1s',
  },
  image: {
    width:              150,
    height:             150,
    display:            'flex',
    alignItems:         'center',
    justifyContent:     'center',
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
    transition:         'all 1s'
  },
  text: {
    width:          '96%',
    height:         50,
    color:          colors.primary,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
  },
  t1: {
    transition:    'all 0.9s',
    fontSize:      26,
    paddingTop:    8,
    paddingBottom: 8,
  },
  t2: {
    transition: 'all 1s',
    fontSize:   22,
  },
  t3: {
    transition: 'all 1.2s',
    fontSize:   22,
  },
  button: {
    marginTop:       50,
    color:           '#FFF',
    width:           260,
    height:          50,
    borderRadius:    '0.4em',
    border:          `1px solid ${ colors.primary }`,
    textAlign:       'center',
    cursor:          'pointer',
    backgroundColor: colors.primary,
    transition:      'transform 1.4s',
    fontWeight:      'bold',
    '&:hover': {
      color:           colors.primary,
      backgroundColor: '#FFF',
    }
   },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        18,
   // fontFamily:     'Fredoka One',
  },
}));

type ScorePageProps = {
  challengeState: contentInfoType;
  showScorePage:  boolean;
  handleExit:     () => void;
 }

const ScorePage: React.FC<ScorePageProps> = ({ challengeState, showScorePage, handleExit }) => {

  const { classes } = useStyles();

  return (
    <div
      className = { classes.root }
      style     = {{
        opacity:    showScorePage ? 1 : 0,
        transform:  `translate(0vw, ${ showScorePage ? 0 : 300 }vh)`
      }}
    >
        <div
         className = { classes.image }
         style     = {{
           backgroundImage: `url(https://iseemath.co/images/calculatorBlue.png)`,
           transform:       `translate(0px, ${ showScorePage ? 0 : 100 }vh)`,
         }}
       />
       <div
         className = { clsx(classes.text, classes.t1) }
         style     = {{
          transform: `translate(0px, ${ showScorePage ? 0 : 100 }vh)`,
         }}
       >
           { `${ challengeState.selectedChallenge.name } Level ${ !!challengeState.level ? challengeState.level : 1 } Complete!` }
       </div>
       <div 
           className = { clsx(classes.text, classes.t2) }
           style     = {{
            transform: `translate(0px, ${ showScorePage ? 0 : 100 }vh)`,
           }}
       >
           { `Your Score: ${ challengeState.score }` }
       </div>
       <div 
           className = { clsx(classes.text, classes.t3) }
           style     = {{
            transform: `translate(0px, ${ showScorePage ? 0 : 100 }vh)`,
           }}
       >
           { `Time Bonus: ${ challengeState.timeBonus }` }
       </div>
       <div 
           className = { classes.button }
           onClick   = { handleExit }
           style     = {{
            transform: `translate(0px, ${ showScorePage ? 0 : 100 }vh)`,
           }}
        >
            <div className = { classes.buttonText } >
                Continue
            </div>                
       </div>
    </div>
   )
}


export default ScorePage;
