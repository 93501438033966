import { componentsStateType} from '../../reducer/types'

const selectContent = (
    variant:         string,
    val:             string,
    componentsState: componentsStateType,
    setComponentsState:  (val: componentsStateType) => void
) => {


    setComponentsState({
        ...componentsState,
        selectedGrades:      '1234567',
        selectedAppTopic:    '',
        selectedAppSubTopic: '',
        searchStr:           '',
        selectedContent:     variant,
        openGradeGrid:       false,
        showAppIntro:        false,
       // showMenu:            val === 'showTopicIntro' ? false : componentsState.showMenu,
        showTopicIntro:      false,
        selectedVideo:       variant === 'introVideo' ? -1 : componentsState.selectedVideo,
        showTutorial:        variant === 'introVideo'// ? true : filterState.showTutorial
    })

}

export default selectContent;
