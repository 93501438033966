

const abc = ['a','b','c','d','e','f','g','h','i','j'];

const checkAnswerString = (
    selectedAnswer: number,
    correct_answer: string,
    success:        string,
    setSuccess:     (val: string) => void
) => {

  if ( abc[selectedAnswer] === correct_answer.toLowerCase() ) {
        setSuccess('true')
      }
      else {
        if (success !== 'false') {
          setSuccess('false')
        }
    }
}


export default checkAnswerString