import React             from 'react';
import { introVideoSrc } from 'utils/defaultStates';
import { makeStyles }    from 'makeStyles';

/* 
const W = 4;
const H = 2.5; */

const useStyles = makeStyles()(
  (Theme) => ({    content1: {
        flex:            1,
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
    }
}))


 /* 
type IframeCompProps = {
    size:       number[];
    wideScreen: boolean;
    rootStyle:  { [key: string]: any };
} */
 
const IframeComp/* : React.FC<IframeCompProps> */ = ({ size, wideScreen, rootStyle }) => {

  const { classes } = useStyles();


  //const [H, setH] = React.useState(2.5);


  const video = React.useMemo(() => {

     return {
       width:       wideScreen ? rootStyle.width : rootStyle.width,
       height:      rootStyle.width * 0.57,
      // paddingLeft: wideScreen ? 85 : 0
     }

    /*  if ( wideScreen ) {
       return obj;
     }
     return {
       ...obj,
       maxHeight: rootStyle.minHeight * 0.4,
     } */
  },[wideScreen, rootStyle])


/* 
  const YTStyle = React.useMemo(() => {
 
    const width  = wideScreen ? rootStyle.width/2 - 50 : size[0] - 70;
    const height = wideScreen ? rootStyle.height : rootStyle.minHeight * 0.4
  
    const bigWidth  = height * W / H;
    const bigHeight = width * H / W;

    if ( bigWidth > width ) {
      return {
        width:   width,
        height:  bigHeight,
      }
    }

    if ( bigWidth <= width ) {
      return {
        width:   bigWidth,
        height:  height,
      }
    } 

    return {
      height: '100%',
      width:  '100%',
    }
    
  },[ rootStyle, wideScreen, size/]) */


  return (
      
          <div 
              className = { classes.content1 }
              style     = { video }
           > 
                <iframe 
                   width            = { '100%' }
                   height           = { '100%' }
                   src              = { introVideoSrc }
                   title            = "I SEE MATH" 
                   frameBorder      = "0" 
                   allow            = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                   allowFullScreen
                >
                </iframe>
           </div>  
         
  );
}

export default IframeComp;
