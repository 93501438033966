

  export const SELECT_CONTENT = (payload: { [key:string]: any; }) => ({
    type: 'SELECT_CONTENT',
    payload: payload
  });

  export const GET_APPS_SAGA = (payload: { [key:string]: any; }) => ({
    type: 'GET_APPS_SAGA',
    payload: payload
  });

  export const SET_APPS = (payload: { [key:string]: any; }) => ({
    type: 'SET_APPS',
    payload: payload
  });

  export const GET_PUZZLES_SAGA = (payload: { [key:string]: any; }) => ({
    type: 'GET_PUZZLES_SAGA',
    payload: payload
  });

  export const SET_PUZZLES = (payload: { [key:string]: any; }) => ({
    type: 'SET_PUZZLES',
    payload: payload
  });

  export const GET_LESSONS_SAGA = (payload: { [key:string]: any; }) => ({
    type: 'GET_LESSONS_SAGA',
    payload: payload
  });

  export const SET_LESSONS = (payload: { [key:string]: any; }) => ({
    type: 'SET_LESSONS',
    payload: payload
  });

  export const GET_USER_LESSONS_SAGA = (payload: { [key:string]: any; }) => ({
    type: 'GET_USER_LESSONS_SAGA',
    payload: payload
  });

  export const SET_USER_LESSONS = (payload: { [key:string]: any; }) => ({
    type: 'SET_USER_LESSONS',
    payload: payload
  });

  export const GET_MY_STUDENT_CONTENT_SAGA = (payload: { [key:string]: any; }) => ({
    type: 'GET_MY_STUDENT_CONTENT_SAGA',
    payload: payload
  });

  export const SET_MY_STUDENT_CONTENT = (payload: { [key:string]: any; }) => ({
    type: 'SET_MY_STUDENT_CONTENT',
    payload: payload
  });

   
  
  
  


  