
import { store }                  from 'index';
import { 
  SET_COMPONENTS_STATE, 
  SET_SHOW_SMALL_MENU }           from 'actions/component_actions'
import { SET_CONTENT }            from 'actions/'
import { SET_MODAL_STATE }        from 'actions/modal_actions'
import searchBarClick             from './search_bar_click';
import favoritesClick             from './favorites_click';
import studentContentClick        from './student_content_click';
import selectContent              from './select_content';
import gradesClick                from './grades_click';
import contentClick               from './content_click';
import { componentsStateType }   from 'reducer/types';

export const menuClick = (
    variant:             string, 
    val:                 string,
    wideScreen:          boolean,
    disabled:            boolean,
    setChangeContent:    (val: boolean) => void,
) => {

    const state = store.getState();

    const pageState          = state.page;
    const componentsState    = state.components;
    const setComponentsState = (payload: componentsStateType) => store.dispatch(SET_COMPONENTS_STATE(payload));
    const setContent         = (payload: { [key: string]: any }) => store.dispatch(SET_CONTENT(payload));
    const setShowSmallMenu   = (payload: boolean) => store.dispatch(SET_SHOW_SMALL_MENU(payload));

   
    if ( disabled ) {

      store.dispatch(SET_MODAL_STATE(
        { 
          variant: `disabled${ val }`, 
          open: true, 
          showButtons: true 
        }
      ))
      return;
    }

    if (variant === 'groupLessons') {
      return
    }


    if (variant === 'search') {
      searchBarClick(pageState, componentsState, setComponentsState);
    //  setChangeContent(true)
      return;
    }


    if (variant === 'groupSearch') {
      selectContent(variant, val, componentsState, setComponentsState)
      setChangeContent(true)
      return;
    }
    

    if (variant === 'favorites') {
      favoritesClick( wideScreen, componentsState, setShowSmallMenu, setComponentsState, setContent)
      setChangeContent(true)
      return;
    }

  /*   if (variant === 'myStudentsContent') {
      studentContentClick( wideScreen, componentsState, setShowSmallMenu, setComponentsState)
      setChangeContent(true)
      return;
    } */


    if ( variant === 'userLessons') {
      selectContent(variant, val, componentsState, setComponentsState)
      setShowSmallMenu(false)
      setChangeContent(true)
      return;
    }

   /*  if ( variant === 'puzzles' ) {
      selectContent(variant, val, componentsState, setComponentsState)
      return;
    } */


    if ( variant === 'bonus' || variant === 'puzzles' ) {
      selectContent(variant, val, componentsState, setComponentsState)
      setShowSmallMenu(false)
      return;
    }

    if (variant === 'studentApps' || variant === 'myTeacherContent') {
      selectContent(variant, val, componentsState, setComponentsState)
      setChangeContent(true)
      setShowSmallMenu(false)
      return;
    }

    if ( variant === 'introVideo' ) {
      selectContent(variant, val, componentsState, setComponentsState)
      return;
    }    
    
    if (variant === 'grades') {
      gradesClick(pageState, componentsState, setComponentsState)
      setChangeContent(true)
      return;
    }


     if ( variant === 'apps' ||  variant === 'lessons' || variant === 'games') {
      contentClick(val, componentsState, setComponentsState, setShowSmallMenu)
      setChangeContent(true)
      //if (val === 'lessons') {
        setShowSmallMenu(false)
      //}
      return;
    }

    if ( variant === 'myStudentsContent') {
      studentContentClick(wideScreen, componentsState, setComponentsState, setShowSmallMenu)
      setChangeContent(true)
      return;
    }

    

  
}