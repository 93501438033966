import React          from 'react';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../../../utils/colors';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      width:          '100%',
      height:         '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
    },
     shape: {
      width:  '100%',
      height: '100%',
    }, 
    path: {
        fill:            'transparent',
        stroke:          colors.primary,
        strokeWidth:     0.2,
        strokeLinejoin:  'round',
        strokeDasharray: '7%',
        strokeDashoffset: 700,
        animation:       'dash 600s linear'
    }
   
    
}))

const Perimeter: React.FC = () => {

  const { classes } = useStyles();

  return (
       <div className = { classes.root }>
           <svg className = { classes.shape } viewBox = "0 0 5 5" xmlns = "http://www.w3.org/2000/svg">
              <path className = { classes.path } d = "M1 2 v-1 h3 v3 h-1 v-1 h-1 v1 h-1 v-2 z"/> 
           </svg>
       </div>
  );
}

export default Perimeter;


