import React          from 'react';
import { colors }     from '../../../utils/colors';
import { makeStyles } from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({  spinner_container: {
    position:  'absolute',
    height:    '100%',
    width:     '100%',
  },
  spinner: {
    zIndex:          2,
    borderRight:     'none',
    width:           '50%',
    height:          '100%',
//    backgroundColor: colors.primary
  }
}))

type SpinnerProps = {
     compSize:  number;
     speed:     number;
     rotate:    number;
     variant:   string;
}

const Spinner: React.FC<SpinnerProps> = ({
    compSize,
    rotate,
    speed,
    variant,
}) => {

  const { classes } = useStyles();


  return (
       <div 
          className = { classes.spinner_container }
          style     = {{
            animation: variant === 'failure' ? undefined : !rotate ? `fill ${ speed }s steps(1, end)` : `spin ${ speed }s linear`,
         }}
       >
            <div 
                className = { classes.spinner }
                style     = {{
                  borderRadius:    `${ Math.max(compSize/2, 70) }px 0 0 ${ Math.max(compSize/2, 70) }px`,
                  backgroundColor: variant === 'success' ? colors.primary : colors.red
                }}
            />
       </div> 
  );
}

export default Spinner;
