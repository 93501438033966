const content: { [key: string]: any } = {
   'gali': { 
      title: ['Gali Shimoni', 'CEO'],
      image: 'https://iseemath.co/images/gali.png',
      text:  [`Gali Shimoni has an M.Sc. In Math Education and a B.A. in Mathematics from the Hebrew University of Jerusalem. Gali has been teaching math in schools for 20 years. In 2007 he was awarded the Technion's Avital Prize for outstanding contribution to mathematics education. For the past 20 years Gali has held the position of Head of Math Department in the Israel Center for Excellence Through Education. Throughout this time Gali focused on developing math programs for grades 1-9, and has been training teachers in hundreds of schools throughout Israel, the United States and Singapore.`]
    },
    'zvi': { 
      title: ['Zvi Shalem', 'COO'],
      image: 'https://iseemath.co/images/zvi.jpg',
      text:  [`Zvi  Shalem has a B.Sc. in Physics from the Hebrew University of Jerusalem. Together with Gali Shimoni, he invented the card game "Swish” that was sold over 400,000 pieces. Since then, Zvi invented several more successful board games. Over the past 15 years Zvi has been developing programs in Math and Science for grades 1-9 and has been training teachers in hundreds of schools throughout Israel and the United States and has extensive experience in gifted math education.`]
    },
    'avi': { 
      title: ['Avi Poleg', 'Mathematical Consultant'],
      image: 'https://iseemath.co/images/avi.jpg',
      text:  [`Dr. Avi Poleg earned his B.Sc. in Mathematics and Physics from the Hebrew University of Jerusalem, his M.Sc. in Physics from the Technion and completed his Ph.D. in Science Education at the Hebrew University. He was the commander of the elite "Talpiot" Israel Defense Forces unit which trains select youngsters to become officers in the field of research and development in defense systems technologies. For the past 20 years Avi has been involved in management and development in the fields of education, and he has a wealth of experience writing and editing curriculum, guiding and training both teachers and students.`]
    },
}

export default content