import React                      from 'react';
import { Auth }                   from 'aws-amplify';
import PasswordInput              from '../PasswordInput';
import TosCheckBox                from './CheckBox';
import TextField                  from '@mui/material/TextField';
import useTextFieldStyle          from 'components/authenticator/functions/get_textfield_style';
import { userInfoType }           from 'types';
import { makeStyles }             from 'makeStyles';
import { colors }                 from 'utils/colors';

const useStyles = makeStyles()((Theme) => ({
  container: {    
    display:        'flex',
    alignItems:     'center',
    flexFlow:       'column',
    width:          '90%',
    marginLeft:     '5%',
  },
  logo: {
     width:              200,
     height:             65,
     backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
     backgroundPosition: 'center',
     backgroundSize:     'contain',
     backgroundRepeat:   'no-repeat',
  },
  form: {
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
  },
  text_field: {
    height:          45,
    fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
    transform:      'translate(0px, -20px)'
  },
  defaultInputValue: {
    height:          45,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
    fontWeight:      'bold'
  },
  password: {
    marginTop: 10
  },
  signup: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    marginTop:       20,
  //  fontSize:        20,
    color:           colors.darkText,
    width:           250,
   // maxWidth:        '90vw',
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    height:          40,
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
  },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        18,
   // fontFamily:     'Fredoka One',
  },
  login: {
    color:     colors.darkText,
    marginTop: 35,
    cursor:    'pointer'
  },
  forgot_pwd: {
     cursor: 'pointer',
     color:   colors.darkText,
     margin: 20,
     '&:hover': {
       opacity: 0.5
     }
  },
  loader_container: {
    width:  60,
    height: 60
 },
 err: {
   display:        'flex',
   justifyContent: 'center',
   alignItems:     'center',
   textAlign:      'center',
   height:         30,
   color:          '#F00',
 },
 iseemath: {
   marginTop:  5,
   fontSize:   18,
   fontWeight: 'bold'
 }
}));


type SignUpForm1Props = {
    userInfo:       userInfoType;
    size:           number[];
    name:           string;
    email:          string;
    password:       string;
    userOrigin:     string;
    error:          string;
    tosChecked:     boolean;
    showLogo:       boolean;
    setDisplayForm: (val: string) => void;
    setEmail:       (val: string) => void;
    setName:        (val: string) => void;
    setPassword:    (val: string) => void;
    setUserOrigin:  (val: string) => void;
    setError:       (val: string) => void;
    setShowLoader:  (val: boolean) => void;
    setShowTOS:     (val: boolean) => void;
    setShowPrivacy: (val: boolean) => void;
    setTosChecked:  (val: boolean) => void;
    logOut:         () => void;
}

const CompleteSignUp: React.FC<SignUpForm1Props> = ({
     userInfo,
     size,
     name,
     email,
     password,
     userOrigin,
     error,
     tosChecked,
     showLogo,
     setTosChecked,
     setDisplayForm,
     setEmail,
     setName,
     setPassword,
     setUserOrigin,
     setError,
     setShowLoader,
     setShowTOS,
     setShowPrivacy,
     logOut
}) => { 

  const { classes } = useStyles();


  const defaultUserName = React.useMemo(() => {
     return userInfo.userName.length > 0 && userInfo.userName !== userInfo.userEmail;
  },[userInfo])

 
  React.useEffect(() => {
    if ( defaultUserName && name !== userInfo.userName ) {
      setName(userInfo.userName)
    }
    if ( email !== userInfo.userEmail ) {
      setEmail(userInfo.userEmail)
    }
  },[defaultUserName, userInfo, name, email, setName, setEmail]) 
  

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'howDidYouHearAboutUs') { setUserOrigin(target.value) }
    if (target.id === 'name')                 { setName(target.value) }
    if (target.id === 'email')                { setEmail(target.value) }
    if (target.id === 'password')             { setPassword(target.value) }

  }

   const handleSubmit = async() => {

    if (!name.length)         { setError('Please enter your name'); return }
    if (!email.length)        { setError('Please enter your Email address'); return }
    if (password.length < 8)  { setError('Password needs to be at least 8 characters long'); return }
    if (!tosChecked)          { setError('You must agree to the I SEE MATH terms of service'); return }

    setShowLoader(true);

    const username = email;
    
    try {      
       await Auth.signUp({
            username,  //(username = email)
            password,
            attributes: { 'custom:name':name, email },
            validationData: []
       });
       setShowLoader(false);

       localStorage.setItem('subscriberEmail', username);
      
       setDisplayForm('completeSignUp2'); 
    }
    catch(err: any) {

      const _error = err.message || err;
      
      console.error("error occured in sign_up_form1 handleSubmit: ", _error);
      
      if ( _error === 'User already exists' ) {
        setError(`User already exists. If you already used this Email address to create an account try completing the sign up proccess by logging in. If you have an account but lost your password click on 'forgot password'.`);
      }
      else {
        setError(_error || '');
      }

      setShowLoader(false)
    }
    finally {}
  }

  const textFieldStyle = useTextFieldStyle(size);


  const [ constainerStyle, formSyle, errorStyle, signupStyle ] = React.useMemo(() => {
    return [
      {
        transform: `translate(0px, ${ size[1] > 660 ? 0 : -30 }px)`
      },
      {
        marginTop: size[1] > 690 ? 20 : 0,
      },
      {
        marginTop:    size[1] > 690 ? 12 : 0,
        marginBottom: size[1] > 690 ? 8 : 0,
      },
      {
          marginTop: size[1] > 690 ? 20 : 0,
      }
    ]
  },[size])
  

  return (
      <div 
          className = { classes.container }
          style     = { constainerStyle }
      >       
          {
            showLogo && <div className = { classes.logo }/>
          }
          <div 
             className = { classes.err }
             style     = { errorStyle }
          > 
             { error } 
          </div>
          <form 
            className = { classes.form }
            style     = { formSyle }
          >
            <div 
                className = { classes.defaultInputValue }
                style     = { textFieldStyle }
            >
                { `Email Address:  ${ userInfo.userEmail }` }
            </div>
            {
              !defaultUserName
              &&
              <TextField
                className    = { classes.text_field }
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "name"
                label        = "Name / Organisation"
                onChange     = { changeInputValue }
              />
            }
            {
              defaultUserName
              &&
              <div 
                  className = { classes.defaultInputValue }
                  style     = { textFieldStyle }
              >
                { `Name:  ${ userInfo.userName }` }
              </div>
            }
            {
              userInfo.userOrigin.length === 0
              &&
              <TextField
                className    = { classes.text_field }
                style        = { textFieldStyle }
                variant      = "standard"
                id           = "howDidYouHearAboutUs"
                label        = "How did you hear about us?"
                autoComplete = "off"
                onChange     = { changeInputValue }
              />
            }
            <PasswordInput
               password         = { password }
               showHelperText   = { true }
               textFieldStyle   = { textFieldStyle }
               changeInputValue = { changeInputValue }
            />
            <TosCheckBox
               size           = { size }
               tosChecked     = { tosChecked }
               setShowTOS     = { setShowTOS }
               setShowPrivacy = { setShowPrivacy }
               setTosChecked  = { setTosChecked }
            />
            <div 
               className = { classes.signup }
               style     = { signupStyle }
               onClick   = { handleSubmit }
            >
                <div className = { classes.buttonText }>Sign Up</div>
            </div>
            <div 
               onClick   = { logOut }
               className = { classes.forgot_pwd }>
                  Not You? Sign Out
            </div>    
          </form>
      </div>
    )
  
}


export default CompleteSignUp;




