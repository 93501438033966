import { store }          from "index";
import { SET_AUTH_STATE } from "actions/auth_actions";
import recordEvent        from "functions/contentInteraction/record_event";

const handleClose = () => {
   
    const state     = store.getState();
    const authState = state.auth;
    const form      = authState.displayForm;

    store.dispatch(SET_AUTH_STATE({ 
        ...authState,
        name:             '',
        email:            '',
        password:         '',
        userOrigin:       '',
        code:             '',
        error:            '',
        showGoogleButton: true,
        showAuthLoader:   false,
        showAuthForm:     false
    }))

    recordEvent('registration', `${ form } form closed`, 'close authenticator')

}

export default handleClose