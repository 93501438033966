import { store }            from 'index';
import { SET_MODAL_STATE }      from 'actions/modal_actions'
import recordEvent          from 'functions/contentInteraction/record_event';


const goToPlanSelection = () => { 
    console.log('goToPlanSelection goToPlanSelection')
    const setModalState = (payload: { variant: string, open: boolean, showButtons: boolean }) => store.dispatch(SET_MODAL_STATE(payload))
    setModalState({ variant: 'selectPlan', open: true, showButtons: true }) 
    recordEvent('registration', 'plan selection modal opened', 'plan selector opened')
}


export default goToPlanSelection
