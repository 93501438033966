import React           from 'react';
import Text            from './Text';
import { colors }      from 'utils/colors';
import { makeStyles }  from 'makeStyles';

const arr = [
  ['text',  'text0'],
  ['title', 'title0'],
  ['list',  'topics'],
  ['title', 'title1'],
  ['text',  'text1'],
  ['list',  'list0'],
  ['title', 'title2'],
  ['text',  'text2'],
  ['list',  'list1'],
  ['text',  'text3'],
  ['list',  'list2'],
  ['title', 'title3'],
  ['text',  'text4'],
  ['list',  'list3'],
  ['text',  'text5'],
  ['title', 'title4'],
  ['text',  'text6'],
  ['title', 'title5'],
  ['text',  'text7'],
  ['title', 'title6'],
  ['text',  'text8'],
  ['title', 'title7'],
  ['text',  'text9'],
  ['list',  'list4'],
  ['title', 'title8'],
  ['text',  'text10'],
  ['title', 'title9'],
  ['text',  'text11'],
  ['title', 'title10'],
  ['text',  'text12'],
  ['title', 'title11'],
  ['text',  'text13'],
  ['title', 'title12'],
  ['text',  'text14'],
  ['title', 'title13'],
  ['text',  'text15']
]
 
const useStyles = makeStyles()((Theme) => ({  
  root: {
    position:        'fixed',
    top:             0,
    left:            0,
    width:           '100vw',
    height:          '100vh',
    display:         'flex',
    flexFlow:        'column',
    alignItems:      'center',
    justifyContent:  'center',
    backgroundColor: '#FFF',
    transition:      'opacity 0.4s',
    zIndex:          4
  },
  text_container: {
   // height:          '75%',
    width:           '85%',
    display:         'flex',
    flexFlow:        'column',
    textAlign:       'left',
    overflowY:       'scroll',
    overflowX:       'hidden',
    backgroundColor: `rgba(250, 250, 250, 0.85)`,
    borderTop:       `1px solid #DDD`,
    borderBottom:    `1px solid #DDD`,
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CCC',
      outline:         '1px solid #FFF',
      borderRadius:    10
    }
  },
  listItem: {
    display:    'flex',
    flexFlow:   'row',
    textAlign:  'left',
   // padding:    10,
    lineHeight: 2,
  },
  text: {
    padding:    10,
    lineHeight: 2,
  },
  submitContainer: {
    flex:      1,
    maxHeight: 100,
    cursor:    'pointer'
  },
  submit: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    textAlign:       'center',
    backgroundColor: colors.primary,
    color:           '#FFF',
    marginTop:       20,
    width:           240,
    height:          40,
    borderRadius:    '0.2em',
    fontWeight:      'bold'
  },
  title: {
    height:     80,
    width:      '85%',
    display:    'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize:   18
  }
}));

type PrivacyPolicyProps = {  
    size:           number[];
    showPrivacy:    boolean;
    setShowPrivacy: (val: boolean) => void;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ size, showPrivacy, setShowPrivacy }) => {


const rootStyle = React.useMemo(() => {
   return {
       transform: `translate(0vw, ${ showPrivacy ? 0 : 200 }vh)`,
       opacity:   showPrivacy ? 1 : 0
   }
},[showPrivacy])

 
const { classes } = useStyles();  

const func = () => {

  const schoolSignup  = !!localStorage.getItem('schoolSignup') && localStorage.getItem('schoolSignup') === 'true';
  const webinarSignup = !!localStorage.getItem('webinarSignup') && localStorage.getItem('webinarSignup') === 'true';
  
  if (!!schoolSignup) {
    window.location.assign('https://iseemath.co/schoolSignup.html')
    return;
  }
  else if (!!webinarSignup) {
    window.location.assign('https://iseemath.co/webinarSignup.html')
    return;
  }
  else {
    setShowPrivacy(false)
  }
}

return (
    <div 
        className = { classes.root }
        style     = { rootStyle }
    >
        <div className = { classes.title }>
           I SEE MATH Privacy Policy
        </div>
        <div 
           className = { classes.text_container }
           style     = {{
               height: size[1] - ( size[1] > 750 ? 160 : 200 )
           }}
        >
          {
            arr.map((val, ind) => {
              return(
                <Text 
                  key     = { `PrivacyPolicyText${ val[1] }${ ind }` } 
                  variant = { val[0] } 
                  value   = { val[1] }
                />
              )
            })
          }
        </div>
        <div className = { classes.submitContainer }>
           <div 
            className = { classes.submit }
            onClick   = { func }
           >
              continue
           </div>  
       </div>
    </div>
  );
}


export default PrivacyPolicy