import { call, take, put }            from 'redux-saga/effects';
import axios                          from 'axios';
import { RECORD_ERROR }               from '../actions'; 
import { SET_MODAL_STATE, CREATE_ERROR } from 'actions/modal_actions'


const dev = process.env.NODE_ENV === 'development';
const endpoint = 'https://iseemath.co/api/createFreeTrial.php';


function* createFreeTrial(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('CREATE_FREE_TRIAL_SAGA');
        yield put(SET_MODAL_STATE({ variant: 'loader', open: true, showButtons: false })); 
        

        
        const { userEmail } = userInput.payload;

        if (dev) {
            console.log(userInput.payload)
            yield put(SET_MODAL_STATE({ variant: 'freeTrialCreated', open: true, showButtons: true })); 
        }
        else {
 
            const res = yield call( axios.post, endpoint, userInput.payload );

            console.log('res       :   ',res)
            console.log('res.data  :   ',res.data)
     
            if (res && res.data && res.data.success && res.data.success === 1 ) {
                yield put(SET_MODAL_STATE({ variant: 'freeTrialCreated', open: true, showButtons: true })); 
            }
            else {
              yield put(CREATE_ERROR());
              if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: userEmail, err: res.data.msg, endpoint: 'create_trial' }));
              }
            }
 
        }
    }
    catch(err) {
      yield put(CREATE_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default createFreeTrial;


