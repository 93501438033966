import React                       from 'react';
import { store }                   from "index";
import getUserBrowser              from "./get_user_browser";    
import { recordUserDeviceType }    from "actions/types";
import { RECORD_USER_DEVICE_SAGA } from "actions";

const useGetUserDevice = (
    userEmail: string, 
    referrer:  string,
    videoPage: string
) => {

  React.useEffect(() => {
  
        const languages    = !!window.navigator.languages.length ? window.navigator.languages.join() : 'not detected';
        const screenHeight = `${ window.screen.height }`;
        const screenWidth  = `${ window.screen.width }`;
        const referer      = referrer;
        const browser      = getUserBrowser();

        const recordUserDevice = (payload: recordUserDeviceType) => store.dispatch(RECORD_USER_DEVICE_SAGA(payload));

        const payload = {
            userEmail:    userEmail,
            languages:    languages,
            screenHeight: screenHeight,
            screenWidth:  screenWidth,
            browser:      browser,
            referer:      referer,
            videoPage:    videoPage
        }

        recordUserDevice(payload);

  },[userEmail, referrer, videoPage])
}


export default useGetUserDevice;


