import { Auth }        from 'aws-amplify';
import { store }       from '../../index';
import { RESET_STATE } from '../../actions';


const logOut = async() => {

    const url_string = window.location.href;

    let newLocation = 'https://iseemath.co/';

    if ( process.env.NODE_ENV === 'development' ) {
      newLocation = 'http://localhost:3000/';
    }

    if ( url_string.includes('http://www.stg.iseemath.co') ) {
      newLocation = 'http://www.stg.iseemath.co';
    }

    try {      
       store.dispatch(RESET_STATE());
       localStorage.clear();
       await Auth.signOut({ global: true })
     //  window.location.reload();
       window.location.assign(newLocation);

    }
    catch(err: any) {
      console.error("error occured in signout: ",err.message || err)
    }
    finally {
    }

  }

export default logOut;