import { store }                  from 'index';
import closeModal                 from 'functions/modal/close_modal'
import recordEvent                from 'functions/contentInteraction/record_event'
import { GET_GROUP_LESSONS_SAGA } from 'actions';


const goToGroup = (
    groupStr:           string,
    groupName:          string,
    subscriptionStatus: string,
    setGroupSearch:     (val: string) => void,
    setGroupStr:        (val: string) => void,
    goToGroupContentCB: () => void
) => {

    const state = store.getState();
    const hash  = state.content.hash;

    if (groupStr.length === 0) {
        return;
    }
    if ( groupStr === groupName) {
        goToGroupContentCB()
        closeModal(subscriptionStatus)
        return;
    }

    recordEvent('modal', `${ groupStr } group lessons requested`, 'GET_GROUP_LESSONS_SAGA')

    store.dispatch(GET_GROUP_LESSONS_SAGA({ groupName: groupStr, hash: hash }));
    setGroupSearch(groupStr);
    setGroupStr('');
}

export default goToGroup