import React                    from 'react'
import { colors }               from '../../../utils/colors'
import { makeStyles }           from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    userContainer: {
      display:        'flex',
      marginLeft:     10,
      alignItems:     'center',
      jusifyContent:  'center',
      overFlow:       'hidden',
      borderBottom:   '1px solid #DDD',
      borderTop:      '1px solid #DDD',
    },
    user: {
      paddingLeft: 30,
      color:      colors.blue,
      fontWeight: 'bold',
      fontSize:   18,
      cursor:     'default'
    },

}))

type UserNameProps = {
  menuSize:        number;
  userNameHeight:  number;
  userName:        string;
  guest:           boolean;
  tester:          boolean;
  studentLoggedIn: boolean;
  wideScreen:      boolean;
  testFunc:        () => void;
}


const UserName: React.FC<UserNameProps> = ({ 
  menuSize, 
  userNameHeight,
  userName,
  guest,
  tester,
  studentLoggedIn,
  wideScreen,
  testFunc
}) => {


  const { classes } = useStyles();

  return (
  <>
        {
          (!guest || studentLoggedIn)
          && 
          <div 
             className = { classes.userContainer }
             onClick   = { () => { if (!tester)return; testFunc(); } }
             style     = {{
               width:     menuSize - 10,
               minHeight: userNameHeight - 2,
               maxHeight: userNameHeight - 2,
             }}
          >
             <div 
                className = { classes.user }
                style     = {{
                  fontSize:    wideScreen ? undefined : 15,
                  paddingLeft: wideScreen ? 30 : 10,
                }}
             >
               { !userName ? '' : userName.slice(0, 90) }
             </div>
          </div>
        }
  </>
  );
}




export default UserName;

