import { call, take }  from 'redux-saga/effects';
import axios           from 'axios';

const endpoint = 'https://iseemath.co/api/recordError.php';

function* recordError(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('RECORD_ERROR');    
        
        console.log(input.payload);
       
        if (process.env.NODE_ENV === 'production') {
           const res = yield call( axios.post, endpoint, input.payload);
           console.log('res  :   ',res)
        }
       /*  else {
            console.log(input.payload)
        }
 */
    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default recordError;
