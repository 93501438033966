export const text1 = [
    'Established in 2014, I SEE MATH Provides math teachers with dozens of applications and lessons for students in grades 1-6.',
    'Our developers have over 25 years of experience in developing study materials for teaching mathematics.',
]

export const text2 = [
/*     'I SEE MATH apps:', */
    'Are used by thousands of teachers in Israel, South Korea and the United States',
    'Integrate seamlessly into any elementary math program.',
    'Cover all math topics for grades 1-6'
]

