import React          from 'react';
import Grid           from './Grid';
import { makeStyles } from 'makeStyles';

const speed = 1500;
 
const grids = [
    [0, 1, 0, 1, 0, 1],
    [0, 0, 0, 1, 0, 1],
    [1, 1, 0, 0, 1, 1],
]

const positions = [
    [
       [0, 0, 1], [1, 0, 0], [0, 0, -1], [0, 0, 1], [-1, 0, 0], [0, 0, -1]
    ],
    [
       [0, -0.5, 0.5], [0, -0.5, 0.5], [0, 0.5, -0.5], [0, 0.5, 1], [-1, 0.5, 0.5], [0, -0.5, -1]
    ],
    [
       [1, -0.5, 0.5], [1, -0.5, -0.5], [0, -0.5, -1], [-1, 0.5, 1], [0, 0.5, -0.5], [0, 0.5, -0.5]
    ],
]

    

const useStyles = makeStyles()((Theme) => ({    
  root: {
      width:          '100%',
      height:         '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
    }
}))

type ImaginigFractionsProps = {
    dimensions: number;
}

const ImaginigFractions: React.FC<ImaginigFractionsProps> = ({ dimensions }) => {

  const { classes } = useStyles();

  const unit = React.useMemo(() => {
     return dimensions / 3
  },[dimensions])

  const [initialPos, setInitialPos]   = React.useState(false)
  const [change, setChange]           = React.useState(0)
  const [gridIndex, setGridIndex]     = React.useState(0)
  const [gridArr, setGridArr]         = React.useState(grids[0])
  const [positionArr, setPositionArr] = React.useState(positions[0])
  const [showCircle, setShowCircle]   = React.useState(0)
  const [next, setNext]               = React.useState(0)

  React.useEffect(() => {
      setTimeout(() => {
        setInitialPos(true)
      },10)
  },[setInitialPos])


  React.useEffect(() => {


    if (!change) {
        setTimeout(() => {
          setChange(1)
      },speed)

       setTimeout(() => {
         setChange(2)
      },speed + 500)

      setTimeout(() => {

        setShowCircle(1)

      },speed + 1000)

      setTimeout(() => {

        setNext(1)

      },speed + 2500)

      setTimeout(() => {

        const newGrid = gridIndex >= 2 ? 0 : gridIndex + 1;
        setGridIndex(newGrid)
        setPositionArr(positions[newGrid])
        setGridArr(grids[newGrid])
        setShowCircle(0)
        setNext(0)
        setChange(0)

      },speed + 3500)
    }
  
},[change, gridIndex, setChange, setGridArr, setPositionArr, setShowCircle, setNext])



 
  return (
       <div className = { classes.root }>
          <Grid
            grid       = { gridArr }
            positions  = { positionArr }
            unit       = { unit }
            change     = { change }
            dimensions = { dimensions }
            gridIndex  = { gridIndex }
            showCircle = { showCircle }
            next       = { next }
            initialPos = { initialPos }
          />
       </div>
  );
}

export default ImaginigFractions;

