import React               from 'react';
import { makeStyles }      from 'makeStyles';
import { colors }          from '../../utils/colors';

//var clsx = require('classnames')

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      top:             0,
      left:            0,
      position:        'fixed',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      width:           '100vw',
      height:          '100vh',
      backgroundColor: '#FFF',
      transition:      'opacity 0.6s',
      zIndex:          9,
    },
    button: {
      position:        'fixed',
      top:             70,
      left:            15,
      height:          36,
      width:           160,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           colors.primary,
      backgroundColor: '#FFF',
      cursor:          'pointer',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      borderRadius:    '0.25em',
      zIndex:           4,
      '&:hover': {
         opacity: 0.5
      }
    },
    buttonSmall: {
      position:        'fixed',
      top:             7,
      left:            7,
      height:          30,
      width:           100,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           colors.primary,
      fontSize:        14,
      backgroundColor: '#FFF',
      cursor:          'pointer',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      borderRadius:    '0.25em',
      zIndex:           4,
      '&:hover': {
         opacity: 0.5
      }
    },
}))

type PDFCompProps = {
    src:        string;
    wideScreen: boolean;
    closePDF:   () => void;
}
const PDFComp: React.FC<PDFCompProps> = ({ 
    src,
    wideScreen,
    closePDF
}) => {

  const { classes } = useStyles();


  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setInitialPos(true)
    },10)
  },[])



  const handleClose = () => {
    setInitialPos(false)
    setTimeout(() => {
        closePDF()
    },600)
  }

  return (
  <div
     className = { classes.root }
     style     = {{
      opacity: initialPos ? 1 : 0
    }}
  >
      <iframe 
            title           = "Yisumatica"
            width           = '100%'
            height          = '100%'
            src             = { src }
            frameBorder     = '0'
            allow-scripts   = 'true'
            allowFullScreen
      />
      <div 
            dir       = 'rtl'
            className = { wideScreen ? classes.button : classes.buttonSmall }
            onClick   = { handleClose }
      >
            Go Back
      </div>
  </div>
  );
}




export default PDFComp;

