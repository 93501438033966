
const getUserBrowser = () => {

  let browser = 'not detected';
    
  if (navigator.userAgent.indexOf("Chrome") !== -1 ) {
    browser = "Google Chrome";
  }
  
  else if (navigator.userAgent.indexOf("Firefox") !== -1 ) {
    browser = "Mozilla Firefox";
  }
  
  else if (navigator.userAgent.indexOf("MSIE") !== -1 ) {
    browser = "Internet Exploder";
  }
  
  else if (navigator.userAgent.indexOf("Edge") !== -1 ) {
    browser = "Edge";
  }
  
  else if (navigator.userAgent.indexOf("Safari") !== -1 ) {
    browser = "Safari";
  }
  
  else if (navigator.userAgent.indexOf("Opera") !== -1 ) {
    browser = "Opera";
  }

  else if (navigator.userAgent.indexOf("YaBrowser") !== -1 ) {
    browser = "YaBrowser";
  }
  
  else {
    browser = "Other";
  }

  return browser;
}

export default getUserBrowser;






