
import { call, take, put }  from 'redux-saga/effects';
import { SET_USER_INFO }    from 'actions/userInfo_actions';
import { RECORD_ERROR }     from 'actions';
import axios                from 'axios';

const endpoint = 'https://iseemath.co/api/updateOneTimeEvents.php';

function* updateOneTimeEvents(): Generator<any,any,any> {
  while(true) {
    try {
        
        const input = yield take('UPDATE_ONE_TIME_EVENTS_SAGA');

        const { userEmail, events } = input.payload;

        const payload = JSON.parse(events);

        yield put(SET_USER_INFO({ oneTimeEvents: payload }));
        
        if (process.env.NODE_ENV === 'production') {

            const res = yield call( axios.post, endpoint, { userEmail: userEmail, events: events });

            if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
              yield put(RECORD_ERROR({ userEmail: userEmail, err: res.data.msg, endpoint: 'update_one_time_events' }));
            }

            console.log('res  :  ',res)
           
        }
       

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default updateOneTimeEvents;
