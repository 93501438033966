import React           from 'react';
import { connect }     from 'react-redux';
import { reducerType } from '../../reducer';
import { useNavigate } from "react-router-dom";
import { colors }      from '../../utils/colors';
import { makeStyles }  from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
        height:          50,
        width:           '100%',
        display:         'flex',
        alignItems:      'center',
        textAlign:       'left',
        cursor:          'pointer',
        fontSize:        17,
        fontWeight:      'bold',
        borderRadius:    '0.2em',
        color:           colors.darkText,
        textDecoration:  'none',
        '&:hover': {
           opacity: 0.5
        }
      },
    
}))

type ButtonProps = {
    menuSize:       number;
    variant:        string;
    directPageLink: boolean;
    func:           () => void;
}
const Button: React.FC<ButtonProps> = ({ 
    variant,
    menuSize,
    directPageLink,
    func
}) => {

  const { classes } = useStyles();

  const navigate = useNavigate();

  const rootStyle =  React.useMemo(() => {
   return { 
     width:  menuSize - 60
    }
  },[menuSize])

  const text = React.useMemo(() => {
    if ( variant === 'Go Back' && directPageLink ) {
      return 'All Content'
    }
    return (variant)
  },[directPageLink, variant])


  const goBack = () => {
    if ( directPageLink ) {
      navigate('../Apps', { replace: true });
    }
    else {
      navigate('../', { replace: true });
    }
    func() 
  }
  
  return (
    <>
    {
      variant === 'Go Back' &&
      <div 
          className = { classes.root }
          onClick   = { goBack }
          style     = { rootStyle }
      >
        { text }      
      </div>
    }
    {
      variant !== 'Go Back' &&
      <div 
          className = { classes.root }
          onClick   = { func }
          style     = { rootStyle }
      >
        { text }      
      </div>
    }
    </>
    
  );
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    directPageLink: reducer.components.directPageLink
}};

export default connect(mapStateToProps)(Button);


