import React                    from 'react';
//import { useNavigate }           from "react-router-dom";
import { Dispatch }             from "redux";
import { connect }              from 'react-redux';
import { reducerType }          from '../../../reducer';
import { ActionTypes }          from '../../../actions/types';
import { colors }               from '../../../utils/colors';
import SearchRoundedIcon        from '@mui/icons-material/SearchRounded';
import { makeStyles }           from 'makeStyles';
import { componentsStateType } from '../../../reducer/types';
import { SET_COMPONENTS_STATE } from '../../../actions/component_actions';


var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({      root: {
        marginTop:    8,
        minHeight:    40,
        maxHeight:    40,
       // marginLeft: 30,
        textAlign:    'left',
     //   borderRadius: '10em',
        textDecoration: 'none',
        borderRadius:   '0.5em',
        '&:hover': {
          backgroundColor: colors.brightGrey3,
        }
      },
      selector: {
        minHeight:      40,
        marginLeft:     0,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
      },
      iconContainer: {
        width:           10,
        height:          40,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        transition:      'all 0.3s'
      },
      icon: {
          fontSize:   15,
          color:      colors.menuText
      },
      text_field: {
        height:          '100%',
        marginLeft:      10,
        minHeight:       40,
        width:           '100%',
        fontSize:        18,
        fontFamily:      'Raleway',
        cursor:          'pointer',
        border:          0,
        backgroundColor: 'transparent',
        '&:focus': {
           outline: 0,
           cursor:  'auto',
        }
      },
      dark: {
        color: colors.menuText,
        '&::placeholder': {
          color: colors.menuText,
        }
      },
      bright:{
       // color: colors.hoverText,
        color: colors.menuText,
        '&::placeholder': {
          color: colors.menuText,
      //    color: colors.hoverText,
        }
      },
      effectContainer: {
        marginRight:      '5%',
        width:            '100%',
        height:           1,
        zIndex:           1,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
      },
      effect: {
        borderBottom: `2px solid ${ colors.blue }`,
        transition:   '0.3s'
      },
}))

type SearchBarProps = {
  page:               string;
  menuSize:           number;
  wideScreen:         boolean;
  componentsState:    componentsStateType;
  setOpenItem:        (val: string) => void;
  setChangeContent:   (val: boolean) => void;
  setComponentsState: (val: componentsStateType) => void;
  handleMenuClick:    (variant: string, val: string, disabled: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  page,
  menuSize,
  wideScreen,
  componentsState,
  setOpenItem,
  setComponentsState,
  setChangeContent,
  handleMenuClick
}) => {

  const { classes } = useStyles();

  const [focus, setFocus] = React.useState(0);
  const [hover, setHover] = React.useState(false)

  //const history = useNavigate();

  const rootClick = () => {
    /* if ( history.location.pathname !== '/') {
        history.replace('/');
    } */
    handleMenuClick('search', 'search', false)
  }

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    const selectedContent = page === 'my lessons' ? 'userLessons' :
                            page === 'groupLessons' ? 'groupLessons' :
                            'bigContentArr';
                            //page === 'puzzles' ? 'puzzles' :
                            //page === 'content' ? ( componentsState.selectedContent === 'apps' ? 'apps' : 'lessons' ) :
                            //'bigContentArr';
                            
    setChangeContent(true)
    setComponentsState({
      ...componentsState,
      searchStr:           target.value,
      selectedGrades:      '1234567',
      selectedAppSubTopic: '',
      selectedContent:     selectedContent
    })
    
  }

  const [rootStyle, textStyle, iconContainerStyle, iconStyle] = React.useMemo(() => {

    const emphasize = !focus && componentsState.searchStr.length > 0;

    return [
      {
        width:           menuSize - 40,//(emphasize ? 30 : 40),
        backgroundColor: emphasize ? '#FFF' : undefined,
        border:          `1px solid ${ emphasize ? '#DDD' : 'transparent' }`
      },
      {
        color:      emphasize ? colors.primary : undefined,
        fontWeight: emphasize ? 700 : 400,
        fontSize:   wideScreen ? undefined : 14
      },
      {
        marginLeft: emphasize ? 10 : 0
      },
      {
        color: emphasize ? colors.primary : undefined
      }
    ]
  },[menuSize, componentsState.searchStr, focus, wideScreen])

  


  const on_focus = () => { 
    setFocus(1) 
    setOpenItem('noDisplay')
  }
  const on_blur = () => { setFocus(0) }

  return (
    <div
       className    = { classes.root }
       style        = { rootStyle }
       onClick      = { rootClick }
       onMouseEnter = { () => { setHover(true) }}
       onMouseLeave = { () => { setHover(false) }}
    >
        <div className = { classes.selector }>
            <div 
              className = { classes.iconContainer }
              style     = { iconContainerStyle }
            >
              <SearchRoundedIcon 
                 className = { clsx (classes.icon, ((hover && !focus) ? classes.bright : classes.dark)) }
                 style     = { iconStyle }
              />
            </div>
            <input
                className    = { clsx(classes.text_field, ((hover && !focus) ? classes.bright : classes.dark)) }
                style        = { textStyle }
                type         = "text"
                id           = "code"
                value        = { componentsState.searchStr }
                placeholder  = { focus ? '' : 'Search' }
                onChange     = { changeInputValue }
                onFocus      = { on_focus }
                onBlur       = { on_blur }
                autoComplete = "off"
            />
         </div>
         <div className = { classes.effectContainer } >
            <div
               className = { classes.effect }
               style     = {{
                 width: focus ? '100%' : 0
              }}
            />
         </div>
    </div>
  );
}





const mapStateToProps = (reducer: reducerType) => {

  return {
    componentsState: reducer.components
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setComponentsState: (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);



