import React          from 'react';
import { makeStyles } from 'makeStyles';

const circleSize = 8

const styles = [
    { top: -(circleSize/2), left: -(circleSize/2) },
    { top: -(circleSize/2), right: -(circleSize/2) },
    { bottom: -(circleSize/2), left: -(circleSize/2) },
    { bottom: -(circleSize/2), right: -(circleSize/2) }
];

const useStyles = makeStyles()((Theme) => ({    
    root: {
        position:       'absolute',
        width:          circleSize,
        height:         circleSize,
        borderRadius:   '50%',
        backgroundColor: Theme.primary
    }
}))

type CircleProps = {
    ind: number;
}

const Circle: React.FC<CircleProps> = ({ ind }) => {

  const { classes } = useStyles();

  return (
     <div 
        className  = { classes.root }
        style      = { styles[ind] }
     />
  );
}

export default Circle


