
import { ActionTypes }     from 'actions/types';
import { userStateType }   from 'reducer/types';
import { initialUserInfo } from 'reducer/initialStates';


const initialState: userStateType = {
  newUserCreated:    '',
  userInfo:          initialUserInfo,
  encodedInfo:       { userId: '', timeLimit: '' },
  studentLoginState: { studentName: '', studentClass: '', teacherEmail: '', loginState: '', studentOpenApps: [], myTeacherContent: [] },
};

const userReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {


//SET_USER_INFO   -      -      -      -      -      -      -      -      -      -      -      -


 case 'SET_USER_INFO':
    {
      return {
        ...state,
        userInfo: { 
          ...state.userInfo,
          ...action.payload 
        }
      }
 }



//CREATE_USER   -      -      -      -      -      -      -      -      -      -      -      -


case 'CREATE_USER':
  {
    const { success } = action.payload;

    return {
      ...state,
      newUserCreated: success
    }

  }


//UPDATE_USER_NAME   -      -      -      -      -      -      -      -      -      -      -      -



case 'UPDATE_USER_NAME':
  {


    const { userName, userOrigin } = action.payload;

    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        userName:   userName,
        userOrigin: userOrigin,
      }
    }
  }



  
/* 
case 'SET_SUSPENSION_DATE': {

  const deactivationDate = action.payload;

  return {
      ...state,
      userInfo: {
        ...state.userInfo,
        suspendSubscriptionAt: deactivationDate
      }
  }

} */



case 'SET_STUDENT_OPEN_APPS': {

    const { arr } = action.payload;
  
    const soa = !!arr ? arr : state.userInfo.studentOpenApps;
  
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        studentOpenApps: soa,
      }
    }
  
}
  




//SET_ENCODED_INFO   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_ENCODED_INFO':
  {
    return {
      ...state,
      encodedInfo: action.payload
    }

 }



//UPDATE_TEACHER_CODE   -      -      -      -      -      -      -      -      -      -      -      -


case 'UPDATE_TEACHER_CODE':
  {

    return {
      ...state,
      userInfo: { ...state.userInfo, teacherCode: action.payload }
    }

  }



//STUDENT_LOGIN   -      -      -      -      -      -      -      -      -      -      -      -


case 'STUDENT_LOGIN':

  {

    return {
      ...initialState,
      encodedInfo:       state.encodedInfo,
      studentLoginState: action.payload
    }

  }


  
//RESET_STATE   -      -      -      -      -      -      -      -      -      -      -      -


case 'RESET_STATE':
  {

    return initialState;


  }




// -      -      -      -      -      -      -      -      -      -      -      -




    default: return state;
  }
};

export type reducerType = ReturnType<typeof userReducer>;

export { userReducer };
