import React               from 'react';
import Button              from './Button';
import { contentInfoType } from '../../types';
import { colors }          from '../../utils/colors';
import { sizes }           from '../../utils/defaultStates';
import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
     //  transform:      `translate(0px, -${ sizes.tabSize }px)`,
       height:         '100%',
       borderRight:    '1px solid #CCC',
       backgroundColor: colors.brightGrey,
    },
    description: {
      position:        'absolute',
      overflowY:       'scroll',
      overflowX:       'hidden',
      paddingLeft:     30,
      paddingRight:    30,
      left:            0,
      borderTop:       `1px solid #DDD`,
      lineHeight:      2,
      letterSpacing:   2,
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
      }
    },
    buttonContainer: {
      borderTop:       `1px solid #DDD`,
      width:           '100%',
      left:            0,
      position:        'absolute', 
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'space-around',
      flexFlow:        'column',
    }
}))

type SideBarProps = {
    app:                   contentInfoType;
    size:                  number[];
    menuSize:              number;
    showFAppButton:        boolean;
    showAppLessonsButton:  boolean;
    //showGoToPdf:           boolean;
    handleClose:           () => void;
    goTofather_app:        () => void;
    showAppLessons:        () => void;
    //goToPDF:               (val: string) => void;
}


const SideBar: React.FC<SideBarProps> = ({ 
    app,
    size,
    menuSize,
    showFAppButton,
    showAppLessonsButton,
    //showGoToPdf,
    handleClose,
    goTofather_app,
    showAppLessons,
    //goToPDF
}) => {

  const { classes } = useStyles();


  const [buttonContainerStyle, descriptionStyle] =  React.useMemo(() => {

   const arr          = [showFAppButton, showAppLessonsButton/* , showGoToPdf */];
   const buttonHeight = 39;
   let   totalHeight  = buttonHeight;

   for(let i = 0; i < arr.length; i++) {
      if (!!arr[i]) {
         totalHeight += buttonHeight + 6;
      }
   }

   const buttonContainerHeight = 20 + totalHeight;

   return [
      {
         top:    0,
         height: buttonContainerHeight,
         width:  menuSize,
      },
      {
         top:    showFAppButton ? buttonContainerHeight : buttonContainerHeight,
         height: size[1] - sizes.navBarSize - sizes.footerSize - buttonContainerHeight - 4,
         width:  menuSize - 60,
      }
      
   ]
  },[showFAppButton, showAppLessonsButton/* , showGoToPdf */, size, menuSize])


 /*  const goToPdfFunction = () => {
     goToPDF(app.student_page)
  } */
  
  return (
   
    <div 
       className = { classes.root }
       style     = {{
           width: menuSize
       }}
    >
       <div 
             className = { classes.buttonContainer }
             style     = { buttonContainerStyle }
          >
             <Button
                variant  = { 'Go Back' }
                menuSize = { menuSize }
                func     = { handleClose }
             />
             {
                showFAppButton
                &&
                <Button
                   variant  = { 'Go To Source App' }
                   menuSize = { menuSize }
                   func     = { goTofather_app }
                />
             }
             {
                showAppLessonsButton
                &&
                <Button
                   variant  = { 'Related Lessons' }
                   menuSize = { menuSize }
                   func     = { showAppLessons }
                />
             }
             {/* {
                showGoToPdf 
                &&
                <Button
                   variant  = { 'The Student Page' }
                   menuSize = { menuSize }
                   func     = { goToPdfFunction }
                />
             } */}
          </div>
          {
            (!!app.description_eng)
            &&
            <div 
               className = { classes.description }
               style     = { descriptionStyle }
            >
                <br/>

                {  !!app.description_eng ? app.description_eng : !!app.description ? app.description : '' }
             </div>
          }
          
    </div>
  );
}



export default SideBar;

