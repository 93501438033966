import { call, take }  from 'redux-saga/effects';
import axios           from 'axios';

const getIp = fetch('https://api.ipify.org/?format=json').then(response =>response.json());

const endpoint = 'https://iseemath.co/api/recordUserDevice.php';

function* recordUserDevice(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('RECORD_USER_DEVICE_SAGA');   

        const userIp = yield getIp;
        const ip = userIp.ip ? userIp.ip : '';

        const payload = {
          ...input.payload,
          userIp: ip
        }
       
        if (process.env.NODE_ENV === 'production') {
            yield call( axios.post, endpoint, payload);
        }
        else {
           // console.log(input.payload)
        }

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default recordUserDevice;
