
import { ActionTypes }            from 'actions/types';
import { componentsStateType }    from 'reducer/types';
import { initialComponentsState } from './initialStates';

const initialState: componentsStateType = initialComponentsState;

const componentsReducer = (state = initialState, action: ActionTypes) => {

switch (action.type) {



case 'SET_COMPONENTS_STATE':   
 {
    const newState = action.payload

    return {
      ...newState
    }
}

case 'SHOW_INTRO_VIDEO':  
  {
    return {
      ...initialState,
      selectedContent: 'introVideo',
      openHelp:        true,
      showTutorial:    true
    }
  }

  case 'STUDENT_LOGIN':
  {

    const { loginState } = action.payload;

    return {
      ...initialState,
      selectedContent: loginState === 'success' ? 'studentApps' : state.selectedContent,
      openHelp:        false//loginState === 'success' ? true : state.openHelp
    }

  }

  case 'SET_INITIAL_VIDEO_PAGE': {
    return {
      ...state,
      initialVideoPage: action.payload,
    }
  }

  case 'SET_SHOW_SMALL_MENU': {
    return {
      ...state,
      showSmallMenu: action.payload,
    }
  }

  case 'SET_SHOW_TOPIC_INTRO': {

    return {
      ...state,
      showTopicIntro: action.payload,
      showMenu:       !action.payload,
      directPageLink: false
    }
  }

  case 'SET_SHOW_PRACTICE_COMPONENT': {

    return {
      ...state,
      showPracticeComp: action.payload,
      directPageLink:   false
    }
  }

  case 'SET_FOLLOW_LINK':
      {
        return {
          ...state,
          followLink: action.payload
        }

      }

  


// -      -      -      -      -      -      -      -      -      -      -      -




    default: return state;
  }
};

export type reducerType = ReturnType<typeof componentsReducer>;

export { componentsReducer };
