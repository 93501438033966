import { store } from '../../index';

const getFatherApp = (father_app: string) => {
   
    const state   = store.getState();
    const appsArr = state.content.apps;

    let res: { [key: string]: any } = { lesson_id: 'no' };

    for (let i = 0; i < appsArr.length; i++) {
        if (appsArr[i].lesson_id === father_app) {
                res = appsArr[i];
                break;
        }
    }

    return res;

    
}


export default getFatherApp;








