import React           from 'react';
import Milim           from './Milim';
import Title           from './Title';
import { makeStyles }  from 'makeStyles';

const useStyles = makeStyles()(() => ({   
    root: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'space-between',
        padding:        10
    },
    image: {
        border:             '1px solid #CCC',
        minHeight:          120,
        minWidth:           120,
        maxHeight:          120,
        maxWidth:           120,
        borderRadius:       '50%',
        backgroundPosition: 'center',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat',
    }
}))


type MishehuProps = {
    variant:    string;
    wideScreen: boolean;
}
 
const Mishehu: React.FC<MishehuProps> = ({
    variant,
    wideScreen
  }) => {


  const { classes } = useStyles();

  const [rootStyle] = React.useMemo(() => {
     return[
        {
            flexFlow:     wideScreen ? 'row' : 'column',
            marginTop:    wideScreen ? undefined : 10,
            marginBottom: wideScreen ? undefined : 10,
        }
     ]
  },[wideScreen])

  return (       
       <div 
           className = { classes.root }
           style     = { rootStyle }
       >  
           {
              wideScreen && variant === 'zvi' && <Milim variant = { variant } wideScreen = { wideScreen } />
           }
           {
            !wideScreen && <Title variant = { variant }  wideScreen = { wideScreen } />
           }
          
           <div 
             className = { classes.image }
             style     = {{ backgroundImage: `url(https://iseemath.co/images/${ variant }.${ variant === 'gali' ? 'png' : 'jpg' })`}}
           />
           {
              (variant !== 'zvi' || !wideScreen) && <Milim wideScreen = { wideScreen } variant = { variant }/>
           }
       </div> 
  );
}


export default Mishehu;
