import React          from 'react';
import Rect           from './Rect';
import { makeStyles } from 'makeStyles';

const speed = 2500;

const useStyles = makeStyles()((Theme) => ({    
  root: {
        width:     '100%',
        height:    '100%',  
        animation: 'simple_rotation 20s linear infinite',
    }
}))

type FinsTheShapeProps = {
    dimensions: number;
}

const FinsTheShape: React.FC<FinsTheShapeProps> = ({ dimensions }) => {

  const { classes } = useStyles();

  const [ counter, setCounter ] = React.useState(2);

  React.useEffect(() => {

    setTimeout(() => { setCounter(0)}, 10)

  },[setCounter])



  React.useEffect(() => {

    if (!counter) {
        setTimeout(() => {
          setCounter(1)
        },speed)

        setTimeout(() => {
          setCounter(2)
       },speed * 2)

       setTimeout(() => {
          setCounter(0)
       },speed * 3)
    }

},[counter, setCounter])

  return (

    <div className  = { classes.root }>
        {
            new Array(3).fill(0).map((val, ind) => {
                return(
                    <Rect 
                      key        = { `rect_${ind}` }
                      ind        = { ind }
                      len        = { !ind ? 4 : 3 }
                      counter    = { counter }
                      dimensions = { dimensions }
                      speed      = { speed }
                    />
                )
            })
        }
    </div>
  );
}

export default FinsTheShape


