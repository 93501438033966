
import { Auth }                  from 'aws-amplify';
import { store }                 from 'index';  
import { SET_ERROR }             from 'actions/auth_actions';
import { UPDATE_USER_NAME_SAGA } from 'actions';
import { SET_MODAL_STATE }       from 'actions/modal_actions'

import recordEvent               from 'functions/contentInteraction/record_event'


async function updateUser(name: string) {
  try {   
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:name': name
    });   
  }
    catch(err) {
      console.log(err)
  }
  finally {
  }
  
}

const handleUpdateUserName = ( userEmail: string ) => {

    const state                = store.getState();
    const authState            = state.auth;
    const { name, userOrigin } = authState;

    if ( !name.length ) {
      store.dispatch(SET_ERROR('please enter a name'));
      return;
    }
    const payload = {
      userName:   name,
      userEmail:  userEmail,
      userOrigin: userOrigin
    }

    store.dispatch(UPDATE_USER_NAME_SAGA(payload));

    store.dispatch(SET_MODAL_STATE({
      variant:      '',
      open:         false,
      showButtons:  false
    }));

    updateUser(name);

    recordEvent('registration', 'user completed signup', 'UPDATE_USER_NAME_SAGA')
    
}

export default handleUpdateUserName;