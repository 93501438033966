import React                   from 'react';
import { Dispatch }            from "redux";
import { ActionTypes }         from 'actions/types';
import { connect }             from 'react-redux';
import { reducerType }         from 'reducer';
import NavBar                  from './NavBar';
import Title                   from './title';
import Numbers                 from './numbers';
import Sirton                  from './sirton';
import Sirton2                 from './sirton2';
import About                   from './about';
import Feedback                from './feedback';
import Button                  from 'pages/home/Button';
import Footer                  from 'components/Footer'; 
import recordEvent             from 'functions/contentInteraction/record_event';
import navBarClick             from 'functions/navBar/nav_bar_click';
import { makeStyles }          from 'makeStyles';
import { SET_MODAL_STATE }     from 'actions/modal_actions'


type nbar = 'fixed' | 'relative';
const navbars: nbar[] = [ 'fixed', 'relative'];

const useStyles = makeStyles()((Theme) => ({    
    root: {
        position:        'absolute',
        backgroundColor: '#FFF',
        overflowY:       'scroll',
        overflowX:       'hidden',
        '&::-webkit-scrollbar': {
          width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#CCC',
          outline:         '1px solid #FFF',
          borderRadius:    10
        }
    },
    button: {
        paddingBottom:  50,
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        zIndex:         99
    }
}))
 
type homeProps = {
  size:               number[];
  page:               string;
  subscriber:         boolean;
  userSignedIn:       boolean;
  denyAccess:         boolean;
  setChangeContent:   (val: boolean) => void;
  setShowTOS:         (val: boolean) => void;
  setShowPrivacy:     (val: boolean) => void;
  setModalState:      (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
}
 
const Home: React.FC<homeProps> = ({ 
    size,
    page,
    subscriber,
    userSignedIn,
    denyAccess,
    setChangeContent,
    setShowTOS,
    setShowPrivacy,
    setModalState
  }) => {

  const { classes } = useStyles();

  const wideScreen = React.useMemo(() => {
    return size[0] > 1150
  },[size])

  const [showFixedNavBar, setShowFixedNavBar] = React.useState(false)
  const [animateNumbers, setAnimateNumbers]   = React.useState(!wideScreen)

  const hideNavbar = React.useMemo(() => {
    return subscriber && userSignedIn
  },[subscriber, userSignedIn])

  const contentRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = () => {
  
      if (contentRef && contentRef.current) {
            
          if ( contentRef.current.scrollTop > size[1]/2 - 50 ) {
            setAnimateNumbers(true)
          } 
          if ( contentRef.current.scrollTop > 250 ) {
            setShowFixedNavBar(true)
          } 
          else {
              if (showFixedNavBar) { 
                setShowFixedNavBar(false) 
              }
          }
      }
  }


  const getStarted = () => {
    if ( !denyAccess && userSignedIn ) {//
        setModalState({
          variant:     'subDetails', 
          open:        true, 
          showButtons: true
        })
    }
    else {
      setModalState({
        variant:     'selectPlan', 
        open:        true, 
        showButtons: true
      })
    }
    recordEvent('registration', 'homepage get started button clicked', 'homepage_get_started')
  } 

  const handleNavBarClick = (val: string) => {
    navBarClick( val, setChangeContent )
  }

  const rootStyle = React.useMemo(() => {
      return{
          height: size[1],
          width:  size[0]
      }
  },[size])


  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
       ref       = { contentRef }
       onScroll  = { handleScroll }
    >
       {
           navbars.map((val) => {
           return(
            <NavBar
               key                = { `${ val }*&` }
               size               = { size }
               variant            = { val }
               subscriber         = { subscriber }
               hideNavbar         = { hideNavbar }
               userSignedIn       = { userSignedIn }
               wideScreen         = { wideScreen }
               showFixedNavBar    = { showFixedNavBar }
               getStarted         = { getStarted }
            /> 
           )
         })
       } 
       <Title
          enableAudio       = { !userSignedIn }
          wideScreen        = { wideScreen }
          handleNavBarClick = { handleNavBarClick }
       />
       <Numbers 
            size           = { size }
            wideScreen     = { wideScreen }
            animateNumbers = { animateNumbers }
       />
       <Sirton
            size       = { size }
            wideScreen = { wideScreen }
        />
       <Sirton2 
            wideScreen = { wideScreen }
       />
       <About 
            wideScreen = { size[0] > 650 }
       />
       <Feedback 
            size       = { size }
            wideScreen = { wideScreen }
       />
       <div className = { classes.button }>
              <Button 
                  variant           = { 'goToApps' }
                  enableAudio       = { false }
                  wideScreen        = { wideScreen }
                  handleNavBarClick = { handleNavBarClick }
              />
       </div>
       <Footer
          size           = { size }
          page           = { page }
          setShowTOS     = { setShowTOS }
          setShowPrivacy = { setShowPrivacy }
       />
    </div>
  );
}




const mapStateToProps = (reducer: reducerType) => {

  return {
}};


const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setModalState: (payload: { variant: string, open: boolean, showButtons: boolean }) => dispatch(SET_MODAL_STATE(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);





