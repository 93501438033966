import { userAttributesType }     from '../../types';
import removeSubscribePrompt      from './remove_subscribe_prompt';
import {
  createSubscriptionType, 
 //recordEventType 
}                                 from '../../actions/types';

const handleSubscribe = (
    data:               any,
    email:              string,
  // planType:           string,
    userAttributes:     userAttributesType,
    setShowPayForm:     (val: boolean) => void,
    createSubscription: (payload: createSubscriptionType) => void,
   // recordEvent:        (payload: recordEventType) => void,
) => {


  //let subEmail = localStorage.getItem('subscriberEmail') ? localStorage.getItem('subscriberEmail') : authData ? authData.attributes.email : email;
    let subEmail = email;

    if ( localStorage.getItem('subscriberEmail') ) {
      subEmail = localStorage.getItem('subscriberEmail') || '';
    }

    else if ( userAttributes.userSignedIn === 'true' ) {
      subEmail = userAttributes.userEmail
    }
        
    if (!subEmail) {
      subEmail = '';
    }

    if (subEmail.length === 0) {
      console.log('noEmail!')
      return;
    }

    let plan             = localStorage.getItem('selectedPlan');
    let subscriptionType = plan && ( plan === 'Monthly' || plan === 'Annually' || plan === 'testPlan' ) ? plan : ''; 
    let paymentId        = '';
    let orderId          = '';

    

       if ( data.paymentId && data.paymentId.length && data.orderId && data.orderId.length ) {
        //  subscriptionType = 'live';
          paymentId        = data.paymentId;
          orderId          = data.orderId;
       }

       const { billingToken, subscriptionID } = data;

       console.log('data.paymentId    :   ',data.paymentId)
       console.log('data.orderId      :   ',data.orderId)
       console.log('billingToken      :   ',billingToken)
       console.log('subscriptionID    :   ',subscriptionID)
       console.log('subscriptionType  :   ',subscriptionType)
       console.log('subEmail          :   ',subEmail)
       
       const payload = {
          orderId:          orderId,
          billingToken:     billingToken,
          subscriptionID:   subscriptionID,
          paymentId:        paymentId,
          userEmail:        subEmail,
          subscriptionType: subscriptionType
       }

       removeSubscribePrompt();

       setShowPayForm(false);
       createSubscription(payload);

     //  recordEvent(recordEventPayload);
}


export default handleSubscribe;