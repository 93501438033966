
import { componentsStateType, pageStateType} from '../../reducer/types'


const searchBarClick = (
    pageState:      pageStateType,
    filterState:    componentsStateType,
    setFilterState: (val: componentsStateType) => void,
) => {

      setFilterState({
        ...filterState,
        selectedGrades:          '1234567',
        selectedAppTopic:        '',
        selectedAppSubTopic:     '',
      //  selectedContent:         'apps',
        openGradeGrid:           false,
        showTopicIntro:          false,
        showAppIntro:            false,
        showTutorial:            false,
        showStudentVideo:        false,
        selectedVideo:           -1,
        createTopicArr:          false
      })
}

export default searchBarClick;