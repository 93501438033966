import { store }           from '../../index';
import recordEvent         from 'functions/contentInteraction/record_event';
import { SET_AUDIO_STATE } from 'actions/audio_actions';
import { audioStateType }  from 'reducer/types';

  
  export const handlePlay = (AudioRef: any) => { 

    if (AudioRef && AudioRef.current) {
        AudioRef.current.play(); 
    }

    const state         = store.getState();
    const audioState    = state.audio;
    const setAudioState = (payload: audioStateType) => store.dispatch(SET_AUDIO_STATE(payload));

    setAudioState({ 
      ...audioState, 
      playAudio:  true,
      pauseAudio: false,
    }) 
    recordEvent('audio', 'audio played', audioState.audioSrc)
  }
  

  export const handlePause = (AudioRef: any) => { 

    const state         = store.getState();
    const audioState    = state.audio;
    const setAudioState = (payload: audioStateType) => store.dispatch(SET_AUDIO_STATE(payload));

    if (AudioRef && AudioRef.current) {
      AudioRef.current.pause();
    }
    setAudioState({ 
      ...audioState, 
      pauseAudio: true,
    }) 
  }



  export const handleReplay = (AudioRef: any) => {
    if (AudioRef && AudioRef.current) {
      AudioRef.current.currentTime = 0;
    }
  }


  export const handleStop = (AudioRef: any) => {
    if (AudioRef && AudioRef.current) {
        AudioRef.current.currentTime = 0;
        AudioRef.current.pause();
        handlePause(AudioRef);
       // handleEnd()
    }
}

 
export const onEnd = () => {
    const state         = store.getState();
    const audioState    = state.audio;
    const setAudioState = (payload: audioStateType) => store.dispatch(SET_AUDIO_STATE(payload));
    setAudioState({ 
        ...audioState, 
        playAudio:  false,
        pauseAudio: true,
    }) 
}

 
