import { store }           from 'index';
import { SET_MODAL_STATE } from 'actions/modal_actions'
import recordEvent         from 'functions/contentInteraction/record_event'


const goToPayForm = ( setShowPayForm: (val: boolean) => void ) => {

    const state      = store.getState();
    const modalState = state.modal;

    store.dispatch(SET_MODAL_STATE({
      variant:     modalState.modalVariant,
      open:        false,
      showButtons: true
    }))

    setShowPayForm(true);
    recordEvent('registration', 'payform open', 'ShowPayForm')
}

export default goToPayForm;