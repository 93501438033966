import { useMemo }       from 'react';
import { pageStateType } from 'types';

const useGetDropDownItems = (
  pageState:       pageStateType,
  showSelectors:   boolean,
  studentLoggedIn: boolean,
  guest:           boolean,
  subscriber:      boolean,
) => {


  const [selectors, dropDownitems] = useMemo(() => {

    const aboutToggle    = (pageState.page === 'about us' ? 'apps' : 'about us')
    const selectors1     = studentLoggedIn ? [] : ['introduction video', 'video tutorials', 'contact us'];
    const extraSelectors = studentLoggedIn ? ['join lesson', 'about us','log out'] :
                           guest ? ['join lesson', aboutToggle] : 
                           (!guest && !subscriber) ? ['join lesson', 'about us','log out'] : 
                           ['join lesson', aboutToggle, 'my account', 'log out'];

    const arr            = showSelectors ? [] : selectors1;

   return [
     selectors1,
     arr.concat(extraSelectors)
   ]

 },[guest, pageState, subscriber, showSelectors, studentLoggedIn ])

 return { selectors, dropDownitems }
}


export default useGetDropDownItems;

