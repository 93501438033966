import React, { Suspense } from 'react';
import { Dispatch }        from "redux";
import { connect }         from 'react-redux';
import { ActionTypes }     from 'actions/types';
import { reducerType }     from 'reducer';
import Loader              from 'components/Loader';
import AppIframe           from 'components/appIframe'
import { 
          contentInfoType,
          userInfoType,
       }                   from 'types'
import { colors }          from 'utils/colors';
import { sizes }           from 'utils/defaultStates';
import { makeStyles }      from 'makeStyles';
import { SELECT_CONTENT }  from 'actions/content_actions';
 
const Grid = React.lazy(() => import('./Grid'));


const useStyles = makeStyles()((Theme) => ({    
      root: {
      position:        'absolute',
      left:            0,
      top:             0,
      height:          '100vh',
      width:           '100vw',
      backgroundColor: colors.brightGrey,
    },
    fallBack: {
      position:        'absolute',
      right:           0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'transparent',
    }
}))

type StudentGridProps = {
  allContent:            contentInfoType[];
  userInfo:              userInfoType;
  studentOpenApps:       string[];
  page:                  string;
  size:                  number[];
  menuSize:              number;
  wideScreen:            boolean;
  showLoader:            boolean;
  showHelp:              boolean;
  denyAccess:            boolean;
  selectedApp:           contentInfoType;
  setSelectedApp:        ( val: contentInfoType ) => void;
  selectContent:         (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
  addStudentOpenApps:    ( val: contentInfoType ) => void;
  removeStudentOpenApps: ( val: contentInfoType ) => void;
}

const StudentGrid: React.FC<StudentGridProps> = ({
  allContent,
  userInfo,
  studentOpenApps,
  page,
  size,
  menuSize,
  wideScreen,
  showLoader,
  showHelp,
  selectedApp,
  denyAccess,
  setSelectedApp,
  selectContent,
  addStudentOpenApps,
  removeStudentOpenApps,
}) => {

  const { classes } = useStyles();

  return (
         <div className = { classes.root }> 
            {
              !showLoader
              &&
              <Suspense 
                fallback = {
                  <div 
                    className = { classes.fallBack }
                    style     = {{
                      top:    sizes.navBarSize,
                      width:  size[0] - menuSize,
                      height: size[1] - sizes.navBarSize - sizes.footerSize
                    }}
                  >
                    <Loader/>
                  </div>
                }
              >
                  <Grid
                    allContent            = { allContent }
                    userInfo              = { userInfo }     
                    size                  = { size }   
                    studentOpenApps       = { studentOpenApps }
                    page                  = { page }     
                    menuSize              = { menuSize }  
                    wideScreen            = { wideScreen } 
                    showHelp              = { showHelp }
                    selectContent         = { selectContent } 
                    addStudentOpenApps    = { addStudentOpenApps }
                    removeStudentOpenApps = { removeStudentOpenApps }
                  />
              </Suspense> 
            }
            {
              showLoader
              &&
              <div 
                    className = { classes.fallBack }
                    style     = {{
                      width:  wideScreen ? size[0] - menuSize : size[0],
                      height: size[1] - sizes.navBarSize - sizes.footerSize,
                    }}
              >
                <Loader/>
              </div>
            }
            <AppIframe
                size           = { size }
                selectedApp    = { selectedApp }
                guest          = { denyAccess }
                setSelectedApp = { setSelectedApp }
            />
         </div>
  );
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    selectedApp: reducer.content.selectedApp
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setSelectedApp: (payload: contentInfoType) => dispatch(SELECT_CONTENT(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentGrid);

