import React          from 'react';
import Checkbox       from '@mui/material/Checkbox';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../../utils/colors';


const useStyles = makeStyles()(
  (Theme) => ({ root: {
  paddingRight:   10,
  marginTop:      35,
  display:        'flex',
  alignItems:     'center',
  justifyContent: 'center',
  flexFlow:       'column',
  fontSize:       15
 },
 text_row: {
    display: 'flex',
 },
 switch: {
 //  color:'#0DD'
 },
 tos: {
    marginLeft: 7,
    cursor:     'pointer',
    color:      colors.primary,
    fontWeight: 'bold',
    '&:hover': {
        opacity: 0.5
    }
 }
}));

type TosCheckBoxProps = {
    size:           number[];
    tosChecked:     boolean;
    setShowTOS:     (val: boolean) => void;
    setShowPrivacy: (val: boolean) => void;
    setTosChecked:  (val: boolean) => void;
}

const TosCheckBox: React.FC<TosCheckBoxProps> = ({
    size,
    tosChecked,
    setShowTOS,
    setShowPrivacy,
    setTosChecked
  }) => {

  const { classes } = useStyles();

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTosChecked(event.target.checked);
  };

  const [rowStyle, rowStyle2, row1] = React.useMemo(() => {

    return [
      {
         fontSize: size[0] > 400 ? 15 : 12,
      },
      {
        fontSize: size[0] > 400 ? 15 : 12,
        transform: 'translate(0px,-5px)'
      },
      {
        transform: 'translate(0px,12px)'
      }
      ]
  },[size])

  return (
    <div className = { classes.root }>
        <div 
            className = { classes.text_row }
            style     = { rowStyle }
        >
          <Checkbox
            checked    = { tosChecked }
            onChange   = { handleCheck }
            color      = "primary"
            inputProps = {{ 'aria-label': 'primary checkbox' }}
        />
            <div style = { row1 }>
                I agree to I-SEE-MATH`s
            </div>
            <div 
              className = { classes.tos }
              style     = { row1 }
              onClick   = { () => { setShowTOS(true) }}
            >
                Terms Of Service
            </div>
        </div>
        <div 
            className = { classes.text_row }
            style     = { rowStyle2 }
        >
            <div style = {{ marginLeft: 5 }}>
                and
            </div>
            <div 
                className = { classes.tos }
                onClick   = { () => { setShowPrivacy(true) }}
            >
                Privacy Policy
            </div>
        </div>
    </div>
  )
}




export default TosCheckBox;







