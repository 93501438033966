const arr = [
    [
       `1. Preface: I-SEE-MATH is a website containing apps that offer visualizations which make math more accessible and enjoyable for students.`,
       `It also offers apps that allow the students to practice independently.`,
       `You may enjoy these materials according to the following terms of service.`
    ],
    [
        `2. The content in I-SEE-MATH may be changed and updated and the terms of use changed without a dedicated notification.`
    ],
    [
        `3. All apps, videos, and downloadable material are the intellectual property of I-SEE-MATH.`,
        `They are only for the private use of the teacher or student and may not be distributed, advertised, or imitated without the explicit consent of I-SEE-MATH.`,
        `Furthermore, the apps or images from the apps cannot be used in creating of material such as worksheets or video tutorials without the explicit consent of I-SEE-MATH.`
    ],
    [
        `4. We do our best to make the site and the content constantly available but there may be technical issues or problems with a third-party, or other issues that may cause a disturbance in the availability of the website.`,
        `Therefore, we cannot guarantee that the site will be available at all times.`,
        `There can be no financial or other compensation for unavailability of the site.` 
    ],
    [
        `5. External links: I-SEE-MATH do not guarantee that sites who are linked to from the I-SEE-MATH website are safe, trustworthy, or of high quality.`,
        `Visiting them is in the purview of your judgement and your exclusive responsibility.`
    ],
    [
        `6. The site management retains the right to block any user.`,
        `This may also be done by blocking an IP address, the MACID address of a computer or even the origin country without giving a reason acceptable to the user.`
    ],
    [
        `7. The information given on registration will not be disclosed to a third party.`,
        `In the event that I-SEE-MATH is sold or undergoes a merger, the information will be shared with the purchasing or merging company.`,
        `Since this is an online site, I-SEE-MATH cannot absolutely guarantee exposure of the information by an illegal cyber-attack and will not provide the user compensation in such a case.`,
        "Credit card information is NOT saved on I-SEE-MATH's server."
    ],
    [
        `8. The site may request your permission from time to time to send you marketing information to your email address.`,
        `In this case you will always have the option of removing yourself from the mailing list.`
    ],
    [
        `9. The site my use "local storage" and "cookies" as well as internal statistics in order to track usage patterns such as time spent on different pages.`
    ],
    [
        `10. In case of a judicial dispute, you agree that all of the above is under the exclusive jurisdiction of Israeli law using the Israeli judicial system of Jerusalem.` 
    ]
]

export default arr;