import React                            from 'react';
import { connect }                      from 'react-redux';
import { reducerType }                  from 'reducer';
import { Dispatch }                     from "redux";
import { ActionTypes }                  from 'actions/types';
import goToFatherApp                    from 'functions/contentInteraction/go_to_father_app';
import showAppLessons                   from 'functions/contentInteraction/show_app_lessons';
import recordEvent                      from 'functions/contentInteraction/record_event'


import TopBar                           from './TopBar';
import SideBar                          from './SideBar';
import BottomBar                        from './BottomBar';
import Container                        from './Container';
import PDFComp                          from './PDFComp';
import { 
  contentInfoType, 
  pageStateType, 
  pdfStateType
}                                       from 'types';
import { colors }                       from 'utils/colors';
import { sizes }                        from 'utils/defaultStates';

import getFatherApp                     from 'functions/contentInteraction/get_father_app'

import { makeStyles }                   from 'makeStyles';
import { componentsStateType }          from 'reducer/types';
import { SET_COMPONENTS_STATE }         from 'actions/component_actions';
import { SELECT_CONTENT } from 'actions/content_actions';

const initialPDFState = {
  showPDF: false,
  src:     ''
}


const useStyles = makeStyles()(
  (Theme) => ({    root: {
      display:         'flex',
      width:           '100vw',
      left:            0,
      position:        'fixed',
      backgroundColor:  colors.brightGrey,
      transition:      'opacity 0.6s',
    //  zIndex:          2
    }
}))


type AppIntroProps = {
    componentsState:       componentsStateType;
    pageState:             pageStateType;
    //modalState:            modalStateType;
    app:                   contentInfoType;
    size:                  number[];
    menuSize:              number;
    freeApp:               string;
    wideScreen:            boolean;
    activityUpdated:       boolean;
    denyAccess:            boolean;
    studentLoggedIn:       boolean;
    PDFState:              pdfStateType;
    setPDFState:           (val: pdfStateType) => void;
    setChangeContent:      (val: boolean) => void;
    //setModalState:         (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
    setComponentsState:    (payload: componentsStateType) => void;
    selectContent:         (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
    setSelectedContent:    (payload: contentInfoType) => void;
}


const AppIntro: React.FC<AppIntroProps> = ({ 
    componentsState,
    app,
    pageState,
    size,
    menuSize,
    freeApp,
    wideScreen,
    activityUpdated,
    denyAccess,
    studentLoggedIn,
    PDFState,
    setPDFState,
    setChangeContent,
    setComponentsState,
    setSelectedContent,
    selectContent
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(componentsState.disabledFadeIn)

  React.useEffect(() => {
    const timeOut = setTimeout(() => {
      setInitialPos(true)
    },10)

    return () => { clearTimeout(timeOut) }
  },[])
  
  const handleGoToFatherApp = () => {
    goToFatherApp(app, selectContent)
  }

  const [showIframe, showImage] = React.useMemo(() => {

    const valIsAnApp       = app.origin   && app.origin === 'apps';
    const valIsALesson     = app.origin   && app.origin === 'lessons';
    const showAppIframe    = valIsAnApp   && !!app.youtube_eng && app.youtube_eng !== 'hide';
    const showLessonIframe = valIsALesson && !!app.youtube;

    return[
      pageState.page === 'content' && (showAppIframe || showLessonIframe),
      !showAppIframe && !showLessonIframe
    ]
  },[pageState, app])



  const [showFAppButton, showGoToPdf, showAppLessonsButton] = React.useMemo(() => {

    if (studentLoggedIn) {
      return [false, false, false];
    }

    let showFather = false,
        showPdf    = app.origin === 'lessons' && !!app.student_page && !!app.student_page.length

    if (app.origin === 'lessons' && !!app.father_app) {

      const fApp = getFatherApp(app.father_app);

      if ( !!fApp && !!fApp.lesson_id && fApp.lesson_id !== 'no') {
        showFather = true;
      }
    }

    const showAlessons = !!componentsState.appLessons.length;

    return[showFather, showPdf, showAlessons]

 },[studentLoggedIn, app, componentsState])




  const descriptionHeight = React.useMemo(() => {
    return wideScreen ? 0 : 155
  },[wideScreen])    



  const [rootHeight, width, containerHeight] = React.useMemo(() => {

    const { navBarSize, tabSize, footerSize } = sizes;

    const titleHeight = 70;
    const smallDescriptionHeight = 229;

    const W = wideScreen ? size[0] - menuSize - 1 : size[0];
    //const H = wideScreen ? ( size[1] - (titleHeight * 2) - navBarSize - tabSize - footerSize) : (size[1] - titleHeight - smallDescriptionHeight - navBarSize - tabSize - footerSize);
    const H = wideScreen ? ( size[1] - (titleHeight * 2) - navBarSize - tabSize - footerSize) : (size[1] - titleHeight - smallDescriptionHeight - navBarSize - tabSize - footerSize);

    return [
     size[1] - navBarSize - footerSize - 2,
     W,
     H
    ]
  },[size, menuSize, wideScreen])


  const handleClose = () => {

    recordEvent('app', `app intro closed`, componentsState.appUrl)

    setInitialPos(false)
    const { directPageLink, selectedContent } = componentsState;
    setTimeout(() => {
      if ( directPageLink ) {
        setChangeContent(true)
      }
      setComponentsState({
        ...componentsState,
        appLessons:      [],
        showAppIntro:    false,
        showMenu:        true,
        directPageLink:  false,
        selectedContent: directPageLink ? 'apps' : selectedContent
      })
      setSelectedContent({
        selectedApp: {}
      })
    },50)


  }


  const goToPDF = (src: string) => {

    const url = `https://www.yisumatica.org.il/flash-upload/english_lessons/${ src }.pdf`;

    setPDFState({
      showPDF: true,
      src:     url
    })
  }

  const closePDF = () => {
    recordEvent('app', `student worksheet closed`, componentsState.appUrl)
    setPDFState(initialPDFState)
  }
  
  return (
  <div
     className = { classes.root }
     style     = {{
      top:     sizes.navBarSize,
      height:  rootHeight,
      opacity: initialPos ? 1 : 0
    }}
  >
      {
        wideScreen
        &&
        <SideBar
           app                   = { app }
           size                  = { size }
           menuSize              = { menuSize }
           showFAppButton        = { showFAppButton }
           showAppLessonsButton  = { showAppLessonsButton }
           handleClose           = { handleClose }
           goTofather_app        = { handleGoToFatherApp }
           showAppLessons        = { showAppLessons }
        />
      }
      <div style = {{ width: width }}>
        <TopBar
            size            = { size }
            menuSize        = { menuSize }
            app             = { app }
            wideScreen      = { wideScreen }
            activityUpdated = { activityUpdated }
            denyAccess      = { denyAccess }
            showFAppButton  = { showFAppButton }
            showGoToPdf     = { showGoToPdf }
            handleClose     = { handleClose }
            goTofather_app  = { handleGoToFatherApp }
            goToPDF         = { goToPDF }
 
        />
        <Container
           app               = { app }
           size              = { size }
           containerHeight   = { containerHeight }
           descriptionHeight = { descriptionHeight }
           width             = { width }
           wideScreen        = { wideScreen }
           showIframe        = { showIframe }
           showImage         = { showImage }
        />
        <BottomBar
           app                 = { app }
           freeApp             = { freeApp }
           wideScreen          = { wideScreen }
           size                = { size }
           width               = { width }
           descriptionHeight   = { wideScreen ? 0 : showIframe ? descriptionHeight : 155 }
           containerHeight     = { containerHeight }
           denyAccess          = { denyAccess }
           studentLoggedIn     = { studentLoggedIn }
           showGoToPdf         = { showGoToPdf }
           goToPDF             = { goToPDF }
        />
        {
           PDFState.showPDF
           &&
           <PDFComp
              src        = { PDFState.src }
              wideScreen = { wideScreen }
              closePDF   = { closePDF }
           />
       }
      </div>
  </div>
  );
}






const mapStateToProps = (reducer: reducerType) => {

  return {
    pageState:       reducer.page,
    componentsState: reducer.components,
}};


const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setComponentsState: (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload)),
  setSelectedContent: (payload: contentInfoType) => dispatch(SELECT_CONTENT(payload))
});



export default connect(mapStateToProps, mapDispatchToProps)(AppIntro);

