
const reload = () => {
  
    const url_string = window.location.href;
  
    let newLocation = 'https://iseemath.co/';
  
    if ( process.env.NODE_ENV === 'development' ) {
      newLocation = 'http://localhost:3000/';
    }
  
    if ( url_string.includes('http://www.stg.iseemath.co') ) {
      newLocation = 'http://www.stg.iseemath.co';
    }
  
    window.location.assign(newLocation);
  
}

export default reload;