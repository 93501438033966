import { topicsObjType, appTopicsArrType } from '../../types'

const ignore: string[] = [] 

const ceateTopicArr = (appsArr: { [key: string]: any }, selectedGrades: string) => {

       let obj: topicsObjType = { 
           "שלמים": [], 
           "שבר": [], 
           "גיאומטרייה": [], 
           "נושאים נוספים": [] 
       };
       let arr = Object.keys(obj);

       let appTopicsArr: appTopicsArrType[] = [];

        for (let i = 0; i < appsArr.length; i++) {

            const classIsSelected = selectedGrades === '1234567' || ( appsArr[i].classes.includes(selectedGrades));

            for (let j = 0; j < arr.length; j++) {

               if (appsArr[i].top_topic && appsArr[i].top_topic === arr[j]) {

                   const mainTopic = appsArr[i].top_topic;

                   if (
                       classIsSelected && 
                       appsArr[i].topic.length > 0 &&
                       !obj[mainTopic].includes(appsArr[i].topic) &&
                       !ignore.includes(appsArr[i].topic) 
                     ) {
                       obj[mainTopic].push(appsArr[i].topic);
                   }
                   break;  
               }
               if (
                    classIsSelected &&
                    !!appsArr[i].topic &&
                    !!appsArr[i].topic.length && 
                    !ignore.includes(appsArr[i].topic) &&
                    (j === arr.length - 1) && 
                    !obj["נושאים נוספים"].includes(appsArr[i].topic)
                  ) {
                      obj["נושאים נוספים"].push(appsArr[i].topic);
               }
            }
       }

       for (let k = 0; k < arr.length; k++) {

        const subTopics = obj[arr[k]];

          if (subTopics.length) {

             if ( subTopics.includes('אחר') ){
                subTopics.splice(subTopics.indexOf('אחר'),1);
                subTopics.push('אחר')
             }
 
             if ( subTopics.includes('מגוון נושאים במספרים שלמים') ){
                subTopics.splice(subTopics.indexOf('מגוון נושאים במספרים שלמים'),1);
                subTopics.push('מגוון נושאים במספרים שלמים')
             }
 
             appTopicsArr.push({
                 mainTopic: arr[k], 
                 subTopics: subTopics
             })
          }
       }

       return appTopicsArr;
    
}


export default ceateTopicArr;










