import React               from 'react';
import TopBar              from './TopBar';
import BottomBar           from './BottomBar';
import SideBar             from './SideBar';
import Container           from './Container';
import { contentInfoType } from '../../types';
import { colors }          from '../../utils/colors'
import { sizes }           from '../../utils/defaultStates'

import { makeStyles }      from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
      display:         'flex',
      width:           '100vw',
      left:            0,
      position:        'fixed',
      backgroundColor:  colors.brightGrey,
      transition:      'opacity 0.6s',
     // zIndex: 2
    }
}))

type TopicIntroProps = {
    app:                 contentInfoType;
    size:                number[];
    menuSize:            number;
    wideScreen:          boolean;
    disable:             boolean;
    studentLoggedIn:     boolean;
    setShowTopicIntro:   (val: boolean) => void;
    setShowPracticeComp: (val: boolean) => void;
}
const TopicIntro: React.FC<TopicIntroProps> = ({ 
    app,
    size,
    menuSize,
    wideScreen,
    disable,
    studentLoggedIn,
    setShowTopicIntro,
    setShowPracticeComp
}) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setInitialPos(true)
    },10)
  },[])
//



  const [showDescription, description, rootHeight, width, containerHeight] = React.useMemo(() => {

    const description_ = studentLoggedIn  && !!app.student_text ? app.student_text : 
                         !studentLoggedIn && !!app.teacher_text ? app.teacher_text : "";
                           
    const showDescription_ = !!description_.length;

    const titleHeight = 70;
    const smallDescriptionHeight = 229;
    const { navBarSize, footerSize } = sizes;

    const W = wideScreen ? size[0] - menuSize - 1 : size[0];
    const H = wideScreen ? ( size[1] - (titleHeight * 2) - navBarSize - footerSize) : (size[1] - titleHeight - smallDescriptionHeight - navBarSize - footerSize);

    return [
     showDescription_,
     description_,
     size[1] - navBarSize - footerSize,
     W,
     H
    ]
  },[app, size, menuSize, wideScreen, studentLoggedIn])

  const descriptionHeight = React.useMemo(() => {
    return wideScreen ? 0 : 155
  },[wideScreen])    

  
  return (
  <div
     className = { classes.root }
     style     = {{
      top:     sizes.navBarSize,
      height:  rootHeight,
      opacity: initialPos ? 1 : 0
    }}
  >
      <div style = {{ width: width }}>
        <TopBar
            size            = { size }
            menuSize        = { menuSize }
            app             = { app }
            wideScreen      = { wideScreen }
            handleClose     = { () => { setShowTopicIntro(false) } }
        />
        <Container
           app               = { app }
           size              = { size }
           containerHeight   = { containerHeight }
           descriptionHeight = { descriptionHeight }
           showDescription   = { showDescription }
           description       = { description }
           width             = { width }
           wideScreen        = { wideScreen }
        />
        <BottomBar
           app                 = { app }
           wideScreen          = { wideScreen }
           width               = { width }
           containerHeight     = { containerHeight }
           descriptionHeight   = { wideScreen ? 0 : descriptionHeight }
           showDescription     = { showDescription }
           disable             = { disable }
           setShowPracticeComp = { setShowPracticeComp }
           setShowTopicIntro   = { setShowTopicIntro }
        />
      </div>
      {
        wideScreen
        &&
        <SideBar
           app                   = { app }
           size                  = { size }
           menuSize              = { menuSize }
           showFAppButton        = { false }
           studentLoggedIn       = { studentLoggedIn }
           handleClose           = { () => { setShowTopicIntro(false) } }
           goTofather_app        = { () => {} }
        />
      }
  </div>
  );
}




export default TopicIntro;

