import { contentInfoType } from "types";
import { ActionTypes } from "./types";

export const SET_USER_INFO = (payload: contentInfoType): ActionTypes => ({
    type: 'SET_USER_INFO',
    payload: payload
});


export const UPDATE_ONE_TIME_EVENTS_SAGA = (payload: { userEmail: string, events: string }) => ({
  type: 'UPDATE_ONE_TIME_EVENTS_SAGA',
  payload: payload
});












 


