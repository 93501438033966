
import recordEvent from 'functions/contentInteraction/record_event';
import goToLogin   from 'functions/auth/go_to_login'

  const handleClick = (
     val:             string,
     navbarItems:     string[],
     location:        any,
     navigate:        any, 
     goToMessageForm: () => void,
     handleOpenModal: (val: string) => void,
     handleNavClick:  (val: string) => void,
     setopen:         (val: boolean) => void,
  ) => {

    setopen(false)

    if ( val !== 'video tutorials' ) {
      recordEvent('navigation button', `dropdown button clicked`, `dropdown ${ val }`)
    }

    if (val === 'apps') {
      handleNavClick('apps');
      navigate('../Apps', { replace: true });
      return;
   } 
    
    if ( location.pathname !== '/') {
        if (navbarItems.includes( val )) {
           navigate('../', { replace: true })
        } 
    }

    if (val === 'sign in') {
        goToLogin();
    }
    else if (val === 'contact us') {
        goToMessageForm();
    }
    else if (val === 'video tutorials') {
      handleOpenModal('tutorialMenu');
    }
    else if (val === 'introduction video') {
      handleNavClick('introVideo');
    }
    else if (navbarItems.includes(val)) {
        handleNavClick(val);
    }
    else {
        handleOpenModal(val)
    }
  }


export default handleClick;



