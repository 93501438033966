import { store }          from 'index'
import { menuClick }      from 'functions/menu/menu_click'
import { SET_PAGE_STATE } from 'actions/page_actions';
import { 
    contentInfoType, 
    pageStateType 
}                         from 'types';
import { SELECT_CONTENT } from 'actions/content_actions';

const goToContent = (
    navigate:         any,
    path:             string,
    wideScreen:       boolean,
    setGoToAppOnce:   (val: boolean) => void,
    setChangeContent: (val: boolean) => void,
    selectContent:    (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void
) => {

    const state = store.getState();

    const { hash }           = state.content;
    const setPageState       = (payload: pageStateType) => store.dispatch(SET_PAGE_STATE(payload));
    const setSelectedApp     = (payload: contentInfoType) => store.dispatch(SELECT_CONTENT(payload));
  
    const key = path.replaceAll(' ','_');

    if (!!hash[`${ key }`]) {
        menuClick( 'content', 'content', wideScreen, false, setChangeContent)
        setSelectedApp({ selectedApp: hash[`${ key }`] })
        selectContent(hash[`${ key }`], 'directAppLink', false, false)
        setGoToAppOnce(true)
        setPageState({ page: 'content', opacity: 1, savedPage: 'content' })
        navigate(`/${ path }`, { replace: true })
        return;
    }
    else {
        navigate(`/Apps`, { replace: true })
    }
    setGoToAppOnce(true)
}

export default goToContent;

