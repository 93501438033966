import React from 'react';

type sizeVarsType = {
    wideScreen: boolean,
    menuSize:   number
}

const useSizeVars = (size: number[]) => {

    const [wideScreen, menuSize] = React.useMemo(() => {

        const wide1 = size[0] > 800;
     
         return [
           wide1, 
           wide1 ? 260 : (size[0] * 0.55 )
         ]
     
    },[size])

    return { 
        wideScreen: wideScreen, 
        menuSize: menuSize
    } as sizeVarsType;
  
}

export default useSizeVars;

