import { call, take }  from 'redux-saga/effects';
import axios           from 'axios';

const endpoint = 'https://iseemath.co/api/recordEvent.php';
const dev = process.env.NODE_ENV === 'development';

function* recordEvent(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('RECORD_EVENT_SAGA'); 
        console.log('RECORD_EVENT_SAGA')

        const { user_ip, user_email } = input.payload;
        
         if (dev || user_ip === '185.175.35.247' || user_email.includes('raphael.shalem')) {
             console.log(input.payload)
         }
       
         else {
            const res = yield call(axios.post, endpoint, input.payload);
            console.log('res : ', res.data)
         }

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default recordEvent;
