const mockQuestions: { [key: string]: any }[] = [
  {
    "id": 4,
    "topic": "Fractions",
    "sub_topic": "Introduction to Fraction Names",
    "youtube": "f-O5A5OSch8",
    "img": "1_thumbnail.png",
    "teacher_text": "This video introduces students to the names of unit fractions by revealing a pattern (the name of the fraction corresponds to how many it takes to make a whole). As students make this connection they begin to develop an intuition about the sizes of unit fractions and how they compare to one another.",
    "student_text": "Watch this video to learn the names of basic fractions as well as how they got their names!",
    "origin": "topicIntros"
  },
  {
    "id": 5,
    "topic": "Fractions",
    "sub_topic": "Fraction Name Practice",
    "youtube": "Ur8GbBUr2JE",
    "img": "2_thumbnail.png",
    "teacher_text": "This video builds upon students’ new understanding of unit fractions by presenting them with interesting questions that can be answered with logic and critical thinking. For example, “If we put a half and a quarter together, can they fill a circle?” The dynamic visuals of the Placing Fractions in Circles app make complex questions like this accessible to all students.\n",
    "student_text": "Follow along with this video and see if you can answer these fun and challenging questions about fraction pieces!\n"
,        "origin": "topicIntros"
  },
  {
    "id": 6,
    "topic": "Fractions",
    "sub_topic": "How do we Write Fractions?",
    "youtube": "cflWEHzw0Qk",
    "img": "3_thumbnail.png",
    "teacher_text": "This video provides a simple explanation of how fractions are written. Students will learn about the function of the numerator and denominator, and will see a couple of examples.\n",
    "student_text": "After watching this video, you will know how to write fractions in number form, along with two new math terms: numerator and denominator! "
,        "origin": "topicIntros"
  },
  {
    "id": 7,
    "topic": "Fractions",
    "sub_topic": "What is a Half?",
    "youtube": "iz_EpcOSw-I",
    "img": "4_thumbnail.png",
    "teacher_text": "This video deepens students’ understanding of halves by showing a real-life example that will likely sound familiar! Students will see halves represented in different ways, demonstrating that half doesn’t always look like a circle cut down the middle.",
    "student_text": "What happens when two friends want to share a cookie? Fractions happen! Watch this video to learn all about what “half” really means."
,        "origin": "topicIntros"
  },
  {
    "id": 8,
    "topic": "Fractions",
    "sub_topic": "Fascinating Fourths",
    "youtube": "u919zWlvxO4",
    "img": "5_thumbnail.png",
    "teacher_text": "This video is a follow-up to the “What is a half?” video. Students will learn that fourths can be made from splitting halves in half or from simply splitting an object into four equal parts. \n",
    "student_text": "If you watched the “What is Half?” video, you know that Sam and Josh shared a cookie by splitting it right down the middle. This video continues the story, as Sam and Josh face another problem that will help you learn about fourths!\n"
,        "origin": "topicIntros"
  },
  {
    "id": 9,
    "topic": "Fractions",
    "sub_topic": "Exciting Eighths",
    "youtube": "Fx3WN7utPR4",
    "img": "6_thumbnail.png",
    "teacher_text": "This video begins where “Fascinating Fourths” left off. Students will continue the pattern of splitting fractions in half by finding out what fraction is made when we do this to fourths. By seeing fractions created in this way, students are building an understanding of how unit fractions relate to one another, as well as the inverse relationship between the quantity of the denominator and the size of the fractional piece.",
    "student_text": "Would you rather have half of a cookie or an eighth of a cookie? Watch this video to find out how eighths are made, and to see if Sam and Josh are satisfied with the pieces they end up with.\n"
,        "origin": "topicIntros"
  },
  {
    "id": 11,
    "topic": "Fractions",
    "sub_topic": "Halves, Fourths and Eighths",
    "youtube": "g8SxZ0v_ipY",
    "img": "7_thumbnail.png",
    "teacher_text": "In this video, students will discover the relationship between halves, fourths, and eighths. They will connect these fractions to what they already know about the 2, 4, and 8 fact family, and see visually that these fraction pieces can fit nicely inside one another. This conceptual foundation will prepare students for comparing and simplifying fractions in the future. \n",
    "student_text": "Watch this video to learn about the many connections that can be made between halves, fourths, and eighths! "
,        "origin": "topicIntros"
  },
  {
    "id": 12,
    "topic": "Fractions",
    "sub_topic": "Fractions on the Number Line",
    "youtube": "gzMU1wDNxlY",
    "img": "8_thumbnail.png",
    "teacher_text": "This video introduces students to the idea that fractions are more than just pieces of an object - they are also numbers! Students will see the number line divided into various fractions to represent real-life scenarios. They will also get the chance to practice identifying and placing fractions on the number line. ",
    "student_text": "Did you know that fractions are actually numbers, and that they have a spot on the number line? It’s true - we can even count by fractions! Watch this video to learn all about fractions on the number line.",
    "origin": "topicIntros"
  }
]
export default mockQuestions;