
import React                     from 'react';
import { store }                 from 'index';
import { GET_USER_LESSONS_SAGA } from 'actions/content_actions';
import { userAccessType }        from 'types';

const useGetUserLessons = (
    userEmail:             string,
    guest:                 boolean,
    userAccess:            userAccessType,
    getUserLessonsOnce:    boolean,
    setGetUserLessonsOnce: (val: boolean) => void
) => {


    const state           = store.getState();
    const userInfoUpdated = state.data.dataUpdateState.user;
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const userInfo        = state.user.userInfo;


    React.useEffect(() => {

      if (userInfoUpdated && !studentLoggedIn && !getUserLessonsOnce ) {

        const payload = {
          userEmail:       userEmail,
          guest:           guest ? true : false,
          subscriber:      !userAccess.denyAccess || userAccess.freeUser,
          studentOpenApps: userInfo.studentOpenApps
        }
    
        setGetUserLessonsOnce(true)
        store.dispatch(GET_USER_LESSONS_SAGA(payload))
      }
    
    },[userInfoUpdated, studentLoggedIn, getUserLessonsOnce, userEmail, guest, userInfo, userAccess, setGetUserLessonsOnce])

}

export default useGetUserLessons;