import React                 from 'react';
import { connect }           from 'react-redux';
import { reducerType }       from 'reducer';
import { Dispatch }          from "redux";
import { ActionTypes }       from 'actions/types';
import { /* handlePlay, */ onEnd } from './functions';
import { audioStateType }    from 'reducer/types';
import { SET_AUDIO_STATE }   from 'actions/audio_actions';

var playPromise: Promise<any>;


type AudioProps = { 
  AudioRef:      any;
  audioState:    audioStateType;
  setAudioState: (payload: audioStateType) => void
}

const Audio: React.FC<AudioProps> = ({ 
  AudioRef,
  audioState,
  setAudioState
}) => {

  const [playAudio, pauseAudio, audioSrc/* , replay */] = React.useMemo(() => {
    const { playAudio, pauseAudio, audioSrc/* , replay */ } = audioState;
    return [playAudio, pauseAudio, audioSrc/* , replay */]
  },[audioState])


  if (AudioRef && AudioRef.current && !!audioSrc.length && playAudio && !pauseAudio) {
    playPromise = AudioRef.current.play(); 
  }
  

  return (
      <audio  
            webkit-playsinline = "true" 
            playsInline
            preload     = "auto"
            muted       = { false }
            onEnded     = { onEnd }
            ref         = { AudioRef }
            src         = { audioSrc } 
      />
  )
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    audioState: reducer.audio
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setAudioState: (payload: audioStateType) => dispatch(SET_AUDIO_STATE(payload)),
});



export default connect(mapStateToProps, mapDispatchToProps)(Audio);
