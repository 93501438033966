import React           from 'react';
import Buttons         from './Buttons';
import { colors }      from '../../../../utils/colors';
//import CloseSharpIcon  from '@mui/icons-material/CloseSharp';
import { makeStyles }  from 'makeStyles';


//type textAlignType = 'left' | 'center';

const useStyles = makeStyles()(
  (Theme) => ({    root : {
        position:        'fixed',
        left:            0,
        height:          '100vh',
        width:           '100vw',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        transition:      'opacity 0.5s',
        transform:       'all 0.4s',
        overflowY:       'scroll',
        overflowX:       'hidden',
        zIndex:           10,
        '&::-webkit-scrollbar': {
          width: '0.5em'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#FFF`,
          borderRadius:    10
        }
    },
    modal: {
        marginLeft:      10,
        display:         'flex',
        justifyContent:  'space-evenly',
        flexFlow:        'column',
        backgroundColor: '#FFF',
        border:          '1px solid #DDD',
        borderRadius:    '0.25em',
        boxShadow:       '3px 3px 3px rgba(0,0,0,0.05)'
    },
    iconContainer: {
        height:          37,
        width:           '100%',
        display:         'flex',
        alignItems:      'flex-end',
        justifyContent:  'flex-end',
    },
    closeIcon: {
        color:       colors.blue,
        cursor:      'pointer',
        marginRight: 12,
        fontSize:    32,
        '&:hover': {
            opacity: 0.5
        }
    },
    title: {
        display:         'flex',
        width:           '80%',
        marginLeft:      '10%',
        justifyContent:  'center',
        flexFlow:        'column',
        fontSize:        18,
        lineHeight:      1.6,
    },
    row: {
        width:   '100%',
        display: 'flex',
    },
    closeAccount: {
        fontWeight: 'bold',
        height:     70
    },
    inputContainer: {
        marginBottom:    15,
        height:          100,
        //width:          '75%',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'space-between',
        textAlign:       'center',
        flexFlow:        'column'
    },
    learnMore: {
        marginLeft:      '50%',
        width:           200,
        height:          45,
        transform:       'translate(-50%, -50px)',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        backgroundColor: colors.blue,
        color:           '#FFF',
        fontWeight:      'bold',
        fontSize:        18,
        cursor:          'pointer',
        '&:hover': {
            opacity: 0.5
        }

    },
    loaderContainer: {
        position:        'absolute',
        border:          '1px solid transparent',
        borderRadius:    '0.25em',
        backgroundColor: '#FFF',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center'
    }
}))


type ModalType = {
    size:             number[];
    variant:          string; 
    showModal:        boolean; 
    handleCloseModal: () => void;
    func:             () => void;
}

const Modal: React.FC<ModalType> = ({ 
    size,
    variant,
    showModal,
    handleCloseModal,
    func
}) => {

  const { classes } = useStyles();
/* 
  const title = React.useMemo(() => {

    if (texts[variant]) {
      return texts[variant];
    }
    return [];
    
  },[variant]) */



  const [rootStyle, modalStyle/* , inputStyle */, rowStyle] = React.useMemo(() => {


/*     const height: { [key: string]: number } = {
        "abortChallenge": 500,
    }; */

    return [
       {
          opacity:   showModal ? 1 : 0,
          top:       showModal ? 0 : '150vh',
       },
       {
          width:      Math.min(630, size[0] - 20),
         // height:     Math.min(( height[variant] ? height[variant] : 400 ), size[1]),
          height:    275
       },
     /*   {
          width:      Math.min(580, size[1] / 100 * 85),
          marginLeft: Math.min(25,  size[0] / 100 * 7.5)
       }, */
       {
          alignItems:      'center',
          justifyContent:  'center',
          textAlign:       'center' as 'center'
       }
    ]
   },[size,/*  variant,  */showModal])
/* 

   const goToSubscribe = () => {
    handleOpenModal('subscribe')
   } */


  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
    >
        <div 
           className = { classes.modal }
           style     = { modalStyle }
        >
            <div className = { classes.iconContainer } >
                {/* {
                   variant !== 'subsciptionCreation'
                   &&
                   <CloseSharpIcon 
                      className = { classes.closeIcon }
                      onClick   = { handleCloseModal }
                   />
                } */}
            </div>
            <div 
               className = { classes.title }
               style     = {{ 
                   flex: 0.8
               }} 
            >
                <div
                   className = { classes.row }
                   style     = { rowStyle }
                >
                   Start Puzzle?
                </div>
            </div>
                <Buttons
                   handleCloseModal = { handleCloseModal }
                   func             = { func }
               />
        </div>
    </div>
  );
}





export default Modal;



