import { store }                from 'index'
import { SET_COMPONENTS_STATE } from 'actions/component_actions';
import { SET_PAGE_STATE }       from 'actions/page_actions';
import { 
    contentInfoType, 
    pageStateType 
}                               from 'types';
import { componentsStateType }  from 'reducer/types';
import { SELECT_CONTENT }       from 'actions/content_actions';
import { SET_PRACTICE_STATE }   from 'actions/challenges_actions';

const GoToBonusContent = (
    navigate:            any,
    path:                string,
    setGoToAppOnce:      (val: boolean) => void,
    setChangeContent:    (val: boolean) => void,
    selectContent:       (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void
) => {

    const state = store.getState();
    const { puzzles, topicIntros } = state.content;
    const componentsState = state.components;

    const setPageState       = (payload: pageStateType) => store.dispatch(SET_PAGE_STATE(payload));
    const setComponentsState = (payload: componentsStateType) => store.dispatch(SET_COMPONENTS_STATE(payload));
    const setSelectedContent = (payload: contentInfoType) => store.dispatch(SELECT_CONTENT(payload));
    const setPracticeState   = (payload: contentInfoType) => store.dispatch(SET_PRACTICE_STATE(payload));


    for ( let i = 0; i < puzzles.length; i++ ) {

      const puzzleId = `${ puzzles[i].page }${ puzzles[i].extra_url }`;

      if ( path === puzzleId ) {

        setPracticeState({ practiceVariant: 'puzzles' })
        setSelectedContent({ selectedPuzzle: puzzles[i] })
        setChangeContent(true)
        selectContent(puzzles[i], 'directPracticeLink', false, false)
        setGoToAppOnce(true)
        setPageState({ page: 'content', opacity: 1, savedPage: 'content' })
        navigate(`/${ path }`, { replace: true })
        break;
      }
    }
    for ( let i = 0; i < topicIntros.length; i++ ) {

      const topicIntId = `${ topicIntros[i].sub_topic.replaceAll(' ','_').replaceAll('?','') }`;
  
      if ( path === topicIntId ) {

        setPracticeState({ practiceVariant: 'puzzles' })
        setSelectedContent({ selectedAppIntro: topicIntros[i].sub_topic })
        setGoToAppOnce(true)
        setChangeContent(true)
        setComponentsState({
           ...componentsState,
           selectedContent: 'bonus',
           showMenu:        false,
           showTopicIntro:  true,
           directPageLink:  true
        })
        setPageState({ page: 'content', opacity: 1, savedPage: 'content' })
        navigate(path, { replace: true })
        break;
      }
    }
    setGoToAppOnce(true);
}

export default GoToBonusContent;

