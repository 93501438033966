import React               from 'react';
import ExitButton          from './components/buttons/ExitButton';
import Modal               from './components/modal';
import ProgressBar         from '../progressBars/ProgressBar';
import BigProgressBar      from '../progressBars/BigProgressBar';
import Image               from './Image';
import Input               from './Input';
import ActionBar           from '../actionbar'
//import BottomBar           from './components/BottomBar';
import setNumberValues     from './functions/set_number_values';
import checkAnswerNumeric  from './functions/check_answer_numeric';
import checkAnswerString   from './functions/check_answer_string';
import handleExit          from './functions/handle_exit';
import handleGoToPuzzle    from './functions/handle_go_to_puzzle';
import handleNextTest      from './functions/handle_next_test';
import handlePrevTest      from './functions/handle_prev_test';
import { colors }          from '../../utils/colors';
import { makeStyles }      from 'makeStyles';
import { contentInfoType } from '../../types';
import { useNavigate }     from 'react-router-dom';

var clsx = require('classnames')

let decode = (str: string) => {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
}

const arr = ['.', '!', '?', ':', ';', ',', ' ', "\n"]

const useStyles = makeStyles()(
  (Theme) => ({    root: {
      position:        'fixed',
      left:            0,
      top:             0,
      height:          '100vh',
      width:           '100vw',
      backgroundColor: '#FFF',
      transition:      'opacity 0.5s',
    },
    container: {
      height:         '100%',
      width:          '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'space-between',
      flexFlow:       'column',
    },
    container2: {
      width:          '100%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'space-evenly',
      flexFlow:       'column',
    },
    text: {
      maxWidth:       '95%',
      fontWeight:     'bold',
      textAlign:      'center',
      lineHeight:     1.5,
      display:        'flex',
      alignItems:     'center',
      flexFlow:       'column',
      paddingTop:     8,
      color:          colors.blue
    },
    startPuzzleButton: {
      color:           colors.primary,
      height:          40,
      width:           300,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      borderRadius:    '0.4em',
      textAlign:       'center',
      fontSize:        17,
      fontWeight:     'bold',
      border:         `1px solid ${ colors.primary }`,
      cursor:         'pointer',
      transition:     'transform 0.4s',
      '&:hover':{
          color:          '#FFF',
          backgroundColor: colors.primary,
      } 
    },
    shake: {
      animation:               'shake 0.5s',
      animationIterationCount: '0.5s',
    },
    exit: {
      minHeight: 40,
      marginTop: 10,
    }
}))

type PracticeProps = {
  questionSet:         contentInfoType[];
  selectedApp:         contentInfoType;
  size:                number[];
  menuSize:            number;
  wideScreen:          boolean;
  setShowPracticeComp: (val: boolean) => void;
  selectContent:       (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
}

const PracticeComp: React.FC<PracticeProps> = ({
  wideScreen,
  questionSet,
  selectedApp,
  size,
  menuSize,
  setShowPracticeComp,
  selectContent
}) => {


  const { classes } = useStyles();
  const navigate    = useNavigate();

  const [initialPos, setInitialPos]             = React.useState(false);
  const [testNum, setTestNum]                   = React.useState(0);
  const [questionObj, setQuestionObj]           = React.useState(questionSet[testNum]);
  const [integer, setInteger]                   = React.useState(false);
  const [fraction, setFraction]                 = React.useState(false);
  const [combined, setCombined]                 = React.useState(false);
  const [numValuesSet, setNumValuesSet]         = React.useState(false);
  const [number, setNumber]                     = React.useState('');
  const [numerator, setNumerator]               = React.useState('');
  const [denominator, setDenominator]           = React.useState('');
  const [inputInteger, setInputInteger]         = React.useState('');
  const [inputNumerator, setInputNumerator]     = React.useState('');
  const [inputDenominator, setInputDenominator] = React.useState('');
  const [selectedAnswer, setSelectedAnswer]     = React.useState(-1);
  const [success, setSuccess]                   = React.useState('')
  const [maxLength, setMaxLength]               = React.useState(questionSet[testNum].correct_answer.length);
  const [correctAnswer, setCorrectAnswer]       = React.useState(questionSet[testNum].correct_answer);
  const [animateImage, setAnimateImage]         = React.useState(false);
  const [animateInput, setAnimateInput]         = React.useState(false);
  const [openModal, setOpenModal]               = React.useState(false);

  React.useEffect(() => {
    
      const timeOut = setTimeout(() => {
        setInitialPos(true)
      },10)
      
      return () => { clearTimeout(timeOut) }
  },[]) 

  
  const numberInputFull = React.useMemo(() => {
     return(
       (integer && !!inputInteger) || 
       (fraction && !!inputNumerator && !!inputDenominator) ||
       (combined && !!inputInteger && !!inputNumerator && !!inputDenominator)
     )
  },[integer, fraction, combined, inputInteger, inputNumerator, inputDenominator])


  const enableCheckAnswer = React.useMemo(() => {
    return (
      (questionObj.question_type === 'N' && numberInputFull) || 
      (questionObj.question_type === 'MC' && selectedAnswer !== -1)
    )
  },[questionObj.question_type, numberInputFull, selectedAnswer])

  
  const buttonText = React.useMemo(() => {
    if (testNum === questionSet.length - 1) {
      return questionObj.question_type === "puzzle" ? 'start puzzle' : 'finish'
    }
    return 'continue'
  },[testNum, questionSet, questionObj])


  React.useEffect(() => {
    setNumberValues(numValuesSet, questionObj, numerator, combined, fraction, setNumValuesSet, setInteger, setCombined, setFraction, setNumber, setNumerator, setDenominator, setMaxLength, setCorrectAnswer)
 },[ numValuesSet, combined,fraction,numerator,questionObj,testNum,setNumerator,setNumber,setDenominator,setInteger,setCombined,setMaxLength, setCorrectAnswer])
  
  
  const goToPuzzle = () => {
    handleGoToPuzzle(navigate, selectedApp, questionSet, () => {}, setNumValuesSet, setInteger, setShowPracticeComp, setInputInteger, setSelectedAnswer, setSuccess, setTestNum, setQuestionObj, selectContent)
  }

  const nextTest = () => {
    handleNextTest(navigate, questionSet, questionObj, selectedApp, testNum, () => {}, setNumValuesSet, setInteger, setShowPracticeComp, setAnimateImage, setAnimateInput, setSelectedAnswer, setSuccess, setNumber, setNumerator, setDenominator, setInputInteger, setInputNumerator, setInputDenominator, setTestNum, setQuestionObj, selectContent)
  }

  const prevTest = () => {
     handlePrevTest(questionSet, questionObj, testNum, setQuestionObj, setTestNum, setAnimateImage, setAnimateInput, setNumber, setNumerator, setDenominator, setInputInteger, setInputNumerator, setInputDenominator, setSelectedAnswer)
  }

  const checkAnswer = () => {
    if (!enableCheckAnswer) {
      return
    }
    if (questionObj.question_type === 'N') {
        checkAnswerNumeric(success, number, numerator, denominator, inputInteger, inputNumerator, inputDenominator, integer, fraction, combined, setSuccess);
    }
    else {
        checkAnswerString(selectedAnswer, questionSet[testNum].correct_answer, success, setSuccess)
    }
  }

  const exit = () => {
    handleExit(navigate, 'backToGrid', questionSet, () => {}, setNumValuesSet, setInteger, setShowPracticeComp, setInputInteger, setSelectedAnswer, setSuccess, setTestNum, setQuestionObj)
  }





  const [variant, answerChoices] = React.useMemo(() => {

    const { question_type, answer_choices } = questionObj;

    const abc = ['a','b','c','d','e','f','g','h','i','j'];

    const choices = isNaN(+answer_choices) ? answer_choices : abc.splice(0, +answer_choices).join('@');

    return[
        question_type,
        choices,
    ]
  },[questionObj])




  const [rootStyle, container2Style, textStyle, lineStyle, line2Style, startButtonStyle] = React.useMemo(() => {

    const textLen  = questionObj.question.length;
    const longText = textLen > 400;

    return [
      {
         opacity:    initialPos ? 1 : 0,
         width:      wideScreen ? size[0] - menuSize : '100vw',
         marginLeft: wideScreen ? menuSize : 0
      },
      {
        height:    wideScreen ? size[1] - 35 : size[1] - 85,
        marginTop: wideScreen ? 35 : 10,
      },
      {
        fontSize:  !wideScreen ? (longText ? 15 : 18) : longText ? 19 : (size[1] >= 650) ? 23 : 19,
        maxWidth:  textLen > 180 ? Math.min(800, size[0] - (wideScreen ? menuSize : 0 ) - 40 ) : Math.min(500, size[0] - (wideScreen ? menuSize : 0 ) - 40 ),
        textAlign: textLen > 180 ? 'left' : 'center' as 'left' | 'center',
      },
      {
        maxWidth: '98%',
        width:    questionObj.question.length > 180 ? 800 : 600,
      },
      {
        opacity:    !wideScreen && success === 'true' ? 1 : 0,
        transition: 'all 0.4s'
      },
      {
        transform: `translate(0%, ${ buttonText === 'start puzzle' ? 0 : 300 }vh)`
      }
    ]
  },[ size,  menuSize, initialPos, wideScreen, questionObj.question, success, buttonText ])

  
  return (
      <div
        className = { classes.root }
        style     = { rootStyle }
      > 
          <div className = { classes.container }>
              <div 
                className = { success === 'false' ? clsx(classes.container2, classes.shake) : classes.container2 }
                style     = { container2Style }
              >
                <div className = { classes.exit }/>
                <div 
                  className = { classes.text } 
                  style     = { textStyle }
                >  
                  {
                      questionObj.question.split('.').map((val: string) => {

                          let str = decode(val);

                          const end = !!str.length ? str[str.length - 1] : ' ';

                          if (!!str.length && !!end.length && !arr.includes(end)) {
                            str = `${ str }.`
                          }

                          return(
                            <div 
                              key   = { str }
                              style = { lineStyle }
                            >
                              { `${ str }` }
                            </div>
                          )
                      })
                  }
                  <div 
                    className = { classes.text }
                    style     = { line2Style }
                  >
                      Correct!
                  </div>
                </div>
               <Image
                 wideScreen      = { wideScreen }
                 questionObj     = { questionObj }
                 animateImage    = { animateImage }
                 setAnimateImage = { setAnimateImage }
               />
               <Input
                 size                 = { size }
                 variant              = { variant }
                 answerChoices        = { answerChoices }
                 integer              = { integer }
                 fraction             = { fraction }
                 combined             = { combined }
                 number               = { number }
                 numerator            = { numerator }
                 denominator          = { denominator }
                 success              = { success }
                 inputInteger         = { inputInteger }
                 maxLength            = { maxLength }
                 inputNumerator       = { inputNumerator }
                 inputDenominator     = { inputDenominator }
                 numeratorMaxLength   = { numerator.length }
                 denominatorMaxLength = { denominator.length }
                 animateInput         = { animateInput }
                 selectedAnswer       = { selectedAnswer }
                 correctAnswer        = { correctAnswer }
                 setSelectedAnswer    = { setSelectedAnswer }
                 setInputInteger      = { setInputInteger }
                 setInputNumerator    = { setInputNumerator }
                 setInputDenominator  = { setInputDenominator }
                 setAnimateInput      = { setAnimateInput }
               />
               <div 
                  className = { classes.startPuzzleButton }
                  style     = { startButtonStyle }
                  onClick   = { goToPuzzle }
               >
                 Start
               </div>
          </div>     
          {
            !wideScreen && 
            <ProgressBar 
                length = { questionSet.length - 1 } 
                testNum = { testNum }
            />
          }
          {
            wideScreen &&
            <BigProgressBar 
               size     = { size } 
               length   = { questionSet.length - 1 } 
               prog     = { testNum }
               menuSize = { menuSize }
            />
          }
          </div>
          <ActionBar
            size             = { size }
            menuSize         = { menuSize }
            wideScreen       = { wideScreen }
            name             = { !!questionObj.practice_set ? questionObj.practice_set : '' }
            disableSkip      = { false }
            disableContinue  = { !inputNumerator.length && !inputInteger.length && !inputDenominator.length }
            resetTimer       = { false }
            activateTimer    = { false }
            challengeState   = { {} }
            checkOrContinue  = { 'continue' }
           // correctAnswer    = { success === 'true' } 
            handleContinue   = { nextTest }
            handleSkip       = { nextTest }
            handleExit       = { exit }
            setResetTimer    = { () => {} } 
            setTime          = { () => {} }

            variant           = { questionObj.question_type === "puzzle" ? 'puzzle' : 'practice' }
            text              = { buttonText }
            testNum           = { testNum }
            enableCheckAnswer = { enableCheckAnswer }
            success           = { success }
            checkAnswer       = { checkAnswer }
            nextTest          = { nextTest }
            prevTest          = { prevTest }
            goToPuzzle        = { goToPuzzle }
            setOpenModal      = { setOpenModal }
          />     
          {
            !wideScreen &&
            <ExitButton 
              func = { exit }
            />
          }   
          <Modal 
             size             = { size } 
             variant          = { 'exit' }
             showModal        = { openModal }
             handleCloseModal = { () => setOpenModal(false) }
             func             = { goToPuzzle }
          /> 
      </div>
  );
}

export default PracticeComp;

