import { store }                from '../../index';
import { SET_SHOW_SMALL_MENU }  from '../../actions/component_actions'
import { SET_PAGE_STATE }       from '../../actions/page_actions'
//import { SET_COMPONENTS_STATE } from '../../actions/component_actions'
import tutorialItemClick        from './tutorial_item_click'


const tutorialClick = (
    dataSet:     string,
    val:         number,
    //setShowMenu: (val: boolean) => void
) => {

    const state           = store.getState();
    const pageState       = state.page;
  //  const componentsState = state.components;

    store.dispatch(SET_SHOW_SMALL_MENU(false))

     if ( pageState.page !== 'content' ) {

      console.log('dataSet : ',dataSet)
      console.log('val     : ',val)

      store.dispatch(SET_PAGE_STATE({
         ...pageState,
         page:      'content',
         savedPage: 'content'
      }))

    /*   if (!componentsState.showMenu) {
          store.dispatch(SET_COMPONENTS_STATE({
            ...componentsState,
            showMenu: true
          }))
      } */
    } 

    

    
    tutorialItemClick( dataSet, val )
}

export default tutorialClick;