
import { store }                from 'index';
import  getAppLessons           from './get_app_lessons'; 
import  goToApp                 from './go_to_app'; 
import  setAppUrl               from './set_app_url'; 
import { contentInfoType }      from 'types';
import recordEvent              from 'functions/contentInteraction/record_event'
import { SET_COMPONENTS_STATE } from 'actions/component_actions'
import { SET_MODAL_STATE }      from 'actions/modal_actions'
import { noMenuComponents }     from 'utils/defaultStates';
import { SELECT_CONTENT }       from 'actions/content_actions';


export const contentSelection = (
    navigate:       any,
    location:       any,
    size:           number[],
    selected:       contentInfoType, 
    destination:    string, 
    disabled:       boolean,
    disabledFadeIn: boolean,
) => {


    const state           = store.getState();
    const studentLoggedIn = state.user.studentLoginState.loginState === 'success';
    const componentsState = state.components
    const showMenu        = !noMenuComponents.includes(destination)


    // send user trying to access restricted content to registration modal

    if ( disabled && !studentLoggedIn ) {
      
      store.dispatch(SET_MODAL_STATE({
        variant:      'subscribe',
        open:         true,
        showButtons:  true
     }))
      return;
    } 

    //

    // content cannot be selected

    if (!selected) { return }
    if (!selected.lesson_id && !selected.uid) {
       selected.lesson_id = "";
    }

    //

    const appLessons = selected.origin === 'apps' ? getAppLessons(selected.lesson_id) : [];

    store.dispatch(SELECT_CONTENT({
      selectedApp: selected
    }))

    const url   = setAppUrl(selected);
    const id    = (!!selected.uid && selected.uid !== "undefined") ? `${ selected.uid }` : (!!selected.lesson_id && selected.lesson_id !== "undefined") ? selected.lesson_id : !!selected.puzzletype ? selected.page : '';
    const event = destination === 'appIntro' ? 'app intro opend' : 
                  destination === 'appPage' ? 'app entered' :  
                  destination === 'practiceComp' ? 'bonus content selected' :  
                  destination === 'directAppLink' ? 'direct link to app entered' :
                  '';

    recordEvent('app', event, url)

    store.dispatch(SET_COMPONENTS_STATE({
      ...componentsState,
      appLessons:       appLessons,
      showAppIntro:     destination === 'appIntro' || destination === 'directAppLink',
      selectedContent:  (destination === 'practiceComp' || destination === 'directPracticeLink') ? 'bonus' : componentsState.selectedContent,
      showPracticeComp: destination === 'practiceComp' || destination === 'directPracticeLink', 
      showMenu:         showMenu,
      disabledFadeIn:   disabledFadeIn,
      directPageLink:   destination === 'directAppLink' || destination === 'directPracticeLink'
    }))

    if ((destination === 'appIntro' || destination === 'practiceComp' || destination === 'directAppLink' || destination === 'directPracticeLink') && location.pathname !== `/${ id }`) {
      navigate(`../${ id }`, { replace: true })
    }

    if (destination === 'appPage' || destination === 'scorePage' || destination === 'userLessons' || destination === 'groupLessons') {
      goToApp(size, false)
    }
  
  }
