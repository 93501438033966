import { useMemo }       from 'react';
import SelectorContainer from './SelectorContainer';
import ButtonContainer   from './ButtonContainer';
import { colors }        from '../../../utils/colors';
import { makeStyles }    from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    container: {
      position:        'absolute',
      top:             0,
      height:          '100%',
      display:         'flex',
      alignItems:      'center',
      backgroundColor: colors.primary,
    }
}))

type ContainerProps = {
  size:                   number[];
  bigContainerWidth:      number;
  dropDownButtonWidth:    number;
  menuButtonWidh:         number;
  logoWidh:               number;
  selectors:              string[];
  page:                   string;
  subscriptionStatus:     string;
  bigNavBar:              boolean;
  wideScreen:             boolean;
  showSubscribeButton:    boolean;
  showSignInButton:       boolean;
  showSelectors:          boolean;
  goToMessageForm:        () => void;
  getStarted:             () => void;
  handleNavClick:         (val: string) => void;
  handleOpenModal:        (val: string) => void;
}

const Container: React.FC<ContainerProps> = ({ 
  size,
  dropDownButtonWidth,
  menuButtonWidh,
  logoWidh,
  bigNavBar,
  bigContainerWidth,
  page,
  selectors,
  subscriptionStatus,
  wideScreen,
  showSelectors,
  showSubscribeButton,
  showSignInButton,
  goToMessageForm,
  getStarted,
  handleNavClick,
  handleOpenModal,
}) => {

  const { classes } = useStyles();

  const containerStyle = useMemo(() => {
    if ( bigNavBar ) {
      return {
        width:       bigContainerWidth - dropDownButtonWidth - 25,
        marginRight: 25,
        paddingLeft: 25,
        left:        0,
      }
    }
    return {
      width: size[0] - dropDownButtonWidth - menuButtonWidh - logoWidh,
      right: dropDownButtonWidth,
    }
  },[ bigNavBar, bigContainerWidth, dropDownButtonWidth, menuButtonWidh, logoWidh, size ])


  return (
    
          <div 
            className = { classes.container }
            style     = { containerStyle }
          >     
                <SelectorContainer
                    page            = { page }
                    selectors       = { selectors }
                    wideScreen      = { wideScreen }
                    showSelectors   = { showSelectors }
                    goToMessageForm = { goToMessageForm }
                    handleNavClick  = { handleNavClick }
                    handleOpenModal = { handleOpenModal }
                />
                {
                  (showSignInButton || showSubscribeButton)
                  &&
                   <ButtonContainer
                     showSelectors       = { showSelectors }
                     wideScreen          = { wideScreen }
                     subscriptionStatus  = { subscriptionStatus }
                     showSignInButton    = { showSignInButton }
                     showSubscribeButton = { showSubscribeButton }
                     getStarted          = { getStarted }
                  />
                }
                {
                  !showSignInButton && !showSubscribeButton && !showSelectors
                  &&
                  <div style = {{ flex: 1 }}/>
                }
          </div>
          
  );
}




export default Container;



