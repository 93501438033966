import { store }          from '../../index';
import { SET_PAGE_STATE } from '../../actions/page_actions';
import { 
    pageStateType, 
    planTypeType 
}                         from '../../types';
import goToSignUp         from '../auth/go_to_signup';


const goToSubscribe = (
    navigate:     any,
    userSignedIn: string,
    setPlanType:  (val: planTypeType) => void
) => {

    const setPageState = (payload: pageStateType) => { store.dispatch(SET_PAGE_STATE(payload)) }

    if (userSignedIn === 'true') {
        navigate('/', { replace: true })
        return;
    }

    setPageState({
        page:      'home',
        opacity:   1,
        savedPage: ''
    })

    setPlanType('Basic');
    goToSignUp(false, 'Basic');
    navigate('/', { replace: true })}

export default goToSubscribe;

