
import { contentInfoType } from "types";
import handleExit          from "./handle_exit";

const handleGoToPuzzle = (
    navigate:            any,
    selectedApp:         contentInfoType,
    questionSet:         contentInfoType[],
    setInitialPos:       (val: boolean) => void,
    setNumValuesSet:     (val: boolean) => void,
    setInteger:          (val: boolean) => void,
    setShowPracticeComp: (val: boolean) => void,
    setInputInteger:     (val: string) => void,
    setSelectedAnswer:   (val: number) => void,
    setSuccess:          (val: string) => void,
    setTestNum:          (val: number) => void,
    setQuestionObj:      (val: contentInfoType) => void,
    selectContent:       (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void
) => {

    selectContent(selectedApp, 'appPage', false, false)
    setTimeout(() => { 
        handleExit(navigate, 'appIframe', questionSet, setInitialPos, setNumValuesSet, setInteger, setShowPracticeComp, setInputInteger, setSelectedAnswer, setSuccess, setTestNum, setQuestionObj) 
    },2500)
}

export default handleGoToPuzzle;