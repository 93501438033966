import { call, take, put }          from 'redux-saga/effects';
import axios                        from 'axios';
import { SET_PUZZLES }              from 'actions/content_actions';
import { RECORD_ERROR }             from 'actions';
 import mockPuzzles                 from 'content/mockContent/puzzles2';

 const puzzlesEndpoint = 'https://iseemath.co/api/getPuzzles.php';


function* getPuzzles(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('GET_PUZZLES_SAGA');

        console.log('GET_PUZZLES_SAGA');

        const { userEmail } = input.payload;

        if ( process.env.NODE_ENV === 'development' ) {

          const puzzlesFinal = mockPuzzles;

          const payload = { puzzles: puzzlesFinal }

          yield put(SET_PUZZLES( payload ))

        }


        if (process.env.NODE_ENV === 'production') {

                const puzzles = yield call( axios.post, puzzlesEndpoint );
          
                if ( puzzles && puzzles.data && puzzles.data.result ) {

                    const puzzlesFinal = puzzles && puzzles.data && puzzles.data.result ? puzzles && puzzles.data && puzzles.data.result : [];

                    const payload = { puzzles: puzzlesFinal }
  
                    yield put(SET_PUZZLES( payload ))

                  
                }
                if ( puzzles && puzzles.data && !Boolean(puzzles.data.success) && puzzles.data.msg ) {
                  yield put(RECORD_ERROR({ userEmail: userEmail, err: puzzles.data.msg, endpoint: 'get_puzzles' }));
                }

        }
      
      

    }
    catch(err) {
      console.log('err at get content saga  :  '+err);
    }
    finally {}
  }
}


export default getPuzzles;
