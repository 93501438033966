import React      from 'react';
import { colors } from 'utils/colors';

const useTextFieldStyle = (size: number[]) => {

    const textFieldStyle = React.useMemo(() => {
        return { 
          width:        size[0] > 600 ? '350px' : '90%',
          height:       45,
          color:        colors.darkText,
          marginTop:    8,
          marginBottom: 5,
          transform:    'none'
        }
    },[size])

    return textFieldStyle;
}

export default useTextFieldStyle;