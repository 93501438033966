
import { SELECT_CONTENT }       from "actions/content_actions";
import { SET_COMPONENTS_STATE } from "actions/component_actions";
import { store }                from "index";
import { contentInfoType }      from "types";
import recordEvent              from 'functions/contentInteraction/record_event';

const handleExit = (
    navigate:            any,
    destination:         string,
    questionSet:         contentInfoType[],
    setInitialPos:       (val: boolean) => void,
    setNumValuesSet:     (val: boolean) => void,
    setInteger:          (val: boolean) => void,
    setShowPracticeComp: (val: boolean) => void,
    setInputInteger:     (val: string) => void,
    setSelectedAnswer:   (val: number) => void,
    setSuccess:          (val: string) => void,
    setTestNum:          (val: number) => void,
    setQuestionObj:      (val: contentInfoType) => void,
) => {

    const state = store.getState();
    const componentsState = state.components;

    recordEvent('app', 'bonus content closed', componentsState.selectedApp)

    if ( destination === 'backToGrid') {
      store.dispatch(SET_COMPONENTS_STATE({
        ...componentsState,
        showMenu: true
      })) 
      store.dispatch(SELECT_CONTENT({ 
        selectedPuzzle:   {},
        selectedApp:      {},
        selectedAppIntro: ''
      }))

      setTimeout(() => {
        navigate('../', { replace: true });
      },50)
    }
    
    setInitialPos(false)

    setTimeout(() => {
      setTestNum(0);
      setQuestionObj(questionSet[0]);
      setInteger(false);
      setInputInteger('');
      setSelectedAnswer(-1);
      setSuccess('');
      setShowPracticeComp(false)
      setNumValuesSet(false)
    },10)


    
 }

export default handleExit;