import { store }           from 'index';
import { SET_MODAL_STATE }      from 'actions/modal_actions'
import recordEvent         from 'functions/contentInteraction/record_event'

const goToSubscribe = () => {

    store.dispatch(SET_MODAL_STATE({
        variant:       'selectPlan',
        open:          true,
        showButtons:   true
  }));

  recordEvent('menu button', `access to menu item denied`, 'menu button disabled')
}

export default goToSubscribe