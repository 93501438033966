import { store }             from 'index';
import goToCloseAccount      from './go_to_close_account';
import goToPlanSelection     from './go_to_plan_selection';
import handlReactivateSub    from './handle_reactivate_sub';

const setSubscriptionState = () => {

    const state    = store.getState();
    const userInfo = state.user.userInfo;
    const { 
      subscriptionSuspended, 
      allowTrial 
    } = userInfo;

    
    if ( allowTrial ) {
      goToPlanSelection();
    }
   
    else if ( subscriptionSuspended ) {
      handlReactivateSub();
    }
    else {
      goToCloseAccount();
    }
}

export default setSubscriptionState;