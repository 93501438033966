import React                    from 'react'
import { connect }              from 'react-redux';
import { Dispatch }             from "redux";
import { reducerType }          from 'reducer';
import { ActionTypes }          from 'actions/types';
import UserName                 from './UserName'
import SearchBar                from './SearchBar'
import MenuButton               from './MenuButton'

import { menuClick }            from 'functions/menu/menu_click'

import { sizes }                from 'utils/defaultStates';
import { colors }               from 'utils/colors'

import { studentLoginStateType } from 'types';

import { makeStyles }           from 'makeStyles';
import { componentsStateType }  from 'reducer/types';

import { DO_TEST }              from 'actions';
import { SET_MODAL_STATE }      from 'actions/modal_actions'


const userNameHeight = sizes.navBarSize;


const useStyles = makeStyles()(
  (Theme) => ({    root: {
        position:        'fixed',
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'flex-start',
        flexFlow:        'column',
        minWidth:        200,
        transition:      'all 0.4s',
        overflowY:       'scroll',
        overflowX:       'hidden',
        backgroundColor: colors.brightGrey,
        borderRight:     `1px solid #CCC`,
        '&::-webkit-scrollbar': {
          width: '0.5em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `#BBB`,
          borderRadius:    10
        }
    }
}))

type MenuProps = {
  studentLoginState:     studentLoginStateType;
  componentsState:       componentsStateType;
  AudioRef:              any;
  size:                  number[];
  menuSize:              number;
  page:                  string;
  userName:              string;
  guest:                 boolean;
  tester:                boolean;
  denyAccess:            boolean;
  wideScreen:            boolean;
  studentLoggedIn:       boolean;
  setChangeContent:      (val: boolean) => void;
  setModalState:         (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
  doTest:                () => void;
}


const Menu: React.FC<MenuProps> = ({ 
  studentLoginState,
  componentsState,
  AudioRef,
  size,
  menuSize, 
  page, 
  userName,
  guest,
  tester,
  denyAccess,
  wideScreen,
  studentLoggedIn,
  setChangeContent,
  setModalState,
  doTest
}) => {


  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)
  const [openItem, setOpenItem]     = React.useState('noDisplay')



  React.useEffect(() => {

    let unmounted = false;

    setTimeout(() => { 
      if (!unmounted) { 
        setInitialPos(true) 
      }
    },10)
    
    return () => { unmounted = true };
  },[])



  React.useEffect(() => {

    if ( componentsState.selectedContent === 'introVideo' && openItem !== 'noDisplay' ) {
      setOpenItem('noDisplay')
    }

  },[componentsState.selectedContent, openItem])


  React.useEffect(() => {
    setOpenItem('noDisplay')
  },[page])


  /* 
  const userHeight = React.useMemo(() => {
    return guest ? 0 : userNameHeight
  },[guest]);
 */


  const handleMenuClick = (variant: string, val: string, disabled: boolean) => {
    menuClick( variant, val, wideScreen, disabled, setChangeContent)
  } 

  /* const handleTopicClick = ( variant: string, val: string ) => {
    topicClick( variant, val, setChangeContent)
  } */

  const handleOpenModal = (val: string) => {
    setModalState({ variant: val, open: true, showButtons: true })
  }
  

  /* const handleGradeSelection = (val: string) => {

    setComponentsState({
      ...componentsState,
      selectedGrades:      val,
      openGradeGrid:       false,
      selectedAppSubTopic: '',
      selectedAppTopic:    '',
      createTopicArr:      true,
      selectedContent:     'apps'
      //
    })

    history.replace('./Apps')
    setChangeContent(true)
  } */

  const [ showSearchBar, menuButtons ] = React.useMemo(() => {
    
      const searchBar        = false//(!studentLoggedIn && page !== 'students');
      const studentApps      = studentLoggedIn && !!studentLoginState.studentOpenApps.length;
      const myTeacherContent = studentLoggedIn && !!studentLoginState.myTeacherContent.length;
      const bonus            = studentLoggedIn;
      const favorites        = (!studentLoggedIn && page !== 'students' && page !== 'groupLessons');
      const myStudentC       = (!studentLoggedIn && page === 'content');
      const groups           = (!studentLoggedIn && page === 'content');
      const lessons          = (!studentLoggedIn && page === 'content');
      const userLessons      = (!studentLoggedIn && page !== 'students');

      return[
        searchBar,
        [
           (lessons && 'lessons'),
           (studentApps && 'studentApps'),
           (myTeacherContent && 'myTeacherContent'),
           (bonus && 'bonus'),
           (favorites && 'favorites'),
           (groups && 'groupLessons'),
           (userLessons && 'userLessons'),
           (myStudentC && 'myStudentsContent'),
        ]
      ]

  },[page, studentLoggedIn, studentLoginState])



  
  const rootStyle = React.useMemo(() => {

      const menuHeight  = size[1] - sizes.navBarSize - sizes.footerSize;
      const menuDisplay = (( wideScreen && componentsState.showMenu ) || ( !wideScreen && componentsState.showSmallMenu ))

      return {
           width:           menuSize,
           height:          menuHeight,
           top:             sizes.navBarSize,
           left:            menuDisplay ? 0 : -500,
           opacity:         !initialPos || !menuDisplay ? 0 : 1,
           backgroundColor: wideScreen ? undefined : '#FFF',
           boxShadow:       wideScreen ? undefined : '0px 4px 10px rgba(0,0,0,0.15)',
        }
      
  },[size, wideScreen, componentsState, menuSize, initialPos]) 




  return (
    <div 
       className = { classes.root }
       style     = { rootStyle }
    >
        <UserName
           menuSize        = { menuSize } 
           userNameHeight  = { userNameHeight }
           userName        = { userName }
           guest           = { guest }
           tester          = { tester }
           studentLoggedIn = { studentLoggedIn }
           wideScreen      = { wideScreen }
           testFunc        = { doTest }
        />
        {
          !studentLoggedIn &&
          ['apps', 'games', 'bonus'].map((val, ind) => {
              return(
                  <MenuButton
                    key             = { `MenuButton${ val }` }
                    variant         = { val }
                    menuSize        = { menuSize }
                    disable         = { false } 
                    bigButton       = { true } 
                    wideScreen      = { wideScreen }
                    AudioRef        = { AudioRef }
                    setOpenItem     = { setOpenItem }
                    handleOpenModal = { handleOpenModal }
                    handleMenuClick = { handleMenuClick }
                  />
              )
          })
        }
        {
           showSearchBar 
           &&
           <SearchBar
             menuSize         = { menuSize }
             wideScreen       = { wideScreen }
             page             = { page }
             setOpenItem      = { setOpenItem }
             setChangeContent = { setChangeContent }
             handleMenuClick  = { handleMenuClick }
           />
        }
       {/*  <ComplexButtons
            appTopicsArr         = { appTopicsArr }
            userHeight           = { userHeight }
            openItem             = { openItem }
            componentsState      = { componentsState }   
            menuSize             = { menuSize }
            page                 = { page }
            wideScreen           = { wideScreen }
            showSearchBar        = { showSearchBar }
            studentLoggedIn      = { studentLoggedIn }
            handleGradeSelection = { handleGradeSelection }
            setOpenItem          = { setOpenItem }
            handleMenuClick      = { handleMenuClick }
            handleTopicClick     = { handleTopicClick }
        />  */}
        {/* {
                !studentLoggedIn && componentsState.selectedContent === 'apps' &&
                <GradeGrid
                    componentsState      = { componentsState }
                    menuSize             = { menuSize }
                    wideScreen           = { wideScreen }
                    handleMenuClick      = { handleMenuClick }
                    handleGradeSelection = { handleGradeSelection }
                />
         } */}
        {
          menuButtons.map((val, ind) => {
            if (!!val) {

              const disable = (!['studentApps','bonus','myTeacherContent', 'lessons'].includes(val)) && (guest || denyAccess);

              return(
                  <MenuButton
                    key             = { `MenuButton${ val }` }
                    variant         = { val }
                    AudioRef        = { AudioRef }
                    menuSize        = { menuSize }
                    disable         = { disable } 
                    bigButton       = { false } 
                    wideScreen      = { wideScreen }
                    setOpenItem     = { setOpenItem }
                    handleOpenModal = { handleOpenModal }
                    handleMenuClick = { handleMenuClick }
                  />
              )
            }
            else {
              return(< div key = { `MenuButton${ ind }` }/>)
            }
          })
        }  
    </div>
  );
}







const mapStateToProps = (reducer: reducerType) => {

  const studentLoginState = reducer.user.studentLoginState;
  const studentLoggedIn   = reducer.user.studentLoginState.loginState === 'success';

  return {
    componentsState:   reducer.components,
    studentLoginState: studentLoginState,
    studentLoggedIn:   studentLoggedIn,
    page:              reducer.page.page,
    userName:          studentLoggedIn ? studentLoginState.studentName : reducer.user.userInfo.userName
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
 // setComponentsState: (payload: componentsStateType) => dispatch(SET_COMPONENTS_STATE(payload)),
  setModalState:      (payload: { variant: string, open: boolean, showButtons: boolean }) => dispatch(SET_MODAL_STATE(payload)),
  doTest:             () => dispatch(DO_TEST())
});

export default connect(mapStateToProps,mapDispatchToProps)(Menu);





