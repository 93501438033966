import React          from 'react';
import { makeStyles } from 'makeStyles';
import { colors }     from '../../utils/colors';

var clsx = require('classnames');

const useStyles = makeStyles()(
  (Theme) => ({    input_container: {
        display:        'flex',
        alignItems:     'center',
        flexFlow:       'column',
        transition:     'all 1s',
    },
    text_field: {
        paddingLeft:     10,
        paddingRight:    10,
        backgroundColor: '#FFF',
        borderRadius:    '0.25em',
        fontSize:        30,
        marginTop:       10,
        marginBottom:    10,
        transition:      'background 0.2s',
        '&:focus': {
          outline: 'none',
          border:  `2px solid ${ colors.primary }`,
          height:   48,
        },
    },
    shake: {
        animation:               'shake 0.4s',
        animationIterationCount: 'infinite',
    }
}))

type IntegerProps = {
    combined:        boolean;
    number:          string;
    success:         string;
    inputInteger:    string;
    maxLength:       number;
    textFieldHeight: number;
    setInputInteger: (val: string) => void;
}

const Integer: React.FC<IntegerProps> = ({
    combined,
    number,
    success,
    maxLength,
    inputInteger,
    textFieldHeight,
    setInputInteger,
}) => {

  const { classes } = useStyles();

  const [shake, setShake] = React.useState(false);


  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'input1') {
        if (isNaN(+target.value) || target.value.length > maxLength) {
            target.value = inputInteger;
            setShake(true);
            setTimeout(() => { setShake(false) }, 1000)
            return;
        }
        setInputInteger(target.value)
    }
  }


  const [disable, failure] = React.useMemo(() => { 
      return [
          (success !== ''),
          (success === 'false')
        ]
 },[ success ])

  const [ containerStyle, inputStyle ] = React.useMemo(() => {
      return[
          {
            height:         combined ? '100%' : undefined,
            width:          combined ? '100%' : '100vw',
            justifyContent: combined ? 'center' : 'flex-start',
          },
          {
            textAlign: 'center' as 'center',
            width:     maxLength * 30,
            height:    textFieldHeight,
            border:    `1px solid ${ failure ? '#BBB' : colors.primary }`,
            color:     failure ? '#BBB' : colors.primary
          }
      ]

  },[failure,combined,maxLength,textFieldHeight])

  return (

    <div
       className = { shake ? clsx(classes.input_container, classes.shake) : classes.input_container }
       style     = { containerStyle }
    >
            <input
                className    = { classes.text_field }
                type         = "text"
                autoComplete = "off"
                onChange     = { changeInputValue }
                value        = { failure ? number : inputInteger }
                disabled     = { disable }
                id           = "input1"
                style        = { inputStyle }
            />
    </div>
  );
}

export default Integer;
