import React                       from 'react';
import { store }                   from 'index';
import { GET_STUDENT_SCORES_SAGA } from 'actions';

const useGetStudentScoresFunc = ( guest: boolean) => {

  const state                   = store.getState();
  const studentLoggedIn         = state.user.studentLoginState.loginState === 'success';
  const scoresUpdated           = state.data.dataUpdateState.scores;
  const userInfo                = state.user.userInfo;
  const { userEmail, userRole } = userInfo;


  React.useEffect(() => {

    if ( !studentLoggedIn && !guest && !scoresUpdated && userEmail && userEmail.length && userRole !== 'student' ) {
  
      store.dispatch(GET_STUDENT_SCORES_SAGA({ userEmail: userEmail }));
    }

  },[studentLoggedIn, scoresUpdated, userEmail, userRole, guest]) 
    
    
}

export default useGetStudentScoresFunc;
