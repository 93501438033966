
import { combineReducers }   from 'redux';
import { userReducer }       from './userReducer';
import { contentReducer }    from './contentReducer';
import { componentsReducer } from './componentsReducer';
import { dataReducer }       from './dataReducer';
import { modalReducer }      from './modalReducer';
import { pageReducer }       from './pageReducer';
import { authReducer }       from './authReducer';
import { audioReducer }      from './audioReducer';
import { challengesReducer } from './challengesReducer';
import { practiceReducer }   from './practiceReducer';



const reducer = combineReducers({
  user:       userReducer,
  content:    contentReducer,
  components: componentsReducer,
  data:       dataReducer,
  modal:      modalReducer,
  page:       pageReducer,
  auth:       authReducer,
  challenges: challengesReducer,
  practice:   practiceReducer,
  audio:      audioReducer
})

export type reducerType = ReturnType<typeof reducer>;

export { reducer };
