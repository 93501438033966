
const removeSubscribePrompt = () => {
    if (localStorage.getItem('subscribeAfterSignup')) {
        localStorage.removeItem('subscribeAfterSignup');
    }    
    if (localStorage.getItem('selectedPlan')) {
        localStorage.removeItem('selectedPlan');
    }   
}

export default removeSubscribePrompt;

