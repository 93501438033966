
import { ActionTypes }     from '../actions/types';
import { dataStateType }   from '../reducer/types';


const initialState: dataStateType = {
  dataState:          { userActivity: [], totalLikes: [], scores: [] },
  dataUpdateState:    { user: false, content: false, studentVideos: false, challenges: false, questions: false, topicIntros: false,  activity: false, totalLikes: false, scores: false, final: false },
  storageUpdateState: false,
};

const dataReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {


//SET_USER_INFO   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_USER_INFO':
{
    console.log('SET_USER_INFO')
    return {
      ...state,
      dataUpdateState: { ...state.dataUpdateState, user: true }
    }
}



//SET_CONTENT   -      -      -      -      -      -      -      -      -      -      -      -



case 'SET_CONTENT':
  {
    const { contentUpdated } = action.payload;

    return {
      ...state,
      dataUpdateState: {
        ...state.dataUpdateState,
        content: !!contentUpdated ? true : state.dataUpdateState.content
      }
    }

  }


//SET_STUDENT_VIDEOS   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_STUDENT_VIDEOS':
  {
      console.log('SET_STUDENT_VIDEOS')

      return {
        ...state,
        dataUpdateState: {
          ...state.dataUpdateState,
          studentVideos: true
        }
      }

  }


//SET_CHALLENGES   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_CHALLENGES':
  {

    console.log('SET_CHALLENGES')
    return {
      ...state,
      dataUpdateState: {
        ...state.dataUpdateState,
        challenges: true
      } 
    }

}


//SET_QUESTIONS   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_QUESTIONS':
{
  console.log('SET_QUESTIONS')
    
      return {
        ...state,
        dataUpdateState: {
          ...state.dataUpdateState,
          questions: true
        } 
      }
  
}
  

//SET_TOPIC_INTROS   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_TOPIC_INTROS':
  {

    console.log('SET_TOPIC_INTROS')


    return {
      ...state,
      dataUpdateState: {
        ...state.dataUpdateState,
        topicIntros: true
      } 
    }

}




//CHANGE_MODAL_STATE   -      -      -      -      -      -      -      -      -      -      -      -

/* 

case 'CHANGE_MODAL_STATE':
  {

    const modalVariant = action.payload;

    return {
      ...state,
      modalVariant: modalVariant
    }
  } */





//SET_USER_ACTIVITY 



case 'SET_USER_ACTIVITY':
  {

    console.log('SET_USER_ACTIVITY')

    return {
      ...state,
      dataState: {
        ...state.dataState,
        userActivity: action.payload
      }
    }

  }


// UPDATE_ACTIVITY  -      -      -      -      -      -      -      -      -      -      -      -



case 'UPDATE_ACTIVITY': {

  console.log('UPDATE_ACTIVITY')

  return {
     ...state,
     dataUpdateState: {
       ...state.dataUpdateState,
       activity: true
     }
  }

}

//_ _ _ _

case 'SET_TOTAL_LIKES':
  {

    console.log('SET_TOTAL_LIKES')

    return {
      ...state,
      dataState: {
        ...state.dataState,
        totalLikes: action.payload
      }
    }

  }



case 'UPDATE_TOTAL_LIKES': {

  console.log('UPDATE_TOTAL_LIKES')

  return {
     ...state,
     dataUpdateState: {
       ...state.dataUpdateState,
       totalLikes: true
     }
  }

}

//_ _ _ _


case 'SET_STUDENT_SCORES':
  {

    console.log('SET_STUDENT_SCORES')


    return {
      ...state,
      dataState: {
        ...state.dataState,
        scores: action.payload
      }
    }

  }

case 'UPDATE_STUDENT_SCORES': {

  console.log('UPDATE_STUDENT_SCORES')

  return {
     ...state,
     dataUpdateState: {
       ...state.dataUpdateState,
       scores: true
     }
  }

}


case 'UPDATE_GUEST_DATA': {

  console.log('UPDATE_GUEST_DATA')

  return {
     ...state,
     dataUpdateState: {
       user:           true,
       content:        true,
       studentVideos:  true,
       challenges:     true,
       questions:      true,
       topicIntros:    true,
       activity:       true,
       totalLikes:     true,
       scores:         true,
       userLessons:    true,
       final:          false
    }
  }

}






// SET_DATA    -      -      -      -      -      -      -      -      -      -      -      -



case 'SET_DATA': {

  console.log('SET_DATA')

  const { variant } = action.payload;

  // data found in DB

  if (variant === 'real') {
    return {
      ...state,
      dataUpdateState: {
        ...state.dataUpdateState,
        final: true
      },
      storageUpdateState: true
    }
  }

  return {
    ...state,
    storageUpdateState: true
  }

}
  


case 'STUDENT_LOGIN':

  {
    return initialState
  }




  
//RESET_STATE   -      -      -      -      -      -      -      -      -      -      -      -


case 'RESET_STATE':
  {
    return initialState;
  }




// -      -      -      -      -      -      -      -      -      -      -      -




    default: return state;
  }
};

export type reducerType = ReturnType<typeof dataReducer>;

export { dataReducer };
