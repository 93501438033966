const groupsArr = [
    `Groups are a convenient way for teachers to share lessons with each other.`,
    `When you save a lesson (see the tutorial "Saving a lesson") you can ascribe it to a group.`,
    `Any other teacher that searches for lessons in this group will find them here.`,
]


export const texts: { [key: string]: string[] } = {

        'disableForGuest':           [ "Guests do not have access to this content" ],
        'disableForNonSub':          [ "This content is accessable to subscribers" ],
        'log out':                   [ "Are you sure you want to sign out?" ],
        'teacherUrl':                [ "A unique link has been copied to your clipboard.", " Paste it (CTRL V) and send it via email, WhatsApp, Zoom etc. to your students to receive their results.", " The link will be active for two weeks."],
        'groupUrl':                  [ "A link has been copied to your clipboard.", " Paste it (CTRL V) and send it to your students via email, WhatsApp, Zoom etc.", "This link will open a page containing the lessons of this group."],
        'subsciptionCreation':       [ "Thank you for subscribing!", "As a member of I See Math you now have full access to our content."],
        'freeTrialCreated':          [ "You have started your free trial!", "click on continue for full access to our content."],
        'trialCompletion':           [ "Your 30 day free trial has expired.", "Subscribe to access I See Math content" ],
        'showSuspendedSub':          [ "A subscription belonging to your account has been deactivated.", "Would you like to reactivate your old subscription or create a new one?", " " ],
        'messageSent':               [ "Thank you for your message." ],
        'generalError':              [ "Something is wrong.", "Please try again later." ],
        'abortSuspensionSuccess':    [ "Deactivation of your account has been successfuly canceled" ],
        'screenTooSmall':            [ "I See Math apps are optimized for larger screens" ],
        'badBrowser':                [ "I See Math apps are currently optimized for Google Chrome, Edge, and Safari browsers" ],
        'maxStudentOpenApps':        [ "You can add up to five apps to your student content collection" ],
        'disabledfavorites':         [ "Your favorite apps will appear here.", "Register or start a free trial to add apps and lessons to your favorites list." ],
        'disabledgroups':            groupsArr.concat(['Register or start a free trial to save and share your lessons and access lessons shared by other teachers.']),
        'disableduserLessons':       ['Most apps allow you to create pages and easily save them as lessons.' , 'The saved lessons appear much like a presentation, but with the added interest of the dynamic app.' , `Watch the tutorial "Saving a lesson" to see how it's done.`, `Register or start a free trial to save and share your lessons.`],
        'disabledmyStudentsContent': [ `Your students can log onto I SEE MATH by clicking the "I'm A Student" button in the log in form.`, `The students will have access to all puzzles and explainer videos in the Bonus Content.` , `In addition, you can choose to share up to 5 apps and lessons.`, `Register or start a free trial to save and share your lessons with your students.`],
        'showFollowLink':            [ 'To join lesson click on the following link:' ],
        'followLinkNotFound':        [ "Lesson number doesn't exist, please try again" ],
        'groups':                    groupsArr,
        'emptyGroup':                groupsArr,
        'failedPaymentsWarning':     []
}


export const subDeactivation = () => {
    return[ "Are you sure you want to deactivate your subscription?", `This action will result in restricting your access to all subscriber content.`, "Note: outstanding balance will be billed at the next billing cycle."];
}

export const deactivationSuccess = () => {
    return[ `You have chosen to deactivate your subscription.`, `You will no longer have access to subscriber content.`, "Note: outstanding balance will be billed at the next billing cycle."];
}

export const expirationWarning = ( balance: string ) => {
    return[ `This is just a notice that your I See Math account has a balance due of $${ balance } on it.`, `Note: Accounts overdue for 60 days will be suspended for non-payment.`];
}




