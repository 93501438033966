import { call, take, put }     from 'redux-saga/effects';
import axios                   from 'axios';
import { 
        UPDATE_TEACHER_CODE,
        RECORD_ERROR 
    }                          from '../actions'; 
import { CREATE_ERROR }        from 'actions/modal_actions'; 

const dev = process.env.NODE_ENV === 'development';
const endpoint = 'https://iseemath.co/api/getTeacherCode.php';


function* getTeacherCode(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('GET_TEACHER_CODE_SAGA');

        const obj = { userEmail: userInput.payload }

        if (dev) {
            yield put(UPDATE_TEACHER_CODE('100')); 
        }
        else {

            const res = yield call( axios.post, endpoint, obj );
     
            if (res && res.data && res.data.success && res.data.success === 1 ) {
                yield put(UPDATE_TEACHER_CODE(`${ res.data.result }`)); 
            }
            else {
              yield put(CREATE_ERROR());
              if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
                yield put(RECORD_ERROR({ userEmail: userInput.payload, err: res.data.msg, endpoint: 'get_teacher_code' }));
              }
            }

        }
    }
    catch(err) {
      yield put(CREATE_ERROR());
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default getTeacherCode;


