

import { ActionTypes }   from '../actions/types';
import { authStateType } from '../reducer/types';


const initialState: authStateType = {
    showAuthLoader:   false,
    showAuthForm:     false,
    showGoogleButton: true,
    userConfirmed:    true,
    displayForm:      'sign_up_part1',
    name:             '',
    grade:            '',
    email:            '',
    message:          '',
    password:         '',
    userOrigin:       '',
    code:             '',
    error:            ''
};

const authReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {

    case 'SET_AUTH_STATE':{

      const newState = action.payload;
    
      return {
        ...newState
      }
    }


    case 'SET_AUTH_LOADER':{

        return {
          ...state,
          showAuthLoader: action.payload
        }
    }

    case 'SET_ERROR':{

        return {
          ...state,
          error: action.payload
        }
    }

    case 'RESET_STATE':
        {
          return initialState;
        }
    


    

    default: return state;
  }
};

export type reducerType = ReturnType<typeof authReducer>;

export { authReducer };
