import React                   from 'react';
import { Dispatch }            from "redux";
import { connect }             from 'react-redux';
import { reducerType }         from 'reducer';
import { ActionTypes }         from 'actions/types';
import removeSubscribePrompt   from 'functions/payments/remove_subscribe_prompt';    
import { colors }              from 'utils/colors';
import { sizes }               from 'utils/defaultStates';
import goToLogin               from 'functions/auth/go_to_login';
import recordEvent             from 'functions/contentInteraction/record_event';
import { makeStyles }          from 'makeStyles';
import { SET_MODAL_STATE } from 'actions/modal_actions';


var clsx = require('classnames')

type fxabs = 'absolute' | 'fixed';

const useStyles = makeStyles()((Theme) => ({    
    navBar: {
        top:             0,
        width:           '100vw',
        display:         'flex',
        justifyContent:  'flex-end',
        alignItems:      'center',
        transition:      'all 0.2s',
        zIndex:          2,
    },
    moveRight30px: {
        marginRight: 30,
    },
    moveLeft30px: {
        marginLeft:  15,
    },
    button: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        backgroundColor: '#FFF',
        borderRadius:    '0.5em',
        border:          `2px solid ${ colors.primary }`,
        color:           colors.primary,
        width:           120,
        height:          48,
        fontWeight:      'bold',
        cursor:          'pointer',
        '&:hover': {
            border: `2px solid ${ colors.primaryDark }`,
            color:  colors.primaryDark,
        }
    },
    button2: {
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
        textAlign:       'center',
        backgroundColor: colors.primary,
        marginRight:     15,
        borderRadius:    '0.5em',
        border:          `2px solid ${ colors.primary }`,
        color:           '#FFF',
       // marginTop:       48,
        width:           150,
        height:          48,
        fontWeight:      'bold',
        cursor:          'pointer',
        '&:hover': {
            backgroundColor: colors.primaryDark,
            border:          `2px solid ${ colors.primaryDark }`,
        }
    },
    logo: {
        marginLeft:         30,
        position:           'absolute',
        backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
        backgroundPosition: 'center',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat'
    },
}))
 
type NavBarProps = {
    variant:            'relative' | 'fixed';
    size:               number[];
    subscriber:         boolean;
    userSignedIn:       boolean;
    showFixedNavBar:    boolean;
    wideScreen:         boolean;
    hideNavbar:         boolean;
    getStarted:         () => void;
    setModalState:      (payload: { variant: string, open: boolean, showButtons: boolean }) => void;
}
 
const NavBar: React.FC<NavBarProps> = ({ 
    variant,
    size,
    subscriber,
    userSignedIn,
    showFixedNavBar,
    wideScreen,
    hideNavbar,
    getStarted,
    setModalState
  }) => {

  const { classes } = useStyles();

  const logIn = () => {
    removeSubscribePrompt();
    goToLogin();
    recordEvent('registration', 'homepage logIn button clicked', 'homepage_logIn')
  }

  const handleOpenModal = (val: string) => {
    setModalState({ variant: val, open: true, showButtons: true })
  }

  

  const [rootStyle, logoStyle] = React.useMemo(() => {

    const hide = variant === 'fixed' && !showFixedNavBar;

    return [
        {
           height:          sizes.navBarSize + 8,
           position:        variant === 'fixed' ? 'fixed' as fxabs : 'absolute' as fxabs ,
           backgroundColor: variant === 'fixed' ? '#FFF' : 'transparent',
           transform:      `translate(0px, ${ hide ? -sizes.navBarSize : 0 }px)`,
           opacity:        hide ? 0 : 1,
           boxShadow:      variant === 'fixed' ? '0px 4px 4px rgba(0,0,0,0.2)' : undefined,
        },
        {
            left:          wideScreen ? 0 : 15,
            height:        sizes.navBarSize,
            width:         sizes.navBarSize,
        }
    ]

  },[variant, showFixedNavBar, wideScreen])


  return (
       <div 
          className = { classes.navBar }
          style     = { rootStyle }
        >
          {
              variant === 'fixed' && size[0] > 400
              &&
              <div 
                 className = { classes.logo }
                 style     = { logoStyle }
              />
          }
          {
              !hideNavbar && (!subscriber || !userSignedIn) &&
              <div 
                 className = { classes.button2 }
                 onClick   = { getStarted }
              >
                  register for free
              </div>
          }
          {
              !hideNavbar && !userSignedIn &&
                  <div 
                     className = { classes.button }
                     onClick   = { logIn }
                  >
                      log in
                  </div>
                  
          }
          <div 
             className = { clsx(classes.button, (size[0] > 500 ? classes.moveRight30px : ''), classes.moveLeft30px) }
             onClick   = { () => handleOpenModal('join lesson')
            }
          >
              join lesson
          </div>
       </div>
  );
}


  

const mapStateToProps = (reducer: reducerType) => {
    return {}
};
   
  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setModalState: (payload: { variant: string, open: boolean, showButtons: boolean }) => dispatch(SET_MODAL_STATE(payload)),
  });
   
  
  export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
  
  