import { store }           from 'index';
import { modalStateType }  from 'actions/types';
import { authStateType }   from 'reducer/types';
import { SET_AUTH_STATE }  from 'actions/auth_actions';
import { SET_MODAL_STATE } from 'actions/modal_actions'

export const openMessageForm = (
    guest: boolean
) => {

    const state         = store.getState();
    const authState     = state.auth;
    const userInfo      = state.user.userInfo;
    const setAuthState  = (payload: authStateType) => store.dispatch(SET_AUTH_STATE(payload));
    const setModalState = (payload: modalStateType) => store.dispatch(SET_MODAL_STATE(payload));

   
    
    if ( guest ) {
      setAuthState({
        ...authState,
        name:    '',
        email:   '',
        message: '',
        error:   '',
      })
    }
    if ( !guest ) {
      setAuthState({
        ...authState,
        name:    userInfo.userName,
        email:   userInfo.userEmail,
        message: '',
        error:   '',
      })
    }
  
   setModalState({
     variant:     'Contact us',
     open:        true,
     showButtons: true
   })
 }
