import { store }                  from '../../index';
import { SET_COMPONENTS_STATE }   from '../../actions/component_actions'

const menuToggle = () => {

    /* if ( wideScreen ) {
      setShowMenu(!showMenu)
    }
    else {
      setShowSmallMenu(!showSmallMenu)
    } */


    const state = store.getState();

    const componentsState = state.components;

    store.dispatch(SET_COMPONENTS_STATE({
      ...componentsState,
     // selectedAppTopic: '',
      showSmallMenu:    !componentsState.showSmallMenu
    }))
}

export default menuToggle