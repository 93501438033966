
import { ActionTypes }      from '../actions/types';
import { contentStateType } from '../reducer/types';
import { make_hash }        from 'functions/appData/make_hash';


const initialState: contentStateType = {
  hash:             {},
  topicIntrosHash:  {},
  allContent:       [],
  bigContentArr:    [],
  apps:             [],
  lessons:          [],
  puzzles:          [],
  userLessons:      [],
  myStudentContent: [],
  challenges:       [],
  questions:        [],
  topicIntros:      [],
  studentVideos:    [],
  selectedAppIntro: '',
  selectedApp:      {},
  selectedPuzzle:   {},
  groupState:       { groupName: '', groupLessons: [], groupLessonsFound: false, showGroupLessonsLoader: false },
};

const contentReducer = (state = initialState, action: ActionTypes) => {

switch (action.type) {



//SET_CONTENT   -      -      -      -      -      -      -      -      -      -      -      -




case 'SET_CONTENT':
  {
    const { bigContentArr, apps, puzzles, lessons, userLessons, myStudentContent, hash, allContent/*, sender*/ } = action.payload;

    return {
      ...state,
      allContent:       allContent       && allContent.length       ? allContent       : [],
      bigContentArr:    bigContentArr    && bigContentArr.length    ? bigContentArr    : state.bigContentArr,
      apps:             apps             && apps.length             ? apps             : state.apps,
      puzzles:          puzzles          && puzzles.length          ? puzzles          : state.puzzles,
      lessons:          lessons          && lessons.length          ? lessons          : state.lessons,
      userLessons:      userLessons      && userLessons.length      ? userLessons      : state.userLessons,
      myStudentContent: myStudentContent && myStudentContent.length ? myStudentContent : state.myStudentContent,
      hash:             !!hash ? hash : state.hash
    }

  }

  //SET_APPS   -      -      -      -      -      -      -      -      -      -      -      -


  case 'SET_APPS':
  {
    const { apps } = action.payload;

    const bigArr = apps.concat(state.lessons).concat(state.puzzles).concat(state.userLessons).concat(state.myStudentContent);

    //console.log('bigArr SET_APPS : ',bigArr)
    const hash: { [key: string]: any } = make_hash(bigArr);

    return {
      ...state,
      apps:          apps,
      bigContentArr: bigArr,
      hash:          hash
    }

  }

  //SET_PUZZLES   -      -      -      -      -      -      -      -      -      -      -      -


  case 'SET_PUZZLES':
  {
    const { puzzles } = action.payload;

    const bigArr = (state.apps).concat(state.lessons).concat(puzzles).concat(state.userLessons).concat(state.myStudentContent);
    const hash: { [key: string]: any } = make_hash(bigArr);

    return {
      ...state,
      puzzles:       puzzles,
      bigContentArr: bigArr,
      hash:          hash
    }

  }


  //SET_LESSONS   -      -      -      -      -      -      -      -      -      -      -      -


  case 'SET_LESSONS':
  {
    const { lessons } = action.payload;

    const bigArr = (state.apps).concat(lessons).concat(state.puzzles).concat(state.userLessons).concat(state.myStudentContent);

    const hash: { [key: string]: any } = make_hash(bigArr);

    return {
      ...state,
       lessons:       lessons,
       bigContentArr: bigArr,
       hash:          hash
    }

  }


  //SET_USER_LESSONS   -      -      -      -      -      -      -      -      -      -      -      -


  case 'SET_USER_LESSONS':
  {
    const { userLessons } = action.payload;

    const bigArr = (state.apps).concat(state.lessons).concat(state.puzzles).concat(userLessons).concat(state.myStudentContent);

    const hash: { [key: string]: any } = make_hash(bigArr);

    return {
      ...state,
      userLessons:   userLessons,
      bigContentArr: bigArr,
      hash:          hash
    }

  }


  //SET_MY_STUDENT_CONTENT   -      -      -      -      -      -      -      -      -      -      -      -


  case 'SET_MY_STUDENT_CONTENT':
  {
    const { myStudentContent } = action.payload;

    const bigArr = (state.apps).concat(state.lessons).concat(state.puzzles).concat(state.userLessons).concat(myStudentContent);
   // console.log('bigArr SET_MY_STUDENT_CONTENT : ',bigArr)

    const hash: { [key: string]: any } = make_hash(bigArr);

    return {
      ...state,
      myStudentContent: myStudentContent,
      bigContentArr:    bigArr,
      hash:             hash
    }

  }




//SELECT_CONTENT   -      -      -      -      -      -      -      -      -      -      -      -




case 'SELECT_CONTENT':
  {
    return {
      ...state,
      selectedAppIntro : action.payload.selectedAppIntro ? action.payload.selectedAppIntro : state.selectedAppIntro,
      selectedApp      : action.payload.selectedApp ? action.payload.selectedApp : state.selectedApp,
      selectedPuzzle   : action.payload.selectedPuzzle ? action.payload.selectedPuzzle : state.selectedPuzzle,
    }
  }




  //SET_STUDENT_VIDEOS   -      -      -      -      -      -      -      -      -      -      -      -




  case 'SET_STUDENT_VIDEOS':
    {

      const cont = action.payload.filter((val: { [key: string]: any; }) => { return ( !!val.ism_channel )});

      return {
        ...state,
        studentVideos: cont,
      }

    }




//SET_CHALLENGES   -      -      -      -      -      -      -      -      -      -      -      -




case 'SET_CHALLENGES':
  {

    const chal = action.payload;

    return {
      ...state,
      challenges: chal
    }

  }



  //SET_QUESTIONS   -      -      -      -      -      -      -      -      -      -      -      -




case 'SET_QUESTIONS':
  {

    const questions_ = action.payload;

    return {
      ...state,
      questions: questions_,
    }

  }




  //SET_TOPIC_INTROS   -      -      -      -      -      -      -      -      -      -      -      -




case 'SET_TOPIC_INTROS':
  {

    const intros = action.payload;
    const obj    = {} as { [key: string]: any };

    for (let i = 0; i < intros.length; i++) {
      if (!!intros[i].sub_topic) {
        obj[`${ intros[i].sub_topic }`] = intros[i];
      }
    }

    return {
      ...state,
      topicIntros: intros,
      topicIntrosHash: obj,
    }

  }



    

//UPDATE_HASH-      -      -      -      -      -      -      -


case 'UPDATE_HASH':
  {
    const hash = action.payload;

    return {
      ...state,
      hash: hash
    }

  }



//SET_STUDENT_OPEN_APPS   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_STUDENT_OPEN_APPS': {

  const { myStudentContent } = action.payload;

  const msc = !!myStudentContent ? myStudentContent : state.myStudentContent

  return {
    ...state,
    myStudentContent: msc,
  }

}



//SET_GROUP_STATE   -      -      -      -      -      -      -      -      -      -      -      -


case 'SET_GROUP_STATE': {

  const { name, lessons } = action.payload;

  return {
    ...state,
    groupState: {
      ...state.groupState,
      groupName:    name,
      groupLessons: lessons
    }
  }

}





//STUDENT_LOGIN   -      -      -      -      -      -      -      -      -      -      -      -


case 'STUDENT_LOGIN':

  {

    const allCont = state.challenges.concat(state.puzzles).concat(state.questions)

    return {
      ...initialState,
      allContent:   allCont,
      challenges:   state.challenges,
      puzzles:      state.puzzles,
      questions:    state.questions,
      topicIntros:  state.topicIntros
    }

  }


  
//RESET_STATE   -      -      -      -      -      -      -      -      -      -      -      -


case 'RESET_STATE':
  {

    return initialState;


  }




// -      -      -      -      -      -      -      -      -      -      -      -




    default: return state;
  }
};

export type reducerType = ReturnType<typeof contentReducer>;

export { contentReducer };
