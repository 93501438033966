import React             from 'react';
import { Auth }          from 'aws-amplify';
import TextField         from '@mui/material/TextField';
import PasswordInput     from '../PasswordInput';
import recordEvent       from 'functions/contentInteraction/record_event';
import useTextFieldStyle from 'components/authenticator/functions/get_textfield_style';
import { makeStyles }    from 'makeStyles';
import { colors }        from 'utils/colors';


const useStyles = makeStyles()((Theme) => ({  
  root: {
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    width:          '90%',
    height:         '100%',
    marginLeft:     '5%',
  },
  logo: {
    width:              200,
    height:             65,
    backgroundImage:    `url(https://iseemath.co/images/logo.png)`,
    backgroundPosition: 'center',
    backgroundSize:     'contain',
    backgroundRepeat:   'no-repeat',
    marginBottom:       10
  },
  form: {
    paddingTop:     40,
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center'
  },
  text_field: {
    height:          45,
    fontSize:        30,
    color:           colors.darkText,
    marginTop:       10,
    marginBottom:    10,
    transform:      'translate(0px, -20px)'
  },
  forgot_pwd: {
     cursor: 'pointer',
     color:  colors.darkText,
     margin: 20
  },
  login: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    marginTop:       40,
    fontSize:        18,
    color:           colors.darkText,
    width:           200,
    borderRadius:    '0.5em',
    textAlign:       'center',
    cursor:          'pointer',
    height:          40,
    border:          `1px solid ${ colors.brightText }`,
    '&:hover': {
      opacity: 0.5
    } 
   },
   buttonText: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    fontSize:        18,
    fontFamily:     'Fredoka One',
  },
  loader_container: {
     width:  60,
     height: 60
  },
  txt: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         30,
    color:          colors.darkText,
  },
  err: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    height:         30,
    color:          '#F00'
  },
  iseemath: {
    marginTop:  5,
    fontSize:   18,
    fontWeight: 'bold'
  }
}));


type ReacoverPwd3Props = {
  size:          number[];
  email:         string;
  password:      string;
  error:         string;
  showLogo:      boolean;
  setError:      (val: string) => void;
  setEmail:      (val: string) => void;
  setPassword:   (val: string) => void;
  setShowLoader: (val: boolean) => void;
}

const RecoverPwd3: React.FC<ReacoverPwd3Props> = ({
   size,
   email,
   password,
   error,
   showLogo,
   setError,
   setEmail,
   setPassword,
   setShowLoader,
}) => { 

  const { classes } = useStyles();

  const textFieldStyle = useTextFieldStyle(size);

  const changeInputValue = (e: React.FormEvent) => {
    
    e.preventDefault();
    const target = e.target as HTMLInputElement;

    if (target.id === 'email')    { setEmail(target.value) }
    if (target.id === 'password') { setPassword(target.value) }

  }


    // g_signout(event) {
    //   console.log('saga dispached');
    //   const email = this.state.email;
    //   this.props.g_signout(email);
    // }

  
    const handleSubmit = async() => {

      setShowLoader(true);
  
      try{
           await Auth.signIn(email, password);
          // await (user => {
          //     if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          //          Auth.completeNewPassword(
          //             user,
          //             password
          //         )
          //     }
          // })
          window.location.reload(); 
      }
      catch(err: any){
        const _error = err.message || err
        setError(`${ _error }`);
        setShowLoader(false)
        recordEvent('registration', `recoverPwrd3 error : ${ _error }`, 'recoverPwrd3 error')
      }
      finally { }
    }
  
  
  return (
     <div className = { classes.root }>
              { showLogo && <div className = { classes.logo }/> }
              { error.length > 0   && <div className = { classes.err }> { error } </div> }
              { error.length === 0 && <div className = { classes.txt }>Please enter your Email address and your new password</div> }
                <form className = { classes.form } >
                  <TextField
                      className    = { classes.text_field }
                      style        = { textFieldStyle }
                      variant      = "standard"
                      id           = "email"
                      label        = "Email"
                      onChange     = { changeInputValue }
                  />
                   <PasswordInput
                      password         = { password }
                      showHelperText   = { false }
                      textFieldStyle   = { textFieldStyle }
                      changeInputValue = { changeInputValue }
                   />
                </form>
                <div onClick = { handleSubmit } className = { classes.login }>
                    Log In
                </div>            
      </div>
    )
}



export default RecoverPwd3;










