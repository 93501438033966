import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';
import mockApps            from '../content/mockContent/mockStudentApps';
import { 
  STUDENT_LOGIN, 
  GET_BONUS_CONTENT_SAGA,
  SET_ENCODED_INFO,
  RECORD_ERROR 
}                          from '../actions'; 
import { 
  my_encoder, 
  create_time_limit_code
}                           from '../functions/userData/my_encoder';

const dev = process.env.NODE_ENV === 'development';

const endpoint     = 'https://iseemath.co/api/studentLogin.php';
const appsEndpoint = 'https://www.yisumatica.org.il/api/getStudentAppsTa.php';

const resetPayload = {
  studentName:      '',
  studentClass:     '',
  teacherEmail:     '',
  loginState:       '',
  studentOpenApps:  [],
  myTeacherContent: [],
}

const failurePayload = {
  ...resetPayload,
  loginState: 'failure'
}


const sortStudentApps = (arr: { [key: string]: any; }[]) => {

   let apps    = [];
   let teacher = [];

   for (let i = 0; i < arr.length; i++) {
     if ( !!arr[i].origin && (arr[i].origin === 'apps' || arr[i].origin === 'lessons') ) {
        apps.push(arr[i])
     }
     else {
        arr[i].origin = 'myTeacherContent';
        teacher.push(arr[i])
     }
   }
   return[apps, teacher]
}


function* studentLogin(): Generator<any,any,any> {
  while(true) {
    try {

        const userInput = yield take('STUDENT_LOGIN_SAGA');
        
        const { email, code, name, grade, reset } = userInput.payload;

        if (reset) {
          yield put(STUDENT_LOGIN(resetPayload)); 
        }

        else {

            if (dev) {

                yield put(GET_BONUS_CONTENT_SAGA({ userEmail: email, hash: {} }))
                
                const studentContent = yield sortStudentApps(mockApps);
                const hashedEmail    = yield my_encoder(email, false);
                const timeLimit      = yield create_time_limit_code();
        
                const encoded = { 
                 userId:    hashedEmail,
                 timeLimit: timeLimit
                }
               
               yield put(SET_ENCODED_INFO( encoded ))
                
                yield put(STUDENT_LOGIN({
                    studentName:      name,
                    studentClass:     grade,
                    teacherEmail:     '',
                    loginState:       'success',
                    studentOpenApps:  studentContent[0],
                    myTeacherContent: studentContent[1]
                })); 
            }

            else {

                const obj1 = {
                  userEmail: email,
                  code:      code
                }
    
                const res1 = yield call( axios.post, endpoint, obj1 );

                if (res1 && res1.data && res1.data.success && res1.data.success === 1 ) {

                    console.log('res1       :   ',res1)

                    const apps = res1.data.result.student_open_apps;
                    const arr = !!apps.length ? JSON.parse(apps) : [];
                   // const str = arr.join();

                    const obj2 = {
                      apps: arr
                    }

                    const res2 = yield call( axios.post, appsEndpoint, obj2 );

                    console.log('res2 : ',res2)

                    if (res2 && res2.data && res2.data.success && res2.data.success === 1 ) {
    
                         const studentOpenApps = !!res2.data.studentOpenApps ? res2.data.studentOpenApps : [];
                         
                         const studentContent = yield sortStudentApps(studentOpenApps);

                         const payload = {
                              studentName:      name,
                              studentClass:     grade,
                              teacherEmail:     email,
                              loginState:       'success',
                              studentOpenApps:  studentContent[0],
                              myTeacherContent: studentContent[1]
                          } 

                         
                         yield put(STUDENT_LOGIN(payload)); 
                         
                    }
                    else {
  
                        const payload = {
                          studentName:      name,
                          studentClass:     grade,
                          teacherEmail:     email,
                          loginState:       'success',
                          studentOpenApps:  [],
                          myTeacherContent: [],
                        } 

                        yield put(STUDENT_LOGIN(payload)); 
  
                        if ( res2 && res2.data && !Boolean(res2.data.success) && res2.data.msg ) {
  
                          yield put(RECORD_ERROR({ userEmail: email, err: res2.data.msg, endpoint: 'student login (getStudentApps.php)' }));
  
                        }
                    }

                    yield put(GET_BONUS_CONTENT_SAGA({ userEmail: email, hash: {} }))

                    const hashedEmail = yield my_encoder(email, false);
                    const timeLimit   = yield create_time_limit_code();
            
                    const encoded = { 
                     userId:    hashedEmail,
                     timeLimit: timeLimit
                    }
                   
                    yield put(SET_ENCODED_INFO( encoded ))

                }
                else {
  
                  yield put(STUDENT_LOGIN(failurePayload));

                  if ( res1 && res1.data && !Boolean(res1.data.success) && res1.data.msg ) {

                    yield put(RECORD_ERROR({ userEmail: email, err: res1.data.msg, endpoint: 'student login (studentLogin.php)' }));

                  }
                }

        
            }
        }
    }
    catch(err) {
      yield put(STUDENT_LOGIN(failurePayload)); 
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default studentLogin;


