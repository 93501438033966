import React               from 'react';
import { connect }         from 'react-redux';
import { reducerType }     from 'reducer';
import { contentInfoType } from '../types'
import { makeStyles }      from 'makeStyles';

const images = [
  'myStudentContntImage', 
  'favoriteImage', 
  'integers1',
  'geometry2',
  'fractions',
  'axis2',
  'gali',
  'zvi'
];


const useStyles = makeStyles()(
  (Theme) => ({    hidden: {  
      top:      '-300vh',
      left:     '-300vw',
      position: 'fixed',
      height:   100,
      width:    100, 
    //  border: '100px solid #D00',
      opacity:  0, 
    // zIndex:  9999999
    },
    hidden2: {  
      top:      100,
      left:     100,
      position: 'fixed',
      height:   500,
      width:    500, 
      border: '10px solid #D00',
      opacity:  1, 
     zIndex:  9999999
    }
}))

type HiddenProps = {
  challenges:     contentInfoType[];
  questions:      contentInfoType[];
  challengeState: contentInfoType;
}

const Hidden: React.FC<HiddenProps> = ({ challenges, questions, challengeState }) => {

  const { classes } = useStyles();

  const tests = React.useMemo(() => {

    if (!!Object.keys(challengeState.selectedChallenge).length) {
      const { set1, set2, set3, set4, set5, set6 } = challengeState.selectedChallenge;
      return [ set1, set2, set3, set4, set5, set6 ]
    }

    return[[]]
    
  },[challengeState])


  return (
        <>
            {
              questions.map((val: contentInfoType, ind: number) => {
                return(
                  <div  
                    key       = { `questions_${ val.image }_${ ind }???` }
                    className = { classes.hidden }
                    style     = {{ backgroundImage: `url(https://www.yisumatica.org.il/justine/img/${ val.image })` }}
                  />
                )
              })
            }
            {
              challenges.map((val: contentInfoType, ind: number) => {
                return(
                  <div  
                    key       = { `challenges_${ val.image }_${ ind }??@` }
                    className = { classes.hidden }
                    style     = {{ backgroundImage: `url(https://iseemath.co/images/${ val.image }.png)` }}
                  />
                )
              })
            }
            {
              tests.map((val, ind) => {
                return(
                  <div key = { `bigTestArr${ ind }` } >
                      {
                        val.map((value: contentInfoType, index: number) => {
                          return(
                            <div 
                               key       = { `innerTestArr${ index }.]` }
                               className = { classes.hidden }
                               style     = {{ backgroundImage: `url(https://iseemath.co/images/challenges/${ value['img'] }.jpg)` }}
                            />
                          )
                        })
                      }
                  </div>
                )
              })
            }
            {
              images.map((val, ind) => {
                return(
                  <div 
                     key       = { `images***_${ val }${ ind }` } 
                     className = { classes.hidden }
                     style     = {{ backgroundImage: `url(https://iseemath.co/images/${ val }.${ val === 'zvi' ? 'JPG' : 'png' })` }}
                  />
                )
              })
            }
        </>
  );
  
}


const mapStateToProps = (reducer: reducerType) => {

  return {
    challenges:     reducer.content.challenges,
    questions:      reducer.content.questions,
    challengeState: reducer.challenges
}};

export default connect(mapStateToProps)(Hidden);



