import React                     from 'react';
import { store }                 from 'index';
import { GET_TEACHER_CODE_SAGA } from 'actions';

const useGetTeacherCode = () => {

  const state           = store.getState();
  const userInfoUpdated = state.data.dataUpdateState.user;
  const userInfo        = state.user.userInfo;

  React.useEffect(() => {

    if (!!userInfoUpdated && userInfo.userRole === 'teacher' && (!userInfo.teacherCode.length || userInfo.teacherCode === '0')) {
        store.dispatch(GET_TEACHER_CODE_SAGA(userInfo.userEmail));
    }
    
  },[userInfoUpdated, userInfo])

}

export default useGetTeacherCode;