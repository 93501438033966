
import { ActionTypes }     from 'actions/types';
import { contentInfoType } from 'types';


export const initialChallengeState: contentInfoType = { 
    selectedChallenge: {} as contentInfoType,
    level:             0,
    score:             0, 
    timeBonus:         0 
}

const challengesReducer = (state = initialChallengeState, action: ActionTypes) => {

  switch (action.type) {

    case 'SET_CHALLENGE_STATE':{
    
      return {
        ...action.payload
      }
    }

    default: return state;
  }
};


export { challengesReducer };
