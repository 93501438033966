import React               from 'react';
//import { sizes } from 'utils/defaultStates';

 
const useGetStyles = (
    size:       number[],
    state:      number,
    stateMax:   number,
    wideScreen: boolean,
) => {



  const [rootStyle, containerStyle, innerContainerStyle, unitStyle, arrowStyle, arrowStyle2, unitWidth, unitHeight] = React.useMemo(() => {

    const containerWidth = wideScreen ? size[0] * 0.84 : size[0] * 0.7;
    const unitH          = wideScreen ? 350 : 300;
    const unitW          = wideScreen ? (containerWidth)/3 : containerWidth;
    
    return[
        {
          //height:        unitH,
          paddingTop:    wideScreen ? 35 : 20,
          paddingBottom: wideScreen ? 35 : 20,
        },
        {
          minHeight:     unitH,
          maxHeight:     unitH,
          paddingBottom: wideScreen ? undefined : 20,
         },
        {
          height: unitH,
          width:  containerWidth
        },
        {
          width:  unitW - 40, 
          height: unitH - 40,
        },
        {
          fontSize:  45,
          cursor:    state === 0 ? 'default' : 'pointer',
          opacity:   state === 0 ?  0.2 : undefined,
        },
        {
          fontSize:  45,
          cursor:    state >= stateMax - 1 ? 'default' : 'pointer',
          opacity:   state >= stateMax - 1 ?  0.2 : undefined,
        },
        unitW,
        unitH,
    ]
  },[ wideScreen, size, state, stateMax ])

  return { rootStyle, containerStyle, innerContainerStyle, unitStyle, arrowStyle, arrowStyle2, unitWidth, unitHeight }
}


export default useGetStyles;
