
export const make_hash = (array: { [key: string]: any }[]) => {

let hash: { [key: string]: any } = {};
//console.log('array ;: ',array)

    for (let i = 0; i < array.length; i++) {

      if (!!array[i]) {
        let id = "";

        if (!!array[i].puzzle_id) {
          id = array[i].puzzle_id
        }
        if (!!array[i].uid) {
          id = `${ array[i].uid }`
        }
        if (!!array[i].lesson_id) {
          id = array[i].lesson_id
        }
  
        if (id.length) {
            hash[id]          = array[i];
            hash[id].liked    = 0;
            hash[id].selected = 0;
            hash[id].viewed   = 0;
        }
  
      }

      
      
    }

    return hash
          
}

   
   
   
   
   
   
   
   