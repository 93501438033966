import React             from 'react';
import Button            from './Button';
import goToLogin         from 'functions/auth/go_to_login';
import recordEvent       from 'functions/contentInteraction/record_event';
import { makeStyles }    from 'makeStyles';


const useStyles = makeStyles()(
  (Theme) => ({    root: {
      height:          '100%',
      display:         'flex',
      alignItems:      'center',
  },
    button: {
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      cursor:         'pointer',
      fontWeight:     'bold',
      color:          '#FFF'
    },
    hover: {
      '&:hover': {
        opacity: 0.5
      }
    },
    small: {
      fontSize: 13,
    },
    big: {
      fontSize: 17,
    }
}))


type SelectorProps = {
  subscriptionStatus:  string;
  wideScreen:          boolean;
  showSelectors:       boolean;
  showSignInButton:    boolean;
  showSubscribeButton: boolean;
  getStarted:          () => void;
}

const Selector: React.FC<SelectorProps> = ({ 
   subscriptionStatus,
   wideScreen,
   showSelectors,
   showSignInButton,
   showSubscribeButton,
   getStarted,
}) => {

  
  const { classes } = useStyles();


  const handleGoToLogin = () => {
    goToLogin();
    recordEvent('registration', 'navbar logIn button clicked', 'navbar_logIn')
  }

  const rootStyle = React.useMemo(() => {
    return{
      flex:            !wideScreen ? 1 : showSelectors ? 0.5 : 1,//0.9,
      minWidth:        !wideScreen ? undefined : showSelectors ? 230 : 270,
      justifyContent:  !wideScreen ? 'center' : 'right',
     }
  },[showSelectors, wideScreen])

  return (
       <div
         className = { classes.root }
         style     = { rootStyle }
       >
         {
           showSignInButton &&
           <Button
              val                = { 'sign in' }
              subscriptionStatus = { subscriptionStatus }
              wideScreen         = { wideScreen }
              func               = { handleGoToLogin }
           />
         }
         {
           showSubscribeButton &&
           <Button
              val                = { 'subscribe' }
              subscriptionStatus = { subscriptionStatus }
              wideScreen         = { wideScreen }
              func               = { getStarted }
           />
         }
      </div>
  );
}




export default Selector;



