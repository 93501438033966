
import { store }                  from '../../index';
import { contentInfoType }        from '../../types';
import { SET_COMPONENTS_STATE }   from '../../actions/component_actions'

const setAppUrl = (
    selected: contentInfoType, 
) => {

    const state   = store.getState();
  
    const { userInfo, encodedInfo }                   = state.user;
    const studentLoginState                           = state.user.studentLoginState;
    const studentLoggedIn                             = state.user.studentLoginState.loginState === 'success';
    const componentsState                             = state.components
    const { userEmail, userName }                     = userInfo;
    const { studentClass, studentName, teacherEmail } = studentLoginState;

    const id            = (!!selected.uid && selected.uid !== "undefined") ? `${ selected.uid }` : (!!selected.lesson_id && selected.lesson_id !== "undefined") ? selected.lesson_id : !!selected.puzzletype ? selected.page : '';
    const puzzleParam   = !!selected.puzzletype ? `&no_intro=1&student_tirgul=1&tr=1${ !!selected.extra_url ? `&${ selected.extra_url }` : "" }` : '';
    const studentParams = studentLoggedIn ? (`&ismStudent=1&tr=${ encodedInfo.userId }&studentClass=${ studentClass }${ !!selected.origin && selected.origin === 'myTeacherContent' ? '&teacherContent=1&sz=1&hm=1&m=1' : '' }`) : '';
    
    const appUrl   =  `https://www.yisumatica.org.il/flash-upload/js/js.html?lang=eng&page=${ id }&name=${ studentLoggedIn ? studentName : userName }&mail=${ studentLoggedIn ? teacherEmail : userEmail }&iseemath=1&exit_button=1${ puzzleParam }${ studentParams }`;
  //  const scoreUrl =  `https://www.yisumatica.org.il/flash-upload/js/js.html?lang=eng&page=Shiyuch_app&lesson_num=${ id }&name=${ userName }&mail=${ userEmail }&iseemath=1`;

    store.dispatch(SET_COMPONENTS_STATE({
      ...componentsState,
      appUrl: appUrl
    }))

    return(appUrl)

}

export default setAppUrl;
