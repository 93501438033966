import { updateUserActivityType } from "../../actions/types";
import { contentInfoType }        from "../../types";

const handleLike = (
     id:                 string, 
     action:             string,
     userName:           string,
     userEmail:          string,
     hash:               contentInfoType,
     updateUserActivity: (payload: updateUserActivityType) => void
) => {

    const payload = {
      hash:      hash,
      userName:  userName,
      userEmail: userEmail,
      lesson_id: id,
      action:    action
    }
    updateUserActivity(payload)
}

export default handleLike;