import { Auth } from 'aws-amplify';

const def = {
    userName:  'Guest',
    userEmail: 'Guest'
}

const getUser = async() => {

    try {      

        let userName = 'Guest', userEmail = 'Guest';

        const user = await Auth.currentAuthenticatedUser();

        if ( user && user.attributes ) {
            userName  = user.attributes['custom:name'] ? user.attributes['custom:name'] : user.attributes['email'];
            userEmail = user.attributes['email'];
            return {
                userName: userName,
                userEmail: userEmail
            }
        }
        else {
            return def;
        }
        
    }
    catch(err) {
        console.log(err)
        return def;
    }
    finally {
    }

}

export default getUser;
