import { take, put }  from 'redux-saga/effects';
import { 
  UPDATE_STUDENT_SCORES, 
  SET_ENCODED_INFO
}                           from '../actions';
import { 
  my_encoder, 
  create_time_limit_code
}                           from '../functions/userData/my_encoder';

function* getStudentScores(): Generator<any,any,any> {
    while(true) {
      try {

        const input         = yield take('GET_STUDENT_SCORES_SAGA');
        const { userEmail } = input.payload;
        const email         = my_encoder(userEmail, false);
        const timeLimit     = create_time_limit_code();

        const payload = { 
         userId:    email,
         timeLimit: timeLimit
        }
       
       yield put(SET_ENCODED_INFO( payload ))
       yield put(UPDATE_STUDENT_SCORES());
     
      }
      catch(err: any){
        console.log('err  :  '+err);
      }
      finally {
        
      }
    }
  }

export default getStudentScores;

