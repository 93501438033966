import { store }                 from 'index';
import { DISABLE_MODAL_VARIANT } from 'actions/modal_actions';
import recordEvent               from 'functions/contentInteraction/record_event';

const disableVariant = () => { 
    const state   = store.getState();
    const variant = state.modal.modalVariant;
    store.dispatch(DISABLE_MODAL_VARIANT(variant));
    recordEvent('modal', 'modal closed', variant)
}


export default disableVariant
