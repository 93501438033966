import { store }                 from 'index';
import { ABORT_SUSPENSION_SAGA } from 'actions';
import recordEvent               from 'functions/contentInteraction/record_event';


const handleAbortSuspension = () => { 

    const state = store.getState();
    const userInfo = state.user.userInfo;
    store.dispatch(ABORT_SUSPENSION_SAGA(userInfo.userEmail));
    recordEvent('modal', 'subscription suspension canceled', 'ABORT_SUSPENSION_SAGA')
}


export default handleAbortSuspension
