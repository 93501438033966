
import { ActionTypes }   from '../actions/types';
import { pageStateType } from '../reducer/types';


const initialState: pageStateType = {
    page:      'content',
    opacity:   1,
    savedPage: 'content'
};

const pageReducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {

    case 'SET_PAGE_STATE':{

      const newState = action.payload;
    
      return {
        ...newState
      }
    }

    case 'GO_TO_HOME_PAGE': {
        return {
            page:      'home',
            opacity:   1,
            savedPage: 'content'
        }
    }

    default: return state;
  }
};

export type reducerType = ReturnType<typeof pageReducer>;

export { pageReducer };
