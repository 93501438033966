
import { 
    ActionTypes, 
    createUserType,
    createDataType,
    createSubscriptionType,
    changeSubStatus,
    getUserInfoType,
    getUserActivityType,
    updateUserActivityType,
    getStudentScoresType,
    groupLessonsType,
    getContentType,
    setDataType,
    updateUserNameType,
    submitMessageType,
    schedualSuspensionType,
    updateFreeAccessType,
    createTrialType,
    recordUserDeviceType,
    //recordEventType,
    studentLoginType,
   // testType
  } from "./types";

  import { contentInfoType, studentLoginStateType, eventType } from 'types';
  


  // create things

  export const CREATE_USER_SAGA = (payload: createUserType) => ({
    type: 'CREATE_USER_SAGA',
    payload: payload
  });

  export const CREATE_USER = (payload: { success: string; }) => ({
    type: 'CREATE_USER',
    payload: payload
  });


  export const CREATE_DATA_SAGA = (payload: createDataType) => ({
    type: 'CREATE_DATA_SAGA',
    payload: payload
  });

  export const CREATE_FREE_TRIAL_SAGA = (payload: createTrialType) => ({
    type: 'CREATE_FREE_TRIAL_SAGA',
    payload: payload
  });


  export const CREATE_SUBSCRIPTION_SAGA = (payload: createSubscriptionType) => ({
    type: 'CREATE_SUBSCRIPTION_SAGA',
    payload: payload
  });




  // get things from db
  


  export const GET_USER_INFO_SAGA = (payload: getUserInfoType) => ({
    type: 'GET_USER_INFO_SAGA',
    payload: payload
  });

  export const GET_CONTENT_SAGA = (payload: getContentType) => ({
    type: 'GET_CONTENT_SAGA',
    payload: payload
  });

  export const GET_USER_ACTIVITY_SAGA = (payload: getUserActivityType) => ({
    type: 'GET_USER_ACTIVITY_SAGA',
    payload: payload
  });

  export const GET_TOTAL_LIKES_SAGA = () => ({
    type: 'GET_TOTAL_LIKES_SAGA',
    payload: true
  });

   export const GET_STUDENT_SCORES_SAGA = (payload: getStudentScoresType) => ({
    type: 'GET_STUDENT_SCORES_SAGA',
    payload: payload
  }); 

  
  export const GET_GROUP_LESSONS_SAGA = (payload: { groupName: string, hash: { [key: string]: any } }) => ({
    type: 'GET_GROUP_LESSONS_SAGA',
    payload: payload
  });


  export const GET_USER_LESSONS_SAGA = (payload: string): ActionTypes => ({
    type: 'GET_USER_LESSONS_SAGA',
    payload: payload
  });

  export const GET_GROUP_CONTENT_SAGA = (payload: string): ActionTypes => ({
    type: 'GET_GROUP_CONTENT_SAGA',
    payload: payload
  });

  export const GET_BONUS_CONTENT_SAGA = (payload: { userEmail: string, hash: {[key:string]:any} }): ActionTypes => ({
    type: 'GET_BONUS_CONTENT_SAGA',
    payload: payload
  });



  export const GET_TEACHER_CODE_SAGA = (payload: string): ActionTypes => ({
    type: 'GET_TEACHER_CODE_SAGA',
    payload: payload
  });


  export const JOIN_LESSON_SAGA = (payload: number): ActionTypes => ({
    type: 'JOIN_LESSON_SAGA',
    payload: payload
  });

  


  // set things in reducer


  export const SET_DATA = (payload: setDataType ) => ({
    type: 'SET_DATA',
    payload: payload
  })
  

  export const SET_ENCODED_INFO = (payload: { [key: string]: string } ) => ({
    type: 'SET_ENCODED_INFO',
    payload: payload
  });


  export const SET_USER_ACTIVITY = (payload: { [key: string]: any }[]) => ({
    type: 'SET_USER_ACTIVITY',
    payload: payload
  });



  export const SET_TOTAL_LIKES = (payload: any[]): ActionTypes => ({
    type: 'SET_TOTAL_LIKES',
    payload: payload
  });

  export const SET_STUDENT_SCORES = (payload: any[]): ActionTypes => ({
    type: 'SET_STUDENT_SCORES',
    payload: payload
  });

  
  export const SET_CONTENT = (payload: { [key: string]: any }) => ({
    type: 'SET_CONTENT',
    payload: payload
  });


  export const SET_GROUP_STATE = (payload: groupLessonsType): ActionTypes => ({
    type: 'SET_GROUP_STATE',
    payload: payload
  });

  
  export const SET_STUDENT_OPEN_APPS_SAGA = (payload: { userEmail: string, arr: string, myStudentContent: contentInfoType[] }): ActionTypes => ({
    type: 'SET_STUDENT_OPEN_APPS_SAGA',
    payload: payload
  });


  export const SET_STUDENT_OPEN_APPS = (payload: {  arr: string, myStudentContent: contentInfoType[] }): ActionTypes => ({
    type: 'SET_STUDENT_OPEN_APPS',
    payload: payload
  });


  
  export const SET_GROUP_STATE_LOADER = (): ActionTypes => ({
    type: 'SET_GROUP_STATE_LOADER',
    payload: true
  });


  export const SET_USER_LESSONS = (payload: { [key: string]: any }[]): ActionTypes => ({
    type: 'SET_USER_LESSONS',
    payload: payload
  });


  
  export const SET_USER_LESSONS_LOADER = (): ActionTypes => ({
    type: 'SET_USER_LESSONS_LOADER',
    payload: true
  });


  export const SET_STUDENT_VIDEOS = (payload: { [key: string]: any }[]): ActionTypes => ({
    type: 'SET_STUDENT_VIDEOS',
    payload: payload
  });


  export const SET_CHALLENGES = (payload: { [key: string]: any }[]): ActionTypes => ({
    type: 'SET_CHALLENGES',
    payload: payload
  });


  export const SET_QUESTIONS = (payload: { [key: string]: any }[]): ActionTypes => ({
    type: 'SET_QUESTIONS',
    payload: payload
  });


  export const SET_TOPIC_INTROS = (payload: { [key: string]: any }[]): ActionTypes => ({
    type: 'SET_TOPIC_INTROS',
    payload: payload
  });


  
  export const SET_FOLLOW_LINK = (payload: string) => ({
    type: 'SET_FOLLOW_LINK',
    payload: payload
  });  




  





  // update things in reducer

  
  
  export const UPDATE_ACTIVITY = (): ActionTypes => ({
    type: 'UPDATE_ACTIVITY',
    payload: true
  });


  export const UPDATE_USER_ACTIVITY_SAGA = (payload: updateUserActivityType): ActionTypes => ({
    type: 'UPDATE_USER_ACTIVITY_SAGA',
    payload: payload
  });


  export const UPDATE_TOTAL_LIKES = (): ActionTypes => ({
    type: 'UPDATE_TOTAL_LIKES',
    payload: true
  });


  export const UPDATE_STUDENT_SCORES = (): ActionTypes => ({
    type: 'UPDATE_STUDENT_SCORES',
    payload: true
  });

  
  export const UPDATE_GUEST_DATA = (): ActionTypes => ({
    type: 'UPDATE_GUEST_DATA',
    payload: true
  });


  export const UPDATE_HASH = (payload: { [key: string]: any }): ActionTypes => ({
    type: 'UPDATE_HASH',
    payload: payload
  });

  export const UPDATE_TEACHER_CODE = (payload: string): ActionTypes => ({
    type: 'UPDATE_TEACHER_CODE',
    payload: payload
  });

  
  // record user info and activity
  
  
  export const RECORD_USER_DEVICE_SAGA = (payload: recordUserDeviceType): ActionTypes => ({
    type: 'RECORD_USER_DEVICE_SAGA',
    payload: payload
  });


  export const RECORD_EVENT_SAGA = (payload: eventType): ActionTypes => ({
    type: 'RECORD_EVENT_SAGA',
    payload: payload
  });


  export const RECORD_ERROR = (payload: { userEmail: string; err: string; endpoint: string; }): ActionTypes => ({
    type: 'RECORD_ERROR',
    payload: payload
  });


  

  // log out, unsubscribe, reactivateSubscription, handle modal changes



  export const SUBMIT_MESSAGE_SAGA = (payload: submitMessageType): ActionTypes => ({
    type: 'SUBMIT_MESSAGE_SAGA',
    payload: payload
  });


  export const STUDENT_LOGIN_SAGA = (payload: studentLoginType): ActionTypes => ({
    type: 'STUDENT_LOGIN_SAGA',
    payload: payload
  });

  export const STUDENT_LOGIN = (payload: studentLoginStateType): ActionTypes => ({
    type: 'STUDENT_LOGIN',
    payload: payload
  });

  export const LOG_OUT_SAGA = (): ActionTypes => ({
    type: 'LOG_OUT_SAGA',
    payload: ''
  });

 
  export const UPLOAD_SAGA = (): ActionTypes => ({
    type: 'UPLOAD_SAGA',
    payload: ''
  });


  export const SCHEDUAL_SUSPENSION_SAGA = (payload: schedualSuspensionType): ActionTypes => ({
    type: 'SCHEDUAL_SUSPENSION_SAGA',
    payload: payload
  });

  export const ABORT_SUSPENSION_SAGA = (payload: string): ActionTypes => ({
    type: 'ABORT_SUSPENSION_SAGA',
    payload: payload
  });
/* 
  export const SET_SUSPENSION_DATE = (payload: string): ActionTypes => ({
    type: 'SET_SUSPENSION_DATE',
    payload: payload
  });
 */

  export const UNSUBSCRIBE_SAGA = (payload: changeSubStatus): ActionTypes => ({
    type: 'UNSUBSCRIBE_SAGA',
    payload: payload
  });


  export const REACTIVATE_SUBSCRIPTION_SAGA = (payload: changeSubStatus): ActionTypes => ({
    type: 'REACTIVATE_SUBSCRIPTION_SAGA',
    payload: payload
  });


  /* export const SET_MODAL_STATE = (payload: modalStateType) => ({
    type: 'SET_MODAL_STATE',
    payload: payload
  });
 */
  export const UPDATE_USER_NAME_SAGA = (payload: updateUserNameType) => ({
    type: 'UPDATE_USER_NAME_SAGA',
    payload: payload
  });

  export const UPDATE_USER_NAME = (payload: { userName: string, userOrigin: string }) => ({
    type: 'UPDATE_USER_NAME',
    payload: payload
  });

  export const UPDATE_FREE_ACCESS_SAGA = (payload: updateFreeAccessType) => ({
    type: 'UPDATE_FREE_ACCESS_SAGA',
    payload: payload
  });

  

  export const RESET_STATE = () => ({
    type: 'RESET_STATE',
    payload: true
  });

  export const DO_TEST = () => ({
    type: 'DO_TEST',
    payload: true
  });

  



   
  
  
  