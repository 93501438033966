import getFatherApp        from './get_father_app';
import { contentInfoType } from '../../types';

type appType = { [key: string]: any };

const goToFatherApp = (
    selectedApp:    appType,
    selectContent:  (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void,
) => {

    const father_app = getFatherApp(selectedApp.father_app);
    selectContent(father_app, 'appIntro', false, false)
}

export default goToFatherApp;