import React                   from 'react';
import { connect }             from 'react-redux';
import { reducerType }         from 'reducer';
import { contentInfoType }     from 'types'
import recordEvent             from 'functions/contentInteraction/record_event'
import goToApp                 from 'functions/contentInteraction/go_to_app'
import { colors }              from 'utils/colors';
import { makeStyles }          from 'makeStyles';
import { componentsStateType } from 'reducer/types';
import { sizes }               from 'utils/defaultStates';

var clsx = require('classnames')

const useStyles = makeStyles()(
  (Theme) => ({    bar: {
      position:        'absolute', 
      height:          70,
      backgroundColor: colors.brightGrey,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      transition:      'all 0.5s',
      color:           '#FFF',
    },
    button: {
      height:          35,
      flex:            1,
      marginLeft:      5,
      marginRight:     5,
     // maxWidth:        340,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      cursor:          'pointer',
      fontWeight:      'bold',
      boxShadow:       '2px 4px 9px rgba(0,0,0,0.1)',
      color:           '#FFF',
      backgroundColor: colors.primary,
      borderRadius:    '0.2em',
      textDecoration:  'none',
      '&:hover': {
         opacity: 0.5
      }
    },
    big: {
       fontSize: 18,
       maxWidth: 400,
    }
}))

type BarProps = {
    app:                 contentInfoType;
    componentsState:     componentsStateType;
    freeApp:             string;
    size:                number[];
    descriptionHeight:   number;
    containerHeight:     number;
    width:               number;
    wideScreen:          boolean;
    denyAccess:          boolean;
    studentLoggedIn:     boolean;
    showGoToPdf:         boolean;
    goToPDF:             (val: string) => void;
}
const Bar: React.FC<BarProps> = ({ 
    app,
    componentsState,
    freeApp,
    size,
    wideScreen,
    descriptionHeight,
    containerHeight,
    width,
    denyAccess,
    studentLoggedIn,
    showGoToPdf,
    goToPDF,
}) => {

  const { classes } = useStyles();

  const handleClick = () => {

    const id = !!app.lesson_id ? app.lesson_id : !!app.uid ? `${ app.uid }` : '';

    const disableApps    = app.origin !== "lessons" && denyAccess && app.totalLikes === 0;
    const disableLessons = app.origin === "lessons" && denyAccess && (app.lesson_id !== '34846') && (app.lesson_id !== '34959');

    const disableAccess = (studentLoggedIn || id === freeApp) ? false : (disableApps || disableLessons);
    
    if ( !disableAccess ) {
      recordEvent('app', 'app entered', componentsState.appUrl)
    } 
    else {
      recordEvent('app', 'access to app denied', 'access to app denied')
    } 

    const disable = disableAccess && !!id.length && componentsState.initialVideoPage !== id;

    goToApp(size, disable)
    
  }


  const [rootStyle] = React.useMemo(() => {
     return[
      {
         width: width / 10 * 9.6,
         right: width / 10 * 0.2,
         top:   containerHeight + sizes.tabSize + 74 + ((!!app.description || !!app.description_eng) ? descriptionHeight : 0),
       }
     ]
  },[width, containerHeight, app, descriptionHeight])

  return (
        <div 
         className = { classes.bar }
         style     = { rootStyle }
         >
            <div 
               className = { wideScreen ? clsx(classes.button, classes.big) : classes.button }
               onClick   = { handleClick }
            >
               { `Continue To ${ app.origin === 'lessons' ? 'Lesson' : 'App' }` }
            </div>
            {
               wideScreen && showGoToPdf &&
               <div 
                  className = { clsx(classes.button, classes.big) }
                  onClick   = { () => { 
                     goToPDF(app.student_page) 
                     recordEvent('app', 'student worksheet entered', app.student_page)
                  }}
               >
                  Student Worksheets
               </div>
            }
       </div>
  );
}



const mapStateToProps = (reducer: reducerType) => {
   return {
     componentsState: reducer.components, 
   }
};
 
 
 
 export default connect(mapStateToProps)(Bar);
 
 