
import { store }                  from '../../index';
import { SET_CONTENT }            from '../../actions/'

import create_topic_arr           from './create_topic_arr';
import create_app_arr             from './create_app_arr';
import createTopicIntrosArr       from './create_topic_intros_arr';

import { 
    appTopicsArrType, 
    appInfoType, 
} from '../../types';
import { SET_MODAL_STATE } from 'actions/modal_actions';


const setAppFilters = ( setAppTopicsArr: (val: appTopicsArrType[]) => void ) => {

    const state = store.getState();

    const { 
      bigContentArr,
      apps,
      lessons,
      puzzles,
      userLessons,
      topicIntros,
      myStudentContent,
      groupState 
    }                           = state.content;
    const componentsState       = state.components;
    const { groupLessons }      = groupState;
    const { studentLoginState } = state.user


    const setContent    = (payload: { [key: string]: any }) => store.dispatch(SET_CONTENT(payload));
    const setModalState = (payload: { variant: string, open: boolean, showButtons: boolean }) => store.dispatch(SET_MODAL_STATE(payload))


    if ( componentsState.selectedContent === 'favorites') {
     // console.log('test')
      return;
    }

     if ( componentsState.selectedContent === 'myStudentsContent') {
      setContent({ allContent: myStudentContent, sender: 'setAppFilters1' })
      if ( !myStudentContent.length ) {
        setModalState({ variant: 'help', open: true, showButtons: true }) 
      }
      return;
    } 

    /* 
     if ( componentsState.selectedContent === 'myStudentsContent') {
       let sApps = [];
       for ( let i = 0; i < studentOpenApps.length; i++ ) {
         if ( hash[`${ studentOpenApps[i] }`] ) {
           sApps.push(hash[`${ studentOpenApps[i] }`])
         }
       }

       setAllContent(sApps)
       return;
    }  */
   

    if (
      componentsState.selectedContent     === 'bigContentArr' && 
      componentsState.selectedGrades      === '1234567' && 
      componentsState.selectedAppTopic    === '' && 
      componentsState.selectedAppSubTopic === '' && 
      componentsState.searchStr           === ''
      ) {
         setContent({ allContent: bigContentArr, sender: 'setAppFilters2' })
        // setAllContent(bigContentArr); 
         const topics: appTopicsArrType[] = create_topic_arr(bigContentArr, componentsState.selectedGrades);
         setAppTopicsArr(topics)
         return;
    }



    const arr: appInfoType[] = create_app_arr(
      bigContentArr, 
      apps, 
      lessons, 
      puzzles, 
      myStudentContent,
      userLessons,
      groupLessons,
      studentLoginState,
      componentsState.selectedContent, 
      componentsState.selectedAppTopic,
      componentsState.selectedAppSubTopic,
      componentsState.selectedGrades, 
      componentsState.searchStr
    );


    if ( componentsState.selectedContent === 'userLessons' && !arr.length) {
       setModalState({ variant: 'help', open: true, showButtons: true }) 
       return;
     }
 
    
    if (componentsState.selectedContent === 'Practice') {
      setContent({ allContent: topicIntros, sender: 'setAppFilters3' })

    //  setAllContent(topicIntros);
    }
    else {
      if (!!arr.length) {
        setContent({ allContent: arr, sender: 'setAppFilters4' })
      }
    //  setAllContent(arr)
    }
    

    if (componentsState.createTopicArr) {

      if (arr.length === 0) {
        setAppTopicsArr([])
        return;
      }

      if ( componentsState.selectedContent === 'Practice' ) {
        const topics: appTopicsArrType[] = createTopicIntrosArr(topicIntros);
        setAppTopicsArr(topics)
        return;
      }

      const topics: appTopicsArrType[] = create_topic_arr(arr, componentsState.selectedGrades);
      setAppTopicsArr(topics)
    }
      
};

export default setAppFilters;







