import { call, take, put }     from 'redux-saga/effects';
import axios                   from 'axios';
import { 
    SET_STUDENT_VIDEOS,
    SET_CHALLENGES,
    SET_QUESTIONS,
    SET_TOPIC_INTROS,
    RECORD_ERROR
}                              from '../actions';

import mockstudentVideos      from '../content/mockContent/studentVideos';
import mockChallenges         from '../content/mockContent/challenges';
import mockQuestions          from '../content/mockContent/mockQuestions';
import mockTopicIntros        from '../content/mockContent/mockTopicIntros';


const videosEndpoint      = 'https://www.yisumatica.org.il/api/getVideos.php';
const challengesEndpoint  = 'https://iseemath.co/api/getChallenges.php';
const questionsEndpoint   = 'https://iseemath.co/api/getQuestions.php';
const topicIntrosEndpoint = 'https://iseemath.co/api/getTopicIntros.php';



const filterChallenges = (arr: { [key: string]: any; }[]) => {

   let arr_ = [];

   for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === 'Variables') {
          if (!!arr[i].set1) { arr[i].set1 = JSON.parse(arr[i].set1) }
          if (!!arr[i].set2) { arr[i].set2 = JSON.parse(arr[i].set2) }
          if (!!arr[i].set3) { arr[i].set3 = JSON.parse(arr[i].set3) }
          if (!!arr[i].set4) { arr[i].set4 = JSON.parse(arr[i].set4) }
          if (!!arr[i].set5) { arr[i].set5 = JSON.parse(arr[i].set5) }
          if (!!arr[i].set6) { arr[i].set6 = JSON.parse(arr[i].set6) }
          arr_.push(arr[i])
      }
   }
    return arr_;
}


function* getBonusContent(): Generator<any,any,any> {
  while(true) {
    try {

        const input = yield take('GET_BONUS_CONTENT_SAGA');

        const { userEmail/* , hash  */} = input.payload;

        if ( process.env.NODE_ENV === 'development' ) {

          const payload    = mockstudentVideos.filter((val: { [key: string]: any; }) => { return !!val.image });
          const challenges = filterChallenges(mockChallenges);

          yield put(SET_STUDENT_VIDEOS( payload ))
          yield put(SET_CHALLENGES( challenges ))
          yield put(SET_QUESTIONS( mockQuestions ))
          yield put(SET_TOPIC_INTROS( mockTopicIntros ))
        }


        if (process.env.NODE_ENV === 'production') {

                // get videos
               
                const res = yield call( axios.post, videosEndpoint );

                if ( res && res.data && res.data.result ) {

                    const payload = res.data.result.filter((val: { [key: string]: any; }) => { return !!val.image });;
                    yield put(SET_STUDENT_VIDEOS( payload ))

                  //  console.log('videos : ',JSON.stringify(payload, null, 2))

                }
                else {
                    if ( res && res.data && !Boolean(res.data.success) && res.data.msg ) {
                        yield put(RECORD_ERROR({ userEmail: userEmail, err: res.data.msg, endpoint: 'get_student_content - get videos' }));
                    }
                    yield put(SET_STUDENT_VIDEOS([]))
                }




                // get challenges


               
                const res2 = yield call( axios.post, challengesEndpoint );

                if ( res2 && res2.data && res2.data.result ) {

                    const challenges = filterChallenges(res2.data.result);
                    yield put(SET_CHALLENGES( challenges ))

                  //  console.log('challenges : ',JSON.stringify(challenges, null, 2))

                }
                else {
                    if ( res2 && res2.data && !Boolean(res2.data.success) && res2.data.msg ) {
                        yield put(RECORD_ERROR({ userEmail: userEmail, err: res2.data.msg, endpoint: 'get_student_content - get challenges' }));
                    }
                    yield put(SET_CHALLENGES([]))
                }


                // get questions


               
                const res3 = yield call( axios.post, questionsEndpoint );

                if ( res3 && res3.data && res3.data.result ) {

                    const questions = res3.data.result;

                   // console.log(JSON.stringify(questions, null, 2))
                    yield put(SET_QUESTIONS( questions ))


                }
                else {
                    if ( res3 && res3.data && !Boolean(res3.data.success) && res3.data.msg ) {
                        yield put(RECORD_ERROR({ userEmail: userEmail, err: res3.data.msg, endpoint: 'get_student_content - get questions' }));
                    }
                    yield put(SET_QUESTIONS([]))
                }



                // get topic intros


               
                const res4 = yield call( axios.post, topicIntrosEndpoint );

                if ( res4 && res4.data && res4.data.result ) {

                    const topicIntros = res4.data.result;
                  //  console.log(JSON.stringify(topicIntros, null, 2))

                    yield put(SET_TOPIC_INTROS( topicIntros ))



                }
                else {
                    if ( res4 && res4.data && !Boolean(res4.data.success) && res4.data.msg ) {
                        yield put(RECORD_ERROR({ userEmail: userEmail, err: res4.data.msg, endpoint: 'get_student_content - get topic intros' }));
                    }
                    yield put(SET_TOPIC_INTROS([]))
                }


        }
    }
    catch(err) {
      console.log('err at get student content saga  :  '+err);
    }
    finally {}
  }
}


export default getBonusContent;
