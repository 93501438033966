import { colors }      from 'utils/colors';
import { makeStyles }  from 'makeStyles';

const useStyles = makeStyles()((Theme) => ({     
  container: {    
    width:          '100%',
    height:         '65%',
    display:        'flex',
    flexFlow:       'column',
   // border: '1px dashed #000',


  },
  form: {
    paddingBottom:  5,
    flex:           1,
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
   // justifyContent: 'felx-start',
    justifyContent: 'center',
    alignItems:     'center',
  },
  label: {
      marginTop:    5,
      marginBottom: 20,
      textAlign:    'center',
      fontSize:     17,
    },
  text_field: {
    fontSize:        16,
    height:          45,
    color:           colors.darkText,
    marginBottom:    10, 
  },
  err: {
   marginTop:      20,
   fontSize:       17,
   display:        'flex',
   justifyContent: 'center',
   alignItems:     'center',
   textAlign:      'center',
   height:         30,
   color:          '#F00',
 }
}));


type JoinLessonInputProps = {
    error:           string;
    lessonNumber:    string;
    setLessonNumber: (val: string) => void;
    setError:        (val: string) => void;
}

const JoinLessonInput: React.FC<JoinLessonInputProps> = ({
     error,
     lessonNumber,
     setLessonNumber,
     setError,
}) => { 

  const { classes } = useStyles();

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();
    
    const target = e.target as HTMLInputElement;

    if (!isNaN(+target.value)) {
      setLessonNumber(target.value.trim()) 
      if (!!error.length) {
        setError('')
      }
    }

    else {
      setError('input must be a number')
    }
  }
 

  return (
      <div className = { classes.container }>       
          <div className = { classes.err }> 
             { error } 
          </div>
            <form className = { classes.form }>
                <div className = { classes.label } >
                  Enter lesson number
                </div>
                <input
                  className   = { classes.text_field }
                  id          = "messageFormName"
                  value       = { lessonNumber }
                  onChange    = { changeInputValue }
                />                
            </form>
      </div>
    )
  
}



export default JoinLessonInput;




