import { store }            from 'index';
import { SET_MODAL_STATE }  from 'actions/modal_actions'
import { SET_AUTH_STATE }   from 'actions/auth_actions';
import { authStateType }    from 'reducer/types';

const goToLogin = () => {

    const state         = store.getState();
    const modalState    = state.modal;
    const authState     = state.auth;

    const setModalState = (payload: { variant: string, open: boolean, showButtons: boolean }) => store.dispatch(SET_MODAL_STATE(payload))
    const setAuthState  = (payload: authStateType) => store.dispatch(SET_AUTH_STATE(payload))

    setModalState({
      variant:     modalState.modalVariant,
      open:        false,
      showButtons: true
    })
    setAuthState({
      ...authState,
      showAuthForm:    true,
      displayForm:     'sign_in'
    })

  }

  export default goToLogin;