import { store }                    from 'index';
import { RECORD_EVENT_SAGA }        from 'actions'
import { eventType, eventTypeType } from 'types';
import get_app_id                   from 'functions/get_app_id';
import { DateTime }                 from 'luxon';

const recordEvent = (
    eventType:   eventTypeType,
    action:      string,
    componentId: string,
) => { 

  const now             = DateTime.local();
  const time            = `${ now.toFormat('yyyy-LL-dd') }T${ now.toFormat('HH:mm:ss') }`;
  const state           = store.getState();
  const userInfo        = state.user.userInfo; 
  const contentState    = state.content;
  const record          = (payload: eventType) => store.dispatch(RECORD_EVENT_SAGA(payload));
  const { 
      sessionId, 
      userIp, 
      userEmail, 
      userName, 
      subscriber, 
      allowTrial 
    }                   = userInfo;
  const { selectedApp } = contentState;
  const selectedAppId   = get_app_id(selectedApp);
  const appId           = !!selectedAppId.length ? selectedAppId : componentId;
  const accessLevel     = subscriber ? 'subscriber' : 
                          allowTrial ? 'free trial' :
                          (!!userEmail && userEmail !== 'Guest') ? 'registered user' :
                          'guest'
  
  const payload = {
    session_id:             sessionId,
    user_ip:                userIp,
    user_email:             userEmail,
    user_name:              userName,
    user_access_level:      accessLevel,
    event_type:             eventType,
    action:                 action,
    app_id:                 eventType === 'app' ? appId : '',
    video_id:               eventType === 'video' ? componentId : '',
    audio_id:               eventType === 'audio' ? componentId : '',
    modal_id:               eventType === 'modal' ? componentId : '',
    registration_action_id: eventType === 'registration' ? componentId : '',
    button_id:              eventType === 'button' ? componentId : '',
    menu_button_id:         eventType === 'menu button' ? componentId : '',
    navigation_button_id:   eventType === 'navigation button' ? componentId : '',
    time:                   time
  }

  if ( !sessionId.length || !userEmail.length ) {
    return;
  }

  console.log('payload : ',payload)
  record(payload);

}

export default recordEvent;