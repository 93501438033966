import { contentInfoType } from "types";

const getAppId = (selected: contentInfoType) => {

  let id = '';

  if ( !!selected.uid && selected.uid !== "undefined" ) {
    id = `${ selected.uid }`;
  }
  if ( !!selected.lesson_id && selected.lesson_id !== "undefined" ) {
    id = selected.lesson_id;
  }
  if (!!selected.origin && selected.origin === 'puzzles' && !!selected.page) {
    id = `${ selected.page }${ selected.extra_url ? selected.extra_url : '' }`
  }

  return id
}

export default getAppId;
