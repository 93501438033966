import React          from 'react';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(
  (Theme) => ({    root: {
        height:         '50vh',
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        flexFlow:       'column',
        justifyContent: 'flex-end',
    },
    image: {
        backgroundPosition: 'center',
        backgroundSize:     'contain',
        backgroundRepeat:   'no-repeat',
        transition:         'transform 1s',
      //  height:             '55%',
    }
}))

type ImageProps = {
   size:            number[];
   variant:         string;
   src:             string;
   animateImage:    boolean;
   setAnimateImage: (val: boolean) => void;
}

const Image: React.FC<ImageProps> = ({ size, variant, src, animateImage, setAnimateImage }) => {

  const { classes } = useStyles();

  const [initialPos, setInitialPos] = React.useState(false);
  const [animate, setAnimate]       = React.useState(false);
  const [imageSrc, setImageSrc]     = React.useState(`https://iseemath.co/images/challenges/${ src }.jpg`);

  React.useEffect(() => {

        setTimeout(() => { setInitialPos(true) }, 10)

  },[setInitialPos])

  React.useEffect(() => {

    if (animateImage) {
      setAnimate(true);
      setAnimateImage(false);
      setTimeout(() => { 
        setImageSrc(`https://iseemath.co/images/challenges/${ src }.jpg`);
        setAnimate(false) 
      }, 500)
    }

  },[animateImage, setAnimateImage, setAnimate, setImageSrc, src])

  const imgStyle = React.useMemo(() => {
    return {
      height:          variant === 'perimeters' ? '55%' : '75%',
      backgroundImage: `url(${imageSrc})`,
      transform:       `translate(0px, ${initialPos && !animate ? 0 : -1000 }px)`,
      width:           size[0] > 600 ? '55%' : '90%',
    }
  },[imageSrc, size, initialPos, animate, variant])

  return (
    <div className = { classes.root }>
        <div
           className = { classes.image }
           style     = { imgStyle }
        />
    </div>
  );
}

export default Image;
