import React               from 'react';
//import FindTheShape        from './find_the_shape';
import Perimeter           from './perimeter';
//import PerimeterComparison from './perimeter_comparison';
//import BreakingCircles     from './breaking_circles'; */
import Volumes             from './volumes';
//import PerimetersTwoShapes from './perimeter_two_shapes';
//import Darts               from './darts';
//import FractionAddition    from './fraction_addition';
//import ImaginingFractions  from './imagining_fractions';
//import CuttingRectPuzzle   from './cutting_rect_puzzle';
import Variables           from './variables';
//import Submarines          from './submarines';
//import LongAddition        from './long_addition';
 

import { makeStyles }      from 'makeStyles';

const useStyles = makeStyles()((Theme) => ({
    root: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        width:           500,
       // overflow:       'hidden',
        transition:     'all 1s',
    }
}))

type AnimationProps = {
    size:             number[];
    variant:          string;
    initialAnimation: boolean;
}

const Animation: React.FC<AnimationProps> = ({ size, variant, initialAnimation }) => {

  const { classes } = useStyles();
  
  return (
    <div 
        className = { classes.root }
        style     = {{
           height:  initialAnimation ? (size[1] > 800 ? 400 : 250) : 1,
           opacity: initialAnimation ? 1 : 0
        }}
    >
      { variant === 'Perimeters'          && <Perimeter/> }
    {/*   { variant === 'Battleship'          && <Submarines          dimensions = { size[0] > 600 && size[1] > 800 ? 240 : 180 }/> }
      { variant === 'Long Addition'       && <LongAddition        dimensions = { size[0] > 600 && size[1] > 800 ? 240 : 180 }/> }
      { variant === 'Cutting Grids'       && <CuttingRectPuzzle   dimensions = { size[0] > 600 && size[1] > 800 ? 240 : 180 }/> }
      { variant === 'Imagining Fractions' && <ImaginingFractions  dimensions = { size[0] > 600 && size[1] > 800 ? 300 : 200 }/> }
      { variant === 'Fraction Addition'   && <FractionAddition    dimensions = { size[0] > 600 && size[1] > 800 ? 300 : 200 }/> }
      { variant === 'Acute Darts'         && <Darts               dimensions = { size[0] > 600 && size[1] > 800 ? 300 : 200 }/> }
      { variant === 'Obtuse Darts'        && <Darts               dimensions = { size[0] > 600 && size[1] > 800 ? 300 : 200 }/> }
      { variant === 'Perimeter Comparison'&& <PerimeterComparison dimensions = { size[0] > 600 && size[1] > 800 ? 400 : 250 }/> }
      { variant === 'Double Perimeters'   && <PerimetersTwoShapes dimensions = { size[0] > 600 && size[1] > 800 ? 400 : 250 }/> }
      { variant === 'Breaking Circles'    && <BreakingCircles     dimensions = { size[0] > 600 && size[1] > 800 ? 400 : 250 } size = { size }  /> }
      { variant === 'Fraction Variables'  && <Variables           dimensions = { size[0] > 600 && size[1] > 800 ? 240 : 180 } variant = { variant }/> } 
      { variant === 'Find The Shape'      && <FindTheShape        dimensions = { size[0] > 600 && size[1] > 400 ? 0.7 : 0.5 }/> }  */}
      { variant === 'Volumes'             && <Volumes             dimensions = { size[0] > 600 && size[1] > 800 ? 150 : 100 }/> }
      { variant === 'Variables'           && <Variables           dimensions = { size[0] > 600 && size[1] > 800 ? 240 : 180 } variant = { variant }/> }

    </div>
  );
}

export default Animation;
