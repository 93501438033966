
import { ActionTypes }     from 'actions/types';
import { contentInfoType } from 'types';

export const initialChallengeState: contentInfoType = { 
    practiceVariant: 'puzzles'
}

const practiceReducer = (state = initialChallengeState, action: ActionTypes) => {

  switch (action.type) {

    case 'SET_PRACTICE_STATE':{
    
      return {
        ...action.payload
      }
    }

    default: return state;
  }
};


export { practiceReducer };
